import React, { Fragment, useState, useEffect } from 'react';

import { Form, Button, Select, Row, Col, Input, Popconfirm } from 'antd';
import { PlusOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { getSupplierList } from './ApiCall';
// import { getDeviceSpecsList, getSupplierList, getParameterTypes } from './ApiCall';
const { Option } = Select;

//@ATT:this was created to make nested dynamic elements! This is hard!
const SpecForm = (props) => {
  // const [specList, setData] = useState([]);
  // const [paramList, paramData] = useState([]);
  // useEffect(() => {
  //   (async () => {
  //     try {
  // let response = await getDeviceSpecsList(props.deviceType);
  // setData(response.data);
  //       let paramTypes = await getParameterTypes();
  //       paramData(paramTypes.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, []);
  return (
    <div>
      <Form.List name={[props.fieldKey, 'Specifications']}>
        {(Specs, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                {' '}
                <Button
                  type="dashed"
                  className="submitBtn"
                  onClick={() => {
                    add();
                  }}
                >
                  {' '}
                  <PlusOutlined /> Add Spec{' '}
                </Button>{' '}
              </Form.Item>
              {Specs.map((Spec, index2) => (
                <>
                  <Row>
                    <Col span={5} offset={1}>
                      <Form.Item
                        label="Parameter"
                        {...Spec}
                        name={[Spec.name, 'SpecId']}
                        fieldKey={[Spec.fieldKey, 'SpecId']}
                        key={index2}
                        rules={[{ required: true, message: 'Spec Missing' }]}
                      >
                        <Select placeholder="Parameter" style={{ width: '90%' }}>
                          {/* {specList &&
                            Array.isArray(specList) &&
                            specList.map((param, index) => {
                              return (
                                <Option key={index} value={param.Id}>
                                  {' '}
                                  {param.Name}{' '}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="Parameter Type"
                        {...Spec}
                        name={[Spec.name, 'ParameterTypeId']}
                        fieldKey={[Spec.fieldKey, 'ParameterTypeId']}
                        key={index2}
                        rules={[{ required: true, message: 'Spec Missing' }]}
                      >
                        <Select placeholder="Parameter" style={{ width: '90%' }}>
                          {/* {paramList &&
                            Array.isArray(paramList) &&
                            paramList.map((param, index) => {
                              return (
                                <Option key={index} value={param.Id}>
                                  {' '}
                                  {param.Name}{' '}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="Value"
                        {...Spec}
                        name={[Spec.name, 'Value']}
                        fieldKey={[Spec.fieldKey, 'Value']}
                        key={index2}
                        rules={[{ required: true, message: 'Value Missing' }]}
                      >
                        <Input placeholder="Value" style={{ width: '90%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="UOM"
                        {...Spec}
                        name={[Spec.name, 'UOM']}
                        fieldKey={[Spec.fieldKey, 'UOM']}
                        key={index2}
                      >
                        <Input placeholder="UOM" style={{ width: '90%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={1}>
                      <Popconfirm
                        title="Are you sure that you want to  delete the Specification ?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() => remove(Spec.name)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <MinusCircleOutlined className="deleteIcon" />
                      </Popconfirm>
                    </Col>
                  </Row>
                </>
              ))}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};

const SupplierForm = (props) => {
  const [supplierList, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        let response = await getSupplierList();
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div>
      <Form.List initialValue={props.Suppliers} name={[props.fieldKey, 'Suppliers']}>
        {(Specs, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                {' '}
                <Button
                  type="dashed"
                  className=""
                  onClick={() => {
                    add();
                  }}
                >
                  {' '}
                  <PlusOutlined /> Add Supplier{' '}
                </Button>{' '}
              </Form.Item>
              {Specs.map((Spec, index2) => (
                <Row key={index2}>
                  <Col>
                    {' '}
                    <Form.Item
                      {...Spec}
                      name={[Spec.name, 'SupplierId']}
                      fieldKey={[Spec.fieldKey, 'SupplierId']}
                      key={index2}
                      rules={[{ required: true, message: 'Supplier Missing' }]}
                    >
                      <Select placeholder="Select Supplier" style={{ width: '200px' }}>
                        {supplierList &&
                          Array.isArray(supplierList) &&
                          supplierList.map((param, index) => {
                            return (
                              <Option key={index} value={param.Id}>
                                {' '}
                                {param.Name}{' '}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={1} offset={1}>
                    <Popconfirm
                      title="Are you sure that you want to  delete the Supplier ?"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => remove(Spec.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MinusCircleOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};
const PartsParamForm = (props) => {
  // const [setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        // let response = await getSupplierList();
        // setData(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div>
      <Form.List initialValue={props.Parameters} name={[props.fieldKey, 'Parameters']}>
        {(Specs, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                {' '}
                <Button
                  type="dashed"
                  className=""
                  onClick={() => {
                    add();
                  }}
                >
                  {' '}
                  <PlusOutlined /> Add Supplier{' '}
                </Button>{' '}
              </Form.Item>
              {Specs.map((Spec, index2) => (
                <Row key={index2}>
                  <Col span={6}>
                    <Form.Item
                      label="Parameter Name"
                      {...Spec}
                      name={[Spec.name, 'Name']}
                      fieldKey={[Spec.fieldKey, 'Value']}
                      key={index2}
                      rules={[{ required: true, message: 'Value Missing' }]}
                    >
                      <Input placeholder="Name" style={{ width: '90%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Parameter Type"
                      {...Spec}
                      name={[Spec.name, 'Value']}
                      fieldKey={[Spec.fieldKey, 'Value']}
                      key={index2}
                      rules={[{ required: true, message: 'Value Missing' }]}
                    >
                      <Input placeholder="Type" style={{ width: '90%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={1} offset={1}>
                    <Popconfirm
                      title="Are you sure that you want to  delete the Parameter ?"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => remove(Spec.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MinusCircleOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};
export { SpecForm, SupplierForm, PartsParamForm };
