import React, { useState, useEffect } from 'react';
import { Form, Button, Collapse, Popconfirm, Select, Divider, Space, message, Tooltip } from 'antd';
import themeSettings from '../../../Utils/themeSettings.json';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import RemediesForm from './RemediesForm';
import translation from '../../../Utils/translation';
import { getCauseCodes, insertModalData } from '../../../Utils/FormCalls';
import { StyledForm, StyledCollapse, StyledButton, StyledModal } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';

const { Panel } = Collapse;
const { Option } = Select;
const ActionType = 'create';
const CauseMaster = 'Master_CauseCodes';
const CausesForm = (props) => {
  const [CausesList, setCauses] = useState([]);
  const [Visible, setVisible] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [translationCache, setTranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getCauseCodes();
        setCauses(response);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  const CauseModalRef = React.createRef();

  function onCauseModalOpen() {
    setVisible(true);
  }
  const onCauseModalSubmit = async (e) => {
    e.preventDefault();
    // let data = CauseModalRef.current.getFieldValue();
    // let payloadCause = data;
    // let statusModal = await insertModalData(CauseMaster, ActionType, payloadCause);
    // setVisible(false);
    // if (statusModal && statusModal.message) {
    //   message.success(statusModal.message);
    //   if (CauseModalRef.current) CauseModalRef.current.resetFields();

    //   let list = await getCauseCodes();
    //   setCauses(list);
    // } else if (statusModal && statusModal.Error) {
    //   message.error(statusModal.Error);
    // }
    CauseModalRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        let response = await insertModalData(CauseMaster, ActionType, payload);
        setVisible(false);
        if (response && response.message) {
          message.success(response.message);
          if (CauseModalRef.current) CauseModalRef.current.resetFields();
          let list = await getCauseCodes();
          setCauses(list);
        } else if (response && response.Error) {
          message.error(response.Error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onCancel(e) {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      setVisible(false);
    }
  }
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
    // pattern: {
    //   mismatch: '${label} does not match pattern'
    // }
  };
  return (
    <>
      <div style={{ color: 'black', fontWeight: 'bold' }}>
        <>
          <div>
            <Form.List name={[props.fieldKey, 'Causes']} className="collapse">
              {(Causes, { add }) => (
                <>
                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Form.Item>
                      <StyledButton
                        theme={themeSettings}
                        // htmlType="submit"
                        // type="dashed"
                        // size="middle"
                        // style={{ background: '#29415a', color: '#fff', marginBottom: '20px' }}
                        onClick={() => add()}
                        // icon={<PlusOutlined />}
                      >
                        {translation(translationCache, constant.addCause)}
                      </StyledButton>
                    </Form.Item>
                  </div>
                  {Causes && Array.isArray(Causes) && Causes.length > 0 ? (
                    <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                      {Causes.map((cause, index2) => (
                        <Panel
                          key={index2}
                          header={`${translation(translationCache, 'Cause')} ${index2 + 1}`}
                          extra={
                            <Popconfirm
                              title={translation(
                                translationCache,
                                'Are you sure that you want to delete the cause'
                              )}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              // onConfirm={() => remove(cause.name)}
                              onConfirm={() => props.onRemove(props.fieldKey, index2, 'Causes')}
                              okText={translation(translationCache, constant.yes)}
                              cancelText={translation(translationCache, constant.no)}
                            >
                              <Tooltip
                                title={translation(translationCache, 'Delete')}
                                placement="bottom"
                              >
                                <DeleteOutlined />
                              </Tooltip>
                            </Popconfirm>
                          }
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Form.Item
                              style={{ width: '100%', flex: '50%' }}
                              {...cause}
                              label={translation(translationCache, constant.causeCode)}
                              name={[cause.name, 'CauseId']}
                              fieldKey={[cause.fieldKey, 'Cause Code']}
                              key={index2}
                              rules={[
                                { required: true }
                                // {
                                // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                // pattern: new RegExp(/^[^%!^ ]*$/)
                                // }
                              ]}
                            >
                              <Select
                                style={{ width: '70%' }}
                                onChange={(value) =>
                                  props.onCauseChange(
                                    value,
                                    'Problems',
                                    props.fieldKey,
                                    'Causes',
                                    index2,
                                    CausesList.filter((cause) => cause.Id === value),
                                    Causes
                                  )
                                }
                                placeholder={translation(translationCache, 'Select Cause')}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: '8px 0'
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: '0 8px 4px'
                                      }}
                                    >
                                      <Button
                                        type="dashed"
                                        // icon={<PlusOutlined />}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          onCauseModalOpen();
                                        }}
                                      >
                                        {translation(translationCache, constant.addCause)}
                                      </Button>
                                    </Space>
                                  </>
                                )}
                              >
                                {CausesList &&
                                  Array.isArray(CausesList) &&
                                  CausesList.map((causes, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={causes.Id}
                                        disabled={
                                          props &&
                                          props.formObject &&
                                          props.formObject.Problems &&
                                          props.formObject.Problems[props.fieldKey] &&
                                          props.formObject.Problems[props.fieldKey].Causes &&
                                          props.formObject.Problems[props.fieldKey].Causes.filter(
                                            (failure) => failure && failure.CauseId === causes.Id
                                          ).length > 0
                                            ? true
                                            : false
                                        }
                                      >
                                        {' '}
                                        {causes.CauseCode}{' '}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ width: '100%', flex: '50%' }}
                              {...cause}
                              // style={{ marginLeft: '20px' }}
                              label={translation(translationCache, 'Description')}
                              name={[cause.name, 'Description']}
                              fieldKey={[cause.fieldKey, 'Description']}
                              key={index2}
                              rules={[{ required: false }]}
                            >
                              <InputComponent
                                placeholder={translation(
                                  translationCache,
                                  'Autopopulate based on Cause'
                                )}
                                disabled={true}
                                // maxLength={150}
                                style={{ width: '70%' }}
                              />
                            </Form.Item>
                            <Form.Item
                              style={{ width: '100%', flex: '50%', marginLeft: '35px' }}
                              {...cause}
                              // style={{ marginLeft: '20px' }}
                              label={translation(translationCache, constant.Remarks)}
                              name={[cause.name, 'Remarks']}
                              fieldKey={[cause.fieldKey, 'Remarks']}
                              key={index2}
                            >
                              <InputComponent
                                placeholder={translation(translationCache, 'Input Remarks')}
                                // maxLength={150}
                                style={{ width: '32%' }}
                              />
                            </Form.Item>
                            {Visible === true ? (
                              <StyledModal
                                width={800}
                                closable
                                title={translation(translationCache, constant.newCause)}
                                visible={Visible}
                                onCancel={onCancel}
                                footer={[
                                  <Button key={2} onClick={onCancel}>
                                    {translation(translationCache, constant.cancel)}
                                  </Button>,
                                  <Button
                                    key={1}
                                    type="primary"
                                    onClick={(e) => {
                                      onCauseModalSubmit(e);
                                    }}
                                  >
                                    {translation(translationCache, constant.submit)}
                                  </Button>
                                ]}
                              >
                                {/* <Form
                                                                ref={CauseModalRef}
                                                                onFinish={onCauseModalSubmit}
                                                                // onFinishFailed={this.onFinishFailed}
                                                                // initialValues={formObject}
                                                                layout="vertical"
                                                            > */}
                                <StyledForm
                                  name="Item"
                                  theme={themeSettings}
                                  ref={CauseModalRef}
                                  // initialValues={formObject}
                                  // onFinish={this.onFinish}
                                  layout="vertical"
                                  // labelCol={{ flex: '130px' }}
                                  labelAlign="right"
                                  labelWrap
                                  //wrapperCol={{ flex: 1 }}
                                  validateMessages={validateMessages}
                                >
                                  <Form.Item
                                    style={{ width: '100%', flex: '50%' }}
                                    label={translation(translationCache, constant.newCauseCode)}
                                    name="CauseCode"
                                    rules={[
                                      { required: true },
                                      {
                                        // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                        // pattern: new RegExp(
                                        //   /^[一-龯ぁ-んァ-ン-_&0-9A-Za-zァ-ヶ ]*$/
                                        // )
                                      }
                                    ]}
                                  >
                                    <InputComponent
                                      placeholder={translation(
                                        translationCache,
                                        'Input Cause Code'
                                      )}
                                      style={{ width: '70%' }}
                                      //  / maxLength={50}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    style={{ width: '100%', flex: '50%' }}
                                    label={translation(translationCache, 'Description')}
                                    name="Description"
                                    rules={[
                                      {
                                        required: true,
                                        message: translation(
                                          translationCache,
                                          'Description is required'
                                        )
                                      },
                                      {
                                        // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                        // pattern: new RegExp(
                                        //   /^[一-龯ぁ-んァ-ン-_&0-9A-Za-zァ-ヶ ]*$/
                                        // )
                                      }
                                    ]}
                                  >
                                    <InputComponent
                                      placeholder={translation(
                                        translationCache,
                                        'Input Description'
                                      )}
                                      style={{ width: '70%' }}
                                      // maxLength={150}
                                    />
                                  </Form.Item>
                                </StyledForm>
                              </StyledModal>
                            ) : (
                              ''
                            )}
                          </div>
                          <Form.Item>
                            <RemediesForm
                              problemIndex={props.fieldKey}
                              causeIndex={cause.name}
                              onRemedieChange={props.onRemedieSelect}
                            />
                          </Form.Item>
                        </Panel>
                      ))}
                    </StyledCollapse>
                  ) : null}
                </>
              )}
            </Form.List>
          </div>
        </>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(CausesForm);
