import React, { Component, Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';

import ReactEcharts from '../../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
import { getChartDataWithSelection, getChartDataWithoutSelection } from '../../ChartCalls';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

class GridGuage extends Component {
  constructor(props) {
    super(props);
    let option = this.getInitialState();
    this.state = {
      option: option.option,
      fromedit: props.graphprops.fromedit,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  requestCheck = true;
  abortController = new window.AbortController();

  getOption = () => {
    let theme = this.props.selectedThemeing;

    let { graphprops } = this.props;

    let stringRange =
      graphprops.GridGuage && graphprops.GridGuage.range ? graphprops.GridGuage.range : '[]';
    let uom = graphprops.GridGuage && graphprops.GridGuage.uom ? graphprops.GridGuage.uom : '';

    let range = JSON.parse(stringRange);

    let min = range[0] || '';
    let max = range[1] || '';

    return {
      tooltip: {
        // formatter: '{c}'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            iconStyle: {
              color: 'none',
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: 'GridGuage',
          title: {
            offsetCenter: [0, '-30%'],
            fontSize: 25,
            // fontStyle: 'italic',
            color: ''
          },
          type: 'gauge',
          axisLabel: {
            color: '#fff000',
            fontSize: 25,
            distance: -40,
            radius: '80%',
            rotate: 'tangential'
            // formatter: function (value) {
            //   if (value === 0) {
            //     return '0';
            //   } else if (value === 15) {
            //     return '15';
            //   } else if (value === 30) {
            //     return '30';
            //   }
            //   return '';
            // }
          },
          startAngle: 160,
          endAngle: 20,
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },

          axisLine: {
            lineStyle: {
              color: [
                [0.25, '#e34b04'],
                [0.5, '#fec91b'],
                [0.75, '#6daa11'],
                [1, '#67e0e3']
              ],
              show: true,
              width: 18
            }
          },
          pointer: {
            offsetCenter: [0, '-55%'],
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '40%',
            itemStyle: {
              color: '#ffffff '
            }
          },

          detail: {
            // formatter: `{value} ${uom}`,
            fontWeight: 'smoothConstraint',
            fontSize: 30,
            color: 'auto',
            valueAnimation: true,
            // fontSize: 60,
            offsetCenter: [0, '-22%'],
            formatter: `{value} ${uom}`
          },
          splitNumber: 2,
          min: min,
          max: max,

          data: [
            {
              name: '',
              value: 0
            }
          ]
        }
      ]
    };
  };

  timeTicket = null;
  getInitialState = () => ({ option: this.getOption() });

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }
  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let json = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
        this.setState({ json }); //need
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      const option = cloneDeep(this.state.option);

      if (json && Array.isArray(json) && json.length > 0) {
        let tagValue = Number(json[0].Value).toFixed(2);

        option.series[0].data[0].value = tagValue;
      }

      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            id={Math.ceil(Math.random(100, 100000) * 1000)}
            option={this.state.option}
            style={{
              height: '150%',
              width: '100%'
            }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(GridGuage);
