import React, { Fragment } from 'react';
import moment from 'moment';
import { Table, message, Popconfirm, Switch, Input, Button } from 'antd';
import { DeleteOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import localStorage from '../../utils/localStorage';
import { getUserProfile } from '../../selectors/layout';
import {
  ruleList,
  deleteRule,
  updateRuleStatus,
  getRuleCounts,
  updateNotificationStatus
} from './Apicalls';
import { getTranslationCache } from '../../selectors/language';
import translation from '../../utils/translation';
import { StyledDashboard, StyledTable, RuleButton, lightTheme } from './style';
import constants from './constants';
import Highlighter from 'react-highlight-words';

class RulesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      countsData: {},
      userProfile: props.userProfile || {}
    };
  }
  async componentDidMount() {
    try {
      let data = await ruleList();
      this.countsReferesh();
      this.setState({ tableData: data.data });
    } catch (err) {
      message.warn('OOPS Something went Wrong');
    }
  }
  countsReferesh = async () => {
    let countsData = await getRuleCounts();
    this.setState({ countsData });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };
  deleteRule = async (key) => {
    try {
      let data = await deleteRule(key);
      if (data.status === 200) {
        message.success('Deleted Rule');
        try {
          let tableData = await ruleList();
          this.countsReferesh();
          this.setState({ tableData: tableData.data });
        } catch (err) {
          message.warn('Something went Wrong !');
        }
      }
    } catch (err) {
      message.warn('Something went Wrong !');
    }
  };
  ruleStatusUpdate = async (rule) => {
    let payLoad = {
      Id: rule.Id,
      Type: rule.Type,
      RuleStatus: !rule.RuleStatus
    };
    let data = await updateRuleStatus(payLoad);
    if (data && data.status === 200) {
      let data = await ruleList();
      this.countsReferesh();
      this.setState({ tableData: data.data });
    } else {
      message.error('something Went Wrong');
    }
  };

  notificationStatusUpdate = async (rule) => {
    let payLoad = {
      Id: rule.Id,
      Type: rule.Type,
      NotificationStatus: !rule.NotificationStatus
    };
    if (rule.Type === 'batch') {
      payLoad.FilterOption = rule.Priority;
    } else {
      payLoad.FilterOption = rule.ParameterType;
    }

    let data = await updateNotificationStatus(payLoad);
    if (data.status === 200) {
      let data = await ruleList();
      this.countsReferesh();
      this.setState({ tableData: data.data });
    } else {
      message.error('something Went Wrong');
    }
  };

  handleRowClick = (ruleId) => {
    this.props.history.push(`/rubus/RulesFeedback/?ruleId=${ruleId}`);
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constants.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constants.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constants.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let permissionsArray = [];
    let { translationCache } = this.props;
    let { userProfile, countsData } = this.state;
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'rule_engine')
        .map((name) => permissionsArray.push(name.action));
    const columns = [
      {
        title: translation(this.props.translationCache, constants.Rule_Name),
        dataIndex: 'DisplayRuleName',
        key: 'DisplayRuleName',
        ...this.getColumnSearchProps('DisplayRuleName')
      },
      {
        title: translation(this.props.translationCache, constants.ParameterName),
        dataIndex: 'ParameterName',
        key: 'ParameterName',
        ...this.getColumnSearchProps('ParameterName')
      },
      {
        title: translation(this.props.translationCache, constants.Rule_Description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(this.props.translationCache, constants.AssetName),
        dataIndex: 'AssetName',
        key: 'AssetName',
        ...this.getColumnSearchProps('AssetName')
      },
      {
        title: translation(this.props.translationCache, constants.createdTime),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      },
      {
        title: translation(this.props.translationCache, constants.edit),
        key: 'Edit',
        render: (text, record) => (
          <span>
            <FormOutlined
              style={{ color: '#1a3652' }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`RuleEngine/${record.Id}`);
              }}
            />
          </span>
        )
      },
      {
        title: translation(this.props.translationCache, constants.delete),
        key: 'Delete',
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title={translation(this.props.translationCache, constants.Delete_Rule)}
              onConfirm={(e) => {
                e.stopPropagation();
                this.deleteRule(record.Id);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText={translation(this.props.translationCache, constants.Yes)}
              cancelText={translation(this.props.translationCache, constants.No)}
            >
              <DeleteOutlined
                style={{ color: '#f5222d' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        )
      },
      {
        title: translation(this.props.translationCache, constants.RuleStatus),
        key: 'RuleStatus',
        render: (text, record, index) => {
          return (
            <span>
              <Switch
                checkedChildren={
                  translationCache && translationCache['Active']
                    ? translationCache['Active']
                    : 'Active'
                }
                unCheckedChildren={
                  translationCache && translationCache['InActive']
                    ? translationCache['InActive']
                    : 'InActive'
                }
                checked={record.RuleStatus}
                onChange={() => {
                  this.ruleStatusUpdate(record, index);
                }}
              />
            </span>
          );
        }
      },
      {
        title: translation(this.props.translationCache, constants.status),
        key: 'NotificationStatus',
        render: (text, record, index) => {
          return (
            <span>
              <Switch
                checkedChildren={
                  translationCache && translationCache['Active']
                    ? translationCache['Active']
                    : 'Active'
                }
                unCheckedChildren={
                  translationCache && translationCache['InActive']
                    ? translationCache['InActive']
                    : 'InActive'
                }
                checked={record.NotificationStatus}
                onChange={() => {
                  this.notificationStatusUpdate(record, index);
                }}
              />
            </span>
          );
        }
      },
      {
        title: '',
        key: '',
        render: (text, record) => {
          return (
            <RuleButton
              type="primary"
              onClick={() => {
                this.handleRowClick(record.Id);
              }}
            >
              {translation(this.props.translationCache, constants.RuleHistory)}
            </RuleButton>
          );
        }
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              color: '#fff !important'
            }}
          >
            <Button
              type="primary"
              disabled={!permissionsArray.includes('create') ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/rubus/RuleEngine`);
              }}
            >
              {translationCache && translationCache[`${constants.addrule}`]
                ? translationCache[`${constants.addrule}`]
                : `${constants.addrule}`}
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              color: 'white',
              margin: '10px 0px'
            }}
          >
            {countsData && !Array.isArray(countsData) ? (
              <span>
                <span>
                  {translation(this.props.translationCache, constants.Total_Count)} :{' '}
                  {countsData.TotalCount || '-'} ,{' '}
                </span>
                <span>
                  {translation(this.props.translationCache, constants.Active_Count)} :{' '}
                  {countsData.ActiveCount || '-'} ,{' '}
                </span>
                <span>
                  {translation(this.props.translationCache, constants.Inactive_Count)} :{' '}
                  {countsData.InActiveCount || '-'}{' '}
                </span>
              </span>
            ) : null}
          </div>
          <div>
            <StyledTable theme={lightTheme}>
              <Table columns={columns} dataSource={this.state.tableData} />
            </StyledTable>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(RulesList);
