import React, { Component } from 'react';
import { Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';

import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { getMasterList } from '../../../Utils/FormCalls';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { StyledModal, StyledTable } from '../../../CSS/style';
// import InputComponent from '../../../Widgets/InputComponent';
const urlList = {
  list: '/api/masterFailureCodes/hierarchy'
};
class SelectNestedComponennt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      currentOption: [],
      selectedValue: [],
      current: 1,
      conditionList: [],
      visible: false
    };
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  componentDidUpdate = (prevprops) => {
    if (this.props.translationCache !== prevprops.translationCache) {
      this.setState({ translationCache: this.props.translationCache });
    }
  };

  _getAdhocDataSource = async () => {
    let currentOption = await getMasterList(urlList.list);
    this.setState({
      currentOption
    });
  };

  openModal = () => {
    this._getAdhocDataSource();
    this.setState({
      visible: true
    });
  };
  handleCancel = () => {
    this._getAdhocDataSource();
    this.setState({
      visible: false,
      selectedValue: [],
      current: 1
    });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, `Search`)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { currentOption, selectedValue, current } = this.state;
    let { text, translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, 'Failure Class'),
        dataIndex: 'value',
        key: 'value',
        ...this.getColumnSearchProps('value')
      },
      {
        title: translation(translationCache, 'Type'),
        dataIndex: 'Type',
        key: 'Type',
        ...this.getColumnSearchProps('Type')
      },
      {
        title: translation(translationCache, 'Description'),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(translationCache, 'Remarks'),
        dataIndex: 'Remarks',
        key: 'Remarks'
      }
    ];
    return (
      <div>
        <span>
          <Input
            style={{ marginLeft: '37px', width: '20%', flex: '30%' }}
            size="small"
            placeholder={translation(this.props.translationCache, 'Select Failure Class')}
            value={text && text !== '' ? text : undefined}
          />
          <UploadOutlined
            onClick={this.openModal}
            style={{ fontSize: '20px', cursor: 'pointer', color: 'white' }}
          ></UploadOutlined>
        </span>
        <StyledModal
          title={
            currentOption && currentOption[0] && currentOption[0]['Type']
              ? translation(translationCache, `Select ${currentOption[0]['Type']}`)
              : translation(translationCache, 'Select')
          }
          visible={this.state.visible}
          // onOk={undefined}
          footer={null}
          closable
          onCancel={this.handleCancel}
        >
          {currentOption && Array.isArray(currentOption) && currentOption.length > 0 ? (
            <StyledTable
              key={current}
              theme={themeSettings}
              dataSource={currentOption}
              columns={columns}
              onRow={(record) => {
                return {
                  onClick: () => {
                    selectedValue.push(record);
                    this.setState({
                      selectedValue,
                      currentOption: record && record.child ? record.child : [],
                      current: current + 1
                    });
                    if (current === 4) {
                      this.handleCancel();
                      this.setState({
                        currentOption: this.state.optionList
                      });
                      this.props.NestedSelectOnChange(selectedValue, 'FailureCodes');
                    }
                  }
                };
              }}
            />
          ) : (
            <div>No data </div>
          )}
        </StyledModal>
      </div>
    );
  }
}

export default SelectNestedComponennt;
