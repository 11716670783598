import React, { Component } from 'react';
import axios from 'axios';
import { Modal, Table, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import localStorage from '../../../../utils/localStorage';

class SelectNestedComponennt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      currentOption: [],
      selectedValue: [],
      current: 1,
      conditionList: [],
      visible: false
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.text !== this.props.text) {
      this.setState({
        text: this.props.text
      });
    }

    if (
      prevprops.datasource !== this.props.datasource ||
      prevprops.subdatasource !== this.props.subdatasource
    ) {
      let { column, datasource, subdatasource } = this.props;
      if (column && column.table && column.table.payload && column.table.payload.conditionList) {
        //Condition based filter option
        let conditionList = column.table.payload.conditionList;
        let dataSourceKeys = Object.keys(datasource);
        let subdataSourceKeys =
          subdatasource && subdatasource !== undefined && Object.keys(subdatasource);

        conditionList &&
          Array.isArray(conditionList) &&
          conditionList.map((condition, key) => {
            if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
              conditionList[key].value = datasource[condition.referenceKey];
            } else if (
              condition.referenceKey &&
              subdataSourceKeys &&
              subdataSourceKeys.indexOf(condition.referenceKey) > -1
            ) {
              conditionList[key].value = subdatasource[condition.referenceKey];
            }
            return {};
          });
        this.setState({
          conditionList
        });
        this._getOptionList(this.props.column, conditionList);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextPrev) {
    let siteId = localStorage.get('currentSite');
    if (nextPrev.column.hierarchy && nextPrev.column.hierarchy === true) {
      this.setState({
        optionList:
          this.props.userProfile &&
          this.props.userProfile.belongsTo &&
          this.props.userProfile.belongsTo.sites &&
          this.props.userProfile.belongsTo.sites[siteId] &&
          this.props.userProfile.belongsTo.sites[siteId].hierarchy
      });
    } else if (Array.isArray(nextPrev.column.table)) {
      if (nextPrev.column.table !== this.state.optionList) {
        this.setState({ optionList: nextPrev.column.table });
      }
    }
  }

  componentDidMount() {
    this._getOptionList(this.props.column);
  }

  _getOptionList = (tableName, conditionList) => {
    // let { conditionList } = this.state
    let siteId = localStorage.get('currentSite');
    if (tableName.hierarchy && tableName.hierarchy === true) {
      this.setState({
        optionList:
          this.props.userProfile &&
          this.props.userProfile.belongsTo &&
          this.props.userProfile.belongsTo.sites &&
          this.props.userProfile.belongsTo.sites[siteId] &&
          this.props.userProfile.belongsTo.sites[siteId].hierarchy
      });
    } else if (!tableName.hierarchy) {
      const accessToken = localStorage.get('accessToken');
      let objectHeader = {};
      if (tableName && tableName.table && Array.isArray(tableName.table)) {
        //Direct OPtions Array
        this.setState({
          optionList: tableName.table
        });
        return tableName.table;
      }
      if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
        objectHeader = {
          //URL,GET
          method: 'GET',
          url: `/api/${tableName.table.url}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
      } else if (
        tableName &&
        tableName.table &&
        tableName.table.url &&
        tableName.table.method &&
        tableName.table.payload &&
        tableName.table.method === 'POST'
      ) {
        objectHeader = {
          //URL,POST
          method: tableName.table.method,
          url: `${tableName.table.url}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            ...tableName.table.payload,
            conditionList: conditionList ? conditionList : []
          }
        };
      } else {
        //Table Name
        objectHeader = {
          method: 'POST',
          url: `/api/logbook/dropdown`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            tableName: `master."${tableName.table}"`
          }
        };
      }
      return axios(objectHeader)
        .then((response) => {
          if (Array.isArray(response.data)) {
            let optionList = response.data;
            this.setState({
              optionList,
              currentOption: optionList
            });
          }
        })
        .catch(() => {
          return [];
        });
    }
  };

  openModal = () => {
    this.setState({
      visible: true
    });
  };
  handleCancel = () => {
    let { optionList } = this.state;
    this.setState({
      visible: false,
      selectedValue: [],
      currentOption: optionList,
      current: 1
    });
  };

  render() {
    let { currentOption, selectedValue, current } = this.state;
    let { text } = this.props;
    let columns = [
      //   {
      //   title: 'Key',
      //   dataIndex: 'key',
      //   key: 'key'
      // },
      {
        title: 'Failure Code',
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type'
      },
      {
        title: 'Description',
        dataIndex: 'FailureDescription',
        key: 'FailureDescription'
      }
    ];
    return (
      <div>
        <span>
          <Input
            style={{ width: 120 }}
            size="small"
            value={text && text !== '' ? text : undefined}
          />
          <UploadOutlined
            onClick={this.openModal}
            style={{ fontSize: '20px', cursor: 'pointer', color: 'white' }}
          />
        </span>
        <Modal
          title="Select"
          visible={this.state.visible}
          onOk={undefined}
          onCancel={this.handleCancel}
        >
          {currentOption && Array.isArray(currentOption) && currentOption.length > 0 ? (
            <Table
              dataSource={currentOption}
              columns={columns}
              onRow={(record) => {
                return {
                  onClick: () => {
                    selectedValue.push(record);
                    this.setState({
                      selectedValue,
                      currentOption: record && record.child ? record.child : [],
                      current: current + 1
                    });
                    if (current === 4) {
                      this.handleCancel();
                      this.setState({
                        currentOption: this.state.optionList
                      });
                      this.props.NestedSelectOnChange(selectedValue);
                    }
                  }
                };
              }}
            />
          ) : (
            <div>No data </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default SelectNestedComponennt;
