import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  //  Popconfirm,
  Input,
  Tooltip,
  message
} from 'antd';
import { encode as base64_encode } from 'base-64';
import {
  EditOutlined,
  // DeleteOutlined,
  // QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ListView from '../../../Utils/ListView';
import history from '../../../../../commons/history';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { getTranslationCache } from '../../../../../selectors/language';
import {
  // deleteById,
  deleteEquipmentMaster,
  getUsersList,
  getMasterList
} from '../../../Utils/FormCalls';
import { StyledComponent } from '../../../CSS/style';

const urlList = {
  list: '/api/device/list',
  delete: '/api/device/delete'
};
class EquipmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || {},
      dataSource: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['DeviceCode'],
        attributeListTitle: ['Equipment Code'],

        firstTitle: 'DeviceCode',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/EquipmentDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }

  componentDidMount = async () => {
    this._getDataSource();
  };

  componentDidUpdate(prevprops) {
    if (
      this.props.userProfile !== prevprops.userProfile ||
      this.props.translationCache !== prevprops.translationCache
    ) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }

  _editRecord = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  _getDataSource = async () => {
    let url = '/api/device/list';
    let datasource = await getMasterList(url);
    this.setState({
      dataSource: datasource
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, 'Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  // _deleteById = async (id) => {
  //   let payload = {
  //     url: `/api/masterAssetDetails/delete?Id=${id}`,
  //     method: 'POST'
  //   };
  //   await deleteById(payload);
  //   let dataSource = await this._getDataSource();
  //   this.setState({
  //     dataSource
  //   });
  // };
  _deleteById = async (id) => {
    let deleteResp = await deleteEquipmentMaster(urlList.delete, id);
    if (deleteResp && deleteResp.message) {
      message.success(deleteResp.message);
      let dataSource = await getMasterList(urlList.list);
      this.setState({
        dataSource,
        activeKey: '1'
      });
    }
  };
  reset = async () => {
    await getMasterList(urlList.list);
  };
  render() {
    let { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.equipment),
        dataIndex: 'DeviceCode',
        key: 'DeviceCode',
        filter: true,
        ...this.getColumnSearchProps('DeviceCode')
      },
      {
        title: translation(translationCache, constant.equipmentDescription),
        dataIndex: 'Name',
        key: 'Name',
        filter: true,
        ...this.getColumnSearchProps('Name')
      },
      // {
      //   title: translation(translationCache, constant.DisplayCode),
      //   dataIndex: 'DisplayCode',
      //   key: 'DisplayCode',
      //   filter: true,
      //   ...this.getColumnSearchProps('DeviceCode')
      // },
      {
        title: translation(translationCache, constant.location),
        dataIndex: 'Asset',
        key: 'Asset'
        // ...this.getColumnSearchProps('Asset')
      },
      {
        title: translation(translationCache, constant.locationDescription),
        dataIndex: 'AssetDescription',
        key: 'AssetDescription',
        filter: true
      },
      {
        title: translation(translationCache, constant.equipmentCategory),
        dataIndex: 'DeviceType',
        key: 'DeviceType',
        filter: true,
        ...this.getColumnSearchProps('DeviceType')
      },

      {
        title: translation(translationCache, constant.equipmentCategoryDescription),
        dataIndex: 'DeviceTypeDescription',
        key: 'DeviceTypeDescription',
        filter: true
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              disabled={record.Status === 'Inactive' ? true : false}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this._editRecord(record)}
              size="middle"
            >
              {' '}
              <Tooltip title={translation(translationCache, constant.Edit)}>
                <EditOutlined />
              </Tooltip>
            </Button>
            {/* <Popconfirm
              title={translation(
                translationCache,
                constant.areYouSureThatYouWantToDeleteTheEquipment
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  <Tooltip title={translation(translationCache, constant.Delete)}>
                    {' '}
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm> */}
          </>
        )
      }
    ];

    let config = {
      title: translation(translationCache, constant.EQUIPMENT_MASTER),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['DeviceCode'],
      attributeListTitle: ['Equipment Name'],

      firstTitle: 'DeviceCode',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit'],
      // actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/EquipmentDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };

    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EquipmentList);
