import React, { Fragment } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Table,
  message,
  TreeSelect,
  Upload,
  Popconfirm,
  Input
} from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import localStorage from '../../../utils/localStorage';
import { getUploadList, deleteDoc } from './ApiCall';
import { getDeviceType, getHierarchyLevel } from '../EquipmentSelection/ApiCalls';
import { StyledApplication1 } from '../style';
import '../EquipmentSelection/style.css';

const { Option } = Select;
const { Dragger } = Upload;
const { TreeNode } = TreeSelect;
class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeList: [],
      deviceType: undefined,
      deviceList: [],
      deviceCode: undefined,
      hierarchy: [],
      Description: undefined,
      DeviceId: undefined,

      fileList: []
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    try {
      let deviceType = await getDeviceType();

      this.setState({
        deviceTypeList: deviceType.data
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  handelDeviceType = async (value) => {
    this.setState({ deviceType: value });
    try {
      let deviceList = await getHierarchyLevel(value);
      this.setState({
        deviceList: deviceList.data,
        DeviceId: undefined,
        deviceCode: undefined,
        hierarchy: []
      });
    } catch (error) {
      return {};
    }
  };
  handleDevice = async (value) => {
    this.setState({ deviceCode: value });
    try {
      let hierarchyList = await getHierarchyLevel(this.state.deviceType, value);
      this.setState({ hierarchy: hierarchyList.data, DeviceId: undefined });
    } catch (error) {
      return {};
    }
  };
  handleFile = async (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      try {
        let uploadList = await getUploadList(this.state.DeviceId);
        this.setState({ fileList: uploadList.data, Description: undefined });
      } catch (error) {
        console.log(error);
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  onTreeSelect = async (DeviceId) => {
    this.setState({ DeviceId });
    try {
      let uploadList = await getUploadList(DeviceId);
      this.setState({ fileList: uploadList.data });
    } catch (error) {
      console.log(error);
    }
  };
  renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode value={item.DeviceId} title={item.Name} key={item.Id} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode value={item.DeviceId} key={item.Id} title={item.Name} {...item} />;
    });
  onDescChange = (e) => {
    this.setState({ Description: e.target.value });
    if (e.target.value.length === 0) {
      this.setState({ Description: undefined });
    }
  };
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isPNGorJPEG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isPNGorJPEG) {
      message.error('You can only upload Images of png or jpeg file!');
    }
    if (!isLt2M) {
      message.error('Image file must be smaller than 10MB!');
    }
    return isLt2M && isPNGorJPEG;
  };
  beforePdfUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isPNGorJPEG = file.type === 'application/pdf';
    if (!isPNGorJPEG) {
      message.error('You can only upload PDF !');
    }
    if (!isLt2M) {
      message.error('Image file must be smaller than 10MB!');
    }
    return isLt2M && isPNGorJPEG;
  };

  handleChange = () => {};
  render() {
    let { deviceType, deviceTypeList, deviceList, deviceCode, hierarchy, DeviceId, Description } =
      this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const columns = [
      {
        title: 'File Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'File Description',
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: 'File Type',
        dataIndex: 'Type',
        key: 'Type'
      },
      {
        title: 'Delete',
        key: 'Delete',
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title="Are you sure that you want to delete this File?"
              onConfirm={async (e) => {
                e.stopPropagation();
                await deleteDoc(record);
                let data = await getUploadList(DeviceId);
                this.setState({ fileList: data.data });

                //  this.handleSupplierDelete(record)
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        )
      }
    ];

    return (
      <StyledApplication1 style={{ minHeight: window.innerHeight - 170, background: '#eff1f3' }}>
        <Fragment>
          <Form className="headerDiv" layout="vertical">
            <Row>
              <Col span={5} offset={1}>
                <Form.Item label="Select Equipment Category">
                  <Select
                    placeholder="Select Equipment Category"
                    style={{ width: '300px' }}
                    value={deviceType}
                    onChange={this.handelDeviceType}
                  >
                    {deviceTypeList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select Primary Equipment">
                  <Select
                    placeholder="Select Equipment"
                    style={{ width: '300px' }}
                    value={deviceCode}
                    onChange={this.handleDevice}
                  >
                    {deviceList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Code}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select Equipment">
                  <TreeSelect
                    showSearch
                    style={{ width: '300px' }}
                    value={DeviceId}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Select Equipment Hierarchy"
                    allowClear
                    treeDefaultExpandAll={true}
                    placement={'topLeft'}
                    onChange={this.onTreeSelect}
                  >
                    hierarchy && Array.isArray(hierarchy) && hierarchy.length != 0 ?{' '}
                    {this.renderTreeNodes(hierarchy)} : null
                  </TreeSelect>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {DeviceId !== undefined ? (
            <div>
              <Form className="headerDiv" layout="vertical">
                <Row>
                  <Col span={5} offset={1}>
                    <Form.Item
                      label="Description For Attachment"
                      rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                      <Input
                        placeholder="Description"
                        type="text"
                        value={Description}
                        onChange={this.onDescChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col span={10} offset={1}>
                  <Dragger
                    disabled={Description === undefined ? true : false}
                    className="upload-list-inline"
                    name="file"
                    action={`/api/equipmentDocs/create?DeviceId=${DeviceId}&Type=image&SiteId=${siteId}&Description=${Description}`}
                    multiple="false"
                    headers={{ Authorization: Authorization }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleFile}
                    accept="image/*"
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload Equipment Image{' '}
                    </p>
                    <p className="ant-upload-hint">1. Your can only upload .PNG/.JPEG file</p>
                    <p className="ant-upload-hint">2. File should be less than 10MB</p>
                    <p className="ant-upload-hint">3.Description is mandatory for Upload</p>
                  </Dragger>
                </Col>
                <Col span={10} offset={1}>
                  <Dragger
                    disabled={Description === undefined ? true : false}
                    className="upload-list-inline"
                    name="file"
                    action={`/api/equipmentDocs/create?DeviceId=${DeviceId}&Type=pdf&SiteId=${siteId}&Description=${Description}`}
                    multiple="false"
                    headers={{ Authorization: Authorization }}
                    beforeUpload={this.beforePdfUpload}
                    onChange={this.handleFile}
                    accept=".pdf"
                    showUploadList={false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload Equipment Documents
                    </p>
                    <p className="ant-upload-hint">1. Your can only upload .PDF file</p>
                    <p className="ant-upload-hint">2. File should be less than 10MB</p>
                    <p className="ant-upload-hint">3.Description is mandatory for Upload</p>
                    {/* <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibit from uploading company data or other
      band files
    </p> */}
                  </Dragger>
                </Col>
              </Row>
              <Row style={{ marginTop: '2%' }}>
                <Col span={21} offset={1}>
                  {' '}
                  <Table columns={columns} dataSource={this.state.fileList} pagination={false} />
                </Col>
              </Row>
            </div>
          ) : null}
        </Fragment>
      </StyledApplication1>
    );
  }
}

//   const mapStateToProps = createStructuredSelector({
//     translationCache: getTranslationCache(),
//   });connect(mapStateToProps)

export default FileUpload;
