/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Task
 */
import React, { Component } from 'react';
import moment from 'moment';
import {
  Button,
  Input,
  Popconfirm,
  Tooltip,
  Divider,
  Space,
  Card,
  Pagination,
  Select,
  Empty,
  Table
} from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  AuditOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  PlusOutlined
} from '@ant-design/icons';

import history from '../../../commons/history';
import { getUserProfile } from '../../../selectors/layout';
import { StyledDashboard } from '../../Settings/Masters/styles';
import {
  getUsecaseList,
  deleteUsecase,
  getUserListData,
  getSitesList
} from '../APICalls/FormCalls';
import { lightTheme, StyledTable } from './../CSS/style';
import './../CSS/style.css';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      dataSource: [],
      activeKey: '1',
      form: {},
      ellipsis: true,
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      searchField: '',
      current: 1,
      pageSize: 10,
      filterKey: null,
      viewType: 'card',
      usernameFiltered: '',

      SiteList: [],
      selectedSortKey: '',
      searchKey: '',
      FirstName: ''
    };
  }

  _getUserList = async () => {
    let userList = await getUserListData();
    this.setState({ userList });
  };

  componentDidMount = async () => {
    this._getAdhocDataSource();
    this._getUserList();
    let SiteList = await getSitesList();
    this.setState({ SiteList: SiteList });
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }

  _getAdhocDataSource = async () => {
    let dataSource = await getUsecaseList('both');
    this.setState({
      dataSource,
      listData: dataSource,
      activeKey: '1',
      form: {},
      searchField: ''
    });
  };

  filterByValue = (value) => {
    let { listData, filterKey } = this.state;
    let SearchedDate = null;
    const formats = ['YYYY-MM-DD', 'MM-DD-YYYY', 'YYYY-MM-DD'];

    //  const siteName = SiteList.filter((e) => {
    //    return e.Name === value;
    //  });
    if (filterKey === 'UserId') {
      return listData.filter((o) => {
        return o.FirstName.toString().toLowerCase().includes(value.toString().toLowerCase());
      });
    } else if (filterKey === 'SiteId') {
      return listData.filter((o) => {
        return o.Name.toString().toLowerCase().includes(value.toString().toLowerCase());
      });
    } else if (filterKey === 'CreatedDate') {
      for (const format of formats) {
        const momentObj = moment(value, format, true);
        if (momentObj.isValid()) {
          SearchedDate = momentObj.toDate();
          break;
        }
      }
      if (SearchedDate !== null) {
        const cretaeddate = listData.filter((o) => {
          const date = new Date(o.CreatedDate);
          return (
            date.getDate() === SearchedDate.getDate() &&
            date.getMonth() === SearchedDate.getMonth() &&
            date.getFullYear() === SearchedDate.getFullYear()
          );
          // return o[filterKey].toString().toLowerCase().includes(DayMonthYear.toString().toLowerCase());
        });
        return cretaeddate;
      }
    } else {
      return listData.filter((o) => {
        return o[filterKey].toString().toLowerCase().includes(value.toString().toLowerCase());
      });
    }
  };

  onSearch = (value) => {
    let dataSource = this.filterByValue(value);
    this.setState({ dataSource });
  };

  sortDate = (e) => {
    const { listData, selectedSortKey, userList } = this.state;

    if (selectedSortKey === 'CreatedDate') {
      if (e === 'ascending') {
        listData.sort((a, b) => a.CreatedDate.localeCompare(b.CreatedDate));
        this.setState({ dataSource: listData });
      } else if (e === 'descending') {
        listData.sort((a, b) => b.CreatedDate.localeCompare(a.CreatedDate));
        this.setState({ dataSource: listData });
      }
    } else if (selectedSortKey === 'CreatedBy') {
      const newArray = listData.map((Obj) => {
        const matchingObj = userList.find((item) => item.Id === Obj.UserId);
        if (matchingObj) {
          return { ...Obj, FirstName: matchingObj.FirstName };
        } else {
          return Obj;
        }
      });
      const filtredListData = newArray.sort(function (a, b) {
        return a.FirstName.localeCompare(b.FirstName);
      });
      this.setState({ dataSource: filtredListData });
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  deleteUsecase = async (id) => {
    await deleteUsecase(id);
    let dataSource = await getUsecaseList();
    this.setState({
      dataSource,
      activeKey: '1'
    });
  };

  editUsecase = async (id) => {
    history.push({ pathname: `/rubus/AnalyticsDetails/${id}` });
  };

  add3Dots = (text, limit) => {
    let dots = '...';
    if (text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };

  onPaginationChange = (current, pageSize) => {
    this.setState({ current, pageSize });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onViewTypeChange = (viewType) => {
    this.setState({ viewType });
  };
  onhandleChange = (e) => {
    const { listData, userList, SiteList } = this.state;
    this.setState({ filterKey: e, listData });
    if (e === 'UserId') {
      const newArray = listData.map((obj1) => {
        const obj2 = userList.find((obj2) => obj2.Id === obj1.UserId);
        return {
          ...obj1,
          FirstName: obj2.FirstName
        };
      });
      this.setState({ listData: newArray });
    } else if (e === 'SiteId') {
      const siteArray = listData.map((obj1) => {
        const obj2 = SiteList.find((obj2) => obj2.Id === obj1.SiteId);
        return {
          ...obj1,
          Name: obj2.Name
        };
      });
      this.setState({ listData: siteArray });
    } else {
      this.setState({ listData });
    }
  };

  render() {
    const { dataSource, userList, current, pageSize, viewType, selectedSortKey, FirstName } =
      this.state;
    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );
    let columns = [
      {
        title: 'Usecase',
        dataIndex: 'UseCaseName',
        key: 'UseCaseName',
        align: 'center',
        width: '40%'
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        align: 'center',
        width: '40%'
      },
      {
        title: 'Action',
        // width: '600',
        align: 'center',
        render: (text, record) => (
          <>
            <Button
              // style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.editUsecase(record.Id)}
              size="middle"
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure that you want to delete the Usecase"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteUsecase(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];

    const data =
      dataSource !== undefined &&
      dataSource &&
      Array.isArray(dataSource) &&
      dataSource.map((i) => ({
        title: i.Title,
        avatar: (
          <AuditOutlined
            style={{
              backgroundColor: '#fff',
              color: '#1a4c7e',
              fontSize: '21px',
              padding: '6px'
            }}
          />
        ),
        content: i.UseCaseName,
        Id: i.Id,
        UserId: i.UserId,
        CreatedDate: i.CreatedDate
      }));

    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 143 }}>
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={'selectFilter'} style={{ padding: '5px' }}>
              <Select
                className={'selectFilter'}
                showSearch
                placeholder="Select Attribute"
                allowClear
                value={this.state.filterKey}
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                onChange={(e) => this.onhandleChange(e)}
              >
                <Select.Option key="Title">Title</Select.Option>
                <Select.Option key="Description">Description</Select.Option>
                <Select.Option key="SiteId">Site</Select.Option>
                <Select.Option key="UserId">CreatedBy</Select.Option>
                <Select.Option key="CreatedDate">CreatedDate</Select.Option>
              </Select>
              <Input
                type="text"
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  color: 'white',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                id="searchText"
                value={this.state.searchField}
                onChange={(e) => {
                  this.setState({ searchField: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.onSearch(this.state.searchField);
                  }
                }}
                placeholder="Search"
              />
              <Select
                className={'selectFilter'}
                showSearch
                placeholder="Sort Attribute "
                allowClear
                // value={this.state.selectedSortKey}
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                onChange={(e) => {
                  this.setState({ selectedSortKey: e });
                }}
              >
                <Select.Option key="CreatedBy">CreatedBy</Select.Option>
                <Select.Option key="CreatedDate">CreatedDate</Select.Option>
              </Select>
              <Select
                className={'selectFilter'}
                showSearch
                placeholder="Select Sort Attribute "
                allowClear
                // value={this.state.selectedSortKey}
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                onChange={this.sortDate}
              >
                {selectedSortKey === 'CreatedDate' ? (
                  <>
                    <Select.Option key="ascending">Ascending</Select.Option>
                    <Select.Option key="descending">Descending</Select.Option>
                  </>
                ) : (
                  <Select.Option key="Alpa">sort</Select.Option>
                )}
              </Select>
            </div>

            <div style={{ marginTop: '9px' }}>
              <PlusOutlined
                onClick={() => history.push({ pathname: `/rubus/AnalyticsDetails/new` })}
                style={{
                  fontSize: '25px',
                  color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                  marginLeft: '10px'
                }}
              />
              <UnorderedListOutlined
                onClick={() => this.onViewTypeChange('table')}
                style={{
                  fontSize: '25px',
                  color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                  marginLeft: '10px'
                }}
              />
              <AppstoreOutlined
                onClick={() => this.onViewTypeChange('card')}
                style={{
                  fontSize: '25px',
                  color: viewType === 'card' ? 'rgb(255, 108, 0)' : '#dfa230',
                  marginLeft: '10px'
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            {viewType === 'card' ? (
              <div>
                {data && Array.isArray(data) && data.length !== 0 ? (
                  <div className="ListDataView" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {data &&
                      Array.isArray(data) &&
                      data
                        .filter(
                          (e, index) =>
                            index >= pageSize * current - pageSize && index < pageSize * current
                        )
                        .map((useCase) => {
                          let userName =
                            userList && Array.isArray(userList)
                              ? userList.filter((e) => e.Id === useCase.UserId)
                              : '';
                          return (
                            <Card
                              key={useCase.title}
                              style={{ width: '410px', padding: '5px', margin: '5px' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div
                                  style={{
                                    padding: '0px 0px 6px 0px',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: '#d98e04'
                                  }}
                                >
                                  {this.add3Dots(useCase.title, 35)}
                                </div>

                                <div style={{ margin: '0px 0px 7px 0px' }}>
                                  <IconText
                                    icon={UserOutlined}
                                    text={
                                      (userName && userName[0] && userName[0].FirstName
                                        ? userName[0].FirstName
                                        : '') || (FirstName && FirstName ? FirstName : '')
                                    }
                                    key="list-vertical-star-o"
                                  />
                                </div>
                                <div style={{ margin: '0px 0px 7px 0px' }}>
                                  <IconText
                                    icon={CalendarOutlined}
                                    text={moment(useCase.CreatedDate).format('YYYY-MM-DD HH:mm:ss')}
                                    key="list-vertical-like-o"
                                  />
                                </div>
                              </div>
                              <Divider
                                className="customDividerHor"
                                style={{ borderTop: '1px solid #000', height: '0px' }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: '0px 0px',
                                  fontSize: '18px'
                                }}
                              >
                                <Tooltip placement="top" key="Edit" title="Edit">
                                  <EditOutlined
                                    style={{ color: '#d98e04', marginRight: '8px' }}
                                    onClick={() => this.editUsecase(useCase.Id)}
                                  />
                                </Tooltip>
                                <Divider type="vertical" />
                                <Popconfirm
                                  key="1"
                                  title="Are you sure that you want to delete the Usecase"
                                  icon={<QuestionCircleOutlined style={{ color: '#d98e04' }} />}
                                  onConfirm={() => this.deleteUsecase(useCase.Id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip placement="top" key="Delete" title="Delete">
                                    <DeleteOutlined
                                      style={{ color: '#d98e04', marginRight: '8px' }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </div>
                            </Card>
                          );
                        })}
                  </div>
                ) : (
                  <Empty style={{ color: 'white' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {data && Array.isArray(data) && data.length !== 0 && data.length ? (
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '14px' }}>
                    <Pagination
                      current={current}
                      onChange={this.onPaginationChange}
                      total={data.length}
                      pageSize={10}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <StyledTable theme={lightTheme}>
                <Table
                  dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                  columns={columns}
                />
              </StyledTable>
            )}
          </div>
        </>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(Analytics);
