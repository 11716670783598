import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Highlighter from 'react-highlight-words';
import { Form, Table, message, Button, Drawer, Input, Col, Row, Select } from 'antd';

import localStorage from '../../../utils/localStorage';
import { AddTableButton, DrawerFooter } from './styles';
import { getTranslationCache } from '../.././../selectors/language';
import { constant } from '../Constants';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const FormItem = Form.Item;

class WarningLimits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataSource: [],
      editObject: {},
      searchText: '',
      translationCache: props.translationCache || [],
      language: props.language || 'English',
      deviceList: [],
      parameterList: []
    };
  }
  myRef = React.createRef();

  componentDidMount() {
    this._getTranslationMasterData();
    this._getDeviceList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getDeviceList = () => {
    const SiteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let changePassword = {
      method: 'GET',
      url: `/api/device/get?SiteId=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(changePassword)
      .then((response) => {
        if (response.data) {
          this.setState({ deviceList: response.data });
        }
      })
      .catch((error) => {
        message.error(error);
        this.setState({ deviceList: [] });
      });
  };

  _getParameterListByDevice = (DeviceCode) => {
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let changePassword = {
      method: 'POST',
      url: `/api/deviceParameter/deviceParameters?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      },
      data: {
        DeviceCode: [[DeviceCode]],
        SiteId: siteId
      }
    };
    axios(changePassword)
      .then((response) => {
        if (response.data && response.data.Operational) {
          this.setState({ parameterList: response.data.Operational });
        }
      })
      .catch(() => {
        message.error('Error');
        this.setState({ parameterList: [] });
      });
  };

  _getTranslationMasterData = () => {
    const SiteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let changePassword = {
      method: 'GET',
      url: `/api/WarningLimits/get?SiteId=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(changePassword)
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          this.setState({ dataSource: response.data });
        }
      })
      .catch(() => {
        message.error('Error loading list');
        // this.setState({ dataSource: [] });
      });
  };

  showDrawer = (e, record, type) => {
    e.preventDefault(e);
    if (type === 'edit') {
      this._getParameterListByDevice(record.DeviceCode);
    } else {
      record = {
        ...record,
        data: [
          {
            Warning: 'Warning-1',
            Type: 'HIGH',
            LowerLimit: '',
            UpperLimit: ''
          },
          {
            Warning: 'Warning-1',
            Type: 'LOW',
            LowerLimit: '',
            UpperLimit: ''
          },
          {
            Warning: 'Warning-2',
            Type: 'HIGH',
            LowerLimit: '',
            UpperLimit: ''
          },
          {
            Warning: 'Warning-2',
            Type: 'LOW',
            LowerLimit: '',
            UpperLimit: ''
          },
          {
            Warning: 'Warning-3',
            Type: 'HIGH',
            LowerLimit: '',
            UpperLimit: ''
          },
          {
            Warning: 'Warning-3',
            Type: 'LOW',
            LowerLimit: '',
            UpperLimit: ''
          }
        ]
      };
      // if (this.myRef.current) this.myRef.current.setFieldsValue({ DeviceCode: record.DeviceCode });
    }
    if (this.myRef.current)
      this.myRef.current.setFieldsValue({
        DeviceCode: record.DeviceCode,
        ParameterDescription: record.ParameterDescription
      });
    this.setState({ editObject: record, visible: true });
  };

  onClose = () => {
    this.setState({ editObject: {}, visible: false });
  };

  saveWarning = () => {
    let { editObject, parameterList } = this.state;
    this.myRef.current.validateFields().then(async (values) => {
      let filteredParameter =
        parameterList &&
        Array.isArray(parameterList) &&
        parameterList.filter((parameter) => {
          return parameter.ParameterCode === values.ParameterDescription;
        });
      let saveWarnings = {
        ...this.state.editObject,
        DeviceCode: values.DeviceCode,
        ParameterCode:
          filteredParameter && filteredParameter[0] && filteredParameter[0].ParameterCode
            ? filteredParameter[0].ParameterCode
            : editObject.ParameterCode,
        ParameterDescription:
          filteredParameter && filteredParameter[0] && filteredParameter[0].ParameterDescription
            ? filteredParameter[0].ParameterDescription
            : editObject.ParameterDescription,
        TagId:
          filteredParameter && filteredParameter[0] && filteredParameter[0].TagId
            ? filteredParameter[0].TagId
            : editObject.TagId
      };
      this._curdWarnings(saveWarnings, 'create');
      if (this.myRef.current) this.myRef.current.resetFields();
      this.setState({ visible: false });
    });
  };

  updateWarning = () => {
    let { editObject, parameterList } = this.state;
    this.myRef.current.validateFields().then(async (values) => {
      let filteredParameter =
        parameterList &&
        Array.isArray(parameterList) &&
        parameterList.filter((parameter) => {
          return parameter.ParameterCode === values.ParameterDescription;
        });
      let updateWarnings = {
        ...this.state.editObject,
        DeviceCode: values.DeviceCode,
        ParameterCode:
          filteredParameter && filteredParameter[0] && filteredParameter[0].ParameterCode
            ? filteredParameter[0].ParameterCode
            : editObject.ParameterCode,
        ParameterDescription:
          filteredParameter && filteredParameter[0] && filteredParameter[0].ParameterDescription
            ? filteredParameter[0].ParameterDescription
            : editObject.ParameterDescription,
        TagId:
          filteredParameter && filteredParameter[0] && filteredParameter[0].TagId
            ? filteredParameter[0].TagId
            : editObject.TagId,
        Id: editObject.Id,
        CreatedBy: '1'
      };
      this._curdWarnings(updateWarnings, 'updateWarning');
      if (this.myRef.current) this.myRef.current.resetFields();
      this.setState({ visible: false });
    });
  };
  _curdWarnings = (saveObject, curdOperation) => {
    const SiteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      method: 'POST',
      url: `/api/WarningLimits/${curdOperation}?SiteId=${SiteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ...saveObject,
        SiteId
      }
    };
    axios(payload)
      .then((response) => {
        if (response.status && response.status === 200) {
          message.success(response.data.message);
        }
        this._getTranslationMasterData();
      })
      .catch(() => {});
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  deviceOnChange = async (deviceCode) => {
    let parameterList = await this._getParameterListByDevice(deviceCode);
    this.setState({
      parameterList
    });
  };

  handleFieldChange = (e, parameter, index) => {
    let { editObject } = this.state;
    let arrayUpdated = this.updateArray(editObject.data, index, (item) => ({
      ...item,
      [parameter]: e
    }));
    editObject = {
      ...editObject,
      data: arrayUpdated
    };
    this.setState({ editObject });
  };

  updateArray = (array, index, updateFn) => {
    return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { dataSource, editObject, deviceList, parameterList } = this.state;
    console.log('editObject', editObject, parameterList);
    const columns = [
      {
        title: this.translation(constant.Device),
        dataIndex: 'DeviceName',
        key: 'DeviceName',
        width: '25%',
        ...this.getColumnSearchProps('DeviceName')
      },
      {
        title: this.translation(constant.Parameter),
        dataIndex: 'ParameterDescription',
        width: '25%',
        key: 'ParameterDescription',
        ...this.getColumnSearchProps('ParameterDescription')
      },
      {
        title: this.translation(constant.Edit),
        dataIndex: '',
        key: '',
        width: '25%',
        render: (text, record) => (
          <div>
            <button
              type="button"
              className="ant-btn"
              onClick={(e) => this.showDrawer(e, record, 'edit')}
            >
              <EditOutlined />
            </button>
          </div>
        )
      }
    ];
    return (
      <div>
        <AddTableButton type="primary" onClick={(e) => this.showDrawer(e, {}, 'new')}>
          {this.translation(constant.AddWarningLimits)}
        </AddTableButton>
        <Drawer
          title={
            !editObject.DeviceCode
              ? this.translation(constant.Create_Warning_Limits)
              : this.translation(constant.Update_Warning_Limits)
          }
          placement="right"
          closable={true}
          width={800}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form
            layout="vertical"
            initialValues={editObject}
            onFinish={this.handleSubmit}
            ref={this.myRef}
          >
            <Row>
              <Col span={24}>
                <FormItem
                  name="DeviceCode"
                  label={this.translation(constant.Device)}
                  rules={[{ required: true, message: 'Equipment is required' }]}
                >
                  <Select
                    placeholder="Select Equipment"
                    onSelect={(value) => this.deviceOnChange(value)}
                    disabled={editObject.DeviceCode ? true : false}
                  >
                    {deviceList &&
                      deviceList.map((item) => {
                        return (
                          <Option key={item.Id} value={item.DeviceCode}>
                            {item.Name}
                          </Option>
                        );
                      })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem
                  name="ParameterDescription"
                  label={this.translation(constant.Parameter)}
                  rules={[{ required: true, message: 'Parameter is required' }]}
                >
                  <Select
                    placeholder="Select Parameter"
                    disabled={editObject.DeviceCode ? true : false}
                  >
                    {parameterList &&
                      parameterList.map((item) => {
                        return (
                          <Option key={item.Id} value={item.ParameterCode}>
                            {item.ParameterDescription}({item.ParameterCode})
                          </Option>
                        );
                      })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Form.Item>
                {editObject &&
                  editObject.data &&
                  editObject.data.map((k, index) => {
                    return (
                      <Row key={index}>
                        <Col span={5}>
                          <Form.Item
                            name={[index, 'Warning']}
                            label={index === 0 ? this.translation(constant.Warning) : ''}
                            key={k.Warning}
                            rules={[{ required: true, message: 'Warning is required' }]}
                          >
                            <Input
                              defaultValue={k.Warning}
                              style={{ width: '90%' }}
                              placeholder="Input Warning"
                              onBlur={(e) => {
                                this.handleFieldChange(e.target.value, 'Warning', index);
                              }}
                              value={k.Warning}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            name={[index, 'Type']}
                            label={index === 0 ? this.translation(constant.Type) : ''}
                            key={k.Type}
                            rules={[{ required: true, message: 'Type is required' }]}
                          >
                            <Input
                              defaultValue={k.Type}
                              style={{ width: '90%' }}
                              placeholder="Input Type"
                              onBlur={(e) => {
                                this.handleFieldChange(e.target.value, 'Type', index);
                              }}
                              value={k.Type}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            name={[index, 'LowerLimit']}
                            label={index === 0 ? this.translation(constant.LowerLimit) : ''}
                            key={k}
                            rules={[{ required: true, message: 'LowerLimit is required' }]}
                          >
                            <div>
                              <Input
                                defaultValue={k.LowerLimit}
                                value={k.LowerLimit}
                                style={{ width: '90%' }}
                                placeholder="Input LowerLimit"
                                onChange={(e) => {
                                  this.handleFieldChange(e.target.value, 'LowerLimit', index);
                                }}
                              />
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item
                            name={[index, 'UpperLimit']}
                            label={index === 0 ? this.translation(constant.UpperLimit) : ''}
                            key={k}
                            rules={[{ required: true, message: 'UpperLimit is required' }]}
                          >
                            <div>
                              <Input
                                defaultValue={k.UpperLimit}
                                value={k.UpperLimit}
                                style={{ width: '90%' }}
                                placeholder="Input UpperLimit"
                                onChange={(e) => {
                                  this.handleFieldChange(e.target.value, 'UpperLimit', index);
                                }}
                              />
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
              </Form.Item>
            </Row>
            <DrawerFooter>
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                {this.translation(constant.Cancel)}
              </Button>
              {!editObject.DeviceCode ? (
                <Button type="primary" onClick={this.saveWarning}>
                  {this.translation(constant.Create)}
                </Button>
              ) : (
                <Button type="primary" onClick={this.updateWarning}>
                  {this.translation(constant.Update)}
                </Button>
              )}
            </DrawerFooter>
          </Form>
        </Drawer>
        <Table columns={columns} dataSource={dataSource} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WarningLimits);
