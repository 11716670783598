/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Task
 */
import React, { Component } from 'react';
import {} from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

import { getById } from '../APICalls/FormCalls';
import history from '../../../commons/history';
import { getUserProfile } from '../../../selectors/layout';
import { StyledDashboard } from '../../Settings/Masters/styles';
import './../CSS/style.css';
import PredictionForm from './PredictionForm';

class AnalyticsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { AnalysisTasks: [] },
      isEditable: ''
    };
  }

  componentDidMount() {
    this.getUseCaseById(this.props.match.params.Id);
  }

  getUseCaseById = async (Id) => {
    let response = await getById(Id);
    if (response && response.AnalysisTasks && response.AnalysisTasks !== undefined) {
      response.AnalysisTasks.map((item, i) => {
        return _.cloneDeepWith(response, (value) => {
          return value && value !== null && value.AnalysisTasks
            ? {
                ...(value && value.AnalysisTasks),
                ...(value.AnalysisTasks[i]['Date'] = [
                  moment.utc(value.AnalysisTasks[i]['Date'][0], 'YYYY-MM-DD HH:mm:ss'),
                  moment.utc(value.AnalysisTasks[i]['Date'][1], 'YYYY-MM-DD HH:mm:ss')
                ])
              }
            : _.noop();
        });
      });
    } else {
      this.setState({ form: response, isEditable: this.props.match.params.Id });
    }
    if (response && response.PredictionTasks && response.PredictionTasks !== undefined) {
      response.PredictionTasks.map((item, i) => {
        return _.cloneDeepWith(response, (value) => {
          return value && value !== null && value.PredictionTasks && value.PredictionTasks
            ? {
                ...(value && value.PredictionTasks && value.PredictionTasks),
                ...(value.PredictionTasks[i]['Date'] = [
                  moment.utc(value.PredictionTasks[i]['Date'][0], 'YYYY-MM-DD HH:mm:ss'),
                  moment.utc(value.PredictionTasks[i]['Date'][1], 'YYYY-MM-DD HH:mm:ss')
                ])
              }
            : _.noop();
        });
      });
    } else {
      this.setState({ form: response, isEditable: this.props.match.params.Id });
    }
    this.setState({ form: response, isEditable: this.props.match.params.Id });
  };

  _getAdhocDataSource = async () => {
    this.setState({
      form: {}
    });
    history.push('/rubus/Analytics');
  };

  render() {
    let { form, isEditable } = this.state;
    return (
      <StyledDashboard className="EAMFormDetails" style={{ minHeight: window.innerHeight - 143 }}>
        <PredictionForm
          formObject={form}
          _getAdhocDataSource={this._getAdhocDataSource}
          isEditable={isEditable}
        />
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(AnalyticsDetails);
