import React, { Component } from 'react';

class NotificationAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>Notification Alerts</div>;
  }
}

export default NotificationAlerts;
