import React, { Component } from 'react';
import axios from 'axios';
import { flattenDeep } from 'lodash';
import moment from 'moment';
import { Form, message, Select, Button, Divider, Space, Row, Col, Input, Tree } from 'antd';
import {
  getSitesList,
  getLocationByAsset,
  submitMaster,
  getDeviceList,
  questionsListMaster,
  getinspectionAreaList,
  getinspectionItemList,
  getAnswersList,
  getWorkPlanningList,
  saveQuestionData,
  saveAreaData,
  saveItemData,
  getEquipmentRecord,
  // getMasterById,
  getHierarchyList,
  getEquipmentHierarchy,
  getInspectionData
} from '../.././../Utils/FormCalls';
import Highlighter from 'react-highlight-words';
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import TableSelect from '../../../Widgets/TableSelect';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { constant } from '../../../Utils/constants';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import {
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal,
  StyledTree
} from '../../../CSS/style';
// import history from '../../../../../commons/history';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { PlusOutlined } from '@ant-design/icons';
import InputComponent from '../../../Widgets/InputComponent';
import localStorage from '../../../../../utils/localStorage';
//  import localStorage from '../../../../utils/localStorage';
const { Option } = Select;
const type = 'Item';
const urlList = {
  insert: '/api/inspectionQuestions/insert',
  delete: 'api/masterItems/getById?Id=3',
  getbyId: '/api/inspectionQuestions/getById'
};
const fileData = new FormData();
class InspectionMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      answersList: [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 2,
          name: 'No'
        }
      ],
      translationCache: props.translationCache || [],
      visible: false,
      deviceList: [],
      questionList: [],
      inspectionAreaList: [],
      inspectionItemList: [],
      status: 'false',
      AnswerList: [],
      WorkPlanList: [],
      open: 'false',
      viewQuestionModal: false,
      ViewModal: false,
      viewAreaModal: false,
      viewItemModal: false,
      EquipmentRecord: [],
      getById: '',
      answerKey: [],
      hierarchyList: [],
      formObjectId: ''
    };
    this.keys = this.getAllKeys(this.state.treeData);
  }

  QuestionRef = React.createRef();
  deviceRef = React.createRef();
  areaRef = React.createRef();
  ItemRef = React.createRef();
  optionRef = React.createRef();
  componentDidMount = async () => {
    try {
      this.getEquipmentHierarchy();
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.QuestionRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: sitesList && sitesList[0] && sitesList[0].Id ? sitesList[0].Id : ''
      };
      this.QuestionRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let AnswerList = await getAnswersList();
      this.setState({ AnswerList: AnswerList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let WorkPlanList = await getWorkPlanningList();
      this.setState({ WorkPlanList: WorkPlanList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let questionList = await questionsListMaster();
      this.setState({ questionList: questionList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let inspectionAreaList = await getinspectionAreaList();
      this.setState({ inspectionAreaList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let inspectionItemList = await getinspectionItemList();
      this.setState({ inspectionItemList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.QuestionRef.current) {
        this.QuestionRef.current.setFieldsValue(this.props.formObject);
      }
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  handleChange = async (value, key) => {
    let { formObject, deviceList } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.data.Name
      };
    }
    if (this.QuestionRef.current) this.QuestionRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  onanswerchange = (answerKey) => {
    this.setState({
      answerKey
    });
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.QuestionRef.current.getFieldsValue();
    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.QuestionRef.current) this.QuestionRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onFormsubmit = async (values) => {
    let { formObject, fileData } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      ...formObject,
      ...values,
      ...(formObject && formObject.Id ? { Id: formObject.Id } : ''),
      SiteId: siteId
    };
    let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
    if (statusResponse && statusResponse.message) {
      message.success(statusResponse.message);
      this.getEquipmentHierarchy();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    // this.formRef.current.resetFields();
    if (this.QuestionRef.current) this.QuestionRef.current.resetFields();
  };
  onDrawerCancel = () => {
    this.setState({
      visible: false
    });
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  onChangeQuestion = () => {
    let formObject = this.QuestionRef.current.getFieldsValue();
    formObject = {
      ...formObject
    };
    this.setState({
      formObject
    });
    this.QuestionRef.current.setFieldsValue(formObject);
  };
  openCollapse = () => {
    this.setState({ open: true });
  };
  onAreaClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewAreaModal: false });
      this.areaRef.current.resetFields();
    }
  };
  onFormAreaSubmit = (e) => {
    e.preventDefault();
    this.areaRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          data: { ...values },
          MasterName: 'Master_Inspection_Areas',
          ActionType: 'create'
        };
        let CreateArea = await saveAreaData(payload);
        if (CreateArea) {
          message.success(CreateArea.message);
          let inspectionAreaList = await getinspectionAreaList();
          this.setState({ inspectionAreaList, viewAreaModal: false });

          if (this.areaRef.current) this.areaRef.current.resetFields();
          let formObject = this.QuestionRef.current.getFieldsValue();
          let areaId = inspectionAreaList.filter((item) => item.Area === values.Area);
          if (areaId && areaId[0]) {
            formObject = {
              ...formObject,
              AreaId: areaId[0].Id
            };

            this.QuestionRef.current.setFieldsValue(formObject);
            this.setState({ formObject, viewAreaModal: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onItemClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewItemModal: false });
      if (this.ItemRef.current) this.ItemRef.current.resetFields();
    }
  };
  onFormItemSubmit = (e) => {
    e.preventDefault();
    this.ItemRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          MasterName: 'Master_Inspection_Items',
          ActionType: 'create',
          data: {
            ...values
          }
        };
        let CreateItemData = await saveItemData(payload);
        if (CreateItemData) {
          message.success(CreateItemData.message);
          let inspectionItemList = await getinspectionItemList();
          this.setState({ inspectionItemList, viewItemModal: false });
          if (this.ItemRef.current) this.ItemRef.current.resetFields();
          let formObject = this.QuestionRef.current.getFieldsValue();
          let itemId = inspectionItemList.filter((item) => item.Item === values.Item);
          if (itemId && itemId[0]) {
            formObject = {
              ...formObject,
              ItemId: itemId[0].Id
            };

            this.QuestionRef.current.setFieldsValue(formObject);
            this.setState({ formObject, viewItemModal: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  adQuestionForm = () => {
    this.setState({ viewQuestionModal: true });
    if (this.deviceRef.current) this.deviceRef.current.resetFields();
  };
  addAnswerModal = () => {
    this.setState({ ViewModal: true });
    if (this.optionRef.current) this.optionRef.current.resetFields();
  };
  onQuestionsClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewQuestionModal: false });
    }
  };
  onModalclose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ ViewModal: false });
    }
  };
  onModalFormsubmit = () => {
    this.deviceRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        let createDeviceResponse = await saveQuestionData(payload);
        if (createDeviceResponse) {
          message.success(createDeviceResponse.message);
          let questionList = await questionsListMaster();
          let formObject = this.QuestionRef.current.getFieldsValue();
          let questionId = questionList.filter((item) => item.Question === values.Question);
          formObject = {
            ...formObject,
            QuestionIds: [
              ...(formObject && formObject['QuestionIds'] ? formObject['QuestionIds'] : []),
              questionId[0].Id
            ]
          };
          this.QuestionRef.current.setFieldsValue(formObject);
          this.setState({ formObject, viewQuestionModal: false, questionList, ViewModal: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onHandleChange = async (e, type) => {
    let form = this.QuestionRef.current.getFieldsValue();

    let formObject = {
      ...form
    };
    let payload = {
      // Equipment: formObject.Equipment,
      AreaId: formObject.AreaId,
      ItemId: formObject.ItemId
    };
    if (type === 'ItemId') {
      let EquipmentRecord = await getEquipmentRecord(payload);
      let getById = EquipmentRecord && EquipmentRecord[0] && EquipmentRecord[0].Id;
      this.setState({ EquipmentRecord, getById: getById });
      formObject = {
        ...formObject,
        QuestionIds:
          EquipmentRecord &&
          EquipmentRecord &&
          EquipmentRecord[0] &&
          EquipmentRecord[0] !== undefined
            ? EquipmentRecord[0].QuestionIds
            : []
      };
      this.setState({
        formObject
      });
    }

    this.QuestionRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.QuestionRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.QuestionRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.QuestionRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode
    };
    this.setState({
      formObject,
      [modal]: false
    });
    this.QuestionRef.current.setFieldsValue(formObject);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getEquipmentHierarchy = async () => {
    let treeData = await getEquipmentHierarchy();
    if (treeData) {
      this.expandAll(treeData);
    }
    this.setState({ treeData });
  };
  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              title={item && !item.ParentId ? `${item.Code} (${item.Name})` : item.Name}
              key={item.key}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return <Tree.TreeNode key={item.key} title={<div>{item.Name}</div>} dataRef={item} />;
      })
    );
  };
  onExpand = (expandedKeys) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys
    });
  };
  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };
  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys =
      data &&
      Array.isArray(data) &&
      data.map((node) => {
        let childKeys = [];
        if (node.children) {
          childKeys = this.getAllKeys(node.children);
        }
        return [childKeys, node.key];
      });
    return flattenDeep(nestedKeys);
  };
  onSelect = async (selectedKeys, info) => {
    let status =
      info &&
      info['selectedNodes'] &&
      info['selectedNodes'][0] &&
      info['selectedNodes'][0]['dataRef'] &&
      info['selectedNodes'][0]['dataRef']['Type'] &&
      info['selectedNodes'][0]['dataRef']['Type'] === 'Items';
    if (status) {
      let formObject = {};
      let dataRef = info['selectedNodes'][0]['dataRef'];
      let questionPayload = {
        Equipment: dataRef && dataRef['MainParentId'] ? dataRef['MainParentId'] : '',
        AreaId: dataRef && dataRef['ParentId'] ? dataRef['ParentId'] : '',
        ItemId: dataRef && dataRef['Code'] ? dataRef['Code'] : '',
        currentDate: moment().utc().format('YYYY-MM-DD')
      };

      let data = await getInspectionData(questionPayload);
      if (data && data[0]) {
        formObject = data[0];
        this.setState({
          formObject,
          formObjectId: formObject.Id
        });
        if (formObject) {
          this.QuestionRef.current.setFieldsValue(formObject);
        }
      }
      // else {
      //   message.error('No Questions found');
      // }
    } else if (status === false) {
      message.error('Please Select Item');
    }
  };
  setNewRecord = async () => {
    this.QuestionRef.current.resetFields();
    this.setState({
      formObject: {}
    });
  };
  render() {
    let {
      formObject,
      // deviceList,
      // hierarchyList,
      viewQuestionModal,
      WorkPlanList,
      questionList,
      inspectionAreaList,
      inspectionItemList,
      viewAreaModal,
      viewItemModal,
      translationCache,
      AnswerList,
      formObjectId,
      treeData,
      expandedKeys
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const newLocal = true;
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '15px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={{
                borderRight: '1px dashed white',
                width: '50%',
                flex: '10%',
                overflowY: 'auto',
                height: window.innerHeight - 250
              }}
            >
              <StyledTree
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                style={{ width: '100%', flex: '50%', padding: '20px' }}
                onSelect={this.onSelect}
                showLine
                defaultExpandAll={true}
                switcherIcon={<DownOutlined />}
              >
                {this.renderTreeNodes(treeData)}
              </StyledTree>
            </div>
            <div
              style={{
                width: '100%',
                flex: '50%',
                padding: '10px',
                borderLeft: '3px dashed steelblue',
                minHeight: window.innerHeight - 143
              }}
            >
              <StyledForm
                name="InspectionMasters"
                theme={themeSettings}
                ref={this.QuestionRef}
                className="collapse"
                onFinish={(data) => this.onFormsubmit(data, formObjectId)}
                // onFinishFailed={this.onFinishFailed}
                // initialValues={formObject}
                layout="horizontal"
                labelCol={{ flex: '250px' }}
                labelAlign="right"
                labelWrap
                // //wrapperCol={{ flex: 1 }}
                validateMessages={validateMessages}
              >
                <Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    {formObject && formObject.Id ? (
                      <StyledButton theme={themeSettings} htmlType="submit">
                        {translation(translationCache, constant.update)}
                      </StyledButton>
                    ) : (
                      <StyledButton theme={themeSettings} htmlType="submit">
                        {translation(translationCache, constant.submit)}
                      </StyledButton>
                    )}
                    <Button
                      type="primary"
                      style={{ marginRight: '5px' }}
                      onClick={() => {
                        this.setNewRecord();
                      }}
                    >
                      {translation(translationCache, 'Add')}
                    </Button>
                  </div>
                </Form.Item>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '100%'
                    }}
                    label={translation(translationCache, constant.equipment)}
                    name="Equipment"
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      disabled={true}
                      suffix={
                        <DownOutlined
                          style={{
                            color: '#1a3b5c',
                            cursor: formObject && formObject.Id ? 'not-allowed' : null,
                            opacity: formObject && formObject.Id ? '0.8' : null
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (formObject && !formObject.Id) {
                              this.getDeviceData('visible');
                            }
                          }}
                        />
                      }
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, `Select ${constant.equipment}`)}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%', flex: '100%' }}
                    label={translation(translationCache, constant.inspectionArea)}
                    name="AreaId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Inspection Area')}
                      onSelect={(e) => this.onHandleChange(e, 'AreaId')}
                      showArrow={true}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0'
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px'
                            }}
                          >
                            <Button
                              type="dashed"
                              icon={<PlusOutlined />}
                              onClick={() => this.setState({ viewAreaModal: true })}
                            >
                              {translation(translationCache, constant.addArea)}
                            </Button>
                          </Space>
                        </>
                      )}
                    >
                      {inspectionAreaList &&
                        Array.isArray(inspectionAreaList) &&
                        inspectionAreaList.map((area, index) => {
                          return (
                            <Option key={index} value={area.Id}>
                              {area.Area}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%', flex: '100%' }}
                    className="label"
                    label={translation(translationCache, constant.inspectionItems)}
                    name="ItemId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Inspection Items')}
                      onSelect={(e) => this.onHandleChange(e, 'ItemId')}
                      showArrow={true}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0'
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px'
                            }}
                          >
                            <Button
                              type="dashed"
                              icon={<PlusOutlined />}
                              onClick={() => this.setState({ viewItemModal: true })}
                            >
                              {translation(translationCache, constant.addItems)}
                            </Button>
                          </Space>
                        </>
                      )}
                    >
                      {inspectionItemList &&
                        Array.isArray(inspectionItemList) &&
                        inspectionItemList.map((device, index) => {
                          return (
                            <Option key={index} value={device.Id}>
                              {device.Item}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%' }}
                    className="label"
                    label={translation(translationCache, constant.question)}
                    name="QuestionIds"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: '70%' }}
                      mode="multiple"
                      placeholder={translation(translationCache, 'Select Question')}
                      showArrow={false}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0'
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px'
                            }}
                          >
                            <Button
                              type="dashed"
                              icon={<PlusOutlined />}
                              onClick={() => this.adQuestionForm()}
                            >
                              {translation(translationCache, 'Add Question')}
                            </Button>
                          </Space>
                        </>
                      )}
                    >
                      {questionList &&
                        Array.isArray(questionList) &&
                        questionList.map((question, index) => {
                          return (
                            <Option key={index} value={question.Id}>
                              {' '}
                              {question.Question}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="Id"></Form.Item>
                  <StyledModal
                    title={translation(translationCache, 'Add Item')}
                    open={viewItemModal}
                    okText={'Submit'}
                    onOk={(e) => this.onFormItemSubmit(e)}
                    onCancel={this.onItemClose}
                  >
                    <Form
                      layout="vertical"
                      ref={this.ItemRef}
                      name="user_form"
                      onFinish={() => this.onFormItemSubmit()}
                    >
                      <Form.Item
                        label={translation(translationCache, 'Item')}
                        name={'Item'}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <InputComponent
                          placeholder={translation(translationCache, 'Please enter Item')}
                        />
                      </Form.Item>
                    </Form>
                  </StyledModal>
                  <StyledModal
                    open={this.state.visible}
                    width={800}
                    closable
                    title={translation(translationCache, constant.selectEquipment)}
                    onOk={this.onModalOk}
                    onCancel={this.onDrawerCancel}
                  >
                    <TableSelect
                      data={this.state.tableData}
                      translationCache={this.props.translationCache}
                      key="Equipment"
                      columns={[
                        {
                          title: translation(translationCache, 'Equipment'),
                          dataIndex: 'DeviceCode',
                          key: 'DeviceCode',
                          ...this.getColumnSearchProps('DeviceCode')
                        },
                        {
                          title: translation(translationCache, 'Equipment Description'),
                          dataIndex: 'Name',
                          key: 'Name',
                          ...this.getColumnSearchProps('Name')
                        }
                      ]}
                      handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                    />
                  </StyledModal>
                  <StyledModal
                    title={translation(translationCache, constant.addArea)}
                    open={viewAreaModal}
                    okText={'Submit'}
                    onOk={(e) => this.onFormAreaSubmit(e)}
                    onCancel={this.onAreaClose}
                  >
                    <Form
                      layout="vertical"
                      ref={this.areaRef}
                      name="user_form"
                      onFinish={() => this.onFormAreaSubmit()}
                    >
                      <Form.Item
                        label={translation(translationCache, constant.area)}
                        name={'Area'}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <InputComponent placeholder={translation(translationCache, 'Enter Area')} />
                      </Form.Item>
                    </Form>
                  </StyledModal>
                  <StyledModal
                    title={translation(translationCache, 'Add Question')}
                    open={viewQuestionModal}
                    okText={'Submit'}
                    width={700}
                    onOk={(e) => this.onModalFormsubmit(e)}
                    onCancel={this.onQuestionsClose}
                    footer={[
                      <Button key="Submit" onClick={this.onModalFormsubmit}>
                        {translation(translationCache, 'Submit')}
                      </Button>,
                      <Button key="Cancel" onClick={this.onQuestionsClose}>
                        {translation(translationCache, 'Cancel')}
                      </Button>
                    ]}
                  >
                    <StyledForm
                      theme={themeSettings}
                      ref={this.deviceRef}
                      name="user_form"
                      layout="vertical"
                      labelAlign="right"
                      labelWrap
                      //wrapperCol={{ flex: 1 }}
                      // initialValues={editObj || {}}
                    >
                      <Form.Item
                        label={translation(translationCache, 'Add Question')}
                        name={'Question'}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <InputComponent
                          onChange={(e) => this.onanswerchange(e)}
                          placeholder={translation(translationCache, 'Add Question')}
                        />
                      </Form.Item>
                      <Form.List name="Answers">
                        {(Answers, { add }) => (
                          <>
                            <Form.Item>
                              <Button
                                type="primary"
                                disabled={AnswerList && AnswerList.length === 0 ? true : false}
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                {' '}
                                {translation(translationCache, 'Add Answers')}{' '}
                              </Button>
                            </Form.Item>
                            {Answers.map(({ key, name, ...restField }) => (
                              <Row key={key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                  <Form.Item
                                    {...restField}
                                    label={translation(translationCache, 'Answer')}
                                    name={[name, 'Option']}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      value={
                                        formObject && formObject.Answers ? formObject.Answers : []
                                      }
                                      placeholder={translation(translationCache, 'Select Answer')}
                                      onChange={(e) => this.onanswerchange(e)}
                                      showArrow={true}
                                      style={{ opacity: newLocal ? 1 : 1 }}
                                    >
                                      {AnswerList &&
                                        Array.isArray(AnswerList) &&
                                        AnswerList.map((answers, index) => {
                                          return (
                                            <Option
                                              key={index}
                                              value={answers.Id}
                                              disabled={
                                                this.deviceRef.current &&
                                                this.deviceRef.current.getFieldsValue() &&
                                                this.deviceRef.current.getFieldsValue()[
                                                  'Answers'
                                                ] &&
                                                this.deviceRef.current.getFieldsValue()['Answers'][
                                                  index
                                                ] &&
                                                this.deviceRef.current.getFieldsValue()['Answers'][
                                                  index
                                                ]['Option'] === answers.Id
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {translation(
                                                this.props.translationCache,
                                                answers.Answer
                                              )}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <Form.Item
                                    {...restField}
                                    label={translation(translationCache, 'Select WorkPlan')}
                                    name={[name, 'Actions']}
                                    rules={[{ required: false }]}
                                  >
                                    <Select
                                      placeholder={translation(translationCache, 'Select WorkPlan')}
                                      mode="multiple"
                                      showArrow={false}
                                    >
                                      {WorkPlanList &&
                                        Array.isArray(WorkPlanList) &&
                                        WorkPlanList.map((answers, index) => {
                                          return (
                                            <Option key={index} value={answers.Code}>
                                              {answers.Name}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </StyledForm>
                  </StyledModal>
                </div>
              </StyledForm>
            </div>
          </div>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(InspectionMaster);
