import React from 'react';
import { Button } from 'antd';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import history from '../../../../../../commons/history';
import { getTranslationCache } from '../../../../../../selectors/language';
import { selectDashboard } from '../../../../DashboardComponents/DashboardCalls';
import WarningLine from './WarningLine';

class WarningDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setPropsData: {},
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    if (this.props && this.props.location) {
      this._prepareProperties(queryString.parse(this.props.location.search));
    }
  }
  componentDidUpdate(preprops) {
    if (preprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  _prepareProperties = async ({ Name, PanelId }) => {
    let dashboards = await selectDashboard(Name);
    console.log('_prepareProperties', dashboards);
    if (dashboards && dashboards[0] && dashboards[0].json) {
      let filteredPanel = dashboards[0].json.filter(
        (dashboardObject) => dashboardObject.id === Number(PanelId)
      );
      console.log('filteredPanel', filteredPanel);
      this.setState({
        setPropsData:
          filteredPanel && filteredPanel[0]
            ? {
                ...filteredPanel[0],
                graphprops: filteredPanel[0]
              }
            : {}
      });
    }
  };

  translation = (keyword) => {
    console.log('keyword', keyword, this.state.translationCache);
    let { translationCache } = this.state;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    return (
      <div style={{ minHeight: window.innerHeight - 115, backgroundColor: 'rgb(26, 54, 82)' }}>
        <Button style={{ margin: '5px' }} type="primary" onClick={() => history.goBack()}>
          {this.translation('Go Back')}
        </Button>
        <Link
          style={{ float: 'right', marginRight: '22px' }}
          to={`/rubus/settingsPage/WarningLimits`}
        >
          {this.translation('Redirect to Warning Limits')}
        </Link>
        <WarningLine {...this.state.setPropsData} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(WarningDashboard);
