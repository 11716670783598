import React, { Component } from 'react';
import localStorage from '../../../../utils/localStorage';
import { connect } from 'react-redux';
import { getUserProfile } from '../../../../selectors/layout';
import { createStructuredSelector } from 'reselect';
import {
  Row,
  Col,
  Form,
  Slider,
  Button,
  InputNumber,
  DatePicker,
  message,
  Radio,
  Table
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import './style.css';

class SmartMotorControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      parameterList: [],
      minValue: 0,
      maxValue: 50,
      formType: '',
      mode: '',
      userProfile: this.props.userProfile,
      Time: moment().format('HH:mm')
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    //  this.historyLog();
    try {
      this.setState({ formType: this.props.match.params.name });
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount = () => {
    // this.getFormData();
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };

  handleSubmit = () => {
    const { parameterValue, mode } = this.state;
    // let siteId=localStorage.get("currentSite")

    let payload = {};

    if (mode === '' || parameterValue === '') {
      message.error('Please Provide SetPoints');
    } else if (mode !== '' && parameterValue !== '') {
      payload = {
        motorSpeed: parameterValue,
        status: mode
      };

      this.formSubmittion(payload);
    }
  };
  formSubmittion = (payload) => {
    // let siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get('accessToken');
    const formSubmittionObject = {
      method: 'POST',
      url: `/api/dashboard/vibratingMotorForm `,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(formSubmittionObject)
      .then((response) => {
        message.success('Submitted Successfully');
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  historyLog = () => {
    const accessToken = localStorage.get('accessToken');
    const historyLogData = {
      method: 'POST',
      url: `/api/edge/get`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
      //data: payload,
    };
    axios(historyLogData)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  parameterChange = (value) => {
    let { parameterList } = this.state;

    let filterdArray = parameterList.filter((item) => item.ParameterName === value);
    this.setState({
      minValue: filterdArray[0].minValue,
      maxValue: filterdArray[0].maxValue,
      selectedParameter: filterdArray[0].ParameterName
    });
  };
  onTimePickerChnage = (value) => {
    this.setState({ Time: moment(value).format('HH:mm') });
  };

  render() {
    let {
      minValue,
      maxValue,
      parameterValue,

      mode
    } = this.state;
    const columns = [
      {
        title: 'SI.No',
        dataIndex: 'sno',
        key: 'sno'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'satus'
      },
      {
        title: 'Frequency',
        dataIndex: 'frequency',
        key: 'frequency'
      },
      {
        title: 'Submitted Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp'
      },
      {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName'
      }
    ];

    return (
      <div>
        <Row style={{ background: 'grey', padding: '50px' }}>
          <Col span={8}>
            <Form>
              <div className="mode3">
                {/* <div className="status start"> The Motor is  Running</div> */}
                {/* <div className="status stop"> The Motor  Stopped</div> */}
                <Row>
                  <Col offset={6} span={8}>
                    <Radio.Group
                      onChange={(e) => this.setState({ mode: e.target.value })}
                      buttonStyle="solid"
                    >
                      <Radio.Button value="start">START</Radio.Button>
                      <Radio.Button value="stop">STOP</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row style={{ marginTop: '20px' }}>
                  <Col offset={4} span={10}>
                    <h4 style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                      Select Range
                    </h4>
                    <div className="sliderBackground">
                      <Slider
                        disabled={mode === 'start' ? false : true}
                        //marks={marks}
                        min={minValue}
                        max={maxValue}
                        value={parameterValue}
                        onChange={(value) => this.setState({ parameterValue: value })}
                        tipFormatter={(value) => {
                          return `${value}`;
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={2}>
                    <InputNumber
                      min={minValue}
                      max={maxValue}
                      style={{
                        width: '60px',
                        height: '50px',
                        marginTop: '35px',
                        marginLeft: '5px'
                      }}
                      value={mode === 'stop' ? 0 : parameterValue}
                      onChange={(value) => this.setState({ parameterValue: value })}
                      // step={0.01}
                    />
                  </Col>
                </Row>
                <Button
                  style={{ marginLeft: '45%', marginTop: '60px' }}
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => this.handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Col>

          <Col offset={4} span={8}>
            <DatePicker style={{ marginBottom: '10px', float: 'right' }} />
            <Table className="smartVibrate" columns={columns} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(SmartMotorControl);
