import React, { Component } from 'react';
import localStorage from '../../../../utils/localStorage';
import { connect } from 'react-redux';
import { getUserProfile } from '../../../../selectors/layout';
import { createStructuredSelector } from 'reselect';
import {
  Row,
  Col,
  Form,
  Slider,
  TimePicker,
  Button,
  InputNumber,
  message,
  Select,
  Modal,
  Table
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import './style.css';
const { Option } = Select;
const columns = [
  {
    title: 'S.No',
    dataIndex: 'sno',
    key: 'sno'
  },
  {
    title: 'Parameter Name',
    dataIndex: 'ParameterName',
    key: 'ParameterName'
  },

  {
    title: 'Value',
    dataIndex: 'Value',
    key: 'Value'
  },
  {
    title: 'Submitted TimeStamp',
    dataIndex: 'SubmittedTime',
    key: 'SubmittedTime'
  },
  {
    title: 'Updated TimeStamp',
    dataIndex: 'UpdatedTime',
    key: 'UpdatedTime'
  },
  {
    title: 'Created By',
    dataIndex: 'CreatedBy',
    key: 'CreatedBy'
  }
];

class SmartControlTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      parameterList: [],
      minValue: 0,
      maxValue: 10,
      parameterValue: 0,
      selectedParameter: '',
      datasource: [],
      userProfile: this.props.userProfile,
      Time: moment().format('HH:mm')
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    this.historyLog();
  };
  componentDidMount = () => {
    this.getFormData();
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };
  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    clearInterval(this.timeTicket);
    this.setState({ visible: false });
  };

  getFormData = () => {
    // const siteId = localStorage.get("currentSite");
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const parameterGroup = {
      method: 'GET',
      url: `/api/edge/list `,
      headers: {
        Authorization: Authorization
      }
    };
    axios(parameterGroup)
      .then((res) => {
        this.setState({
          parameterList: res.data
        });
      })
      .catch(() => {});
  };
  handleSubmit = () => {
    const { selectedParameter, parameterValue, Time, userProfile } = this.state;
    let siteId = localStorage.get('currentSite');

    let payload = {};

    if (selectedParameter === '' || parameterValue === '' || Time === '') {
      message.error('Please Provide SetPoints');
    } else if ((selectedParameter !== '' && parameterValue !== '') || Time === '') {
      payload = {
        siteId: siteId,
        CreatedBy: userProfile && userProfile.user ? userProfile.user.Id : '',
        ParameterName: selectedParameter,
        Value: parameterValue,
        //  UpdatedTime:Time,
        SubmittedTime: Time
      };

      this.formSubmittion(payload);
    }
  };
  formSubmittion = (payload) => {
    // let siteId = localStorage.get("currentSite");
    const accessToken = localStorage.get('accessToken');
    const formSubmittionObject = {
      method: 'POST',
      url: `/api/edge/create`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(formSubmittionObject)
      .then((response) => {
        message.success('Submitted Successfully');
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  historyLog = () => {
    const accessToken = localStorage.get('accessToken');
    const historyLogData = {
      method: 'POST',
      url: `/api/edge/get`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
      //data: payload,
    };
    axios(historyLogData)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  parameterChange = (value) => {
    let { parameterList } = this.state;

    let filterdArray = parameterList.filter((item) => item.ParameterName === value);
    this.setState({
      minValue: filterdArray[0].minValue,
      maxValue: filterdArray[0].maxValue,
      selectedParameter: filterdArray[0].ParameterName
    });
  };
  onTimePickerChnage = (value) => {
    this.setState({ Time: moment(value).format('HH:mm') });
  };

  render() {
    let { parameterList, minValue, maxValue, parameterValue, datasource } = this.state;

    return (
      <div className="mode1" style={{ padding: '50px' }}>
        <Button
          style={{ float: 'right', marginRight: '2%' }}
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            this.setState({ visible: true });
          }}
        >
          Submitted History
        </Button>

        <Form>
          <div className="mode3">
            <Row>
              <Col span={5}>
                <Form.Item style={{ width: '200px' }} label="Select Parametr :">
                  <Select
                    onChange={(value) => this.parameterChange(value)}
                    placeholder="Please Select Parameter"
                  >
                    {parameterList.map((item, i) => {
                      return (
                        <Option value={item.ParameterName} key={i}>
                          {item.ParameterName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <h4 style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
                  Select Range
                </h4>
                <div className="sliderBackground">
                  <Slider
                    //marks={marks}
                    min={minValue}
                    max={maxValue}
                    value={parameterValue}
                    onChange={(value) => this.setState({ parameterValue: value })}
                    tipFormatter={(value) => {
                      return `${value}`;
                    }}
                  />
                </div>
              </Col>
              <Col span={2}>
                <InputNumber
                  min={minValue}
                  max={maxValue}
                  style={{
                    width: '60px',
                    height: '50px',
                    marginTop: '35px',
                    marginLeft: '5px'
                  }}
                  value={parameterValue}
                  onChange={(value) => this.setState({ parameterValue: value })}
                  // step={0.01}
                />
              </Col>
              <Col span={5}>
                <Form.Item style={{ fontWeight: 'bold', fontSize: '15px' }} label="Select Time :">
                  <TimePicker
                    style={{ width: '80%' }}
                    onChange={(e) => this.onTimePickerChnage(e)}
                    //disabledMinutes={(e)=>this.getDisabledMinutes("activate")}
                    format={'HH:mm'}
                    // defaultValue={moment('12:08', format)} format={format}
                    placeholder={'HH:mm'}

                    // format={format}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Button
            style={{ marginLeft: '45%', marginTop: '60px' }}
            type="primary"
            shape="round"
            size="large"
            onClick={() => this.handleSubmit()}
          >
            Submit
          </Button>

          <br />
        </Form>

        <Modal
          visible={this.state.visible}
          title="Submitted Records"
          width={950}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Close
            </Button>
          ]}
        >
          <Table
            className="ModalTable"
            //  rowSelection={rowSelection}
            columns={columns}
            dataSource={datasource}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(SmartControlTags);
