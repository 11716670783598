import React, { Component } from 'react';
import axios from 'axios';
import { Table, Form, Button, Drawer, Row, Col, message, Select } from 'antd';

import localStorage from '../../../utils/localStorage';
import { StyledApplication, StyledTable, lightTheme, DrawerFooter } from '../UserManagement/style';

const { Option } = Select;
const FormItem = Form.Item;

class MasterMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mappingList: [],
      mappingObject: {},
      tableNameList: [],
      masterTableNames: [],
      columnsList: [],
      masterList: []
    };
  }
  userRef = React.createRef();
  componentDidMount = () => {
    this._getmappingList();
    this._getMasterList();
    this._getTableMasterList();
  };
  onClose = () => {
    if (this.userRef.current) {
      this.userRef.current.resetFields();
    }
    this.setState({
      visible: false,
      mappingObject: {}
    });
  };
  showDrawer = () => {
    if (this.userRef.current) {
      this.userRef.current.resetFields();
    }
    this.setState({
      visible: true,
      mappingObject: {}
    });
  };
  _getmappingList = () => {
    const accessToken = localStorage.get('accessToken');
    const axiosObject = {
      method: 'GET',
      url: `/api/mastermapping/get`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(axiosObject)
      .then((response) => {
        this.setState({
          mappingList: response.data
        });
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };
  _getTableMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/masterconfiguration/getTableList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          masterTableNames: response.data
        });
      })
      .catch(() => {});
  };
  _getMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/masterconfiguration/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          masterList: response.data
        });
      })
      .catch(() => {});
  };
  _getColumnNamesByTableName = () => {
    const Object = {
      method: 'POST',
      url: `/api/masterconfiguration/getColumnNamesByTableName `
      // headers: {
      //     'Authorization': `Bearer ${accessToken}`
      // },
    };
    axios(Object)
      .then((response) => {
        this.setState({
          masterTableNames: response.data
        });
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };

  savemastermapping = (type) => {
    const accessToken = localStorage.get('accessToken');
    if (type === 'save') {
      this.userRef.current.validateFields().then(async (values) => {
        let payloadData = {
          ParentTable: values.ParentTable,
          ParentColumn: values.ParentColumn,
          ChildTable: values.ChildTable,
          ChildColumn: values.ChildColumn,
          DisplayColumn: values.DisplayColumn
        };
        let savemapping = {
          method: 'POST',
          url: `/api/mastermapping/create`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: payloadData
        };
        axios(savemapping)
          .then(() => {
            this._getmappingList();
            message.success('Maping created');
            this.onClose();
          })
          .catch(function () {
            message.error('Error');
          });
      });
    } else if (type === 'update') {
      this.userRef.current.validateFields().then(async (values) => {
        let updatemapping = {
          method: 'POST',
          url: `/api/mastermapping/update`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            ...values,
            Id: this.state.mappingObject.Id
          }
        };
        axios(updatemapping)
          .then(() => {
            this._getmappingList();
            message.success('Maping updated');
            this.onClose();
          })
          .catch(function () {
            message.error('Error');
          });
      });
    } else if (type === 'delete') {
      let deletemapping = {
        method: 'POST',
        url: `/api/mastermapping/delete`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          Id: this.state.mappingObject.Id
        }
      };
      axios(deletemapping)
        .then(() => {
          this._getmappingList();
          message.success('Maping deleted');
          this.onClose();
        })
        .catch(function () {
          message.error('Error');
        });
    }
  };

  getColumnData = async (value) => {
    let columnsList = await this.getAxiosColumnsFromTableName(value);
    this.setState({
      columnsList
    });
  };

  getAxiosColumnsFromTableName = (TableName) => {
    const Object = {
      method: 'POST',
      url: `/api/masterconfiguration/getColumnNamesByTableName`,
      data: {
        TableName
      }
    };
    return axios(Object)
      .then((response) => {
        if (response && response.data && Array.isArray(response.data)) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };

  render() {
    const { masterList, mappingObject, mappingList, columnsList, masterTableNames } = this.state;
    const columns = [
      {
        title: 'Parent Table',
        dataIndex: 'ParentTable',
        key: 'ParentTable'
      },

      {
        title: 'Parent Column',
        dataIndex: 'ParentColumn',
        key: 'ParentColumn'
      },
      {
        title: 'Child Table',
        dataIndex: 'ChildTable',
        key: 'ChildTable'
      },
      {
        title: 'Child Column',
        dataIndex: 'ChildColumn',
        key: 'ChildColumn'
      },
      {
        title: 'Child Display Column',
        dataIndex: 'DisplayColumn',
        key: 'DisplayColumn'
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <div>
          <Row>
            <Col span={8}>
              <Button
                type="primary"
                onClick={this.showDrawer}
                style={{
                  backgroundColor: '#000c17',
                  border: '#000c17',
                  marginBottom: '5%'
                }}
              >
                Create
              </Button>
            </Col>
            <StyledTable theme={lightTheme}>
              <Table
                size="medium"
                columns={columns}
                dataSource={mappingList && Array.isArray(mappingList) ? mappingList : []}
                pagination={
                  mappingList && mappingList.length > 10
                    ? {
                        pageSize: '5'
                      }
                    : false
                }
                locale={{ emptyText: 'No Data' }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      this.setState({
                        visible: true,
                        mappingObject: record
                      });
                    }
                  };
                }}
              />
            </StyledTable>
            <Drawer
              title={!mappingObject.MasterMappingId ? 'Create' : 'Update'}
              placement="right"
              closable
              width={800}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <Form
                layout="vertical"
                ref={this.userRef}
                name="user_form"
                initialValues={mappingObject || {}}
              >
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Parent Table:"
                      name="ParentTable"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Parent Table!'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select Parent Table"
                        style={{ width: '300px' }}
                        onChange={(e) => this.getColumnData(e)}
                      >
                        {masterTableNames &&
                          Object.keys(masterTableNames)
                            .filter((fil) => {
                              return !(
                                masterList &&
                                masterList
                                  .map((master) => {
                                    return master.TableName;
                                  })
                                  .includes(fil)
                              );
                            })
                            .map((master, index) => {
                              return (
                                <Option key={index} value={master}>
                                  {master}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Parent Column:"
                      name="ParentColumn"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Parent Column!'
                        }
                      ]}
                    >
                      <Select placeholder="Select Parent Column" style={{ width: '300px' }}>
                        {columnsList &&
                          Array.isArray(columnsList) &&
                          columnsList.map((item, index) => {
                            return (
                              <Option key={index} value={item.ColumnName}>
                                {item.ColumnName}
                              </Option>
                            );
                          })}
                        {masterTableNames &&
                          this.userRef.current &&
                          masterTableNames[this.userRef.current.getFieldValue('ParentTable')] &&
                          masterTableNames[this.userRef.current.getFieldValue('ParentTable')]
                            // .filter(fil => {
                            //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                            //         return master.key
                            //     }).includes(fil))
                            // }
                            // )
                            .map((keyNames, index) => {
                              return (
                                <Option key={index} value={keyNames}>
                                  {keyNames}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Child Table:"
                      name="ChildTable"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Child Table!'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select Child Table"
                        style={{ width: '300px' }}
                        onChange={(e) => this.getColumnData(e)}
                      >
                        {masterTableNames &&
                          Object.keys(masterTableNames)
                            .filter((fil) => {
                              return !(
                                masterList &&
                                masterList
                                  .map((master) => {
                                    return master.TableName;
                                  })
                                  .includes(fil)
                              );
                            })
                            .map((master, index) => {
                              return (
                                <Option key={index} value={master}>
                                  {master}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Child Column:"
                      name="ChildColumn"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Child Column!'
                        }
                      ]}
                    >
                      <Select placeholder="Select Child Column" style={{ width: '300px' }}>
                        {masterTableNames &&
                          this.userRef.current &&
                          masterTableNames[this.userRef.current.getFieldValue('ChildTable')] &&
                          masterTableNames[this.userRef.current.getFieldValue('ChildTable')]
                            // .filter(fil => {
                            //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                            //         return master.key
                            //     }).includes(fil))
                            // }
                            // )
                            .map((keyNames, index) => {
                              return (
                                <Option key={index} value={keyNames}>
                                  {keyNames}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Child Display Column:"
                      name="DisplayColumn"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Child Display Column !'
                        }
                      ]}
                    >
                      <Select placeholder="Select Child Display Column" style={{ width: '300px' }}>
                        {masterTableNames &&
                          this.userRef.current &&
                          masterTableNames[this.userRef.current.getFieldValue('ChildTable')] &&
                          masterTableNames[this.userRef.current.getFieldValue('ChildTable')]
                            // .filter(fil => {
                            //     return !(editObject && editObject.columns && editObject.columns.map((master) => {
                            //         return master.key
                            //     }).includes(fil))
                            // }
                            // )
                            .map((keyNames, index) => {
                              return (
                                <Option key={index} value={keyNames}>
                                  {keyNames}
                                </Option>
                              );
                            })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
              <DrawerFooter>
                {
                  <div>
                    {!mappingObject.Id ? (
                      <div>
                        <Button
                          type="primary"
                          style={{
                            marginLeft: '5px',
                            marginBottom: '5px',
                            backgroundColor: '#000c17',
                            border: '#000c17'
                          }}
                          onClick={() => this.savemastermapping('save')}
                        >
                          Save
                        </Button>
                        <Button
                          type="primary"
                          style={{
                            marginLeft: '5px',
                            marginBottom: '5px',
                            backgroundColor: '#000c17',
                            border: '#000c17'
                          }}
                          onClick={(e) => {
                            this.onClose(e);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          type="primary"
                          style={{
                            marginLeft: '5px',
                            marginBottom: '5px',
                            backgroundColor: '#000c17',
                            border: '#000c17'
                          }}
                          onClick={() => this.savemastermapping('update')}
                        >
                          Update
                        </Button>
                        <Button
                          type="primary"
                          style={{
                            marginLeft: '5px',
                            marginBottom: '5px',
                            backgroundColor: '#000c17',
                            border: '#000c17'
                          }}
                          onClick={() => this.savemastermapping('delete')}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                }
              </DrawerFooter>
            </Drawer>
          </Row>
        </div>
      </StyledApplication>
    );
  }
}

export default MasterMapping;
