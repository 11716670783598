import { createSelector } from 'reselect';

const device = (state) => {
  return state.get('device').get('device');
};

const getDeviceListSelector = () =>
  createSelector(device, (state) => {
    return state.get('allDeviceList');
  });

const getDeviceCMListSelector = () =>
  createSelector(device, (state) => {
    return state.get('allCMDeviceList');
  });
const getCurrentDevice = () =>
  createSelector(device, (state) => {
    return state.get('currentDevice');
  });

const getDeviceBreadcrumbList = () =>
  createSelector(device, (state) => {
    return state.get('devicebreadcrumbList');
  });

const getTagReferenceListSelector = () =>
  createSelector(device, (state) => {
    return state && state.get('tagReferenceList');
  });

const getConditionListSelector = () =>
  createSelector(device, (state) => {
    return state && state.get('conditionList');
  });

export {
  getDeviceListSelector,
  getDeviceCMListSelector,
  getCurrentDevice,
  getDeviceBreadcrumbList,
  getTagReferenceListSelector,
  getConditionListSelector
};
