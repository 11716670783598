import axios from 'axios';

import localStorage from '../../../../utils/localStorage';

export function _getTopicList() {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: '/api/datawatch/topicList',
    mehod: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
export function _getDeviceList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: `/api/device/get?SiteId=${siteId}`,
    mehod: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}

export function _getGroupList(data) {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: '/api/datawatch/groupList',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}

export function _generate(data) {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: '/api/datawatch/getParameters',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
