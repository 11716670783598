import React, { Fragment } from 'react';
import { Input, Select, Popover, Divider, TimePicker } from 'antd';

import {
  Boxdiv,
  StyledAppdiv,
  RuleTypeSelectionDiv,
  BlockedLabel,
  ActionSelectionDiv,
  AddParagraph,
  BlankDiv,
  DeviceSelect,
  OperaterSelect,
  ValueInput,
  ButtonBoxDiv,
  MarginRightButton,
  OnchangeCheckbox,
  ColumnNameSelect,
  LogicAddButton,
  BlockedLabelBlack,
  ActionCheckbox,
  TableConditionSelect,
  TextParagraph
} from '../style';
import { actionList } from '../utils/const';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

export default function TableRuleView(props) {
  const {
    inputList,
    conif,
    conelseif,
    conelse,
    elsifLength,
    elseList,
    ruleType,
    tableList,
    batchPriority,
    onChange,
    tablePrevisible,
    tableRootCause,
    tableElseprevisible,
    tablElseroot,
    deviceList,
    columnList
  } = props.config;
  const {
    handledeviceSelect,
    handleelseif,
    handleelse,
    handleDelay,
    checkonChange,
    handleTagSelect,
    handleInputChange,
    handleAddClick,
    handleRuleType,
    handleRadioButton,
    handleRemoveClick,
    handleVisibleChange,
    hanblePopoverOnclick,
    handleTextAreaChange,
    handleConditionchange,
    handleTableDeviceSelect
  } = props.Methods;

  return (
    <StyledAppdiv>
      <RuleTypeSelectionDiv>
        <div style={{ marginRight: '12%' }}>
          <BlockedLabel>Type</BlockedLabel>
          <Select
            placeholder="RuleType"
            style={{ width: '120px', display: 'inline-block' }}
            value={ruleType}
            onChange={(value) => handleRuleType(value, 'ruleType')}
            disabled
          >
            <Option value="tableRule">tableRule</Option>
          </Select>
        </div>
        <div style={{ marginRight: '12%' }}>
          <label style={{ display: 'block' }}>Priority</label>
          <Select
            placeholder="Priority"
            style={{ width: '120px', display: 'inline-block' }}
            value={batchPriority === '' ? undefined : batchPriority}
            onChange={(value) => handleRuleType(value, 'batchPriority')}
          >
            {props.config.priorityTypes.map((type, index) => (
              <Option key={index} value={type.PriorityType}>
                {type.PriorityType}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div style={{ marginRight: "12%", width: "200px" }}>
          <BlockedLabel>Replicate</BlockedLabel>
          <PickyStyling
            id="replicate"
            numberDisplayed="2"
            options={deviceList}
            labelKey="DeviceId"
            valueKey="DeviceId"
            multiple={true}
            value={replicateDevices}
            includeFilter
            onChange={handlePickyChange}
          />
        </div> */}
        <OnchangeCheckbox checked={onChange} onChange={(value) => handleRadioButton(value)}>
          OnChange
        </OnchangeCheckbox>
      </RuleTypeSelectionDiv>
      {conif
        ? inputList.map((x, i) => {
            return (
              <Boxdiv key={`if${i}`}>
                {i === 0 ? <BlockedLabel>if</BlockedLabel> : null}
                {i > 0 ? (
                  <TableConditionSelect
                    name="operater"
                    placeholder="Select Condition"
                    size="default"
                    value={x['54765'] === '' ? undefined : x['54765']}
                    onChange={(e) => handleConditionchange(e, i, 'inputList')}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </TableConditionSelect>
                ) : null}
                <DeviceSelect
                  placeholder="Select Table"
                  size="default"
                  value={x.device === '' ? undefined : x.device}
                  onChange={(value) => handledeviceSelect(value, i, 'inputList')}
                >
                  {tableList.map((table, i) => {
                    return (
                      <Option key={`ifdeviceList${i}`} value={table.TableName}>
                        {table.TableName}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <ColumnNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder="Select TableName"
                  value={x.tagName === '' ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, 'inputList')}
                >
                  {columnList
                    .filter((param) => param.TableName === x.device)
                    .map((param, j) => {
                      return (
                        <Option key={`inputColumnagname${i}${j}`} value={param.columnnames}>
                          {param.columnnames}
                        </Option>
                      );
                    })}
                </ColumnNameSelect>
                <DeviceSelect
                  placeholder="Device"
                  size="default"
                  value={x.tableDevice === '' ? undefined : x.tableDevice}
                  onChange={(value) => handleTableDeviceSelect(value, i, 'inputList')}
                >
                  {deviceList.map((device, i) => {
                    return (
                      <Option key={`iftabledeviceList${i}`} value={device.DeviceId}>
                        {device.DeviceId}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder="Select operator"
                  value={x.operater === '' ? undefined : x.operater}
                  onChange={(e) => handleInputChange(e, i, 'select', 'inputList')}
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {'>'}
                  </Option>
                  <Option value="<" title="Less Than">
                    {'<'}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {'>='}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {'<='}
                  </Option>
                </OperaterSelect>
                <ValueInput
                  size="default"
                  name="value"
                  placeholder="value"
                  value={x.value}
                  onChange={(e) => handleInputChange(e, i, 'input', 'inputList')}
                />
                <ButtonBoxDiv>
                  {inputList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, 'inputList')}
                    >
                      Remove
                    </MarginRightButton>
                  )}
                  {inputList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={'primary'}
                        size="default"
                        onClick={() => handleAddClick('inputList')}
                      >
                        Add
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]['priventive']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(e, i, 'inputList', 'priventive')
                              }
                            />

                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'inputList',
                                  'close',
                                  'priventive',
                                  'tablePrevisible'
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'inputList', 'ok', 'tablePrevisible');
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={tablePrevisible}
                        onVisibleChange={(value) => handleVisibleChange(value, 'tablePrevisible')}
                        title="Preventive Measures"
                        trigger="click"
                      >
                        <TextParagraph
                          style={{
                            color: 'blue',
                            marginLeft: '2%',
                            display: 'inline-block'
                          }}
                        >
                          Preventive Measures
                        </TextParagraph>
                      </Popover>
                      <Divider type="vertical" />
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]['rootcause']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(e, i, 'inputList', 'tableRootCause')
                              }
                            />
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'inputList',
                                  'close',
                                  'rootcause',
                                  'tableRootCause'
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'inputList', 'ok', 'tableRootCause');
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={tableRootCause}
                        onVisibleChange={(value) => handleVisibleChange(value, 'tableRootCause')}
                        title=" RootCause Analysis"
                        trigger="click"
                      >
                        <TextParagraph>RootCause Analysis</TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === inputList.length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      Action<span style={{ color: 'red' }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={actionList}
                      value={props.config.inputList[i]['action']}
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, 'inputList', i);
                      }}
                    />

                    <TimePicker
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        props.config.inputList[i]['delay'] === undefined
                          ? undefined
                          : moment(props.config.inputList[i]['delay'][1], 'HH:mm:ss')
                      }
                      onChange={(e) => handleDelay(e, 'inputList', i)}
                      placeholder="Select Delay"
                      allowClear={false}
                      size="default"
                    />
                  </ActionSelectionDiv>
                ) : null}
              </Boxdiv>
            );
          })
        : null}
      {conelseif
        ? elsifLength.map((elsepropName, index) => {
            return props.config[elsepropName].map((x, i) => {
              return (
                <Boxdiv key={`${index}${i}`}>
                  {i === 0 ? <BlockedLabel>else if</BlockedLabel> : null}
                  {i > 0 ? (
                    <TableConditionSelect
                      size="default"
                      name="operater"
                      value={x['54765'] === '' ? undefined : x['54765']}
                      placeholder="Select condition"
                      onChange={(e) => handleConditionchange(e, i, elsepropName)}
                    >
                      <Option value="&&">AND</Option>
                      <Option value="||">OR</Option>
                    </TableConditionSelect>
                  ) : null}
                  <DeviceSelect
                    placeholder="Select Table"
                    size="default"
                    value={x.device === '' ? undefined : x.device}
                    onChange={(value) => handledeviceSelect(value, i, elsepropName)}
                  >
                    {tableList.map((table, i) => {
                      return (
                        <Option key={`ifdeviceList${i}`} value={table.TableName}>
                          {table.TableName}
                        </Option>
                      );
                    })}
                  </DeviceSelect>
                  <ColumnNameSelect
                    showSearch
                    name="tagName"
                    size="default"
                    placeholder="Select TableName"
                    value={x.tagName === '' ? undefined : x.tagName}
                    onChange={(e) => handleTagSelect(e, i, elsepropName)}
                  >
                    {columnList
                      .filter((param) => param.TableName === x.device)
                      .map((param, j) => {
                        return (
                          <Option key={`elseifColumnagname${i}${j}`} value={param.columnnames}>
                            {param.columnnames}
                          </Option>
                        );
                      })}
                  </ColumnNameSelect>
                  <DeviceSelect
                    placeholder="Device"
                    size="default"
                    value={x.tableDevice === '' ? undefined : x.tableDevice}
                    onChange={(value) => handleTableDeviceSelect(value, i, elsepropName)}
                  >
                    {deviceList.map((device, i) => {
                      return (
                        <Option key={`elseiftabledeviceList${i}`} value={device.DeviceId}>
                          {device.DeviceId}
                        </Option>
                      );
                    })}
                  </DeviceSelect>
                  <OperaterSelect
                    size="default"
                    value={x.operater === '' ? undefined : x.operater}
                    name="operater"
                    placeholder="Select operator"
                    onChange={(e) => handleInputChange(e, i, 'select', elsepropName)}
                  >
                    <Option value="==">==</Option>
                    <Option value="!=">!=</Option>
                    <Option value=">">{'>'}</Option>
                    <Option value="<">{'<'}</Option>
                    <Option value=">=">{'>='}</Option>
                    <Option value="<=">{'<='}</Option>
                  </OperaterSelect>
                  <ValueInput
                    name="value"
                    size="default"
                    placeholder="value"
                    value={x.value}
                    onChange={(e) => handleInputChange(e, i, 'input', elsepropName)}
                  />
                  <ButtonBoxDiv>
                    {props.config[elsepropName].length !== 1 && (
                      <MarginRightButton
                        size="default"
                        onClick={() => handleRemoveClick(i, elsepropName)}
                      >
                        Remove
                      </MarginRightButton>
                    )}
                    {props.config[elsepropName].length - 1 === i && (
                      <Fragment>
                        <LogicAddButton
                          type={'primary'}
                          size="default"
                          onClick={() => handleAddClick(elsepropName)}
                        >
                          Add
                        </LogicAddButton>
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={props.config[elsepropName][i]['priventive']}
                                rows={5}
                                maxLength="150"
                                onChange={(e) =>
                                  handleTextAreaChange(e, i, elsepropName, 'priventive')
                                }
                              />
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'close',
                                    'priventive',
                                    `tablePrevisible${elsepropName}`
                                  );
                                }}
                              >
                                Cancel
                              </span>
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'ok',
                                    `tablePrevisible${elsepropName}`
                                  );
                                }}
                              >
                                Ok
                              </span>
                            </div>
                          }
                          visible={props.config[`tablePrevisible${elsepropName}`]}
                          onVisibleChange={(value) =>
                            handleVisibleChange(value, `previsible${elsepropName}`)
                          }
                          title="Preventive Measures"
                          trigger="click"
                        >
                          <TextParagraph>Preventive Measures</TextParagraph>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={props.config[elsepropName][i]['rootcause']}
                                rows={5}
                                maxLength="150"
                                onChange={(e) =>
                                  handleTextAreaChange(e, i, elsepropName, 'rootcause')
                                }
                              />
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'close',
                                    'rootcause',
                                    `tableElseifroot${elsepropName}`
                                  );
                                }}
                              >
                                Cancel
                              </span>
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'ok',
                                    `tableElseifroot${elsepropName}`
                                  );
                                }}
                              >
                                Ok
                              </span>
                            </div>
                          }
                          visible={props.config[`tableElseifroot${elsepropName}`]}
                          onVisibleChange={(value) =>
                            handleVisibleChange(value, `elseifroot${elsepropName}`)
                          }
                          title=" RootCause Analysis"
                          trigger="click"
                        >
                          <TextParagraph>RootCause Analysis</TextParagraph>
                        </Popover>
                      </Fragment>
                    )}
                  </ButtonBoxDiv>
                  {i === props.config[elsepropName].length - 1 ? (
                    <ActionSelectionDiv>
                      <BlockedLabelBlack>
                        Action<span style={{ color: 'red' }}>*</span>
                      </BlockedLabelBlack>
                      <ActionCheckbox
                        options={actionList}
                        value={props.config[elsepropName][i]['action']}
                        onChange={(checkedValues) => {
                          checkonChange(checkedValues, elsepropName, i);
                        }}
                      />
                      <TimePicker
                        defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                        value={
                          props.config[elsepropName][i]['delay'] === undefined
                            ? undefined
                            : moment(props.config[elsepropName][i]['delay'][1], 'HH:mm:ss')
                        }
                        onChange={(e) => handleDelay(e, elsepropName, i)}
                        placeholder="Select Delay"
                        size="default"
                        allowClear={false}
                      />
                    </ActionSelectionDiv>
                  ) : null}
                </Boxdiv>
              );
            });
          })
        : null}

      {conelse
        ? elseList.map((x, i) => {
            return (
              <Boxdiv key={`else${i}`}>
                {i === 0 ? <BlockedLabel>else</BlockedLabel> : null}
                {i > 0 ? (
                  <TableConditionSelect
                    name="operater"
                    size="default"
                    value={x['54765'] === '' ? undefined : x['54765']}
                    placeholder="Select condition"
                    onChange={(e) => handleConditionchange(e, i, 'elseList')}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </TableConditionSelect>
                ) : null}
                <DeviceSelect
                  placeholder="Select Table"
                  size="default"
                  value={x.device === '' ? undefined : x.device}
                  onChange={(value) => handledeviceSelect(value, i, 'elseList')}
                >
                  {tableList.map((table, i) => {
                    return (
                      <Option key={`ifdeviceList${i}`} value={table.TableName}>
                        {table.TableName}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <ColumnNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder="Select TableName"
                  value={x.tagName === '' ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, 'elseList')}
                >
                  {columnList
                    .filter((param) => param.TableName === x.device)
                    .map((param, j) => {
                      return (
                        <Option key={`elseColumnagname${i}${j}`} value={param.columnnames}>
                          {param.columnnames}
                        </Option>
                      );
                    })}
                </ColumnNameSelect>
                <DeviceSelect
                  placeholder="Device"
                  size="default"
                  value={x.tableDevice === '' ? undefined : x.tableDevice}
                  onChange={(value) => handleTableDeviceSelect(value, i, 'elseList')}
                >
                  {deviceList.map((device, i) => {
                    return (
                      <Option key={`elsetabledeviceList${i}`} value={device.DeviceId}>
                        {device.DeviceId}
                      </Option>
                    );
                  })}
                </DeviceSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder="Select operator"
                  value={x.operater === '' ? undefined : x.operater}
                  onChange={(e) => handleInputChange(e, i, 'select', 'elseList')}
                >
                  <Option value="==">==</Option>
                  <Option value="!=">!=</Option>
                  <Option value=">">{`>`}</Option>
                  <Option value="<">{'<'}</Option>
                  <Option value=">=">{`>=`}</Option>
                  <Option value="<=">{'<='}</Option>
                </OperaterSelect>
                <ValueInput
                  size="default"
                  name="value"
                  placeholder="value"
                  value={x.value}
                  onChange={(e) => handleInputChange(e, i, 'input', 'elseList')}
                />
                <ButtonBoxDiv>
                  {elseList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, 'elseList')}
                    >
                      Remove
                    </MarginRightButton>
                  )}
                  {elseList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={'primary'}
                        size="default"
                        onClick={() => handleAddClick('elseList')}
                      >
                        Add
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]['priventive']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange(e, i, 'elseList', 'priventive')}
                            />
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'elseList',
                                  'close',
                                  'priventive',
                                  `tableElseprevisible`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'elseList', 'ok', `tableElseprevisible`);
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={tableElseprevisible}
                        onVisibleChange={(value) => handleVisibleChange(value, `elseprevisible`)}
                        title="Preventive Measures"
                        trigger="click"
                      >
                        <TextParagraph>Preventive Measures</TextParagraph>
                      </Popover>
                      <Divider type="vertical" />
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]['rootcause']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange(e, i, 'elseList', 'rootcause')}
                            />
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'elseList',
                                  'close',
                                  'rootcause',
                                  `tablElseroot`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'elseList', 'ok', `tablElseroot`);
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={tablElseroot}
                        onVisibleChange={(value) => handleVisibleChange(value, `elseroot`)}
                        title=" RootCause Analysis"
                        trigger="click"
                      >
                        <TextParagraph>RootCause Analysis</TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === elseList.length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      Action<span style={{ color: 'red' }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={actionList}
                      value={props.config.elseList[i]['action']}
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, 'elseList', i);
                      }}
                    />

                    <TimePicker
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        props.config.elseList[i]['delay'] === undefined
                          ? undefined
                          : moment(props.config.elseList[i]['delay'][1], 'HH:mm:ss')
                      }
                      onChange={(e) => handleDelay(e, 'elseList', i)}
                      placeholder="Select Delay"
                      size="default"
                      allowClear={false}
                    />
                  </ActionSelectionDiv>
                ) : null}
              </Boxdiv>
            );
          })
        : null}
      <AddParagraph onClick={handleelse}>Add Else</AddParagraph>
      <AddParagraph onClick={handleelseif}>Add Elseif</AddParagraph>
      <BlankDiv></BlankDiv>
    </StyledAppdiv>
  );
}
