import axios from 'axios';
import { message } from 'antd';
import localStorage from '../../utils/localStorage';
import moment from 'moment';
/**this function is for save rule and param is json  payload  */
export async function saveRule(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const saveRule = {
    method: 'POST',
    url: `/api/ruleEngine/create?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId }
  };
  return axios(saveRule)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      } else if (err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function is to get the rule list created by user based on siteID */
export async function ruleList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const ruleList = {
    method: 'GET',
    url: `/api/ruleEngine/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(ruleList)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getRuleCounts() {
  // return {
  //   ActiveCount: 23,
  //   InActiveCount: 82,
  //   TotalCount: 105
  // };

  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const ruleList = {
    method: 'GET',
    url: `/api/ruleEngine/getStatusCount?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(ruleList)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function is for get the rule details based on ID for edit or update purpose  */
export async function ruleListID(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const getById = {
    method: 'GET',
    url: `/api/ruleEngine/get?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getById);
}
/**this function is for update the rule   */
export async function editRule(key, payLoad) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const updateRule = {
    method: 'PATCH',
    url: `/api/ruleEngine/update?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...payLoad, SiteId: siteId, Id: parseInt(key) }
  };
  return axios(updateRule)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function is for delete the rule  based on rule id  */
export async function deleteRule(key) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deleteRule = {
    method: 'DELETE',
    url: `/api/ruleEngine/delete?SiteId=${siteId}&Id=${parseInt(key)}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deleteRule)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose to get the Device List */
export async function deviceList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'POST',
    url: `/api/device/cache?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Module: '',
      SiteId: siteId
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getCommunicationList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Notification List',
      ActionType: 'read',
      Type: 'WithSiteId',

      Conditions: [
        {
          key: 'Status',
          value: true
        }
      ]
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getparameterChangeList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const parameterChangeList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'appconfig."Site_Master_ParameterTypes"',
      ColumnName: 'Name'
    }
  };
  return axios(parameterChangeList)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getDeviceData(param, hierarchycode) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'POST',
    url: `/api/hierarchymodule/getRuleEngineHierarchyLevelsByParameterType?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ParameterType: param,
      HierarchyCode: hierarchycode
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

/**this function purpose to get the Equipment Type List */
export async function deviceTypeList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'POST',
    /**this url for device TypeList */
    url: `/api/device/getDeviceTypes`,

    headers: {
      Authorization: Authorization
    },
    data: {
      Module: '',
      SiteId: siteId
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to get recipient or user list for mail ID purpose */
export async function recipientList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const recipientListApi = {
    method: 'GET',
    url: `/api/user/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(recipientListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getParamtersListWithType() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const parameterListApi = {
    method: 'GET',
    url: `/api/deviceParameter/getParametersByType?SiteId=${siteId}&Type=RuleEngine`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(parameterListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this funtion is to get the list of parametbased on seleted Equipment ID  */
export async function getparameterList(device, parameter) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const parameterListApi = {
    method: 'POST',
    // url: `/api/device/relatedParams?SiteId=${siteId}`,
    url: `/api/device/getDeviceParamsByType?SiteId=${siteId}`,
    data: {
      Device: device,
      ParameterType: parameter
    },
    headers: {
      Authorization: Authorization
    }
  };
  return axios(parameterListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to get REST API list */
export async function urlList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const urlListApi = {
    method: 'GET',
    url: `/api/ruleEngine/getRestApiList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(urlListApi)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
/**this function purpose is to either enable or disable the rule to trigger notifications  */
export async function updateNotificationStatus(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const upateRuleStatus = {
    method: 'POST',
    url: `/api/ruleEngine/updateNotificationStatus?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...payload, SiteId: siteId }
  };
  return axios(upateRuleStatus)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to either enable or disable the rule to trigger notifications  */
export async function updateRuleStatus(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const upateRuleStatus = {
    method: 'PATCH',
    url: `/api/ruleEngine/updateRuleStatus?SiteId=${siteId}&RuleStatus=${payload.RuleStatus}&Id=${payload.Id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(upateRuleStatus)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/** this function is get noDevice parameters list*/
export async function nodeviceParameterList(deviceListData) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const nodeviceparameterList = {
    method: 'GET',
    url: `/api/ruleEngine/getNonDeviceParametersList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      deviceListData
    }
  };
  // return axios(nodeviceparameterList)
  return axios(nodeviceparameterList)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}

export async function tablesList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const tableList = {
    method: 'GET',
    url: `/api/ruleEngine/getTableList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(tableList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getColumnsList(tables) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const columnList = {
    method: 'POST',
    url: `/api/ruleEngine/getColumnList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      tableId: tables
    }
  };
  return axios(columnList)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is get the cronJob frequency list which was usingon batch Type */
export async function frequencyList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const frequencyList = {
    method: 'GET',
    url: `/api/ruleEngine/getPriorityList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(frequencyList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
/**this function purpose is to get the rule trigger list based on rule selection */
export async function getRuleExecutionInfo(ruleId, value) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `/api/ruleEngine/getRuleEngineLogs?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      RuleId: ruleId,
      StartDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
      EndDate: moment(value[1]).format('YYYY-MM-DD HH:mm'),
      SiteId: siteId,
      SourceType: 'RuleEngine'
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to give  the feedback for already excuted or notification trigger rules */
export async function saveFeedback(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const saveFeedback = {
    method: 'POST',
    url: `/api/ruleEngine/saveFeedbackData?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(saveFeedback)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getfeedBackInfo(ruleId) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'GET',
    url: `/api/ruleEngine/getFeedbackList/${ruleId}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getWarningTypes() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const getWarningTypes = {
    method: 'GET',
    url: `/api/ruleEngine/getWarningTypes?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getWarningTypes)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to updated  the feedback  */
export async function updateFeedBack(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `/api/ruleEngine/updateFeedback?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to get the rootcause  list based on rule executation */
export async function getRootCauseInfo(ruleData) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const getRootCauseInfo = {
    method: 'POST',
    url: `/api/ruleEngine/getRootCause?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      DeviceId: ruleData.DeviceId,
      StartDate: moment(ruleData.selectedDate.startDate).format('YYYY-MM-DD HH:mm'),
      EndDate: moment(ruleData.selectedDate.endDate).format('YYYY-MM-DD HH:mm '),

      ParameterCode: ruleData.ParameterCode,
      WarningType: ruleData.WarningType,

      SiteId: siteId
    }
  };
  return axios(getRootCauseInfo)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
