import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import { Form, Input, InputNumber, TimePicker, message, Button, DatePicker } from 'antd';
import TableView from '../Widgets/TableView';
import SelectComponennt from '../Widgets/SelectComponent';
import 'antd/lib/date-picker/style/css';
import { StyledElogbook } from '../CSS/style';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';
import { TableWithModalView } from '../Widgets';

const { RangePicker } = DatePicker;
const newLocal = true;
class GasLogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      shift: 'daily',
      isOpen: false
    };
  }

  componentDidMount() {
    let { formObject } = this.state;
    formObject = cloneDeep(this.props.formObject);
    if (
      formObject &&
      formObject['form'] &&
      formObject['form'].datasource &&
      formObject['form'].datasource['Date']
    ) {
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['form'] &&
              value['form']['datasource'] &&
              value['form']['datasource']['Date']
                ? (value['form']['datasource']['Date'] = moment(
                    value['form']['datasource']['Date'],
                    'YYYY'
                  ).format('YYYY'))
                : null)
            }
          : _.noop();
      });
    }

    this.setState({
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : [],
      formObject: {
        ...formObject
      }
    });
    // this.props.downTimeData(formObject, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      let formObject = this.props.formObject;
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['form'] &&
              value['form']['datasource'] &&
              value['form']['datasource']['Date']
                ? (value['form']['datasource']['Date'] = moment(
                    value['form']['datasource']['Date'],
                    'YYYY'
                  ).format('YYYY'))
                : null)
            }
          : _.noop();
      });
      this.setState({
        formObject: formObject,
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  onInputChange = (columnValue, columnKey, name, columns, min) => {
    let { formObject } = this.state;
    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          datasource: {
            ...formObject[name].datasource,
            [columnKey]: ''
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          datasource: {
            ...formObject[name].datasource,
            [columnKey]: ''
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (columnKey === 'MeterName' && columns.conditionApi === true) {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          datasource: {
            ...formObject[name].datasource,
            [columnKey]: columnValue
          }
        }
      };
      let payload = {
        Date:
          formObject[name] && formObject[name].datasource && formObject[name].datasource['Date']
            ? formObject[name] &&
              formObject[name].datasource &&
              moment(formObject[name].datasource['Date']).utc().format('YYYY-MM-DD')
            : null,
        Device:
          formObject[name] &&
          formObject[name].datasource &&
          formObject[name].datasource['MeterName']
            ? columnValue
            : null
      };
      const token = localStorage.get('accessToken');
      var Authorization = 'Bearer ' + token;
      const siteId = localStorage.get('currentSite');
      let headers = {
        method: 'POST',
        url: `/api/logbook/getFinalEntry?SiteId=${siteId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Authorization
        },
        data: payload
      };
      axios(headers)
        .then((response) => {
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                InitialReading:
                  response && response.data && response.data[0] && response.data[0].TagValue
                    ? response.data[0].TagValue
                    : null
              }
            }
          };
          this.props.downTimeData(formObject);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (
        columns &&
        columns.validationStep &&
        columns.validationStep.validationStep &&
        columns.validationStep.validationStep.operation &&
        columns.validationStep.validationStep.operation === 'Final_Initial' &&
        columnKey
      ) {
        let { autoGenerateField, mandatoryFields } = columns.validationStep;
        let mandatoryCheck = true;
        if (mandatoryCheck && mandatoryFields && mandatoryFields[0] && mandatoryFields[1]) {
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                [columnKey]: columnValue
              }
            }
          };
          let datasource =
            formObject && formObject[name] && formObject[name].datasource
              ? formObject[name].datasource
              : {};
          let generatedColumn = datasource[mandatoryFields[0]] - datasource[mandatoryFields[1]];
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                [autoGenerateField]: generatedColumn
              }
            }
          };
        }
        this.props.downTimeData(formObject, false);
      } else if (
        columns &&
        columns.validationStep &&
        columns.validationStep.validationStep &&
        columns.validationStep.validationStep.operation &&
        columns.validationStep.validationStep.operation === 'step' &&
        columnKey
      ) {
        let { autoGenerateField, mandatoryFields } = columns.validationStep.validationStep;
        let mandatoryCheck = true;
        if (formObject && formObject[name] && formObject[name]['datasource']) {
          mandatoryFields &&
            Array.isArray(mandatoryFields) &&
            mandatoryFields.length > 0 &&
            mandatoryFields.map((mandField) => {
              if (!formObject[name]['datasource'][mandField]) {
                message.error('Please input ' + mandField);
                mandatoryCheck = false;
              }
              return {};
            });
        }
        if (mandatoryCheck) {
          let step1 =
            mandatoryFields &&
            Array.isArray(mandatoryFields) &&
            mandatoryFields[0] &&
            formObject &&
            formObject[name] &&
            formObject[name]['datasource'] &&
            formObject[name]['datasource'][mandatoryFields[0]]
              ? formObject[name]['datasource'][mandatoryFields[0]]
              : '';

          let spliteDataX = step1.split('X');
          let spliteDatax = step1.split('x');
          let step4 = 0;
          if (spliteDataX[0] && spliteDataX[1]) {
            let formula = (spliteDataX[0] * spliteDataX[1]) / 10000;
            let step3 = formula * formObject[name]['datasource'][mandatoryFields[1]];
            step4 = step3.toFixed(2) * columnValue;
          } else if (spliteDatax[0] && spliteDatax[1]) {
            let formula = (spliteDatax[0] * spliteDatax[1]) / 10000;
            let step3 = formula * formObject[name]['datasource'][mandatoryFields[1]];
            step4 = step3.toFixed(2) * columnValue;
          }

          let sum = 0;

          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                [columnKey]: columnValue,
                [autoGenerateField]: step4 ? Math.round((step4 + Number.EPSILON) * 100) / 100 : 0
              }
            }
          };
          if (columns && columns.defaultAutoGeneratedField && formObject[name]['columns']) {
            let filter = formObject[name]['columns'].filter((col) => {
              return col.key === columns.defaultAutoGeneratedField;
            });

            if (
              filter &&
              filter[0] &&
              filter[0]['autoGenerateColumns'] &&
              Array.isArray(filter[0]['autoGenerateColumns'])
            ) {
              filter[0]['autoGenerateColumns'].map((columnName) => {
                if (formObject[name]['datasource'][columnName]) {
                  sum = sum + formObject[name]['datasource'][columnName];
                }
                return {};
              });
            }

            formObject = {
              ...formObject,
              [name]: {
                ...formObject[name],
                datasource: {
                  ...formObject[name].datasource,
                  [columns.defaultAutoGeneratedField]: sum
                    ? Math.round((sum + Number.EPSILON) * 100) / 100
                    : 0
                }
              }
            };
          }
        }
        this.props.downTimeData(formObject, false);
      } else {
        if (
          formObject &&
          formObject[name] &&
          formObject[name].datasource &&
          !formObject[name].datasource['Date']
        ) {
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                Date:
                  formObject &&
                  formObject[name] &&
                  formObject[name].datasource &&
                  formObject[name].datasource['Date']
                    ? formObject &&
                      formObject[name] &&
                      formObject[name].datasource &&
                      formObject[name].datasource['Date']
                    : this.props.elog &&
                      this.props.elog.logbookID &&
                      this.props.elog.logbookID === 'MC-08 Sorting-Packing Production Logbook'
                    ? moment(this.props.headerValue.CurrentDate).format('YYYY-MM-DD HH:mm:ss')
                    : undefined,
                [columnKey]: columnValue
              }
            }
          };
        } else {
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              datasource: {
                ...formObject[name].datasource,
                [columnKey]: columnValue
              }
            }
          };
        }

        this.props.downTimeData(formObject, false);
      }
    }
  };

  handleKeyPress = () => {};
  onOk = (date, columnKey, name) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            datasource: {
              ...formObject[name].datasource,
              [columnKey]: {}
            }
          }
        };
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            datasource: {
              ...formObject[name].datasource,
              [columnKey]: {
                startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        };
      }
    } else {
      message.error('Please Input Start & End Times');
    }

    this.props.downTimeData(formObject, false);
  };

  onChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    formObject = cloneDeep(this.props.formObject);
    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  disabledDateRange = (formObject, current) => {
    if (
      formObject &&
      formObject['form'] &&
      formObject['form'].datasource &&
      formObject['form'].datasource['Date']
    ) {
      let selectedDate = moment(formObject['form'].datasource['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  onHeaderChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    if (columnKey === 'Date') {
      let columns = [];
      if (formObject[name].columns) {
        columns = formObject[name].columns.map((formCol) => {
          return formCol.key;
        });
      }
      if (
        columns &&
        Array.isArray(columns) &&
        columns.length > 0 &&
        columns.includes('TimeSelection')
      ) {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            datasource: {
              ...(formObject && formObject[name] && formObject[name].datasource
                ? formObject[name].datasource
                : {}),
              [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss'),
              TimeSelection: {}
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            datasource: {
              ...(formObject && formObject[name] && formObject[name].datasource
                ? formObject[name].datasource
                : {}),
              [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
            }
          }
        };
        this.props.downTimeData(formObject, false);
      }
    } else {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          datasource: {
            ...(formObject && formObject[name] && formObject[name].datasource
              ? formObject[name].datasource
              : {}),
            [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  onSelectChange = (columnValue, columnKey, name, columns) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        datasource: {
          ...(formObject && formObject['form'] && formObject['form'].datasource
            ? formObject['form'].datasource
            : {}),
          [columnKey]:
            columnValue &&
            Array.isArray(columnValue) &&
            columns &&
            columns.hierarchy &&
            columns.hierarchy === true
              ? columnValue[columnValue.length - 1]
              : columnValue,
          ...(columnValue &&
          Array.isArray(columnValue) &&
          columns &&
          columns.hierarchy &&
          columns.hierarchy === true
            ? {
                [`${columnKey}` + 1]: columnValue
              }
            : {})
        }
      }
    };
    this.props.downTimeData(formObject);
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    if (columnKey === 'Date') {
      formObject = {
        ...formObject,
        form: {
          ...(formObject && formObject.form ? formObject.form : {}),
          datasource: {
            ...(formObject && formObject.form && formObject.form.datasource
              ? formObject.form.datasource
              : {}),
            [columnKey]: date,
            TimeSelection: formObject.form.datasource.TimeSelection !== '' ? '' : ''
          }
        }
      };
    } else {
      formObject = {
        ...formObject,
        form: {
          ...(formObject && formObject.form ? formObject.form : {}),
          datasource: {
            ...(formObject && formObject.form && formObject.form.datasource
              ? formObject.form.datasource
              : {}),
            [columnKey]: date
            // TimeSelection: {}
          }
        }
      };
    }

    this.props.downTimeData(formObject, false);
  };

  renderSwitch(formObject, columns, name) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            disabled={columns.disabled ? columns.disabled : false}
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name, columns)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              columns && columns.hierarchy && columns.hierarchy === true
                ? formObject['form'] &&
                  formObject['form'].datasource &&
                  formObject['form'].datasource[`${columns.key}` + 1]
                  ? formObject['form'].datasource[`${columns.key}` + 1]
                  : ''
                : formObject['form'] &&
                  formObject['form'].datasource &&
                  formObject['form'].datasource[columns.key]
                ? formObject['form'].datasource[columns.key]
                : ''
            }
            datasource={
              formObject[name] && formObject[name].datasource ? formObject[name].datasource : []
            }
            handleFieldChange={(e) => this.onSelectChange(e, columns.key, name, columns)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              (formObject[name].datasource[columns.key] ||
                formObject[name].datasource[columns.key] === 0)
                ? formObject[name].datasource[columns.key]
                : ''
            }
            disabled={columns.disabled ? columns.disabled : false}
            placeholder={columns.placeHolder}
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            // onChange={(e) => this.onInputChange(e, columns.key, name, columns)}
            // onFocus={(e) => this.inputHeaderNumberChange(columns.min,columns.max,e, columns.key, name,columns)}
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name,
                columns
              )
            }
            // validateTrigger="onBlur"
            onChange={(e) =>
              this.onInputChange(e, columns.key, name, columns, columns.min, columns.max)
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? moment(formObject[name].datasource[columns.key], 'YYYY-MM-DD HH:mm:ss')
                : this.props.elog &&
                  this.props.elog.logbookID &&
                  columns.defaultData &&
                  columns.defaultData === 'today'
                ? formObject[name].datasource[columns.key]
                  ? moment.utc(formObject[name].datasource[columns.key], 'YYYY-MM-DD HH:mm:ss')
                  : moment()
                : null
            }
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            allowClear={false}
            showNow={false}
            showTime={columns.showTime ? columns.showTime : false}
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(current) => this.disabledDate(current, columns)}
            disabled={columns.disabled ? columns.disabled : false}
            onChange={(e, datestring) =>
              this.onDateHeaderMainChange(datestring, columns.key, name, columns)
            }
            style={{ width: 300 }}
          />
        );
      case 'date-pickerTime':
        return (
          <DatePicker
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? moment(formObject[name].datasource[columns.key], 'YYYY-MM-DD HH:00')
                : null
            }
            showTime={true}
            format={'YYYY-MM-DD HH:00'}
            allowClear={false}
            showNow={false}
            disabledDate={(current) => this.disabledDate(current, columns)}
            disabled={columns.disabled ? columns.disabled : false}
            onChange={(e) => this.onHeaderChange(e, columns.key, name, columns)}
            style={{ width: 300 }}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key] &&
              formObject[name].datasource[columns.key]['startDate'] &&
              formObject[name].datasource[columns.key]['endDate']
                ? [
                    moment(formObject[name].datasource[columns.key]['startDate'], dateFormat),
                    moment(formObject[name].datasource[columns.key]['endDate'], dateFormat)
                  ]
                : null
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            format="YYYY-MM-DD HH:mm"
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            onOk={(e) => this.onOk(e, columns.key, name, columns)}
            // onCalendarChange={(e) => this.onChange(e, columns.key, name, columns)}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            use12Hours
            format="h:mm:ss A"
            value={
              formObject[name] &&
              formObject[name].datasource &&
              (formObject[name].datasource[columns.key] ||
                formObject[name].datasource[columns.key] === 0)
                ? moment(formObject[name].datasource[columns.key], 'h:mm:ss A')
                : ''
            }
            style={{ width: 300 }}
            onChange={(e, string) => this.onInputChange(string, columns.key, name, columns)}
          />
        );
      case 'yearPicker':
        return (
          <DatePicker
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? moment(formObject[name].datasource[columns.key], 'YYYY')
                : null
            }
            open={this.state.isOpen}
            mode="year"
            placeholder="Select Year"
            format="YYYY"
            onOpenChange={(status) => {
              if (status) {
                this.setState({ isOpen: true });
              } else {
                this.setState({ isOpen: false });
              }
            }}
            onPanelChange={(v) => {
              formObject = {
                ...formObject,
                form: {
                  ...(formObject && formObject.form ? formObject.form : {}),
                  datasource: {
                    ...(formObject && formObject.form && formObject.form.datasource
                      ? formObject.form.datasource
                      : {}),
                    [columns.key]: moment(v).format('YYYY')
                  }
                }
              };
              this.props.downTimeData(formObject, false);
            }}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name, columns)}
          />
        );
    }
  }
  disabledRangeTime = (current) => {
    if (current !== null) {
      let { shift, formObject } = this.state;
      if (
        formObject &&
        formObject['form'] &&
        formObject['form'].datasource &&
        formObject['form'].datasource['Date']
      ) {
        let selectedDate = moment(formObject['form'].datasource['Date']).format('YYYY-MM-DD');
        let currentDate = moment(current).format('YYYY-MM-DD');
        let range = (start, end) => {
          const result = [];
          for (let i = start; i < end; i++) {
            result.push(i);
          }
          return result;
        };
        if (shift === 'Morning Shift' && selectedDate === currentDate) {
          return {
            // disabledHours: () => range(18, 18),
            disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
            disabledMinutes: () => range(60, 60)
            // disabledSeconds: () => [55, 56],
          };
        } else if (shift === 'Night Shift' && selectedDate === currentDate) {
          return {
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
            disabledMinutes: () => range(60, 60)
          };
        } else if (shift === 'Night Shift' && selectedDate !== currentDate) {
          return {
            disabledHours: () => [
              6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
            ],
            disabledMinutes: () => range(60, 60)
          };
        } else if (selectedDate === currentDate) {
          return {
            disabledHours: () => [0, 1, 2, 3, 4, 5],
            disabledMinutes: () => range(60, 60)
          };
        } else if (selectedDate !== currentDate) {
          return {
            disabledHours: () => [
              6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
            ]
          };
        }
      }

      return {
        disabledHours: () => [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
        ]
      };
    }
  };

  disabledDate = (current, columns) => {
    if (columns.showTime) {
      const { formObject } = this.props;
      if (formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']) {
        if (formObject.mainHeaderValue.Date !== undefined) {
          let currentDate = moment(current).format('YYYY-MM-DD');
          let Date = moment(formObject.mainHeaderValue.Date).format('YYYY-MM-DD');
          let addedDate = moment(formObject.mainHeaderValue.Date)
            .add(1, 'day')
            .format('YYYY-MM-DD');
          if (moment(currentDate).isSame(Date)) {
            return false;
          } else if (moment(currentDate).isSame(addedDate)) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else if (
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
      ) {
        if (formObject.form.datasource.Date !== undefined) {
          let currentDate = moment(current).format('YYYY-MM-DD');
          let Date = moment(formObject.form.datasource.Date).format('YYYY-MM-DD');
          let addedDate = moment(formObject.form.datasource.Date)
            .add(1, 'day')
            .format('YYYY-MM-DD');
          if (moment(currentDate).isSame(Date)) {
            return false;
          } else if (moment(currentDate).isSame(addedDate)) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }
    }
    if (!columns.showTime) {
      return current > moment().endOf('day');
    }
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };
  fetchData = (formObject) => {
    let payload = {
      date:
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
          ? moment(
              formObject &&
                formObject['form'] &&
                formObject['form']['datasource'] &&
                formObject['form']['datasource']['Date']
            ).format('YYYY-MM-DD')
          : {},
      EquipmentName:
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
          ? formObject &&
            formObject['form'] &&
            formObject['form']['datasource'] &&
            formObject['form']['datasource']['EquipmentName']
          : ''
    };
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let headers = {
      method: 'POST',
      url: '/api/logbook/copy',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      },
      data: payload
    };
    axios(headers)
      .then((response) => {
        if (response && response.data && Array.isArray(response.data) && response.data[0]) {
          let fetchData = response.data[0];
          formObject = {
            ...formObject,
            form: {
              ...formObject['form'],
              datasource: {
                ...formObject['form'].datasource,
                ...fetchData
              }
            }
          };
          this.props.downTimeData(formObject, false);
        } else if (response && response.data && response.data.Error) {
          if (
            formObject &&
            formObject['form'] &&
            formObject['form'] &&
            formObject['form'].datasource
          ) {
            let newObj = {
              Date:
                formObject &&
                formObject['form'] &&
                formObject['form'] &&
                formObject['form'].datasource &&
                formObject['form'].datasource['Date'],
              EquipmentName:
                formObject &&
                formObject['form'] &&
                formObject['form'] &&
                formObject['form'].datasource &&
                formObject['form'].datasource['EquipmentName']
            };
            formObject = {
              ...formObject,
              form: {
                ...formObject['form'],
                datasource: {
                  ...newObj
                }
              }
            };
            this.props.downTimeData(formObject, false);
          }
          message.error(response.data.Error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };
  inputHeaderNumberChange = (minValue, maxValue, value, key, name, columns) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputChange(parseInt(value), key, name, columns, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputChange(parseInt(value), key, name, columns, minValue, maxValue);
    } else {
      this.onInputChange('', key, name, columns, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  saveTableViewData = (datasource) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      table: {
        ...(formObject && formObject['table'] ? formObject['table'] : {}),
        datasource
      }
    };
    this.setState({ formObject });
    this.props.downTimeData(formObject, false);
  };

  render() {
    let { formObject } = this.state;
    return (
      <StyledElogbook>
        {this.props.elog &&
        this.props.elog.logbookID &&
        this.props.elog.logbookID === 'MC-08 Sorting-Packing Production Logbook' ? (
          <Button
            size="large"
            className="saveButton"
            style={{ float: 'right' }}
            onClick={() => this.fetchData(formObject)}
          >
            Fetch
          </Button>
        ) : null}

        <Form className="formCustomLabel" layout="vertical">
          <div
            style={{
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
            className="formRowWrap"
          >
            {formObject &&
              Object.keys(formObject).map((name, index) => {
                return (
                  <div key={index} className="formRowWrap">
                    {name !== 'table' && formObject && formObject[name] && formObject[name].columns
                      ? formObject[name].columns.map((columns) => {
                          if (
                            columns &&
                            columns.show &&
                            columns.show.key &&
                            columns.show.value &&
                            formObject &&
                            formObject[name] &&
                            formObject[name].datasource &&
                            (!formObject[name].datasource[columns.show.key] ||
                              formObject[name].datasource[columns.show.key] !== columns.show.value)
                          ) {
                            return null;
                          } else {
                            return (
                              <div key={index} className="subSectionFormBody">
                                <Form.Item
                                  required={false}
                                  label={
                                    <span style={{ marginLeft: columns.marginLeft }}>
                                      {columns.displayName}
                                    </span>
                                  }
                                >
                                  <div>{this.renderSwitch(formObject, columns, name)}</div>
                                </Form.Item>
                              </div>
                            );
                          }
                        })
                      : null}
                  </div>
                );
              })}
          </div>
        </Form>

        {formObject && formObject['table'] && formObject['table'].columns ? (
          <div>
            <div>
              {formObject &&
              formObject['table'] &&
              formObject['table'].columns &&
              formObject['table'].tableType === 'TableWithModalView' ? (
                <TableWithModalView
                  form={formObject['table']}
                  recordType={'FormSubSectionTable'}
                  saveTableViewData={(e) => this.saveTableViewData(e)}
                  showAddButton={
                    formObject && formObject['table'] && formObject['table'].showAddButton
                      ? formObject['table'].showAddButton
                      : false
                  }
                  translationCache={this.props.translationCache}
                />
              ) : formObject && formObject['table'] && formObject['table'].columns ? (
                <TableView
                  form={formObject['table']}
                  recordType={'FormSubSectionTable'}
                  saveTableViewData={(e) => this.saveTableViewData(e)}
                  showAddButton={
                    formObject['table'].showAddButton ? formObject['table'].showAddButton : false
                  }
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </StyledElogbook>
    );
  }
}

export default GasLogForm;
