import React, { Component } from 'react';
import { Select } from 'antd';
import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import ReportTypeComponent from './ReportTypeComponent';
import { getReportTypeList, _getReportList } from './reportCalls';
import { StyledDashboard } from './styles';

const { Option } = Select;

class ReportTypeConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'Generate JSON',

      reportList: [],
      reportTypeList: [],

      reportObject: {},
      reportType: '',

      reportName: '',

      editObj: {},
      filteredObject: {},

      editList: [],
      tabName: ''
    };
  }

  componentDidMount() {
    this._getReportListData();
    this._getReportTypeList();
  }

  _getReportListData = async () => {
    let reportList = await _getReportList();
    this.setState({ reportList });
  };
  _getReportTypeList = async () => {
    let reportTypeList = await getReportTypeList();
    this.setState({ reportTypeList });
  };

  onChangeRadio = (e) => {
    this.setState({
      currentTab: e.target.value,
      editObj: {}
    });
  };

  handleChange = (value) => {
    let { reportList, editList } = this.state;

    let filteredObject = reportList.filter((key) => key.ReportName === value);
    this._getReportJsonList(filteredObject[0]);
    this.setState({
      reportObject: filteredObject[0],
      reportName: value,
      reportType:
        editList && filteredObject[0] && filteredObject[0].ReportType
          ? filteredObject[0].ReportType
          : ''
    });
  };

  _getReportJsonList = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/report/get?SiteId=${siteId}&ReportName=${payload.ReportName}&ReportType=${payload.ReportType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...payload, SiteId: siteId }
    };
    axios(deviceTypeObject)
      .then((response) => {
        let editList = response.data.map((edit) => {
          let newObj = {
            ...edit,
            editEditor: edit.JSON
          };
          return newObj;
        });

        this.setState({
          editList,
          editObj:
            editList && editList[0] && editList[0].JSON && editList[0].JSON ? editList[0].JSON : {},

          tabName: editList && editList[0].TabName
        });
      })
      .catch(function () {});
  };

  handleReportTypeChange = (reportType) => {
    this.setState({
      reportType,
      editObj: {}
    });
  };

  render() {
    let { currentTab, reportList, editObj, reportType, reportObject, reportName, tabName } =
      this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173, background: '#a6b9cc' }}>
        <div>
          <Select
            placeholder={'Please select report'}
            style={{ width: 250 }}
            onChange={this.handleChange}
          >
            {reportList &&
              Array.isArray(reportList) &&
              reportList.map((report, index) => {
                if (report.ReportType === 'Report') {
                  return (
                    <Option key={index} value={report.ReportName}>
                      {report.ReportName}
                    </Option>
                  );
                }
                return {};
              })}
          </Select>
          {reportName !== '' ? (
            <ReportTypeComponent
              editObj={editObj ? editObj : {}}
              reportType={reportType}
              reportName={reportName}
              currentTab={currentTab}
              reportObject={reportObject}
              updatefunction={this._getReportJsonList}
              tabName={tabName}
            />
          ) : null}
        </div>
      </StyledDashboard>
    );
  }
}

export default ReportTypeConfiguration;
