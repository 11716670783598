import React, { Component } from 'react';
import moment from 'moment';
import { Table, Input, Upload, Button, Tooltip, message, InputNumber, TimePicker } from 'antd';

import SelectComponent from './SelectComponent';
import localStorage from '../../../../utils/localStorage';
import KeyComponent from './KeyComponent';
import { deleteFileUpload } from '../eLogCalls';

import styles from '../CSS/style.less';
import { lightTheme } from '../CSS/style.js';
import '../CSS/eLog.css';
import { EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      modal: props.form && props.form.modal ? props.form.modal : [],
      data:
        props.form && props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : [],
      open: false,
      mainHeaderValue: props.mainHeaderValue ? props.mainHeaderValue : {},
      subHeaderValue: props.subHeaderValue ? props.subHeaderValue : {},
      optionList: []
    };
  }

  componentDidMount = async () => {
    let optionList = [];
    if (
      this.props.form.columns[0] &&
      this.props.form.columns[0].table &&
      this.props.form.columns[0].table.responseType
    ) {
      optionList = await this.getDropdownList(this.props.form.columns[0]);
      this.setState({
        optionList
      });
    }

    let columns = this.generateColumns(
      this.props.form && this.props.form.columns ? this.props.form.columns : [],
      this.props.mainHeaderValue ? this.props.mainHeaderValue : {},
      this.props.subHeaderValue ? this.props.subHeaderValue : {}
    );
    this.setState({
      form: this.props.form,
      columns,
      modal: this.props.form && this.props.form.modal ? this.props.form.modal : [],
      data:
        this.props.form && this.props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : []
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.form !== this.props.form ||
      prevProps.mainHeaderValue !== this.props.mainHeaderValue ||
      prevProps.subHeaderValue !== this.props.subHeaderValue
    ) {
      let columns = this.generateColumns(
        this.props.form.columns,
        this.props.mainHeaderValue,
        this.props.subHeaderValue
      );
      this.setState({
        form: this.props.form,
        columns,
        data:
          this.props.form && this.props.form.datasource
            ? this.props.form.datasource.map((dataSource, index) => {
                return {
                  ...dataSource,
                  key: index + 1
                };
              })
            : []
      });
    }
  }

  getDropdownList = (column) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let objectHeader = {
      method: column.table.method,
      url: `${column.table.url}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: column.table.payload
    };
    return axios(objectHeader).then((response) => {
      if (Array.isArray(response.data)) {
        return response.data;
      }
    });
  };

  generateColumns = (columns, mainHeaderValue, subHeaderValue) => {
    let { translationCache } = this.props;
    let { optionList } = this.state;

    let finalColumns = [];
    if (columns && columns.length > 0) {
      finalColumns = columns.map((column) => {
        return {
          title:
            translationCache && translationCache[column.title]
              ? translationCache[column.title]
              : column.title,
          dataIndex: column.key || 0,
          key: column.key || 0,
          width: column.width,
          widget: column.widget,
          required: column.required,
          table: column.table || {},
          render: (text, record) => {
            if (record.editable) {
              return this.renderWidget(
                column,
                text,
                record,
                columns,
                mainHeaderValue,
                subHeaderValue
              );
            }

            if (Array.isArray(text)) {
              return text.map((file, index) => {
                return (
                  <div key={index}>
                    <a href={file.url}>{file.name}</a>
                  </div>
                );
              });
            } else if (column.widget === 'timePicker') {
              return text;
            } else if (column.key === 'name') {
              return (
                <div>
                  <a
                    style={{ color: '#062d57' }}
                    href={record.base64}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                </div>
              );
            } else if (
              column &&
              column.table &&
              column.table.responseType &&
              column.table.responseType === 'arrayOfObject'
            ) {
              let filteredObject = [];
              optionList &&
                Array.isArray(optionList) &&
                optionList.filter((optionData) => {
                  if (optionData.Value === text) {
                    filteredObject.push(optionData.Key);
                  }
                  return {};
                });
              return filteredObject && filteredObject[0] ? filteredObject[0] : text;
            } else {
              return text && text !== 'null' ? text : '';
            }
          }
        };
      });
    }
    return finalColumns;
  };

  deleteFile = async (file) => {
    if (file && file.response && file.response.name) {
      await deleteFileUpload(file.response.name);
      message.success('File Deleted');
    } else {
      message.error('Error deleting file');
    }
  };

  onFileChange = (info, column, text, record) => {
    if (info.file.status === 'done') {
      let fieldValue = [];
      if (text && Array.isArray(text) && text !== undefined) {
        fieldValue = [...text, info.file.response];
      } else if (!text) {
        fieldValue = [info.file.response];
      }
      this.handleFieldChange(fieldValue, column.key, record.key);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      this.deleteFile(info.file);
      this.handleFieldChange(info.fileList, column.key, record.key);
    }
  };

  renderWidget = (column, text, record, columns, mainHeaderValue, subHeaderValue) => {
    const token = localStorage.get('accessToken');
    let defaultFileList = text && Array.isArray(text) ? text : [];
    var Authorization = 'Bearer ' + token;
    const renderProps = {
      name: 'file',
      action: `/api/master/fileupload`,
      headers: {
        Authorization: Authorization
      },
      defaultFileList
    };
    switch (column.widget) {
      case 'input':
        return (
          <Input
            autoFocus
            value={text && text !== 'null' ? text : ''}
            style={{ width: '300px', fontWeight: 'bold' }}
            onChange={(e) => this.handleFieldChange(e.target.value, column.key, record.key)}
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            onKeyPress={(e) => this.handleKeyPress(e.target.value, record.key)}
            placeholder={column.placeHolder || column.displayName}
            disabled={column.disable ? column.disable : false}
          />
        );
      case 'inputNumber':
        return column.widgetCondition &&
          column.widgetCondition === true &&
          record.widget !== undefined &&
          record.widget !== true ? (
          <InputNumber value="" disabled style={{ width: '90%', fontWeight: 'bold' }} />
        ) : (
          <InputNumber
            value={text}
            style={{ width: '300px', fontWeight: 'bold' }}
            // min={column.min !== undefined ? column.min : 0}
            // max={column.max !== undefined ? column.max : 1000000}
            // onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            onBlur={(e) =>
              this.inputNumberChange(column.min, column.max, e.target.value, column.key, record.key)
            }
            onChange={(e) =>
              this.handleFieldChange(e, column.key, record.key, column.min, column.max)
            }
            onKeyPress={(e) => this.handleKeyPress(e.target.value, record.key)}
            placeholder={column.placeHolder || column.displayName}
          />
        );
      case 'textComponent':
        return (
          <KeyComponent
            data={this.state.data}
            column={column}
            record={record}
            text={text}
            datasource={mainHeaderValue ? mainHeaderValue : {}}
            subdatasource={subHeaderValue ? subHeaderValue : {}}
            handleDataChange={this.handleDataChange}
            // handleKeyPress={this.handleKeyPress}
          />
        );
      case 'text':
        return this.TextComponent(text, columns);

      case 'select':
        return (
          <SelectComponent
            data={this.state.data}
            column={column}
            record={record}
            text={text}
            datasource={mainHeaderValue ? mainHeaderValue : {}}
            subdatasource={subHeaderValue ? subHeaderValue : {}}
            handleFieldChange={this.handleFieldChange}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'upload':
        return (
          <Upload
            {...renderProps}
            onChange={(info) => this.onFileChange(info, column, text, record)}
          >
            <Button>
              <UploadOutlined /> Upload
            </Button>
          </Upload>
        );
      case 'timePicker':
        return (
          <TimePicker
            format="HH:mm:ss"
            value={text && text !== 'Invalid date' ? moment(text, 'HH:mm:ss') : undefined}
            style={{ width: 300 }}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            onOpenChange={() => this.setState({ open: true })}
            open={this.state.open}
            addon={() => (
              <Button size="small" type="primary" onClick={() => this.setState({ open: false })}>
                Ok
              </Button>
            )}
          />
        );
      default:
        return text;
    }
  };
  TextComponent = (text) => {
    let { optionList } = this.state;
    let optionFiltered =
      optionList &&
      Array.isArray(optionList) &&
      optionList.filter((option) => {
        return option.Value === text;
      });
    return optionFiltered && optionFiltered[0] && optionFiltered[0].Key
      ? optionFiltered[0].Key
      : text;
  };

  saveRow(e, key, record) {
    let { columns } = this.state;
    e.persist();
    this.setState({
      loading: true
    });
    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }
    const target = this.getRowByKey(key) || {};
    let checkReqField = false;
    columns &&
      Array.isArray(columns) &&
      columns.map((columnsObject) => {
        if (
          columnsObject['required'] &&
          (columnsObject['required'] === 'true' || columnsObject['required'] === true)
        ) {
          if (
            target[columnsObject.key] ||
            (target[columnsObject.key] === 0 && target[columnsObject.key] !== '') ||
            (record && record.widget === false)
          ) {
            return {};
          } else {
            e.target.focus();
            checkReqField = true;
          }
        }
        return {};
      });
    if (checkReqField) {
      message.warn('Please fill in the complete information');
    } else {
      this.saveToggleEditable(e, key);
      this.setState({
        loading: false
      });
    }
  }

  editRow(key) {
    this.toggleEditable(key);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key]);
        delete this.cacheOriginData[key];
      }
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable && this.cacheOriginData) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
      //  Save to Backend
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
    }
  };
  remove(key) {
    const { data } = this.state;
    if (key.isNew) {
      const newData = data.filter((item) => item.key !== key.key);
      this.setState({ data: newData });
      //Delete BAckend Call
    } else {
      message.error('Cant delete this record..');
    }
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  inputNumberChange = (minValue, maxValue, value, key, record) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.handleFieldChange(value, key, record, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.handleFieldChange(value, key, record, minValue, maxValue);
    } else {
      this.handleFieldChange('', key, record, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  handleFieldChange = async (fieldValue, fieldName, keyValue, min) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(keyValue, newData);
    // if (fieldValue < 0 && typeof (fieldValue) === "number" && min>0) {
    //   message.error("Please Input Positive Values  Only")
    //   target[fieldName] = ""
    //   this.setState({ data: newData });
    // }

    if (min !== undefined && min > 0 && typeof fieldValue === 'number' && fieldValue < 0) {
      message.error('Please Input Positive Values  Only');
      target[fieldName] = '';
      this.setState({ data: newData });
    } else if (min === undefined && typeof fieldValue === 'number' && fieldValue < 0) {
      message.error('Please Input Positive Values  Only');
      target[fieldName] = '';
      this.setState({ data: newData });
    } else {
      if (target) {
        if (fieldName === 'StartTime') {
          target[fieldName] = moment(fieldValue).format('HH:mm:ss');
        } else {
          target[fieldName] = fieldValue;
        }
        this.setState({ data: newData });
      }
    }
  };

  handleDataChange = (fieldValue, fieldName) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    if (newData) {
      newData.map((item, key) => {
        newData[key][fieldName] = fieldValue;
        return {};
      });
      this.setState({ data: newData });
    }
    this.props.saveTableViewData(newData);
  };

  addRow = () => {
    let { data } = this.state;
    //  let dataLength = data.length + 1;
    let dataLength;
    // if (typeof data[2].SNo === 'string') {
    //   dataLength = `RM${data.length + 1}`;
    // } else {
    dataLength = data.length + 1;
    // }
    let newdata = {
      key: dataLength,
      SNo: dataLength
    };
    data.push(newdata);
    this.setState({ data });
    this.props.saveTableViewData(data);
  };

  render() {
    let { recordType, loading, form, translationCache } = this.props;
    let { action } = form;
    let { columns, data } = this.state;
    if (action && action === true) {
      if (recordType !== 'Approver' && this.props.status !== 'Completed') {
        columns = [
          ...columns,
          {
            title:
              translationCache && translationCache['Action']
                ? translationCache['Action']
                : 'Action',
            key: 'action',
            render: (text, record) => {
              if (!!record.editable && loading) {
                return null;
              }
              if (record.editable) {
                if (record.isNew) {
                  return (
                    <span>
                      <Tooltip placement="top" trigger="hover" title={'Save'}>
                        <Button onClick={(e) => this.saveRow(e, record.key, record)}>
                          <SaveOutlined
                            style={{ fontSize: '20px', color: '#193652' }}
                            type="save"
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  );
                }
                return (
                  <span>
                    {/* <Tooltip placement="top" trigger="hover" title={"Save"}> */}
                    <Button onClick={(e) => this.saveRow(e, record.key, record)}>
                      <SaveOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                    </Button>
                    {/* </Tooltip> */}
                  </span>
                );
              } else {
                return (
                  <span>
                    {/* <Tooltip placement="top" trigger="hover" title={"Edit"}> */}
                    <Button onClick={(e) => this.editRow(record.key, e)}>
                      <EditOutlined style={{ fontSize: '20px', color: '#193652' }} type="edit" />
                    </Button>
                    {/* </Tooltip> */}
                  </span>
                );
              }
            }
          }
        ];
      }
    }
    return (
      <div className="tableView">
        <div>
          <Table
            theme={lightTheme}
            className="customTable"
            loading={loading}
            columns={columns}
            // scroll={{ x: 2000 }}
            // size={"small"}
            dataSource={data}
            pagination={false}
            style={{ marginTop: '10px', border: '1px solid  red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
          />
          {form && form.showAddButton ? (
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              onClick={this.addRow}
              disabled={this.props.status && this.props.status === 'Completed' ? true : false}
            >
              {translationCache && translationCache['Add Row'] && translationCache['Add Row']
                ? translationCache['Add Row']
                : 'Add Row'}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TableView;
