/* eslint-disable no-unused-vars */
import axios from 'axios';
import moment from 'moment';
import localStorage from '../../../utils/localStorage';

export async function getChartDataWithoutSelection(
  graphprops,
  abortController,
  dropdownSelectionn,
  selectionDateLive,
  deviceCode,
  timeZone
) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let dropdownSelection = {};
  if (graphprops && graphprops.dashboardType === 'multi' && dropdownSelectionn) {
    dropdownSelection = dropdownSelectionn;
  }
  // let currentDay = moment(selectionDateLive).utc().format('YYYY-MM-DD 00:00:00');
  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      dashboardType: 'static',
      type: graphprops.type || 'historic',
      siteId,
      startDate: graphprops.startDate,
      endDate: graphprops.endDate,
      timeBucket: '1h',
      timeZone,
      date: moment(selectionDateLive).format('YYYY-MM-DD HH:mm:ss'),
      ...dropdownSelection,
      ...(deviceCode ? { deviceCode } : {})
    }),
    signal: abortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}

export async function getChartWarningSelection(
  graphprops,
  deviceCode,
  selectionDate,
  selectionMonth,
  selectionWeek,
  shift,
  selectionDateRange,
  dayMonthYear,
  abortController,
  dropdownSelection,
  dashboardPayload,
  AssetCode,
  selectionDateLive,
  timeZone
) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};
  let treePayload = {};
  let payload = {
    Name: graphprops && graphprops.dashboardName,
    PanelName: graphprops && graphprops.title,
    Id: graphprops && graphprops.id,
    type: graphprops && graphprops.type ? graphprops.type : 'historic',
    timeZone,
    date: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm'),
    dashboardType: 'static',
    siteId,
    startDate: timeZone
      ? moment
          .tz(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().subtract(1, 'hour').utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().utc().format('YYYY-MM-DD HH:mm:ss'),

    timeBucket: '1h'
  };
  if (graphprops.dashboardType === 'dynamic' || deviceCode) {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }

  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDate, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePickerLive') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDateLive, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    let monthslipt = selectionMonth;
    let currentYear = moment().format('YYYY-MM');
    let selectedYear = monthYear[0];
    let selectedmonth = monthYear[1];
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      deviceCode,
      selectionType: 'month',
      startDate: moment
        .tz([monthYear[0], monthYear[1]], timeZone)
        .startOf('month')
        .subtract('month', 1)
        .utc()
        .format(`YYYY-MM-DD HH:mm:ss`),
      endDate:
        monthslipt === currentYear
          ? moment().subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz([selectedYear, selectedmonth - 1], timeZone)
              .endOf('month')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.weekExists === 'true') {
    let currentWeek = moment().startOf('week');
    newWeekPayload = {
      weekExists: true,
      startDate: moment
        .tz(selectionWeek, timeZone)
        .startOf('week')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: selectionWeek.isSame(currentWeek)
        ? moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        : moment.tz(selectionWeek, timeZone).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }

  if (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: moment
        .tz(
          selectionDateRange && selectionDateRange.startDate ? selectionDateRange.startDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment
        .tz(
          selectionDateRange && selectionDateRange.endDate ? selectionDateRange.endDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dayMonthYearExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    if (graphprops.type === 'summary') {
      // Customized for Enewood Power Generation (Summary Data)
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        startDate: moment(dayMonthYear && dayMonthYear.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dayMonthYear && dayMonthYear.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeZone
      };
    } else {
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        startDate: moment
          .tz(dayMonthYear && dayMonthYear.startDate ? dayMonthYear.startDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment
          .tz(dayMonthYear && dayMonthYear.endDate ? dayMonthYear.endDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }

  if (graphprops.treeExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    treePayload = {
      AssetCode: AssetCode
    };
  }

  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload,
    ...treePayload
  };

  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/WarningLimits/getWarningData?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
export async function getChartDataWithSelection(
  graphprops,
  deviceCode,
  selectionDate,
  selectionMonth,
  selectionWeek,
  shift,
  selectionDateRange,
  dayMonthYear,
  abortController,
  dropdownSelection,
  dashboardPayload,
  AssetCode,
  selectionDateLive,
  timeZone
) {
  let siteId = localStorage.get('currentSite');

  const token = localStorage.get('accessToken');

  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};
  let treePayload = {};
  let payload = {
    Name: graphprops && graphprops.dashboardName,
    PanelName: graphprops && graphprops.title,
    Id: graphprops && graphprops.id,
    type: (graphprops && graphprops.type) || 'historic',
    timeZone,
    date: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm'),
    dashboardType: 'static',
    siteId,
    startDate: timeZone
      ? moment
          .tz(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().subtract(1, 'hour').utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().utc().format('YYYY-MM-DD HH:mm:ss'),

    timeBucket: '1h'
  };
  if (graphprops.dashboardType === 'dynamic' || deviceCode) {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDate, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePickerLive') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDateLive, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    let monthslipt = selectionMonth;
    let currentYear = moment().format('YYYY-MM');
    let selectedYear = monthYear[0];
    let selectedmonth = monthYear[1];
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      deviceCode,
      selectionType: 'month',
      startDate: moment
        .tz([monthYear[0], monthYear[1]], timeZone)
        .startOf('month')
        .subtract('month', 1)
        .utc()
        .format(`YYYY-MM-DD HH:mm:ss`),
      endDate:
        monthslipt === currentYear
          ? moment().subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz([selectedYear, selectedmonth - 1], timeZone)
              .endOf('month')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.weekExists === 'true') {
    let currentWeek = moment().startOf('week');
    newWeekPayload = {
      weekExists: true,
      startDate: moment
        .tz(selectionWeek, timeZone)
        .startOf('week')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: selectionWeek.isSame(currentWeek)
        ? moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        : moment.tz(selectionWeek, timeZone).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }

  if (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: moment
        .tz(
          selectionDateRange && selectionDateRange.startDate ? selectionDateRange.startDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment
        .tz(
          selectionDateRange && selectionDateRange.endDate ? selectionDateRange.endDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.dayMonthYearExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    if (graphprops.type === 'summary') {
      // Customized for Enewood Power Generation (Summary Data)
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        startDate: moment(dayMonthYear && dayMonthYear.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dayMonthYear && dayMonthYear.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeZone
      };
    } else {
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        startDate: moment
          .tz(dayMonthYear && dayMonthYear.startDate ? dayMonthYear.startDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment
          .tz(dayMonthYear && dayMonthYear.endDate ? dayMonthYear.endDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }

  if (graphprops.treeExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    treePayload = {
      AssetCode: AssetCode
    };
  }
  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload,
    ...treePayload
  };
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}

export async function getChartDataWithSelectionPrediction(
  graphprops,
  deviceCode,
  selectionDate,
  selectionMonth,
  selectionWeek,
  shift,
  selectionDateRange,
  dayMonthYear,
  abortController,
  dropdownSelection,
  dashboardPayload,
  AssetCode,
  selectionDateLive,
  timeZone
) {
  let siteId = localStorage.get('currentSite');

  const token = localStorage.get('accessToken');

  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};
  let treePayload = {};
  let payload = {
    Name: graphprops && graphprops.dashboardName,
    predictionType: 'predictions',
    PanelName: graphprops && graphprops.title,
    Id: graphprops && graphprops.id,
    type: (graphprops && graphprops.type) || 'historic',
    timeZone,
    date: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm'),
    dashboardType: 'static',
    siteId,
    startDate: timeZone
      ? moment
          .tz(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().subtract(1, 'hour').utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().utc().format('YYYY-MM-DD HH:mm:ss'),

    timeBucket: '1h'
  };
  if (graphprops.dashboardType === 'dynamic' || deviceCode) {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDate, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePickerLive') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDateLive, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    let monthslipt = selectionMonth;
    let currentYear = moment().format('YYYY-MM');
    let selectedYear = monthYear[0];
    let selectedmonth = monthYear[1];
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      deviceCode,
      selectionType: 'month',
      startDate: moment
        .tz([monthYear[0], monthYear[1]], timeZone)
        .startOf('month')
        .subtract('month', 1)
        .utc()
        .format(`YYYY-MM-DD HH:mm:ss`),
      endDate:
        monthslipt === currentYear
          ? moment().subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz([selectedYear, selectedmonth - 1], timeZone)
              .endOf('month')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.weekExists === 'true') {
    let currentWeek = moment().startOf('week');
    newWeekPayload = {
      weekExists: true,
      startDate: moment
        .tz(selectionWeek, timeZone)
        .startOf('week')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: selectionWeek.isSame(currentWeek)
        ? moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        : moment.tz(selectionWeek, timeZone).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }

  if (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: moment
        .tz(
          selectionDateRange && selectionDateRange.startDate ? selectionDateRange.startDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment
        .tz(
          selectionDateRange && selectionDateRange.endDate ? selectionDateRange.endDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.dayMonthYearExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    if (graphprops.type === 'summary') {
      // Customized for Enewood Power Generation (Summary Data)
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        startDate: moment(dayMonthYear && dayMonthYear.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dayMonthYear && dayMonthYear.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeZone
      };
    } else {
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        startDate: moment
          .tz(dayMonthYear && dayMonthYear.startDate ? dayMonthYear.startDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment
          .tz(dayMonthYear && dayMonthYear.endDate ? dayMonthYear.endDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }

  if (graphprops.treeExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    treePayload = {
      AssetCode: AssetCode
    };
  }
  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload,
    ...treePayload
  };

  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/Abnormalprediction?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
export async function historicChartData(
  graphprops,
  abortController,
  dropdownSelectionn,
  selectionDateLive,
  deviceCode,
  timeZone
) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      dashboardType: 'static',
      siteId,
      date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      startDate: moment(graphprops.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(graphprops.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      duration: graphprops.duration,
      durationType: graphprops.durationType,
      timeZone,
      ...(deviceCode ? { deviceCode } : {})
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return [];
    });

  return data;
}

export async function historicWithPayloadTableChartData(
  graphprops,
  deviceCode,
  selectionDate,
  selectionDateLive,
  selectionMonth,
  selectionWeek,
  shift,
  selectionDateRange,
  dayMonthYear,
  abortController,
  dropdownSelection
) {
  let siteId = localStorage.get('currentSite');

  const token = localStorage.get('accessToken');
  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};

  let payload = {
    Name: graphprops.dashboardName,
    PanelName: graphprops.title,
    // date1:selectionDate.date,
    Id: graphprops.id,
    type: graphprops.type || 'historic',
    dashboardType: 'static',
    siteId,
    // startDate: graphprops && graphprops.startDate,
    // endDate: graphprops && graphprops.endDate,
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    timeBucket: '1h'
  };
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }

  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDate.date).format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .utc()
              .format(`YYYY-MM-DD HH:mm:ss`)
          : moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .format(`YYYY-MM-DD HH:mm:ss`),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .utc()
              .format(`YYYY-MM-DD HH:mm:ss`)
          : moment([monthYear[0], monthYear[1]])
              .add('months', 1)
              .subtract('month', 1)
              .subtract('day', 1)
              .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.weekExists === 'true') {
    newWeekPayload = {
      weekExists: true,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionWeek).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionWeek).endOf('week').format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(dayMonthYear.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(dayMonthYear.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(dayMonthYear.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(dayMonthYear.endDate).format('YYYY-MM-DD HH:mm:ss')
    };
  }

  payload = {
    ...payload,
    // ...newdashboardPayload,
    ...newDatePayload,
    // ...newMonthPayload,
    // ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload
    // ...newdayMonthYearPayload,
    // ...newmultiDropdownPayload
  };

  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used for prediction graphtype
 * @return Array
 */
export async function historicPredictionsChartDataa(type, graphprops) {
  const token = localStorage.get('accessToken');
  let siteId = localStorage.get('currentSite');

  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      dashboardType: 'static',
      siteId,
      date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      predictionType: type
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used for prediction graphtype with date selection
 * @return Array
 */
export async function historicPredictionsWithPayloadChartDataa(
  type,
  graphprops,
  deviceCode,
  selectionDate,
  selectionMonth,
  selectionWeek,
  shift,
  selectionDateRange,
  dayMonthYear
) {
  const token = localStorage.get('accessToken');
  let siteId = localStorage.get('currentSite');

  var Authorization = 'Bearer ' + token;

  let newdashboardPayload = {};
  let newDatePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newDateRangePayload = {};
  let newdayMonthYearPayload = {};

  let payload = {
    Name: graphprops.dashboardName,
    PanelName: graphprops.title,
    Id: graphprops.id,
    predictionType: type,
    dashboardType: 'static',
    siteId,
    startDate: graphprops.startDate,
    endDate: graphprops.endDate,
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss')
  };
  if (graphprops.dashboardType === 'dynamic') {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      deviceCode
    };
  }

  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDate).format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .utc()
              .format(`YYYY-MM-DD`)
          : moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .format(`YYYY-MM-DD`),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment([monthYear[0], monthYear[1]])
              .startOf('month')
              .subtract('month', 1)
              .utc()
              .format(`YYYY-MM-DD`)
          : moment([monthYear[0], monthYear[1]])
              .add('months', 1)
              .subtract('month', 1)
              .subtract('day', 1)
              .format('YYYY-MM-DD')
    };
  }

  if (graphprops.weekExists === 'true') {
    newWeekPayload = {
      weekExists: true,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionWeek).startOf('week').utc().format('YYYY-MM-DD')
          : moment(selectionWeek).startOf('week').format('YYYY-MM-DD'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionWeek).endOf('week').utc().format('YYYY-MM-DD')
          : moment(selectionWeek).endOf('week').format('YYYY-MM-DD')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }
  if (graphprops.dateRangeExists === 'true') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dayMonthYearExists === 'true') {
    newdayMonthYearPayload = {
      dayMonthYearExists: true,
      ...dayMonthYear,
      startDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.startDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate:
        graphprops.type && graphprops.type === 'live'
          ? moment(selectionDateRange.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
          : moment(selectionDateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
    };
  }

  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newShiftPayload,
    ...newWeekPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    type
  };

  let data = await fetch(`/api/dashboard/getDashboard?SiteId=${siteId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}

/**
 * @description This function is used for excel download
 * @return String
 */
export function _getReportNametoDownload(excelData) {
  let payload = {
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm:ss'),
    data: excelData || []
  };

  let siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  let options = {
    method: 'POST',
    url: `/api/file/generateExcel?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

/**
 * @description This function is used to get treeGraph data response
 * @return Nested Object with childrens
 */
export async function treeGraphData(abortController) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/hierarchymodule/getMaximoHierarchy?SiteId=${siteId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    signal: abortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
