import React from 'react';
import { Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  PicRightOutlined,
  RightSquareOutlined,
  UploadOutlined
} from '@ant-design/icons';

import { withRouter } from 'react-router';

import ApplicationSettingsRouter from '../../../routes/applicationSettingsRoute';
import styles from '../../../commons/styles';

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class ApplicationSettings extends React.Component {
  render() {
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 250 }}>
        <StyledWrapperLeft>
          <Menu mode="inline" defaultSelectedKeys={['configUpload']}>
            <Menu.Item
              key="configUpload"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/configurationUpload');
              }}
            >
              <UploadOutlined /> Configuration Upload
            </Menu.Item>
            <Menu.Item
              key="hierarchyUpload"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/hierarchyUpload');
              }}
            >
              <RightSquareOutlined />
              Default Hierarchy Upload
            </Menu.Item>
            <Menu.Item
              key="BridgeConfig"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/BridgeConfiguration');
              }}
            >
              <PicRightOutlined /> Bridge Configuration
            </Menu.Item>
            <Menu.Item
              key="EdgeConfig"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/EdgeConfiguration');
              }}
            >
              <MenuUnfoldOutlined /> Edge Configuration
            </Menu.Item>
            <Menu.Item
              key="ParameterHierarchy"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/ParameterHierarchy');
              }}
            >
              <MenuUnfoldOutlined /> Parameter Hierarchy
            </Menu.Item>

            <Menu.Item
              key="ValidationConfiguration"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/ValidationConfiguration');
              }}
            >
              <MenuUnfoldOutlined /> Validation Configuration
            </Menu.Item>
          </Menu>
        </StyledWrapperLeft>
        <StyledWrapperRight style={{ backgroundColor: 'white', margin: '5px 15px 13px 15px' }}>
          <ApplicationSettingsRouter />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}

export default withRouter(ApplicationSettings);
