export default {
  device: 'Device Name',
  timestamp: 'Time Stamp',
  filename: 'File Name',
  fftgenerateddate: 'FFT Generated Date',
  status: 'Status',
  timestamprange: 'Timestamp range',
  showonlygenerated: 'Show Only Generated',
  submit: 'Submit',
  selectdevices: 'Select Device',
  startdatetime: 'Start Date Time',
  enddatetime: 'End Date Time',
  SELECT_HIERARCHY: 'Select Hierarchy',
  Reset: 'Reset',
  Search: 'Search',
  yes: 'Yes',
  no: 'No',
  Generate: 'Generate',
  Do_you_want_to_Generate: 'Do you want to Generate ?',
  FFT_Graph: 'FFT Graph',
  Summary: 'Summary'
};
