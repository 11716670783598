/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { Form, message, Select } from 'antd';
import { StyledButton, StyledComponent, StyledForm } from '../../../CSS/style';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitWorkOrder,
  getJobPlansList,
  getWorkOrderName,
  getLocationByAsset,
  getWorkTypes,
  getStatus,
  getWorkOrderById
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import FileBase64 from '../../../Utils/FileUpload';
import { constant } from '../../../Utils/constants';
import history from '../../../../../commons/history';
import { connect } from 'react-redux';
import { decode as base64_decode } from 'base-64';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import InputComponent from '../../../Widgets/InputComponent';
import { getUserProfile } from '../../../../../selectors/layout';
const { Option } = Select;

class AssetMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      workTypesList: [],
      statusList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      activeKey: '1',
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      datasource: [],
      translationCache: props.translationCache || {}
    };
  }
  // formRef = React.createRef();
  workOrderRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let JobPlansList = await getJobPlansList('Active');
      //  JobPlansList = filterJobPlanByReference(JobPlansList, [formObject["JobPlan"]]);
      this.setState({ JobPlansList: JobPlansList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let workTypes = await getWorkTypes();
      this.setState({ workTypesList: workTypes });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let Status = await getStatus();
      this.setState({ statusList: Status });
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.formObject !== this.props.formObject) {
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  _getDataById = async () => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/InventoryDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getWorkOrderById(base64_decode(path[1]));
    }
    this.setState({
      formObject
    });
    if (this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
  };
  handleSelect = (value, mainIndex, subindex, keyName) => {
    // let list = this.formRef.current.getFieldValue("TaskData")
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
    // this.formRef.current.setFieldsValue({ "TaskData": TaskData })
  };
  handleChange = async (value, key) => {
    let { formObject, deviceList } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.data.Name
      };
      // this.workOrderRef.current.setFieldsValue({"LocationId":location["Name"]})
    }

    this.workOrderRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject });
  };
  fileupload = async (values, key, fileData) => {
    let formObject = this.workOrderRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  jobPlanfileUpload = async (values, key, fileData) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    if (formObject && formObject['JobPlan'] && formObject['JobPlan'][key]) {
      formObject['JobPlan'][key].push(...values);
    } else {
      formObject['JobPlan'][key] = values;
    }
    formObject['JobPlan'][key] = formObject['JobPlan'][key].reduce((acc, current) => {
      const x = acc.find((item) => item.Name === current.Name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    formObject = {
      ...formObject,
      JobPlan: {
        ...(formObject && formObject['JobPlan'] ? formObject['JobPlan'] : {})
      }
    };
    if (this.state.fileData && this.state.fileData !== undefined) {
      this.state.fileData.push(...fileData);
    }
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };

  onFinish = async (values, key, file) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: {
        ...formObject[key],
        ...values
      }
    };
    this.setState({ formObject, fileData: file });
    this.workOrderRef.current.setFieldsValue(formObject);
  };
  onFormsubmit = async () => {
    let { formObject } = this.state;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...this.state.formObject,
      ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
      ...(formObject && formObject.Id ? { UpdatedBy: userId } : {})
    };
    let statusResponse = await submitWorkOrder(formObject, this.state.fileData);
    if (statusResponse && statusResponse.message) {
      // history.goBack();
      this._getDataById();
      message.success('WorkOrder Submitted Successfully');
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    // this.formRef.current.resetFields();
    this.workOrderRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let { sitesList, formObject, workTypesList, translationCache } = this.state;

    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="Inventory"
            theme={themeSettings}
            ref={this.workOrderRef}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            // onFinishFailed={this.onFinishFailed}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Id"></Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.item)}
                name="Item"
                rules={[
                  { required: true, message: translation(translationCache, 'Please Input Item!') }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, constant.inputItem)}
                  style={{ width: '70%' }}
                  value={'WO-11'}
                  onChange={(e) => this.handleChange(e.target.value, 'Item')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.site)}
                name="Site"
                rules={[
                  { required: true, message: translation(translationCache, 'Please Input Site!') }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Site')}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Site')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.issueUnit)}
                name="Issue Unit"
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Issue Unit')}
                  disabled={true}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Issue Unit')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.availableBalanceSummary)}
                name=" Available Balance Summary"
              >
                <InputComponent
                  placeholder={translation(translationCache, constant.inputAvailSummary)}
                  disabled={true}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, ' Available Balance Summary')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.currentBalance)}
                name="Current Balance"
                rules={[
                  {
                    required: true,
                    message: translation(translationCache, 'Please Input Current Balance!')
                  }
                ]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Current Balance')}
                  onChange={(e) => this.handleChange(e, 'Current Balance')}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.quantityCurrentlyReserved)}
                name="Quantity Currently Reserved"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Please Input Quantity Currently Reserved!'
                    )
                  }
                ]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Quantity Currently Reserved')}
                  onChange={(e) => this.handleChange(e, 'Quantity Currently Reserved')}
                >
                  {workTypesList.map((workType, index) => {
                    return (
                      <Option key={index} value={workType.Id}>
                        {' '}
                        {translation(translationCache, workType.WorkType)}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.serialNo)}
                name="Serial No"
                rules={[
                  { required: true, message: translation(translationCache, 'Please Serial No!') }
                ]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Serial No')}
                  onChange={(e) => this.handleChange(e, 'Serial No')}
                >
                  {workTypesList.map((workType, index) => {
                    return (
                      <Option key={index} value={workType.Id}>
                        {' '}
                        {translation(translationCache, workType.WorkType)}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.lastIssueDate)}
                name="Last Issue Date"
                rules={[
                  {
                    required: true,
                    message: translation(translationCache, 'Please Input Last Issue Date!')
                  }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Last Issue Date')}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Last Issue Date')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.quantityAvailable)}
                name="Quantity Available"
                rules={[
                  {
                    required: true,
                    message: translation(translationCache, 'Please Input Quantity Available!')
                  }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Quantity Available')}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Equipment')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.reorderDetails)}
                name="Reorder Details"
                rules={[
                  {
                    required: true,
                    message: translation(translationCache, 'Please Input Reorder Details!')
                  }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Reorder Details')}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Reorder Details')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.reorderPoint)}
                name="Reorder Point"
                rules={[
                  {
                    required: true,
                    message: translation(translationCache, 'Please Input Reorder Point!')
                  }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Reorder Point')}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'Reorder Point')}
                />
              </Form.Item>
              {/* <Col span={4} >
                                     <Form.Item label="Total Downtime" name="TotalDowntime" rules={[{ required: true, message: 'Please Input TotalDowntime!' }]}>
                                         <InputComponent placeholder="Input Total Downtime" style={{ width: "90%" }} onChange={(e) => this.handleChange(e.target.value, "Equipment")}/>
                                     </Form.Item>
                                 </Col>
                                 <Col span={4} >
                                     <Form.Item label="Changed By" name="Changed By" rules={[{ required: true, message: 'Please Input Changed By!' }]}>
                                         <InputComponent placeholder="Input Changed By" style={{ width: "90%" }} onChange={(e) => this.handleChange(e.target.value, "Changed By")}/>
                                     </Form.Item>
                                 </Col>
                                 <Col span={4} >
                                     <Form.Item label="Changed Date" name="Changed Date" rules={[{ required: true, message: 'Please Input Changed Date!' }]}>
                                         <InputComponent placeholder="Input Changed Date" style={{ width: "90%" }} onChange={(e) => this.handleChange(e.target.value, "Changed Date")}/>
                                     </Form.Item>
                                 </Col> */}

              <Form.Item
                name="Attachments"
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.attachments)}
              >
                <FileBase64
                  downloadClassName={'formdownloadInput'}
                  Attachments={formObject.Attachments}
                  fileUpload={this.fileupload}
                ></FileBase64>
              </Form.Item>
            </div>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AssetMasterForm);
