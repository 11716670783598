import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import clone from 'lodash.clonedeep';
import { message, Tag } from 'antd';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

import ReactEcharts from '../../../Echarts/Echarts';
import localStorage from '../../../../../../utils/localStorage';
import { ErrorComponent, NoRecordComponent } from '../../../Utils';
import ModalView from './ModalView';
import {
  trendLabelTranslation,
  predictionsLegendsTranslation
} from '../../../../Utils/Adapter/translationAdapter';
import {
  getChartDataWithSelectionPrediction,
  getChartDataWithoutSelection
} from '../../../ChartCalls';
import { getTranslationCache } from '../../../../../../selectors/language';
import { arrayOfObjects } from './mock.js';
import { defaultRefreshTime } from '../../../constant.json';
import { getCurrentTimezone } from '../../../../../../selectors/layout.js';

class PredictedGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(props),
      data: [],
      loading: false,
      openModal: false,
      feedbackForm: this.props.feedbackFormData ? this.props.feedbackFormData : {},
      translationCache: props.translationCache || [],
      modalVisible: '',
      tableData: []
    };
  }
  timeTicket = null;
  requestCheck = true;

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
    if (prevProps.feedbackFormData !== this.props.feedbackFormData) {
      this.setState({ feedbackForm: this.props.feedbackFormData });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let json = [];
    let series = [];
    this.requestCheck = false;
    const { graphprops, translationCache, errorInAPI, noDataInDB } = this.props;
    const option = cloneDeep(this.state.option);
    this.setState({ loading: true });

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelectionPrediction(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.setState({ loading: false });
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.setState({ loading: false });
      }
    }
    // json =[
    //   {
    //     "time": "2023-10-09 00:11:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:11:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:12:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:12:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:13:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:13:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:14:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:14:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:15:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:15:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:16:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:16:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:17:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:17:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:18:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:18:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:19:00",
    //     "count": 1,
    //     "tagsList": [
    //       {
    //         "Type": "LOWER",
    //         "Limit": 17.49945013,
    //         "TagName": "SI131B@@PV",
    //         "TimeStamp": "2022-10-08 22:28:00",
    //         "OutlierCnt": 3,
    //         "TagDescription": "燃料供給 ﾌｨｰﾀﾞB周波数@@PV"
    //       }
    //     ],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:19:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:20:00",
    //     "count": 1,
    //     "tagsList": [
    //       {
    //         "Type": "LOWER",
    //         "Limit": 17.49945013,
    //         "TagName": "SI131B@@PV",
    //         "TimeStamp": "2022-10-08 22:28:00",
    //         "OutlierCnt": 3,
    //         "TagDescription": "燃料供給 ﾌｨｰﾀﾞB周波数@@PV"
    //       }
    //     ],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:20:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:21:00",
    //     "count": 1,
    //     "tagsList": [
    //       {
    //         "Type": "LOWER",
    //         "Limit": 17.49945013,
    //         "TagName": "SI131B@@PV",
    //         "TimeStamp": "2022-10-08 22:28:00",
    //         "OutlierCnt": 3,
    //         "TagDescription": "燃料供給 ﾌｨｰﾀﾞB周波数@@PV"
    //       }
    //     ],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:21:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:22:00",
    //     "count": 1,
    //     "tagsList": [
    //       {
    //         "Type": "LOWER",
    //         "Limit": 17.49945013,
    //         "TagName": "SI131B@@PV",
    //         "TimeStamp": "2022-10-08 22:28:00",
    //         "OutlierCnt": 3,
    //         "TagDescription": "燃料供給 ﾌｨｰﾀﾞB周波数@@PV"
    //       }
    //     ],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:22:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:23:00",
    //     "count": 1,
    //     "tagsList": [
    //       {
    //         "Type": "LOWER",
    //         "Limit": 17.49945013,
    //         "TagName": "SI131B@@PV",
    //         "TimeStamp": "2022-10-08 22:28:00",
    //         "OutlierCnt": 3,
    //         "TagDescription": "燃料供給 ﾌｨｰﾀﾞB周波数@@PV"
    //       }
    //     ],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:23:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:24:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:24:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   },
    //   {
    //     "time": "2023-10-09 00:25:00",
    //     "count": 0,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2023-10-09 00:25:00",
    //       "NotificationId": null,
    //       "TagName": "TIC301@@PV",
    //       "PredictionType": "11TO15_LGBM",
    //       "TagDescription": null,
    //       "Action": null,
    //       "Comment": null
    //     },
    //     "status": "null"
    //   }
    // ]
    if (json && Array.isArray(json)) {
      this.setState({
        data: json
      });
      if (graphprops.predictionType === 'arrayOfArray') {
        option.xAxis.data = json.map(function (item) {
          return moment.utc(item[0]).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm');
        });

        series = [
          {
            name: 'Anomaly',
            data: json.map(function (item) {
              return item[1];
            }),
            type: 'scatter',
            symbolSize: function () {
              return 15;
            }
          },
          {
            symbolSize: 0,
            data: json.map(function () {
              return 4;
            }),
            type: 'line',
            lineStyle: {
              color: 'skyblue'
            }
          },
          {
            name: 'Actual',
            symbolSize: 12,
            data: json.map(function (item) {
              return item[2];
            }),
            type: 'line',
            symbol: 'triangle',
            lineStyle: {
              color: 'skyblue'
            }
          }
        ];
      } else {
        option.xAxis.data = json.map(function (item) {
          return moment.utc(item.time).local().format('YYYY-MM-DD HH:mm');
        });
        series = [
          {
            name:
              translationCache && translationCache['Predicted Abnormal']
                ? translationCache['Predicted Abnormal']
                : 'Predicted Abnormal',
            barWidth: '60%',
            data: json.map(function (item) {
              if (item && item.count && item.Future !== true) {
                return item.count;
              } else {
                return 'null';
              }
            }),
            type: 'bar',
            color: 'steelblue'
          },
          {
            name:
              translationCache && translationCache['Actual Abnormal']
                ? translationCache['Actual Abnormal']
                : 'Actual Abnormal',
            symbolSize: 9,
            data: json.map(function (item) {
              if (item && item.count && item.count > 0 && item.status === 4) {
                // Change
                return item.count + 1;
              } else if (item.status === 4 && item.count === 0) {
                return 5;
              } else {
                return 'null';
              }
            }),
            type: 'scatter',
            color: 'yellow'
          },
          {
            name:
              translationCache && translationCache['Future Abnormal']
                ? translationCache['Future Abnormal']
                : 'Future Abnormal',
            // symbolSize: 6,
            barWidth: '60%',
            data: json.map(function (item) {
              if (item && item.Future && item.Future === true) {
                // Change
                return item.count;
              } else {
                return 'null';
              }
            }),
            type: 'bar',
            color: 'red'
          }
        ];
      }

      series = await trendLabelTranslation(
        series,
        this.props.translationCache,
        this.props.language
      );
      let legends = await predictionsLegendsTranslation(
        option.legend,
        this.props.translationCache,
        this.props.language
      );
      option.legends = legends;
      option.series = series;
      this.setState({
        option
      });
    }

    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    const { translationCache } = this.props;
    let option = {
      title: {
        left: 'center',
        textStyle: {
          color: 'white',
          align: 'center'
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white',
          formatter: function (value) {
            return moment(value).format('HH:mm');
          }
        }
      },
      yAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white'
        }
      },
      dataZoom: [
        {
          // startValue: '2020-04-20'
        },
        {
          type: 'inside'
        }
      ],
      legend: {
        right: 60,
        data:
          this.props.graphprops &&
          this.props.graphprops.Predictions &&
          this.props.graphprops.Predictions.PredictionType === 'arrayOfObject'
            ? [
                translationCache && translationCache['Predicted Abnormal']
                  ? translationCache['Predicted Abnormal']
                  : 'Predicted Abnormal',
                translationCache && translationCache['Actual Abnormal']
                  ? translationCache['Actual Abnormal']
                  : 'Actual Abnormal',
                translationCache && translationCache['Future Abnormal']
                  ? translationCache['Future Abnormal']
                  : 'Future Abnormal'
              ]
            : ['Anomaly', 'Actual'],
        textStyle: {
          color: 'white'
        }
      }
    };
    return option;
  };

  onChartClick = (cellData) => {
    let { graphprops, translationCache } = this.props;
    console.log('graphprops', graphprops);

    if (
      graphprops.Predictions &&
      graphprops.Predictions.PredictionType &&
      graphprops.Predictions.PredictionType === 'arrayOfArray'
    ) {
      if (cellData.seriesType === 'scatter' && cellData.seriesIndex === 0) {
        let predictedObject = this.state.data.filter((predicted) => {
          return (
            moment.utc(predicted[0], 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm') ===
            cellData.name
          );
        });

        if (predictedObject[0] && predictedObject[0][3]) {
          this.props.summaryTable(predictedObject[0], graphprops.predictionType);
        } else {
          message.warn(
            translationCache && translationCache['No Data'] && translationCache['No Data']
              ? translationCache['No Data']
              : 'No Data'
          );
        }
      }
    } else {
      if (
        graphprops.Predictions &&
        graphprops.Predictions.PredictionType &&
        graphprops.Predictions.PredictionType === 'arrayOfObject'
      ) {
        if (cellData.seriesType === 'bar' && cellData.seriesIndex === 0) {
          this.setState({ modalVisible: true });
          let predictedObject = this.state.data.filter((predicted) => {
            return (
              moment.utc(predicted.time, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm') ===
              cellData.name
            );
          });
          if (
            predictedObject[0] &&
            predictedObject[0].tagsList &&
            predictedObject[0].tagsList.length > 0
          ) {
            this.props.summaryTable(predictedObject[0], graphprops.Predictions.PredictionType);
          } else {
            // message.warn("No Data");
          }

          if (predictedObject[0] && predictedObject[0].notification) {
            if (
              predictedObject[0] &&
              predictedObject[0].tagsList &&
              predictedObject[0].tagsList.length > 0
            ) {
              this.props.feedbackForm(clone(predictedObject[0].notification));
            } else {
              this.setState({
                feedbackForm: predictedObject[0].notification,
                openModal: true
              });
            }
          } else {
            this.props.feedbackForm({});
            // message.warn("No Data");
          }
        }
      }
    }
  };

  handleOk = (feedbackText) => {
    const feedbackForm = feedbackText;
    let payload = {
      ...feedbackForm,
      Timestamp: moment(feedbackForm.Timestamp, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      'Notification Id': feedbackForm.NotificationId,
      'Tag Description': feedbackForm.TagDescription
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/feedback/predictionsFeedBack?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(deviceTypeObject)
      .then(() => {
        this.setState({
          modalVisible: false
        });
        this.props.refereshData('close');
      })
      .catch(function () {
        return 'error';
      });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false
    });
    this.props.refereshData('close');
  };

  render() {
    let { errorInAPI, noDataInDB, modalVisible, feedbackForm, graphprops, refreshDateTime } =
      this.state;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };

    return (
      <span>
        <Fragment>
          {graphprops && graphprops.checked === true ? (
            <Tag className="graphTag" color="blue">
              {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
            </Tag>
          ) : null}
          {noDataInDB === true || errorInAPI === true ? (
            noDataInDB === true ? (
              <NoRecordComponent />
            ) : (
              <ErrorComponent />
            )
          ) : (
            <ReactEcharts
              onEvents={onEvents}
              option={this.state.option}
              className="react_for_echarts"
            />
          )}
        </Fragment>

        {this.state.modalVisible ? (
          <ModalView
            feedbackForm={feedbackForm}
            visible={modalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          />
        ) : null}
      </span>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(PredictedGraph);
