import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import {
  Drawer,
  Button,
  Input,
  Select,
  message,
  Upload,
  Table,
  Space,
  Form,
  Popconfirm,
  Tooltip
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { adapter } from '../utility/adapter';
import { getUserProfile } from '../../../../selectors/layout';
import { _getMenuList } from './ApiCalls';
import { StyledTable, lightTheme, StyledApplication, DrawerFooter } from './style';
import { getTranslationCache } from '../../../../selectors/language';

import './index.css';
import 'react-sortable-tree/style.css';
import { constant } from '../Constants';

let accessToken = localStorage.get('accessToken');
const { Option } = Select;

class MenuBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      forms: [],
      name: '',
      path: '',
      visible: false,
      TableVisible: false,
      userProfile: props && props.userProfile ? props.userProfile : {},
      siteId: '',
      menuId: '',
      translationCache: props.translationCache || [],
      updateMenuData: [],
      editObject: {},
      defValue: '',
      editObj: {},
      UnstructuredDataSource: []
    };
  }

  menuRef = React.createRef();

  componentDidMount() {
    this.getMenuListData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  getMenuById = async (editObject) => {
    let response = await this.getMenuByType(editObject, 'Structured');
    if (response && response[0]) {
      this.setState({
        treeData: toggleExpandedForAll({
          treeData: adapter(response[0])
        }),
        menuKey: response[0].key,
        menuName: response[0].name,
        menuId: response[0].Id,
        siteId: response[0].SiteId,
        editObject
      });
    }
    let UnstructuredDataSource = await this.getMenuByType(editObject, 'UnStructured');
    this.setState({ UnstructuredDataSource: UnstructuredDataSource });
  };

  getMenuByType = (editObject, Type) => {
    const accessToken = localStorage.get('accessToken');
    let authMenuObject = {
      method: 'GET',
      url: `/api/menu/get?SiteId=${editObject.SiteId}&Type=${Type}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(authMenuObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  saveMenu = () => {
    let { editObj, editObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    this.menuRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          method: 'POST',
          url: ` /api/menu/create?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            name: values.name,
            path: values.path,
            type: values.type,
            parentId: editObj.parentId,
            SiteId: siteId
          }
        };

        axios(payload)
          .then((response) => {
            message.success(response.data.message);
            this.getMenuById(editObject);
            this.setState({
              editObj: {},
              visible: false
            });
            if (this.menuRef.current) {
              this.menuRef.current.resetFields();
            }
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateMenu = () => {
    let { editObj, editObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    this.menuRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          method: 'PATCH',
          url: `/api/menu/update?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            name: values.name,
            path: values.path,
            type: values.type,
            parentId: editObj.parentId,
            Id: editObj.Id,
            SiteId: siteId
          }
        };

        axios(payload)
          .then((response) => {
            message.success(response.data.message);
            this.getMenuById(editObject);
            if (this.menuRef.current) {
              this.menuRef.current.resetFields();
            }
            this.setState({
              editObj: {},
              visible: false
            });
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteMenuItem = (Id) => {
    let { editObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      method: 'DELETE',
      url: `/api/menu/delete?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(payload)
      .then((response) => {
        message.success(response.data.message);
        this.getMenuById(editObject);
        if (this.menuRef.current) {
          this.menuRef.current.resetFields();
        }
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  UploadMenu = (e) => {
    let { editObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      method: 'POST',
      url: `/api/menu/bulkUpload?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: e
    };

    axios(payload)
      .then((response) => {
        message.success(response.data.message);
        this.getMenuById(editObject);
        if (this.menuRef.current) {
          this.menuRef.current.resetFields();
        }
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  getMenuListData = async () => {
    let dataSource = await _getMenuList();
    this.setState({
      dataSource
    });
  };

  editMenuItem(obj) {
    let editObj = obj.node.maniObj;
    if (this.menuRef.current) {
      this.menuRef.current.setFieldsValue({ editObj });
    }
    this.setState({
      visible: true,
      editObj
    });
  }

  onClose = () => {
    if (this.menuRef.current) {
      this.menuRef.current.resetFields();
    }
    this.setState({ visible: false, editObj: {} });
  };

  onTableClose = () => {
    this.setState({ TableVisible: false, treeData: {} });
  };

  OnAddModule = (data) => {
    let parentId =
      data && data.node && data.node.maniObj && data.node.maniObj.key ? data.node.maniObj.key : 0;
    if (this.menuRef.current) {
      this.menuRef.current.resetFields();
    }
    this.setState({ visible: true, editObj: { parentId } });
  };

  editMenu = (editObject) => {
    this.setState({
      editObject,
      TableVisible: true
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    let { treeData, type, editObj, dataSource, TableVisible, UnstructuredDataSource } = this.state;

    let columns = [
      {
        title: this.translation('SiteId'),
        dataIndex: 'SiteId',
        key: 'SiteId'
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editMenu(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
          </div>
        )
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <StyledTable theme={lightTheme}>
          <Table
            className="basictable"
            columns={columns}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            onRow={(editObject) => {
              return {
                onClick: () => {
                  this.getMenuById(editObject);
                }
              };
            }}
            pagination={false}
          />
        </StyledTable>

        <Drawer
          onClose={() => this.onTableClose()}
          title={this.translation('Menu Configuration')}
          width={1300}
          closable
          visible={TableVisible}
          extra={
            <Space>
              <div style={{ float: 'right' }}>
                <Upload
                  name="file"
                  accept="application/json"
                  headers={{
                    Authorization: `Bearer ${accessToken}`
                  }}
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.UploadMenu(JSON.parse(e.target.result));
                    };
                    reader.readAsText(file);
                    return false;
                  }}
                >
                  <Button>
                    <UploadOutlined /> {this.translation('Upload')}
                  </Button>
                </Upload>
              </div>

              <div>
                <Button
                  type="primary"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(UnstructuredDataSource)
                  )}`}
                  download="menuConfiguration.json"
                >
                  <DownloadOutlined /> {this.translation(`Download`)}
                </Button>
              </div>
            </Space>
          }
        >
          <div style={{ padding: '10px 40px' }}>
            <div style={{ padding: 20, background: 'white', minHeight: '65vh' }}>
              <SortableTree
                treeData={treeData}
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                onMoveNode={(treeData) => {
                  let { editObject } = this.state;
                  const siteId = localStorage.get('currentSite');
                  let data = {
                    ...treeData.node.maniObj,
                    parentId:
                      treeData &&
                      treeData.nextParentNode &&
                      treeData.nextParentNode.maniObj &&
                      treeData.nextParentNode.maniObj.key
                        ? treeData.nextParentNode.maniObj.key
                        : null
                  };
                  delete data.children;
                  let payloadData = {
                    method: 'PATCH',
                    url: `/api/menu/update?SiteId=${siteId}`,
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    },
                    data
                  };

                  axios(payloadData)
                    .then((response) => {
                      message.success(response.data.message);
                      this.getMenuById(editObject);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }}
                isVirtualized={false}
                slideRegionSize={0}
                rowHeight={60}
                generateNodeProps={(rowInfo, index) => {
                  if (rowInfo.node.name === 'Modules' || rowInfo.node.name === 'Configuration') {
                    return {};
                  } else {
                    return {
                      buttons: [
                        <PlusOutlined
                          key={index}
                          style={{ margin: '7px' }}
                          onClick={() => this.OnAddModule(rowInfo)}
                        />,
                        <EditOutlined
                          key={index}
                          style={{ margin: '7px' }}
                          onClick={() => this.editMenuItem(rowInfo)}
                        />,
                        <Popconfirm
                          key="delete"
                          title={this.translation('Are you sure, do you want to delete?')}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => {
                            this.deleteMenuItem(rowInfo.node.maniObj.Id);
                          }}
                          okText={this.translation(constant.yes)}
                          cancelText={this.translation(constant.no)}
                        >
                          <Tooltip title={this.translation('Delete')}>
                            <DeleteOutlined key={index} style={{ margin: '7px' }} />
                          </Tooltip>
                        </Popconfirm>
                      ]
                    };
                  }
                }}
              />
            </div>
          </div>
          <DrawerFooter>
            <Button onClick={this.onTableClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {/* <Button
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => {
                this.updateMenu(siteId, menuId);
              }}
            >
              {this.translation(constant.Update_Menu)}
            </Button> */}
          </DrawerFooter>
        </Drawer>

        <Drawer
          title={
            editObj && editObj.Id
              ? this.translation('Update MenuItem')
              : this.translation('Create MenuItem')
          }
          width={600}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            layout="vertical"
            ref={this.menuRef}
            name="user_form"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={this.translation('Type')}
              name="type"
              rules={[
                {
                  required: true,
                  message: this.translation('Select Type')
                }
              ]}
            >
              <Select value={type} placeholder={this.translation('Please Select')}>
                {treeData &&
                  Array.isArray(treeData) &&
                  treeData.map((item) => (
                    <Option key={item.key} value={item.key}>
                      {item.key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: this.translation('Select Module name')
                }
              ]}
            >
              <Input placeholder={this.translation('Select Module name')} />
            </Form.Item>

            <Form.Item
              label={this.translation('Path')}
              name="path"
              rules={[{ required: true, message: this.translation('Enter path') }]}
            >
              <Input placeholder={this.translation('Enter Path')} />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {editObj.Id ? (
              <Button onClick={this.updateMenu} type="primary">
                {this.translation('Update')}
              </Button>
            ) : (
              <Button onClick={this.saveMenu} type="primary">
                {this.translation('Save')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
      </StyledApplication>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(MenuBuilder);
