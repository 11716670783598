import React, { Component } from 'react';
import { Table, Switch, Popconfirm, message } from 'antd';
import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import { StyledTable } from '../../../routes/styles';

class CraneManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      Status: ''
    };
  }
  componentDidMount() {
    this.getTableData();
  }
  changeAction = () => {};
  getTableData = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    let Headers = {
      method: 'GET',
      url: `/api/overview/getStatus?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Headers)
      .then((response) => {
        this.setState({ dataSource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  confirm = (text, row) => {
    const { Status } = this.state;
    row.Status = Status === true ? 'Active' : 'Inactive';
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    let Headers = {
      method: 'POST',
      url: `/api/overview/updateStatus`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ...row,
        SiteId: siteId
      }
    };
    axios(Headers)
      .then(() => {
        this.getTableData();
        message.success('Crane Status Updated Successfully');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  cancel = () => {
    this.getTableData();
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('CraneName'),
        dataIndex: 'DeviceCode',
        key: 'DeviceCode'
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        render: (text, row, key) => {
          return (
            <Popconfirm
              title="Are you sure to change this status?"
              onConfirm={() => this.confirm(text, row, key)}
              onCancel={() => this.cancel()}
              okText="Yes"
              cancelText="No"
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="InActive"
                onChange={(e) => this.setState({ Status: e })}
                checked={text === 'Active' ? true : false}
              />
            </Popconfirm>
          );
        }
      }
    ];
    return (
      <div>
        <StyledTable>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered={'false'}
            pagination={
              dataSource.length > 10
                ? {
                    pageSize: '7'
                  }
                : false
            }
            //className="TableWidget"
            //style={{ padding: '0px 10px', marginTop: '12px' }}
          />
        </StyledTable>
      </div>
    );
  }
}

export default CraneManagement;
