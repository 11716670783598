import React, { Component } from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { createStructuredSelector } from 'reselect';
import { Button, Input, message, Row, Col, Tabs, Table, Popconfirm, Space } from 'antd';
import { DoubleLeftOutlined, SearchOutlined } from '@ant-design/icons';
import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { Header } from './Widgets';
import { StyledDashboard } from './styles';
import { getCurrentTimezone, getUserProfile } from '../../../selectors/layout';
import FormType from './formtype.js';
import { updateBatchStatus, getConfigByElogName, getList, _saveELogEdit } from './eLogCalls';
import { getTranslationCache } from '../../../selectors/language';
import './CSS/eLog.css';
import { StyledTable, lightTheme } from './CSS/style.js';
import { columnsAdapter } from '../../Dashboard/Utils/Adapter/translationAdapter';

const { TabPane } = Tabs;
const newLocal = true;
class ELogbookView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      loading: false,
      activeKey: '1',
      location: '',
      data: [],
      date: '',
      json: {},
      userName: '',
      header: [],
      recordType: 'user',
      Remarks: '',
      formType: '',
      elog: {},
      headerValue: {},
      form: {},
      saveStatus: false,
      logList: [],
      dataSource: [],
      action: '',
      userProfile: props.userProfile || {},
      open: false
    };
    this.index = 0;
    this.clickedCancel = false;
  }

  componentDidMount = async () => {
    let elogName = this.props.match.params.name;
    this._getAdhocDataSource(elogName);
  };

  _getAdhocDataSource = async (elogName) => {
    let dataSource = await getList(elogName);
    let config = await getConfigByElogName(elogName);
    this._initialDataSetup(config, this.props.userProfile);
    this.setState({
      userProfile: this.props.userProfile,
      editData: config,
      dataSource,
      elogName
      // action: ''
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.userProfile !== this.props.userProfile ||
      prevProps.match.params.name !== this.props.match.params.name
    ) {
      let elogName = this.props.match.params.name;
      this._getAdhocDataSource(elogName);
      this.setState({
        activeKey: '1'
      });
    }
  };

  _initialDataSetup = async (json, userProfile) => {
    if (json && !json.Id) {
      this.setState({
        json,
        form: json && json.JSON && json.JSON.form ? json.JSON.form : {},
        headerValue: {
          CurrentDate: moment().format('YYYY-MM-DD'),
          CurrentTime: moment().format('HH:mm:ss'),
          userName: userProfile.user ? userProfile.user.FirstName : '',
          location: json && json.Location ? json.Location : '',
          ...(json && json.JSON && json.JSON.headerValue ? json.JSON.headerValue : {})
        },
        location: json && json.Location,
        Remarks: json && json.Remarks,
        process: json && json.Process,
        formType: json && json.FormType,
        adhocTable: json && json.TableName ? json.TableName : '',
        elog: json,
        logList: [],
        header: json && json.JSON && json.JSON.header ? json.JSON.header : [],
        date: moment(),
        userName: json && json.userName,
        recordType: json && json.recordType,
        status: ''
      });
    } else if (json && json.Id) {
      this.setState({
        json,
        form: json && json.JSON && json.JSON.form ? json.JSON.form : {},
        headerValue: {
          CurrentDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          userName: userProfile.user ? userProfile.user.FirstName : '',
          location: json && json.Location ? json.Location : '',
          ...(json && json.JSON && json.JSON.headerValue ? json.JSON.headerValue : {})
        },
        location:
          json && json.headerValue && json.headerValue.Location ? json.headerValue.Location : '',
        date: moment(),
        userName: json && json.userName,
        header: json && json.JSON && json.JSON.header ? json.JSON.header : [],
        recordType: json && json.recordType,
        Remarks: json && json.Remarks ? json.Remarks : '',
        process: json && json.Process ? json.Process : '',
        formType: json && json.FormType,
        elog: json,
        adhocTable: json && json.TableName ? json.TableName : '',
        logList: json.Id,
        logId: json.logId,
        status: json.status
      });
    }
  };

  checkAllRows = (formType, form) => {
    let row = false;
    if (formType === 'EquipmentBatch') {
      form &&
        Object.keys(form).map((tabs) => {
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;

          tabs !== 'mainHeader' &&
            tabs !== 'mainHeaderValue' &&
            form &&
            form[tabs] &&
            Object.keys(form[tabs]).map((dynamicTabs) => {
              let HeaderData =
                form[tabs] &&
                form[tabs][dynamicTabs] &&
                form[tabs][dynamicTabs].headerValue &&
                dynamicTabs !== 'defaultData';

              if (mainHeaderValue !== 0 && HeaderData !== false && HeaderData !== undefined) {
                if (dynamicTabs !== 'defaultData') {
                  let HeaderValue =
                    form[tabs] &&
                    form[tabs][dynamicTabs] &&
                    Object.keys(form[tabs][dynamicTabs].headerValue).length;
                  if (HeaderValue !== 0) {
                    form[tabs] &&
                      form[tabs][dynamicTabs] &&
                      form[tabs][dynamicTabs].header &&
                      Object.keys(form[tabs][dynamicTabs].header).map((header) => {
                        if (header && header.required && header.required === true) {
                          if (!form[tabs][dynamicTabs].headerValue[header]) {
                            row = true;
                          }
                        }
                        return {};
                      });
                    let active =
                      form[tabs] &&
                      form[tabs][dynamicTabs] &&
                      form[tabs][dynamicTabs].headerValue &&
                      form[tabs][dynamicTabs].headerValue.EquipmentActiveStatus;
                    if (
                      active === 'Yes' &&
                      form[tabs][dynamicTabs]['form'].datasource !== undefined &&
                      form[tabs][dynamicTabs]['form'].datasource
                    ) {
                      let dataSource =
                        form[tabs] &&
                        form[tabs][dynamicTabs] &&
                        form[tabs][dynamicTabs]['form'] &&
                        Object.keys(form[tabs][dynamicTabs]['form'].datasource).length;
                      if (dataSource !== 0) {
                        let batchColumns =
                          form[tabs] &&
                          form[tabs][dynamicTabs] &&
                          form[tabs][dynamicTabs]['form'] &&
                          form[tabs][dynamicTabs]['form'].columns &&
                          Array.isArray(form[tabs][dynamicTabs]['form'].columns) &&
                          form[tabs][dynamicTabs]['form'].columns.map((dataColumns) => {
                            return dataColumns.key;
                          });

                        Object.keys(form[tabs][dynamicTabs]['form'].datasource).map((keys) => {
                          if (batchColumns && batchColumns.length > 0) {
                            batchColumns &&
                              Array.isArray(batchColumns) &&
                              batchColumns.map((batchkeys) => {
                                if (
                                  !form[tabs][dynamicTabs]['form'].datasource[keys][batchkeys] &&
                                  batchkeys !== 'Defloccular'
                                ) {
                                  row = true;
                                }
                                return {};
                              });
                          }
                          return {};
                        });
                      } else {
                        if (dataSource === 0) {
                          row = true;
                        }
                      }
                    }
                  } else {
                    if (HeaderValue === 0) {
                      row = true;
                    }
                  }
                }
              } else {
                if (
                  mainHeaderValue === 0 ||
                  (HeaderData === undefined && dynamicTabs !== 'defaultData')
                ) {
                  row = true;
                }
              }
              return {};
            });
          return {};
        });
    } else if (formType === 'EquipmentBodyCodeForm') {
      form &&
        Object.keys(form).map((tabs) => {
          let objectLength =
            tabs !== 'mainHeaderValue' && tabs !== 'mainHeader' && Object.keys(form[tabs]).length;
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
          if (mainHeaderValue !== 0) {
            if (objectLength === 1) {
              row = true;
            }
            let batchColumns =
              form['mainHeader'] &&
              form['mainHeader'] &&
              Array.isArray(form['mainHeader']) &&
              form['mainHeader'].map((dataColumns) => {
                return dataColumns.key;
              });
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((batchkeys) => {
                if (!form['mainHeaderValue'][batchkeys]) {
                  row = true;
                }
                return {};
              });

            tabs !== 'mainHeader' &&
              tabs !== 'mainHeaderValue' &&
              form &&
              form[tabs] &&
              Object.keys(form[tabs]).map((sections) => {
                if (sections !== 'defaultData' && sections !== 'ReferenceAttributes') {
                  let headerValue =
                    form &&
                    form[tabs] &&
                    form[tabs][sections] &&
                    form[tabs][sections].headerValue.length;
                  if (headerValue !== 0) {
                    form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].header &&
                      form[tabs][sections].header.map((header) => {
                        if (header && header.required && header.required === true) {
                          let active =
                            form[tabs][sections].headerValue &&
                            form[tabs][sections].headerValue.EquipmentActiveStatus;
                          if (
                            active === 'Yes' &&
                            form[tabs][sections].headerValue !== undefined &&
                            form[tabs][sections].headerValue
                          ) {
                            if (!form[tabs][sections].headerValue) {
                              row = true;
                            } else {
                              if (
                                !form[tabs][sections].headerValue[header.key] ||
                                form[tabs][sections].headerValue[header.key] === ''
                              ) {
                                row = true;
                              }
                            }
                            form &&
                              form[tabs] &&
                              form[tabs][sections].subSection &&
                              Object.keys(form[tabs][sections].subSection).map((subSectionName) => {
                                if (
                                  subSectionName !== 'bodycode' &&
                                  subSectionName !== 'ReferenceAttributes' &&
                                  form
                                ) {
                                  let subHeaderValue =
                                    form[tabs] &&
                                    form[tabs][sections].subSection[subSectionName].headerValue &&
                                    Object.keys(
                                      form[tabs][sections].subSection[subSectionName].headerValue
                                    ).length;
                                  if (subHeaderValue !== 0) {
                                    form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].header.map(
                                        (header) => {
                                          if (
                                            header &&
                                            header.required &&
                                            header.required === true
                                          ) {
                                            if (
                                              form[tabs][sections].subSection[subSectionName]
                                                .headerValue !== undefined &&
                                              form[tabs][sections].subSection[subSectionName]
                                                .headerValue
                                            ) {
                                              if (
                                                !form[tabs][sections].subSection[subSectionName]
                                                  .headerValue
                                              ) {
                                                row = true;
                                              } else {
                                                if (
                                                  !form[tabs][sections].subSection[subSectionName]
                                                    .headerValue[header.key] ||
                                                  form[tabs][sections].subSection[subSectionName]
                                                    .headerValue[header.key] === ''
                                                ) {
                                                  row = true;
                                                }
                                              }
                                            }
                                          }
                                          return {};
                                        }
                                      );
                                    let batchColumns =
                                      form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].form &&
                                      form[tabs][sections].subSection[subSectionName].form
                                        .columns &&
                                      Array.isArray(
                                        form[tabs][sections].subSection[subSectionName].form.columns
                                      ) &&
                                      form[tabs][sections].subSection[
                                        subSectionName
                                      ].form.columns.map((dataColumns) => {
                                        return dataColumns.key;
                                      });
                                    if (
                                      form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].form &&
                                      form[tabs][sections].subSection[subSectionName].form
                                        .datasource &&
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ) &&
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ).length > 0
                                    ) {
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ).map((data) => {
                                        batchColumns &&
                                          Array.isArray(batchColumns) &&
                                          batchColumns.map((batchkeys) => {
                                            if (
                                              !form[tabs][sections].subSection[subSectionName].form
                                                .datasource[data][batchkeys]
                                            ) {
                                              row = true;
                                            } else if (
                                              !form[tabs][sections].subSection[subSectionName].form
                                                .datasource[data]
                                            ) {
                                              row = true;
                                            }
                                            return {};
                                          });
                                        return {};
                                      });
                                    } else {
                                      if (batchColumns && batchColumns.length > 0) {
                                        row = true;
                                      }
                                    }
                                  } else {
                                    if (subHeaderValue === 0) {
                                      row = true;
                                    }
                                  }
                                }
                                return {};
                              });
                          } else if (active === 'No') {
                            row = false;
                          }
                        }
                        return {};
                      });
                  } else {
                    if (headerValue === 0) {
                      row = true;
                    }
                  }
                }
                return {};
              });
          } else {
            if (mainHeaderValue === 0) {
              row = true;
            }
          }
          return {};
        });
    } else if (formType === 'FormWithSubModelsTable' || formType === 'FormWithSubModels') {
      form &&
        Object.keys(form).map((tabs) => {
          form &&
            form[tabs] &&
            Object.keys(form[tabs]).map((sections) => {
              let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
              let batchColumns =
                form['mainHeader'] &&
                form['mainHeader'] &&
                Array.isArray(form['mainHeader']) &&
                form['mainHeader'].map((dataColumns) => {
                  return dataColumns.key;
                });
              if (mainHeaderValue !== 0) {
                batchColumns &&
                  Array.isArray(batchColumns) &&
                  batchColumns.map((batchkeys) => {
                    if (
                      !form['mainHeaderValue'][batchkeys] &&
                      form['mainHeaderValue'][batchkeys] !== 0
                    ) {
                      row = true;
                    }
                    return {};
                  });
                if (
                  form[tabs] &&
                  form[tabs][sections] &&
                  form[tabs][sections] &&
                  Object.keys(form[tabs][sections])
                ) {
                  if (sections !== 'defaultData') {
                    let formColumnsArray =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].columns
                        ? form[tabs][sections]['form'].columns
                        : [];
                    let formColumnsObject =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      Array.isArray(formColumnsArray) &&
                      formColumnsArray.map((dataColumns) => {
                        return dataColumns.key;
                      });
                    if (form[tabs] && form[tabs][sections] && form[tabs][sections]['form']) {
                      formColumnsObject &&
                        Array.isArray(formColumnsObject) &&
                        formColumnsObject.map((batchkeys) => {
                          if (!form[tabs][sections].form.datasource[batchkeys]) {
                            row = true;
                          }
                          return {};
                        });
                    }
                    if (
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].table &&
                      form[tabs][sections].table
                    ) {
                      let batchNo =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        form[tabs][sections].table.datasource;
                      let tableObject =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        form[tabs][sections].table.columns;
                      let tableColumns =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        Array.isArray(form[tabs][sections].table.columns) &&
                        tableObject &&
                        Array.isArray(tableObject) &&
                        tableObject.map((dataColumns) => {
                          return dataColumns.key;
                        });
                      tableColumns &&
                        Array.isArray(tableColumns) &&
                        tableColumns.map((batchkeys) => {
                          // if (form[tabs][sections].table.validation === "atleastOne") {
                          //   Object.keys(batchNo).map((keys) => {
                          //     if (batchNo[0].editable === true) {
                          //       row = true

                          //     } else {
                          //       if (!batchNo[0][batchkeys]) {
                          //         row = true
                          //       }
                          //     }
                          //   })
                          // }
                          // else {
                          //-----new comm
                          // Object.keys(batchNo).map((keys) => {
                          //   if (!batchNo[keys].editable) {
                          //     if (!batchNo[keys][batchkeys]) {
                          //       row = true
                          //     }
                          //     else {
                          //       row = false
                          //     }

                          //   } else {
                          //     if (!batchNo[keys][batchkeys]) {
                          //       row = true
                          //     }
                          //   }
                          // })
                          //-----new comm
                          // }
                          ////swathi

                          if (form[tabs][sections].table.validation === 'atleastOne') {
                            Object.keys(batchNo).map(() => {
                              if (batchNo[0].editable && batchNo[0].editable === true) {
                                row = true;
                              } else {
                                if (!batchNo[0][batchkeys]) {
                                  row = true;
                                }
                              }
                              return {};
                            });
                          } else {
                            Object.keys(batchNo).map((keys) => {
                              if (batchNo[keys].editable && batchNo[keys].editable === true) {
                                row = true;
                              } else {
                                if (!batchNo[keys][batchkeys]) {
                                  row = true;
                                }
                              }
                              return {};
                            });
                          }
                          return {};
                        });
                    }
                  }
                }
              } else {
                if (mainHeaderValue === 0) {
                  row = true;
                }
              }
              return {};
            });
          return {};
        });
    } else if (formType === 'GasLogForm1' || formType === 'tableView1') {
      form &&
        Object.keys(form).map((sections) => {
          let dataSource =
            form[sections].datasource &&
            Object.keys(form[sections].datasource).length &&
            sections !== 'table';
          if (dataSource !== 0 && sections !== 'table') {
            let batchColumns =
              form[sections] &&
              form[sections].columns &&
              Array.isArray(form[sections].columns) &&
              form[sections].columns.map((dataColumns) => {
                if (
                  form[sections].datasource.SampleType &&
                  form[sections].datasource.SampleType === 'Production'
                ) {
                  if (!dataColumns.show && dataColumns.required === true) {
                    return dataColumns.key;
                  }
                } else {
                  if (dataColumns.required === true) {
                    return dataColumns.key;
                  }
                }
                return {};
              });
            if (
              form[sections] &&
              form[sections].datasource &&
              Object.keys(form[sections].datasource) &&
              Object.keys(form[sections].datasource).length > 0
            ) {
              Object.keys(form[sections].datasource).map(() => {
                batchColumns &&
                  Array.isArray(batchColumns) &&
                  batchColumns.map((batchkeys) => {
                    if (!form[sections].datasource[batchkeys] && batchkeys !== undefined) {
                      row = true;
                    }
                    return {};
                  });
                return {};
              });
            }
          }
          if (sections === 'table') {
            let tableColumns = form[sections].columns;
            let batchColumns =
              form[sections] &&
              tableColumns &&
              Array.isArray(tableColumns) &&
              tableColumns.map((dataColumns) => {
                if (dataColumns.required === true) {
                  return dataColumns.key;
                }
                return {};
              });
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((tableColumns) => {
                if (form[sections].validation === 'atleastOne') {
                  Object.keys(form[sections].datasource).map(() => {
                    if (
                      form[sections].datasource[0].editable &&
                      form[sections].datasource[0].editable === true
                    ) {
                      row = true;
                    } else {
                      if (!form[sections].datasource[0][tableColumns]) {
                        row = true;
                      }
                    }
                    return {};
                  });
                } else {
                  Object.keys(form[sections].datasource).map((key) => {
                    if (
                      form[sections].datasource[key].editable &&
                      form[sections].datasource[key].editable === true
                    ) {
                      row = true;
                    } else {
                      if (!form[sections].datasource[key][tableColumns]) {
                        row = true;
                      }
                    }
                    return {};
                  });
                }
                return {};
              });
          } else {
            if (dataSource === 0) {
              row = true;
            }
          }
          return {};
        });
    } else if (formType === 'FormWithFormSubSectionFormTable' || formType === 'FormTable') {
      form &&
        Object.keys(form).map((tabs) => {
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
          let batchColumns =
            form['mainHeader'] &&
            form['mainHeader'] &&
            Array.isArray(form['mainHeader']) &&
            form['mainHeader'].map((dataColumns) => {
              if (dataColumns.required === true) {
                return dataColumns.key;
              }
              return {};
            });
          if (mainHeaderValue !== 0) {
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((batchkeys) => {
                // if (
                //   !form["mainHeaderValue"][batchkeys] && form["mainHeaderValue"][batchkeys] === 0
                //&& form["mainHeaderValue"][batchkeys] === "" &&
                //   batchkeys !== "NoOfChangeovers" || form["mainHeaderValue"][batchkeys] === undefined
                // ) {
                if (
                  (batchkeys !== 'NoOfChangeovers' && !form['mainHeaderValue'][batchkeys]) ||
                  (form['mainHeaderValue'][batchkeys] === 0 &&
                    form['mainHeaderValue'][batchkeys] === '' &&
                    form['mainHeaderValue'][batchkeys] === undefined)
                ) {
                  row = true;
                }
                return {};
              });

            tabs !== 'mainHeader' &&
              tabs !== 'mainHeaderValue' &&
              tabs !== 'model' &&
              form &&
              form[tabs] &&
              Object.keys(form[tabs]).map((sections) => {
                if (sections !== 'defaultData') {
                  let formDataSource = {};
                  let formDataSourcelength = {};
                  if (
                    form &&
                    form[tabs] &&
                    form[tabs][sections] &&
                    form[tabs][sections]['form'] &&
                    form[tabs][sections]['form'].datasource
                  ) {
                    formDataSource =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].datasource;

                    formDataSourcelength =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'].datasource &&
                      Object.keys(form[tabs][sections]['form'].datasource).length;
                  } else {
                    formDataSource =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections] &&
                      form[tabs][sections].datasource;

                    formDataSourcelength =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].datasource &&
                      Object.keys(form[tabs][sections].datasource).length;
                  }
                  // Object.keys(form[tabs][sections]["form"].datasource).length
                  if (formDataSourcelength !== 0) {
                    let columnsArray =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].columns;

                    let formColumns =
                      form[tabs] &&
                      form[tabs][sections] &&
                      Array.isArray(columnsArray) &&
                      columnsArray.map((dataColumns) => {
                        if (!dataColumns.show && dataColumns.required === true) {
                          return dataColumns.key;
                        }
                        return {};
                      });

                    formColumns &&
                      Array.isArray(formColumns) &&
                      formColumns.map((batchkeys) => {
                        if (batchkeys !== undefined) {
                          if (!form[tabs][sections]['form'].datasource[batchkeys]) {
                            row = true;
                          }
                        }
                        return {};
                      });

                    let subsection =
                      form[tabs] && form[tabs][sections] && form[tabs][sections].subsection;

                    subsection &&
                      Object.keys(subsection).map((subSectionKeys) => {
                        let subsectionData = form[tabs][sections].subsection[subSectionKeys];
                        if (
                          subsectionData &&
                          subsectionData['form'] &&
                          subsectionData['form'].columns
                        ) {
                          let subColumns =
                            subsectionData &&
                            subsectionData['form'] &&
                            subsectionData['form'].columns;
                          let subsectionColumns =
                            Array.isArray(subColumns) &&
                            subColumns.map((columns) => {
                              if (
                                formDataSource &&
                                formDataSource['Typology'] &&
                                formDataSource['Typology'] === 'SINGLECHARGE'
                              ) {
                                if (!columns.show && columns.required === true) {
                                  return columns.key;
                                }
                              } else {
                                if (columns.required === true) {
                                  return columns.key;
                                }
                              }
                              return {};
                            });

                          let subsectionDataSourceLength =
                            subsectionData &&
                            subsectionData['form'] &&
                            subsectionData['form'].datasource &&
                            Object.keys(subsectionData['form'].datasource).length;
                          if (subsectionDataSourceLength !== 0) {
                            subsectionColumns &&
                              Array.isArray(subsectionColumns) &&
                              subsectionColumns.map((batchkeys) => {
                                if (batchkeys !== undefined) {
                                  if (
                                    !subsectionData['form'].datasource[batchkeys] &&
                                    subsectionData['form'].datasource[batchkeys] === null
                                  ) {
                                    row = true;
                                  }
                                }
                                return {};
                              });

                            let dynamicsubsection =
                              form[tabs] &&
                              form[tabs][sections] &&
                              form[tabs][sections].subsection &&
                              form[tabs][sections].subsection[subSectionKeys] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'][
                                'dynamicSubForm'
                              ];
                            dynamicsubsection &&
                              Object.keys(dynamicsubsection).map((dynamicsubsectionkey) => {
                                let dynamicformdata =
                                  form[tabs][sections].subsection[subSectionKeys]['form'][
                                    'dynamicSubForm'
                                  ][dynamicsubsectionkey];

                                if (
                                  form[tabs][sections].subsection[subSectionKeys]['form']
                                    .datasource[dynamicsubsectionkey]
                                ) {
                                  if (
                                    dynamicformdata &&
                                    dynamicformdata['form'] &&
                                    dynamicformdata['form'].columns
                                  ) {
                                    let subColumns =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].columns;

                                    let subsectionColumns =
                                      Array.isArray(subColumns) &&
                                      subColumns.map((columns) => {
                                        if (
                                          columns.required === true &&
                                          columns.disabled !== true
                                        ) {
                                          return columns.key;
                                        }
                                        return {};
                                      });
                                    let dynamicformDataSource =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].datasource &&
                                      Object.keys(dynamicformdata['form'].datasource).length;
                                    if (dynamicformDataSource !== 0) {
                                      subsectionColumns &&
                                        Array.isArray(subsectionColumns) &&
                                        subsectionColumns.map((batchkeys) => {
                                          if (batchkeys !== undefined) {
                                            dynamicformdata['form'].datasource &&
                                              Object.keys(dynamicformdata['form'].datasource).map(
                                                (dataKey) => {
                                                  if (
                                                    !dynamicformdata['form'].datasource[dataKey][
                                                      batchkeys
                                                    ]
                                                  ) {
                                                    row = true;
                                                  }

                                                  return {};
                                                }
                                              );
                                          }
                                          return {};
                                        });
                                    } else {
                                      if (dynamicformDataSource < 0) {
                                        row = true;
                                      }
                                    }
                                  }
                                }
                                return {};
                              });
                          } else {
                            if (subsectionDataSourceLength === 0) {
                              row = true;
                            }
                          }
                        }

                        if (
                          subsectionData &&
                          subsectionData['table'] &&
                          subsectionData['table'].columns
                        ) {
                          let subColumns =
                            subsectionData &&
                            subsectionData['table'] &&
                            subsectionData['table'].columns;

                          let subsectionColumns =
                            Array.isArray(subColumns) &&
                            subColumns.map((columns) => {
                              if (
                                formDataSource &&
                                formDataSource['Typology'] &&
                                formDataSource['Typology'] === 'SINGLECHARGE'
                              ) {
                                if (!columns.show && columns.required === true) {
                                  return columns.key;
                                }
                              } else {
                                if (columns.required === true) {
                                  return columns.key;
                                }
                              }
                              return {};
                            });
                          let subsectionDataSourceLength =
                            subsectionData &&
                            subsectionData['table'] &&
                            subsectionData['table'].datasource &&
                            subsectionData['table'].datasource.length;

                          if (subsectionDataSourceLength !== 0) {
                            subsectionColumns &&
                              Array.isArray(subsectionColumns) &&
                              subsectionColumns.map((batchkeys) => {
                                if (batchkeys !== undefined) {
                                  subsectionData['table'].datasource.map(() => {
                                    //   if (!subsectionData["table"].datasource[index].editable) {
                                    //     if (
                                    //       !subsectionData["table"].datasource[index][batchkeys]
                                    //     ) {
                                    //       row = true;
                                    //     }
                                    //   }
                                    // })
                                    if (subsectionData['table'].validation === 'atleastOne') {
                                      Object.keys(subsectionData['table'].datasource).map(() => {
                                        if (
                                          subsectionData['table'].datasource[0].editable &&
                                          subsectionData['table'].datasource[0].editable === true
                                        ) {
                                          row = true;
                                        } else {
                                          if (!subsectionData['table'].datasource[0][batchkeys]) {
                                            row = true;
                                          }
                                        }
                                        return {};
                                      });
                                    } else {
                                      Object.keys(subsectionData['table'].datasource).map(
                                        (datakey) => {
                                          if (
                                            subsectionData['table'].datasource[datakey].editable &&
                                            subsectionData['table'].datasource[datakey].editable ===
                                              true
                                          ) {
                                            row = true;
                                          } else {
                                            if (
                                              !subsectionData['table'].datasource[datakey][
                                                batchkeys
                                              ]
                                            ) {
                                              row = true;
                                            }
                                          }
                                          return {};
                                        }
                                      );
                                    }
                                    return {};
                                  });
                                }
                                return {};
                              });
                            // if (batchkeys !== undefined) {
                            // if (subsectionData["table"].validation === "atleastOne") {
                            //   Object.keys( subsectionData["table"].datasource).map((datakey, index) => {
                            //     if (!subsectionData["table"].datasource[0].editable === true) {
                            //       row = true

                            //     } else {
                            //       if (!subsectionData["table"].datasource[0]) {
                            //         row = true
                            //       }
                            //     }
                            //   })
                            // }
                            // else {
                            //   Object.keys( subsectionData["table"].datasource).map((datakey, index) => {
                            //     if (!subsectionData["table"].datasource[index].editable === true) {
                            //       row = true

                            //     } else {
                            //       if (!subsectionData["table"].datasource[index]) {
                            //         row = true
                            //       }
                            //     }
                            //   })
                            // }
                            // }

                            let dynamicsubsection =
                              form[tabs] &&
                              form[tabs][sections] &&
                              form[tabs][sections].subsection &&
                              form[tabs][sections].subsection[subSectionKeys] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'][
                                'dynamicSubForm'
                              ];

                            dynamicsubsection &&
                              Object.keys(dynamicsubsection).map((dynamicsubsectionkey) => {
                                let dynamicformdata =
                                  form[tabs][sections].subsection[subSectionKeys]['form'][
                                    'dynamicSubForm'
                                  ][dynamicsubsectionkey];

                                if (
                                  form[tabs][sections].subsection[subSectionKeys]['form']
                                    .datasource[dynamicsubsectionkey]
                                ) {
                                  if (
                                    dynamicformdata &&
                                    dynamicformdata['form'] &&
                                    dynamicformdata['form'].columns
                                  ) {
                                    let subColumns =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].columns;

                                    let subsectionColumns =
                                      Array.isArray(subColumns) &&
                                      subColumns.map((columns) => {
                                        if (
                                          columns.required === true &&
                                          columns.disabled !== true
                                        ) {
                                          return columns.key;
                                        }
                                        return {};
                                      });
                                    let dynamicformDataSource =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].datasource &&
                                      Object.keys(dynamicformdata['form'].datasource).length;
                                    if (dynamicformDataSource !== 0) {
                                      subsectionColumns &&
                                        Array.isArray(subsectionColumns) &&
                                        subsectionColumns.map((batchkeys) => {
                                          if (batchkeys !== undefined) {
                                            dynamicformdata['form'].datasource &&
                                              Object.keys(dynamicformdata['form'].datasource).map(
                                                (dataKey) => {
                                                  if (
                                                    !dynamicformdata['form'].datasource[dataKey][
                                                      batchkeys
                                                    ]
                                                  ) {
                                                    row = true;
                                                  }
                                                  return {};
                                                }
                                              );
                                          }
                                          return {};
                                        });
                                    } else {
                                      if (dynamicformDataSource === 0) {
                                        row = true;
                                      }
                                    }
                                  }
                                }
                                return {};
                              });
                          } else {
                            if (subsectionDataSourceLength === 0) {
                              row = true;
                            }
                          }
                        }
                        return {};
                      });

                    if (sections === 'samples') {
                      Object.keys(form[tabs][sections]['form'].datasource).map((key) => {
                        if (form[tabs][sections]['form'].datasource[key] === null) {
                          row = true;
                        }

                        return {};
                      });
                    }
                  } else {
                    if (formDataSourcelength === 0) {
                      row = true;
                    }
                  }
                }
                return {};
              });
          } else {
            if (mainHeaderValue === 0) {
              row = true;
            }
          }
          return {};
        });
    } else if (formType === 'Form Table With Multi Sub Header Table') {
      // ....Form Checking
      let formData = form.form;
      let { columns, datasource } = formData;
      let mandatoryFormCol = ['DeviceId'];
      mandatoryFormCol = columns
        .filter((e) => e.required === true || e.required === 'true')
        .map((dataColumns) => {
          return dataColumns.key;
        });
      if (mandatoryFormCol) {
        Array.isArray(mandatoryFormCol) &&
          mandatoryFormCol.length > 0 &&
          mandatoryFormCol.map((col) => {
            if (!datasource[col]) {
              row = true;
            }
            return {};
          });
      }

      // ---Table Checking
      let tableData = form.table;
      let tcolumns = tableData.columns;
      let tdatasource = tableData.datasource;

      let mandatoryTableCol = tcolumns
        .filter((e) => e.required === true || e.required === 'true')
        .map((dataColumns) => {
          return dataColumns.key;
        });

      if (mandatoryTableCol && Array.isArray(mandatoryTableCol) && mandatoryTableCol.length > 0) {
        if (tdatasource && Array.isArray(tdatasource) && tdatasource.length > 0) {
          tdatasource &&
            Array.isArray(tdatasource) &&
            tdatasource.map((data) => {
              mandatoryTableCol.map((col) => {
                if (!data[col]) {
                  row = true;
                } else {
                  let tableInTable = data.table ? data.table : {};
                  if (Object.keys(tableInTable) && Array.isArray(tableInTable)) {
                    Object.keys(tableInTable).map((tableInTableKey) => {
                      let { columns, datasource } = tableInTable[tableInTableKey];
                      datasource &&
                        Array.isArray(datasource) &&
                        datasource.map((dataObj) => {
                          columns &&
                            Array.isArray(columns) &&
                            columns.map((col) => {
                              if (!dataObj[col]) {
                                row = true;
                              }
                              return {};
                            });
                          return {};
                        });
                      return {};
                    });
                  }
                }
                return {};
              });
              return {};
            });
        } else {
          // row = true
        }
      }
    }
    return row;
  };

  saveEditObject = async () => {
    let { translationCache } = this.props;
    let siteId = localStorage.get('currentSite');
    let { header, elog, headerValue, formType, form, Remarks, adhocTable, logList, process } =
      this.state;
    let { userProfile } = this.props;
    let status = false;
    if (form) {
      status = await this.checkAllRows(formType, form);
      if (status === true) {
        message.error(
          translationCache && translationCache['Please Enter Mandatory Fields']
            ? translationCache['Please Enter Mandatory Fields']
            : 'Please Enter Mandatory Fields'
        );
      }
    }
    if (form && form['form']) {
      form = {
        ...form,
        form: {
          ...form['form'],
          datasource: {
            ...form['form']['datasource']
            // Date: moment(form['form']['datasource']['Date']).utc().format('YYYY-MM-DD HH:mm:ss')
          }
        }
      };
    } else {
      form = {
        ...form,
        mainHeaderValue: {
          ...form['mainHeaderValue']
          // Date: moment(form['mainHeaderValue']['Date']).utc().format('YYYY-MM-DD HH:mm:ss')
        }
      };
    }

    if (form && !status === true) {
      if (form) {
        delete form.batch;
        let payload = {
          logId: elog.logId,
          formType,
          JSON: {
            form: { ...form },
            header,
            headerValue
          },
          userId: userProfile && userProfile.user ? userProfile.user.Id : '',
          roleId: userProfile.belongsTo.sites[siteId]
            ? userProfile.belongsTo.sites[siteId].role.Id
            : '',
          adhocTable,
          Remarks,
          process,
          Id: logList
        };
        let updateResponse = await _saveELogEdit(payload);
        // let logId = updateResponse && updateResponse[0] && updateResponse[0].Id;
        this.setState({
          saveStatus: true,
          logList: updateResponse
        });
        // message.success('You have saved Elog book no.' + logId);
        message.success(
          translationCache && translationCache['You have saved Elog book']
            ? translationCache['You have saved Elog book']
            : 'You have saved Elog book'
        );
      } else {
        message.error(
          translationCache && translationCache['Save all records before saving e-log']
            ? translationCache['Save all records before saving e-log']
            : 'Save all records before saving e-log'
        );
      }
    }
  };

  _submitStatusAPI = async (payload) => {
    let { translationCache } = this.props;
    let { logList, form, elogName } = this.state;
    let logId = logList && logList[0] && logList[0].Id ? logList[0].Id : [];
    let statusResponse = await updateBatchStatus(payload, elogName);
    if (statusResponse && statusResponse.message) {
      this.setState({
        activeKey: '2'
      });
      message.success(
        translationCache && translationCache['You have Submitted Elog book no.' + logId]
          ? translationCache['You have Submitted Elog book no.' + logId]
          : 'You have Submitted Elog book no.' + logId
      );
      if (form && form['dataSource'] && form['dataSource']['Type'] === 'Observation') {
        form &&
          form['columns'] &&
          Array.isArray(form['columns']) &&
          form['columns'].map((item) => {
            if (item.key === 'SubEquipment') {
              // item.table = []
              if (form && form['dataSource']) {
                form['dataSource'][item.key] = '';
              }
            }
            return {};
          });
        form = {
          ...form,
          dynamicSubForm: {}
        };
      } else {
        this.setState({ status: 'Completed' });
      }
      this.setState({ form });
      // history.goBack()
    } else if (statusResponse && statusResponse.Error) {
      // message.error(statusResponse.Error)
    } else {
      message.error(
        translationCache && translationCache['Error Occured']
          ? translationCache['Error Occured']
          : 'Error Occured'
      );
    }
  };

  SubmitLogbook = async () => {
    let { translationCache } = this.props;
    let { formType, form, logList, elogbookName } = this.state;
    let siteId = localStorage.get('currentSite');
    let fieldValidation = false;
    if (form) {
      fieldValidation = await this.checkAllRows(formType, form);
    }

    let payload = {
      status: 'submitted',
      Id: logList,
      SiteId: siteId,
      elogbookName: elogbookName
    };
    if (!fieldValidation) {
      this._submitStatusAPI(payload);
    } else {
      message.warn(
        translationCache && translationCache['Mandatory Checks failed']
          ? translationCache['Mandatory Checks failed']
          : 'Mandatory Checks failed'
      );
    }
  };

  getColumnSearchOptionsProps = (dataIndex) => ({
    onFilter: (value, record) => record[dataIndex].startsWith(value)
  });

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onChangeRemarks = (e) => {
    this.setState({
      Remarks: e.target.value
    });
  };

  setValue = (e) => {
    let { headerValue } = this.state;
    headerValue = {
      ...headerValue,
      [e.key]: e.value
    };
    this.setState({
      headerValue
    });
  };

  saveSubSectionTableViewData = (form) => {
    this.setState({
      form
    });
  };

  downTimeData = (form, removeId) => {
    if (removeId) {
      this.setState({
        form,
        logList: []
      });
    } else {
      this.setState({
        form
      });
    }
  };

  checkDeviceExistsOrNot = (dataExitsObject) => {
    let { form } = this.state;
    form = {
      ...form,
      ...(dataExitsObject &&
      dataExitsObject[0] &&
      dataExitsObject[0].JSON &&
      dataExitsObject[0].JSON.form
        ? dataExitsObject[0].JSON.form
        : {})
    };
    this.setState({
      form,
      logList: dataExitsObject[0] && dataExitsObject[0].Id ? [{ Id: dataExitsObject[0].Id }] : []
    });
  };

  setMainSaveId = (Id) => {
    this.setState({
      Id
    });
  };

  getRecord = async (record) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const Object = {
      method: 'GET',
      url: `/api/logbook/getLogBookById?SiteId=${siteId}&Id=${record.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      return axios(Object).then((response) => {
        return response.data;
      });
    } catch (e) {
      return {};
    }
  };
  handleOpenChange = async (newOpen) => {
    let { translationCache } = this.props;
    let { formType, form, logList } = this.state;
    if (form) {
      await this.checkAllRows(formType, form);
    }
    if (this.state.saveStatus === true && logList && logList.length > 0) {
      this.setState({ open: newOpen });
    } else {
      message.error(
        translationCache && translationCache['Please Save Before Submit']
          ? translationCache['Please Save Before Submit']
          : 'Please Save Before Submit'
      );
      this.setState({ open: false });
    }
  };

  SubmitConfirm = () => {
    this.setState({ open: false });
    this.SubmitLogbook('Submit');
  };
  cancel = () => {
    this.setState({ open: false });
    // message.error('Click on cancel.');
  };

  onFileUpload = (e) => {
    e.preventDefault();
    let { translationCache } = this.props;
    // let { form } = this.state
    var files = e.target.files;
    var f = files[0];
    var reader = new FileReader();

    reader.onload = (e) => {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let main = [];
      let { uploadExcel } = this.state.form;
      let packer1 = 0;
      let packer2 = 0;
      let packer3 = 0;
      let packer11 = 0;
      let packer22 = 0;
      let packer33 = 0;
      let { form, formType, elog } = this.state;
      if (formType === 'tableView') {
        let equipmentList = [
          {
            key: 'Crusher',
            value: 26
          },
          {
            key: 'Raw Mill RP# 1',
            value: 1
          },
          {
            key: 'Raw Mill RP# 2',
            value: 2
          },
          {
            key: 'Kiln',
            value: 3
          },
          {
            key: 'Coal Mill',
            value: 4
          },
          {
            key: 'Cement Mill RP# 1',
            value: 5
          },
          {
            key: 'Cement Mill RP# 2',
            value: 6
          },
          {
            key: 'Slag Mill-1 RP #1',
            value: 7
          },
          {
            key: 'Slag Mill-1 RP #2',
            value: 8
          },
          {
            key: 'Slag Mill-2 RP #1',
            value: 9
          },
          {
            key: 'Slag Mill-2 RP #2',
            value: 10
          },
          {
            key: 'Paddle Mixer#1',
            value: 11
          },
          {
            key: 'Paddle Mixer#2',
            value: 12
          },
          {
            key: 'Paddle Mixer#3',
            value: 13
          },
          {
            key: 'Paddle Mixer#4',
            value: 14
          },
          {
            key: 'Packer#1',
            value: 15
          },
          {
            key: 'Packer#2',
            value: 16
          },
          {
            key: 'Packer#3',
            value: 17
          },
          {
            key: 'Compressor',
            value: 18
          }
        ];
        if (dataParse && Array.isArray(dataParse) && dataParse.length === 29) {
          dataParse.map((data, index) => {
            if (data && uploadExcel && index > 0) {
              if (data && Array.isArray(data)) {
                let newObject = { key: index };
                uploadExcel &&
                  uploadExcel.map((columns, colIndex) => {
                    if (index === 16 || index === 17 || index === 18 || index === 25) {
                      if (colIndex === 2 || colIndex === 3) {
                        if (colIndex === 2) {
                          packer1 = packer1 + (data[colIndex] ? data[colIndex] : 0);
                        } else {
                          packer11 = packer11 + (data[colIndex] ? data[colIndex] : 0);
                        }
                      }

                      if (index === 25) {
                        if (colIndex === 2 || colIndex === 3) {
                          newObject = {
                            ...data[colIndex],
                            ...newObject,
                            EquipmentId: equipmentList[index] ? equipmentList[index].key : ''
                          };
                          if (colIndex === 2) {
                            newObject[columns] = packer1;
                          } else if (colIndex === 3) {
                            newObject[columns] = packer11;
                          }
                        }
                      }
                    } else if (index === 19 || index === 20 || index === 21 || index === 26) {
                      if (colIndex === 2 || colIndex === 3) {
                        if (colIndex === 2) {
                          packer2 = packer2 + (data[colIndex] ? data[colIndex] : 0);
                        } else {
                          packer22 = packer22 + (data[colIndex] ? data[colIndex] : 0);
                        }
                      }
                      if (index === 26) {
                        if (colIndex === 2 || colIndex === 3) {
                          newObject = {
                            ...data[colIndex],
                            ...newObject,
                            EquipmentId: equipmentList[index] ? equipmentList[index].key : ''
                          };
                          if (colIndex === 2) {
                            newObject[columns] = packer2;
                          } else if (colIndex === 3) {
                            newObject[columns] = packer22;
                          }
                        }
                      }
                    } else if (index === 22 || index === 23 || index === 24 || index === 27) {
                      if (colIndex === 2 || colIndex === 3) {
                        if (colIndex === 2) {
                          packer3 = packer3 + (data[colIndex] ? data[colIndex] : 0);
                        } else {
                          packer33 = packer33 + (data[colIndex] ? data[colIndex] : 0);
                        }
                      }

                      if (index === 27) {
                        if (colIndex === 2 || colIndex === 3) {
                          newObject = {
                            ...data[colIndex],
                            ...newObject,
                            EquipmentId: equipmentList[index] ? equipmentList[index].key : ''
                          };
                          if (colIndex === 2) {
                            newObject[columns] = packer3;
                          } else if (colIndex === 3) {
                            newObject[columns] = packer33;
                          }
                        }
                      }
                    } else {
                      newObject[columns] = data[colIndex];
                      newObject['EquipmentId'] = equipmentList[index]
                        ? equipmentList[index].key
                        : '';
                    }
                    return {};
                  });
                if (
                  index === 16 ||
                  index === 17 ||
                  index === 18 ||
                  index === 19 ||
                  index === 20 ||
                  index === 21 ||
                  index === 22 ||
                  index === 23 ||
                  index === 24
                ) {
                  let dummy = [];
                  dummy.push('');
                } else {
                  main.push(newObject);
                }
              } else {
                message.error(
                  translationCache && translationCache['Row Data Issue']
                    ? translationCache['Row Data Issue']
                    : 'Row Data Issue'
                );
              }
            }
            return {};
          });
        } else {
          message.error(
            translationCache && translationCache['Please update valid xlsx']
              ? translationCache['Please update valid xlsx']
              : 'Please update valid xlsx'
          );
        }

        let mixedData = [];
        // && form.table.datasource.length === main.l2ength
        if (form && form.table && form.table.datasource) {
          mixedData = form.table.datasource.map((tableData, index) => {
            let RunHour =
              main[index] && (main[index].Production || main[index].Production === 0)
                ? moment()
                    .startOf('day')
                    .add(main[index].Production * 1440, 'minutes')
                    .format('HH:mm')
                : '';
            let RunHoursInHrs =
              main[index] && (main[index].Production || main[index].Production === 0)
                ? main[index].Production * 24
                : 0;
            return {
              ...tableData,
              RunHour: RunHoursInHrs && RunHoursInHrs === 24 ? '24:00' : RunHour,
              RunHoursInHrs,
              Production: main[index] && main[index].RunHour ? Math.round(main[index].RunHour) : 0
            };
          });
        }
        form = {
          ...form,
          table: {
            ...(form && form.table ? form.table : {}),
            datasource: mixedData
          }
        };
        this.setState({
          form
        });
      } else if (formType === 'FormWithSubModelsTable') {
        let equipmentList = [
          {
            key: 'Crusher',
            value: 46
          },
          {
            key: 'Raw Mill',
            value: 47
          },
          {
            key: 'Kiln',
            value: 48
          },
          {
            key: 'Coal Mill',
            value: 49
          },
          {
            key: 'Cement Mill',
            value: 50
          },
          {
            key: 'Slag Mill',
            value: 51
          },
          {
            key: 'Packing Plant',
            value: 52
          }
        ];
        if (dataParse && Array.isArray(dataParse) && dataParse.length === 10) {
          dataParse.map((data, index) => {
            if (data && uploadExcel && data.length === uploadExcel.length && index > 2) {
              let newObject = { key: index };
              uploadExcel &&
                uploadExcel.map((columns, colIndex) => {
                  newObject[columns] = data[colIndex];
                  return {};
                });
              if (index > 1) {
                newObject['EquipmentId'] =
                  equipmentList && equipmentList[index - 3] ? equipmentList[index - 3].value : '';
              }

              main.push(newObject);
            }
            return {};
          });
          form = {
            ...form,
            model: {
              ...(form && form.model ? form.model : {}),
              'On-Date': {
                ...(form && form.model && form.model['On-Date'] ? form.model['On-Date'] : {}),
                table: {
                  ...(form && form.model && form.model['On-Date'] && form.model['On-Date'].table
                    ? form.model['On-Date'].table
                    : {}),
                  datasource: main
                }
              },
              MTD: {
                ...(form && form.model && form.model['MTD'] ? form.model['MTD'] : {}),
                table: {
                  ...(form && form.model && form.model['MTD'] && form.model['MTD'].table
                    ? form.model['MTD'].table
                    : {}),
                  datasource: main
                }
              },
              YTD: {
                ...(form && form.model && form.model['YTD'] ? form.model['YTD'] : {}),
                table: {
                  ...(form && form.model && form.model['YTD'] && form.model['YTD'].table
                    ? form.model['YTD'].table
                    : {}),
                  datasource: main
                }
              }
            }
          };
        } else {
          message.error(
            translationCache && translationCache['Please update proper xlxs file']
              ? translationCache['Please update proper xlxs file']
              : 'Please update proper xlxs file'
          );
        }
        this.setState({
          form
        });
      } else if (formType === 'GasLogForm') {
        if (form && form.form && form.form.datasource && form.form.datasource.Date) {
          let { userProfile } = this.props;
          let siteId = localStorage.get('currentSite');
          let attrs = {
            ...(form && form['form'] && form['form'].datasource ? form['form'].datasource : {}),
            roleId: userProfile.belongsTo.sites[siteId]
              ? userProfile.belongsTo.sites[siteId].role.Id
              : '',
            logbookId: elog.logId
          };

          const formData = new FormData();
          formData.append('file', f);
          formData.append('attrs', JSON.stringify(attrs));

          const accessToken = localStorage.get('accessToken');
          const uploadObject = {
            method: 'POST',
            url: `/api/logbook/manualFormUpload`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
            data: formData
          };
          axios(uploadObject)
            .then((response) => {
              if (response.data && response.data.message && response.data.message !== 'Success') {
                message.error(
                  translationCache && translationCache[response.data.message]
                    ? translationCache[response.data.message]
                    : response.data.message
                );
              } else if (
                response.data &&
                response.data.message &&
                response.data.message === 'Success'
              ) {
                message.success(
                  translationCache && translationCache[response.data.message]
                    ? translationCache[response.data.message]
                    : response.data.message
                );
              } else {
                message.warn(
                  translationCache && translationCache[response.data]
                    ? translationCache[response.data]
                    : response.data
                );
              }
            })
            .catch((error) => {
              if (error.response && error.response.status && error.response.status === 500) {
                message.error(
                  translationCache && translationCache[error.response.data.message]
                    ? translationCache[error.response.data.message]
                    : error.response.data.message
                );
              }
            });
        } else {
          message.warn(
            translationCache && translationCache['Please select Date']
              ? translationCache['Please select Date']
              : 'Please select Date'
          );
        }
      }
    };
    reader.readAsBinaryString(f);
  };
  render() {
    let {
      loading,
      header,
      recordType,
      formType,
      headerValue,
      form,
      Remarks,
      elog,
      status,
      dataSource,
      userProfile,
      json
    } = this.state;
    let { translationCache } = this.props;
    let elogName = this.props.match.params.name;
    let columns = [];

    if (json && json.JSON && json.JSON.form && json.JSON.form.list) {
      let columnsData = columnsAdapter(json.JSON.form.list, this.props.translationCache);
      columns =
        columnsData &&
        Array.isArray(columnsData) &&
        columnsData.map((column) => {
          if (column && column.isYear && column.isYear === true) {
            return {
              ...column,
              render: (text) => <span>{text ? moment(text).format('YYYY') : text}</span>
            };
          } else if (column && column.key && column.key === 'Status') {
            return {
              ...column,
              render: (text, record) => (
                <span style={{ color: record.Status === 'submitted' ? 'green' : 'red' }}>
                  {text}
                </span>
              )
            };
          } else {
            return column;
          }
        });
    } else {
      columns = [
        {
          title: 'LogBookId',
          key: 'LogBookId',
          dataIndex: 'LogBookId'
        },
        {
          title: 'Location',
          key: 'Location',
          dataIndex: 'Location'
        },
        {
          title: 'Process',
          key: 'Process',
          dataIndex: 'Process'
        },
        {
          title: 'CreatedDate',
          key: 'Timestamp',
          dataIndex: 'Timestamp',
          render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
        },
        {
          title: 'Status',
          key: 'Status',
          dataIndex: 'Status',
          render(text, record) {
            return {
              children: (
                <div>
                  <Space style={{ color: record.Status === 'submitted' ? 'green' : 'red' }}>
                    {text}
                  </Space>
                </div>
              )
            };
          }
        }
      ];
    }

    if (elogName === 'Vibration Sensor Field Entry') {
      columns = [
        {
          title:
            translationCache && translationCache['Device Name']
              ? translationCache['Device Name']
              : 'Device Name',
          key: 'DeviceName',
          dataIndex: 'DeviceName'
        },
        ...columns
      ];
    }

    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'elog_book')
        .map((name) => permissionsArray.push(name.action));

    return (
      <StyledDashboard className="adhocView" style={{ minHeight: window.innerHeight - 69 }}>
        <Button type="dashed" style={{ marginBottom: '20px' }} onClick={() => history.goBack()}>
          <DoubleLeftOutlined />
          {/* Go Back */}
          {translationCache && translationCache['Go Back']
            ? translationCache['Go Back']
            : 'Go Back'}
        </Button>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {this.state.process !== 'Field Data Measurement' && this.state.activeKey !== '2' ? (
            <Button
              type=""
              size="large"
              className="saveButton"
              style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
              onClick={() => {
                this.setState({
                  activeKey: '2',
                  status: 'Inprogress',
                  editObj: {},
                  form,
                  action: 'new'
                });
              }}
            >
              {translationCache && translationCache['Add New']
                ? translationCache['Add New']
                : 'Add New'}
            </Button>
          ) : null}
        </div>

        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={this.state.activeKey}
          onChange={async (activeKey) => {
            let action = '';
            if (activeKey === '2' && this.state.process === 'Field Data Measurement') {
              return {};
            } else {
              if (activeKey === '2') {
                this._initialDataSetup(this.state.editData, this.props.userProfile);
                action = 'new';
              }
              if (activeKey === '1') {
                let processName = this.props.match.params.name;
                this._getAdhocDataSource(processName);
              }
              this.setState({ activeKey, action });
            }
          }}
        >
          <TabPane
            tab={
              translationCache && translationCache['List'] && translationCache['List']
                ? translationCache['List']
                : 'List'
            }
            key="1"
          >
            <StyledTable style={{ width: '100%' }} theme={lightTheme}>
              <Table
                dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                columns={columns ? columns : []}
                onRow={(tableRowData) => {
                  return {
                    onClick: async () => {
                      let { form } = this.state;
                      let data = await this.getRecord(tableRowData);
                      let record = data && Array.isArray(data) && data[0] ? data[0] : {};
                      form = {
                        ...(record && record.JSON && record.JSON.form && record.JSON.form
                          ? record.JSON.form
                          : {})
                      };
                      this.setState({
                        activeKey: '2',
                        status:
                          tableRowData && tableRowData.Status && tableRowData.Status === 'submitted'
                            ? 'Completed'
                            : this.state.status,
                        form: form,
                        headerValue:
                          record && record.JSON && record.JSON.headerValue
                            ? record.JSON.headerValue
                            : '',
                        action: '',
                        editObj: record,
                        logList: record && record['Id'] ? [{ Id: record['Id'] }] : [],
                        saveStatus: false,
                        Remarks: record && record.Remarks ? record.Remarks : ''
                      });
                    }
                  };
                }}
              />
            </StyledTable>
          </TabPane>

          <TabPane
            tab={
              translationCache && translationCache['Details']
                ? translationCache['Details']
                : 'Details'
            }
            key="2"
          >
            <div>
              {formType &&
              (formType === 'tableView1' ||
                formType === 'FormWithSubModelsTable1' ||
                formType === 'GasLogForm1') ? (
                <div style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}>
                  <input type="file" onChange={this.onFileUpload} />
                </div>
              ) : null}
              <Row>
                {header && Array.isArray(header) && header.length > 0 ? (
                  <Col span={8}>
                    <Header
                      header={header}
                      headerValue={headerValue}
                      recordType={recordType}
                      setValue={this.setValue}
                      status={this.state.status}
                    />
                  </Col>
                ) : null}
              </Row>

              <FormType
                formType={formType}
                formObject={form}
                loading={loading}
                header={header}
                headerValue={headerValue}
                recordType={recordType}
                elog={elog}
                userProfile={this.props.userProfile}
                translationCache={this.props.translationCache}
                status={status}
                getMainSaveId={this.setMainSaveId}
                setValue={this.setValue}
                downTimeData={this.downTimeData}
                action={this.state.action}
                checkDeviceExistsOrNot={this.checkDeviceExistsOrNot}
                currentTimeZone={this.props.currentTimeZone}
              />

              <div>
                <div
                  style={{
                    ...(status === 'Completed'
                      ? {
                          opacity: newLocal ? 0.75 : 1,
                          pointerEvents: newLocal ? 'none' : 'initial',
                          margin: '20px 0px'
                        }
                      : { margin: '20px 0px' })
                  }}
                >
                  <div className="RemarksHeading" style={{ color: 'white' }}>
                    {translationCache && translationCache['Remarks'] && translationCache['Remarks']
                      ? translationCache['Remarks']
                      : 'Remarks'}{' '}
                  </div>
                  <Input.TextArea
                    value={Remarks && Remarks !== null && Remarks !== 'null' ? Remarks : ''}
                    onChange={(e) => this.onChangeRemarks(e)}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    style={{ width: '100%' }}
                    maxLength={250}
                  />
                  <div style={{ color: 'white' }}>
                    {translationCache &&
                    translationCache['Characters left'] &&
                    translationCache['Characters left']
                      ? translationCache['Characters left']
                      : 'Characters left'}{' '}
                    :{' '}
                    <span style={{ color: 'white', fontWeight: 'bold' }}>
                      {Remarks && Remarks !== null && Remarks !== 'null'
                        ? 250 - Remarks.length
                        : 250}{' '}
                    </span>
                  </div>
                </div>

                <div style={{ margin: '20px 0px' }}>
                  <Button
                    className="saveButton"
                    disabled={
                      !permissionsArray.includes('create') || !permissionsArray.includes('update')
                        ? true
                        : false
                    }
                    style={{
                      ...(status === 'Completed'
                        ? {
                            opacity: newLocal ? 0.75 : 1,
                            pointerEvents: newLocal ? 'none' : 'initial',
                            marginRight: '5px'
                          }
                        : { marginRight: '5px' })
                    }}
                    type=""
                    onClick={(e) => this.saveEditObject(e)}
                  >
                    {translationCache && translationCache['Save'] && translationCache['Save']
                      ? translationCache['Save']
                      : 'Save'}
                  </Button>
                  <Popconfirm
                    placement="right"
                    title="Do you want to submit?"
                    open={this.state.open}
                    onOpenChange={(e) => this.handleOpenChange(e)}
                    onConfirm={(e) => this.SubmitConfirm(e)}
                    onCancel={(e) => this.cancel(e)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        ...(status === 'Completed'
                          ? {
                              opacity: newLocal ? 0.75 : 1,
                              pointerEvents: newLocal ? 'none' : 'initial'
                            }
                          : {})
                      }}
                      type="danger"
                      disabled={!permissionsArray.includes('submit') ? true : false}
                      // onClick={this.showConfirm}
                    >
                      {' '}
                      {translationCache && translationCache['Submit']
                        ? translationCache['Submit']
                        : 'Submit'}
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(ELogbookView);
