/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import {
  Form,
  message,
  Col,
  Button,
  Collapse,
  Popconfirm,
  Select,
  Tabs,
  DatePicker,
  Drawer,
  Divider,
  Space,
  Tooltip
} from 'antd';
import {
  // ApartmentOutlined,
  DeleteOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  getTaskList,
  getTaskById,
  filterTaskByReference,
  getHierarchyList,
  getUOMList,
  getUsersList
} from '../../../Utils/FormCalls';
import {
  StyledForm,
  StyledButton,
  StyledCollapse,
  StyledTabs,
  StyledSubForm
} from '../../../CSS/style';
import { Toolsform, LaborForm, MaterialForm, ItemForm } from './WorkOrderTasks';
import FileBase64 from '../../../Utils/FileUpload';
import localStorage from '../../../../../utils/localStorage';
import HierarchyModal from '../../../Widgets/HierarchyModal';
import moment from 'moment';
import themeSettings from '../../../Utils/themeSettings.json';
import axios from 'axios';
import { connect } from 'react-redux';
import translation from '../../../Utils/translation';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

class WorkOrderJobPlanes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      workOrderStatus: props.workOrderStatus,
      fileData: this.props.fileData ? this.props.fileData : new FormData(),
      translationCache: props.translationCache || {},
      hierarchyList: [],
      UOMList: [],
      showMoreTaskDetails: false
    };
  }
  workOrderJobPlanRef = React.createRef();

  componentDidMount = async () => {
    const { recordData } = this.state;
    this.workOrderJobPlanRef.current !== null &&
      this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let TaskList = await getTaskList('Active');
      TaskList = filterTaskByReference(TaskList, recordData);
      this.setState({ TaskList: TaskList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let UOMList = await getUOMList();
      this.setState({ UOMList: UOMList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      // this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : [],
        workOrderStatus: this.props.workOrderStatus ? this.props.workOrderStatus : null,
        fileData: this.props.fileData ? this.props.fileData : null
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };

    // this.workOrderJobPlanRef.current.setFieldsValue(formObject)
    this.setState({ [key]: value, formObject });
  };

  onReset = () => {
    this.workOrderJobPlanRef.current.resetFields();
  };
  actualTaskOnChange = async (value) => {
    let TaskData = await getTaskById(value, 'withoutId');
    console.log(TaskData, 'TaskData');
    let data =
      this.workOrderJobPlanRef &&
      this.workOrderJobPlanRef.current &&
      this.workOrderJobPlanRef.current.getFieldsValue() &&
      this.workOrderJobPlanRef.current.getFieldsValue().TaskData !== undefined
        ? this.workOrderJobPlanRef.current.getFieldsValue().TaskData
        : [];
    _.cloneDeepWith(TaskData, (value) => {
      return value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    data.push(TaskData);
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      ActualTaskData: data
    };
    this.setState({ selectedTask: value });
    //    this.setState({ formObject: { ...this.state.formObject, ["TaskData"]: data }, selectedTask: value })
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    // this.workOrderJobPlanRef.current.setFieldsValue({ "TaskData": data })
  };

  TaskOnChange = async (value) => {
    let TaskData = await getTaskById(value, 'withoutId');
    console.log(TaskData, 'TaskData1');
    let data =
      this.workOrderJobPlanRef &&
      this.workOrderJobPlanRef.current &&
      this.workOrderJobPlanRef.current.getFieldsValue() &&
      this.workOrderJobPlanRef.current.getFieldsValue().TaskData !== undefined
        ? this.workOrderJobPlanRef.current.getFieldsValue().TaskData
        : [];
    _.cloneDeepWith(TaskData, (value) => {
      return value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    data.push(TaskData);
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      TaskData: data
    };
    this.setState({ selectedTask: value });
    //    this.setState({ formObject: { ...this.state.formObject, ["TaskData"]: data }, selectedTask: value })
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    // this.workOrderJobPlanRef.current.setFieldsValue({ "TaskData": data })
  };
  handleSelect = (value, mainIndex, subindex, keyName, selectedObject) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldValue();
    let TaskData = formObject && formObject.TaskData;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
        item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
      }
      return {};
    });
    formObject['TaskData'] = TaskData;
    this.setState({ formObject });
    this.workOrderJobPlanRef.current.setFieldsValue(TaskData);
  };
  datepickerchange = (date, dateString, index, key) => {
    // this.workOrderJobPlanRef.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
    ) {
      let startTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'];
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (
      key &&
      key === 'StartTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
    ) {
      let endTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'];
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }
    formObject['TaskData'][index]['EstimatedDuration'] = EstimatedDuration;
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
  };
  getDisabledHours = (e, index, key) => {
    let hours = [];
    let minutes = [];
    let seconds = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'EndTime' &&
      moment(StartDate).isSame(moment(EndDate)) &&
      moment(StartDateTime).hours() !== moment(EndDateTime).hours()
    ) {
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (let i = 0; i < moment(StartDateTime).hours(); i++) {
          if (i < 61) {
            hours.push(i);
          }
        }
        return {
          disabledHours: () => hours
        };
      }
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j > moment(EndDateTime).minutes() - 1) {
            minutes.push(j);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      (key === 'EndTime' && moment(StartDate).isSame(moment(EndDate))) ||
      moment(StartDateTime).hours() === moment(EndDateTime).hours()
    ) {
      for (var k = 0; k < moment(StartDateTime).hours(); k++) {
        if (k < 25) {
          hours.push(k);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var K = 0; K < moment(StartDateTime).minutes(); K++) {
          if (K < 61) {
            minutes.push(K);
          }
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var K1 = 0; K1 < moment(StartDateTime).seconds() + 1; K1++) {
          if (K1 < 61) {
            seconds.push(K);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds
      };
    }
  };
  onFinish = () => {};
  taskFileupload = async (values, key, fileDataObject, index, TaskId, submodule) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (
      formObject &&
      formObject &&
      formObject[submodule] &&
      formObject[submodule][index] &&
      formObject[submodule][index][key]
    ) {
      formObject[submodule][index][key].push(...values);
    } else if (formObject && formObject[submodule][index] !== undefined) {
      formObject[submodule][index][key] = values;
    } else {
      formObject[submodule].push({ [key]: values });
    }
    let TaskData = [...(fileUploadList && fileUploadList.TaskData ? fileUploadList.TaskData : [])];
    TaskData.push({ fileDataObject, TaskId });
    fileUploadList = {
      ...fileUploadList,
      TaskData
    };

    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
    this.props.fileUploadListBack(fileUploadList);
  };
  disabledDate = (currentDay) => {
    return currentDay > moment().endOf('day');
  };
  disabledHours = () => {
    const hours = [];
    const currentHour = moment().hour();
    for (let i = currentHour + 1; i <= 24; i++) {
      hours.push(i);
    }
    return hours;
  };
  disabledMinutes = (selectedHour) => {
    const minutes = [];
    const currentMinute = moment().minute();
    if (selectedHour === moment().hour()) {
      for (let i = currentMinute; i <= 60; i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  disabledEndHours = () => {
    let hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };
  getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.workOrderJobPlanRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  hierarchyDropdownChange = (value) => {
    // let formObject = this.workOrderJobPlanRef.current.getFieldsValue()
    // formObject = {
    //     ...formObject,
    //     "Equipment": value.Code,
    //     "EquipmentDescription": value.Name,
    // }
    // this.workOrderJobPlanRef.current.setFieldsValue(formObject)
    let { formObject } = this.state;
    let list = this.workOrderJobPlanRef.current.getFieldValue('TaskData');
    let TaskData = list;
    TaskData.map((item) => {
      // if (index === mainindex) {
      item.Equipment = value.Code;
      // }
      return {};
    });
    formObject = {
      ...formObject,
      TaskData: TaskData
    };
    this.setState({
      visiblerender: false,
      formObject
    });
    this.workOrderJobPlanRef.current.setFieldsValue({ TaskData: TaskData });
  };

  render() {
    let {
      formObject,
      TaskList,
      workOrderStatus,
      selectedTask,
      // hierarchyList,
      // deviceList,
      UOMList,
      translationCache,
      usersList,
      fileData,
      showMoreTaskDetails
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const styledCollapse = (formObject, key, translationCache) => (
      <span>
        <span style={{ fontWeight: 'bold' }}>{translation(translationCache, 'Task Name')}</span>:
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {formObject &&
          formObject['TaskData'] &&
          formObject['TaskData'][key] &&
          formObject['TaskData'][key]['TaskName']
            ? formObject['TaskData'][key]['TaskName']
            : ''}
        </span>
        <span style={{ fontWeight: 'bold' }}>
          {translation(translationCache, 'Task Description')}
        </span>{' '}
        :
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {formObject &&
          formObject['TaskData'] &&
          formObject['TaskData'][key] &&
          formObject['TaskData'][key]['TaskDescription']
            ? formObject['TaskData'][key]['TaskDescription']
            : ''}
        </span>
      </span>
    );
    return (
      <div>
        <>
          <StyledForm
            ref={this.workOrderJobPlanRef}
            name={'TaskData'}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            theme={themeSettings}
            validateMessages={validateMessages}
          >
            {this.props.noJobplan === false ? (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label={translation(translationCache, constant.jobPlan)}
                  name="JobPlanName"
                  style={{ width: '100%', flex: '50%' }}
                >
                  <InputComponent
                    placeholder={translation(translationCache, 'Input JobPlan')}
                    style={{ width: '70%' }}
                    value={formObject && formObject.JobPlanName ? formObject.JobPlanName : ''}
                    onChange={(e) => this.handleChange(e.target.value, 'JobPlanName')}
                  />
                </Form.Item>
                <Form.Item
                  label={translation(translationCache, constant.description)}
                  name="JobPlanDescription"
                  style={{ width: '100%', flex: '50%' }}
                >
                  <InputComponent
                    placeholder={translation(translationCache, 'Input Description')}
                    style={{ width: '70%' }}
                    onChange={(e) => this.handleChange(e.target.value, 'JobPlanDescription')}
                  />
                </Form.Item>
              </div>
            ) : null}
            <StyledTabs
              theme={themeSettings}
              // className={'cardContainer'}
              defaultActiveKey="1"
              type="card"
              size={'middle'}
            >
              <TabPane
                className={workOrderStatus && workOrderStatus === 3 ? 'checkTab' : 'testt'}
                tab={translation(translationCache, 'Planned Task')}
                key="1"
              >
                <Form.List
                  initialValue={formObject && formObject['TaskData'] ? formObject['TaskData'] : []}
                  name="TaskData"
                >
                  {(fields, { add, remove }) => (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ width: '100%', flex: '50%' }}
                          label={translation(translationCache, constant.addTask)}
                          name="TaskName"
                        >
                          <Select
                            placeholder={translation(translationCache, 'Select Task')}
                            defaultValue={selectedTask}
                            onChange={(value) => this.TaskOnChange(value)}
                            style={{ width: '70%' }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0'
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px'
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    // onClick={() => this.setState({ viewEquipmentModal: true })}
                                    onClick={() => add()}
                                  >
                                    {' '}
                                    {translation(translationCache, constant.addNewTask)}{' '}
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {TaskList &&
                              Array.isArray(TaskList) &&
                              TaskList.map((task, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={task.Id}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current.getFieldsValue() &&
                                      this.workOrderJobPlanRef.current.getFieldsValue().TaskData &&
                                      this.workOrderJobPlanRef.current
                                        .getFieldsValue()
                                        .TaskData.filter(
                                          (item) =>
                                            item &&
                                            item !== undefined &&
                                            item.TaskName === task.TaskName
                                        ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {task.TaskName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>

                        <Form.Item style={{ width: '100%', flex: '50%' }}>
                          <InputComponent style={{ display: 'none' }} />
                        </Form.Item>
                      </div>
                      {fields && Array.isArray(fields) && fields.length > 0 ? (
                        <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Panel
                              key={name}
                              // header={translation(translationCache, `Task ${key + 1}`)}
                              header={styledCollapse(formObject, key, translationCache)}
                              extra={
                                <Popconfirm
                                  title={translation(
                                    translationCache,
                                    'Are you sure that you want to delete the task？'
                                  )}
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => remove(name)}
                                  okText={translation(translationCache, constant.yes)}
                                  cancelText={translation(translationCache, constant.no)}
                                >
                                  <Tooltip
                                    title={translation(translationCache, 'Delete')}
                                    placement="bottom"
                                  >
                                    <DeleteOutlined />
                                  </Tooltip>
                                </Popconfirm>
                              }
                            >
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.sequence)}
                                  {...restField}
                                  name={[name, 'PlannedSequence']}
                                >
                                  <InputComponent
                                    type="number"
                                    placeholder={translation(translationCache, 'PlannedSequence')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.name)}
                                  {...restField}
                                  name={[name, 'TaskName']}
                                  rules={[{ required: true }]}
                                  TaskData
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Name')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.description)}
                                  {...restField}
                                  name={[name, 'TaskDescription']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Description')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.startTime)}
                                  {...restField}
                                  name={[name, 'StartTime']}
                                >
                                  <DatePicker
                                    placeholder={translation(translationCache, constant.selectDate)}
                                    inputReadOnly={true}
                                    style={{ width: '70%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    // inputReadOnly={false}
                                    onChange={(date, dateString) => {
                                      this.datepickerchange(date, dateString, key, 'StartTime');
                                    }}
                                    showTime={{
                                      defaultValue: moment('00:00:00', 'HH:mm:ss')
                                    }}
                                    disabledDate={(d) =>
                                      !d ||
                                      d.isAfter(
                                        this.workOrderJobPlanRef.current.getFieldValue() &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .TaskData &&
                                          this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                            key
                                          ] &&
                                          this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                            key
                                          ]['EndTime']
                                          ? moment(
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData[key]['EndTime']
                                            )
                                              .add(1, 'day')
                                              .format('YYYY-MM-DD')
                                          : ''
                                      )
                                    }
                                    disabledTime={(e) =>
                                      this.getDisabledHours(
                                        moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                        key,
                                        'StartTime'
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.endTime)}
                                  {...restField}
                                  name={[name, 'EndTime']}
                                >
                                  <DatePicker
                                    placeholder={translation(translationCache, constant.selectDate)}
                                    inputReadOnly={true}
                                    style={{ width: '70%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={(date, dateString) => {
                                      this.datepickerchange(date, dateString, key, 'EndTime');
                                    }}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current !== null &&
                                      this.workOrderJobPlanRef.current.getFieldValue() &&
                                      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                      this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                        key
                                      ] &&
                                      this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                        key
                                      ]['StartTime']
                                        ? false
                                        : true
                                    }
                                    // inputReadOnly={false}
                                    disabledDate={(d) =>
                                      !d ||
                                      d.isBefore(
                                        this.workOrderJobPlanRef.current &&
                                          this.workOrderJobPlanRef.current !== null &&
                                          this.workOrderJobPlanRef.current.getFieldValue() &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .TaskData &&
                                          this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                            key
                                          ] &&
                                          this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                            key
                                          ]['StartTime']
                                          ? moment(
                                              this.workOrderJobPlanRef.current.getFieldValue() &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .TaskData &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .TaskData[key] &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .TaskData[key]['StartTime']
                                            ).format('YYYY-MM-DD')
                                          : ''
                                      )
                                    }
                                    disabledTime={(e) =>
                                      this.getDisabledHours(
                                        moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                        key,
                                        'EndTime'
                                      )
                                    }
                                    showTime={{
                                      defaultValue: moment('23:59:59', 'HH:mm:ss')
                                    }}
                                  />{' '}
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.estimatedDuration)}
                                  {...restField}
                                  name={[name, 'EstimatedDuration']}
                                >
                                  <InputComponent
                                    style={{ width: '70%' }}
                                    placeholder={translation(translationCache, 'EstimatedDuration')}
                                    onKeyPress={(event) => {
                                      if (/[a-z|| A-Z || -]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                                {showMoreTaskDetails && showMoreTaskDetails === true ? (
                                  <>
                                    {workOrderStatus && workOrderStatus === 'CLOSE' ? (
                                      <>
                                        <Form.Item
                                          style={{ width: '100%', flex: '50%' }}
                                          label={translation(
                                            translationCache,
                                            constant.actualStartTime
                                          )}
                                          {...restField}
                                          name={[name, 'ActualStartTime']}
                                        >
                                          <DatePicker
                                            placeholder={translation(
                                              translationCache,
                                              constant.selectDate
                                            )}
                                            inputReadOnly={true}
                                            style={{ width: '70%' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                              defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            disabledDate={this.disabledDate}
                                            disabledHours={this.disabledHours}
                                            disabledMinutes={this.disabledMinutes}
                                          />{' '}
                                        </Form.Item>
                                        <Form.Item
                                          style={{ width: '100%', flex: '50%' }}
                                          label={translation(
                                            translationCache,
                                            constant.actualEndTime
                                          )}
                                          {...restField}
                                          name={[name, 'ActualEndTime']}
                                        >
                                          <DatePicker
                                            placeholder={translation(
                                              translationCache,
                                              constant.selectDate
                                            )}
                                            inputReadOnly={true}
                                            style={{ width: '70%' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                              defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            disabledDate={this.disabledDate}
                                            disabledHours={this.disabledEndHours}
                                            disabledMinutes={this.getDisabledMinutes}
                                          />{' '}
                                        </Form.Item>
                                      </>
                                    ) : null}

                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.UOM)}
                                      {...restField}
                                      name={[name, 'UOM']}
                                    >
                                      <Select
                                        style={{ width: '70%' }}
                                        showArrow
                                        showSearch
                                        openOnFocus
                                        placeholder={translation(translationCache, 'UOM')}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {UOMList &&
                                          Array.isArray(UOMList) &&
                                          UOMList.map((uom, index) => {
                                            return (
                                              <Option key={index} value={uom.Key}>
                                                {uom.Value}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.observation)}
                                      {...restField}
                                      name={[name, 'Observation']}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Observation')}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.criteria)}
                                      {...restField}
                                      name={[name, 'Criteria']}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Criteria')}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.recordBy)}
                                      {...restField}
                                      name={[name, 'RecordBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select RecordBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.checkedBy)}
                                      {...restField}
                                      name={[name, 'CheckedBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select CheckedBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      label={translation(translationCache, constant.attachments)}
                                      style={{ width: '100%', flex: '50%' }}
                                    >
                                      <FileBase64
                                        Attachments={
                                          formObject &&
                                          formObject['TaskData'] &&
                                          formObject['TaskData'][key] &&
                                          formObject['TaskData'][key]['Attachments']
                                            ? formObject['TaskData'][key]['Attachments']
                                            : []
                                        }
                                        disableAdd={
                                          formObject &&
                                          formObject['TaskData'] &&
                                          formObject['TaskData'][key] &&
                                          formObject['TaskData'][key].Id
                                            ? false
                                            : true
                                        }
                                        name={key}
                                        fileUpload={(values, key, fileDataObject) => {
                                          this.taskFileupload(
                                            values,
                                            key,
                                            fileDataObject,
                                            index,
                                            formObject &&
                                              formObject &&
                                              formObject.TaskData &&
                                              formObject.TaskData[index] &&
                                              formObject.TaskData[index].Id
                                              ? formObject.TaskData[index].Id
                                              : '',
                                            'TaskData'
                                          );
                                        }}
                                      ></FileBase64>
                                    </Form.Item>
                                  </>
                                ) : null}

                                <span style={{ width: '100%', flex: '50%' }}></span>
                                <span style={{ width: '100%', flex: '50%', color: 'white' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      width: '74%',
                                      cursor: 'pointer',
                                      color: '#d98e04',
                                      fontWeight: 'bold'
                                    }}
                                    onClick={() =>
                                      this.setState({ showMoreTaskDetails: !showMoreTaskDetails })
                                    }
                                  >
                                    <u>
                                      {showMoreTaskDetails === true ? 'Show less' : 'Show more'}
                                    </u>
                                  </div>
                                </span>
                                <Drawer
                                  visible={this.state.visible}
                                  width={1000}
                                  closable
                                  title="Hierarchy"
                                  onClose={this.onDrawerCancel}
                                >
                                  <HierarchyModal
                                    value={this.state.deviceId}
                                    treeData={this.state.treeData}
                                    translationCache={this.props.translationCache}
                                    language={this.props.language}
                                    fieldKey={name}
                                    handleDropdownChange={this.hierarchyDropdownChange}
                                    deviceList={this.state.deviceList}
                                  />
                                </Drawer>
                              </div>
                              <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
                                <StyledTabs
                                  theme={themeSettings}
                                  // className={'cardContainer'}
                                  defaultActiveKey="1"
                                  type="card"
                                  size={'middle'}
                                >
                                  <TabPane
                                    tab={translation(translationCache, constant.tool)}
                                    key="1"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <Toolsform
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onToolChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.labor)}
                                    key="2"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <LaborForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onLaborChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.material)}
                                    key="3"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <MaterialForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onMaterialChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.items)}
                                    key="4"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <ItemForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onItemChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                </StyledTabs>
                              </StyledSubForm>
                            </Panel>
                          ))}
                        </StyledCollapse>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </TabPane>
              <TabPane tab={translation(translationCache, 'Actual Task')} key="2">
                <Form.List
                  initialValue={
                    formObject && formObject['ActualTaskData'] ? formObject['ActualTaskData'] : []
                  }
                  name="ActualTaskData"
                >
                  {(fields, { add, remove }, index) => (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ width: '100%', flex: '50%' }}
                          label={translation(translationCache, constant.addTask)}
                          name="TaskName"
                        >
                          <Select
                            placeholder={translation(translationCache, 'Select Task')}
                            defaultValue={selectedTask}
                            onChange={(value) => this.actualTaskOnChange(value)}
                            style={{ width: '70%' }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0'
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px'
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    // onClick={() => this.setState({ viewEquipmentModal: true })}
                                    onClick={() => add()}
                                  >
                                    {' '}
                                    {translation(translationCache, constant.addNewTask)}{' '}
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {TaskList &&
                              Array.isArray(TaskList) &&
                              TaskList.map((task, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={task.Id}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current.getFieldsValue() &&
                                      this.workOrderJobPlanRef.current.getFieldsValue()
                                        .ActualTaskData &&
                                      this.workOrderJobPlanRef.current
                                        .getFieldsValue()
                                        .ActualTaskData.filter(
                                          (item) =>
                                            item &&
                                            item !== undefined &&
                                            item.TaskName === task.TaskName
                                        ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {task.TaskName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>

                        <Form.Item style={{ width: '100%', flex: '50%' }}>
                          <InputComponent style={{ display: 'none' }} />
                        </Form.Item>
                      </div>
                      {fields && Array.isArray(fields) && fields.length > 0 ? (
                        <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                          {fields.map(({ key, name, ...restField }) => (
                            <Panel
                              key={name}
                              // header={`${translation(translationCache, 'Task')} ${key + 1}`}
                              header={styledCollapse(formObject, key, translationCache)}
                              extra={
                                <Popconfirm
                                  title={translation(
                                    translationCache,
                                    'Are you sure that you want to delete the task？'
                                  )}
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => remove(name)}
                                  okText={translation(translationCache, constant.yes)}
                                  cancelText={translation(translationCache, constant.no)}
                                >
                                  <Tooltip
                                    title={translation(translationCache, 'Delete')}
                                    placement="bottom"
                                  >
                                    <DeleteOutlined />
                                  </Tooltip>
                                </Popconfirm>
                              }
                            >
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.sequence)}
                                  {...restField}
                                  name={[name, 'PlannedSequence']}
                                >
                                  <InputComponent
                                    type="number"
                                    placeholder={translation(translationCache, 'PlannedSequence')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.name)}
                                  {...restField}
                                  name={[name, 'TaskName']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Name')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.description)}
                                  {...restField}
                                  name={[name, 'TaskDescription']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Description')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.startTime)}
                                  {...restField}
                                  name={[name, 'StartTime']}
                                >
                                  <DatePicker
                                    placeholder={translation(translationCache, constant.selectDate)}
                                    inputReadOnly={true}
                                    style={{ width: '70%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    // inputReadOnly={false}
                                    onChange={(date, dateString) => {
                                      this.datepickerchange(date, dateString, key, 'StartTime');
                                    }}
                                    showTime={{
                                      defaultValue: moment('00:00:00', 'HH:mm:ss')
                                    }}
                                    disabledDate={(d) =>
                                      !d ||
                                      d.isAfter(
                                        this.workOrderJobPlanRef.current.getFieldValue() &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData[key] &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData[key]['EndTime']
                                          ? moment(
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .ActualTaskData[key]['EndTime']
                                            )
                                              .add(1, 'day')
                                              .format('YYYY-MM-DD')
                                          : ''
                                      )
                                    }
                                    disabledTime={(e) =>
                                      this.getDisabledHours(
                                        moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                        key,
                                        'StartTime'
                                      )
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.endTime)}
                                  {...restField}
                                  name={[name, 'EndTime']}
                                >
                                  <DatePicker
                                    placeholder={translation(translationCache, constant.selectDate)}
                                    inputReadOnly={true}
                                    style={{ width: '70%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={(date, dateString) => {
                                      this.datepickerchange(date, dateString, key, 'EndTime');
                                    }}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current !== null &&
                                      this.workOrderJobPlanRef.current.getFieldValue() &&
                                      this.workOrderJobPlanRef.current.getFieldValue()
                                        .ActualTaskData &&
                                      this.workOrderJobPlanRef.current.getFieldValue()
                                        .ActualTaskData[key] &&
                                      this.workOrderJobPlanRef.current.getFieldValue()
                                        .ActualTaskData[key]['StartTime']
                                        ? false
                                        : true
                                    }
                                    // inputReadOnly={false}
                                    disabledDate={(d) =>
                                      !d ||
                                      d.isBefore(
                                        this.workOrderJobPlanRef.current &&
                                          this.workOrderJobPlanRef.current !== null &&
                                          this.workOrderJobPlanRef.current.getFieldValue() &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData[key] &&
                                          this.workOrderJobPlanRef.current.getFieldValue()
                                            .ActualTaskData[key]['StartTime']
                                          ? moment(
                                              this.workOrderJobPlanRef.current.getFieldValue() &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .ActualTaskData &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .ActualTaskData[key] &&
                                                this.workOrderJobPlanRef.current.getFieldValue()
                                                  .ActualTaskData[key]['StartTime']
                                            ).format('YYYY-MM-DD')
                                          : ''
                                      )
                                    }
                                    disabledTime={(e) =>
                                      this.getDisabledHours(
                                        moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                        key,
                                        'EndTime'
                                      )
                                    }
                                    showTime={{
                                      defaultValue: moment('23:59:59', 'HH:mm:ss')
                                    }}
                                  />{' '}
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.estimatedDuration)}
                                  {...restField}
                                  name={[name, 'EstimatedDuration']}
                                >
                                  <InputComponent
                                    style={{ width: '70%' }}
                                    placeholder={translation(translationCache, 'EstimatedDuration')}
                                    onKeyPress={(event) => {
                                      if (/[a-z|| A-Z || -]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                                {showMoreTaskDetails && showMoreTaskDetails === true ? (
                                  <>
                                    {workOrderStatus && workOrderStatus === 'CLOSE' ? (
                                      <>
                                        <Form.Item
                                          style={{ width: '100%', flex: '50%' }}
                                          label={translation(
                                            translationCache,
                                            constant.actualStartTime
                                          )}
                                          {...restField}
                                          name={[name, 'ActualStartTime']}
                                        >
                                          <DatePicker
                                            placeholder={translation(
                                              translationCache,
                                              constant.selectDate
                                            )}
                                            inputReadOnly={true}
                                            style={{ width: '70%' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                              defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            disabledDate={this.disabledDate}
                                            disabledHours={this.disabledHours}
                                            disabledMinutes={this.disabledMinutes}
                                          />{' '}
                                        </Form.Item>
                                        <Form.Item
                                          style={{ width: '100%', flex: '50%' }}
                                          label={translation(
                                            translationCache,
                                            constant.actualEndTime
                                          )}
                                          {...restField}
                                          name={[name, 'ActualEndTime']}
                                        >
                                          <DatePicker
                                            placeholder={translation(
                                              translationCache,
                                              constant.selectDate
                                            )}
                                            inputReadOnly={true}
                                            style={{ width: '70%' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                              defaultValue: moment('00:00:00', 'HH:mm:ss')
                                            }}
                                            disabledDate={this.disabledDate}
                                            disabledHours={this.disabledEndHours}
                                            disabledMinutes={this.getDisabledMinutes}
                                          />{' '}
                                        </Form.Item>
                                      </>
                                    ) : null}

                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.UOM)}
                                      {...restField}
                                      name={[name, 'UOM']}
                                    >
                                      <Select
                                        style={{ width: '70%' }}
                                        showArrow
                                        showSearch
                                        openOnFocus
                                        placeholder={translation(translationCache, 'UOM')}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {UOMList &&
                                          Array.isArray(UOMList) &&
                                          UOMList.map((uom, index) => {
                                            return (
                                              <Option key={index} value={uom.Key}>
                                                {uom.Value}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.observation)}
                                      {...restField}
                                      name={[name, 'Observation']}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Observation')}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.criteria)}
                                      {...restField}
                                      name={[name, 'Criteria']}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Criteria')}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.recordBy)}
                                      {...restField}
                                      name={[name, 'RecordBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select RecordBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.checkedBy)}
                                      {...restField}
                                      name={[name, 'CheckedBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select CheckedBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      label="Attachments"
                                      style={{ width: '100%', flex: '50%' }}
                                    >
                                      <FileBase64
                                        disableAdd={
                                          formObject &&
                                          formObject['ActualTaskData'] &&
                                          formObject['ActualTaskData'][key] &&
                                          formObject['ActualTaskData'][key].Id
                                            ? false
                                            : true
                                        }
                                        Attachments={
                                          formObject &&
                                          formObject['ActualTaskData'] &&
                                          formObject['ActualTaskData'][key] &&
                                          formObject['ActualTaskData'][key]['Attachments']
                                            ? formObject['ActualTaskData'][key]['Attachments']
                                            : []
                                        }
                                        name={key}
                                        fileUpload={(values, key, fileDataObject) => {
                                          this.taskFileupload(
                                            values,
                                            key,
                                            fileDataObject,
                                            index,
                                            formObject &&
                                              formObject &&
                                              formObject.TaskData &&
                                              formObject.TaskData[index] &&
                                              formObject.TaskData[index].Id
                                              ? formObject.TaskData[index].Id
                                              : '',
                                            'TaskData'
                                          );
                                        }}
                                      ></FileBase64>
                                    </Form.Item>
                                  </>
                                ) : null}

                                <span style={{ width: '100%', flex: '50%' }}></span>
                                <span style={{ width: '100%', flex: '50%', color: 'white' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      width: '74%',
                                      cursor: 'pointer',
                                      color: '#d98e04',
                                      fontWeight: 'bold'
                                    }}
                                    onClick={() =>
                                      this.setState({ showMoreTaskDetails: !showMoreTaskDetails })
                                    }
                                  >
                                    <u>
                                      {showMoreTaskDetails === true ? 'Show less' : 'Show more'}
                                    </u>
                                  </div>
                                </span>
                                <Drawer
                                  visible={this.state.visible}
                                  width={1000}
                                  closable
                                  title="Hierarchy"
                                  onClose={this.onDrawerCancel}
                                >
                                  <HierarchyModal
                                    value={this.state.deviceId}
                                    treeData={this.state.treeData}
                                    translationCache={this.props.translationCache}
                                    language={this.props.language}
                                    fieldKey={name}
                                    handleDropdownChange={this.hierarchyDropdownChange}
                                    deviceList={this.state.deviceList}
                                  />
                                </Drawer>
                              </div>
                              <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
                                <StyledTabs
                                  theme={themeSettings}
                                  // className={'cardContainer'}
                                  defaultActiveKey="1"
                                  type="card"
                                  size={'middle'}
                                >
                                  <TabPane
                                    tab={translation(translationCache, constant.tool)}
                                    key="1"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <Toolsform
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onToolChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.labor)}
                                    key="2"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <LaborForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onLaborChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.material)}
                                    key="3"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <MaterialForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onMaterialChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                  <TabPane
                                    tab={translation(translationCache, constant.items)}
                                    key="4"
                                  >
                                    <Col span={24}>
                                      <Form.Item>
                                        <ItemForm
                                          fieldKey={name}
                                          formObject={
                                            this.workOrderJobPlanRef.current !== null
                                              ? this.workOrderJobPlanRef.current.getFieldsValue()
                                              : {}
                                          }
                                          onItemChange={this.handleSelect}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </TabPane>
                                </StyledTabs>
                              </StyledSubForm>
                            </Panel>
                          ))}
                        </StyledCollapse>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </TabPane>
            </StyledTabs>
            <Form.Item style={{ display: 'flex', justifyContent: 'right' }}>
              <StyledButton
                theme={themeSettings}
                onClick={() =>
                  this.props.onFinish(
                    this.workOrderJobPlanRef.current.getFieldsValue(),
                    'JobPlan',
                    fileData
                  )
                }
                style={{ marginTop: '20px', marginBottom: '-7px' }}
              >
                {translation(translationCache, constant.save)}
              </StyledButton>
            </Form.Item>
          </StyledForm>
        </>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderJobPlanes);
