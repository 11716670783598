import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Modal, Input, Form, Radio } from 'antd';

import { getTranslationCache } from '../../../../../../selectors/language';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 }
};

class ModalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: this.props.feedbackForm
    };
  }

  componentDidUpdate(preprops) {
    if (preprops.feedbackForm !== this.props.feedbackForm) {
      this.setState({
        feedbackForm: this.props.feedbackForm
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { feedbackForm } = this.state;
    return (
      <div>
        <Modal
          visible={this.props.visible}
          width={800}
          height={800}
          title={this.translation('Prediction Feedback')}
          okText="Submit"
          onOk={() => this.props.onOk(feedbackForm)}
          onCancel={this.props.onCancel}
        >
          <Form {...formItemLayout}>
            <Form.Item label={this.translation('TimeStamp')}>
              <Input
                readOnly
                value={moment
                  .utc(feedbackForm.Timestamp, 'YYYY-MM-DD HH:mm:ss')
                  .local()
                  .format('YYYY-MM-DD HH:mm')}
              ></Input>
            </Form.Item>
            {feedbackForm.NotificationId ? (
              <Form.Item label={this.translation('Notification Id')}>
                <Input readOnly value={feedbackForm.NotificationId}></Input>
              </Form.Item>
            ) : null}
            <Form.Item label={this.translation('Tag Name')}>
              <Input readOnly value={feedbackForm.TagName}></Input>
            </Form.Item>
            <Form.Item label={this.translation('Prediction Type')}>
              <Input readOnly value={feedbackForm.PredictionType}></Input>
            </Form.Item>
            {feedbackForm.NotificationId ? (
              <Form.Item label={this.translation('Notification')}>
                <Input readOnly value={feedbackForm.TagDescription}></Input>
              </Form.Item>
            ) : null}
            <Form.Item label={this.translation('Action')}>
              {' '}
              <Radio.Group
                value={feedbackForm[`Action`]}
                onChange={(e) =>
                  this.setState({
                    feedbackForm: {
                      ...feedbackForm,
                      Action: e.target.value
                    }
                  })
                }
              >
                <Radio value="yes">{this.translation('Yes')}</Radio>
                <Radio value="no">{this.translation('No')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={this.translation('Comment')}
              // onChange={(e) => this.setState({ feedbackText: e.target.value })}
            >
              <Input.TextArea
                value={feedbackForm[`Comment`]}
                onChange={(e) =>
                  this.setState({
                    feedbackForm: {
                      ...feedbackForm,
                      Comment: e.target.value
                    }
                  })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ModalView);
