import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Table, Button, Input, message, Popconfirm, Divider, Select } from 'antd';

import styles from './style.less';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

class DeviceTagReference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conditionReference: props.conditionReference || [],
      tagReferenceList: cloneDeep(props.tagReferenceList) || [],
      loading: false,
      data: props.conditionReference.ParameterReferences || []
    };
    this.index = 0;
    this._afterAddedRemoveFromList();
  }

  UNSAFE_componentWillReceiveProps({ tagReferenceList, conditionReference }) {
    this.setState({ tagReferenceList: cloneDeep(tagReferenceList), conditionReference });
    this._afterAddedRemoveFromList();
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter((item) => item.id === key)[0];
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTagReferenceList(newData, this.props.conditionReference.ParameterId); //Save Tag Reference
      this._afterAddedRemoveFromList();
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  newMember = () => {
    const { data } = this.state;
    const newData = data && Array.isArray(data) ? data.map((item) => ({ ...item })) : [];
    newData.push({
      id: `${this.index}`,
      Code: '',
      Reference: '',
      editable: true,
      isNew: true
    });
    this.index += 1;
    this._afterAddedRemoveFromList();
    this.setState({ data: newData });
  };

  remove(key) {
    const { data } = this.state;
    this._afterAddedRemoveFromList();
    if (key.isNew) {
      const newData = data.filter((item) => item.id !== key.id);
      this.setState({ data: newData });
      this.props.saveTagReferenceList(newData, this.props.conditionReference.parameterId);
      this._afterAddedRemoveFromList();
      // onChange(newData);
    } else {
      message.error('Cant delete this record..');
    }
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  handleFieldChange(value, fieldName, recordId) {
    // const { tagReferenceList } = this.state;
    let e = '';
    if (fieldName !== 'Reference') {
      e = value;
      // e = tagReferenceList[value].Code;
    } else {
      e = value;
    }
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(recordId, newData);
    if (target) {
      if (fieldName !== 'Reference') {
        target[fieldName] = e;
        // target[fieldName] = tagReferenceList[value].Code;
      } else {
        target[fieldName] = e;
      }
      this.setState({ data: newData });
    }
  }

  saveRow(e, key) {
    e.persist();
    this.setState({
      loading: true
    });
    setTimeout(() => {
      if (this.clickedCancel) {
        this.clickedCancel = false;
        return;
      }
      const target = this.getRowByKey(key) || {};
      if (!target.Code || !(!target.Reference || !target.Reference !== 0)) {
        message.error('Please fill in the complete member information');
        e.target.focus();
        this.setState({
          loading: false
        });
        return;
      }
      // delete target.isNew;
      this.saveToggleEditable(e, key);
      // this.onChange(data);
      this.setState({
        loading: false
      });
    }, 500);
  }

  _afterAddedRemoveFromList() {
    const { conditionReference } = this.state;

    const tagReferenceListCopy = cloneDeep(this.props.tagReferenceList);
    const duplicateList = [];
    const elements = [];
    conditionReference &&
      conditionReference.ParameterReferences &&
      Array.isArray(conditionReference.ParameterReferences) &&
      conditionReference.ParameterReferences.forEach((usedDP) => {
        tagReferenceListCopy &&
          tagReferenceListCopy.forEach((tagReference, index) => {
            if (tagReference.Code === usedDP.id) {
              duplicateList.push(index);
            }
          });
      });

    tagReferenceListCopy &&
      tagReferenceListCopy.forEach((ele, eleid) => {
        if (duplicateList.indexOf(eleid) === -1) {
          elements.push(ele);
        }
      });

    this.setState({ tagReferenceList: elements });
  }

  editRow(key) {
    this.toggleEditable(key);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key]);
        delete this.cacheOriginData[key];
      }
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  render() {
    const { loading, data, tagReferenceList } = this.state;

    const columns = [
      {
        title: 'Code',
        dataIndex: 'Code',
        key: 'Code',
        width: '30%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Select
                defaultValue={text}
                autoFocus
                style={{ width: 250 }}
                onChange={(e) => this.handleFieldChange(e, 'Code', record.id)}
                onKeyPress={(e) => this.handleKeyPress(e, record.id)}
                placeholder="Code"
              >
                {tagReferenceList &&
                  Array.isArray(tagReferenceList) &&
                  tagReferenceList.map((tagReference, index) => {
                    return (
                      <Option key={index} value={tagReference.Code}>
                        {tagReference.Name}
                      </Option>
                    );
                  })}
              </Select>
            );
          }

          let filterObject =
            tagReferenceList &&
            tagReferenceList.filter((tagRef) => {
              return tagRef.Code === text;
            });
          return filterObject && filterObject[0] ? filterObject[0].Name : '';
        }
      },
      {
        title: 'Reference',
        dataIndex: 'Reference',
        key: 'Reference',
        width: '25%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) => this.handleFieldChange(e.target.value, 'Reference', record.id)}
                onKeyPress={(e) => this.handleKeyPress(e, record.id)}
                placeholder="Reference"
              />
            );
          }
          return text;
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          const { loading } = this.state;
          if (!!record.editable && loading) {
            return null;
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <Button onClick={(e) => this.saveRow(e, record.id)}>Save</Button>
                  <Divider type="vertical" />
                  <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                    <Button>Delete</Button>
                  </Popconfirm>
                </span>
              );
            }
            return (
              <span>
                <Button onClick={(e) => this.saveRow(e, record.id)}>Save </Button>
                <Divider type="vertical" />
                <Button onClick={(e) => this.cancel(e, record.id)}>Cancel</Button>
              </span>
            );
          } else {
            return (
              <span>
                <Button onClick={(e) => this.editRow(record.id, e)}>Edit</Button>
                <Divider type="vertical" />
                <Popconfirm title="Do you want to delete ?" onConfirm={() => this.remove(record)}>
                  <Button>Delete</Button>
                </Popconfirm>
              </span>
            );
          }
        }
      }
    ];

    return (
      <div style={{ marginTop: '15px', backgroundColor: '#eaeaea' }}>
        <Button
          style={{ marginTop: 10, marginBottom: 3, marginLeft: 15 }}
          type="primary"
          size="small"
          onClick={this.newMember}
          icon={<PlusOutlined />}
        >
          Add Parameter Alias
        </Button>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data && Array.isArray(data) ? data : []}
          pagination={false}
          size="small"
          rowClassName={(record) => (record.editable ? styles.editable : '')}
          bordered
        />
      </div>
    );
  }
}

export default DeviceTagReference;
