import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';
import Flowchart from 'flowchart-react';
import { Form, message, Select, Tabs, Row, Col, Button, Tooltip, Drawer, Input } from 'antd';
import {
  NodeExpandOutlined,
  ApartmentOutlined,
  DownOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitWorkOrder,
  getJobPlansList,
  getJobPlanById,
  getWorkOrderName,
  getLocationByAsset,
  getWorkTypes,
  getWorkFlows,
  getStatus,
  getWorkFlow,
  getWorkOrderById,
  workOrderStatus
} from '../../../Utils/FormCalls';
import TableSelect from '../../../Widgets/TableSelect';
import WorkOrderJobPlanes from './WorkOrderJobPlanes';
import SchedulingWorkOrder from './SchedulingWorkOrder';
import Responsibility from './Responsibility';
import FollowUpWork from './Follow-up Work';
import CommunicationLog from './Communication Log';
import FailureReport from './Failure Report';
import { decode as base64_decode } from 'base-64';
import translation from '../../../Utils/translation';
import FileBase64 from '../../../Utils/FileUpload';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  StyledComponent,
  StyledForm,
  StyledButton,
  StyledSubForm,
  StyledTabs,
  StyledModal
} from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
const { Option } = Select;
const { TabPane } = Tabs;

class WorkOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      workTypesList: [],
      workFlowsList: [],
      statusList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      activeKey: '1',
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      noJobplan: false,
      optionDisabled: false,
      visible: false,
      workFlow: {},
      initate: false,
      visibleModal: false,
      translationCache: props.translationCache || {},
      fileUploadList: []
    };
  }
  // formRef = React.createRef();
  workOrderRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let deviceList = await getDeviceList();
      let organisationsList = await getOrganisationsList();
      let workTypesList = await getWorkTypes();
      let JobPlansList = await getJobPlansList('Active');
      let sitesList = await getSitesList();
      this.setState({
        sitesList,
        workTypesList,
        organisationsList,
        JobPlansList,
        deviceList
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  _getDataById = async () => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/WorkOrderDetails/');
    let formId = base64_decode(path[1]);
    let statusList = await getStatus();
    if (formId !== 'new') {
      formObject = await getWorkOrderById(base64_decode(path[1]), 'withId');
      let workFlowsList = await getWorkFlows(formObject.WorkTypeId);
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['JobPlan'] &&
                value['JobPlan']['TaskData'] &&
                value['JobPlan']['TaskData'].map((item, i) => {
                  return value['JobPlan']['TaskData'][i]
                    ? (value['JobPlan']['TaskData'][i]['StartTime']
                        ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['StartTime'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EndTime']
                        ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
                            .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
                            .local())
                        : null)
                    : null;
                }))
            }
          : _.noop();
      });
      this.setState({ workFlowsList });
    } else if (formId === 'new') {
      let formObject = this.workOrderRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.workOrderRef.current.setFieldsValue(formObject);
      if (formObject && !formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        if (workOrderName && workOrderName['WorkOrder']) {
          let WAPPRStatus =
            statusList && Array.isArray(statusList)
              ? statusList.filter((status) => status.Status === 'WAPPR')
              : [];
          let StatusId = WAPPRStatus && WAPPRStatus[0] ? WAPPRStatus[0].Id : undefined;
          this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
          this.handleChange(StatusId, 'StatusId');
        }
      }
    }
    this.setState({
      formObject,
      statusList,
      selectedJobPlan: formObject && formObject.JobPlanName ? formObject.JobPlanName : ''
    });
    if (formObject && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }

    if (prevProps.formObject !== this.props.formObject) {
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };

  handleSelect = (value, mainIndex, subindex, keyName) => {
    // let list = this.formRef.current.getFieldValue("TaskData")
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
    // this.formRef.current.setFieldsValue({ "TaskData": TaskData })
  };
  handleChange = async (value, key) => {
    let { deviceList, workFlowsList, workTypesList } = this.state;
    let formObject = this.workOrderRef.current ? this.workOrderRef.current.getFieldsValue() : {};
    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'Device') {
      let selectedDevice = deviceList.filter((item) => item.AliasCode === value);
      let location = await getLocationByAsset(selectedDevice[0].Asset);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.Name
      };
    } else if (key === 'WorkTypeId') {
      workFlowsList = await getWorkFlows(value);
    } else if (key === 'WorkFlowId') {
      workTypesList = await getWorkTypes(value);
    }
    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject, workFlowsList, workTypesList });
  };

  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (formObject && formObject[key]) {
      formObject[key].push(...values);
    } else if (formObject[key] !== undefined) {
      formObject[key] = values;
    } else {
      formObject[key].push({ [key]: values });
    }
    let WO = [...(fileUploadList && fileUploadList.WO ? fileUploadList.WO : [])];
    WO.push({ fileDataObject });
    fileUploadList = {
      ...fileUploadList,
      WO
    };

    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
  };

  jobPlanfileUpload = async (values, key, fileDataObject, JobPlanId) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (formObject && formObject['JobPlan'] && formObject['JobPlan'][key]) {
      formObject['JobPlan'][key].push(...values);
    } else if (formObject['JobPlan'] !== undefined) {
      formObject['JobPlan'][key] = values;
    } else {
      formObject['JobPlan'].push({ [key]: values });
    }
    let JobPlan = [...(fileUploadList && fileUploadList.JobPlan ? fileUploadList.JobPlan : [])];
    JobPlan.push({ fileDataObject, JobPlanId });
    fileUploadList = {
      ...fileUploadList,
      JobPlan
    };

    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
  };

  onFinish = async (values, key, fileData) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: {
        ...formObject[key],
        ...values
      }
    };
    this.setState({ formObject, fileData, noJobplan: true });
    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);
  };
  onFormsubmit = async () => {
    let { formObject, noJobplan, fileUploadList } = this.state;
    this.workOrderRef.current.getFieldsValue();
    if (!formObject.JobPlan) {
      message.info('Please Provide alteast one Jobplan/Task');
    } else if (noJobplan === true) {
      let statusResponse = await submitWorkOrder(formObject, fileUploadList);
      if (statusResponse && statusResponse.message) {
        this._getDataById(statusResponse.Id);
        message.success(statusResponse.message);
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      message.error('Please save before you submit the Workorder');
    }
  };
  onReset = () => {
    this.workOrderRef.current.resetFields();
  };
  jobPlanOnChange = async (value) => {
    let jobplanData = await getJobPlanById(value, 'withoutId');
    let formObject =
      this.workOrderRef.current !== null && this.workOrderRef.current.getFieldsValue()
        ? this.workOrderRef.current.getFieldsValue()
        : {};
    _.cloneDeepWith(jobplanData && jobplanData['TaskData'], (value) => {
      return value && value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    formObject = { ...formObject, JobPlan: jobplanData };
    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ selectedJobPlan: value, formObject });
  };
  viewWorkFlow = async () => {
    let { formObject } = this.state;
    let NodeData = await getWorkFlow(
      formObject && formObject.WorkFlowId ? formObject.WorkFlowId : null
    );
    if (NodeData && NodeData.message) {
      message.warn(NodeData.message);
    } else {
      if (NodeData && NodeData[0]) this.setState({ workFlow: NodeData[0].Node, visible: true });
    }
  };
  initateWorkOrder = async () => {
    const roleId = localStorage.get('roleId');
    let formObject = this.workOrderRef.current.getFieldsValue();
    let payload = {
      Id: formObject.Id,
      WorkFlowId: formObject.WorkFlowId,
      WfStatus: 'Submit',
      WorkTypeId: formObject.WorkTypeId,
      WorkOrderName: formObject.WorkOrder,
      RoleId: roleId
    };
    let initateWO = await workOrderStatus(payload);
    if (initateWO && initateWO.message) {
      message.info(`${initateWO.message}`);
      this.setState({ initate: true });
    } else {
      this.setState({ initate: false });
    }
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  TableDropdownChange = (value, key) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Device: value.AliasCode,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      EquipmentDescription: value.Name,
      LocationId: value.Asset,
      LocationDescription: value.AssetDescription
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [key]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  onDrawerCancel = (e) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    if (formObject.Equipment === undefined && e.target.tagName === 'DIV') {
      e.preventDefault();
      return message.error(`Please Click On Cancel`);
    } else {
      this.setState({
        visibleModal: false
      });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.workOrderRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  render() {
    let {
      // deviceList,
      sitesList,
      formObject,
      JobPlansList,
      selectedJobPlan,
      activeKey,
      workTypesList,
      statusList,
      workFlowsList,
      translationCache,
      // optionDisabled,
      workFlow,
      visible,
      initate
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <Drawer
            open={visible}
            width={window.innerWidth > 19000 ? 18000 : window.innerWidth - 100}
            onClose={this.handleCancel}
            title="WorkFlow Flow Chart"
          >
            <Flowchart
              showToolbar={false}
              style={{ width: '100%', height: 500 }}
              nodes={workFlow && workFlow.nodes}
              connections={workFlow && workFlow.connections}
            />
          </Drawer>
          <StyledForm
            ref={this.workOrderRef}
            theme={themeSettings}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            disabled={formObject && formObject['StatusId'] === 5 ? true : false}
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>
            <Row style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Col style={{ width: '45px' }}>
                {formObject && formObject.Id ? (
                  <Tooltip title="View WorkFlow">
                    <Button
                      type="primary"
                      onClick={() => {
                        this.viewWorkFlow();
                      }}
                      shape="circle"
                      icon={<ApartmentOutlined />}
                    />
                  </Tooltip>
                ) : null}
              </Col>
              <Col style={{ width: '45px' }}>
                {formObject && formObject.Id ? (
                  <Tooltip title="Initiate WorkFlow">
                    <Button
                      type="primary"
                      disabled={
                        formObject && formObject.RecordStatus === null && initate === false
                          ? false
                          : true
                      }
                      onClick={() => {
                        this.setState({ initate: true });
                        this.initateWorkOrder();
                      }}
                      shape="circle"
                      icon={<NodeExpandOutlined />}
                    />
                  </Tooltip>
                ) : null}
              </Col>
            </Row>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Id"></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.workOrder)}
                name="WorkOrder"
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                rules={[{ required: true }]}
              >
                <InputComponent
                  disabled={true}
                  // disabled={formObject && formObject.Id ? true : false}
                  placeholder={translation(translationCache, 'Input WorkOrderName')}
                  value={'WO-11'}
                  style={{ width: '70%' }}
                  onChange={(e) => this.handleChange(e.target.value, 'WorkOrder')}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.description)}
                name="Description"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input WorkOrderDescription')}
                  onChange={(e) => this.handleChange(e.target.value, 'Description')}
                  style={{ width: '70%' }}
                />
              </Form.Item>
              <Form.Item name="Device"></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.equipment)}
                name="DisplayCode"
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                rules={[{ required: true }]}
              >
                <InputComponent
                  disabled={true}
                  suffix={
                    <DownOutlined
                      style={{
                        color: formObject && formObject.Id ? null : '#1a3b5c',
                        cursor: formObject && formObject.Id ? 'not-allowed' : null,
                        opacity: formObject && formObject.Id ? '0.8' : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (formObject && !formObject.Id) {
                          this.getDeviceData('visibleModal');
                        }
                      }}
                    />
                  }
                  // maxLength={150}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.SelectEquipment)}
                  // onClick={() => {
                  //   this.getDeviceData('visible');
                  // }}
                />
              </Form.Item>
              <StyledModal
                open={this.state.visibleModal}
                width={800}
                closable
                title={translation(translationCache, constant.selectEquipment)}
                onOk={this.onModalOk}
                onCancel={this.onDrawerCancel}
              >
                <TableSelect
                  data={this.state.tableData}
                  translationCache={this.props.translationCache}
                  key="Equipment"
                  columns={[
                    {
                      title: translation(translationCache, 'Equipment'),
                      dataIndex: 'DisplayCode',
                      key: 'DisplayCode',
                      ...this.getColumnSearchProps('DisplayCode')
                    },
                    {
                      title: translation(translationCache, 'Equipment Description'),
                      dataIndex: 'Name',
                      key: 'Name',
                      ...this.getColumnSearchProps('Name')
                    }
                  ]}
                  handleDropdownChange={(e) => this.TableDropdownChange(e, 'visibleModal')}
                />
                {/* <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                  deviceList={this.state.deviceList}
                /> */}
              </StyledModal>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.equipmentDescription)}
                name="EquipmentDescription"
                rules={[{ required: true }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Autopopulate based on Equipment')}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.location)}
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                name="LocationId"
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Input Location')}
                  disabled={true}
                  style={{ width: '70%' }}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.locationDescription)}
                name="LocationDescription"
                rules={[{ required: true }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Enter Location Description')}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.site)}
                name="SiteId"
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Site')}
                  onChange={(value) => this.handleChange(value, 'SiteId')}
                  style={{ width: '70%' }}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.workType)}
                name="WorkTypeId"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Work Type')}
                  onChange={(value) => this.handleChange(value, 'WorkTypeId')}
                  style={{ width: '70%' }}
                >
                  {workTypesList.map((workType, index) => {
                    return (
                      <Option key={index} value={workType.Id}>
                        {' '}
                        {translation(translationCache, workType.WorkType)}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.workFlow)}
                name="WorkFlowId"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Work Flow')}
                  onChange={(value) => this.handleChange(value, 'WorkFlowId')}
                  style={{ width: '70%' }}
                >
                  {workFlowsList &&
                    workFlowsList !== undefined &&
                    workFlowsList.map((workFlow, index) => {
                      return (
                        <Option key={index} value={workFlow.Value}>
                          {workFlow.Key}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.status)}
                name="StatusId"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Status')}
                  onChange={(value) => this.handleChange(value, 'StatusId')}
                  style={{ width: '70%' }}
                >
                  {statusList.map((status, index) => {
                    return (
                      <Option
                        key={index}
                        value={status.Id}
                        // disabled={optionDisabled}
                      >
                        {' '}
                        {translation(translationCache, status.Status)}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="Attachments"
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.attachments)}
              >
                <FileBase64
                  className={'formuploadInput'}
                  downloadClassName={'formdownloadInput'}
                  Attachments={formObject.Attachments}
                  fileUpload={(values, key, fileDataObject) => {
                    this.fileupload(values, key, fileDataObject);
                  }}
                ></FileBase64>
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div>

            <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
              <StyledTabs
                theme={themeSettings}
                defaultActiveKey={activeKey}
                onChange={(activeKey) => this.setState({ activeKey })}
                type="card"
                size={'small'}
              >
                <TabPane tab={translation(translationCache, constant.jobPlanTasks)} key="1">
                  {/* (Plans/Actuals) */}
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Form.Item
                      label={translation(translationCache, constant.selectJobPlan)}
                      name="JobPlanName"
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <Select
                        placeholder={translation(translationCache, 'Select JobPlan')}
                        defaultValue={formObject.JobPlan}
                        style={{ width: '70%' }}
                        onChange={(value) => this.jobPlanOnChange(value)}
                      >
                        {JobPlansList &&
                          Array.isArray(JobPlansList) &&
                          JobPlansList.map((jobplan, index) => {
                            return (
                              <Option
                                key={index}
                                value={jobplan.Id}
                                disabled={
                                  this.workOrderRef.current &&
                                  this.workOrderRef.current.getFieldsValue() &&
                                  this.workOrderRef.current.getFieldsValue().JobPlan &&
                                  this.workOrderRef.current.getFieldsValue().JobPlan &&
                                  this.workOrderRef.current.getFieldsValue().JobPlan.JobPlanName &&
                                  this.workOrderRef.current.getFieldsValue().JobPlan.JobPlanName ===
                                    jobplan.JobPlanName
                                    ? true
                                    : false
                                }
                              >
                                {jobplan.JobPlanName}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={translation(translationCache, constant.attachments)}
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <FileBase64
                        downloadClassName={'downloadInput'}
                        Attachments={
                          formObject &&
                          formObject['JobPlan'] &&
                          formObject['JobPlan']['Attachments']
                            ? formObject['JobPlan']['Attachments']
                            : {}
                        }
                        fileUpload={(values, key, fileDataObject) => {
                          this.jobPlanfileUpload(
                            values,
                            key,
                            fileDataObject,
                            formObject && formObject.JobPlan && formObject.JobPlan.Id
                              ? formObject.JobPlan.Id
                              : ''
                          );
                        }}
                      ></FileBase64>
                    </Form.Item>
                  </div>

                  <Form.Item name="JobPlan" style={{ width: '100%', flex: '50%' }}>
                    {(selectedJobPlan && selectedJobPlan !== undefined && selectedJobPlan !== '') ||
                    (formObject['JobPlan'] !== undefined &&
                      formObject['JobPlan']['Id'] !== undefined) ? (
                      <WorkOrderJobPlanes
                        form={formObject}
                        noJobplan={false}
                        //onFinishFailed={this.onFinishFailed}
                        onFinish={this.onFinish}
                        formObject={formObject['JobPlan']}
                        fileData={this.state.fileData}
                        fileUploadListBack={(fileUploadList) => this.setState({ fileUploadList })}
                        workOrderStatus={formObject && formObject.StatusId ? 'CLOSE' : null}
                      ></WorkOrderJobPlanes>
                    ) : null}
                    {selectedJobPlan === '' ? (
                      <WorkOrderJobPlanes
                        // disableAdd={
                        //   formObject &&
                        //     formObject.TaskData &&
                        //     formObject.TaskData[index] &&
                        //     formObject.TaskData[index].Id
                        //     ? false
                        //     : true
                        // }
                        noJobplan={true}
                        // onFinishFailed={this.onFinishFailed}
                        onFinish={this.onFinish}
                        formObject={formObject['JobPlan']}
                        fileData={this.state.fileData}
                        workOrderStatus={formObject && formObject.StatusId}
                        fileUploadListBack={(fileUploadList) => this.setState({ fileUploadList })}
                      ></WorkOrderJobPlanes>
                    ) : null}
                  </Form.Item>
                </TabPane>

                <TabPane
                  tab={translation(translationCache, constant.schedulingInformationForPM)}
                  key="2"
                >
                  <SchedulingWorkOrder
                    onFinish={this.onFinish}
                    formObject={formObject['SchedulingWorkOrder']}
                  ></SchedulingWorkOrder>
                </TabPane>

                <TabPane tab={translation(translationCache, constant.responsibility)} key="3">
                  <Responsibility
                    onFinish={this.onFinish}
                    formObject={formObject['Responsibility']}
                  ></Responsibility>
                </TabPane>
                <TabPane tab={translation(translationCache, constant.followupWork)} key="4">
                  <FollowUpWork
                    onFinish={this.onFinish}
                    formObject={formObject['FollowUpWork']}
                  ></FollowUpWork>
                </TabPane>
                <TabPane tab={translation(translationCache, constant.communicationLog)} key="5">
                  <CommunicationLog
                    onFinish={this.onFinish}
                    formObject={formObject['CommunicationLog']}
                  ></CommunicationLog>
                </TabPane>
                <TabPane tab={translation(translationCache, constant.failureReport)} key="6">
                  <FailureReport
                    onFinish={this.onFinish}
                    formObject={formObject['FailureReport']}
                  ></FailureReport>
                </TabPane>
              </StyledTabs>
            </StyledSubForm>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderDetails);
