import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Row, Col, Tag, Spin, Card, List, Table, Button, Space } from 'antd';
import history from '../../../../../commons/history';
import ReactEcharts from '../../Echarts/Echarts';
import localStorage from '../../../../../utils/localStorage';
import {
  trendLabelTranslation,
  predictionsLegendsTranslation
} from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { DonutCounterMock } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

import '../../CSS/Table.css';

class DonutCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],

      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      loading: false,
      linksList: [],
      listDataSource: [],
      columns: [],
      dataSource: [],
      selectedDevice: '',
      AlertType: '',
      selectedDeviceName: ''
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getGenerateData(
      this.state.selectedDevice,
      this.state.AlertType,
      true,
      this.state.selectedDeviceName
    );
    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this._getGenerateData(
          this.state.selectedDevice,
          this.state.AlertType,
          true,
          this.state.selectedDeviceName
        );
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
      this._getGenerateData(
        this.state.selectedDevice,
        this.state.AlertType,
        true,
        this.state.selectedDeviceName
      );
    }
  }
  _getAlertsData = (DeviceCode) => {
    let { AssetCode, graphprops, dashboardName } = this.props;

    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/dashboard/getAlertsCount`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        AssetCode: AssetCode,
        DeviceCode: DeviceCode,
        SiteId: siteId,
        StartDate: moment().subtract(7, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        GroupName: dashboardName,
        HierarchyCode:
          graphprops &&
          graphprops.treePayload &&
          graphprops.treePayload &&
          graphprops.treePayload.payload &&
          graphprops.treePayload.payload.HierarchyCode
            ? graphprops.treePayload.payload.HierarchyCode
            : ''
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };
  _getAlertsDetails = (AlertType, DeviceCode) => {
    let { AssetCode, graphprops, dashboardName } = this.props;
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const ParameterTypeObject = {
      method: 'POST',
      url: `/api/dashboard/getAlertsData`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        AssetCode: AssetCode,
        DeviceCode: DeviceCode,
        SiteId: siteId,
        StartDate: moment().subtract(7, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        Type: AlertType || 'All',
        GroupName: dashboardName,
        HierarchyCode:
          graphprops &&
          graphprops.treePayload &&
          graphprops.treePayload &&
          graphprops.treePayload.payload &&
          graphprops.treePayload.payload.HierarchyCode
            ? graphprops.treePayload.payload.HierarchyCode
            : ''
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  _getGenerateData = async (selectedDevice, AlertType, status, selectedDeviceName) => {
    let { graphprops } = this.props;
    if (status) {
      let listDataSource = await this._getAlertsData(selectedDevice);
      this.setState({
        listDataSource
      });
    }
    let dataSource = await this._getAlertsDetails(AlertType, selectedDevice);
    let columnsData =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].columns
        ? JSON.parse(graphprops[graphprops.graph].columns)
        : [];
    let columns =
      columnsData &&
      columnsData.map((data) => {
        return {
          ...data,
          ...(data.sort && data.sort === false
            ? { sorter: (a, b) => a[data].length - b[data].length }
            : {}),
          ...(data.isUTC && data.isUTC === false
            ? {
                render: (text) => {
                  return {
                    children: <div>{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                  };
                }
              }
            : {})
        };
      });
    let linksList =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].links
        ? JSON.parse(graphprops[graphprops.graph].links)
        : [];

    this.setState({
      columns,
      dataSource,
      selectedDevice,
      AlertType,
      selectedAlert: status === false ? AlertType : '',
      selectedDeviceName,
      linksList
    });
  };

  onChartClick = async (cellData) => {
    if (cellData.componentSubType === 'pie') {
      await this._getGenerateData(
        cellData.data.description,
        this.state.AlertType,
        true,
        cellData.name
      );
      this.setState({ selectedAlert: '' });
    } else if (cellData.componentType === 'title') {
      await this._getGenerateData('', this.state.AlertType, true, cellData.name);
      this.setState({ selectedAlert: '' });
      let listDataSource = await this._getAlertsData(this.state.selectedDevice);
      this.setState({
        listDataSource
      });
    }
  };

  render() {
    const {
      errorInAPI,
      noDataInDB,
      refreshDateTime,
      loading,
      listDataSource,
      option,
      dataSource,
      columns,
      selectedDeviceName,
      selectedAlert,
      selectedDevice,
      linksList
    } = this.state;

    let { graphprops } = this.props;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <>
            {loading === false ? (
              <div style={{ padding: '0px' }}>
                <Row>
                  <Col span={24}>
                    {noDataInDB === true || errorInAPI === true ? (
                      noDataInDB === true ? (
                        <NoRecordComponent />
                      ) : (
                        <ErrorComponent />
                      )
                    ) : (
                      <ReactEcharts
                        option={option}
                        className="react_for_echarts"
                        style={{ height: '360px', width: '100%' }}
                        onEvents={onEvents}
                      />
                    )}
                  </Col>
                  <Col span={24}>
                    <Space wrap className="linkList">
                      {linksList && Array.isArray(linksList) && linksList.length > 0
                        ? linksList.map((data) => {
                            return (
                              <>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    history.push(data.path);
                                  }}
                                >
                                  {' '}
                                  {data.name}{' '}
                                </Button>
                              </>
                            );
                          })
                        : null}
                    </Space>
                    {listDataSource &&
                    Array.isArray(listDataSource) &&
                    listDataSource.length > 0 ? (
                      <List
                        grid={{
                          gutter: 5,
                          column: 3
                        }}
                        dataSource={listDataSource}
                        style={{
                          textAlign: 'center',
                          textColor: '#fff !important',
                          fontSize: '1em'
                        }}
                        header={
                          <div style={{ color: 'white', fontSize: '17px', fontWeight: 'bold' }}>
                            {selectedDeviceName ? selectedDeviceName : 'All Devices'}
                          </div>
                        }
                        renderItem={(item) => (
                          <List.Item>
                            <Card
                              className="counterCard"
                              title={item.Name}
                              style={{
                                backgroundColor:
                                  selectedAlert === item.Name ? '#7bbcf2' : 'steelblue',
                                fontSize: '1.5em',
                                cursor: 'pointer'
                              }}
                            >
                              <div
                                onClick={() =>
                                  this._getGenerateData(
                                    selectedDevice,
                                    item.Name,
                                    false,
                                    this.state.selectedDeviceName
                                  )
                                }
                              >
                                {item.Value}
                              </div>
                            </Card>
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </Col>
                  <Col span={24}>
                    <Space wrap className="linkList">
                      {linksList && Array.isArray(linksList) && linksList.length > 0
                        ? linksList.map((data) => {
                            return (
                              <>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    history.push(
                                      `${data.path}?AssetCode=${this.props.AssetCode}&report=true`
                                    );
                                  }}
                                >
                                  {' '}
                                  {data.name}{' '}
                                </Button>
                              </>
                            );
                          })
                        : null}
                    </Space>

                    <div style={{ marginTop: '50px' }}>
                      {columns && Array.isArray(columns) && columns.length > 0 ? (
                        <Table
                          showSorterTooltip={false}
                          className="basictable"
                          columns={columns && Array.isArray(columns) ? columns : []}
                          dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                          pagination={{ pageSize: '5' }}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white',
                  marginTop: '15%'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </>
        )}
      </Fragment>
    );
  }

  componentWillUnmount() {
    this.abortController.abort();
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  getData = async (props, theme) => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = DonutCounterMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        // noDataInDB = true;
      }
    } else {
      const option = cloneDeep(this.state.option);
      let legend = {
        left: '75%',
        orient: 'vertical',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 15,
          fontWeight: 'bold',
          left: '58%',
          top: '61%'
        },
        data:
          json &&
          Array.isArray(json) &&
          json.map((data) => {
            return data.Name;
          })
      };
      let series = [];
      let total = 0;
      if (json && Array.isArray(json)) {
        series = json.map((val) => {
          total = total + val.Value;
          return { value: val.Value, name: val.Name, description: val.Description };
        });

        let title = {
          text: `Total`,
          triggerEvent: true,
          subtext: `${total}`,
          left: '30%',
          top: 'center',

          textStyle: {
            color: 'white'
          },
          subtextStyle: {
            fontSize: 20,
            color: 'white',
            left: '20%'
          },
          textAlign: 'center'
        };

        if (
          this.props.graphprops.colors !== null &&
          this.props.graphprops.colors !== undefined &&
          this.props.graphprops.colors.length !== 0
        ) {
          option.color = [...this.props.graphprops.colors];
        }
        // option.legend.data = legendData || [];
        series = await trendLabelTranslation(series, this.props.translationCache);
        let legends = await predictionsLegendsTranslation(legend, this.props.translationCache);
        option.title = title;
        option.legend = legends;
        option.series[0].data = [...series];
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}'
      },
      legend: {
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 18,
          fontWeight: 'bold'
        },
        type: 'scroll',
        orient: 'vertical',
        right: 10,
        top: 0,
        bottom: 20,
        data: []
      },

      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : 'white',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: this.props.graphprops.title,
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '35%'],
          height: '500px',
          avoidLabelOverlap: false,
          data: [],
          label: {
            show: true,
            position: 'inner',
            formatter: '{c}'
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(DonutCounter);
