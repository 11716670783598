import React from 'react';
import 'leaflet/dist/leaflet.css';

import MapWithClusterExample from './MapWithClusterExample';
// import MapWithClustering from './MapWithClustering';
// import MapWithSidePanel from './MapWithSidePanel';
// import MapWithRoutes from './MapWithRoutes';

function MapContainer() {
  return (
    <div>
      <MapWithClusterExample />
      {/* <MapWithClustering /> */}
      {/* <MapWithSidePanel /> */}
      {/* <MapWithRoutes /> */}
    </div>
  );
}

export default MapContainer;
