import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { defaultRefreshTime } from '../../constant.json';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      imageData: {}
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  render() {
    const { counter, errorInAPI, noDataInDB, refreshDateTime, imageData } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : this.state.boolean === true ? (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <span
              style={{
                color: counter === 'on' ? 'green' : 'red',
                fontSize: this.props.graphprops.counter.fontSize
                  ? this.props.graphprops.counter.fontSize + 'em'
                  : '5em'
              }}
            >
              {this.state.counter}
            </span>
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: `wrap`
            }}
          >
            {graphprops &&
            graphprops[graphprops.graph] &&
            graphprops[graphprops.graph].displayType &&
            graphprops[graphprops.graph].displayType === 'Image & Value' ? (
              <span
                style={{
                  justifyContent: 'center',
                  width: '50%',
                  alignItems: 'center'
                }}
              >
                <img
                  src={imageData && imageData.Description ? imageData.Description : ''}
                  type="image"
                  alt=""
                  style={{ height: '100px', width: '110px' }}
                />
              </span>
            ) : null}

            <span
              style={{
                color:
                  this.props.graphprops[graphprops.graph] &&
                  this.props.graphprops[graphprops.graph].titleColor
                    ? this.props.graphprops[graphprops.graph].titleColor
                    : '',
                fontSize:
                  this.props.graphprops[graphprops.graph] &&
                  this.props.graphprops[graphprops.graph].fontSize
                    ? this.props.graphprops[graphprops.graph].fontSize + 'em'
                    : '5em',
                justifyContent: 'center',
                width: '50%',
                alignItems: 'center',
                marginTop: '5%'
              }}
            >
              {this.state.counter}
            </span>
          </div>
        )}
      </Fragment>
    );
  }

  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let data = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic' && this.props.DeviceCode) ||
        (graphprops && graphprops.dateExists === 'true' && this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops && graphprops.dateRangeExists === 'true' && this.props.selectionDateRange) ||
        (graphprops && graphprops.dayMonthYearExists === 'true' && this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        data = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }

    if (
      (data && data.message) ||
      (Array.isArray(data) && data.length === 0 && graphprops.enableMock !== true)
    ) {
      if (data.message) {
        errorInAPI = true;
      } else if (data.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'number'
      ) {
        this.setState({ counter: Number(data[0].Value).toFixed(2) });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'string'
      ) {
        this.setState({
          counter: data[0].Value,
          refreshDateTime: moment()
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'both'
      ) {
        this.setState({
          counter: Number(data[0].Value).toFixed(2),
          uom: this.props.graphprops.uom
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'boolean'
      ) {
        this.setState({ counter: Number(data[0].Value).toFixed(2), boolean: true });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'date-picker'
      ) {
        this.setState({
          counter: moment
            .utc(data[0].DateTime)
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        });
      }
      if (
        graphprops &&
        graphprops[graphprops.graph] &&
        graphprops[graphprops.graph].displayType &&
        graphprops[graphprops.graph].displayType === 'Image & Value'
      ) {
        this.setState({ imageData: data && data[0] ? data[0] : {} });
      }
      this.setState({
        errorInAPI,
        noDataInDB
      });
    }
    if (data && Array.isArray(data) && data[0] && data[0].Value === null) {
      this.setState({ errorInAPI, noDataInDB: !noDataInDB });
    } else {
      this.setState({ errorInAPI, noDataInDB });
    }
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Counter);
