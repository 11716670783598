import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Card, Col, Row, Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import {
  trendLabelTranslationNew,
  predictionsLegendsTranslation
} from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { objectOfObject } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';

class CountersPie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      fromedit: this.props.graphprops.fromedit,
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      counter: [],
      option1: {},
      option2: {}
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async (props, theme) => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = objectOfObject;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let counter = json && json.query ? json.query : [];
      let pie = json && json.query1 ? json.query1 : [];
      if (pie && Array.isArray(pie) && pie[0]) {
        let option1 = cloneDeep(this.state.option);
        let legend = {
          left: 'center',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontSize: 18,
            fontWeight: 'bold'
          }
        };

        let series = await trendLabelTranslationNew([pie[0]], this.props.translationCache);
        let legends = await predictionsLegendsTranslation(legend, this.props.translationCache);

        option1.series[0].data = series;

        option1.legend = legends;
        this.setState({
          option1,
          refreshDateTime: moment()
        });
      }

      if (pie && Array.isArray(pie) && pie[1]) {
        let option2 = cloneDeep(this.state.option);
        let legend = {
          left: 'center',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontSize: 18,
            fontWeight: 'bold'
          }
        };

        let series = await trendLabelTranslationNew([pie[1]], this.props.translationCache);
        let legends = await predictionsLegendsTranslation(legend, this.props.translationCache);

        option2.series[0].data = series;

        option2.legend = legends;
        this.setState({
          option2,
          refreshDateTime: moment()
        });
      }

      if (counter && Array.isArray(counter)) {
        this.setState({
          counter
        });
      }
      this.setState({
        pie
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime, counter, option2, option1, pie } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <Row>
            <Col span={pie && pie[1] ? 8 : 12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '30px',
                  justifyContent: 'space-between'
                }}
              >
                {counter && Array.isArray(counter)
                  ? counter.map((counterData) => {
                      return (
                        <Card
                          key={counterData}
                          style={{ background: 'rgb(38, 38, 38)', marginBottom: '23px' }}
                          title={
                            <div
                              style={{ display: 'flex', justifyContent: 'center', color: 'white' }}
                            >
                              {counterData.Description}
                            </div>
                          }
                          bordered={true}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              color: 'white',
                              margin: '20px',
                              fontSize: '18px'
                            }}
                          >
                            {counterData.Value}
                          </div>
                        </Card>
                      );
                    })
                  : null}
              </div>
            </Col>
            <Col span={pie && pie[1] ? 8 : 12}>
              <ReactEcharts
                option={option1}
                notMerge={true}
                lazyUpdate={true}
                className="react_for_echarts"
              />
            </Col>
            {pie && pie[1] ? (
              <Col span={8}>
                <ReactEcharts
                  option={option2}
                  notMerge={true}
                  lazyUpdate={true}
                  className="react_for_echarts"
                />
              </Col>
            ) : null}
          </Row>
        )}
      </Fragment>
    );
  }
  getOption = () => {
    let option = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'white'
            }
          },
          axisLine: {
            lineStyle: {
              width: 40
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [],
          title: {
            fontSize: 18,
            fontWeight: 'bold',
            color: 'white',
            offsetCenter: [0, '30%']
          },
          detail: {
            width: 5,
            height: 5,
            fontSize: 18,
            color: 'white',
            formatter: '{value}',
            // fontWeight: 'bold',
            valueAnimation: true,
            offsetCenter: ['0%', '10%']
          }
        }
      ]
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(CountersPie);
