import React from 'react';
import axios from 'axios';
import { Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { Button, Input, Tooltip, Form } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import history from '../../../../../src/commons/history';

import localStorage from '../../../../utils/localStorage';
import { StyledDashboard, StyledButton } from './style.js';

import './style.css';

class XMLConfigurationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      XMLConfiguration: [],
      dataSource: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['Name', 'PayloadType', 'DataObject', 'UniqueKeys', 'ResponseUrl'],

        firstTitle: 'Name',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/MaximoXMLConfiguration'
        }
      }
    };
  }

  componentDidMount = async () => {
    this.getMaximoList();
  };
  editXMLMaster = (record) => {
    history.push({ pathname: `/rubus/MaximoXMLConfiguration/${record.Id}` });
  };

  getMaximoList = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/xmlFormat/get?SiteId=1`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ XMLConfiguration: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { XMLConfiguration } = this.state;
    let maximoCol = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        filter: true,
        ...this.getColumnSearchProps('Name')
      },
      {
        title: 'Data Object',
        dataIndex: 'DataObject',
        key: 'DataObject',
        filter: true,
        ...this.getColumnSearchProps('DataObject')
      },
      {
        title: 'Payload Type',
        dataIndex: 'PayloadType',
        key: 'PayloadType',
        filter: true,
        ...this.getColumnSearchProps('PayloadType')
      },
      {
        title: 'Response Url',
        dataIndex: 'ResponseUrl',
        key: 'ResponseUrl'
      },
      {
        title: 'Action',
        width: '600',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this.editXMLMaster(record)} size="middle">
              {' '}
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];
    let config = {
      title: 'XML File Master',

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['Name', 'DataObject', 'PayloadType', 'UniqueKeys', 'ResponseUrl'],

      firstTitle: 'Name',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/MaximoXMLConfiguration'
      }
    };
    return (
      <StyledDashboard
        className="XMLForm"
        style={{ minHeight: window.innerHeight - 173, background: '#29415a' }}
      >
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'right', background: '#29415a' }}>
            <StyledButton
              // theme={themeSettings}
              type="dashed"
              style={{ background: '#29415a', color: '#fff' }}
              onClick={() =>
                history.push({
                  pathname: `${config.edit.redirectURL}/1`
                })
              }
            >
              Add
            </StyledButton>
          </div>
        </Form.Item>
        <div style={{ marginTop: '2%', background: '#29415a' }}>
          <Table
            className="table"
            columns={maximoCol}
            dataSource={XMLConfiguration}
            config={config}
          />
        </div>
      </StyledDashboard>
    );
  }
}
export default XMLConfigurationList;
