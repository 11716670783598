import React, { Fragment } from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { cloneDeep } from 'lodash';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Tabs,
  Button,
  Form,
  Table,
  message,
  Input,
  Drawer,
  DatePicker,
  Select
} from 'antd';

import 'antd/lib/date-picker/style/css';
import { localTimeConverter } from './utils';
import localStorage from '../../utils/localStorage';
import InitialState from './const';
import {
  getProcessArea,
  getWorkCenter,
  getEquipment,
  getAreaType,
  getDescription,
  saveDownTimelogs,
  getDownTimeRecords,
  editDownTimelogs,
  getShift,
  getSubEquipments,
  submitDowntimeAPI,
  getShiftList,
  getDepartmentDetailsAPI,
  getEquipmentList
} from './apiCalls';
import {
  StyledTab,
  WorkCenterSelect,
  FormLabel,
  StyledTable,
  StyledDowntime,
  lightTheme
} from './styles';
import './style.css';
import { getUserProfile } from '../../selectors/layout';

const { Option } = WorkCenterSelect;
const { TabPane } = Tabs;
const { TextArea } = Input;

const newLocal = true;
class DownTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentDidMount = async () => {
    try {
      let processData = await getProcessArea();
      let areaTypeData = await getAreaType();
      let downTimeRecords = await getDownTimeRecords('All');
      let autoDownTimeRecords = await getDownTimeRecords('Automation');
      let manualRecords = await getDownTimeRecords('Manual');
      let shiftList = await getShiftList();
      let equipmentList = await getEquipmentList();
      this.setState({
        processData: processData ? processData : [],
        areaType: areaTypeData,
        equipmentList,
        downTimeRecords: localTimeConverter(downTimeRecords),
        autoDownTimeRecords: localTimeConverter(autoDownTimeRecords),
        manualRecords: localTimeConverter(manualRecords),
        shiftList: shiftList
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  _getProcessOptionList = () => {
    let options = [];
    options = JSON.parse(localStorage.get('modules'));
    return options && options.hierarchy ? options.hierarchy : [];
  };

  disabledStartDate = (current) => {
    return current > moment().endOf('day');
  };

  disabledEndDate = (current) => {
    const { totalFromDateTime } = this.state;
    return (
      current > moment(totalFromDateTime).add(1, 'day').endOf('day') ||
      current < moment(totalFromDateTime).startOf('day')
    );
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onStartChange = (value) => {
    let { totalToDateTime } = this.state;
    this.onChange('totalFromDateTime', value);
    if (totalToDateTime !== null || totalToDateTime !== undefined || totalToDateTime !== '') {
      let valueString = moment(value).format('DD/MM/YYYY HH:mm');
      let toDateString = moment(totalToDateTime).format('DD/MM/YYYY HH:mm');

      if (
        toDateString !== 'Invalid Date' ||
        toDateString === 'Invalid date' ||
        toDateString > valueString
      ) {
        let ms = moment(toDateString, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(valueString, 'DD/MM/YYYY HH:mm:ss')
        );
        let d = moment.duration(ms);
        let s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

        // let string = moment.utc( moment(toDateString, "DD/MM/YYYY HH:mm").diff(moment(valueString, "DD/MM/YYYY HH:mm"))).format("HH:mm");
        if (toDateString !== 'Invalid date' || s !== 'NaNInvalid date') {
          this.setState({ totalTime: s, addArea: true });
        }
      }
    }
  };

  onEndChange = (value) => {
    const { totalFromDateTime } = this.state;
    let valueString = moment(value).format('DD/MM/YYYY HH:mm');

    let formDateString = moment(totalFromDateTime).format('DD/MM/YYYY HH:mm');
    if (valueString > formDateString) {
      // let string = moment.utc(moment(valueString, "DD/MM/YYYY HH:mm").diff(moment(formDateString, "DD/MM/YYYY HH:mm"))).format("hh:mm");
      let ms = moment(valueString, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(formDateString, 'DD/MM/YYYY HH:mm:ss')
      );
      let d = moment.duration(ms);
      let s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
      this.setState({ totalToDateTime: value, totalTime: s, addArea: true });
    } else {
      message.warn('ToDateTime should be greater than FromDateTime');
    }
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  addSplitArea = () => {
    let splitData = [...this.state.splitDowntimeLog];
    let prevArea = splitData[splitData.length - 1];
    if (splitData.length > 0) {
      if (
        prevArea.FailureArea === '' ||
        prevArea.formDate === '' ||
        prevArea.toDate === '' ||
        prevArea.FailureReasons === ''
      ) {
        this.setState({ checkValidations: true });
        return;
      } else {
        this.setState({ checkValidations: false });
      }
    }

    //if (this.state.totalTime !== totalHoursCalculationLogic(splitData)) {
    let newObject = {};
    newObject[`FailureArea`] = '';
    newObject[`StartTime`] =
      this.state.totalFromDateTime !== null ? this.state.totalFromDateTime : null;
    newObject['EndTime'] = null;
    newObject[`endOpen`] = false;
    newObject[`FailureReasons`] = '';
    newObject[`greyOut`] = false;
    newObject[`TotalTime`] = '';
    newObject[`Remarks`] = '';
    splitData.push(newObject);
    this.setState({ splitDowntimeLog: splitData });
    // } else {
    //   message.warn(constants.warnings.totalTimeValidation);
    // }
  };

  onSplitStartChange = (value, index) => {
    const splitData = [...this.state.splitDowntimeLog];
    let { totalFromDateTime, totalToDateTime } = this.state;

    let totalFromDate = moment(totalFromDateTime).format('DD/MM/YYYY HH:mm');
    let totalToDate = moment(totalToDateTime).format('DD/MM/YYYY HH:mm');
    let valueString = moment(value).format('DD/MM/YYYY HH:mm');

    if (valueString >= totalFromDate && valueString <= totalToDate) {
      splitData[index]['StartTime'] = value;
      if (
        (splitData && splitData[index]['EndTime'] && splitData[index]['EndTime'] !== null) ||
        splitData[index]['EndTime'] !== undefined
      ) {
        let valueString = moment(value).format('DD/MM/YYYY HH:mm:ss');
        let toDateString = moment(splitData[index]['EndTime']).format('DD/MM/YYYY HH:mm:ss');
        // let time = moment
        // .utc
        // (
        //   moment(toDateString, "DD/MM/YYYY HH:mm").diff(
        //     moment(valueString, "DD/MM/YYYY HH:mm")
        //   )
        // )
        // .format("HH:mm");
        let ms = moment(toDateString, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(valueString, 'DD/MM/YYYY HH:mm:ss')
        );
        let d = moment.duration(ms);
        let time = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
        // }
        splitData[index]['TotalTime'] = time;
        this.setState({ splitDowntimeLog: splitData });
      }

      this.setState({ splitDowntimeLog: splitData });
    } else {
      message.warn(`Please Select the range between ${totalFromDate} to ${totalToDate}`);
    }
    // splitData[index]["StartTime"] = value;
    // this.setState({ splitDowntimeLog: splitData });
  };

  addAreaRemark = (i) => {
    const { userProfile } = this.props;
    let splitRecords = [...this.state.splitDowntimeLog];
    const username = userProfile && userProfile.user ? userProfile.user.Id : '';

    if (splitRecords[i] && splitRecords[i].Remarks) {
      splitRecords[i].Remarks.push({
        Remarks: '',
        Date: moment().format('YYYY-MM-DD HH:mm'),
        User: username
      });
    } else {
      splitRecords[i].Remarks = [
        {
          Remarks: '',
          Date: moment().format('YYYY-MM-DD HH:mm'),
          User: username
        }
      ];
    }
    this.setState({ splitDowntimeLog: splitRecords });
  };

  onSplitEndChange = (value, index) => {
    const splitData = [...this.state.splitDowntimeLog];
    let { totalFromDateTime, totalToDateTime } = this.state;

    let totalFromDate = moment(totalFromDateTime).format('DD/MM/YYYY HH:mm');
    let totalToDate = moment(totalToDateTime).format('DD/MM/YYYY HH:mm');
    let valueString = moment(value).format('DD/MM/YYYY HH:mm');
    let formDateString = moment(splitData[index]['StartTime']).format('DD/MM/YYYY HH:mm');
    if (valueString > formDateString && valueString > totalFromDate && valueString <= totalToDate) {
      splitData[index]['EndTime'] = value;
      let ms = moment(valueString, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(formDateString, 'DD/MM/YYYY HH:mm:ss')
      );
      let d = moment.duration(ms);
      let time = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

      splitData[index]['TotalTime'] = time;
      this.setState({ splitDowntimeLog: splitData });
    } else {
      splitData[index]['ToTime'] = null;
      this.setState({ splitDowntimeLog: splitData });
      message.warn('ToDateTime should be greater than FromDateTime');
    }
  };

  handleSplitStartOpenChange = (status, index) => {
    const splitData = [...this.state.splitDowntimeLog];
    if (!status) {
      splitData[index]['endOpen'] = true;
      this.setState({ splitDowntimeLog: splitData });
    }
  };

  handleSplitEndOpenChange = (open, index) => {
    const splitData = [...this.state.splitDowntimeLog];
    splitData[index]['endOpen'] = open;
    this.setState({ splitDowntimeLog: splitData });
  };

  disabledSplitStartDate = (startValue, index) => {
    const { totalFromDateTime, totalToDateTime } = this.state;
    let startDate = moment(startValue).format('YYYY-MM-DD');
    let fromDate = moment(totalFromDateTime).format('YYYY-MM-DD');
    let toDate = moment(totalToDateTime).format('YYYY-MM-DD');
    if (newLocal) {
      return moment(startDate).isBefore(fromDate) || moment(startDate).isAfter(toDate);
    } else {
      const { splitDowntimeLog } = this.state;
      let validateDateTime = splitDowntimeLog[index - 1].toDate;
      let valDate = moment(validateDateTime).format('YYYY-MM-DD');

      return moment(startDate).isBefore(valDate) || moment(startDate).isAfter(toDate);
    }
  };

  disabledSplitEndDate = (endValue, index) => {
    const { totalToDateTime } = this.state;
    let endDate = moment(endValue).format('YYYY-MM-DD');
    let toDate = moment(totalToDateTime).format('YYYY-MM-DD');

    const { splitDowntimeLog } = this.state;
    let validateDateTime = splitDowntimeLog[index].StartTime;
    let valDate = moment(validateDateTime).format('YYYY-MM-DD');
    return moment(endDate).isBefore(valDate) || moment(endDate).isAfter(toDate);
  };

  openModel = async () => {
    this.setState({ openModel: !this.state.openModel });
  };

  handleModelSave = async () => {
    const {
      editLog,
      editAutoLog,
      modifyRecord,
      ProcessArea,
      WorkCentre,
      EquipmentCode,
      TypeOfDowntime,
      splitDowntimeLog,
      DeletedFailureDetails,
      splitView,
      totalFromDateTime,
      totalToDateTime,
      Source,
      UpdateSource,
      totalTime,
      updateId,
      remarks,
      SubEquipmentCode,
      Shift
    } = this.state;
    splitDowntimeLog.map((area) => {
      let splitStart = moment(area.StartTime).format('YYYY-MM-DD HH:mm');
      let splitEnd = moment(area.EndTime).format('YYYY-MM-DD HH:mm');
      let startMainTime = moment(totalFromDateTime).format('YYYY-MM-DD HH:mm');
      let endMainTime = moment(totalToDateTime).format('YYYY-MM-DD HH:mm');
      if (splitStart === startMainTime) {
        this.setState({ splitStartRecord: true });
      }
      if (splitEnd === endMainTime) {
        this.setState({ splitEndRecord: true });
      }
      return {};
    });
    if (editLog || modifyRecord || editAutoLog) {
      let loopValidation = false;
      splitDowntimeLog.forEach((area) => {
        if (
          area.FailureArea === '' ||
          area.FailureReasons === '' ||
          area.StartTime === '' ||
          area.StartTime === null ||
          area.EndTime === '' ||
          area.TotalTime === ''
        ) {
          message.warn('Please select all fields');
          loopValidation = true;
          this.setState({ checkValidations: true });
        }
      });

      splitDowntimeLog.forEach((area) => {
        if (area.EndTime !== null) {
          area.EndTime = moment(area.EndTime).utc();
        } else if (area.EndTime === null || area.EndTime === '' || area.EndTime === undefined) {
          area.EndTime = null;
        }
        area.StartTime = moment(area.StartTime).utc();
      });

      if (loopValidation) {
        return;
      }

      if (
        ProcessArea === '' ||
        WorkCentre === '' ||
        EquipmentCode === '' ||
        // TypeOfDowntime === '' ||
        // TypeOfDowntime === null ||
        totalTime === '' ||
        totalTime === null ||
        totalToDateTime === null
      ) {
        this.setState({ checkValidations: true, addArea: true });
      } else {
        let obj = {
          ProcessArea: ProcessArea,
          WorkCentre: WorkCentre,
          EquipmentCode: EquipmentCode,
          Type: TypeOfDowntime,
          Source: Source,
          UpdateSource: UpdateSource,
          FailureDetails: [...splitDowntimeLog],
          splitView: splitView,
          StartTime: totalFromDateTime,
          EndTime: totalToDateTime,
          DeletedFailureDetails: DeletedFailureDetails,
          TotalTime: totalTime,
          Id: updateId,
          Remarks: remarks,
          SubEquipmentCode
        };

        if (splitDowntimeLog.length !== 0) {
          await editDownTimelogs(obj);
          let downTimeRecords = await getDownTimeRecords('All');
          let autoDownTimeRecords = await getDownTimeRecords('Automation');
          let manualRecords = await getDownTimeRecords('Manual');
          let stateInit = cloneDeep(InitialState);
          stateInit.downTimeRecords = localTimeConverter(downTimeRecords);
          stateInit.autoDownTimeRecords = localTimeConverter(autoDownTimeRecords);
          stateInit.manualRecords = localTimeConverter(manualRecords);
          this.setState({ ...stateInit });
        } else {
          message.warn('Please add Atleast one area');
        }
      }
    } else {
      let loopValidation = false;
      splitDowntimeLog.forEach((area) => {
        if (
          area.FailureArea === '' ||
          area.FailureReasons === '' ||
          area.StartTime === '' ||
          area.EndTime === '' ||
          area.TotalTime === ''
        ) {
          loopValidation = true;
          this.setState({ checkValidations: true });
        }
      });

      if (loopValidation) {
        return;
      }
      if (
        ProcessArea === '' ||
        WorkCentre === '' ||
        EquipmentCode === '' ||
        TypeOfDowntime === '' ||
        TypeOfDowntime === null ||
        totalTime === '' ||
        totalTime === null ||
        totalFromDateTime === null ||
        totalToDateTime === null
      ) {
        this.setState({ checkValidations: true });
        message.warn('Please Enter All Records With Atleast One Area');
      } else {
        let obj = {
          ProcessArea,
          WorkCentre: WorkCentre,
          EquipmentCode: EquipmentCode,
          TypeOfDowntime: TypeOfDowntime,

          FailureDetails: splitDowntimeLog,

          StartTime: totalFromDateTime,
          EndTime: totalToDateTime,
          TotalTime: totalTime,
          Status: 'Open',

          Source: 'Manual',
          remarks,

          SubEquipmentCode,
          Shift,
          RecordStatus: 'Saved'
        };

        if (splitDowntimeLog.length !== 0) {
          try {
            let saveReponse = await saveDownTimelogs(obj);
            if (saveReponse) {
              let downTimeRecords = await getDownTimeRecords('All');
              let autoDownTimeRecords = await getDownTimeRecords('Automation');
              let manualRecords = await getDownTimeRecords('Manual');
              let stateInit = cloneDeep(InitialState);
              stateInit.downTimeRecords = localTimeConverter(downTimeRecords);
              stateInit.autoDownTimeRecords = localTimeConverter(autoDownTimeRecords);
              stateInit.manualRecords = localTimeConverter(manualRecords);
              //stateInit.downTimeRecords =downTimeRecords;
              this.setState({ ...stateInit });
            }
          } catch (error) {
            console.log(error);
            message.warn(`${error.response.message}`);
          }
        } else {
          message.warn('Please Add Atleast One Area');
        }
      }
    }
  };

  handleOnChange = async (e, type) => {
    let { processData } = this.state;
    if (type === 'ProcessArea') {
      let processID = processData.filter((process) => {
        return process.Code === e;
      });
      let data = await getWorkCenter(processID[0].Id);
      this.setState({
        [type]: e,
        workCenterlist: data,
        WorkCentre: '',
        EquipmentCode: ''
      });
    } else if (type === 'WorkCentre') {
      const { workCenterlist } = this.state;

      const WorkCentre = workCenterlist.filter((device) => {
        return device.Code === e;
      });
      let data = await getEquipment(WorkCentre[0].Id);

      if (WorkCentre[0].subEquipment) {
        let subEquipmentdata = await getSubEquipments(WorkCentre[0].Id);
        this.setState({
          [type]: e,
          equipmentData: data,
          hasSubEquipment: true,
          subEquipmentList: subEquipmentdata,
          EquipmentCode: ''
        });
      } else {
        this.setState({
          [type]: e,
          equipmentData: data,
          hasSubEquipment: false,
          EquipmentCode: ''
        });
      }
    } else if (type === 'totalDownTimearea') {
      let descData = await getDescription(e);
      this.setState({
        [type]: e,
        totalDownDescriptionData: descData,
        totalDownDescription: ''
      });
    } else if (type === 'shift') {
      const { shiftData } = this.state;
      let index = shiftData.findIndex((data) => {
        return data.ShiftName === e;
      });
      this.setState({
        [type]: e,
        selectedShift: index !== -1 ? shiftData[index] : ''
      });
    } else if (type === 'EquipmentCode') {
      let EquipmentPayload = {
        Type: 'FailureArea',
        Module: 'DownTime',
        EquipmentCode: e
      };
      // let areaTypeData = await getAreaType(EquipmentPayload);
      let getDepartmentDetails = await getDepartmentDetailsAPI(EquipmentPayload);
      this.setState({
        [type]: e,
        // areaType: areaTypeData,
        EquipmentData: e,
        splitDowntimeLog: [],
        failureAreaList:
          getDepartmentDetails && Object.keys(getDepartmentDetails)
            ? Object.keys(getDepartmentDetails)
            : [],
        failureReasonList: getDepartmentDetails
      });
    } else {
      this.setState({ [type]: e });
    }
  };

  handleSplitOnChange = async (val, type, index) => {
    const splitData = [...this.state.splitDowntimeLog];
    if (type === 'FailureArea') {
      splitData[index]['FailureArea'] = val;
      let descData = await getDescription(val);
      splitData[index]['descriptionData'] = descData;
      splitData[index]['FailureReasons'] = '';
      this.setState({ splitDowntimeLog: splitData });
    } else if (type === 'FailureReasons') {
      let findDuplicate = splitData.find((item) => {
        if (item.FailureReasons === val) {
          return true;
        } else {
          return false;
        }
      });
      if (!findDuplicate) {
        splitData[index]['FailureReasons'] = val;
        this.setState({ splitDowntimeLog: splitData });
      } else if (findDuplicate) {
        message.warn('Duplicate Failure Descriptions not allowed');
        splitData[index]['FailureReasons'] = '';
        this.setState({ splitDowntimeLog: splitData });
      }
    } else {
      splitData[index][type] = val;
      this.setState({ splitDowntimeLog: splitData });
    }
  };

  getDataForEdit = async () => {
    try {
      let processData = await getProcessArea();
      let areaTypeData = await getAreaType();
      let downTimeRecords = await getDownTimeRecords('All');
      let shiftData = await getShift();
      // let rootCausetData = await getRootCauses();
      this._getProcessOptionList();
      this.setState({
        processData: processData,
        areaType: areaTypeData,
        downTimeRecords: localTimeConverter(downTimeRecords),
        //downTimeRecords: downTimeRecords,
        shiftData: shiftData
        //  rootCausetData: rootCausetData,
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  handlelogEdit = async (logItem, type) => {
    if (type === 'auto') {
      this.setState({
        ProcessArea: logItem.ProcessArea,
        WorkCentre: logItem.WorkCentre,
        EquipmentName: logItem.EquipmentName,
        EquipmentCode: logItem.EquipmentCode,
        TypeOfDowntime: logItem.TypeOfDowntime !== null ? logItem.TypeOfDowntime : '',
        shift: logItem.Shift,
        //splitDowntimeLog: logItem.FailureDetails,
        greyOutRecords: [],
        editAutoLog: true,
        openModel: true,
        totalTime: logItem.TotalTime,
        totalFromDateTime: logItem.StartTime !== null ? moment(logItem.StartTime) : null,
        totalToDateTime: logItem.EndTime !== null ? moment(logItem.EndTime) : null,
        Source: logItem.Source,
        UpdateSource: 'Manual',
        updateId: logItem.Id,
        splitView: logItem.FailureDetails.length > 0 ? true : false,
        remarks: logItem.Remarks,
        workOrderId: logItem.WorkOrderId,
        notificationId: logItem.NotificationId,
        rootCause: logItem.Reason
      });
    } else {
      let descData =
        type !== 'auto'
          ? await getDescription(
              logItem &&
                logItem.FailureDetails &&
                logItem.FailureDetails[0] !== undefined &&
                logItem.FailureDetails[0].FailureArea
            )
          : [];
      this.setState({
        ProcessArea: logItem.ProcessArea,
        WorkCentre: logItem.WorkCentre,
        EquipmentCode: logItem.EquipmentCode,
        EquipmentName: logItem.EquipmentName,
        TypeOfDowntime: logItem.TypeOfDowntime,
        splitDowntimeLog: logItem.FailureDetails,
        greyOutRecords: [],
        editLog: true,
        openModel: true,
        totalTime: logItem.TotalTime,
        Source: 'Manual',
        UpdateSource: 'Manual',
        totalFromDateTime: logItem.StartTime !== null ? moment(logItem.StartTime) : null,
        totalToDateTime: logItem.EndTime !== null ? moment(logItem.EndTime) : null,
        totalDownDescriptionData: descData,
        updateId: logItem.Id,
        splitView: logItem.FailureDetails.length > 0 ? true : false,
        remarks: logItem.Remarks,
        workOrderId: logItem.WorkOrderId,
        notificationId: logItem.NotificationId,
        rootCause: logItem.Reason
      });
    }
  };

  handleCancel = async () => {
    let downTimeRecords = await getDownTimeRecords('All');
    let autoDownTimeRecords = await getDownTimeRecords('Automation');
    let manualRecords = await getDownTimeRecords('Manual');
    this.setState({
      downTimeRecords: localTimeConverter(downTimeRecords),
      autoDownTimeRecords: localTimeConverter(autoDownTimeRecords),
      manualRecords: localTimeConverter(manualRecords)
      // downTimeRecords:downTimeRecords,
    });
    let stateInit = cloneDeep(InitialState);
    this.setState({ ...stateInit, splitDowntimeLog: [] });
  };

  addSplitAreaView = () => {
    let splitData = [...this.state.splitDowntimeLog];
    let newObject = {};
    newObject[`area`] = '';
    newObject[`fromDate`] = newObject[`toDate`] = '';
    newObject[`endOpen`] = false;
    newObject[`description`] = '';
    newObject['descriptionData'] = [];

    this.setState({
      splitView: true,
      splitDowntimeLog: splitData
    });
  };

  disabledStartTime = (val) => {
    const { shift, shiftData, selectedShift } = this.state;

    let index =
      shiftData &&
      shiftData.findIndex((data) => {
        return data.ShiftName === shift;
      });
    if (
      selectedShift !== '' &&
      selectedShift &&
      selectedShift.FromTime &&
      selectedShift.FromTime !== undefined &&
      selectedShift.ToTime !== undefined
    ) {
      let startHours = selectedShift.FromTime.split(':')[0];
      let endHours = shiftData[index].ToTime.split(':')[0];
      let beforeHours = this.range(0, startHours);
      let afterHours = this.range(endHours, 24);
      let valHours = moment(val).hours();

      if (shift === 'Night') {
        afterHours = [];
        beforeHours.splice(beforeHours.indexOf(0), Number(endHours) + 1);
      }

      if (valHours === Number(endHours)) {
        return {
          disabledHours: () => [...beforeHours, ...afterHours],
          disabledMinutes: () => this.range(1, 59),
          disabledSeconds: () => this.range(1, 59)
        };
      }
      return {
        disabledHours: () => [...beforeHours, ...afterHours],
        disabledMinutes: () => this.range(0, 0)
      };
    } else {
      return true;
    }
  };

  disabledEndTime = (val) => {
    const { shift, selectedShift } = this.state;

    if (
      selectedShift !== '' &&
      selectedShift &&
      selectedShift.FromTime &&
      selectedShift.FromTime !== undefined &&
      selectedShift.ToTime !== undefined
    ) {
      const { totalFromDateTime } = this.state;
      let hours = moment(totalFromDateTime).hours();
      let mins = moment(totalFromDateTime).minute();
      let secounds = moment(totalFromDateTime).second();
      let fromDate = moment(totalFromDateTime).format('YYYY-MM-DD');
      let toDate = moment(val).format('YYYY-MM-DD');
      let valHours = moment(val).hours();
      let endHours = selectedShift.ToTime.split(':')[0];
      let startHours = selectedShift.FromTime.split(':')[0];
      let beforeHours = this.range(0, hours);
      let afterHours = this.range(endHours, 24);

      if (shift === 'Night') {
        if (moment(fromDate).isSame(toDate)) {
          if (hours >= startHours) {
            return {
              disabledHours: () => [...beforeHours],
              disabledMinutes: () => this.range(0, mins),
              disabledSeconds: () => this.range(0, secounds)
            };
          } else if (hours <= endHours) {
            if (valHours === Number(endHours)) {
              return {
                disabledHours: () => [...beforeHours, ...afterHours],
                disabledMinutes: () => this.range(selectedShift.ToTime.split(':')[1], 60),
                disabledSeconds: () => this.range(1, 60)
              };
            } else {
              return {
                disabledHours: () => [...beforeHours, ...afterHours],
                disabledMinutes: () => this.range(0, mins),
                disabledSeconds: () => this.range(0, secounds)
              };
            }
          }
        } else {
          if (valHours === Number(endHours)) {
            return {
              disabledHours: () => [...afterHours],
              disabledMinutes: () => this.range(selectedShift.ToTime.split(':')[1], 60),
              disabledSeconds: () => this.range(1, 60)
            };
          }
          return {
            disabledHours: () => [...afterHours],
            disabledMinutes: () => this.range(0, mins),
            disabledSeconds: () => this.range(0, secounds)
          };
        }
      }

      if (moment(fromDate).isSame(toDate)) {
        if (valHours === hours) {
          return {
            disabledHours: () => [...beforeHours, ...afterHours],
            disabledMinutes: () => this.range(0, mins),
            disabledSeconds: () => this.range(0, 0)
          };
        }

        if (valHours === Number(endHours)) {
          return {
            disabledHours: () => [...beforeHours, ...afterHours],
            disabledMinutes: () => this.range(1, 60),
            disabledSeconds: () => this.range(1, 60)
          };
        }
        return {
          disabledHours: () => [...beforeHours, ...afterHours],
          disabledMinutes: () => this.range(0, 0),
          disabledSeconds: () => this.range(0, secounds)
        };
      }
    } else {
      return true;
    }
  };

  disabledsplitStartTime = (val, i) => {
    let valHours = moment(val).hours();
    let valminute = moment(val).minute();
    if (newLocal) {
      const { totalFromDateTime, totalToDateTime } = this.state;
      let hours = moment(totalToDateTime).hours();
      let toHours = moment(totalFromDateTime).hours();
      let totalHours = this.range(0, 24).splice(hours + 1, 24);
      let fromstartHours = this.range(0, 24).splice(0, toHours);
      let mins = moment(totalFromDateTime).minute();
      let secounds = moment(totalFromDateTime).second();
      if (valHours !== hours) {
        return {
          disabledHours: () => [...fromstartHours, ...totalHours],

          disabledMinutes: () => this.range(0, 0),
          disabledSeconds: () => this.range(0, 0)
        };
      }
      if (valminute !== mins) {
        return {
          disabledHours: () => [...fromstartHours, ...totalHours],
          disabledMinutes: () => this.range(0, mins),
          disabledSeconds: () => this.range(0, 0)
        };
      }
      return {
        disabledHours: () => [...fromstartHours, ...totalHours],
        disabledMinutes: () => this.range(0, mins),
        disabledSeconds: () => this.range(0, secounds)
      };
    } else {
      const toDateTime = this.state.splitDowntimeLog[i - 1].toDate;
      let hours = moment(toDateTime).hours();
      let mins = moment(toDateTime).minute();
      let secounds = moment(toDateTime).second();
      let toDate = moment(toDateTime).format('YYYY-MM-DD');
      let fromDate = moment(val).format('YYYY-MM-DD');

      if (moment(fromDate).isSame(toDate)) {
        if (valHours !== hours) {
          return {
            disabledHours: () => this.range(0, 24).splice(0, hours),
            disabledMinutes: () => this.range(0, 0),
            disabledSeconds: () => this.range(0, 0)
          };
        }
        if (valminute !== mins) {
          return {
            disabledHours: () => this.range(0, 24).splice(0, hours),
            disabledMinutes: () => this.range(0, mins),
            disabledSeconds: () => this.range(0, 0)
          };
        }
        return {
          disabledHours: () => this.range(0, 24).splice(0, hours),
          disabledMinutes: () => this.range(0, mins),
          disabledSeconds: () => this.range(0, secounds)
        };
      }
    }
  };

  disabledsplitEndTime = (val) => {
    // const fromTime = this.state.splitDowntimeLog[i].FromTime;

    let valHours = moment(val).hours();
    let valminute = moment(val).minute();
    // let toDate = moment(val).format("YYYY-MM-DD");
    // let fromDate = moment(fromTime).format("YYYY-MM-DD");
    // let totalToDate = moment(totalToDateTime).format("YYYY-MM-DD");

    if (newLocal) {
      const { totalFromDateTime, totalToDateTime } = this.state;
      let hours = moment(totalToDateTime).hours();
      let toHours = moment(totalFromDateTime).hours();
      let totalHours = this.range(0, 24).splice(hours + 1, 24);
      let fromstartHours = this.range(0, 24).splice(0, toHours);
      let mins = moment(totalFromDateTime).minute();
      let secounds = moment(totalFromDateTime).second();
      if (valHours !== hours) {
        return {
          disabledHours: () => [...fromstartHours, ...totalHours]

          //  disabledMinutes: () => this.range(0, 0),
          //disabledSeconds: () => this.range(0, 0),
        };
      }
      if (valminute !== mins) {
        return {
          disabledHours: () => [...fromstartHours, ...totalHours],
          disabledMinutes: () => this.range(0, mins),
          disabledSeconds: () => this.range(0, 0)
        };
      }
      return {
        disabledHours: () => [...fromstartHours, ...totalHours],
        disabledMinutes: () => this.range(0, mins),
        disabledSeconds: () => this.range(0, secounds)
      };
    }
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  handleAreaDelete = (index) => {
    let splitRecords = [...this.state.splitDowntimeLog];
    let DeletedFailureDetails = [];
    let deleteID = splitRecords.filter((process, indexEDit) => {
      return indexEDit === index;
    });
    let data = deleteID[0].Id;
    DeletedFailureDetails.push(data);

    splitRecords.splice(index, 1);
    this.setState({ splitDowntimeLog: splitRecords, DeletedFailureDetails });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : text}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  submitDowntime = async (Id) => {
    const SiteId = localStorage.get('currentSite');
    let payload = {
      DownTimeIds: [Id],
      RecordStatus: 'Submitted',
      SiteId
    };
    let submittedResponse = await submitDowntimeAPI(payload);
    if (submittedResponse && submittedResponse.message) {
      message.success(submittedResponse.message);
      let autoDownTimeRecords = await getDownTimeRecords('Automation');
      let manualRecords = await getDownTimeRecords('Manual');
      this.setState({
        autoDownTimeRecords: localTimeConverter(autoDownTimeRecords),
        manualRecords: localTimeConverter(manualRecords)
      });
    }
  };
  showRemarks = (logItem) => {
    let ChildRemarks = [];
    logItem.FailureDetails &&
      Array.isArray(logItem.FailureDetails) &&
      logItem.FailureDetails.map((failure) => {
        ChildRemarks = [
          ...ChildRemarks,
          ...(failure && failure.Remarks && Array.isArray(failure.Remarks) ? failure.Remarks : [])
        ];
        return {};
      });
    this.setState({
      Remarksvisible: true,
      manualRemarks: [...logItem.Remarks, ...ChildRemarks]
    });
  };
  handleMainRemark = (e, i) => {
    let { remarks } = this.state;
    remarks[i]['Remarks'] = e;
    this.setState({
      remarks
    });
  };

  handlechangeRemark = (e, k, index, i) => {
    const { splitDowntimeLog } = this.state;
    splitDowntimeLog[i]['Remarks'][index]['Remarks'] = e;
    this.setState({
      splitDowntimeLog
    });
  };
  add = () => {
    let { remarks } = this.state;
    let { userProfile } = this.props;
    let userId = userProfile && userProfile.user ? userProfile.user.Id : '';

    if (remarks && Array.isArray(remarks)) {
      remarks.push({
        Remarks: '',
        Date: moment().format('YYYY-MM-DD HH:mm'),
        User: userId
      });
    } else {
      remarks = [
        {
          Remarks: '',
          Date: moment().format('YYYY-MM-DD HH:mm'),
          User: userId
        }
      ];
    }
    this.setState({
      remarks
    });
  };

  render() {
    const {
      splitDowntimeLog,
      processData,
      workCenterlist,
      equipmentData,
      areaType,
      downTimeRecords,
      autoDownTimeRecords,
      manualRecords,
      totalFromDateTime,
      totalToDateTime,
      totalTime,
      splitView,
      greyOutRecords,
      editLog,
      editAutoLog,
      modifyRecord,
      checkValidations,
      addArea,
      ProcessArea,
      WorkCentre,
      EquipmentCode,
      EquipmentName,
      TypeOfDowntime,
      subEquipmentList = [],
      hasSubEquipment,
      SubEquipmentCode,
      Shift,
      manualRemarks,
      parentId = '',
      remarks
    } = this.state;
    const closedColumns = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.Id - b.Id
      },
      {
        title: 'Process Area',
        dataIndex: 'ProcessArea',
        key: 'ProcessArea'
      },
      {
        title: 'Work Centre',
        dataIndex: 'WorkCentre',
        key: 'WorkCentre'
      },
      {
        title: 'Equipment',
        dataIndex: 'EquipmentCode',
        key: 'EquipmentCode',
        ...this.getColumnSearchProps('EquipmentCode')
      },
      {
        title: 'Type',
        dataIndex: 'TypeOfDowntime',
        key: 'TypeOfDowntime'
      },

      {
        title: 'From Time',
        dataIndex: 'StartTime',
        key: 'StartTime',
        defaultSortOrder: 'descend'
      },
      {
        title: 'To Time',
        dataIndex: 'EndTime',
        key: 'EndTime'
      },
      {
        title: 'Total Time',
        dataIndex: 'TotalTime',
        key: 'totime'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => <span>{text === 'Open' ? 'Running' : record.Status}</span>
      },
      {
        title: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={() => {
                this.handlelogEdit(record);
              }}
            />
          </span>
        )
      }
    ];
    let autoColumns = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.Id - b.Id
      },
      {
        title: 'ProcessArea',
        dataIndex: 'ProcessArea',
        key: 'ProcessArea'
      },
      {
        title: 'Work Centre',
        dataIndex: 'WorkCentre',
        key: 'WorkCentre'
      },
      {
        title: 'Equipment',
        dataIndex: 'EquipmentCode',
        key: 'EquipmentCode',
        ...this.getColumnSearchProps('EquipmentCode')
      },
      {
        title: 'Type',
        dataIndex: 'TypeOfDowntime',
        key: 'TypeOfDowntime'
      },

      {
        title: 'From',
        dataIndex: 'StartTime',
        key: 'StartTime'
      },
      {
        title: 'To',
        dataIndex: 'EndTime',
        key: 'EndTime'
      },
      {
        title: 'Total Time',
        dataIndex: 'TotalTime',
        key: 'totime'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => <span>{text === 'Open' ? 'Running' : record.Status}</span>
      },
      {
        title: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={() => {
                this.handlelogEdit(record, 'auto');
              }}
            />
          </span>
        )
      },
      {
        title: 'Action',
        key: '',
        render: (text, record) => {
          return (
            <div>
              {record && record.RecordStatus && record.RecordStatus !== 'Saved' ? (
                <div style={{ color: '#006500' }}>Submitted</div>
              ) : (
                <Button
                  style={{ background: '#1a3652' }}
                  type="primary"
                  onClick={() => {
                    this.submitDowntime(text.Id);
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          );
        }
      }
    ];
    let Remarkscolumns = [
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date'
      },
      {
        title: 'User ',
        dataIndex: 'User',
        key: 'User'
      },
      {
        title: 'Remarks ',
        dataIndex: 'Remarks',
        key: 'Remarks'
      }
    ];
    let manualColumns = [
      {
        title: 'Id',
        dataIndex: 'Id',
        key: 'Id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.Id - b.Id
      },
      {
        title: 'Process Area',
        dataIndex: 'ProcessArea',
        key: 'ProcessArea'
      },
      {
        title: 'Work Centre',
        dataIndex: 'WorkCentre',
        key: 'WorkCentre'
      },
      {
        title: 'Equipment',
        dataIndex: 'EquipmentCode',
        key: 'EquipmentCode',
        ...this.getColumnSearchProps('EquipmentCode')
      },
      {
        title: 'Type',
        dataIndex: 'TypeOfDowntime',
        key: 'TypeOfDowntime'
      },

      {
        title: 'From',
        dataIndex: 'StartTime',
        key: 'StartTime'
      },
      {
        title: 'To',
        dataIndex: 'EndTime',
        key: 'EndTime'
      },
      {
        title: 'Total Time',
        dataIndex: 'TotalTime',
        key: 'totime'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => <span>{text === 'Open' ? 'Running' : record.Status}</span>
      },
      {
        title: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={() => {
                this.handlelogEdit(record);
              }}
            />
          </span>
        )
      },
      {
        title: 'Action',
        key: '',
        render: (text, record) => {
          return (
            <div>
              {record && record.RecordStatus && record.RecordStatus !== 'Saved' ? (
                <div style={{ color: '#006500' }}>Submitted</div>
              ) : (
                <Button
                  style={{ background: '#1a3652' }}
                  type="primary"
                  onClick={() => {
                    this.submitDowntime(text.Id);
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          );
        }
      },
      {
        title: ' ',
        key: '',
        render: (text, record) => {
          return (
            <div>
              {
                <Button
                  style={{ background: '#1a3652' }}
                  type="primary"
                  onClick={() => {
                    this.showRemarks(record);
                  }}
                >
                  Show Remarks
                </Button>
              }
            </div>
          );
        }
      }
    ];
    const expandedRowRender = (record) => {
      let columns = [
        { title: 'Area', dataIndex: 'FailureArea', key: 'FailureArea' },
        { title: 'Failure Reasons', dataIndex: 'FailureReasons', key: 'FailureReasons' },
        { title: 'Start Time', dataIndex: 'StartTime', key: 'StartTime' },
        { title: 'End Time', dataIndex: 'EndTime', key: 'EndTime' },
        { title: 'Total Time', dataIndex: 'TotalTime', key: 'TotalTime' }
      ];
      let data;
      if (record.FailureDetails.length === 0) {
        let obj = {
          FailureArea: record.FailureArea,
          DescriptionText: record.DescriptionText,
          fromDate: record.StartTime,
          toDate: record.EndTime,
          totalTime: record.TotalTime
        };
        data = [obj];
      } else {
        data = record.FailureDetails;
      }
      return (
        <StyledTable theme={lightTheme}>
          <Table
            id="child"
            className="downtimeTable"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </StyledTable>
      );
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    let shiftList = ['Shift A', 'Shift B', 'Shift C'];

    const dynamicAreas = (splitData, i, flage) => {
      const AddAreaFormItems =
        splitData &&
        splitData['Remarks'] &&
        Array.isArray(splitData['Remarks']) &&
        splitData['Remarks'].map((k, indexs) => (
          <Form.Item
            // label={'Remarks'}
            required={false}
            key={indexs}
          >
            <TextArea
              placeholder="Remarks"
              value={k.Remarks !== '' ? k.Remarks : null}
              allowClear
              style={{ width: '50%' }}
              onChange={(e) => {
                this.handlechangeRemark(e.target.value, k, indexs, i);
              }}
              rows={2}
            />
          </Form.Item>
        ));

      return (
        <Fragment>
          <div style={{}}>
            <Row
              gutter={24}
              style={{
                marginTop: '2%',
                marginBottom: '2%'
              }}
            >
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>Area</FormLabel>}
                  hasFeedback={splitData.FailureArea !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? splitData.FailureArea !== ''
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <Select
                    placeholder="Select Area"
                    // disabled={
                    //   editLog ? (splitData.greyOut ? true : false) : false
                    // }
                    style={{ width: 250 }}
                    value={splitData.FailureArea === '' ? undefined : splitData.FailureArea}
                    onChange={(e) => {
                      this.handleSplitOnChange(e, 'FailureArea', i);
                    }}
                  >
                    {areaType &&
                      Array.isArray(areaType) &&
                      areaType.map((areaID, index) => {
                        return (
                          <Option
                            key={index}
                            value={areaID.FailureArea}
                            // disabled={this.state.selectedAreas.includes(areaID)}
                          >
                            {areaType[index].FailureArea}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<FormLabel>Downtime Failure Reason</FormLabel>}
                  hasFeedback={splitData.FailureReasons !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? splitData.FailureReasons !== ''
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <Select
                    placeholder="Select Downtime Failure Reason"
                    value={splitData.FailureReasons === '' ? undefined : splitData.FailureReasons}
                    style={{ width: 550 }}
                    onChange={(e) => {
                      this.handleSplitOnChange(e, 'FailureReasons', i);
                    }}
                    // disabled={
                    //   editLog ? (splitData.greyOut ? true : false) : false
                    // }
                  >
                    {splitData.descriptionData
                      ? splitData.descriptionData.map((area, index) => {
                          return (
                            <Option key={index} value={area.FailureReasons}>
                              {area.FailureReasons}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>From DateTime</FormLabel>}
                  hasFeedback={splitData.FromTime !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? splitData.FromTime !== null
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <DatePicker
                    disabledDate={(val) => this.disabledSplitStartDate(val, i)}
                    // disabledTime={(val) => this.disabledsplitStartTime(val, i)}
                    //showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    // value={totalFromDateTime}
                    value={splitData.StartTime !== null ? moment(splitData.StartTime) : null}
                    placeholder="Start DateTime"
                    onChange={(val) => this.onSplitStartChange(val, i)}
                    onOpenChange={(status) => this.handleSplitStartOpenChange(status, i)}
                    style={{ width: 250 }}
                    // disabled={
                    //   modifyRecord || editLog
                    //     ? splitData.greyOut
                    //       ? true
                    //       : true
                    //     : true
                    // }
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm')
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>To DateTime</FormLabel>}
                  hasFeedback={splitData.EndTime !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? splitData.EndTime !== null
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <DatePicker
                    disabledDate={(val) => this.disabledSplitEndDate(val, i)}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={splitData.EndTime !== null ? moment(splitData.EndTime) : null}
                    style={{ width: 250 }}
                    placeholder="End DateTime"
                    onChange={(val) => this.onSplitEndChange(val, i)}
                    open={splitData.endOpen}
                    onOpenChange={(status) => this.handleSplitEndOpenChange(status, i)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>Time</FormLabel>}
                  hasFeedback={splitData.TotalTime !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? splitData.TotalTime !== ''
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <Input
                    style={{ width: 250 }}
                    placeholder="Total Time"
                    value={splitData.TotalTime !== undefined ? splitData.TotalTime : undefined}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col
                style={{
                  display: modifyRecord ? 'none' : null
                }}
                span={6}
              >
                {i === greyOutRecords.length - 1 ? (
                  splitDowntimeLog.length === 0 ? (
                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        type="danger"
                        // disabled={checkValidations===true?false:true}
                        style={{ fontWeight: 700 }}
                        onClick={() => this.addSplitArea()}
                      >
                        Add Area
                      </Button>
                    </Form.Item>
                  ) : null
                ) : null}
                {flage === 'splitLog' ? (
                  i === splitDowntimeLog.length - 1 ? (
                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        type="danger"
                        //  disabled={checkValidations===true?false:true}
                        style={{ fontWeight: 700 }}
                        onClick={() => this.addSplitArea()}
                      >
                        Add Area
                      </Button>
                    </Form.Item>
                  ) : null
                ) : null}
              </Col>
            </Row>
            <Row
              style={{
                borderBottom: i >= 0 ? '1px solid' : 'none'
              }}
              gutter={24}
            >
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => this.addAreaRemark(i, parentId)}
                    style={{ marginTop: '10px' }}
                  >
                    <PlusOutlined /> Add Remarks
                  </Button>
                  <div style={{ marginTop: '10px' }}> {AddAreaFormItems}</div>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Fragment>
      );
    };

    return (
      <StyledDowntime>
        <StyledTab type="card" className="downtimeTabs">
          <TabPane tab="Closed Records" key="1">
            <StyledTable theme={lightTheme}>
              <Table
                className="downtimeTable"
                columns={closedColumns}
                expandedRowRender={expandedRowRender}
                dataSource={downTimeRecords}
              />
            </StyledTable>
          </TabPane>
          <TabPane tab="Manual Records" key="2">
            <div style={{ marginLeft: '90%', marginBottom: '10px' }}>
              <Button
                onClick={() => {
                  this.openModel();
                }}
                size="default"
                type="danger"
                style={{ fontWeight: 700 }}
              >
                Add Down Time
              </Button>
            </div>
            <StyledTable theme={lightTheme}>
              <Table className="downtimeTable" columns={manualColumns} dataSource={manualRecords} />
            </StyledTable>
          </TabPane>
          <TabPane tab="Automated Records" key="3">
            <StyledTable theme={lightTheme}>
              <Table
                className="downtimeTable"
                columns={autoColumns}
                dataSource={autoDownTimeRecords}
              />
            </StyledTable>
          </TabPane>
        </StyledTab>

        <Drawer
          title={<div>Add Downtime</div>}
          placement="right"
          closable
          className="downtimedrawer"
          open={this.state.openModel}
          footer={[
            <Button key="back" style={{ marginLeft: '8px' }} onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{ fontWeight: 800, marginLeft: '8px' }}
              onClick={() => {
                this.handleModelSave();
              }}
            >
              {editLog || editAutoLog ? (modifyRecord ? 'Modify' : 'Update') : 'Save'}
            </Button>
          ]}
          onClose={() => this.handleCancel()}
        >
          <Form layout="vertical">
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // gutter={24}
            >
              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Form.Item
                  label={<FormLabel>Process Area</FormLabel>}
                  hasFeedback={ProcessArea !== '' ? true : false}
                  validateStatus={
                    checkValidations ? (ProcessArea !== '' ? 'success' : 'error') : 'success'
                  }
                >
                  <WorkCenterSelect
                    placeholder="Select Process Area"
                    value={ProcessArea === '' ? undefined : ProcessArea}
                    disabled={editLog ? true : editAutoLog ? true : false}
                    onChange={(e) => {
                      this.handleOnChange(e, 'ProcessArea');
                    }}
                  >
                    {processData &&
                      Array.isArray(processData) &&
                      processData.map((areaID, index) => {
                        return (
                          <Option key={index} value={areaID.Code}>
                            {areaID.Name}
                          </Option>
                        );
                      })}
                  </WorkCenterSelect>
                </Form.Item>
              </Col>

              <Col className="gutter-row" span={6}>
                <Form.Item
                  label={<FormLabel>Work Center</FormLabel>}
                  hasFeedback={this.state.WorkCentre !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? this.state.WorkCentre !== ''
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <WorkCenterSelect
                    placeholder="Select WorkCenter"
                    value={WorkCentre === '' ? undefined : WorkCentre}
                    disabled={editLog ? true : editAutoLog ? true : false}
                    onChange={(e) => {
                      this.handleOnChange(e, 'WorkCentre');
                    }}
                  >
                    {workCenterlist &&
                      Array.isArray(workCenterlist) &&
                      workCenterlist.map((workId, index) => {
                        return (
                          <Option key={index} value={workId.Code}>
                            {workId.Name}
                          </Option>
                        );
                      })}
                  </WorkCenterSelect>
                </Form.Item>
              </Col>

              {editLog || editAutoLog ? null : (
                <Col className="gutter-row" span={6}>
                  <Form.Item
                    label={<FormLabel>Equipment</FormLabel>}
                    hasFeedback={EquipmentCode !== '' ? true : false}
                    validateStatus={
                      checkValidations ? (EquipmentCode !== '' ? 'success' : 'error') : 'success'
                    }
                  >
                    <WorkCenterSelect
                      placeholder="Select Equipment"
                      value={EquipmentCode === '' ? undefined : EquipmentCode}
                      disabled={editLog ? true : editAutoLog ? true : false}
                      onChange={(e) => {
                        this.handleOnChange(e, 'EquipmentCode');
                      }}
                    >
                      {equipmentData &&
                        Array.isArray(equipmentData) &&
                        equipmentData.map((EquipmentCode, index) => {
                          return (
                            <Option key={index} value={EquipmentCode.Code}>
                              {EquipmentCode.Name}
                            </Option>
                          );
                        })}
                    </WorkCenterSelect>
                  </Form.Item>
                </Col>
              )}

              {editAutoLog || editLog ? (
                <Col
                  className="gutter-row"
                  span={6}
                  // span={6}
                >
                  <Form.Item
                    label={<FormLabel>Equipment</FormLabel>}
                    hasFeedback={EquipmentCode !== '' ? true : false}
                    validateStatus={
                      checkValidations ? (EquipmentCode !== '' ? 'success' : 'error') : 'success'
                    }
                  >
                    <WorkCenterSelect
                      placeholder="Select Equipment"
                      value={EquipmentName === '' ? undefined : EquipmentName}
                      disabled={editLog ? true : editAutoLog ? true : false}
                    ></WorkCenterSelect>
                  </Form.Item>
                </Col>
              ) : null}

              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                {hasSubEquipment ? (
                  <Form.Item
                    label={<FormLabel>Sub Equipment</FormLabel>}
                    hasFeedback={SubEquipmentCode !== '' ? true : false}
                    validateStatus={
                      checkValidations ? (EquipmentCode !== '' ? 'success' : 'error') : 'success'
                    }
                  >
                    <WorkCenterSelect
                      placeholder="Select Sub Equipment"
                      value={SubEquipmentCode === '' ? undefined : SubEquipmentCode}
                      disabled={editLog}
                      onChange={(e) => {
                        this.handleOnChange(e, 'SubEquipmentCode');
                      }}
                    >
                      {subEquipmentList.map((list, index) => {
                        return (
                          <Option key={index} value={list.Id}>
                            {list.SubEquipmentName}
                          </Option>
                        );
                      })}
                    </WorkCenterSelect>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={<FormLabel>Type</FormLabel>}
                    hasFeedback={
                      TypeOfDowntime !== '' ? true : TypeOfDowntime !== '' ? true : false
                    }
                    validateStatus={
                      checkValidations ? (TypeOfDowntime !== '' ? 'success' : 'error') : 'success'
                    }
                  >
                    <WorkCenterSelect
                      placeholder="Type of DownTime"
                      value={TypeOfDowntime === '' ? undefined : TypeOfDowntime}
                      disabled={editLog}
                      onChange={(e) => {
                        this.handleOnChange(e, 'TypeOfDowntime');
                      }}
                    >
                      <Option value="Planned">Planned</Option>
                      <Option value="UnPlanned">UnPlanned</Option>
                    </WorkCenterSelect>
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // gutter={24}
            >
              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Form.Item
                  label={<FormLabel>Shift</FormLabel>}
                  hasFeedback={Shift !== '' ? true : false}
                  validateStatus={
                    checkValidations ? (Shift !== '' ? 'success' : 'error') : 'success'
                  }
                >
                  <WorkCenterSelect
                    placeholder="Select Shift"
                    value={Shift === '' ? undefined : Shift}
                    disabled={editLog ? true : editAutoLog ? true : false}
                    onChange={(e) => {
                      this.handleOnChange(e, 'Shift');
                    }}
                  >
                    {shiftList &&
                      Array.isArray(shiftList) &&
                      shiftList.map((shifts, index) => {
                        return (
                          <Option key={index} value={shifts}>
                            {shifts}
                          </Option>
                        );
                      })}
                  </WorkCenterSelect>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Form.Item
                  label={<FormLabel>From DateTime</FormLabel>}
                  hasFeedback={totalFromDateTime !== '' ? true : false}
                  validateStatus={
                    checkValidations
                      ? totalFromDateTime !== '' || totalFromDateTime !== null
                        ? 'success'
                        : 'error'
                      : 'success'
                  }
                >
                  <DatePicker
                    disabledDate={(val) => this.disabledStartDate(val)}
                    disabledTime={(val) => this.disabledStartTime(val)}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={totalFromDateTime !== null ? totalFromDateTime : null}
                    placeholder="Start DateTime"
                    onChange={(val) => this.onStartChange(val)}
                    onOpenChange={(status) => this.handleStartOpenChange(status)}
                    style={{ width: 250 }}
                    disabled={modifyRecord || splitView}
                    // showTime={{
                    //   defaultValue: moment(selectedShift.FromTime, "HH:mm"),
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Form.Item
                  label={<FormLabel>To DateTime</FormLabel>}
                  hasFeedback={totalToDateTime !== '' ? true : false}
                  validateStatus={
                    checkValidations ? (totalToDateTime !== '' ? 'success' : 'error') : 'success'
                  }
                >
                  <DatePicker
                    disabledDate={(val) => this.disabledEndDate(val)}
                    disabledTime={(val) => this.disabledEndTime(val)}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={totalToDateTime !== null ? totalToDateTime : null}
                    placeholder="End DateTime"
                    style={{ width: 250 }}
                    onChange={(val) => this.onEndChange(val)}
                    onOpenChange={(status) => this.handleEndOpenChange(status)}
                    disabled={modifyRecord || splitView}
                    // showTime={{
                    //   defaultValue: moment(selectedShift.FromTime, "HH:mm"),
                    // }}
                    // onOk={(val)=>this.timeValidation(val)}
                  />
                </Form.Item>
              </Col>
              {!hasSubEquipment ? null : (
                <Col
                  className="gutter-row"
                  span={6}
                  // span={6}
                >
                  <Form.Item
                    label={<FormLabel>Type</FormLabel>}
                    hasFeedback={TypeOfDowntime !== '' ? true : false}
                    validateStatus={
                      checkValidations ? (TypeOfDowntime !== '' ? 'success' : 'error') : 'success'
                    }
                  >
                    <WorkCenterSelect
                      placeholder="Type of DownTime"
                      value={TypeOfDowntime === '' ? undefined : TypeOfDowntime}
                      disabled
                      onChange={(e) => {
                        this.handleOnChange(e, 'TypeOfDowntime');
                      }}
                    >
                      <Option value="Planned">Planned</Option>
                      <Option value="UnPlanned">UnPlanned</Option>
                    </WorkCenterSelect>
                  </Form.Item>
                </Col>
              )}

              <Col
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Form.Item
                  label={<FormLabel>Total Time</FormLabel>}
                  // hasFeedback={totalTime !== "" ? false : false}
                  // validateStatus="success"
                  hasFeedback={totalTime !== '' || totalTime !== null ? true : false}
                  validateStatus={
                    checkValidations ? (totalTime !== '' ? 'success' : 'error') : 'success'
                  }
                >
                  <Input
                    placeholder="Total Times"
                    value={totalTime !== null ? totalTime : null}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              // gutter={24}
            >
              <Col
                style={{
                  display: splitView || modifyRecord || !editLog ? 'none' : null
                }}
                className="gutter-row"
                span={6}
                // span={6}
              >
                <Button
                  type="danger"
                  style={{ fontWeight: 700, marginTop: '18%' }}
                  onClick={() => this.addSplitAreaView()}
                >
                  Split DownTime
                </Button>
              </Col>
              {splitDowntimeLog.length > 0 || greyOutRecords.length > 0 ? null : (
                <Col
                  style={
                    {
                      // display: splitView ? null : "none",
                    }
                  }
                  className="gutter-row"
                  span={6}
                  // span={6}
                >
                  <Button
                    type="danger"
                    disabled={addArea ? false : editAutoLog ? false : true}
                    style={{ fontWeight: 700, marginTop: '11%' }}
                    onClick={() => this.addSplitArea()}
                  >
                    Add Area
                  </Button>
                </Col>
              )}
            </Row>
            {/* <Row
              style={{
                borderBottom: i >= 0 ? "1px solid" : "none",
              }}
              gutter={24}
            >

              <Col span={24}>
                <Form.Item>
                  <Button type="primary" onClick={() => this.addAreaRemark(i, parentId)} style={{ marginTop: "10px" }}>
                    <PlusOutlined /> Add Remarks
                  </Button>
                  <div style={{ marginTop: "10px" }}> {AddAreaFormItems}</div>
                </Form.Item>

              </Col>
            </Row> */}
            <Row
              style={{
                borderBottom: splitDowntimeLog.length > 0 ? '1px solid' : null
              }}
              gutter={24}
            >
              <Col span={24}>
                <Button type="primary" onClick={this.add} style={{ marginTop: '10px' }}>
                  <PlusOutlined /> Add Remarks
                </Button>
                <div style={{ marginTop: '10px' }}>
                  {remarks &&
                    Array.isArray(remarks) &&
                    remarks.map((k, index) => (
                      <Form.Item required={false} key={index}>
                        <TextArea
                          // value={ k.Remarks }
                          value={k.Remarks !== '' ? k.Remarks : null}
                          // disabled={editLog ? true : editAutoLog ? true : false}
                          placeholder={'Remarks'}
                          style={{ width: '50%' }}
                          onChange={(e) => {
                            // this.handleOnChange(e.target.value, "Remarks");
                            this.handleMainRemark(e.target.value, index);
                          }}
                          rows={2}
                        />
                      </Form.Item>
                    ))}
                </div>
              </Col>
            </Row>
          </Form>

          {greyOutRecords &&
            Array.isArray(greyOutRecords) &&
            greyOutRecords.map((data, index) => dynamicAreas(data, index, 'greyOut'))}
          {splitDowntimeLog &&
            Array.isArray(splitDowntimeLog) &&
            splitDowntimeLog.map((data, index) => dynamicAreas(data, index, 'splitLog'))}
        </Drawer>

        <Drawer
          width={700}
          title="Remarks"
          closable
          visible={this.state.Remarksvisible}
          onOk={() => {
            this.setState({ Remarksvisible: false });
          }}
          onCancel={() => {
            this.setState({ Remarksvisible: false });
          }}
        >
          <Table columns={Remarkscolumns} dataSource={manualRemarks}></Table>
        </Drawer>
      </StyledDowntime>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(DownTime);
