import React, { Fragment } from 'react';
import moment from 'moment';
import { Spin, Tag } from 'antd';
import cloneDeep from 'lodash.clonedeep';
// import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { defaultRefreshTime } from '../../constant.json';

import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
// import localStorage from '../../../../../utils/localStorage';
import { makeSelectTheme } from '../../../../../selectors/theme';

import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class LineBarArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      loading: false,
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      reportName: '',
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    let { loading, errorInAPI, noDataInDB, refreshDateTime } = this.state;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}

        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            {loading === false ? (
              <ReactEcharts
                option={this.state.option}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: '80%', width: '100%' }}
                className="react_for_echarts"
              />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: 'black' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </span>
        )}
      </Fragment>
    );
  }

  getData = async (props) => {
    this.requestCheck = false;
    let theme = this.props.selectedThemeing;
    let json = [];
    let { graphprops } = this.props;
    this.setState({ loading: true });
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;

      this.requestCheck = true;
      this.setState({ loading: false });
    } else {
      if (
        props.graphprops.graph === 'rangeLine' &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
          (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
          (graphprops &&
            (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.dayMonthYearExists === 'true' ||
              graphprops.selection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.DeviceCode,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection,
            this.props.dashboardPayload,
            this.props.AssetCode,
            this.props.selectionDateLive,
            this.props.currentTimeZone
          );
          this.requestCheck = true;
          this.setState({ loading: false });
        } else {
          json = await getChartDataWithoutSelection(
            graphprops,
            this.abortController,
            this.props.dropdownSelection,
            this.props.selectionDateLive,
            '',
            this.props.currentTimeZone
          );
          this.requestCheck = true;
          this.setState({ loading: false });
        }
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) &&
        json.length === 0 &&
        graphprops.enableMock &&
        graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (json && Array.isArray(json)) {
        let dates = [];
        let values = [];
        let name = '';
        let Names = [];
        let seriesarray = [];
        let legendsdata = [];
        json.map((val) => {
          if (val.Name !== name) {
            if (Names.indexOf(val.Name) === -1) {
              Names.push(val.Name);
              name = val.Name;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 18,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });
        let yAxisData = [];

        if (this.props.graphprops.YAxis) {
          yAxisData = [...this.props.graphprops.YAxis];
        }
        let yaxis = yAxisData.map((value, index) => {
          let colorss = [];
          colorss = [...this.props.graphprops.colors];
          let axis = {};
          axis.splitLine = {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          };
          axis.triggerEvent = true;
          axis.axisLine = {
            lineStyle: {
              color:
                colorss[index] !== undefined
                  ? value && value.tags && value.tags.length > 1
                    ? 'white'
                    : colorss[index]
                  : 'white'
            }
          };
          axis.max = value.max;
          axis.min = value.min;
          axis.offset = value.offset;
          axis.interval = value.interval;
          axis.position = value.position;
          axis.name = value.name;
          axis.tags = value.tags;
          return axis;
        });
        yaxis.unshift({
          splitLine: {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          },
          triggerEvent: true,
          axisLine: {
            lineStyle: {
              // color: theme === "lightTheme" ? "black" : "white",
            }
          },
          // axisLabel: { fontSize: 15 },
          tags: []
        });

        Names.map((tagval, tagindex) => {
          values = [];
          json.map((jsonval) => {
            if (Names[0] === jsonval.Name && tagindex === 0) {
              let date =
                graphprops && graphprops.type === 'live'
                  ? moment
                      .utc(jsonval.DateTime)
                      .tz(this.props.currentTimeZone)
                      .format('DD/MM/YYYY HH:mm:ss')
                  : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm:ss');
              dates.push(date);
            }
            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });
          let graphTypeData = [];
          if (this.props.graphprops.LineBarArea.GraphType) {
            graphTypeData = JSON.parse(this.props.graphprops.LineBarArea.GraphType);
          }

          let graphtype = graphTypeData.map((value) => {
            let Type = {};
            Type.tags = value.tags;
            Type.type = value.type;
            return Type;
          });

          graphtype.map((value) => {
            if (value.tags.indexOf(tagval) !== -1) {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type: 'line',
                smooth: true
              });
              // if (value.type === 'line') {
              //   seriesarray.push({
              //     name: legendsdata[tagindex]['name'],
              //     data: values,
              //     type: 'line',
              //     smooth: true
              //   });
              // } else if (value.type === 'area') {
              //   seriesarray.push({
              //     name: legendsdata[tagindex]['name'],
              //     data: values,
              //     type: 'line',
              //     areaStyle: {},
              //     smooth: true
              //   });
              // } else if (value.type === 'bar') {
              //   seriesarray.push({
              //     name: legendsdata[tagindex]['name'],
              //     data: values,
              //     type: 'bar',
              //     smooth: true
              //   });
              // } else {
              //   seriesarray.push({
              //     name: legendsdata[tagindex]['name'],
              //     data: values,
              //     type: 'line',
              //     smooth: true
              //   });
              // }
            } else {
              if (value.type === 'line') {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: 'line',
                  smooth: true
                });
              } else if (value.type === 'area') {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: 'line',
                  areaStyle: {},
                  smooth: true
                });
              } else if (value.type === 'bar') {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: 'bar',
                  smooth: true
                });
              } else {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: 'line',
                  smooth: true
                });
              }
            }
            return {};
          });
          return {};
        });

        const option = cloneDeep(this.getOption());

        let series = await trendLabelTranslation(seriesarray, this.props.translationCache);

        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
        option.series = [...series];
        option.legend = [...legendsdata];

        this.setState({
          option: option,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;

    let option = {
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect',
            textStyle: {
              // fontSize: 15,
            }
          }
        ]
      },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          // fontSize: 15,
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },

      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectTheme(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(LineBarArea);
