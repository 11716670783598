import React, { Component } from 'react';
import axios from 'axios';
import { Select } from 'antd';

import localStorage from '../../utils/localStorage';

const { Option } = Select;

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initalRender: true
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.column !== this.props.column) {
      this.setState({
        column: this.props.column
      });
    }
  }

  componentDidMount() {
    // let { initalRender } = this.props
    // if (initalRender && initalRender === true) {
    this._getOptionList(this.props.column);
    //}
  }

  _getOptionList = (tableName) => {
    let { column, initalRender } = this.props;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let objectHeader = {};
    if (tableName && tableName.table && Array.isArray(tableName.table)) {
      this.setState({
        optionList: tableName.table
      });
      return tableName.table;
    }
    if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
      objectHeader = {
        method: 'GET',
        url: `${tableName.table.url}?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (
      tableName &&
      tableName.table &&
      tableName.table.url &&
      tableName.table.method &&
      tableName.table.payload &&
      tableName.table.method === 'POST'
    ) {
      objectHeader = {
        method: tableName.table.method,
        url: `${tableName.table.url}?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: tableName.table.payload
      };
    } else {
      objectHeader = {
        method: 'POST',
        url: `/api/master/dropdown?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          tableName: tableName.table
        }
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
          if (
            optionList[0] &&
            column.option &&
            column.option.value &&
            initalRender &&
            initalRender === true
          ) {
            this.props.handleFieldChange(
              optionList[0][column.option.value],
              {
                optionList,
                initalRender: false
              },
              column
            );
          }
        }
      })
      .catch(() => {
        return [];
      });
  };

  render() {
    let { column, record, optionList } = this.props;
    return (
      <div>
        <span style={{ fontWeight: 'bold', color: 'white' }}>{column.title}</span>
        <Select
          value={column && column.defaultValue ? column.defaultValue : ''}
          //  defaultValue={optionList && optionList[0] && optionList[0].Name ? optionList[0].Name : ""}
          style={{ width: 320, margin: '0px 5px' }}
          onChange={(e) =>
            this.props.handleFieldChange(
              e,
              {
                optionList,
                initalRender: false
              },
              column
            )
          }
          onKeyPress={(e) => this.props.handleKeyPress(e, record.key)}
          placeholder={column.placeHolder || column.displayName}
        >
          {optionList &&
            optionList.map((data, index) => {
              if (
                column &&
                column.option &&
                column.option.display &&
                column.option.value &&
                data[column.option.value] &&
                data[column.option.display]
              ) {
                return (
                  <Option key={index} value={data[column.option.value]}>
                    {data[column.option.display]}
                  </Option>
                );
              }
              return (
                <Option key={index} value={data}>
                  {data}
                </Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default SelectComponent;
