import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import QRCodePrint from 'react-qr-code';
import QRCodeImage from 'qrcode.react';
import Highlighter from 'react-highlight-words';
import { jsPDF } from 'jspdf';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { Form, message, Select, Input, Button } from 'antd';
import { decode as base64_decode } from 'base-64';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';

import history from '../../../../commons/history';
import { getScannerId } from './Utils/FormCalls';
import goback from './Utils/images/goback.jpg';
import TableSelect from './Widgets/TableSelect';
import { StyledComponent, StyledButton, StyledModal, StyledForm } from './CSS/style';
import themeSettings from './Utils/themeSettings.json';
import {
  getSitesList,
  getDeviceList,
  getManufacturerList,
  getHierarchyList,
  submitMaster,
  getQRById
} from './Utils/FormCalls';
import { constant } from './Utils/constants';
import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import translation from './Utils/translation';
import InputComponent from './Widgets/InputComponent';
import { getUserProfile } from '../../../../selectors/layout';

const { Option } = Select;
const urlList = {
  insert: '/api/device/updateScannerId',
  //  getbyId: '/api/masterItems/getById',
  getbyId: '/api/device/getScannerDetails'
  //     delete: 'api/masterItems/getById?Id=3'
};
// const previewStyle = {
//     height: 240,
//     width: 320,
// }
// const fileData = new FormData();
const layout = {
  wrapperCol: {
    offset: 8
  }
};
class QrDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      viewQr: false,
      disableQRButton: true
    };
  }
  QrRef = React.createRef();
  emailref = React.createRef();
  picRef = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.QrRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.QrRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let ManufacturerList = await getManufacturerList();
      this.setState({ ManufacturerList: ManufacturerList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    // if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
    //     if (this.QrRef.current) this.QrRef.current.setFieldsValue(this.props.formObject);
    //     // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    // }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
        // datasource:
        //     this.props.formObject && this.props.formObject.Specifications
        //         ? this.props.formObject.Specifications
        //         : []
      });
    }
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.QrRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };

  TableDropdownChange = async (value, modal) => {
    let formObject = this.QrRef.current.getFieldsValue();
    let ScannerIdResult = await getScannerId(value.DeviceCode);
    formObject = {
      ...formObject,
      AliasCode: value.AliasCode,
      Description: value.Name,
      Location: value.Location,
      LocationDescription: value.LocationDescription,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      ScannerId: ScannerIdResult.ScannerId
    };
    this.QrRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false,
      value: ScannerIdResult.ScannerId
    });
  };

  _getDataById = async (QrId) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/QrDetails/');
    let formId = QrId ? QrId : base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getQRById(urlList.getbyId, formId, 'POST');
      formObject = data && data !== undefined ? data : {};
      // if(QrId && QrId !== null)
      // {
      this.setState({
        viewQr: true,
        value: formId
      });
      // }
    }
    if (this.QrRef.current) {
      this.QrRef.current.setFieldsValue(formObject);
    }

    this.setState({
      formObject,

      datasource: formObject && formObject.Specifications ? formObject.Specifications : []
    });
  };
  onFormsubmit = async () => {
    let { formObject } = this.state;
    let payload = {
      ...formObject
    };
    let statusResponse = await submitMaster(urlList.insert, payload);
    if (statusResponse && statusResponse.message) {
      // this._getDataById(formObject.ScannerId);
      this.setState({
        viewQr: true,
        disableQRButton: true
      });
      message.success(statusResponse.message);
      // this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  handleScan = (data) => {
    this.setState({
      result: data
    });
  };
  handleError = (err) => {
    console.error(err);
  };
  sendEmail = (key) => {
    this.setState({
      [key]: true
    });
  };
  emailmodalOk = () => {
    this.setState({ emailmodal: false });
  };
  emailmodalCancel = () => {
    this.setState({ emailmodal: false });
  };
  // downloadQRCode = () => {
  //     // Generate download with use canvas and stream
  //     const canvas = document.getElementById("QRIMAGE");
  //     const pngUrl = canvas.toDataURL("image/png")
  //         .replace("image/png", "image/octet-stream");
  //     let downloadLink = document.createElement("a");
  //     downloadLink.href = pngUrl;
  //     downloadLink.download = `${this.state.value}.png`;
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  // };
  downloadQRCodeasPdf = () => {
    let pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [40, 40]
    });
    const canvas = document.getElementById('QRIMAGE').toDataURL('image/png');
    pdf.addImage(canvas, 'png', 0, 0, 40, 40);
    pdf.save(`${this.state.value}.pdf`);
  };
  onModalOk = () => {
    this.setState({ visible: false });
  };
  onDrawerCancel = () => {
    this.setState({ visible: false });
  };
  print = () => {
    const printablecontent = document.getElementById('print').innerHTML;
    const printwindow = window.open('', '_blank', '', '');
    // printwindow.document.write(printablecontent.innerHTML)
    // printwindow.print()
    printwindow.document.write(printablecontent);
    printwindow.print();
    printwindow.close();
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  resetFields = () => {
    let t1 = {
      DisplayCode: '',
      Description: '',
      Location: undefined,
      LocationDescription: '',
      ScannerId: ''
    };
    if (this.QrRef.current && this.QrRef.current !== null) {
      this.QrRef.current.setFieldsValue({ ...t1 });
      this.setState({
        formObject: {},
        viewQr: false
      });
    }
  };
  render() {
    let { formObject, value, translationCache, deviceList, viewQr } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 73 }}>
        <div style={{ color: '#ffb327', fontSize: '16px' }}>
          <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            <img src={goback} alt="logo" style={{ width: '25px' }} />
          </span>
        </div>
        <StyledForm
          name="basic"
          theme={themeSettings}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          ref={this.QrRef}
          initialValues={formObject}
          onFinish={this.onFormsubmit}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          validateMessages={validateMessages}
        >
          <Form.Item name="Id"></Form.Item>
          <Form.Item
            label={translation(translationCache, constant.equipment)}
            name="DisplayCode"
            rules={[{ required: true }]}
            disabled={formObject && formObject.Id ? true : false}
          >
            <Input
              disabled={true}
              style={{ color: 'black' }}
              color="black"
              suffix={
                <DownOutlined
                  style={{
                    color: '#1a3b5c',
                    cursor: formObject && formObject.Id ? 'not-allowed' : null,
                    opacity: formObject && formObject.Id ? '0.8' : null
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (formObject && !formObject.Id) {
                      this.getDeviceData('visible');
                    }
                    this.setState({
                      disableQRButton: false
                    });
                  }}
                />
              }
              placeholder={translation(translationCache, constant.SelectEquipment)}
            />
          </Form.Item>
          <Form.Item
            label={translation(translationCache, constant.equipmentDescription)}
            name="Description"
            rules={[{ required: true }]}
          >
            <InputComponent
              // maxLength={150}
              style={{ color: 'black' }}
              placeholder={translation(translationCache, constant.AutopopulatebasedonEquipment)}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label={translation(translationCache, constant.location)}
            name="Location"
            rules={[{ required: true }]}
          >
            <Select
              style={{ color: 'black' }}
              placeholder={translation(
                this.props.translationCache,
                constant.AutopopulatebasedonEquipment
              )}
              disabled={true}
              // suffixIcon={
              //     <ApartmentOutlined
              //         style={{
              //             color: formObject && formObject.Id ? 'rgba(0,0,0,0.66)' : '#1a3b5c',
              //             cursor: formObject && formObject.EquipmentFlag ? 'not-allowed' : null,
              //             opacity: formObject && formObject.EquipmentFlag ? '0.3' : null
              //         }}
              //         onClick={(e) => {
              //             e.preventDefault();
              //             if (formObject && !formObject.Id) {
              //                 this.getHierarchyLevels('visible');
              //             }
              //         }}
              //     />
              // }
            >
              {deviceList &&
                Array.isArray(deviceList) &&
                deviceList.map((device, index) => {
                  return (
                    <Option key={index} value={device.DeviceCode}>
                      {' '}
                      {device.DeviceCode}{' '}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label={translation(translationCache, constant.locationDescription)}
            name="LocationDescription"
            rules={[{ required: true }]}
          >
            <InputComponent
              style={{ color: 'black' }}
              placeholder={translation(
                this.props.translationCache,
                constant.AutopopulatebasedonEquipment
              )}
              disabled={true}
              // maxLength={150}
            />
          </Form.Item>
          <Form.Item
            label={translation(translationCache, constant.scannerId)}
            name="ScannerId"
            rules={[{ required: true }]}
          >
            <InputComponent
              disabled={true}
              style={{ color: 'black' }}
              placeholder={translation(translationCache, constant.AutopopulatebasedonEquipment)}
              //   disabled={formObject && formObject.Id ? true : false}
              // onChange={(e) => this.scannerChange(e.target.value)}
            />
          </Form.Item>
          <Form.Item {...layout}>
            <Button htmlType="submit" style={{ backgroundColor: '#d98e04', color: 'black' }}>
              {translation(translationCache, constant.GenerateQR)}
            </Button>
            <Button
              htmlType="button"
              style={{ marginLeft: '5px', color: 'black' }}
              onClick={() => this.resetFields()}
            >
              {translation(translationCache, constant.Reset)}
            </Button>
          </Form.Item>
        </StyledForm>
        {viewQr && viewQr === true ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                // id="print123"
                ref={this.emailref}
                style={{
                  height: 340,
                  width: 420,
                  backgroundColor: 'white'
                }}
              >
                <p style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  {'ScannerId'}:{value}
                </p>

                <QRCodeImage
                  id="QRIMAGE"
                  style={{ marginLeft: '110px' }}
                  value={`${JSON.stringify({ ScannerId: value })}`}
                  // delay={this.state.delay}
                  // style={previewStyle}
                  size={220}
                  onError={this.handleError}
                  onScan={this.handleScan}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                marginBottom: '40px'
              }}
            >
              <StyledButton
                theme={themeSettings}
                style={{ marginRight: '50px' }}
                onClick={() => exportComponentAsJPEG(this.emailref)}
              >
                {translation(translationCache, constant.SaveImage)}
              </StyledButton>

              <StyledButton
                theme={themeSettings}
                style={{ marginRight: '6px' }}
                onClick={this.print}
              >
                {translation(translationCache, constant.PrintImage)}
              </StyledButton>

              {/* <StyledButton theme={themeSettings} style={{ marginLeft: "30px" }}
                onClick={this.downloadQRCode}>
                {translation(translationCache, constant.SaveImage)}
              </StyledButton>
              <StyledButton theme={themeSettings} style={{ marginRight: "30px", height: "40px", width: "120px" }}
                //    onClick={() => exportComponentAsPDF(this.emailref)}
                onClick={this.downloadQRCodeasPdf}
              >
                {translation(translationCache, constant.SavePdf)}
              </StyledButton>
              <StyledButton theme={themeSettings} htmlType="submit" style={{ marginRight: "30px", height: "40px", width: "120px" }}>
                {translation(translationCache, constant.saveList)}
              </StyledButton>
              <StyledButton theme={themeSettings} style={{ marginRight: "40px", height: "40px", width: "120px" }}
                onClick={() => { this.sendEmail('emailmodal') }}>
                {translation(translationCache, constant.SendEmail)}
              </StyledButton> */}
            </div>
          </div>
        ) : null}
        {
          <div
            id="print"
            style={{
              display: 'flex',
              justifyContent: 'center',
              opacity: 0,
              marginLeft: '950px',
              height: 240,
              width: 320,
              backgroundColor: 'white'
            }}
          >
            <p
              size={400}
              //,
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '30px',
                marginTop: '180px'
              }}
            >
              {'ScannerId'}:{value}
            </p>
            <QRCodePrint
              display={false}
              id="QRPrint"
              style={{ marginLeft: '120px' }}
              value={`${JSON.stringify({ ScannerId: value })}`}
              // delay={this.state.delay}
              // style={previewStyle}
              size={400}
              onError={this.handleError}
              onScan={this.handleScan}
            />
          </div>
        }
        <StyledModal
          open={this.state.visible}
          width={800}
          closable
          title={translation(translationCache, constant.selectEquipment)}
          onOk={this.onModalOk}
          onCancel={this.onDrawerCancel}
        >
          <TableSelect
            data={this.state.tableData}
            translationCache={this.props.translationCache}
            key="AliasCode"
            columns={[
              {
                title: translation(translationCache, 'Equipment'),
                dataIndex: 'DisplayCode',
                key: 'DisplayCode',
                ...this.getColumnSearchProps('DisplayCode')
              },
              {
                title: translation(translationCache, 'Equipment Description'),
                dataIndex: 'Name',
                key: 'Name',
                ...this.getColumnSearchProps('Name')
              }
            ]}
            handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
          />
        </StyledModal>
        <StyledModal
          open={this.state.emailmodal}
          width={800}
          closable
          title={translation(translationCache, constant.SendEmail)}
          onOk={this.emailmodalOk}
          onCancel={this.emailmodalCancel}
        >
          <Form.Item
            style={{
              width: '75%'
            }}
            label={translation(translationCache, constant.locationDescription)}
            name="LocationDescription"
            rules={[{ required: true }]}
          >
            <InputComponent
              style={{ color: 'black' }}
              placeholder={translation(
                this.props.translationCache,
                constant.AutopopulatebasedonLocation
              )}
              disabled={true}
              // maxLength={150}
            />
          </Form.Item>
        </StyledModal>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(QrDetails);
