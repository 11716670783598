import React, { Component } from 'react';
import moment from 'moment';
import { Button, Input, Form, Select, Col, Row, DatePicker } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import localStorage from '../../../../utils/localStorage';

import '../CSS/eLog.css';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const newLocal = true;
const { RangePicker } = DatePicker;
class AttributeBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAttributes: props.formAttributes || {},
      attributeList: [],
      attributeLists: [],
      dynamicKeys: [],
      dynames: [],
      dyvalue: []
    };
  }
  componentDidMount() {
    this._onComponentUpdate();
  }

  componentDidUpdate = (preprops) => {
    if (preprops.formAttributes !== this.props.formAttributes) {
      this._onComponentUpdate();
    }
  };

  _onComponentUpdate = async () => {
    let { batchAttribute } = this.props.formAttributes;
    let attributeLists = [];
    let batchStaticColumns = [];
    batchAttribute &&
      batchAttribute.columns &&
      batchAttribute.columns.map((batchColumns) => {
        batchStaticColumns.push(batchColumns.key);
        return {};
      });

    let dynamicKeys = [];
    let dynames = [];
    let dyvalue = [];
    let i = 0;
    batchAttribute &&
      batchAttribute.datasource &&
      Object.keys(batchAttribute.datasource).map((datasourceKeys) => {
        if (batchStaticColumns.indexOf(datasourceKeys) === -1) {
          dynamicKeys.push(i);
          i = i + 1;
          dynames.push(datasourceKeys);
          dyvalue.push(batchAttribute.datasource[datasourceKeys]);
        }
        return {};
      });
    if (batchAttribute && batchAttribute.datasource && batchAttribute.datasource.Equipment) {
      attributeLists = await this._getAttributeList(batchAttribute.datasource.Equipment);
    }
    this.setState({
      formAttributes: this.props.formAttributes,
      dynamicKeys,
      dynames,
      dyvalue,
      attributeLists
    });
  };

  remove = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k)
    });
  };

  add = () => {
    let { dynamicKeys } = this.state;
    // if (dynamicKeys && dynamicKeys.length === 0) {
    //     dynamicKeys = [0]
    // } else {
    //     id = id + 1
    //     dynamicKeys.push(id)
    // }
    let count = dynamicKeys.length;
    dynamicKeys.push(count);
    this.setState({
      dynamicKeys
    });
  };

  onAttributeChange = (value, keyValue, type) => {
    const { form } = this.props;
    if (type === 'dynamic') {
      form.setFieldsValue({
        [`dynames[${keyValue}]`]: value
      });
      form.setFieldsValue({
        [`dyvalue[${keyValue}]`]: ''
      });
    } else {
      form.setFieldsValue({
        [`names[${keyValue}]`]: value
      });
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values && values.dynames && !Array.isArray(values.dynames)) {
          let dynames =
            values.dyvalue &&
            Array.isArray(values.dyvalue) &&
            values.dyvalue.map((dynamicValue, index) => {
              return values.dynames[index];
            });
          values = {
            ...values,
            dynames: [...dynames, value]
          };
        }
        this.props.adhocData(values);
      }
    });
  };

  _getAttributeList = async (equipment) => {
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let data = await fetch(`/api/logbook/attributeslist `, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      },
      body: JSON.stringify({
        Equipment: equipment.substring(0, 3)
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  handleFieldChange = async (fieldValue, fieldName, keyValue, type) => {
    if (fieldName === 'Equipment') {
      let attributeLists = await this._getAttributeList(fieldValue);
      this.setState({
        attributeLists
      });
    }
    const { form } = this.props;
    if (type === 'dynamic') {
      form.setFieldsValue({
        [`dyvalue[${keyValue}]`]: fieldValue
      });
    } else {
      form.setFieldsValue({
        [`value[${keyValue}]`]: fieldValue
      });
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.adhocData(values);
      }
    });
  };

  handleInputFieldChange = (value, keyValue, type) => {
    const { form } = this.props;
    if (type === 'dynamic') {
      form.setFieldsValue({
        [`dyvalue[${keyValue}]`]: value
      });
    } else {
      form.setFieldsValue({
        [`value[${keyValue}]`]: value
      });
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values && values.dynames && !Array.isArray(values.dynames)) {
          let dynames =
            values.dyvalue &&
            Array.isArray(values.dyvalue) &&
            values.dyvalue.map((dynamicValue, index) => {
              return values.dynames[index];
            });
          values = {
            ...values,
            dynames
          };
        }
        this.props.adhocData(values);
      }
    });
  };

  handleKeyPress = () => {};

  onChange = (date, keyValue, type) => {
    const { form } = this.props;

    if (type === 'dynamic') {
      form.setFieldsValue({
        [`dyvalue[${keyValue}]`]: {
          startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
        }
      });
    } else {
      form.setFieldsValue({
        [`value[${keyValue}]`]: {
          startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
        }
      });
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.adhocData(values);
      }
    });
  };

  renderSwitch(attributeList, k) {
    const { getFieldValue } = this.props.form;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let widget =
      attributeList &&
      attributeList.filter(function (itm) {
        return itm.key === getFieldValue(`names[${k}]`);
      }) &&
      attributeList.filter(function (itm) {
        return itm.key === getFieldValue(`names[${k}]`);
      }).length > 0 &&
      attributeList.filter(function (itm) {
        return itm.key === getFieldValue(`names[${k}]`);
      })[0].widget
        ? attributeList.filter(function (itm) {
            return itm.key === getFieldValue(`names[${k}]`);
          })[0].widget
        : 'null';
    switch (widget) {
      case 'input':
        return (
          <Input
            placeholder="input value"
            style={{ width: '300px', fontWeight: 'bold' }}
            value={getFieldValue(`value[${k}]`)}
            onChange={(e) => this.handleInputFieldChange(e.target.value, k, 'static')}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={
              attributeList.filter(function (itm) {
                return itm.key === getFieldValue(`names[${k}]`);
              })[0]
            }
            record={{ key: k }}
            text={getFieldValue(`value[${k}]`)}
            handleFieldChange={this.handleFieldChange}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={
              getFieldValue(`value[${k}]`)
                ? [
                    moment(getFieldValue(`value[${k}]`)['startDate'], dateFormat),
                    moment(getFieldValue(`value[${k}]`)['endDate'], dateFormat)
                  ]
                : ''
            }
            onChange={(e) => this.onChange(e, k, 'static')}
          />
        );
      default:
        return (
          <Input
            placeholder="input value"
            style={{ width: '300px', fontWeight: 'bold' }}
            value={getFieldValue(`value[${k}]`)}
            onChange={(e) => this.handleInputFieldChange(e.target.value, k, 'static')}
          />
        );
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { formAttributes } = this.props;
    let { attributeList, attributeLists, dynamicKeys, dynames, dyvalue } = this.state;
    attributeList =
      formAttributes && formAttributes['batchAttribute'] && formAttributes['batchAttribute'].columns
        ? formAttributes['batchAttribute'].columns
        : [];

    let names =
      attributeList &&
      attributeList.map((attributeKey) => {
        return attributeKey.key;
      });
    names = names.filter(function (x) {
      return x !== undefined;
    });

    let keyIndex = [];
    keyIndex =
      names &&
      Array.isArray(names) &&
      names.map((name, index) => {
        return index;
      });
    getFieldDecorator('keys', { initialValue: keyIndex });
    const keys = getFieldValue('keys');
    const formItems =
      keys &&
      Array.isArray(keys) &&
      keys.map((k, index) => {
        return (
          <div key={index}>
            <Col span={12}>
              <Form.Item
                required={false}
                key={k}
                style={{
                  // opacity: true ? 1 : 1,
                  pointerEvents: newLocal ? 'none' : 'initial'
                }}
              >
                {getFieldDecorator(`names[${k}]`, {
                  initialValue: names[`${k}`]
                })(
                  <Input
                    value={getFieldValue(`names[${k}]`)}
                    style={{ width: 300, fontWeight: 'bold', color: 'black' }}
                  />
                )}
              </Form.Item>
              <Form.Item key={k + 1}>
                {getFieldDecorator(`value[${k}]`, {
                  // initialValue: value[`${k}`],
                  initialValue:
                    formAttributes &&
                    formAttributes['batchAttribute'] &&
                    formAttributes['batchAttribute'].datasource
                      ? formAttributes['batchAttribute'].datasource[getFieldValue(`names[${k}]`)]
                      : ''
                })(<div>{this.renderSwitch(attributeList, k, names)}</div>)}
              </Form.Item>
            </Col>
          </div>
        );
      });

    const dynamicFormItems =
      dynamicKeys &&
      Array.isArray(dynamicKeys) &&
      dynamicKeys.map((k, index) => {
        return (
          <div key={index}>
            <Col span={12}>
              <Form.Item required={true} key={k}>
                {getFieldDecorator(`dynames[${k}]`, {
                  initialValue: dynames[`${k}`] ? dynames[`${k}`] : ''
                })(
                  <Select
                    style={{ width: '300px' }}
                    onChange={(e) => this.onAttributeChange(e, k, 'dynamic')}
                  >
                    {attributeLists &&
                      attributeLists.map((attribute, index) => {
                        if (
                          dynames &&
                          Array.isArray(dynames) &&
                          dynames.indexOf(attribute) === -1
                        ) {
                          return (
                            <Option key={index} value={attribute}>
                              {attribute}
                            </Option>
                          );
                        }
                        return {};
                      })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item key={k}>
                {getFieldDecorator(`dyvalue[${k}]`, {
                  initialValue: dyvalue[`${k}`]
                })(
                  attributeList.filter(function (itm) {
                    return itm.key === getFieldValue(`dynames[${k}]`);
                  }) &&
                    attributeList.filter(function (itm) {
                      return itm.key === getFieldValue(`dynames[${k}]`);
                    }).length > 0 &&
                    attributeList.filter(function (itm) {
                      return itm.key === getFieldValue(`dynames[${k}]`);
                    })[0].widget === 'select' ? (
                    <SelectComponennt
                      column={
                        attributeList.filter(function (itm) {
                          return itm.key === getFieldValue(`dynames[${k}]`);
                        })[0]
                      }
                      record={{ key: k }}
                      text={getFieldValue(`dyvalue[${k}]`)}
                      handleFieldChange={(fieldValue, fieldName, keyValue) =>
                        this.handleFieldChange(fieldValue, fieldName, keyValue, 'dynamic')
                      }
                      handleKeyPress={this.handleKeyPress}
                    />
                  ) : (
                    <div>
                      <Input
                        placeholder="input value"
                        style={{ width: '300px', fontWeight: 'bold' }}
                        value={getFieldValue(`dyvalue[${k}]`)}
                        onChange={(e) => this.handleInputFieldChange(e.target.value, k, 'dynamic')}
                      />
                    </div>
                  )
                )}
              </Form.Item>
            </Col>
          </div>
        );
      });

    return (
      <div style={{ color: 'black', fontWeight: 'bold' }}>
        <Row>
          <Form layout="inline" className="batchUpdateForm" style={{ marginTop: '20px' }}>
            {formItems}
          </Form>
        </Row>
        <Row>
          <div>
            {attributeLists &&
            attributeLists.length > 0 &&
            attributeLists.filter((attribute) => {
              if (dynames && Array.isArray(dynames) && dynames.indexOf(attribute) === -1) {
                return attribute;
              }
              return {};
            }).length > 0 ? (
              <Button type="dashed" onClick={this.add}>
                <PlusOutlined /> Add Attribute
              </Button>
            ) : null}
          </div>
        </Row>
        <Row>
          <Form layout="inline">{dynamicFormItems}</Form>
        </Row>
      </div>
    );
  }
}

export default AttributeBatch;
