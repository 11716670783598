import React, { Component } from 'react';
import axios from 'axios';
import { Input, Form, Col, Row, Select, Upload, Button, message, Tabs } from 'antd';
import { PlusOutlined, UploadOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import XMLViewer from 'react-xml-viewer';
import history from '../../../../../src/commons/history';
import { getMasterById } from '../../../AMM/Utils/FormCalls';
import localStorage from '../../../../utils/localStorage';
import { StyledDashboard } from './style';
import './style.css';
const minifyXML = require('minify-xml').minify;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const urlList = {
  getbyId: '/api/xmlFormat/getById'
};

class XMLConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UniqueKeysList: [],
      uploadFileData: {},
      selectdeFileUploadData: {},
      filename: '',
      dataObject: '',
      childXMLdata: '',
      childDataObject: '',
      PayloadType: '',
      xmlView: false,
      XmlViewData: '',
      sitesList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {}
    };
  }

  userRef = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
  };

  _getDataById = async (ItemId) => {
    let formObject = this.userRef.current.getFieldsValue();
    let path = history.location.pathname.split('/rubus/MaximoXMLConfiguration/');
    let formId = ItemId ? ItemId : path[1];
    if (formId !== '1') {
      let data = await getMasterById(urlList.getbyId, formId, 'GET');
      formObject = data && data !== undefined ? data : {};
    }
    if (this.userRef.current) {
      this.userRef.current.setFieldsValue(formObject);
    }

    this.setState({
      formObject
    });
    if (formObject) {
      this.userRef.current.setFieldsValue(formObject);
    }
  };

  getUniqueKeysList = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `/api/xmlformat/uniqueColumnsByXmlData?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then((response) => {
        this.setState({ UniqueKeysList: response.data });
      })
      .catch(function () {});
  };
  ShowXmlView = () => {
    const { xmlView } = this.state;
    this.setState({ xmlView: !xmlView });
  };
  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  //  * @author sapna.m
  //  * @description XMLConfigUpload function is used to read file data
  //  * @return Get content of file
  XMLConfigUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const xml = e.target.result;
      this.setState({
        uploadFileData: minifyXML(xml),
        filename: file.name,
        XmlViewData: xml
      });
    };
    reader.readAsText(file);
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === 'text/xml';
    if (!isXML) {
      message.error('You can only upload xml file!');
    }
    if (!isLt2M) {
      message.error('xml file must be smaller than 10MB!');
    }
    return false;
  };

  //  * @author sapna.m
  // * @description This function is used to Save Form data
  SaveConfigurationUpload = () => {
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        const { uploadFileData } = this.state;

        let fileObject = {
          Name: values.Name,
          type: 'Parent',
          DataObject: values.DataObject,
          XmlFormat: uploadFileData.replace(/"/g, "'"),
          PayloadType: values.PayloadType,
          UniqueKeys: values.UniqueKeys,
          child:
            values.child &&
            Array.isArray(values.child) &&
            values.child.map((data) => {
              return {
                DataObject: data.DataObject,
                type: 'Child',
                XmlFormat:
                  data.XmlFormat && data.XmlFormat
                    ? minifyXML(data.XmlFormat).replace(/"/g, "'")
                    : data.XmlFormat,
                UniqueKeys: data.UniqueKeys
              };
            })
        };
        if (fileObject.PayloadType === 'Response' && fileObject.PayloadType !== 'Request') {
          fileObject['ResponseUrl'] = values.ResponseUrl;
        }

        let statusResponse = this.saveConfigurationAPi(fileObject);
        if (statusResponse && statusResponse.message) {
          message.success('Record Submitted Successfully');
        } else if (statusResponse && statusResponse.Error) {
          message.error(statusResponse.Error);
        }
        if (this.userRef.current) {
          this.userRef.current.resetFields();
          this.setState({ formObject: {} });
        }
        this.setState({
          uploadFileData: {},
          filename: '',
          formObject: {}
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //APi for save
  saveConfigurationAPi = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `/api/xmlformat/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then(() => {
        message.success('Successfully Submitted');
        if (this.userRef.current) {
          this.userRef.current.resetFields();
          this.setState({ formObject: {} });
        }
      })
      .catch(function () {
        message.error('Error');
      });
  };

  SelectedFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        selectdeFileUploadData: e.target.result
      });
    };
    reader.readAsText(file);

    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === 'text/xml';
    if (!isXML) {
      message.error('You can only upload xml file!');
    }
    if (!isLt2M) {
      message.error('xml file must be smaller than 10MB!');
    }
    return isLt2M && isXML;
  };

  handleSelectChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  _SaveDataEntryApi = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `/api/SelectedFile/DataEntryUpload`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then(() => {
        if (this.userRef.current) {
          this.userRef.current.resetFields();
        }
      })
      .catch(function () {});
  };

  saveDataEntryUpload = (e) => {
    e.preventDefault();
    this.userRef.current.validateFields(['SelectedFile', 'xmlFormat']).then(async (values) => {
      const { selectdeFileUploadData } = this.state;
      let fileObject = {
        xmlFormat: selectdeFileUploadData,
        SelectedFile: values.SelectedFile
      };
      this._SaveDataEntryApi(fileObject);
    });
  };

  //  * @author sapna.m
  // * @description This Function is used for getting uniqueKeys for multi select option
  handleDataObject = (e) => {
    e.preventDefault();
    this.userRef.current.validateFields(['DataObject']).then(async (values) => {
      const { uploadFileData } = this.state;
      let fileObject = {
        DataObject: values.DataObject,
        XmlFormat: uploadFileData
      };
      this.getUniqueKeysList(fileObject); //uniqueKeys List will get from this function
    });
  };
  handleDataObjectChild = (e) => {
    this.setState({ childDataObject: e.target.value });
  };
  ChildXMLData = (e) => {
    // e.preventDefault();
    const { childDataObject } = this.state;
    this.setState({ childXMLdata: e.target.value });
    let formObject = {
      DataObject: childDataObject,
      XmlFormat: e.target.value
    };
    this.getUniqueKeysList(formObject);
  };
  onHandleChanePayload = (value) => {
    this.setState({
      PayloadType: value
    });
  };
  render() {
    const { UniqueKeysList, xmlView, XmlViewData, PayloadType, formObject } = this.state;
    const customTheme = {
      tagColor: '#41b341',
      attributeKeyColor: '#0074D9',
      attributeValueColor: '#b36b41',
      overflowBreak: true,
      textColor: '#FFF'
    };

    return (
      <StyledDashboard
        className="EAMForm"
        style={{ minHeight: window.innerHeight - 173, color: '#ffb327' }}
      >
        <div
          style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}
        >
          <Button type="dashed" style={{ marginBottom: '20px' }} onClick={() => history.goBack()}>
            <DoubleLeftOutlined />
            Go Back
          </Button>
          <>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Configuration" key="1">
                <Row style={{ float: 'right', marginTop: '-40px' }}>
                  <Button type="danger" style={{ fontWeight: 'bold' }} onClick={this.ShowXmlView}>
                    {xmlView === false ? 'Show XmlView' : 'Hide XmlView'}
                  </Button>
                </Row>
                <Form
                  className="XMLForm"
                  ref={this.userRef}
                  onFinish={this.SaveConfigurationUpload}
                  onFinishFailed={this.onFinishFailed}
                  layout="vertical"
                  style={{ marginTop: '50px' }}
                  initialValues={formObject}
                >
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Name :"
                        name="Name"
                        rules={[{ required: true, message: 'Please enter Name' }]}
                      >
                        <Input style={{ width: '350px' }} placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Upload File :"
                        name="XmlFormat"
                        rules={[{ required: true, message: 'Please enter XmlFormat' }]}
                      >
                        <Upload
                          name="file"
                          accept="text/xml"
                          multiple="false"
                          beforeUpload={this.XMLConfigUpload}
                          onChange={this.handleChange}
                        >
                          <Button style={{ width: '350px' }}>
                            <UploadOutlined /> Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="DataObject :"
                        name="DataObject"
                        rules={[{ required: true, message: 'Please enter DataObject' }]}
                      >
                        <Input
                          style={{ width: '350px' }}
                          placeholder="Please enter"
                          onBlur={this.handleDataObject}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="PayloadType :"
                        name={'PayloadType'}
                        rules={[{ required: true, message: 'Please enter PayloadType' }]}
                      >
                        <Select
                          style={{ width: '350px' }}
                          placeholder="Please select PayloadType "
                          onChange={this.onHandleChanePayload}
                        >
                          <Option value="Request">Request</Option>
                          <Option value="Response">Response</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {PayloadType === 'Response' ? (
                      <>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="ResponseUrl :"
                            name={'ResponseUrl'}
                            rules={[{ required: true, message: 'Please enter ResponseUrl' }]}
                          >
                            <Input
                              placeholder=" Please Enter ResponseUrl"
                              style={{ width: '350px' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Group :"
                            name={'Group'}
                            rules={[{ required: true, message: 'Please enter Group' }]}
                          >
                            <Input placeholder="Please Enter Group" style={{ width: '350px' }} />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="UniqueKeys :"
                        name="UniqueKeys"
                        rules={[{ required: true, message: 'Please select UniqueKeys' }]}
                      >
                        <Select
                          style={{ width: '350px' }}
                          mode="multiple"
                          placeholder="Please select"
                        >
                          {UniqueKeysList.map((uniquekey, index) => {
                            return (
                              <Option key={index} value={uniquekey}>
                                {uniquekey}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div>
                    <Form.List
                      name="child"
                      // rules={[
                      //   {
                      //     validator: async (_, TaskData) => {
                      //       if (!TaskData || TaskData.length === 0) {
                      //         return Promise.reject(new Error('At least 1 Task'));
                      //       }
                      //     }
                      //   }
                      // ]}
                    >
                      {(child, { add }) => (
                        <>
                          <Form.Item>
                            <Button
                              size="middle"
                              style={{ background: '#29415a', color: '#fff' }}
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add Fields{' '}
                            </Button>
                          </Form.Item>
                          <div>
                            {child.map(({ key, name }) => (
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} key={key}>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item
                                    label="DataObject"
                                    name={[name, 'DataObject']}
                                    rules={[{ required: true, message: 'DataObject Missing' }]}
                                  >
                                    <Input
                                      placeholder="DataObject"
                                      onBlur={(e) => this.handleDataObjectChild(e)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <Form.Item
                                    label="XmlFormat"
                                    name={[name, 'XmlFormat']}
                                    rules={[{ required: true, message: 'XMLData Missing' }]}
                                    style={{ width: '43vw' }}
                                  >
                                    <TextArea
                                      placeholder="Input XmlFormat"
                                      maxLength={10000}
                                      style={{
                                        height: 100
                                      }}
                                      onChange={(e) => this.ChildXMLData(e, 'XmlFormat')}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item
                                    label="UniqueKeys:"
                                    name={[name, 'UniqueKeys']}
                                    rules={[
                                      { required: true, message: 'Please select UniqueKeys' }
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '350px' }}
                                      mode="multiple"
                                      placeholder="Please select"
                                    >
                                      {UniqueKeysList.map((uniquekey, index) => {
                                        return (
                                          <Option key={index} value={uniquekey}>
                                            {uniquekey}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </>
                      )}
                    </Form.List>
                  </div>

                  <Form.Item>
                    <Button type="primary" className="SubmitButton" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ marginTop: '70px' }}>
                  {xmlView ? (
                    <XMLViewer
                      xml={XmlViewData}
                      theme={customTheme}
                      style={{ minWidth: '50vw', backgroundColor: '#000', padding: '15px' }}
                    />
                  ) : null}
                </div>
              </TabPane>
              {/* <TabPane tab="Data entry" key="2">
                <Form
                  ref={this.userRef}
                  name="user_form"
                  layout="horizontal"
                  hideRequiredMark
                  {...formItemLayout}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="SelectedFile :"
                        name={'SelectedFile'}
                        rules={[{ required: true, message: 'Please enter SelectedFile' }]}
                      >
                        <Select style={{ width: '50%' }} placeholder="Please select">
                          <Option value="file1">file1</Option>
                          <Option value="file2">file2</Option>
                          <Option value="file3">file3</Option>
                          <Option value="file4">file4</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Upload File :"
                        name="xmlFormat"
                        rules={[{ required: true, message: 'please Upload file' }]}
                      >
                        <Upload
                          name="file"
                          accept="text/xml"
                          multiple="false"
                          beforeUpload={this.SelectedFileUpload}
                          onChange={this.handleSelectChange}
                        >
                          <Button style={{ width: '300px' }}>
                            <UploadOutlined /> Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    type="primary"
                    style={{ marginLeft: '45%', marginTop: '30px', width: '100px' }}
                    onClick={this.saveDataEntryUpload}
                  >
                    Save
                  </Button>
                </Form>
              </TabPane> */}
            </Tabs>
          </>
        </div>
      </StyledDashboard>
    );
  }
}

export default XMLConfiguration;
