import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Input, message, Button, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import history from '../../../commons/history';
import {
  selectDashboard,
  deleteDashboard,
  getDashboardByName
} from '../DashboardComponents/DashboardCalls';
import localStorage from '../../../utils/localStorage';
import { getUserProfile } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';
import { constant } from './Constants';

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { StyledComponent, StyledButton, StyledTable } from '../../../commons/styles/layout';
import { AddItemDiv, BadgeStyle, SpanItem } from './styles';

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'table',
      DashboardList: [],
      translationCache: props.translationCache || [],
      userProfile: props.userProfile || {},
      columns: [
        {
          title: 'Dashboard Name',
          dataIndex: 'name',
          key: 'name',
          ...this.getColumnSearchProps('name')
        },
        {
          title: 'Group Name',
          dataIndex: 'GroupName',
          key: 'GroupName',
          ...this.getColumnSearchProps('GroupName'),
          render: (text) => (
            <div onClick={() => this.props.history.push({ pathname: `dashboards/${text}` })}>
              {text}
            </div>
          )
        },
        {
          title: 'Action',
          render: (text, record) => (
            <div>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: `dashboard/${record.name}`,
                    routes: record.name
                  });
                }}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <EyeOutlined />
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.selectDashboard(record.name);
                }}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <EditOutlined />
              </button>
              <Popconfirm
                title={'Are you Sure that you want to delete dashboard'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => this.confirm(record.Id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="ant-btn" size="middle">
                  <span>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Popconfirm>
              {/* <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm(record.Id);
                }}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <DeleteOutlined />
              </button> */}
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.downLoadJson(record.name);
                }}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <DownloadOutlined />
              </button>
            </div>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.getDashboards();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <StyledButton
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </StyledButton>
        <StyledButton
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </StyledButton>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item
    });
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value
    });
  };

  listContent(list) {
    return (
      <div style={{ fontSize: '1' }}>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: '40px'
          }}
        >
          <span style={{ lineHeight: '20px', color: 'rgba(0, 0, 0, 0.65)' }}>Name</span>
          <p style={{ marginTop: '4px', marginBottom: '0', lineHeight: '22px' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push({ pathname: `dashboard/${list.name}` });
              }}
            >
              {list.name}
            </Button>
          </p>
        </div>
      </div>
    );
  }

  downLoadJson = async (name) => {
    const dashboardsdata = await getDashboardByName(name);
    let Name =
      dashboardsdata &&
      Array.isArray(dashboardsdata) &&
      dashboardsdata.map((data) => {
        return data.Name;
      });
    let contentType = 'application/json;charset=utf-8;';
    let a = document.createElement('a');
    a.download = `${Name}`;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(dashboardsdata));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const { translationCache, columns, userProfile, DashboardList } = this.state;
    let permissionsArray = [];
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'dashboard')
        .map((name) => permissionsArray.push(name.action));

    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 69 }}>
        <AddItemDiv>
          <div></div>
          <div>
            <BadgeStyle count={DashboardList ? DashboardList.length : 0} />
            <SpanItem>Items</SpanItem>
            <StyledButton
              disabled={!permissionsArray.includes('create') ? true : false}
              onClick={() => history.push({ pathname: '/rubus/editdashboard', state: 'new' })}
            >
              <PlusOutlined />
              <span style={{ padding: '0 5px' }}>
                {translationCache && translationCache[`${constant.Add_Dashboard}`]
                  ? translationCache[`${constant.Add_Dashboard}`]
                  : `${constant.Add_Dashboard}`}
              </span>
            </StyledButton>
          </div>
        </AddItemDiv>
        <StyledTable columns={columns} dataSource={DashboardList} />
      </StyledComponent>
    );
  }

  selectDashboard = async (dashboardName) => {
    if (dashboardName) {
      history.push({ pathname: '/rubus/editdashboard', state: dashboardName });
    }
  };

  confirm = (dashboardId) => {
    this.deleteDashboard(dashboardId);
  };

  cancel = (e) => {
    e.preventDefault();
  };

  deleteDashboard = async (dashboardId) => {
    const msg = await deleteDashboard(dashboardId);
    this.getDashboards();
    message.info(msg);
  };

  getDashboards = async () => {
    let dashboards = [];
    let dashboardsdata = await selectDashboard('');
    if (dashboardsdata !== undefined) {
      dashboardsdata.forEach((value, index) => {
        dashboards.push({
          id: index,
          name: value.Name,
          Id: value.Id,
          GroupName: value.GroupName
        });
      });
    }
    this.setState({ DashboardList: dashboards });
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(DashboardPage);
