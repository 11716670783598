import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { useLeaflet } from 'react-leaflet';
import turbine from './turbine.gif';

const markerClusters = L.markerClusterGroup();
const customIcon = new L.Icon({
  iconUrl: turbine,
  iconSize: new L.Point(60, 75)
});

const MarkerCluster = ({ markers, addMarkers }) => {
  const { map } = useLeaflet();

  useEffect(() => {
    markerClusters.clearLayers();
    markers.forEach(({ position }) => {
      const popupContent =
        '<h2>' +
        '<div style="font-weight: bold;">' +
        'Latitude :' +
        '</div>' +
        '</h2>' +
        position.lat +
        '<h2>' +
        '<div style="font-weight: bold;">' +
        'Longitude :' +
        '</div>' +
        '</h2>' +
        position.lng;

      L.marker(new L.LatLng(position.lat, position.lng), { icon: customIcon })
        .addTo(markerClusters)
        .bindPopup(popupContent);
    });
    map.addLayer(markerClusters);
  }, [markers, map]);

  map.on('moveend', () => {
    // const start = window.performance.now();

    addMarkers();
    const markersToAdd = [];
    markerClusters.clearLayers();
    markers.forEach(({ position }) => {
      const popupContentOn =
        '<h2>' +
        '<div style="font-weight: bold;">' +
        'Latitude :' +
        '</div>' +
        '</h2>' +
        position.lat +
        '<h2>' +
        '<div style="font-weight: bold;">' +
        'Longitude :' +
        '</div>' +
        '</h2>' +
        position.lng;

      const markerToAdd = L.marker(new L.LatLng(position.lat, position.lng), {
        icon: customIcon
      })
        .addTo(markerClusters)
        .bindPopup(popupContentOn);

      if (markerToAdd !== undefined) {
        markersToAdd.push(markerToAdd);
      }
    });

    markerClusters.addLayers(markersToAdd);
    // const end = window.performance.now();
  });
  // map.on('click', function (e) {
  // var coord = e.latlng.toString().split(',');
  // var lat = coord[0].split('(');
  // var long = coord[1].split(')');
  // });

  return null;
};

export default MarkerCluster;
