import React from 'react';
import {
  Select,
  Button,
  DatePicker,
  message,
  Modal,
  Input,
  Table,
  Divider,
  Dropdown,
  Typography,
  Space
} from 'antd';
import { Form } from 'antd';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import localStorage from '../../utils/localStorage';
import { constant } from './Utils/constants';
import {
  getParameterList,
  getDeviceData,
  CreateTrend,
  getBookmarkList,
  UpdateTrends,
  getOperationList,
  DeleteTrend,
  getDefaultSiteName
} from './ChartCalls';
import './CSS/trends.css';
import { StyledModal } from './CSS/styles';
import HierarchyModal from './Utils/HierarchyModal';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  UpSquareOutlined
} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const IntervalData = [
  {
    key: '1Min',
    value: '1m'
  },
  {
    key: '15Min',
    value: '15m'
  },
  {
    key: '30Min',
    value: '30m'
  },
  {
    key: '60Min',
    value: '1h'
  },
  {
    key: 'Day',
    value: '1d'
  }
];

class FilterComponent extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      Module: '',

      EquipmentList: [],
      ParameterList: {},
      OperationalList: {},
      ParameterListByType: [],

      trendData: [],
      tunedData: {},

      loading: false,
      CurrentTab: 'day',
      GraphType: props.GraphType || 'line',
      ViewType: 'Single',
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],

      type: 'live',
      selectedDevice: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],

      isModalSaveBookmark: false,
      isModalViewBookmark: false,
      fieldVisible: true,
      treeVisible: false,
      checkedValue: []
    };
  }
  formHeader = React.createRef();
  abortController = new window.AbortController();

  async componentDidMount() {
    let Module =
      this.props && this.props.match && this.props.match.params && this.props.match.params.module;
    this._getEquipmentData(Module);
    let BookmarkList = await getBookmarkList(Module);
    this.setState({ Module, BookmarkList });
  }

  _getEquipmentData = async () => {
    let getSiteName = await getDefaultSiteName();
    console.log(getSiteName, 'getsitename');
    let HierarchyCode = getSiteName && getSiteName[0] ? getSiteName[0].HierarchyCode : '';
    let EquipmentList = await getDeviceData('Operational', HierarchyCode);
    this.setState({ EquipmentList });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onChangeFromDate_ToDate = (selectedFromDate_ToDate) => {
    this.setState({
      selectedFromDate_ToDate
    });
  };

  onChangeFromDate_ToDate1 = (selectedFromDate_ToDate1) => {
    this.setState({
      selectedFromDate_ToDate1
    });
  };

  onChangeFromDate1_ToDate1 = (selectedDateRangeComparision) => {
    this.setState({
      selectedDateRangeComparision
    });
  };

  OnResetFields = () => {
    this.formHeader.current.setFieldsValue({
      type: 'live',
      selectedDevice: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      ViewType: 'Single',
      fieldVisible: true,
      GraphType: 'line'
    });
    this.setState({
      type: 'live',
      selectedDevice: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      BookmarkName: undefined,
      Id: undefined,
      ViewType: 'Single',
      fieldVisible: true,
      GraphType: 'line'
    });
    this.props.OnGenerateTrendData({}, {}, 'Trends');
  };

  OnGenerateTrendData = async () => {
    let {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator
    } = this.state;
    let filterObject = {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator
    };
    this.formHeader.current
      .validateFields()
      .then(async () => {
        const tunedData = this.trendAdapter();
        this.props.OnGenerateTrendData(tunedData, filterObject, 'Trends');
        this.setState({
          tunedData
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  trendAdapter() {
    const {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator,
      selectedParameterType
    } = this.state;
    let { GraphType } = this.props;
    try {
      let data = selectedParameter.map((deviceParameter) => {
        let spliteData = deviceParameter.split('@@');
        return {
          DeviceCode: spliteData[0],
          ParameterCode: spliteData[1]
        };
      });
      let payload = {
        Type: type,
        ViewType,
        ParameterList: data
        // GraphType
        // Operator :selectedOperator
      };
      if (selectedParameterType === 'Operational' && type !== 'live') {
        payload.Operator = selectedOperator;
        payload.GraphType = GraphType;
      }
      if (
        selectedParameterType === 'Status' &&
        type !== 'live' &&
        (selectedOperator === 'FIRST' || 'LAST' || 'RAW')
      ) {
        payload.Operator = selectedOperator;
        payload.GraphType = 'Table';
      }
      if (type === 'live') {
        payload.GraphType = GraphType;
      }
      if (type === 'historic') {
        payload.Interval = selectedInterval;
        payload.FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
      } else if (type === 'timeRange') {
        payload.FromDate1 = moment(selectedFromDate_ToDate[0]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate1 = moment(selectedFromDate_ToDate[1]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.FromDate2 = moment(selectedDateRangeComparision[0])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate2 = moment(selectedDateRangeComparision[1])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.Interval = selectedInterval;
      } else if (type === 'timeWithSelectionType') {
        payload.FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.Interval = selectedInterval;
        payload.SelectionType = CurrentTab;
      }
      return payload;
    } catch (error) {
      return {};
    }
  }

  hierarchyDropdownChange = (value) => {
    this.setState({ checkedValue: value });
  };

  onModalsOk = (e) => {
    let { checkedValue } = this.state;
    e.preventDefault();
    this.OnDeviceChange(checkedValue);
    this.setState({
      treeVisible: false
    });
  };

  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };
  OnDeviceChange = async (value) => {
    let val = [];
    val.push(value);
    let ParameterList = {};
    if (value.length === 6) {
      value.shift();
      ParameterList = await getParameterList(val);
    } else {
      ParameterList = await getParameterList(val);
    }
    this.setState({
      selectedDevice: value,
      ParameterList,
      update: false,
      ParameterListByType: [],
      selectedParameterType: undefined,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current.setFieldsValue({
      selectedDevice: value,
      ParameterList,
      update: false,
      ParameterListByType: [],
      selectedParameterType: undefined,
      selectedParameter: [],
      selectedOperator: undefined
    });
  };

  OnParameterChange = (value) => {
    if (this.state.type === 'timeRange' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (this.state.type === 'timeWithSelectionType' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (value.length === 11) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else {
      this.setState({ selectedParameter: value, update: false });
    }
  };

  OnOperatorChange = (value) => {
    this.setState({ selectedOperator: value });
  };

  OnParameterTypeChange = async (selectedParameterType) => {
    let { ParameterList, OperationalList, selectedOperator, type, GraphType } = this.state;
    // let { GraphType } = this.props;
    if (selectedParameterType === 'Status' && type !== 'live') {
      this.setState({ GraphType: 'Table' });
      GraphType = 'Table';
      OperationalList = await getOperationList(selectedParameterType, GraphType);
      this.setState({
        fieldVisible: true,
        OperationalList
      });
    }
    if (
      selectedParameterType !== 'Operational' ||
      (selectedParameterType === 'Status' && type === 'live')
    ) {
      this.setState({
        fieldVisible: false,
        selectedOperator: ''
      });
    } else if (selectedParameterType === 'Operational' && type !== 'live') {
      OperationalList = await getOperationList(selectedParameterType, GraphType);
      this.setState({
        fieldVisible: true,
        OperationalList,
        selectedOperator
      });
    }

    this.setState({
      ParameterListByType: ParameterList[selectedParameterType],
      selectedParameterType,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current.setFieldsValue({
      selectedParameterType,
      selectedParameter: [],
      selectedOperator: undefined
    });
  };

  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };

  OnTypeChange = (value) => {
    let ViewType =
      value && (value === 'timeRange' || value === 'timeWithSelectionType') ? 'Multiple' : 'Single';
    this.setState({
      type: value,
      trendData: [],
      tunedData: {},
      loading: false,
      currentSelectionTab: 'day',
      GraphType: 'line',
      ViewType,
      selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: []
    });
    this.formHeader.current.setFieldsValue({
      type: value,
      ViewType,
      selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: []
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onBookmarkSave = async () => {
    const siteId = localStorage.get('currentSite');
    let {
      type,
      tunedData,
      selectedDevice,
      ViewType,
      selectedOperator,
      selectedParameter,
      selectedParameterType,
      selectedInterval,
      Module,
      BookmarkName
    } = this.state;
    if (BookmarkName && BookmarkName.length > 0) {
      let payload = {
        Devices: selectedDevice,
        Interval: selectedInterval,
        Module: Module,
        Name: BookmarkName,
        Parameters: selectedParameter,
        ParameterType: selectedParameterType,
        Payload: tunedData ? tunedData : [],
        SiteId: siteId,
        Type: type,
        ViewType: ViewType,
        Operator: selectedOperator
      };
      let saveBookmarkResponse = await CreateTrend(payload);
      if (saveBookmarkResponse && saveBookmarkResponse.message) {
        message.success(saveBookmarkResponse.message);

        let BookmarkList = await getBookmarkList(Module);
        this.setState({
          isModalSaveBookmark: false,
          BookmarkList
        });
      }
    } else {
      message.warn('Please input Bookmark name');
    }
  };
  updateBookmark = async () => {
    const siteId = localStorage.get('currentSite');
    let {
      type,
      tunedData,
      selectedDevice,
      ViewType,
      selectedParameter,
      selectedParameterType,
      selectedInterval,
      Module,
      BookmarkName,
      Id,
      selectedOperator
    } = this.state;
    let payload = {
      Devices: selectedDevice,
      Interval: selectedInterval,
      Module: Module,
      Name: BookmarkName,
      Parameters: selectedParameter,
      ParameterType: selectedParameterType,
      Payload: tunedData ? tunedData : [],
      SiteId: siteId,
      Type: type,
      ViewType: ViewType,
      Id: Id,
      Operator: selectedOperator
    };
    let updateBookmarkResponse = await UpdateTrends(payload);
    if (updateBookmarkResponse && updateBookmarkResponse.message) {
      message.success(updateBookmarkResponse.message);
      let BookmarkList = await getBookmarkList(Module);
      this.setState({ BookmarkList });
    }
  };
  onBookmarkModalOpen = () => {
    this.setState({
      isModalSaveBookmark: true
    });
  };
  onBookmarkListOpen = () => {
    this.setState({
      isModalViewBookmark: true
    });
  };
  deleteBookmark = async (record) => {
    console.log('record', record);
    let { Module } = this.state;
    let deleteResponse = await DeleteTrend(record.Id);
    if (deleteResponse && deleteResponse.message) {
      message.success(this.translation(deleteResponse.message));
      let BookmarkList = await getBookmarkList(Module);
      this.setState({ BookmarkList });
    }
  };

  editBookmark = async (editObject) => {
    let { GraphType } = this.props;
    let val = [];
    val.push(editObject.Devices);
    let ParameterList = {};
    let OperationalList = {};
    let ParameterListByType = [];
    if (editObject.Devices) {
      ParameterList = await getParameterList(val);
      ParameterListByType = ParameterList[editObject.ParameterType];
      if (editObject.ParameterType === 'Status' && editObject.Type !== 'live') {
        OperationalList = await getOperationList(editObject.ParameterType, 'Table');
      } else if (editObject.ParameterType === 'Operational' && editObject.Type !== 'live') {
        OperationalList = await getOperationList(editObject.ParameterType, GraphType);
      }
    }
    this.setState({
      type: editObject.Type,
      selectedDevice: editObject.Devices,
      selectedParameter: editObject.Parameters,
      selectedParameterType: editObject.ParameterType,
      selectedInterval: editObject.Interval,
      selectedDateRangeComparision: [
        moment.utc(editObject.Payload.FromDate2, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate2, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      isModalViewBookmark: false,
      ViewType: editObject.ViewType,
      selectedOperator: editObject.Operator,
      Id: editObject.Id,
      BookmarkName: editObject.Name,
      selectedFromDate_ToDate: [
        moment.utc(editObject.Payload.FromDate1, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate1, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      selectedFromDate_ToDate1: [
        moment.utc(editObject.Payload.FromDate, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      ParameterList,
      ParameterListByType,
      OperationalList
    });
    this.formHeader.current.setFieldsValue({
      type: editObject.Type,
      selectedDevice: editObject.Devices,
      selectedParameter: editObject.Parameters,
      selectedParameterType: editObject.ParameterType,
      selectedInterval: editObject.Interval,
      selectedDateRangeComparision: [
        moment.utc(editObject.Payload.FromDate2, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate2, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      ViewType: editObject.ViewType,
      selectedOperator: editObject.Operator,
      selectedFromDate_ToDate: [
        moment.utc(editObject.Payload.FromDate1, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate1, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      selectedFromDate_ToDate1: [
        moment.utc(editObject.Payload.FromDate, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate, 'YYYY-MM-DD HH:mm:ss').local()
      ]
    });
  };

  render() {
    const {
      type,
      EquipmentList,
      selectedDevice,
      ViewType,
      selectedParameter,
      selectedOperator,
      selectedInterval,
      ParameterList,
      ParameterListByType,
      userProfile,
      selectedParameterType,
      BookmarkList,
      BookmarkName,
      fieldVisible,
      OperationalList
    } = this.state;
    let { GraphType } = this.state;
    const columns = [
      {
        title: this.translation('Bookmark Name'),
        dataIndex: 'Name',
        key: 'Name',
        width: 150
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type',
        width: 150
      },
      {
        title: this.translation('Devices'),
        dataIndex: 'Devices',
        key: 'Devices',
        width: 150
      },
      {
        title: this.translation('Parameters'),
        dataIndex: 'Parameters',
        key: 'Parameters',
        width: 150
      },
      {
        title: this.translation('Parameter Type'),
        dataIndex: 'ParameterType',
        key: 'ParameterType',
        width: 150
      },
      {
        title: this.translation('View Type'),
        dataIndex: 'ViewType',
        key: 'ViewType',
        width: 150
      },
      {
        title: this.translation('Actions'),
        dataIndex: '',
        key: '',
        width: 150,
        render: (text, record) => (
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://mew.bmecomosolution.com/rubus/ViewTrends/${this.state.Module}/${record.Id}`}
            >
              <EyeOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  this.editBookmark(record);
                }}
              />
            </a>
            <Divider type="vertical" />
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                this.editBookmark(record);
              }}
            />
            <Divider type="vertical" />
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                this.deleteBookmark(record);
              }}
            />
          </span>
        )
      }
    ];
    const items = [
      {
        key: this.state.Id ? 'update' : 'save',
        label: this.state.Id
          ? this.translation('Update bookmark')
          : this.translation('Save bookmark')
      },
      {
        key: 'list',
        label: this.translation('View bookmarks')
      }
    ];

    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'trends')
        .map((name) => permissionsArray.push(name.action));

    return (
      <>
        {BookmarkName ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '13px',
              fontSize: '16px'
            }}
          >
            {this.translation('Bookmark')}: {BookmarkName}
          </div>
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '10px' }}>
          <Dropdown
            menu={{
              items,
              selectable: true,
              onClick: (e) => {
                if (e.key === 'list') {
                  this.onBookmarkListOpen();
                } else if (e.key === 'save') {
                  this.formHeader.current
                    .validateFields()
                    .then(async () => {
                      this.onBookmarkModalOpen();
                    })
                    .catch(() => {
                      message.warn(
                        this.translation('Please input mandatory fields to save bookmark')
                      );
                    });
                } else if (e.key === 'update') {
                  this.formHeader.current
                    .validateFields()
                    .then(async () => {
                      this.updateBookmark();
                    })
                    .catch(() => {
                      message.warn(
                        this.translation('Please input mandatory fields to update bookmark')
                      );
                    });
                }
              }
            }}
          >
            <Typography.Link>
              <Space>
                {this.translation(constant.Bookmark_Options)}
                <DownOutlined />
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>

        <Form ref={this.formHeader} initialValues={this.state} layout="vertical">
          <Form.Item
            name={'type'}
            label={this.translation(constant.Select_Trend_Type)}
            rules={[{ required: true, message: this.translation('Please Select TrendType!') }]}
          >
            <Select
              placeholder={this.translation(constant.Select_Trend_Type)}
              onChange={this.OnTypeChange}
            >
              <Option value="live">{this.translation('Live Trend')}</Option>
              <Option value="historic">{this.translation('Historical Trend')}</Option>
              <Option value="timeRange">
                {this.translation('Trend between two different TimeRanges')}
              </Option>
              <Option value="timeWithSelectionType">
                {this.translation('Trend based Day Week Month Year Comparision')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={'selectedDevice'}
            label={this.translation(constant.Select_Device)}
            rules={[{ required: true, message: this.translation('Please Select Equipment!') }]}
          >
            <span
              style={{
                // marginRight: '12%',
                // width: '350px',
                display: 'flex',
                justifyContent: 'flex-start'
                // flexFlow: 'row wrap'
              }}
            >
              <Select
                style={{
                  width: '90%'
                }}
                value={selectedDevice}
                mode="multiple"
                placeholder={this.translation(constant.Select_Device)}
                size="default"
                disabled={true}
              >
                {EquipmentList &&
                  Array.isArray(Object.keys(EquipmentList)) &&
                  Object.keys(EquipmentList).map((device, index) => {
                    return (
                      <Option key={index} value={EquipmentList[device].DeviceCode}>
                        {this.translation(EquipmentList[device].Name)}
                      </Option>
                    );
                  })}
              </Select>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ treeVisible: true });
                }}
              >
                <UpSquareOutlined />
              </Button>
            </span>
          </Form.Item>

          <Form.Item
            name={'selectedParameterType'}
            label={this.translation(constant.Select_ParameterType)}
            rules={[{ required: true, message: this.translation('Please Select Parameter Type!') }]}
          >
            <Select
              value={selectedParameterType}
              placeholder={this.translation(constant.Select_ParameterType)}
              onChange={this.OnParameterTypeChange}
            >
              {ParameterList &&
                Array.isArray(Object.keys(ParameterList)) &&
                Object.keys(ParameterList).map((parameterType) => {
                  return <Option key={parameterType}>{this.translation(parameterType)}</Option>;
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name={'selectedParameter'}
            label={this.translation(constant.Select_Parameter)}
            rules={[{ required: true, message: this.translation('Please Select Parameter!') }]}
          >
            <Select
              showSearch
              mode="multiple"
              value={selectedParameter}
              onChange={this.OnParameterChange}
              placeholder={this.translation(constant.Select_Parameter)}
            >
              {ParameterListByType &&
                Array.isArray(ParameterListByType) &&
                ParameterListByType.map((param, index) => {
                  return (
                    <Option key={index} value={`${param.Device}@@${param.ParameterCode}`}>
                      {`${this.translation(param.DeviceName)}=>${this.translation(
                        param.ParameterDescription
                      )}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {(type !== 'live' && fieldVisible === true) ||
          (selectedParameterType === 'Status' && GraphType === 'Table' && type !== 'live') ? (
            <Form.Item
              name={'selectedOperator'}
              label={this.translation(constant.Select_Operator)}
              rules={[{ required: true, message: this.translation('Please Select Operator !') }]}
            >
              <Select
                // showSearch
                value={selectedOperator}
                onChange={this.OnOperatorChange}
                placeholder={this.translation(constant.Select_Operator)}
              >
                {OperationalList &&
                  Array.isArray(OperationalList) &&
                  OperationalList.map((operator, index) => {
                    return (
                      <Option key={index} value={operator.Key}>
                        {this.translation(operator.Value)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          ) : null}

          {type === 'historic' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate1'}
                label={this.translation(constant.Select_Range)}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate1}
                  onChange={this.onChangeFromDate_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation(constant.Interval)}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation(constant.Interval)}
                  value={selectedInterval}
                  onChange={(selectedInterval) => {
                    this.setState({ selectedInterval });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {this.translation(item.key)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type === 'timeRange' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate'}
                label={this.translation(constant.Select_Range)}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate}
                  onChange={this.onChangeFromDate_ToDate}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedDateRangeComparision'}
                label={this.translation(constant.Select_Range_Comparision)}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedDateRangeComparision}
                  onChange={this.onChangeFromDate1_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation(constant.Interval)}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation(constant.Interval)}
                  value={selectedInterval}
                  onChange={(value) => {
                    this.setState({ selectedInterval: value });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {this.translation(item.key)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type === 'timeWithSelectionType' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate1'}
                label={this.translation(constant.Select_Range)}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate1}
                  onChange={this.onChangeFromDate_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation(constant.Interval)}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation(constant.Interval)}
                  value={selectedInterval}
                  onChange={(value) => {
                    this.setState({ selectedInterval: value });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {this.translation(item.key)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type !== 'timeWithSelectionType' ? (
            <>
              <Form.Item
                name={'ViewType'}
                label={this.translation(constant.ViewType)}
                rules={[{ required: true, message: this.translation('Please Select ViewType!') }]}
              >
                <Select
                  placeholder={this.translation(constant.ViewType)}
                  value={ViewType}
                  onChange={(ViewType) => {
                    this.setState({ ViewType });
                  }}
                >
                  <Option key="Single" value="Single">
                    {this.translation('Single')}
                  </Option>
                  <Option key="Multiple" value="Multiple">
                    {this.translation('Multiple')}
                  </Option>
                </Select>
              </Form.Item>
            </>
          ) : null}
          <div>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!permissionsArray.includes('read') ? true : false}
              onClick={() => {
                this.OnGenerateTrendData();
              }}
            >
              {this.translation(constant.Generate_Trend)}
            </Button>
            <Button
              type="dashed"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                this.OnResetFields();
              }}
            >
              {this.translation(constant.Reset)}
            </Button>
          </div>
        </Form>
        <Modal
          title={this.translation(constant.Save_Bookmark)}
          open={this.state.isModalSaveBookmark}
          onOk={this.onBookmarkSave}
          onCancel={() => this.setState({ isModalSaveBookmark: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ isModalSaveBookmark: false })}>
              {this.translation('Cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.onBookmarkSave}>
              {this.translation(constant.Save_Bookmark)}
            </Button>
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              name={'BookmarkName'}
              label={this.translation(constant.BookmarkName)}
              rules={[{ required: true, message: this.translation('Input Bookmark!') }]}
            >
              <Input onChange={(e) => this.setState({ BookmarkName: e.target.value })}></Input>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          width={'900'}
          title={this.translation('View Bookmarks')}
          open={this.state.isModalViewBookmark}
          onOk={() => this.setState({ isModalViewBookmark: false })}
          onCancel={() => this.setState({ isModalViewBookmark: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ isModalViewBookmark: false })}>
              {this.translation('Cancel')}
            </Button>
          ]}
        >
          <Table dataSource={BookmarkList} columns={columns} scroll={{ x: 120, y: 550 }} />
        </Modal>
        <StyledModal
          open={this.state.treeVisible}
          width={800}
          closable
          title={this.translation('Equipment')}
          okText={this.translation('Add')}
          onOk={this.onModalsOk}
          cancelText={this.translation('Cancel')}
          onCancel={this.onModalCancel}
        >
          <HierarchyModal
            value={this.state.deviceId}
            selectedDevice={selectedDevice ? selectedDevice : []}
            treeData={Object.values(EquipmentList)}
            Key="Trends"
            handleDropdownChange={this.hierarchyDropdownChange}
          />
        </StyledModal>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FilterComponent);
