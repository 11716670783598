import localStorage from '../../../../utils/localStorage';
import axios from 'axios';

const token = localStorage.get('accessToken');
var Authorization = 'Bearer ' + token;
export async function getTableNames(selectSchema) {
  let siteId = localStorage.get('currentSite');
  let data = await fetch(
    `/api/queryBuilder/getTablesList?SiteId=${siteId}&schema=${selectSchema}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      // if(error.response.data.name === 'TokenExpiredError'){
      //   jwtToken(error.response.data.message)
      // }
    });

  return data === undefined ? [] : data;
}
export async function getSchemaList() {
  let siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/queryBuilder/getSchema?SiteId=${siteId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      // if(error.response.data.name === 'TokenExpiredError'){
      //   jwtToken(error.response.data.message)
      // }
    });

  return data === undefined ? [] : data;
}
export async function getList(payload) {
  let siteId = localStorage.get('currentSite');
  let data = await fetch(
    `/api/queryBuilder/getFunctionsDropdown?SiteId=${siteId}&Type=${payload.Type}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      // if(error.response.data.name === 'TokenExpiredError'){
      //   jwtToken(error.response.data.message)
      // }
    });

  return data === undefined ? [] : data;
}
export async function getOperatorList() {
  const url = `/api/queryBuilder/operatorList`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: Authorization
    }
  };
  fetch(url, options)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function createQuery(payload) {
  const siteId = localStorage.get('currentSite');
  const options = {
    method: 'POST',
    url: `/api/queryBuilder/createQuery?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(options)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
  // fetch(url, options)
  //   .then((response) => {
  //     response.text().then(function (text) {
  //       return text;
  //       // do something with the text response
  //     });

  //   })
  //   // .then((json) => {
  //   //   return json;
  //   // })
  //   .catch(() => {
  //   });
}
