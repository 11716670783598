import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import DeviceListComponent from './DeviceListComponent';
import { getAllAssetList } from '../../../selectors/asset';
import { getDeviceListSelector, getTagReferenceListSelector } from '../../../selectors/device';
import { getDeviceTypeListSelector } from '../../../selectors/deviceType';
import {
  getAllDeviceList,
  createDevice,
  updateDevice,
  deleteDevice,
  getTagReferenceList,
  getConditionList
} from '../../../modules/device/device.duck';
import { getDeviceTypeList } from '../../../modules/deviceType/deviceType.duck';
import { getAssetDeviceCache } from '../../../modules/asset/asset.duck';

const mapStateToProps = createStructuredSelector({
  asset: getAllAssetList(),
  deviceList: getDeviceListSelector(),
  deviceTypeList: getDeviceTypeListSelector(),
  tagReferenceList: getTagReferenceListSelector()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createDevice,
        updateDevice,
        deleteDevice,
        getTagReferenceList,
        getConditionList,
        getDeviceTypeList,
        getAssetDeviceCache,
        getAllDeviceList
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListComponent);
