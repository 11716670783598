import React from 'react';

import LoginComponent from './LoginContainer';

import { LoginPageBackground, LoginBox, LoginSection, Login } from './styles';

const LoginPage = (props) => {
  document.title = 'BMecomo-MEW';
  return (
    <Login>
      <LoginSection>
        <LoginPageBackground style={{ backgroundImage: `url('covers/rubus_login.jpg')` }} />
        <LoginBox>
          <LoginComponent
            reDirectPath={props && props.location.state ? props.location.state : ''}
          />
        </LoginBox>
      </LoginSection>
    </Login>
  );
};

export { LoginPage };
