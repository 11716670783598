import React, { Fragment } from 'react';
import moment from 'moment';
import { Tag, Cascader, List, Card } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
// import { excelDownloadUrl } from "./ExcelAdapters/const";
import { selectImageMock } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';
import '../../CSS/style.css';

class SelectImageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      images: [],
      imagesList: [],
      translationCache: props.translationCache || [],
      optionsList: [],
      data: [],
      value: [],
      selectedData: {},
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }

    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  imageData = (imagesList) => {
    let images = [];
    if (imagesList && Array.isArray(imagesList) && imagesList.length > 0) {
      images = imagesList.map((src) => {
        return src.imageUrl;
      });
      this.setState({
        images
      });
    }
  };
  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      isViewerOpen: true
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      isViewerOpen: false
    });
  };
  getData = async () => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = selectImageMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (json) {
        // this.imageData(json)
        let optionsList = json && json.options;
        this.setState({
          optionsList: json && json.options,
          data: json && json.data
          // imagesList: json
          // percent:50
        });
        let value = [];
        if (
          optionsList &&
          optionsList[0] &&
          optionsList[0].value &&
          optionsList[0].children &&
          optionsList[0].children[0] &&
          optionsList[0].children[0].value
        ) {
          value = [optionsList[0].value, optionsList[0].children[0].value];
          this.onChange(value);
          this.setState({
            value,
            refreshDateTime: moment()
          });
        }
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  // componentWillUnmount() {
  //   clearInterval(this.timeTicket);
  // }
  filter = (inputValue, path) => {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };
  onChange = (value) => {
    const { data } = this.state;
    data &&
      Array.isArray(data) &&
      data.length > 0 &&
      data.map((selectData) => {
        if (
          selectData &&
          selectData.parentKey &&
          selectData.parentKey === value[0] &&
          selectData.childKey === value[1]
        ) {
          this.setState({ selectedData: selectData, value });
        }
        return {};
      });
  };
  render() {
    const colors = [
      'steelblue',
      '#a0d911',
      '#13c2c2',
      '#faad14',
      '#c41d7f',
      '#006d75',
      '#eb2f96',
      'steelblue',
      '#a0d911',
      '#13c2c2',
      '#faad14',
      '#c41d7f',
      '#006d75',
      // "#f5222d",
      // "#faad14",

      '#eb2f96',
      'steelblue',
      '#a0d911',
      '#13c2c2',
      '#faad14',
      '#c41d7f',
      '#006d75',
      '#eb2f96',
      'steelblue',
      '#a0d911',
      '#13c2c2',
      '#faad14',
      '#c41d7f',
      '#006d75',
      '#eb2f96'
    ];

    let { optionsList, selectedData, refreshDateTime, errorInAPI, noDataInDB } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <Fragment>
            <Cascader
              // defaultValue={optionsList && optionsList[0] && optionsList[0].value && optionsList[0].children &&
              //   optionsList[0].children[0] &&optionsList[0].children[0].value ? [optionsList[0].value,optionsList[0].children[0].value] :[]}
              value={this.state.value}
              options={optionsList}
              onChange={this.onChange}
              placeholder="Please select"
              showSearch={this.filter}
            />
            <div>
              {' '}
              <div style={{ width: '40%', float: 'left' }}>
                {selectedData &&
                  selectedData.imagesList &&
                  Array.isArray(selectedData.imagesList) &&
                  selectedData.imagesList.length > 0 &&
                  selectedData.imagesList.map((src, index) => (
                    <img
                      src={src}
                      width="300"
                      height="300"
                      key={index}
                      style={{ margin: '2px' }}
                      alt=""
                    />
                  ))}
              </div>
              {/* { selectedData && selectedData.truckDetails && Array.isArray(selectedData.truckDetails) && selectedData.truckDetails.map((item)=>( */}
              <div style={{ width: '60%', float: 'left' }}>
                {/* <List
 itemLayout="horizontal"
 dataSource={selectedData.truckDetails}
 renderItem={(item, index) => (
   <List.Item>
     <List.Item.Meta
       style={{
         marginLeft: "30px",
       }}
       title={
         <span style={{ color: colors[index], fontSize: "30px" }}>
           {item.title}
         </span>
       }
     />

     <div style={{ color: colors[index], fontSize: "30px" }}>
       {item.value}
     </div>
   </List.Item> */}
                <List
                  grid={{
                    gutter: 10,
                    column: 4
                  }}
                  dataSource={selectedData.truckDetails}
                  style={{
                    textAlign: 'center',
                    textColor: '#fff !important',
                    fontSize: '2.5em'
                  }}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Card
                        className="selectImage"
                        title={item.title}
                        style={{
                          backgroundColor: colors[index],
                          fontSize: '16px',
                          fontWeight: 'bold'
                        }}
                      >
                        {' '}
                        {item.value}
                      </Card>
                      {/* <Card title={item.title} style={{ backgroundColor: colors[index],fontSize: "1.5em"}}>  <Card.Grid style={gridStyle}>{item.value}</Card.Grid></Card> */}
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Fragment>
        )}

        {/* {
          reportName && excelDownload === "true" ? (
            <a
              href={`${excelDownloadUrl}/${reportName}`}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              <Icon
                type="download"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginRight: "25px",
                  marginTop: "6px",
                }}
              />
            </a>
          ) : null
        } */}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(SelectImageDetails);
