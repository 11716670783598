/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, message, DatePicker, TimePicker } from 'antd';

import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitJobPlan,
  getJobPlanById
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
import { StyledButton } from '../../../CSS/style';
class SchedulingWorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      translationCache: props.translationCache || {}
    };
  }
  taskRef = React.createRef();
  schedulingWorkOrderRef = React.createRef();

  componentDidMount = async () => {
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.schedulingWorkOrderRef.current.setFieldsValue(this.props.formObject);
      this.taskRef.current.setFieldsValue(this.props.formObject.TaskData);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : []
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    this.schedulingWorkOrderRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };

  onFinish = async (values) => {
    let { formObject } = this.state;
    let payload = {
      ...formObject,
      ...values
    };
    let statusResponse = await submitJobPlan(payload);

    if (statusResponse && statusResponse.message) {
      this.props._getAdhocDataSource();
      message.success(statusResponse.message);
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.taskRef.current.resetFields();
    this.schedulingWorkOrderRef.current.resetFields();
  };
  jobPlanOnChange = async (value) => {
    let formObject = await getJobPlanById(value);
    let recordData = formObject.TaskData;
    this.setState({ formObject, recordData, selectedJobPaln: value });
  };
  render() {
    let { formObject, translationCache } = this.state;
    return (
      <>
        <Form
          theme={themeSettings}
          onFinish={(values) => this.props.onFinish(values, 'SchedulingWorkOrder')}
          name={'SchedulingWorkOrder'}
          ref={this.schedulingWorkOrderRef}
          className="jobplan"
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={translation(translationCache, constant.targetStart)}
              name="TargetStart"
              onChange={(value) => this.handleChange(value, 'TargetStart')}
              rules={[{ required: false, message: 'Please Input TargetStart!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                style={{ width: '70%' }}
                format="YYYY-MM-DD HH:mm:ss"
                // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.targetFinish)}
              name="TargetFinish"
              onChange={(value) => this.handleChange(value, 'TargetFinish')}
              rules={[{ required: false, message: 'Please Input TargetFinish!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                style={{ width: '70%' }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.schedulingStart)}
              name="SchedulingStart"
              onChange={(value) => this.handleChange(value, 'SchedulingStart')}
              rules={[{ required: false, message: 'Please Input SchedulingStart!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '70%' }}
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.schedulingFinish)}
              name="SchedulingFinish"
              onChange={(value) => this.handleChange(value, 'SchedulingFinish')}
              rules={[{ required: false, message: 'Please Input SchedulingFinish!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                style={{ width: '70%' }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.actualStart)}
              name="ActualStart"
              onChange={(value) => this.handleChange(value, 'ActualStart')}
              rules={[{ required: false, message: 'Please Input ActualStart!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                style={{ width: '70%' }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.actualFinish)}
              name="ActualFinish"
              onChange={(value) => this.handleChange(value, 'ActualFinish')}
              rules={[{ required: false, message: 'Please Input ActualFinish!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                placeholder={translation(translationCache, constant.selectDate)}
                style={{ width: '70%' }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.duration)}
              name="Duration"
              onChange={(value) => this.handleChange(value, 'Duration')}
              rules={[{ required: false, message: 'Please Input Duration!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <TimePicker
                placeholder={translation(translationCache, constant.selecttime)}
                style={{ width: '70%' }}
              />
            </Form.Item>

            <Form.Item
              label={translation(translationCache, constant.sequence)}
              name="subSequence"
              onChange={(e) => this.handleChange(e.target.value, 'Sequence')}
              rules={[{ required: false, message: 'Please Provide Sequence' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Sequence')}
                style={{ width: '70%' }}
              />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <StyledButton theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.save)}
              </StyledButton>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(SchedulingWorkOrder);
