import React from 'react';
import axios from 'axios';
import {
  Table,
  Form,
  Col,
  Input,
  Drawer,
  Button,
  Card,
  Row,
  message,
  Popconfirm,
  Tree
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { constant } from '../../Settings/Constants';
import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../.././../selectors/language';
import { DrawerFooter, StyledTable, lightTheme, StyledDashboard } from './style';
import './style.css';

const { TreeNode } = Tree;

class Hierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      treeData: [],
      modalVisible: false,
      translationCache: props.translationCache || []
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    this._getHierarchyList();
  }

  _getHierarchyList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'GET',
      url: `/api/hierarchymodule/mainHierarchyList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ datasource: data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editHierarchy = (editObj) => {
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  _createHierarchy = () => {
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        this._createHierarchyAPI(values);
      })
      .catch(() => {});
  };

  _updateHierarchy = () => {
    let { editObj } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        let updateObject = {
          ...values,
          Id: editObj.Id
        };
        this._updateHierarchyAPI(updateObject);
      })
      .catch(() => {});
  };

  _createHierarchyAPI = (createObject) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgCreateHeaders = {
      method: 'POST',
      url: `/api/hierarchymodule/mainHierarchyCreate?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...createObject, SiteId: siteId }
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Saved');
          this._getHierarchyList();
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _updateHierarchyAPI = (updateObject) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `/api/hierarchymodule/mainHierarchyUpdate?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Updated');
          this._getHierarchyList();
          this.setState({ visible: false, editObj: {} });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _deleteHierarchy = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgDeleteHeaders = {
      method: 'POST',
      url: `/api/hierarchymodule/mainHierarchyDelete?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Hierarchy Deleted');
          this._getHierarchyList();
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openHierarchyForm = (record) => {
    history.push(`/rubus/settingsPage/Hierarchy/${record.Id}`);
  };

  _getHierarchyLevels = (record) => {
    this.setState({ modalVisible: true });
    let payload = {
      HierarchyId: record.Id
    };
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevels?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ treeData: data, modalVisible: true });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.Name} key={item.Id} autoExpandParent={true} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.Id} title={item.Name} {...item} />;
    });

  onClose = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({ modalVisible: false, editObj: {} });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    let { datasource, visible, editObj, modalVisible, treeData } = this.state;
    const columns = [
      {
        title: this.translation('Hierarchy Name'),
        dataIndex: 'HierarchyName'
      },
      {
        title: this.translation(constant.Hierarchy_Code),
        dataIndex: 'HierarchyCode'
      },
      {
        title: this.translation(constant.Action),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editHierarchy(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
            <Popconfirm
              title={this.translation(constant.Are_you_sure_delete_Hierarchy)}
              onConfirm={(e) => {
                e.stopPropagation();
                this._deleteHierarchy(record);
              }}
              okText={this.translation(constant.yes)}
              cancelText={this.translation(constant.no)}
            >
              <button
                type="button"
                className="ant-btn"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DeleteOutlined />
              </button>
            </Popconfirm>
            <button
              type="button"
              onClick={() => this.openHierarchyForm(record)}
              style={{ marginLeft: '10px' }}
              className="ant-btn"
            >
              {this.translation(constant.Click_to_redirect)}
            </button>
            <button
              type="primary"
              className="ant-btn"
              style={{ marginLeft: '10px' }}
              onClick={() => this._getHierarchyLevels(record)}
            >
              {this.translation(constant.View)}
            </button>
          </div>
        )
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Drawer visible={modalVisible} closable onClose={this.onClose} width={600}>
          <Tree defaultExpandAll={true} showLine>
            treeData && Array.isArray(treeData) && treeData.length != 0 ?
            {this.renderTreeNodes(treeData)}: null
          </Tree>
        </Drawer>
        <Card bordered={false}>
          <Drawer
            title={this.translation(constant.Create_Hierarchy)}
            visible={visible}
            width={600}
            closable
            onClose={this._cancel}
          >
            <Form
              layout="vertical"
              name={'HierarchyForm'}
              ref={this.hRef}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              initialValues={editObj || {}}
              onFinishFailed={this.onFinishFailed}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={this.translation(constant.Hierarchy_Name)} name="HierarchyName">
                    <Input placeholder={this.translation('Please enter Hierarchy name')} />
                  </Form.Item>

                  <Form.Item label={this.translation(constant.Hierarchy_Code)} name="HierarchyCode">
                    <Input placeholder={this.translation('please enter Hierarchy Code')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <DrawerFooter>
              <Button onClick={this._cancel} style={{ marginRight: 8 }}>
                {this.translation(constant.Cancel)}
              </Button>
              {!editObj.Id ? (
                <Button type="primary" onClick={() => this._createHierarchy()}>
                  {this.translation(constant.Create)}
                </Button>
              ) : (
                <Button type="primary" onClick={() => this._updateHierarchy()}>
                  {this.translation(constant.Update)}
                </Button>
              )}
            </DrawerFooter>
          </Drawer>
          <Button
            type="primary"
            size={'large'}
            icon={<PlusOutlined />}
            style={{ marginBottom: '10px' }}
            onClick={this.openModal}
          >
            {this.translation(constant.Add)}
          </Button>
          <Button
            type="primary"
            size={'large'}
            style={{ marginBottom: '10px', marginLeft: '10px' }}
            onClick={() => history.push('/rubus/settingsPage/RoleBaseHierarchy')}
          >
            {this.translation('Click to provide hierarchy permission to roles')}
          </Button>
          <StyledTable theme={lightTheme}>
            <Table
              className="basictable"
              columns={columns}
              dataSource={datasource}
              pagination={
                datasource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </StyledTable>
        </Card>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Hierarchy);
