import React from 'react';
import axios from 'axios';
import {
  Table,
  Form,
  Col,
  Input,
  Drawer,
  Button,
  Card,
  Row,
  message,
  Popconfirm,
  Upload,
  Select
} from 'antd';
import { InboxOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { getTranslationCache } from '../.././../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DrawerFooter, StyledTable, lightTheme, StyledDashboard } from './style';
import './style.css';
import { constant } from '../../Settings/Constants';
const { Option } = Select;
class NestedHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      HierarchyName: '',
      ParentId: undefined,
      parentHierarchy: [],
      modalVisible: false,
      deviceList: [],
      selectedObject: {},
      value: [],
      translationCache: props.translationCache || []
    };
    this.nhRef = React.createRef();
  }

  componentDidMount() {
    const pathSnippets = this.props.history.location.pathname
      .split('/rubus/settingsPage/Hierarchy/')
      .filter((i) => i);
    if (pathSnippets && pathSnippets[0]) {
      let hierarchyLevel = pathSnippets[0].split('/').filter((i) => i);
      let parentObject = this.props.history.location.state
        ? this.props.history.location.state
        : null;
      let ParentId = parentObject && parentObject.Id ? parentObject.Id : null;
      this._getHierarchyList(hierarchyLevel[0] || null, ParentId);
      this.setState({ HierarchyName: hierarchyLevel[0], parentObject, ParentId });
    }
  }

  _getHierarchyList = (HierarchyName, ParentId) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getById?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ParentId,
        HierarchyId: HierarchyName
      }
    };
    axios(headers)
      .then(async ({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            datasource: data,
            HierarchyName,
            ParentId
          });
        }
      })
      .catch(function () {
        return [];
      });
  };

  openModal = () => {
    if (this.nhRef.current) {
      this.nhRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.nhRef.current) {
      this.nhRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editHierarchy = (editObj) => {
    if (this.nhRef.current) {
      this.nhRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  _createHierarchy = (e) => {
    const { HierarchyName, ParentId } = this.state;
    const siteId = localStorage.get('currentSite');
    e.preventDefault();
    this.nhRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values,
          HierarchyType: values.Next && values.Next === true ? 0 : 1,
          HierarchyId: HierarchyName,
          ParentId,
          SiteId: siteId
        };
        this._createHierarchyAPI(payload);
      })
      .catch(() => {});
  };

  _updateHierarchy = (e) => {
    e.preventDefault();
    let { editObj } = this.state;
    this.nhRef.current
      .validateFields()
      .then(async (values) => {
        let updateObject = {
          ...values,
          HierarchyType: values.Next && values.Next === true ? 0 : 1,
          Id: editObj.Id
        };
        this._updateHierarchyAPI(updateObject);
      })
      .catch(() => {});
  };

  _createHierarchyAPI = (createObject) => {
    let { HierarchyName, parentObject } = this.state;
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgCreateHeaders = {
      method: 'POST',
      url: `/api/hierarchymodule/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: createObject
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Saved');
          this._getHierarchyList(
            HierarchyName,
            parentObject && parentObject.Id ? parentObject.Id : null
          );

          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _updateHierarchyAPI = (updateObject) => {
    let { HierarchyName, editObj, parentObject } = this.state;
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgUpdateHeaders = {
      method: 'POST',
      url: `/api/hierarchymodule/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ...editObj,
        ...updateObject
      }
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Updated');
          this._getHierarchyList(
            HierarchyName,
            parentObject && parentObject.Id ? parentObject.Id : null
          );
          this.setState({ visible: false, editObj: {} });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _deleteHierarchy = (payload) => {
    let { HierarchyName, parentObject } = this.state;
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgDeleteHeaders = {
      method: 'POST',
      url: `/api/hierarchymodule/delete?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Hierarchy Deleted');
          this._getHierarchyList(
            HierarchyName,
            parentObject && parentObject.Id ? parentObject.Id : null
          );
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  nextLevel = (record) => {
    let { HierarchyName, parentHierarchy, ParentId } = this.state;
    this._getHierarchyList(HierarchyName, record && record.Id ? record.Id : null);

    parentHierarchy.push(ParentId);
    history.push({
      pathname: `/rubus/settingsPage/Hierarchy/${HierarchyName}/${record.Name}`,
      state: record
    });
    this.setState({
      parentObject: record,
      parentHierarchy
    });
  };

  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXLXS =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXLXS) {
      message.error('You can only upload xlxs file!');
    }
    if (!isLt2M) {
      message.error('XLXS file must be smaller than 10MB!');
    }
    return isLt2M && isXLXS;
  };
  openDeviceModal = () => {
    this.deviceChange([]);
    this._getDeviceList(this.state.parentObject);
    this.setState({ modalVisible: true });
  };
  _getDeviceList = (parentObject) => {
    const SiteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthHeaders = {
      method: 'GET',
      url: `/api/device/get?SiteId=${SiteId}&ParentEquipment=${
        parentObject && parentObject.MainId ? parentObject.MainId : null
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(healthHeaders)
      .then((response) => {
        this.setState({ deviceList: response.data });
      })
      .catch(() => {});
  };
  onOk = () => {
    const { selectedObject } = this.state;
    this.setState({ modalVisible: false });
    this._createHierarchyAPI(selectedObject);
  };
  deviceChange = (value) => {
    const { deviceList, HierarchyName, parentObject } = this.state;

    let data = [];
    data =
      value &&
      value.map((item) => {
        let innerObject = deviceList.map((selectedDevice) => {
          if (selectedDevice.Id === item) {
            return {
              Name: selectedDevice.Name,
              Code: selectedDevice.DeviceCode,
              ShortDescription: 'This is ' + selectedDevice.Name + ' Equipment',
              LongDescription: 'This is ' + selectedDevice.Name + ' Equipment',

              HierarchyId: HierarchyName,
              ParentId: parentObject && parentObject.Id ? parentObject.Id : null,
              DeviceId: 1,
              Type: 'Device',

              MainId: selectedDevice.Id,
              Next: false,
              HierarchyType: 1
            };
          } else {
            return undefined;
          }
        });
        innerObject = innerObject.filter((element) => {
          return element !== undefined;
        });
        return innerObject && innerObject[0] ? innerObject[0] : {};
      });
    let payload = {
      data,
      deviceSelection: true
    };
    this.setState({ selectedObject: payload, value });
  };

  _OnCancel = () => {
    if (this.nhRef.current) {
      this.nhRef.current.resetFields();
    }
    this.setState({
      modalVisible: false,
      editObj: {}
    });
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let {
      datasource,
      visible,
      editObj,
      parentHierarchy,
      ParentId,
      HierarchyName,
      modalVisible,
      deviceList
    } = this.state;

    const columns = [
      {
        title: this.translation('Name'),
        dataIndex: 'Name'
      },
      {
        title: this.translation('Code'),
        dataIndex: 'Code'
      },
      {
        title: this.translation('Short Description'),
        dataIndex: 'ShortDescription'
      },
      {
        title: this.translation('Long Description'),
        dataIndex: 'LongDescription'
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editHierarchy(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
            <Popconfirm
              title={this.translation('Are you sure delete Hierarchy ?')}
              onConfirm={(e) => {
                e.stopPropagation();
                this._deleteHierarchy(record);
              }}
              okText={this.translation(constant.yes)}
              cancelText={this.translation(constant.no)}
            >
              <button type="button" className="ant-btn">
                <span>
                  <DeleteOutlined />
                </span>
              </button>
            </Popconfirm>
            {record.Next === true ? (
              <button
                type="button"
                onClick={() => this.nextLevel(record)}
                style={{ marginLeft: '10px' }}
                className="ant-btn"
              >
                {this.translation('Next Level')}
              </button>
            ) : null}
          </div>
        )
      }
    ];

    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Drawer visible={modalVisible} onClose={this._OnCancel} closable>
          <Form.Item label={this.translation('Device')} name="Device">
            <Select
              // showSearch
              id=""
              mode="multiple"
              placeholder={this.translation('Please Select Equipment')}
              style={{ width: 350 }}
              value={this.state.value}
              onChange={(value) => this.deviceChange(value)}
              // optionFilterProp="children"
              // filterOption={(input, option) =>{
              //   return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              //  }
              //   }
            >
              {deviceList &&
                Array.isArray(deviceList) &&
                deviceList.map((columns, index) => {
                  return (
                    <Option key={index} value={columns.Id}>
                      {columns.Name}-{columns.DeviceCode}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <DrawerFooter>
            <Button type={'primary'} shape="round" onClick={() => this.onOk()}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
        <Card bordered={false}>
          <Drawer
            title={this.translation('Create Hierarchy')}
            visible={visible}
            width={600}
            closable
            onClose={this._cancel}
          >
            <Form
              layout="vertical"
              name={'NestedHierarchyForm'}
              ref={this.nhRef}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              initialValues={editObj || {}}
              onFinishFailed={this.onFinishFailed}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={this.translation('Name')} name="Name">
                    <Input placeholder={this.translation('Enter name')} />
                  </Form.Item>

                  <Form.Item label={this.translation('Code')} name="Code">
                    <Input placeholder={this.translation('Enter Hierarchy Code')} />
                  </Form.Item>
                  <Form.Item label={this.translation('Short Description')} name="ShortDescription">
                    <Input placeholder={this.translation('Enter Short Description')} />
                  </Form.Item>
                  <Form.Item label={this.translation('Long Description')} name="LongDescription">
                    <Input placeholder={this.translation('Enter Long Description')} />
                  </Form.Item>
                  <Form.Item label={this.translation('Type')} name="Type">
                    <Input placeholder={this.translation('Enter Type')} />
                  </Form.Item>
                  <Form.Item label={this.translation('Enable Next Level')} name="Next">
                    <Select>
                      <Option value={true}>{this.translation('True')}</Option>
                      <Option value={false}>{this.translation('False')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <DrawerFooter>
              <Button onClick={this._cancel} style={{ marginRight: 8 }}>
                {this.translation('Cancel')}
              </Button>
              {!editObj.Id ? (
                <Button type="primary" onClick={this._createHierarchy}>
                  {this.translation('Create')}
                </Button>
              ) : (
                <Button type="primary" onClick={this._updateHierarchy}>
                  {this.translation('Update')}
                </Button>
              )}
            </DrawerFooter>
          </Drawer>

          <Button
            type="primary"
            size={'large'}
            style={{ marginBottom: '10px', float: 'right' }}
            onClick={this.openModal}
          >
            <PlusOutlined />
            {this.translation('Add')}{' '}
          </Button>

          <Button
            type="primary"
            size={'large'}
            style={{ marginBottom: '10px', float: 'right', marginRight: '10px' }}
            onClick={() => this.openDeviceModal()}
          >
            {this.translation('Device Selection')}
          </Button>

          <Button
            onClick={() => {
              history.goBack();
              this._getHierarchyList(HierarchyName, parentHierarchy[parentHierarchy.length - 1]);
              parentHierarchy.splice(-1, 1);
              this.setState({ parentHierarchy });
            }}
          >
            {this.translation('Go back')}
          </Button>
          <StyledTable theme={lightTheme}>
            <Table
              className="basictable"
              columns={columns}
              dataSource={datasource}
              pagination={
                datasource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </StyledTable>
        </Card>

        <Upload.Dragger
          name="file"
          action={`/api/hierarchymodule/hierarchyfileupload/${ParentId}`}
          // accept="text/csv"
          multiple="false"
          headers={{
            Authorization: Authorization
          }}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
        >
          {/* <p className="ant-upload-drag-icon"> */}
          <InboxOutlined />
          {/* </p> */}
          <p className="ant-upload-text">
            {this.translation('Click or drag file to this area to upload')}
          </p>
          <p className="ant-upload-hint">
            {this.translation('1. Your can only upload .xlxs file')}
          </p>
          <p className="ant-upload-hint">{this.translation('2. File should be less than 10MB')}</p>
        </Upload.Dragger>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(NestedHierarchy);
