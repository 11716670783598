import React, { Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Col, Table, Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';

import { makeSelectThemeing } from '../../../../../selectors/theme';
import '../../CSS/Table.css';

import { columnsAdapter } from '../../../Utils/Adapter/translationAdapter';
import { defaultRefreshTime } from '../../constant.json';
import { objectOfObject } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';

import { StyledTable, lightTheme, darkTheme } from '../../CSS/style.js';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class StackedBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      columns: [],
      dataSource: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let { props } = this;
    let theme = this.props.selectedThemeing;
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = objectOfObject;
      this.requestCheck = true;
    } else {
      if (props.graphprops.graph === 'rangeBar' && props.graphprops.currentDateData !== undefined) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
          (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
          (graphprops &&
            (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.dayMonthYearExists === 'true' ||
              graphprops.selection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.DeviceCode,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection,
            this.props.dashboardPayload,
            this.props.AssetCode,
            this.props.selectionDateLive,
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        } else {
          json = await getChartDataWithoutSelection(
            graphprops,
            this.abortController,
            this.props.dropdownSelection,
            this.props.selectionDateLive,
            '',
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        }
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let dates = [];
      let values = [];
      let name = '';
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      let showLabel = false;
      let showStack = false;
      let viewType = '';
      let { graphprops } = this.props;
      if (graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph]['showLabel']) {
        showLabel = graphprops[graphprops.graph]['showLabel'] || false;
      }
      if (graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph]['showStack']) {
        showStack = graphprops[graphprops.graph]['showStack'] || false;
      }
      if (graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph]['viewType']) {
        viewType = graphprops[graphprops.graph]['viewType'] || 'vertical';
      }
      if (
        json &&
        json.query1 &&
        Array.isArray(json.query1) &&
        this.props.graphprops &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].table &&
        this.props.graphprops[graphprops.graph].table === 'yes'
      ) {
        let dataSource = json.query1;
        let columnsData = JSON.parse(this.props.graphprops[graphprops.graph].columns);
        let columns = columnsAdapter(columnsData, this.props.translationCache);
        this.setState({
          columns,
          dataSource
        });
      }
      json = json && json.query;

      json &&
        Array.isArray(json) &&
        json.map((val) => {
          if (val.Name && val.Name !== name) {
            if (tagnames.indexOf(val.Name) === -1) {
              tagnames.push(val.Name);
              name = val.Description;
              legendsdata.push({
                name: val.Name,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });

      let yAxisData = [];
      if (
        this.props.graphprops &&
        this.props.graphprops[this.props.graphprops.graph] &&
        this.props.graphprops[this.props.graphprops.graph].YAxis
      ) {
        yAxisData = JSON.parse(this.props.graphprops[this.props.graphprops.graph].YAxis);
      } else {
        yAxisData = [];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: true,
          lineStyle: {
            color: '#504c4c'
          }
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          show: true,
          lineStyle: {
            color:
              this.props.graphprops[this.props.graphprops.graph].colors[index] !== undefined
                ? value && value.tags && value.tags.length > 1
                  ? 'white'
                  : this.props.graphprops[this.props.graphprops.graph].colors[index]
                : 'white'
          }
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        axis.tags = value.tags;
        return axis;
      });
      yaxis.unshift({
        splitLine: {
          show: true,
          lineStyle: {
            color: '#504c4c'
          }
        },
        triggerEvent: true,
        axisLine: {
          show: true,
          lineStyle: {
            color:
              this.props.graphprops &&
              this.props.graphprops[this.props.graphprops.graph] &&
              this.props.graphprops[this.props.graphprops.graph].Linecolor &&
              this.props.graphprops[this.props.graphprops.graph].Linecolor.length > 0
                ? this.props.graphprops[this.props.graphprops.graph].Linecolor[0]
                : 'white'
          }
        },
        axisLabel: { fontSize: 15 },
        tags: []
      });

      tagnames.map((tagval, tagindex) => {
        values = [];

        json.map((jsonval) => {
          if (tagnames[0] === jsonval.Name && tagindex === 0) {
            let date = {};
            if (
              this.props.graphprops.monthExists === 'true' ||
              this.props.graphprops.monthExists === true
            ) {
              date =
                graphprops && graphprops.type === 'live'
                  ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYY')
                  : moment(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY');
            } else {
              if (this.props.currentTab === 'today') {
                date = moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('HH:mm');
              } else if (this.props.currentTab === 'month') {
                date = moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM');
              } else if (this.props.currentTab === 'year') {
                date = moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('MMMM');
              } else {
                if (jsonval.DateTime && jsonval.DateTime !== '') {
                  date = moment
                    .utc(jsonval.DateTime)
                    .tz(this.props.currentTimeZone)
                    .format('YYYY-MM-DD HH:mm:ss');
                }
              }
            }
            dates.push(date);
          }
          if (tagval === jsonval.Name) {
            values.push(jsonval.Value);
          }
          return {};
        });
        if (yaxis.length > 1) {
          yaxis.map((axis, axisindex) => {
            if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
              let linetags =
                props.graphprops && props.graphprops[this.props.graphprops.graph]
                  ? props.graphprops[this.props.graphprops.graph].tags
                  : [];
              if (linetags && linetags.length > 0) {
                linetags &&
                  linetags.map((tag) => {
                    seriesarray.push({
                      name: legendsdata[tagindex]['name'],
                      data: values,
                      yAxisIndex: axisindex,
                      ...(showStack && showStack === 'true' ? { stack: 'total' } : {}),
                      label: {
                        show:
                          showLabel && (showLabel === 'true' || showLabel === true) ? true : false
                      },
                      markPoint: {
                        data: this.props.graphprops.minMax
                          ? [
                              { type: 'max', name: 'Max' },
                              { type: 'min', name: 'Min' }
                            ]
                          : null
                      },
                      type: legendsdata[tagindex]['name'] !== tag ? 'bar' : 'line',
                      axisLabel: { fontSize: 15 }
                    });
                    return {};
                  });
              } else {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  yAxisIndex: axisindex,
                  type: 'bar',
                  axisLabel: { fontSize: 15 }
                });
              }
            }
            return {};
          });
        } else {
          let linetags = props.graphprops && props.graphprops.bar ? props.graphprops.bar.tags : [];
          if (linetags && linetags.length > 0) {
            linetags &&
              linetags.map((tag) => {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: legendsdata[tagindex]['name'] !== tag ? 'bar' : 'line',
                  axisLabel: { fontSize: 15 }
                });
                return {};
              });
          } else {
            seriesarray.push({
              name: legendsdata[tagindex]['name'],
              data: values,
              type: 'bar',
              axisLabel: { fontSize: 15 }
            });
          }
        }

        return {};
      });
      const option = cloneDeep(this.getOption());

      let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
      if (
        this.props.graphprops &&
        this.props.graphprops[this.props.graphprops.graph] &&
        this.props.graphprops[this.props.graphprops.graph].colors &&
        this.props.graphprops[this.props.graphprops.graph].colors.length > 0
      ) {
        option.color = [...this.props.graphprops[this.props.graphprops.graph].colors];
      }
      if (viewType === 'horizontal') {
        option.xAxis = [...yaxis];
        option.yAxis.data = [...dates];
      } else {
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
      }
      option.series = [...series];
      option.legend = [...legendsdata];
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };
  render() {
    const { dataSource, columns } = this.state;
    let theme = this.props.selectedThemeing;
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : this.props.graphprops &&
          graphprops[graphprops.graph].table &&
          graphprops[graphprops.graph].table !== 'yes' ? (
          <ReactEcharts
            option={this.state.option}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
          />
        ) : (
          <div style={{ padding: '10px' }}>
            {/* <Row> */}
            <Col span={24}>
              <ReactEcharts
                option={this.state.option}
                style={{ height: '280%' }}
                className="react_for_echarts"
              />
            </Col>
            {/* </Row> */}
            {/* <Row> */}
            <Col span={24}>
              {/* <div style={{ padding: '20px' }}> */}
              {graphprops &&
              graphprops[graphprops.graph] &&
              graphprops[graphprops.graph].table &&
              graphprops[graphprops.graph].table === 'yes' ? (
                <StyledTable theme={theme === 'lightTheme' ? lightTheme : darkTheme}>
                  <Table
                    // className="barTable"
                    className="TableLightWidget"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size={'small'}
                    style={{ padding: '0px 10px', marginTop: '12px' }}
                    scroll={{ y: 240 }}
                    bordered
                  />
                </StyledTable>
              ) : (
                <div></div>
              )}
              {/* </div> */}
            </Col>
            {/* </Row> */}
          </div>
        )}
      </Fragment>
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'line' | 'shadow'
        }
      },
      legend: {
        left: 'center',
        itemHeight: 25,
        itemWidth: 25,
        itemGap: 10.5,
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontWeight: 'bold',
          fontSize: 18
        }
      },

      xAxis: {
        type:
          this.props.graphprops.viewType && this.props.graphprops.viewType === 'horizontal'
            ? 'value'
            : 'category',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },

        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        },
        scale: true
      },

      yAxis: {
        type:
          this.props.graphprops.viewType && this.props.graphprops.viewType === 'horizontal'
            ? 'category'
            : 'value',
        scale: true,
        min: 0,
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      dataZoom: {
        type: 'slider'
      },

      series: [],
      toolbox: {
        showTitle: false,
        // orient: "vertical",
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(StackedBar);
