export const constant = {
  equipment: 'Equipment',
  equipmentDescription: 'Equipment Description',
  EquipmentDisplayCode: 'EquipmentDisplayCode',
  location: 'Location',
  locationName: 'Location Name',
  locationDescription: 'Location Description',
  equipmentCategory: 'Equipment Category',
  equipmentCategoryDescription: 'Equipment Category Description',
  action: 'Action',
  addNew: 'Add New',
  yes: 'Yes',
  timeStamp: 'Time Stamp',
  goBack: 'Go Back',
  tagName: 'Tag Name',
  sno: 'S.No',
  exeTime: 'Execution Time',
  rootCause: 'rootCause',
  History: 'History',
  fdBack: 'Feed Back',
  tagValue: 'Tag Value',
  no: 'No',
  deleteItemMaster: 'Are you sure that you want to delete the Item Master',
  createdDate: 'Created Date',
  reset: 'Reset',
  type: 'Type',
  CreatedDate: 'Created Date',
  conditionPoint: 'Condition Point',
  createWorkOrder: 'Create WorkOrder',
  assetDeleteMessage: 'Are you sure that you want to delete the Asset Master',
  addItem: 'Add Item',
  addItems: 'Add Items',
  equipmentCode: 'Equipment Code',
  equipmentName: 'Equipment Name',
  equipmentType: 'Equipment Type',
  parentEquipment: 'Parent Equipment',
  equipmentTimeSeries: 'Equipment TimeSeries',
  hierarchy: 'Hierarchy',
  id: 'Id',
  fail: 'Fail',
  criteria: 'Criteria',
  // TaskType: 'Task Type',
  taskNames: 'Task Name',
  taskItem: 'Task Item',
  taskName: 'Task Name',
  LeadTime: 'Lead Time',
  site: 'Site',
  list: 'List',
  details: 'Details',
  EquipmentTags: 'Equipment Tags',
  failureCodeDescription: 'FailureCode Description',
  specificationNumber: 'Specification Number',
  changedBy: 'Changed By',
  usageCondition: 'Usage Condition',
  changedDate: 'Changed Date',
  modifiedBy: 'Modified By',
  modifiedDate: 'Modified Date',
  TotalDowntime: 'Total Downtime',
  failureCode: 'Failure Code',
  installationDate: 'Installation Date',
  selectinstallationDate: 'Select Installation Date',
  manufacturer: 'Manufacturer',
  manufacturerDescription: 'Manufacturer Description',
  serialNo: 'Serial No',
  recordedDate: 'Recorded Date',
  selectEquipment: 'Select Equipment',
  attachments: 'Attachments',
  addRow: 'Add Row',
  addCause: 'Add Cause',
  newCause: 'New Cause',
  Remarks: 'Remarks',
  submit: 'Submit',
  create: 'Create',
  cancel: 'Cancel',
  nodeData: 'Node Data',
  failureDescription: 'Failure Description',
  parentAsset: 'ParentAsset',
  causeCode: 'Cause Code',
  newCauseCode: 'New CauseCode',
  faultCode: 'Fault Code',
  frequencySetPoint: 'Frequency Set Point',
  rollOverSetPoint: 'RollOver Set Point',
  lastCreationTimeforWO: 'Last CreationTime for WO',
  maximumValue: 'Maximum Value',
  minimumValue: 'Minimum Value',
  item: 'Item',
  notificationType: 'Notification Type',
  itemType: 'Item Type',
  itemCode: 'Item Code',
  itemDescription: 'Item Description',
  attribute: 'Attribute',
  WorkOrder: 'WorkOrder',
  description: 'Description',
  alphanumericValue: 'Alphanumeric Value',
  numericValue: 'Numeric Value',
  unitOfMeasure: 'Unit Of Measure',
  tableValue: 'TableValue',
  dataType: 'Data Type',
  material: 'Material',
  triggeredDateTime: 'Triggered date: time',
  connectionData: 'Connection Data',
  issueUnit: 'Issue Unit',
  storeLocation: 'Store Location',
  relatedEquipment: 'Related Equipment',
  toolName: 'Tool Name',
  addTasks: 'Add Task',
  drawings: 'Drawings',
  specifications: 'Specifications',
  search: 'Search',
  AddFailureClass: 'Add Failure Class',
  Description: 'Description',
  Manufacturer: 'Manufacturer',
  InputDescription: 'Input Description',
  SelectEquipment: 'Select Equipment',
  AutopopulatebasedonEquipment: 'Auto Populate Based on Equipment',
  InputQuantity: 'Input Quantity',
  InputIssueUnit: 'Input Issue Unit',
  InputItemType: 'Input Item Type',
  InputStoreLocation: 'Input Store Location',
  InputMaterial: 'Input Material',
  inputPartsNameSystem: 'Input PartsNameSystem',
  AutopopulatebasedonManufacturer: 'Autopopulate based on Manufacturer',
  Reset: 'Reset',
  DisaplayCode: 'Disaplay Code',
  ActualStartDate: 'Actual StartDate',
  InputDisplayCode: 'Input DisplayCode',
  DisplayCode: 'Display Code',
  inputEquipment: 'Input Equipment',
  Inputequipmentdescription: 'Input Equipment Description',
  InputDisaplayCode: 'Input Disaplay Code ',
  SelectEquipmentType: 'Select Equipment Category',
  AutopopulatebasedonEquipmentCategory: 'Autopopulate based on EquipmentCategory',
  deleteToolMaster: 'Are you sure that you want to delete the Tools Master',
  SelectLocation: 'Select Location',
  AutopopulatebasedonLocation: 'Autopopulate Based on Location',
  SelectFailureCode: 'Select FailureCode',
  SelectSite: 'Select Site',
  InputTotalDowntime: 'Input Total Downtime',
  InputSpecNo: 'Input Specno',
  InputSerialNo: 'Input SerialNo',
  SelectManufacturer: 'Select Manufacturer',
  AutoPopulatebasedonManufacturer: 'Auto Populate based on Manufacturer',
  SelectCreatedBy: 'Select Created By',
  CreatedBy: 'Created By',
  SelectUser: 'Select User',
  PleaseenterManufacturer: 'Enter Manufacturer',
  PleaseenterDescription: 'Enter Description',
  InputUsageCondition: 'Input Usage Condition',
  PleaseSelect: 'Select Attachments',
  ViewAttachments: 'View Attachments',
  AddNewAttachments: 'Add New Attachments',
  selectAttribute: 'Select Attribute',
  Add: 'Add',
  areYouSureThatYouWantToDeleteTheMaterialMaster:
    'Are you sure that you want to delete the Material Master',
  zipCode: 'Zip Code',
  country: 'Country',
  GridView: 'Grid View',
  ListView: 'List View',
  ViewInspection: 'View Inspection',
  InspectionId: 'Inspection Id',
  InspectionDesc: 'Inspection Description',
  Delete: 'Delete',
  Edit: 'Edit',
  addSkill: 'Add Skill',
  // state/Province : 'State/Province',
  state: 'State',
  countryCode: 'Country Code',
  email: 'Email',
  personLastName: 'ManPower Last Name',
  personFirstName: 'ManPower First Name',
  personTitle: 'Salutation',
  city: 'City',
  inputPersonTitle: 'Input Person Title',
  inputId: 'Input ID',
  inputQuantity: 'Input Quantity',
  inputAvailSummary: 'Input  Available Balance Summary',
  inputNotificationTo: 'Input Notification To',
  deleteTool: 'Are you sure that you want to delete the Tool?',
  deleteTool1: 'Are you sure that you want to delete the Tool ?',
  deleteTool2: 'Are you sure that you want to delete the Tool',
  deleteLabor: 'Are you sure that you want to delete the Manpower ?',
  deleteMaterial: 'Are you sure that you want to delete the Material ?',
  deleteMaterial1: 'Are you sure that you want to delete the Material',
  deleteItem: 'Are you sure that you want to delete the Item ?',
  deleteJobPlan: 'Are you sure that you want to delete the JobPlan',
  deletePM: 'Are you sure that you want to delete the PM',
  selectEquipmentForTask: 'Please Select Equipment For Tasks',
  selectDate: 'Select date',
  selectOption: 'Select Option',
  success: 'success',
  observationType: 'Observation Type',
  fieldDataType: 'Field DataType',
  toolDescriptionAutopopulate: ' Auto Populated based on Tool',
  deleteLaborMaster: 'Are you sure that you want to delete the Labor ?',
  deleteLabor1: 'Are you sure that you want to delete the Labor ?',
  autopopulateBasedOnTask: 'Autopopulate based on  Task Estimated Duration',
  startDate: 'Select StartDateTime',
  selectEndDate: 'Select EndDateTime',
  language: 'Language',
  address: 'Address',
  phoneNumber: 'Phone Number',
  uploadedFiles: 'Uploaded Files',
  tool: 'Tools',
  firstName: 'First Name',
  lastName: 'Last Name',
  addProblem: 'Add Problem',
  failureClass: 'Failure Class',
  newProblemCode: 'New Problem Code',
  problemCode: 'Problem Code',
  problems: 'Problems',
  remarks: 'Remarks',
  file: 'File',
  addRemedie: 'Add Remedy',
  areYouSureThatYouWantToDeleteTheRemedie: 'Are you sure that you want to delete the remedy',
  remedieCode: 'Remedy Code',
  newRemedyCode: 'New Remedy Code',
  addArea: 'Add Area',
  area: 'Area',
  inspectionItems: 'Inspection Items',
  inspectionArea: 'Inspection Area',
  question: 'Question',
  jobPlan: 'Job Plan',
  status: 'Status',
  revision: 'Revision',
  addTask: 'Add Task',
  taskDescription: 'Task Description',
  workOrderDescription: 'WorkOrder Description',
  sequence: 'Sequence',
  startTime: 'Start Time',
  endTime: 'End Time',
  estimatedDuration: 'Estimated Duration',
  UOM: 'Unit Of Measure',
  addManufacturer: 'Add Manufacturer',
  observation: 'Observation',
  criterion: 'Criterion',
  recordedBy: 'Recorded By',
  checkedBy: 'Checked By',
  sNo: 'SNo',
  addJobPlan: 'Add JobPlan',
  updateJobPlan: 'Update JobPlan',
  name: 'Name',
  tools: 'Tool',
  labor: 'ManPower',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  items: 'Items',
  taskInformation: 'TaskInformation',
  addTools: 'Add Tools',
  availableQuantity: 'Available Quantity',
  task: 'Task',
  Enable: 'Enable',
  Disable: 'Disable',
  enabledAfterSubmit: 'Enabled After Submit',
  areYouSureThatYouWantToDeleteTheTask: 'Are you sure that you want to delete the Task',
  areYouSureThatYouWantToDeleteTheEquipment: 'Are you sure that you want to delete the Equipment',
  updateTask: 'updateTask',
  addPM: 'AddPM',
  updatePM: 'updatePM',
  workOrderInformation: 'Work Order Information',
  jobPlanName: 'JobPlanName',
  timeBaseFrequency: 'TimeBaseFrequency',
  seasonalDates: 'Seasonal Dates',
  frequency: 'Frequency',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  selectDay: 'Select Day',
  selectDates: 'Select Date',
  selectTime: 'Select Time',
  selecttime: 'Select time',
  selectTask: 'Select Task',
  asset: 'Asset',
  leadTime: 'LeadTime',
  supervisor: 'Supervisor',
  organization: 'Organisation',
  update: 'Update',
  serviceReq: 'ServiceReq',
  serviceRequest: 'Service Request',
  workOrder: 'WorkOrder',
  equipmentId: 'Equipment Id',
  inspectionInference: 'Inspection Inference',
  cMPointDescription: 'CMPoint Description',
  cMPoint: 'CM Point',
  parameter: 'Parameter',
  CM: 'Conditional Monitoring',
  CMHistory: 'CM History',
  overview: 'Overview',
  cMPointType: 'CM Point Type',
  addWarning: 'Add Warning',
  warningType: 'Warning Type',
  toolDescription: 'Tool Description',
  materialDescription: 'Material Description',
  failureClassDescription: 'Failure Class Description',
  upperLimit: 'Upper Limit',
  UpperLimit: 'Upper Limit',
  LowerLimit: 'Lower Limit',
  lowerLimit: 'Lower Limit',
  point: 'Point',
  pointDescription: 'Point Description',
  areYouSureThatYouWantToDelete: 'Are you sure that you want to delete',
  add: 'Add',
  method: 'Method',
  areYouSureThatYouWantToDeleteTheEmergencyWorkOrder:
    'Are you sure that you want to delete the TroubleReport',
  reorderPoint: 'Reorder Point',
  reorderDetails: 'Reorder Details',
  quantityAvailable: 'Quantity Available',
  lastIssueDate: 'Last Issue Date',
  quantityCurrentlyReserved: 'Quantity Currently Reserved',
  currentBalance: 'Current Balance',
  availableBalanceSummary: 'Available Balance Summary',
  inputItem: 'Input Item',
  duration: 'Duration',
  schedulingFinish: 'Scheduling Finish',
  schedulingStart: 'Scheduling Start',
  actualFinish: 'Actual Finish',
  actualStart: 'Actual Start',
  targetFinish: 'Target Finish',
  targetStart: 'Target Start',
  workType: 'Work Type',
  workFlow: 'Work Flow',
  selectJobPlan: 'Select JobPlan',
  addNewTask: 'Add New Task',
  actualStartTime: 'Actual StartTime',
  actualEndTime: 'Actual EndTime',
  recordBy: 'RecordBy',
  remedy: 'Remedy',
  notificationTo: 'Notification To',
  creatdBy: 'Creatd By',
  addWorkOrder: 'Add WorkOrder',
  iD: 'ID',
  problem: 'Problem',
  cause: 'Cause',
  role: 'Role',
  title: 'Title',
  communicationType: 'Communication Type',
  date: 'Date',
  comment: 'Comment',
  Id: 'Id',
  schedulingInformationForPM: 'Scheduling Information for PM',
  responsibility: 'Responsibility',
  followupWork: 'FollowUp Work',
  SelectEquipmentCategory: 'Select Equipment Category',
  communicationLog: 'Communication Log',
  WorkOrderdescription: 'WorkOrder Description',
  failureReport: 'Failure Report',
  jobPlanTasks: 'Job Plan Tasks',
  addServiceRequest: 'Add Service Request',
  updateServiceRequest: 'Update Service Request',
  sRId: 'SR Id',
  reportedBy: 'Reported By',
  summary: 'Summary',
  classification: 'Classification',
  priority: 'Priority',
  userInformation: 'User Information',
  channel: 'Channel',
  user: 'Users',
  dates: 'Dates',
  relatedRecords: 'Related Records',
  updateWorkorder: 'Update WorkOrder',
  device: 'Device',
  workFlowName: 'WorkFlow Name',
  hours: 'Hours',
  minSec: 'Min & Sec',
  workFlowType: 'WorkFlow Type',
  equipmentTypeName: 'Equipment Type Name',
  equipmentTypeCode: 'Equipment Type Code',
  equipmentTypeDescription: 'Equipment Type Description',
  createTag: 'Create Tag',
  updateTag: 'Update Tag',
  TaskName: 'Task Name',
  areYouSureThatYouWantToDeleteTheItem: 'Are you sure that you want to  delete the Item',
  areYouSureThatYouWantToDeleteTheMaterial: 'Are you sure that you want to  delete the Material',
  addMaterial: 'Add Material',
  areYouSureThatYouWantToDeleteTheLabor: 'Are you sure that you want to  delete the Labor',
  areYouSureThatYouWantToDeleteTheTool: 'Are you sure that you want to  delete the Tool',
  addLabor: 'Add ManPower',
  save: 'Save',
  partsNameSystem: 'Parts Name System',
  orderUnit: 'Order Unit',
  InputOrderUnit: 'Input Order Unit',
  deviceTypeName: 'Device Type Name',
  deviceTypeCode: 'Device Type Code',
  deviceTypeDescription: 'Device Type Description',
  Quantity: 'Quantity',
  Skill: 'Skill',
  addEquipment: 'Add Equipment',
  TaskType: 'Task Type',
  Observation: 'Observation',
  NOOfRepairs: 'No of Repairs',
  NOOfInspections: 'No of Inspections',
  RefurbishedDate: 'Refurbished Date',
  NoOfmaintenance: 'No Of maintenance',
  EquipmentDetails: 'Equipment Details',
  EquipmentCatalogue: 'Equipment Catalogue',
  addFailureClass: 'Add FailureClass',
  Name: 'Name',
  value: 'Value',
  Location: 'Location',
  LocationDescription: 'Location Description',
  LocationType: 'Location Type',
  ParentLocation: 'Parent Location',
  ParentLocationDescription: 'Parent Location Description',
  AssetCode: 'Asset Code',
  AssetType: 'Asset Type',
  SiteId: 'Site Id',
  jobplanDescription: 'Job Plan Description',
  measurementValue: 'Measurement Value',
  reportedDate: 'Reported Date',
  recordDate: 'Record Date',
  actualEnd: 'ActualEnd',
  pmName: 'PM Name',
  pmDescription: 'PM Description',
  serviceRequestID: 'SR ID',
  plannedDate: 'Planned Date',
  actualDate: 'Actual Date',

  end: 'End',
  start: 'Start',
  createdTime: 'Created Time',
  createdBy: 'Created By',
  jobPlanDescription: 'JobPlan Description',
  workOrderCreationTime: 'Work Order Creation Time',
  effectiveStartDate: 'Effective Start Date',
  effectiveEndDate: 'Effective End Date',
  measurementDate: 'Measurement Date',
  inspectionMethod: 'Inspection Method',
  inspectionRecord: 'Inspection Record',
  inspectionCreationTime: 'Inspection Creation Time',

  NOOfMaterialsUsed: 'No Of Materials Used',
  noOfPartsReplaced: 'No Of Parts Replaced',
  noOfDowntimes: 'No Of Downtimes',
  noOfInspections: 'No Of Inspections',
  totalNoofDowntimes: 'Total No of Downtimes',
  mostRecentInspection: 'Most Recent Inspection',
  mostRecentDowntime: 'Most Recent Downtime',
  noOfMaintainece: 'No Of Maintainece',
  mostRecentRepair: 'Most Recent Repair',

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  EQUIPMENT_MASTER: 'Equipment',
  ITEM_MASTER: 'Item',
  MATERIAL_MASTER: 'Material',
  TOOL_MASTER: 'Tool',
  MANPOWER_MASTER: 'ManPower',
  FAILURECODE_MASTER: 'FailureCode',
  INSPECTION_MASTER: 'Inspection',
  JOBPLAN: 'Job Plan',
  WORKORDERS: 'WorkOrders',
  PREVENTIVEMAINTENANCE: 'Preventive Maintenance',
  CONDITIONAL_MONITORING: 'Conditional Monitoring',
  TARGET_REPORTING: 'Trouble Reporting',
  FIELD_DATA_MEASUREMENT: 'Field Data Measurement',
  INSPECTION: 'Inspection',
  FAILURE_MASTER: 'Failure Class',
  WORK_FLOW: 'WorkFlow',
  SERVICE_REQUEST: 'Service Request',
  EQUIPMENT_TAGS: 'Equipment Tags',
  INVENTORY: 'Inventory',
  EQUIPMENT_OVERVIEW: 'Equipment Overview',
  ELOG_SCHEDULE: 'ELogbook Schedule',
  LOCATION: 'Location'
};
