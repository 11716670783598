import React from 'react';
import axios from 'axios';
import { Button, Input, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { StyledDashboard, StyledTable, lightTheme } from './styles.js';
import translation from '../../../../utils/translation';
import './style.css';
class MaximoNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maximoNotification: [],
      dataSource: []
    };
  }

  componentDidMount = () => {
    this.getMaximoList();
  };

  getMaximoList = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/notifications/get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ maximoNotification: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let { maximoNotification } = this.state;
    let maximoCol = [
      {
        title: 'Created Time',
        dataIndex: 'CreatedTime',
        key: 'CreatedTime'
      },
      {
        title: 'Data Time',
        dataIndex: 'DataTime',
        key: 'DataTime'
      },
      {
        title: 'Device',
        dataIndex: 'Device',
        key: 'Device'
      },
      {
        title: 'Parameter Code',
        dataIndex: 'ParameterCode',
        key: 'ParameterCode',
        ...this.getColumnSearchProps('DeviceCode')
      },
      {
        title: 'State',
        dataIndex: 'State',
        key: 'State'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status'
      },
      {
        title: 'Tag Value',
        dataIndex: 'TagValue',
        key: 'TagValue'
      },
      {
        title: 'Maximo Response Date',
        dataIndex: 'MaximoResponseDate',
        key: 'MaximoResponseDate'
      },
      {
        title: 'Ticket Id',
        dataIndex: 'TicketId',
        key: 'TicketId'
      }
    ];

    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 173 }}>
        {/* <Row>
          <Col style={{ marginTop: '2%' }}> */}
        <div style={{ marginTop: '2%' }}>
          <StyledTable theme={lightTheme}>
            <Table
              className="table"
              columns={maximoCol}
              size="large"
              pagination={
                maximoNotification.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : 1
              }
              dataSource={maximoNotification}
            ></Table>
          </StyledTable>
        </div>

        {/* </Col>
        </Row> */}
      </StyledDashboard>
    );
  }
}
export default MaximoNotification;
