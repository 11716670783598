import React, { Fragment } from 'react';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { Button, Input, Select, Switch, Drawer, Collapse, message, Form, Popconfirm } from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  SaveOutlined,
  SearchOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';

import DeviceTagReference from './DeviceTagReference';
import localStorage from '../../../utils/localStorage';
import adapter from './adapter';
import { DrawerFooter } from './styles';
import {
  _getDeviceParameterBasedOnType,
  _getDeviceParameterType,
  _getDeviceParameterBasedonDevice,
  _UpdateLinkDeviceParameter,
  _getParameterGroupList,
  _getParameterCategoryList,
  _deleteDeviceParameters,
  _CreateLinkDeviceParameter
} from '../AssetDeviceList/APICalls';
import { StyledButton, StyledComponent, StyledTable } from '../../../commons/styles/layout';

const { Option } = Select;
const Panel = Collapse.Panel;

class DeviceListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleDeviceDrawer: false,
      deviceDataSource: props.deviceList || [],
      asset: props.asset.assetParent || {},
      deviceTypeList: props.deviceTypeList || [],
      deviceParametersBasedOnType: [],
      deviceObject: {},
      ability: undefined,
      visibleConfiguration: false,
      tagReferenceList: props.tagReferenceList || [],
      selectedDeviceParameterId: '',
      selectedDeviceParameterName: '',
      deviceParameterBasedonDevice: [],
      deviceId: '',
      searchText: '',
      parameterGroupList: [],
      ParameterCategoryList: []
    };
    this.paramTypeonChange = this.paramTypeonChange.bind(this);
  }

  devicesRef = React.createRef();
  componentDidMount() {
    this.props.actions.getTagReferenceList();
    this.props.actions.getDeviceTypeList();
    this.props.actions.getAllDeviceList();
    this._getParameterGroupList();
    this._getParameterCategoryList();
  }

  UNSAFE_componentWillReceiveProps({ deviceList, tagReferenceList, asset, deviceTypeList }) {
    const deviceDataSource = adapter.filterJsonData(deviceTypeList, deviceList);
    this.setState({
      deviceDataSource: deviceDataSource,
      tagReferenceList,
      deviceTypeList,
      asset: asset.assetParent
    });
  }

  _getParameterGroupList = async () => {
    let parameterGroupList = await _getParameterGroupList();
    this.setState({
      parameterGroupList
    });
  };

  _getParameterCategoryList = async () => {
    let parameterCategoryList = await _getParameterCategoryList();
    this.setState({
      parameterCategoryList
    });
  };

  showDrawerDevice = () => {
    this.setState({
      deviceObject: {},
      visibleDeviceDrawer: true
    });
  };

  onCloseNew = () => {
    // this.props.form.resetFields();
    this.setState({
      visibleDeviceDrawer: false,
      deviceObject: {}
    });
  };

  onCloseVisibleConfiguration = () => {
    this.setState({
      visibleConfiguration: false
    });
  };

  showDrawerOldDevice = (e, row) => {
    e.preventDefault();
    this.setState({
      deviceObject: row,
      visibleDeviceDrawer: true
    });
  };

  handleChange = (value) => {
    const { form } = this.props;
    form.setFieldsValue({
      deviceType: value
    });
  };

  createDevice = (e) => {
    e.preventDefault();
    this.devicesRef.current
      .validateFields()
      .then(async (values) => {
        this.props.actions.createDevice(values);
        this.setState({ visible: false });
        this.props.actions.getAllDeviceList();
        if (this.devicesRef.current) {
          this.devicesRef.current.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDevice = (e) => {
    const { visibleDeviceDrawer } = this.state;
    e.preventDefault();
    this.devicesRef.current
      .validateFields()
      .then(async (values) => {
        this.props.actions.updateDevice(values);
        this.setState({ visibleDeviceDrawer: !visibleDeviceDrawer });
        this.props.actions.getAllDeviceList();
        if (this.devicesRef.current) {
          this.devicesRef.current.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editRow = async (deviceObject) => {
    let { DeviceType, Id, AliasCode } = deviceObject;
    let deviceParametersBasedOnType = await _getDeviceParameterBasedOnType(DeviceType);
    let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(AliasCode);
    let parameterType = await _getDeviceParameterType();
    this.setState({
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice,
      deviceId: Id,
      parameterType,
      deviceObject,
      visibleConfiguration: true
    });
  };

  _getDeviceParameterBasedonDevice = (DeviceCode) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/device/relatedParams?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        DeviceCode: [DeviceCode],
        Type: 'Device'
      }
    };
    axios(deviceTypeObject)
      .then((response) => {
        this.setState({ deviceParameterBasedonDevice: response.data });
      })
      .catch(function () {});
  };

  paramTypeonChange(index, parameterId) {
    const { parameterType, deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].parameterType = parameterType[index].code;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  }

  parameterTypeonChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterType = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  parameterGrouponChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterGroup = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  parameterCategoryonChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterCategory = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  edgeCodeOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice &&
      deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
        if (deviceParameter.ParameterId === parameterId) {
          deviceParameterBasedonDevice[key].EdgeCode = value;
        }
      });

    this.setState({ deviceParameterBasedonDevice });
  };

  deleteDevice() {}

  _getDeviceParameterBasedOnType = (deviceTypeId) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'GET',
      url: `/api/device/getParameters/${deviceTypeId}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then((response) => {
        this.setState({ deviceParametersBasedOnType: response.data });
      })
      .catch(function () {});
  };

  addDeviceParamter = () => {
    const {
      deviceParameterBasedonDevice,
      selectedDeviceParameterId,
      deviceParametersBasedOnType,
      deviceObject
    } = this.state;
    if (selectedDeviceParameterId) {
      let deviceStatus = false;
      deviceParameterBasedonDevice.forEach((usedDP) => {
        if (usedDP.ParameterId === selectedDeviceParameterId) {
          deviceStatus = true;
        }
      });
      if (!deviceStatus) {
        let newParameter = deviceParametersBasedOnType.filter(
          (parameter) => parameter.Id === selectedDeviceParameterId
        );

        const deviceParameter = {
          EdgeCode: '',
          ParameterGroup: '',
          ParameterCategory: '',
          // ParameterId: selectedDeviceParameterId,
          ParameterReferences: [],
          ParameterType: '',
          Parameter:
            newParameter && newParameter[0] && newParameter[0].Code ? newParameter[0].Code : '',
          ParameterCode:
            newParameter && newParameter[0] && newParameter[0].Code ? newParameter[0].Code : '',
          ParameterDescription:
            newParameter && newParameter[0] && newParameter[0].Name ? newParameter[0].Name : '',
          ParameterAlias:
            newParameter && newParameter[0] && newParameter[0].AliasCode
              ? newParameter[0].AliasCode
              : '',
          AssetAlias: deviceObject && deviceObject.AssetAlias ? deviceObject.AssetAlias : null,
          DeviceAlias: deviceObject && deviceObject.AliasCode ? deviceObject.AliasCode : null
        };
        deviceParameterBasedonDevice.push(deviceParameter);
        this.setState({
          deviceParameterBasedonDevice
        });
      } else {
        message.error('Equipment parameter already exists');
      }
    } else {
      message.error('Please select equipment parameter');
    }
  };

  onChange = (selectedDeviceParameterId) => {
    this.setState({ selectedDeviceParameterId });
  };

  // _afterAddedRemoveFromList() {
  //   let { deviceParametersBasedOnType, deviceParameterBasedonDevice } = this.state;

  //   deviceParameterBasedonDevice.forEach((usedDP) => {
  //     deviceParametersBasedOnType = _.remove(deviceParametersBasedOnType, function (e) {
  //       return e._key !== usedDP.ParameterId;
  //     });
  //     this.setState({ deviceParametersBasedOnType });
  //   });
  // }

  saveDeviceParameter = async (payload, type) => {
    let { deviceObject } = this.state;
    if (payload) {
      payload &&
        payload.ParameterReferences &&
        payload.ParameterReferences.forEach((parameterAlias, index) => {
          if (parameterAlias.isNew) {
            delete parameterAlias.isNew;
            delete parameterAlias.editable;
            payload.ParameterReferences[index] = parameterAlias;
          }
        });

      let responseData = {};
      if (type && type === 'update') {
        responseData = await _UpdateLinkDeviceParameter(payload, deviceObject.AliasCode);
      } else if (type && type === 'create') {
        responseData = await _CreateLinkDeviceParameter(payload, deviceObject.AliasCode);
      }

      if (responseData && responseData.message) {
        message.success(responseData.message);
        let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(
          deviceObject.AliasCode
        );
        this.setState({ deviceParameterBasedonDevice });
      }
    }
  };
  saveTagReferenceList = (updatedTRList, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;

    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterReferences = updatedTRList;
      }
    });
    this.setState({ deviceParameterBasedonDevice });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </SearchOutlined>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  _deleteDeviceParameter = async (parameterId) => {
    await _deleteDeviceParameters(parameterId);
  };

  renderFormSwitch(renderFormItems) {
    let { disableOnUpdate } = renderFormItems;
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 300 }}
            placeholder={`Select ${renderFormItems.Key}`}
            onChange={(e) => this.devicesRef.current.setFieldsValue({ [renderFormItems.Key]: e })}
          >
            {renderFormItems.Options &&
              Array.isArray(renderFormItems.Options) &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Option key={index} value={option[renderFormItems.OptionsSettings.value]}>
                      {option[renderFormItems.OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );
      case 'Switch':
        return <Switch disabled={disableOnUpdate && disableOnUpdate === true ? true : false} />;

      default:
        return (
          <Input
            style={{ width: 300 }}
            placeholder={`Enter ${renderFormItems.Key}`}
            disabled={disableOnUpdate && disableOnUpdate === true ? true : false}
          />
        );
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const {
      deviceDataSource,
      selectedDeviceParameterId,
      deviceObject,
      deviceTypeList,
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice,
      parameterGroupList,
      parameterCategoryList,
      parameterType
    } = this.state;

    const columns = [
      {
        title: 'Equipment Name',
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name'),
        render: (text) => <div>{text}</div>,
        onCell: (record) => {
          return {
            onClick: (e) => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: 'Equipment Code',
        dataIndex: 'DeviceCode',
        key: 'DeviceCode',
        ...this.getColumnSearchProps('DeviceCode'),
        render: (text) => <div>{text}</div>,
        onCell: (record) => {
          return {
            onClick: (e) => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: 'Equipment Type',
        dataIndex: 'EquipmentType',
        key: 'EquipmentType',
        ...this.getColumnSearchProps('EquipmentType'),
        render: (text) => <span>{text}</span>,
        onCell: (record) => {
          return {
            onClick: (e) => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: 'Time Series',
        dataIndex: 'TimeSeries',
        key: 'TimeSeries',
        ...this.getColumnSearchProps('TimeSeries'),
        render: (text) => <span>{text ? text.toString() : ''}</span>,
        onCell: (record) => {
          return {
            onClick: (e) => {
              this.showDrawerOldDevice(e, record);
            }
          };
        }
      },
      {
        title: 'Actions',
        dataIndex: '',
        key: '',
        render: (text, row) => (
          <div>
            <button
              type="button"
              className="ant-btn"
              style={{ marginRight: '5px' }}
              onClick={() => {
                this.editRow(row);
              }}
            >
              <UnorderedListOutlined />
            </button>
          </div>
        )
      }
    ];
    let formStructure = [
      {
        DisplayName: 'Equipment Name',
        Key: 'Name',
        Widget: 'Input'
      },
      {
        DisplayName: 'Id',
        Key: 'Id',
        Widget: 'Input',
        hidden: true
      },
      {
        DisplayName: 'Equipment Code',
        Key: 'DeviceCode',
        Widget: 'Input'
      },
      {
        DisplayName: 'Equipment Type',
        Key: 'DeviceType',
        Widget: 'Select',
        Options: deviceTypeList,
        OptionsSettings: { key: 'Name', value: 'Id' }
      },
      {
        DisplayName: 'Equipment TimeSeries',
        Key: 'TimeSeries',
        Widget: 'Switch'
      },
      {
        DisplayName: 'Asset',
        Key: 'Asset',
        Widget: 'Input',
        hidden: true
      }
    ];

    const customPanel = {
      background: '#f7f7f7',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden'
    };

    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 115 }}>
        <Drawer
          title="Configuration"
          width={800}
          closable
          onClose={this.onCloseVisibleConfiguration}
          visible={this.state.visibleConfiguration}
        >
          <div>
            <h4>Select Equipment Parameter :</h4>
            <div>
              <Select
                defaultValue={selectedDeviceParameterId || undefined}
                style={{ width: 300, marginRight: '15px' }}
                onChange={this.onChange}
                placeholder="Select Equipment Parameters"
              >
                {deviceParametersBasedOnType &&
                  Array.isArray(deviceParametersBasedOnType) &&
                  deviceParametersBasedOnType.map((deviceParameters, index) => {
                    return (
                      <Option key={index} value={deviceParameters.Id}>
                        {deviceParameters.Name}
                      </Option>
                    );
                  })}
              </Select>
              <Button type="primary" onClick={this.addDeviceParamter}>
                Add{' '}
              </Button>
            </div>
            {deviceParameterBasedonDevice.map((device, index) => {
              let abc =
                deviceParametersBasedOnType &&
                Array.isArray(deviceParametersBasedOnType) &&
                deviceParametersBasedOnType.filter((deviceParameter) => {
                  return deviceParameter.Id === device.ParameterId;
                });
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ marginTop: '20px' }}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
                  )}
                >
                  <Panel
                    header={
                      <Fragment>
                        <h4 style={{ display: 'inline' }}>
                          {abc && abc[0] && abc[0].Name ? abc[0].Name : ''}- {device.ParameterId}
                        </h4>
                        <Popconfirm
                          title="Are you sure delete link between parameter and device?"
                          placement="top"
                          onConfirm={(e) => {
                            e.stopPropagation();
                            // this.deleteDeviceParameter(device.ParameterId);
                            this._deleteDeviceParameter(device.Id);
                          }}
                          onCancel={(e) => {
                            e.stopPropagation();
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <StyledButton
                            type="StyledButton"
                            className="ant-btn"
                            style={{
                              float: 'right',
                              marginRight: '5px',
                              marginTop: '-0.4vh'
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DeleteOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </StyledButton>
                        </Popconfirm>
                      </Fragment>
                    }
                    // header={
                    //   <h4>
                    //     {parameterType && parameterType[index] && parameterType[index].Name
                    //       ? parameterType[index].Name
                    //       : ''}{' '}
                    //     - {device.ParameterId}
                    //   </h4>
                    // }
                    key={device.Id}
                    style={customPanel}
                  >
                    <div style={{ padding: '0px 10px' }}>
                      <SaveOutlined
                        theme="twoTone"
                        style={{
                          fontSize: '20px',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end'
                        }}
                        onClick={() =>
                          this.saveDeviceParameter(
                            device,
                            device && device.Id ? 'update' : 'create'
                          )
                        }
                      ></SaveOutlined>

                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Parameter Type
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterType || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterTypeonChange(e, device.ParameterId)}
                          placeholder="Select ParameterType"
                        >
                          {parameterType &&
                            parameterType.map((deviceParamsType, index) => {
                              return (
                                <Option key={index} value={deviceParamsType.Code}>
                                  {deviceParamsType.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Parameter Category
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterCategory || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterCategoryonChange(e, device.ParameterId)}
                          placeholder="Select Parameter Category"
                        >
                          {parameterCategoryList &&
                            Array.isArray(parameterCategoryList) &&
                            parameterCategoryList.map((parameterCategory, index) => {
                              return (
                                <Option key={index} value={parameterCategory.Code}>
                                  {parameterCategory.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Parameter Group
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterGroup || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterGrouponChange(e, device.ParameterId)}
                          placeholder="Select Parameter Group"
                        >
                          {parameterGroupList &&
                            Array.isArray(parameterGroupList) &&
                            parameterGroupList.map((parameterGroup, index) => {
                              return (
                                <Option key={index} value={parameterGroup.Code}>
                                  {parameterGroup.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Edge Code
                        </label>
                        <Input
                          disabled={device && device.Id ? true : false}
                          value={device.EdgeCode || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          placeholder="Input Edge Code"
                          onChange={(e) =>
                            this.edgeCodeOnChange(e.target.value, device.ParameterId)
                          }
                        />
                      </div>
                      <DeviceTagReference
                        conditionReference={device}
                        tagReferenceList={this.props.tagReferenceList}
                        saveTagReferenceList={this.saveTagReferenceList}
                      />
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </Drawer>

        <Drawer
          title={!deviceObject.AliasCode ? 'Create Equipment' : 'Update Equipment'}
          width={500}
          closable
          onClose={this.onCloseNew}
          visible={this.state.visibleDeviceDrawer}
          style={{
            // overflow: 'auto',
            // height: 'calc(100% - 108px)',
            paddingBottom: '108px'
          }}
        >
          <Form
            layout="vertical"
            ref={this.devicesRef}
            name="user_form"
            initialValues={deviceObject || {}}
          >
            {formStructure &&
              Array.isArray(formStructure) &&
              formStructure.map((formItem, index) => {
                return (
                  <Form.Item
                    key={index}
                    name={formItem.Key}
                    label={this.translation(formItem.DisplayName)}
                  >
                    {this.renderFormSwitch(formItem)}
                  </Form.Item>
                );
              })}
          </Form>
          <DrawerFooter>
            <Button type="primary" onClick={this.onCloseNew} style={{ marginRight: 8 }}>
              {' '}
              Cancel
            </Button>
            {deviceObject.AliasCode === undefined ? (
              <Button onClick={this.createDevice} type="primary">
                Create Equipment
              </Button>
            ) : (
              <Button onClick={this.updateDevice} type="primary">
                Update Equipment
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <StyledTable
          size="large"
          columns={columns}
          dataSource={deviceDataSource && Array.isArray(deviceDataSource) ? deviceDataSource : []}
          pagination={
            deviceDataSource && Array.isArray(deviceDataSource) && deviceDataSource.length > 5
              ? {
                  pageSize: '5'
                }
              : false
          }
          locale="No Data"
        />
      </StyledComponent>
    );
  }
}

export default DeviceListComponent;
