export const constant = {
  /**Application Menu Constants Start */
  Edit_Profile: 'Edit Profile',
  Change_Password: 'Change Password',
  Language_Translation: 'Language Translation',
  Preferences: 'Preferences',
  Configuration_Upload: 'Configuration Upload',
  Logo_Upload: 'Logo Upload',
  Data_Upload: 'Data Upload',
  Menu_Configuration: 'Menu Configuration',
  User_Management: 'User Management',
  Organisations: 'Organisations',
  Organisation: 'Organisation',
  Department: 'Department',
  Users: 'Users',
  Landing_Page: 'Landing Page',
  Table_Configuration: 'Table Configuration',
  Master_Configuration: 'Master Configuration',
  /**Application Menu Constants End */
  /**Language Translation component  Start */
  Admin: 'Admin',
  English: 'English',
  Keyword: 'Keyword',
  CreatedBy: 'CreatedBy',
  Action: 'Action',
  Japanese: 'Japanese',
  Default_Template: 'Default Template',
  Add: 'Add',
  Click_to_Upload: 'Click to Upload',
  /**Language Translation component  End */
  /**Edit Profile component  Start */
  First_Name: 'First Name',
  Last_Name: 'Last Name',
  Email: 'Email',
  Update_Profile: 'Update Profile',
  /**Edit Profile component  End */
  /**Change Password component  Start */
  Password_Description:
    'After a successful password update,you will be redirected to the login page where you can log in with your new password.',
  Password_Content1: 'Change your password or recover your current one',
  Password_Content2: 'You must provide your current password in order to change it.',
  Old_Password: 'Old Password',
  New_Password: 'New Password',
  Confirm_New_Password: 'Confirm New Password',
  Password_confirmation: 'Password confirmation',
  Current_Password: 'Current Password',

  /**Change Password component  End */
  /**Preference component  Start */
  Navigation_theme: 'Navigation theme',
  TIMEZONE: 'TimeZone',
  // TIMEZONE_Desc:"Cha",
  Navigation_Desc: 'Customize the appearance of the application header and navigation sidebar.',
  background_theme: 'Background Themeing Light/Dark',
  Theme_Desc: 'Customize the appearance of the application components with Light/Dark Theme.',
  Save_Password: 'Save Password',
  Navigation_Mode: 'Navigation Mode',
  Navigation_Settings: 'This settings allow you to customize the menu position',
  /**Preference component  End */
  /**Table Configuration Upload Start */
  Table: 'Table',
  Upload_Text: 'Click or drag file to this area to upload',
  Upload_Desp1: 'Your can only upload .CSV file',
  Upload_Desp2: 'File should be less than 10MB',
  Done: 'Done',
  Insert_Translation_Words: 'Insert Translation Words',
  Update: 'Update',
  Description: 'Description',
  Create: 'Create',
  Cancel: 'Cancel',

  /**Table Configuration Upload Start */
  /**Hierarchy Role Access Component Start */
  Submit: 'Submit',
  Select_Hierarchy: 'Select Hierarchy',
  Select_a_Role: 'Select a Role',
  Add_User: 'Add User',

  /* User index*/
  Salutation: 'Salutation',
  User_Name: 'User Name',
  Please_enter_FirstName: 'Please enter FirstName',
  Please_enter_LastName: 'Please enter LastName',
  Please_enter_UserName: 'Please enter UserName',
  Please_enter_Email: 'Please enter Email',
  Mobile_Number: 'Mobile Number',
  Please_enter_MobileNumber: 'Please enter MobileNumber',
  Add_Item: 'Add Item',
  Create_User: 'Create User',
  Update_User: 'Update User',

  /**Hierarchy Role Access Component Start */

  Add_Hierarchy: 'Add Hierarchy',
  Hierarchy_Name: 'Hierarchy Name',
  Hierarchy_Code: 'Hierarchy Code',
  View: 'View',
  Create_Hierarchy: 'Create Hierarchy',
  Update_Hierarchy: 'Update Hierarchy',
  Add_Department: 'Add Department',
  Click_to_redirect: 'Click to redirect',
  Are_you_sure_delete_Hierarchy: 'Are you sure delete Hierarchy?',
  Department_Name: 'Department Name',
  Department_Code: 'Department Code',
  Create_Department: 'Create Department',
  Update_Department: 'Update Department',
  Are_you_sure_delete_Department: 'Are you sure delete Department?',

  Create_Translation_Words: 'Save Translation',
  Update_Translation_Words: 'Update Translation',
  Delete: 'Delete',
  Edit: 'Edit',
  resetpassword: 'Reset Password',
  yes: 'Yes',
  no: 'No',

  //  Warning Limits
  Device: 'Unit',
  Parameter: 'Tag Description',
  SETTINGS_MENU_POSITION: 'This settings allow you to customize the menu position',
  AddWarningLimits: 'Add Warning Limits',
  Create_Warning_Limits: 'Create Warning Limits',
  Update_Warning_Limits: 'Update Warning Limits'
};
