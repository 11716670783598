import React from 'react';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { Drawer, Input, Popconfirm, message, Divider, Form } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { bindActionCreators } from 'redux';
import { getUserProfile } from '../../../selectors/layout';
import { createStructuredSelector } from 'reselect';

import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { currentAsset, setBreadcrumbs } from '../../../modules/asset/asset.duck';
import {
  _getAssetsBasedOnAssetID,
  _saveAssetData,
  _updateAssetData,
  _deleteAssetObject
} from './APICalls';
import { AddItemDiv, BadgeStyle, SpanItem, DrawerFooter } from '../AssetDeviceList/style';
import { StyledButton, StyledTable } from '../../../commons/styles/layout';
import { setDeviceBreadcrumbs, currentDevice } from '../../../modules/device/device.duck';

class AssetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      editObj: {},
      data: {},
      datasource: [],
      searchText: '',
      userProfile: props.userProfile || {}
    };
  }

  assetRef = React.createRef();
  componentDidMount() {
    this._getDatasource(this.props.data);
  }
  componentDidUpdate(preprops) {
    if (preprops.data !== this.props.data || preprops.userProfile !== this.props.userProfile) {
      this._getDatasource(this.props.data);
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  }
  _getDatasource = async (data) => {
    let datasource = await _getAssetsBasedOnAssetID(data);
    this.setState({
      data,
      datasource: datasource || []
    });
  };

  searchAssetCode = async (value) => {
    let assetDatasource = await _getAssetsBasedOnAssetID(this.props.data);
    const datasource =
      assetDatasource &&
      Array.isArray(assetDatasource) &&
      assetDatasource.filter(function (asset) {
        return (
          asset.Name.toString().toLowerCase().includes(value.toLowerCase()) ||
          asset.AssetCode.toString().toLowerCase().includes(value.toLowerCase())
        );
      });
    this.setState({ datasource });
  };

  openAssetDrawer = (e) => {
    e.preventDefault();
    this.setState({
      editObj: {},
      visible: true
    });
  };

  navigateNextPage = (e, row) => {
    this.props.actions.currentAsset(row);
    this.props.actions.setBreadcrumbs(row);
    this.props.actions.setDeviceBreadcrumbs([]);
    this.props.actions.currentDevice({});

    history.push({ pathname: `/rubus/assetConfiguration/${row.AssetCode}` });
  };

  onClose = () => {
    if (this.assetRef.current) {
      this.assetRef.current.resetFields();
    }
    this.setState({ visible: false, editObj: {} });
  };

  saveAsset = (e) => {
    e.preventDefault();
    let { data } = this.state;
    this.assetRef.current
      .validateFields()
      .then(async (values) => {
        delete values.Id;
        values = {
          ...values,
          AssetType: data && data.AssetType ? data.AssetType : '',
          ParentAsset: data && data.parentAssetId ? data.parentAssetId : null
        };

        let saveResponse = await _saveAssetData(values);
        if (saveResponse && saveResponse.message) {
          message.success(saveResponse.message);
          this._getDatasource(this.props.data);
          this.setState({ visible: false });
          if (this.assetRef.current) {
            this.assetRef.current.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateAsset = (e) => {
    e.preventDefault();
    let { data } = this.state;
    this.assetRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          AssetType: data && data.AssetType ? data.AssetType : '',
          ParentAsset: data && data.parentAssetId ? data.parentAssetId : null
        };
        let updateResponse = await _updateAssetData(values);
        if (updateResponse && updateResponse.message) {
          message.success(updateResponse.message);
          this._getDatasource(this.props.data);
          this.setState({ visible: false });
          if (this.assetRef.current) {
            this.assetRef.current.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editRow = (e, editObj) => {
    if (this.assetRef.current) {
      this.assetRef.current.setFieldsValue(editObj);
    }
    this.setState({ visible: true, editObj });
  };

  _deleteAssetData = async (deleteObject) => {
    let deleteResponse = await _deleteAssetObject(deleteObject.Id);
    if (deleteResponse) {
      message.success(deleteResponse.message);
    }
    this._getDatasource(this.props.data);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <SearchOutlined
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </SearchOutlined>
        <StyledButton
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </StyledButton>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    let { datasource, visible, editObj, data, userProfile } = this.state;
    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'asset_device')
        .map((name) => permissionsArray.push(name.action));

    return (
      <div style={{ margin: '20px 0px' }}>
        <AddItemDiv>
          <div></div>
          <div>
            <BadgeStyle count={datasource ? datasource.length : 0} />
            <SpanItem>Items</SpanItem>
            <StyledButton
              disabled={!permissionsArray.includes('create') ? true : false}
              onClick={(e) => this.openAssetDrawer(e, data.AssetType)}
            >
              <PlusOutlined />
              Add
              <span style={{ padding: '0 5px' }}>
                {data && data.AssetType
                  ? _.startCase(_.toLower(data.AssetType))
                  : _.startCase(_.toLower(''))}
              </span>
            </StyledButton>
          </div>
        </AddItemDiv>

        <Drawer
          title={
            !editObj.Id
              ? data && data.AssetType
                ? `Create ${_.startCase(_.toLower(data.AssetType))}`
                : `Create ${''}`
              : data.AssetType
              ? `Update ${_.startCase(_.toLower(data.AssetType))}`
              : `Update ${_.startCase(_.toLower(''))}`
          }
          placement="right"
          width={400}
          closable
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            ref={this.assetRef}
            name="user_form"
            initialValues={editObj || {}}
          >
            <Form.Item
              label={
                data && data.AssetType
                  ? `${_.startCase(_.toLower(data.AssetType))} Code`
                  : `${_.startCase(_.toLower(''))} Code`
              }
              name={'AssetCode'}
              style={{ width: 300 }}
              placeholder={
                data && data.AssetType
                  ? `Please enter ${_.startCase(_.toLower(data.AssetType))} Code`
                  : `Please enter ${_.startCase(_.toLower(''))} Code`
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.AssetType
                      ? `Please input ${_.startCase(_.toLower(data.AssetType))} Code`
                      : `Please enter ${_.startCase(_.toLower(''))} Code`
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                data && data.AssetType
                  ? `${_.startCase(_.toLower(data.AssetType))} Name`
                  : `${_.startCase(_.toLower(''))} Name`
              }
              name={'Name'}
              style={{ width: 300 }}
              placeholder={
                data && data.AssetType
                  ? `Please enter ${_.startCase(_.toLower(data.AssetType))} Name`
                  : `Please enter ${_.startCase(_.toLower(''))} Name`
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.AssetType
                      ? `Please input ${_.startCase(_.toLower(data.AssetType))} Name`
                      : `Please enter ${_.startCase(_.toLower(''))} Name`
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Id"
              display="none"
              name="Id"
              hidden={true}
              initialValue={editObj.Id || ''}
            >
              <Input style={{ display: 'none' }} />
            </Form.Item>
          </Form>

          <DrawerFooter>
            <StyledButton onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </StyledButton>
            {!editObj.Id ? (
              <StyledButton
                disabled={!permissionsArray.includes('create') ? true : false}
                onClick={this.saveAsset}
              >
                {data && data.AssetType
                  ? `Create ${_.startCase(_.toLower(data.AssetType))}`
                  : `Create ${_.startCase(_.toLower(''))}`}
              </StyledButton>
            ) : (
              <StyledButton
                disabled={!permissionsArray.includes('update') ? true : false}
                onClick={this.updateAsset}
              >
                {data && data.AssetType
                  ? `Update ${_.startCase(_.toLower(data.AssetType))}`
                  : `Update ${_.startCase(_.toLower(''))}`}
              </StyledButton>
            )}
          </DrawerFooter>
        </Drawer>
        <StyledTable
          columns={[
            {
              title:
                data && data.AssetType
                  ? `${_.startCase(_.toLower(data.AssetType))} Code`
                  : `${_.startCase(_.toLower(''))} Code`,
              dataIndex: 'AssetCode',
              key: 'AssetCode',
              ...this.getColumnSearchProps('AssetCode')
            },
            {
              title:
                data && data.AssetType
                  ? `${_.startCase(_.toLower(data.AssetType))} Name`
                  : `${_.startCase(_.toLower(''))} Name`,
              dataIndex: 'Name',
              key: 'Name',
              ...this.getColumnSearchProps('Name'),
              render: (text) => {
                return {
                  props: {
                    // style: {
                    //     background:
                    //         Math.abs(key % 2) === 1 ? "rgb(251, 250, 250)" : "#fff"
                    // }
                  },
                  children: <span>{text}</span>
                };
              }
            },
            {
              title: 'Action',
              dataIndex: 'Action',
              key: 'Action',
              render: (text, row) => {
                return {
                  children: (
                    <div>
                      <EditOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          this.editRow(e, row);
                        }}
                      />
                      <Divider type="vertical" />

                      <Popconfirm
                        title="Are you sure delete Asset?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          this._deleteAssetData(row);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          theme="twoTone"
                          twoToneColor="red"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Popconfirm>
                      <Divider type="vertical" />
                      <StyledButton onClick={(e) => this.navigateNextPage(e, row)}>
                        Next
                      </StyledButton>
                    </div>
                  )
                };
              }
            }
          ]}
          dataSource={datasource}
          size="large"
          pagination={
            datasource.length > 10
              ? {
                  pageSize: '10'
                }
              : false
          }
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        currentAsset,
        setBreadcrumbs,
        setDeviceBreadcrumbs,
        currentDevice
      },
      dispatch
    )
  };
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetComponent);
