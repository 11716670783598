import React from 'react';
import axios from 'axios';
import { Form, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import localStorage from '../../../utils/localStorage';
import { getTranslationCache } from '../../../selectors/language';
import { constant } from '../Constants';

class EditProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: props.userProfile,
      translationCache: props.translationCache || []
    };
  }

  editProfile = React.createRef();

  componentDidMount() {
    const { userProfile } = this.props;
    this.editProfile.current.setFieldsValue(
      userProfile && userProfile.user ? userProfile.user : {}
    );
    this.setState({
      translationCache: this.props.translationCache,
      userProfile: this.props.userProfile
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userProfile !== this.props.userProfile) {
      const { userProfile } = this.props;
      this.editProfile.current.setFieldsValue(
        userProfile && userProfile.user ? userProfile.user : {}
      );
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  _updateProfile = (profile) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const changePassword = {
      method: 'PATCH',
      url: `/api/user/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: profile
    };
    axios(changePassword)
      .then(() => {
        message.success('Profile updated');
        this.props.actions.getLoggedInProfile();
      })
      .catch(function () {
        message.error('Error');
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { userProfile } = this.state;

    const onFinish = (values) => {
      this._updateProfile({ ...userProfile.user, ...values });
    };

    const onFinishFailed = () => {
      message.error('Please enter valid FirstName and LastName');
    };

    return (
      <Form
        ref={this.editProfile}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={this.translation(constant.First_Name)}
          name="FirstName"
          rules={[{ required: true, message: 'Please input your FirstName!!' }]}
        >
          <Input style={{ width: '300px' }} />
        </Form.Item>

        <Form.Item
          label={this.translation(constant.Last_Name)}
          name="LastName"
          rules={[{ required: true, message: 'Please input your LastName!' }]}
        >
          <Input style={{ width: '300px' }} />
        </Form.Item>
        <Form.Item
          label={this.translation(constant.Email)}
          name="Email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input style={{ width: '300px' }} disabled />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {this.translation(constant.Update_Profile)}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EditProfileComponent);
