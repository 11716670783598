/* eslint-disable no-undef */
/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Form,
  message,
  Col,
  Button,
  Tooltip,
  Collapse,
  Input,
  Popconfirm,
  Select,
  Tabs,
  TimePicker
  // DatePicker,
  // Drawer
} from 'antd';

import axios from 'axios';
// import { ApartmentOutlined } from '@ant-design/icons';
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  DownOutlined
} from '@ant-design/icons';
import { decode as base64_decode } from 'base-64';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import TableSelect from '../../../Widgets/TableSelect';
import history from '../../../../../commons/history';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitJobPlan,
  getTaskListByEquipmentandTaskType,
  getTaskById,
  getJobPlanById,
  getHierarchyList,
  filterTaskByReference,
  getUsersList,
  getStatusListTask,
  getUOMList,
  getSkillTypeList
} from '../../../Utils/FormCalls';
import {
  StyledButton,
  StyledCollapse,
  StyledComponent,
  StyledForm,
  StyledSubForm,
  StyledSubHeading,
  StyledModal,
  StyledTabs
} from '../../../CSS/style';
import Highlighter from 'react-highlight-words';
import themeSettings from '../../../Utils/themeSettings.json';

import { getTranslationCache } from '../../../../../selectors/language';
import { ToolsForm, LaborForm, MaterialForm, ItemForm } from './TaskRequirements';
import translation from '../../../Utils/translation';
import localStorage from '../../../../../utils/localStorage';

import { constant } from '../../../Utils/constants';
import FileBase64 from '../../../Utils/FileUpload';
import InputComponent from '../../../Widgets/InputComponent';

const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
class JobPlanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      TaskList: [],
      usersList: [],
      uomList: [],
      statusList: [],
      tableData: [],
      translationCache: props.translationCache || {},
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      hierarchyList: [],
      viewObservationType: false,
      SkillTypeList: [],
      viewfieldDataType: false,
      fileUploadList: [],
      disableAdd: false
    };
  }
  formHeader = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    let deviceList = await getDeviceList();
    this.setState({ deviceList: deviceList });
    let organisationsList = await getOrganisationsList();
    this.setState({ organisationsList: organisationsList });
    let SkillTypeList = await getSkillTypeList();
    this.setState({ SkillTypeList: SkillTypeList });
    let sitesList = await getSitesList();
    let formObject = this.formHeader.current ? this.formHeader.current.getFieldsValue() : {};
    if (this.formHeader.current) this.formHeader.current.setFieldsValue(formObject);
    this.setState({ sitesList, formObject });
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let uomList = await getUOMList();
      this.setState({ uomList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let statusList = await getStatusListTask();
      this.setState({ statusList: statusList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      this._getDataById();
    }
  };
  _getDataById = async (jobplanId) => {
    let formObject = this.formHeader.current.getFieldsValue();
    let path = history.location.pathname.split('/rubus/AMM/JobPlanDetails/');
    let formId = jobplanId ? jobplanId : base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getJobPlanById(formId, 'withId');
      if (formObject && formObject.Type === 'observationType') {
        this.setState({ viewObservationType: true });
      } else {
        this.setState({ viewfieldDataType: true });
      }
      let TaskList = await getTaskListByEquipmentandTaskType(
        'Active',
        formObject && formObject['Equipment'],
        formObject && formObject['Type']
      );
      this.setState({ TaskList });
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['StartTime']
                ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['EndTime']
                ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['MeasurementDate']
                ? (value['MeasurementDate'] = moment
                    .utc(value.MeasurementDate, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['TaskData'] &&
                value['TaskData'].map((item, i) => {
                  return value['TaskData'][i]
                    ? value['TaskData'][i]['EstimatedDuration']
                      ? (value['TaskData'][i]['EstimatedDuration'] = moment
                          .utc(value['TaskData'][i]['EstimatedDuration'], 'YYYY-MM-DD HH:mm:ss')
                          .local())
                      : null
                    : null;
                }))
            }
          : _.noop();
      });
    } else if (jobplanId) {
      formObject = await getJobPlanById(jobplanId, 'withId');
      if (formObject && formObject.Type === 'observationType') {
        this.setState({ viewObservationType: true, fileUploadList: {} });
      } else {
        this.setState({ viewfieldDataType: true, fileUploadList: {} });
      }
      // let TaskList = await getTaskListByEquipmentandTaskType(
      //   'Active',
      //   formObject && formObject['DeviceCode'],
      //   formObject && formObject['Type']
      // );
      // this.setState({ TaskList });
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['StartTime']
                ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['EndTime']
                ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['MeasurementDate']
                ? (value['MeasurementDate'] = moment
                    .utc(value.MeasurementDate, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['TaskData'] &&
                value['TaskData'].map((item, i) => {
                  return value['TaskData'][i]
                    ? value['TaskData'][i]['EstimatedDuration']
                      ? (value['TaskData'][i]['EstimatedDuration'] = moment(
                          value['TaskData'][i]['EstimatedDuration'],
                          'YYYY-MM-DD HH:mm:ss'
                        ))
                      : null
                    : null;
                }))
            }
          : _.noop();
      });
      this.setState({ fileUploadList: {} });
    }
    this.setState({
      formObject
    });
    if (formObject) {
      this.formHeader.current.setFieldsValue(formObject);
    }
  };
  handleSelect = (value, mainIndex, subindex, keyName, selectedObject) => {
    let { SkillTypeList } = this.state;
    if (keyName === 'Labor') {
      let skill = SkillTypeList.filter((item) => item.Id === selectedObject[0]['SkillTypeId']);
      let list = this.formHeader.current.getFieldValue('TaskData');
      let TaskData = list;
      TaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['SkillTypeId'] =
            skill && skill[mainIndex] && Array.isArray(skill) && skill[mainIndex].Id
              ? skill[mainIndex].Id
              : null;
          item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      this.formHeader.current.setFieldsValue({ TaskData: TaskData });
    } else {
      let list = this.formHeader.current.getFieldValue('TaskData');
      let TaskData = list;
      TaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      this.formHeader.current.setFieldsValue({ TaskData: TaskData });
    }
    // let skill = SkillTypeList.filter((item) => item.Id === selectedObject[0]['SkillTypeId'])

    // let list = this.formHeader.current.getFieldValue('TaskData');
    // let TaskData = list;
    // TaskData.map((item, index) => {
    //   if (index === mainIndex) {

    //     item[keyName][subindex]['SkillTypeName'] = skill[mainIndex].Description;
    //     item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
    //     item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
    //   }
    //   return {};
    // });
  };
  handleChange = async (value, key) => {
    // let { formObject, deviceList } = this.state;
    // let formObject = this.formHeader.current.setFieldsValue();
    const { deviceList } = this.state;
    let formObject = this.formHeader.current.getFieldsValue();
    let selectedDevice = deviceList.filter((test) => test.AliasCode === value);
    if (key === 'datetime') {
      formObject = {
        ...formObject,
        [key]: moment(value).format('YYYY-MM-DD')
      };
    } else if (key === 'EstimatedDuration') {
      formObject = {
        ...formObject,
        [key]: moment(value, 'HH:mm')
      };
    } else if (key === 'DeviceCode') {
      //var TaskList = await getTaskListByEquipmentandTaskType('Active', value);
      // let selectedEquip = Object.keys(deviceList)
      //   .map((r) => deviceList[r])
      //   .filter((item) => item.DeviceCode === value);
      formObject = this.formHeader.current.getFieldsValue();
      formObject = {
        ...formObject,
        [key]: value,
        EquipmentDescription: selectedDevice[0].Name
      };
      /* filtering TaskList from selectedtasks */
      // TaskList = filterTaskByReference(
      //   TaskList
      //   //formObject && formObject['TaskData'] ? formObject['TaskData'] : []
      // );
    } else if (key === 'Type') {
      if (value === 'observationType') {
        formObject = this.formHeader.current.getFieldsValue();
        formObject = {
          ...formObject,
          TaskData: []
        };
        this.setState({
          viewObservationType: true,
          viewfieldDataType: false,
          formObject
        });
        this.formHeader.current.setFieldsValue(formObject);
      } else {
        formObject = {
          ...formObject,
          TaskData: []
        };
        this.setState({
          viewfieldDataType: true,
          viewObservationType: false,
          formObject
        });
        this.formHeader.current.setFieldsValue(formObject);
      }

      let equip = formObject['Equipment'];

      if (equip) {
        formObject = {
          ...formObject,
          [key]: value
        };
        var TaskList = await getTaskListByEquipmentandTaskType('Active', equip, value);
        if (TaskList && Array.isArray(TaskList) && TaskList.length !== 0) {
          TaskList = filterTaskByReference(
            TaskList,
            formObject && formObject['TaskData'] ? formObject['TaskData'] : []
          );
          this.setState({ TaskList, [key]: value });
        } else {
          message.error('No tasks exists for selected equipment');
        }
      } else {
        formObject = {
          ...formObject,
          [key]: []
        };
        message.error('please select equipment before selecting task type');
      }
    } else {
      formObject = {
        ...formObject,
        [key]: value
      };
      this.setState({ [key]: value });
    }
    this.formHeader.current &&
      this.formHeader.current !== null &&
      this.formHeader.current.setFieldsValue(formObject);
    this.setState({ formObject, TaskList });
  };

  taskFileupload = async (values, key, fileDataObject, index, TaskId) => {
    let formObject = this.formHeader.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (
      formObject &&
      formObject['TaskData'] &&
      formObject['TaskData'][index] &&
      formObject['TaskData'][index][key]
    ) {
      formObject['TaskData'][index][key].push(...values);
    } else if (formObject['TaskData'][index] !== undefined) {
      formObject['TaskData'][index][key] = values;
    } else {
      formObject['TaskData'].push({ [key]: values });
    }
    formObject &&
      formObject['TaskData'].map((item, index) => {
        formObject['TaskData'][index][key] =
          formObject['TaskData'][index][key] &&
          formObject['TaskData'][index][key].reduce((acc, current) => {
            const x = acc.find((item) => item.Name === current.Name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        return {};
      });
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    // fileData.append('files', fileDataObject);
    let TaskData = [...(fileUploadList && fileUploadList.TaskData ? fileUploadList.TaskData : [])];
    TaskData.push({ fileDataObject, TaskId });
    fileUploadList = {
      ...fileUploadList,
      TaskData
    };

    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
  };
  onFinish = async (values) => {
    let { formObject, fileUploadList } = this.state;
    delete values.EquipmentDescription;
    delete formObject.EquipmentDescription;
    let payload = {
      ...formObject,
      ...values,
      SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
    };
    if (
      formObject &&
      formObject.TaskData &&
      Array.isArray(formObject.TaskData) &&
      formObject.TaskData.length > 0
    ) {
      let statusResponse = await submitJobPlan(payload, fileUploadList);
      if (statusResponse && statusResponse.message) {
        this._getDataById(statusResponse.Id);
        this.setState({ fileUploadList: [] });
        if (
          statusResponse &&
          statusResponse.response &&
          statusResponse.response.status &&
          (statusResponse.response.status === 400 || statusResponse.response.status === 500)
        ) {
          console.log(statusResponse.response.data.message);
        } else {
          message.success(statusResponse.message);
        }
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      message.error('Please Add Atleast One Task');
    }
  };
  onFinishFailed = () => {
    message.error('Please enter mandatory fields');
  };
  onReset = () => {
    if (this.formHeader.current) {
      this.formHeader.current.resetFields();
    }
  };

  datepickerchange = (date, dateString, index, key) => {
    // this.formHeader.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.formHeader.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.formHeader.current.getFieldValue() &&
      this.formHeader.current.getFieldValue().TaskData &&
      this.formHeader.current.getFieldValue().TaskData[index] &&
      this.formHeader.current.getFieldValue().TaskData[index]['StartTime']
    ) {
      let startTime =
        this.formHeader.current.getFieldValue() &&
        this.formHeader.current.getFieldValue().TaskData &&
        this.formHeader.current.getFieldValue().TaskData[index] &&
        this.formHeader.current.getFieldValue().TaskData[index]['StartTime'];
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (
      key &&
      key === 'StartTime' &&
      this.formHeader.current.getFieldValue() &&
      this.formHeader.current.getFieldValue().TaskData &&
      this.formHeader.current.getFieldValue().TaskData[index] &&
      this.formHeader.current.getFieldValue().TaskData[index]['EndTime']
    ) {
      let endTime =
        this.formHeader.current.getFieldValue() &&
        this.formHeader.current.getFieldValue().TaskData &&
        this.formHeader.current.getFieldValue().TaskData[index] &&
        this.formHeader.current.getFieldValue().TaskData[index]['EndTime'];
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }
    formObject['TaskData'][index]['EstimatedDuration'] = EstimatedDuration;
    this.formHeader.current.setFieldsValue(formObject);
  };
  getDisabledHours = (e, index, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue().TaskData[index] &&
            this.formHeader.current.getFieldValue().TaskData[index]['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue().TaskData[index] &&
            this.formHeader.current.getFieldValue().TaskData[index]['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue().TaskData[index] &&
            this.formHeader.current.getFieldValue().TaskData[index]['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue().TaskData[index] &&
            this.formHeader.current.getFieldValue().TaskData[index]['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var J = 0; J <= 60; J++) {
          if (J > moment(EndDateTime).minutes() - 1) {
            minutes.push(J);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var j = 0; j < moment(StartDateTime).hours(); j++) {
        if (j < 25) {
          hours.push(j);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var k = 0; k < moment(StartDateTime).minutes() + 1; k++) {
          if (k < 61) {
            minutes.push(k);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  TaskOnChange = async (value) => {
    // let { formObject } = this.state;
    let formObject = this.formHeader.current.getFieldValue();
    let TaskData = await getTaskById(value, 'withoutId');

    if (TaskData && TaskData !== undefined) {
      let data =
        this.formHeader.current.getFieldsValue() &&
        this.formHeader.current.getFieldsValue().TaskData !== undefined
          ? this.formHeader.current.getFieldsValue().TaskData
          : [];
      _.cloneDeepWith(TaskData, (value) => {
        return value !== undefined && value !== null
          ? {
              ...value,
              ...(value['StartTime']
                ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['EndTime']
                ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['MeasurementDate']
                ? (value['MeasurementDate'] = moment.utc(
                    value.MeasurementDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ))
                : null),

              ...(value['EstimatedDuration']
                ? (value['EstimatedDuration'] = moment(
                    value.EstimatedDuration,
                    'YYYY-MM-DD HH:mm:ss'
                  ))
                : null)
            }
          : _.noop();
      });
      data.push(TaskData);
      formObject = {
        ...formObject,
        TaskData: data
      };

      this.formHeader.current.setFieldsValue(formObject);
      this.setState({ selectedTask: value, formObject });
    }
  };

  // hierarchyDropdownChange = (value, mainindex) => {
  //   // let formObject = this.formHeader.current.getFieldsValue()
  //   // formObject = {
  //   //     ...formObject,
  //   //     "DeviceCode": value.Code,
  //   //     "EquipmentDescription": value.Name,
  //   // }
  //   // this.formHeader.current.setFieldsValue(formObject)
  //   let { formObject } = this.state;
  //   let list = this.formHeader.current.getFieldValue('TaskData');
  //   let TaskData = list;
  //   TaskData.map((item, index) => {
  //     if (index === mainindex) {
  //       item.DeviceCode = value.Code;
  //     }
  //     return {};
  //   });
  //   formObject = {
  //     ...formObject,
  //     TaskData: TaskData
  //   };
  //   this.setState({
  //     visible: false,
  //     formObject
  //   });
  //   this.formHeader.current.setFieldsValue({ TaskData: TaskData });
  // };

  onDrawerCancel = () => {
    this.setState({
      visible: false
    });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  onTaskSelection = (val) => {
    if (val === 'observationType') {
      this.setState({
        viewObservationType: true,
        viewfieldDataType: false
      });
    } else {
      this.setState({
        viewfieldDataType: true,
        viewObservationType: false
      });
    }
  };
  ObservationCancel = () => {
    this.setState({
      viewObservationType: false
    });
  };
  ObservationoK = () => {
    this.setState({
      viewObservationType: false
    });
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.formHeader.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.formHeader.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      EquipmentDescription: value.Name
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  removeTask = (key) => {
    let formObject = this.formHeader.current.getFieldValue();
    formObject = {
      ...formObject,
      TaskData:
        formObject &&
        formObject.TaskData &&
        formObject.TaskData.filter((task, index) => index !== key)
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  add = (taskIndex, key) => {
    // this.props.noJobplan();
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      TaskData:
        formObject.TaskData &&
        Array.isArray(formObject.TaskData) &&
        formObject.TaskData.map((task, index) => {
          if (index === taskIndex) {
            let newObj = null;
            let reqArray = task && task[key] ? task[key] : [];
            reqArray.push(newObj);
            if (key === 'Tools') {
              return { ...task, Tools: reqArray };
            } else if (key === 'Labor') {
              return { ...task, Labor: reqArray };
            } else if (key === 'Materials') {
              return { ...task, Materials: reqArray };
            } else if (key === 'Items') {
              return { ...task, Items: reqArray };
            } else return task;
          } else {
            return task;
          }
        })
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };

  remove = (taskIndex, toolIndex, key) => {
    let formObject = this.formHeader.current.getFieldsValue();
    let ToolsList =
      formObject &&
      formObject.TaskData &&
      formObject.TaskData[taskIndex] &&
      formObject.TaskData[taskIndex][key];
    if (ToolsList && ToolsList[toolIndex]) {
      let Tools =
        ToolsList &&
        ToolsList.filter((tool, index) => {
          return toolIndex !== index;
        });

      formObject = {
        ...formObject,
        TaskData:
          formObject.TaskData &&
          Array.isArray(formObject.TaskData) &&
          formObject.TaskData.map((tasks, index) => {
            if (index === taskIndex) {
              let ToolsArray = tasks && tasks[key] ? tasks[key] : [];
              Tools =
                ToolsArray &&
                Array.isArray(ToolsArray) &&
                ToolsArray.filter((tool, tlIndex) => {
                  return tlIndex !== toolIndex;
                });
              if (key === 'Tools') {
                return { ...tasks, Tools: Tools };
              } else if (key === 'Labor') {
                return { ...tasks, Labor: Tools };
              } else if (key === 'Materials') {
                return { ...tasks, Materials: Tools };
              } else if (key === 'Items') {
                return { ...tasks, Items: Tools };
              } else return tasks;
            } else {
              return tasks;
            }
          })
      };
      this.formHeader.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
  };
  render() {
    let { formObject, TaskList, selectedTask, usersList, statusList, translationCache, uomList } =
      this.state;

    const children = [];
    for (let i = 10; i < 36; i++) {
      children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const styledCollapse = (formObject, key, translationCache) => (
      <span>
        <span style={{ fontWeight: 'bold' }}>{translation(translationCache, 'Task Name')}</span> :
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {formObject['TaskData'] &&
          formObject['TaskData'][key] &&
          formObject['TaskData'][key]['TaskName']
            ? formObject['TaskData'][key]['TaskName']
            : ''}
        </span>
        <span style={{ fontWeight: 'bold' }}>
          {translation(translationCache, 'Task Description')}
        </span>{' '}
        :
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {formObject['TaskData'] &&
          formObject['TaskData'][key] &&
          formObject['TaskData'][key]['TaskDescription']
            ? formObject['TaskData'][key]['TaskDescription']
            : ''}
        </span>
      </span>
    );
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
      >
        <StyledForm
          name="jobplan"
          theme={themeSettings}
          ref={this.formHeader}
          initialValues={formObject}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
          validateMessages={validateMessages}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <StyledButton theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.submit)}
              </StyledButton>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item name="Id" rules={[{ required: false }]}></Form.Item>
            <Form.Item
              label={translation(translationCache, constant.jobPlan)}
              name="JobPlanName"
              style={{
                width: '100%',
                flex: '50%'
              }}
              rules={[{ required: true }]}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Enter JobPlan')}
                value={formObject && formObject.JobPlanName ? formObject.JobPlanForm : ''}
                style={{ width: '70%' }}
                //maxLength={30}
                disabled={formObject && formObject.Id ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.jobPlanDescription)}
              name="JobPlanDescription"
              style={{ width: '100%', flex: '50%' }}
              rules={[{ required: true }]}
            >
              <InputComponent
                //maxLength={100}
                placeholder={translation(translationCache, 'Enter Description')}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: '100%',
                flex: '50%'
              }}
              label={translation(translationCache, constant.equipment)}
              name="DisplayCode"
              rules={[{ required: true }]}
            >
              <Input
                disabled={true}
                //maxLength={150}
                style={{ width: '70%' }}
                placeholder={translation(translationCache, 'Select Equipment')}
                suffix={
                  <DownOutlined
                    style={{
                      // color: '#1a3b5c',
                      color: formObject && formObject.Id ? null : '#1a3b5c',
                      cursor: formObject && formObject.Id ? 'not-allowed' : null,
                      opacity: formObject && formObject.Id ? '0.8' : null
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (formObject && !formObject.Id) {
                        this.getDeviceData('visible');
                      }
                    }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   this.getDeviceData('visible');
                    // }}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.equipmentDescription)}
              name="EquipmentDescription"
              style={{
                width: '100%',
                flex: '50%'
              }}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Autopopulate Basis To The Equipment')}
                disabled={true}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.status)}
              name="StatusId"
              style={{ width: '100%', flex: '50%' }}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={translation(translationCache, 'Select Status')}
                style={{ width: '70%' }}
              >
                {statusList.map((status, index) => {
                  return (
                    <Option key={index} value={status.Value}>
                      {' '}
                      {translation(translationCache, status.Key)}{' '}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ width: '100%', flex: '50%' }}
              label={translation(translationCache, constant.TaskType)}
              name="Type"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '70%' }}
                disabled={formObject && formObject.Id ? true : false}
                placeholder={translation(translationCache, 'Select Task Type')}
                defaultValue={selectedTask}
                // onChange={(value) => this.onTaskSelection(value)}
                onChange={(value) => this.handleChange(value, 'Type')}
                notFoundContent={'Please Select DeviceCode For Tasks'}
              >
                <option value="observationType">
                  {translation(translationCache, constant.observationType)}
                </option>
                <option value="fieldDataType">
                  {translation(translationCache, constant.fieldDataType)}
                </option>
              </Select>
            </Form.Item>
            <Form.Item name="TaskName"></Form.Item>

            <Form.Item name="Equipment"></Form.Item>
          </div>
          <StyledSubHeading theme={themeSettings}>
            {translation(translationCache, 'Task Details')}
          </StyledSubHeading>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              style={{ width: '100%', flex: '50%' }}
              label={translation(translationCache, constant.addTask)}
              name="TaskName"
              rules={[{ required: false }]}
            >
              <Select
                style={{ width: '70%' }}
                placeholder={translation(translationCache, 'Select Task')}
                multiple
                defaultValue={selectedTask}
                onChange={(value) => this.TaskOnChange(value)}
                notFoundContent={translation(translationCache, constant.selectEquipmentForTask)}
              >
                {TaskList &&
                  Array.isArray(TaskList) &&
                  TaskList.map((task, index) => {
                    return (
                      <Option
                        key={index}
                        disabled={
                          this.formHeader.current &&
                          this.formHeader.current.getFieldsValue() &&
                          this.formHeader.current.getFieldsValue().TaskData &&
                          this.formHeader.current
                            .getFieldsValue()
                            .TaskData.filter((item) => item.TaskName === task.TaskName).length > 0
                            ? true
                            : false
                        }
                        value={task.Id}
                      >
                        {task.TaskName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item style={{ width: '100%', flex: '50%' }} name="TaskName">
              <InputComponent
                //maxLength={20}
                style={{ display: 'none' }}
              />
            </Form.Item>
          </div>
          <Form.List
            name="TaskData"
            rules={[
              {
                validator: async (_, TaskData) => {
                  if (!TaskData || TaskData.length === 0) {
                    return Promise.reject(new Error('At least 1 task'));
                  }
                }
              }
            ]}
          >
            {(fields) => (
              <>
                {fields && Array.isArray(fields) && fields.length > 0 ? (
                  <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                    {fields.map(({ key, name }, index) => (
                      <Panel
                        key={index}
                        header={styledCollapse(formObject, key, translationCache)}
                        extra={
                          <Popconfirm
                            title={translation(
                              translationCache,
                              'Are you sure that you want to delete the task？'
                            )}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => this.removeTask(key)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip
                              title={translation(translationCache, 'Delete')}
                              placement="bottom"
                            >
                              <DeleteOutlined />
                            </Tooltip>
                          </Popconfirm>
                        }
                      >
                        <div>
                          {formObject.Type && formObject.Type === 'observationType' ? (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.taskItem)}
                                name={[name, 'Name']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Name')}
                                  value={formObject.Name}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.taskDescription)}
                                name={[name, 'Description']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Description')}
                                  value={formObject.Description}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.observation)}
                                name={[name, 'Observation']}
                                rules={[{ required: false }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Observation')}
                                  value={formObject.Observation}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.criterion)}
                                name={[name, 'Criteria']}
                                rules={[{ required: false }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Criteria')}
                                  value={formObject.Criteria}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.method)}
                                name={[name, 'Method']}
                                rules={[{ required: false }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Method')}
                                  value={formObject.Method}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>

                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.estimatedDuration)}
                                name={[name, 'EstimatedDuration']}
                              >
                                <TimePicker
                                  style={{ width: '70%' }}
                                  format={'HH:mm'}
                                  onChange={(value) =>
                                    this.handleChange(value, 'EstimatedDuration')
                                  }
                                />
                              </Form.Item>

                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.remarks)}
                                name={[name, 'Remarks']}
                              >
                                <TextArea
                                  rows={3}
                                  style={{ width: '70%' }}
                                  placeholder={translation(translationCache, 'Enter Remarks')}
                                />
                                {/* <InputComponent
                                  placeholder={translation(translationCache, 'description')}="Enter Remarks"
                                  value={formObject.Remarks}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                /> */}
                              </Form.Item>
                              <Form.Item
                                label={translation(translationCache, constant.attachments)}
                                name={[name, 'Attachments']}
                                style={{ width: '100%', flex: '50%' }}
                              >
                                <FileBase64
                                  disableAdd={
                                    formObject &&
                                    formObject.TaskData &&
                                    formObject.TaskData[index] &&
                                    formObject.TaskData[index].Id
                                      ? false
                                      : true
                                  }
                                  style={{ width: '70%' }}
                                  downloadClassName={'formdownloadInput'}
                                  Attachments={
                                    formObject &&
                                    formObject.TaskData &&
                                    formObject.TaskData[index] &&
                                    formObject.TaskData[index].Attachments
                                      ? formObject.TaskData[index].Attachments
                                      : []
                                  }
                                  fileUpload={(values, key, fileDataObject) => {
                                    this.taskFileupload(
                                      values,
                                      key,
                                      fileDataObject,
                                      index,

                                      formObject &&
                                        formObject.TaskData &&
                                        formObject.TaskData[index] &&
                                        formObject.TaskData[index].Id
                                        ? formObject.TaskData[index].Id
                                        : ''
                                    );
                                  }}
                                ></FileBase64>
                              </Form.Item>
                            </div>
                          ) : null}
                          {formObject.Type && formObject.Type === 'fieldDataType' ? (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.taskItem)}
                                name={[name, 'Name']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Task')}
                                  value={formObject.Task}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.taskDescription)}
                                name={[name, 'Description']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Description')}
                                  value={formObject.Description}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              {/* <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.measurementDate)}
                                name={[name, 'MeasurementDate']}
                              >
                                <DatePicker
                                  disabledDate={(d) => d.isBefore(moment().format('YYYY-MM-DD'))}
                                  // inputReadOnly={true}
                                  style={{ width: '70%' }}
                                  placeholder={translation(translationCache, 'description')}="Select Measurement Date"
                                  format="YYYY-MM-DD"
                                  // onChange={(date, dateString) => {
                                  //   this.datepickerchange(date, dateString, key, 'EndTime');
                                  // }}
                                />
                              </Form.Item> */}
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.measurementValue)}
                                name={[name, 'MeasurementValue']}
                              >
                                <InputComponent
                                  placeholder={translation(
                                    translationCache,
                                    'Enter Measurement Value'
                                  )}
                                  value={formObject.MeasurementValue}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.UOM)}
                                name={[name, 'UOM']}
                              >
                                <Select
                                  placeholder={translation(translationCache, 'Select UOM')}
                                  style={{ width: '70%' }}
                                >
                                  {uomList.map((uom, index) => {
                                    return (
                                      <Option key={index} value={uom.Key}>
                                        {' '}
                                        {uom.Key}{' '}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.inspectionMethod)}
                                name={[name, 'Method']}
                              >
                                <InputComponent
                                  placeholder={translation(
                                    translationCache,
                                    'Enter Inspection Method'
                                  )}
                                  value={formObject.InspectionMethod}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.criterion)}
                                name={[name, 'Criteria']}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Criteria')}
                                  value={formObject.Criteria}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              {/* <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.estimatedDuration)}
                                name={[name, 'EstimatedDuration']}
                              >
                                <InputComponent
                                  style={{ width: '70%' }}
                                  // format="HH:mm:ss"
                                  // defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                  // onChange={(value) =>
                                  //   this.handleChange(value, 'EstimatedDuration')
                                  // }
                                />
                              </Form.Item> */}
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.observation)}
                                name={[name, 'Observation']}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Enter Observation')}
                                  value={formObject.Observation}
                                  style={{ width: '70%' }}
                                  //maxLength={30}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.recordedBy)}
                                name={[name, 'RecordBy']}
                              >
                                <Select
                                  placeholder={translation(translationCache, 'Select RecordedBy')}
                                  style={{ width: '70%' }}
                                >
                                  {usersList.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.Id}>
                                        {' '}
                                        {item.FirstName}{' '}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label={translation(translationCache, constant.attachments)}
                                name={[name, 'Attachments']}
                                style={{ width: '100%', flex: '50%' }}
                              >
                                <FileBase64
                                  style={{ width: '70%' }}
                                  downloadClassName={'formdownloadInput'}
                                  Attachments={
                                    formObject &&
                                    formObject.TaskData &&
                                    formObject.TaskData[index] &&
                                    formObject.TaskData[index].Attachments
                                      ? formObject.TaskData[index].Attachments
                                      : []
                                  }
                                  fileUpload={(values, key, fileDataObject) => {
                                    this.taskFileupload(
                                      values,
                                      key,
                                      fileDataObject,
                                      index,

                                      formObject &&
                                        formObject.TaskData &&
                                        formObject.TaskData[index] &&
                                        formObject.TaskData[index].Id
                                        ? formObject.TaskData[index].Id
                                        : ''
                                    );
                                  }}
                                ></FileBase64>
                              </Form.Item>
                              <Form.Item style={{ width: '100%', flex: '50%' }}></Form.Item>
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
                            <StyledTabs
                              theme={themeSettings}
                              // className={'cardContainer'}
                              defaultActiveKey="1"
                              type="card"
                              size={'middle'}
                            >
                              <TabPane tab={translation(translationCache, constant.tool)} key="1">
                                <Col span={24}>
                                  <Form.Item>
                                    <ToolsForm
                                      fieldKey={name}
                                      formObject={
                                        this.formHeader.current !== null
                                          ? this.formHeader.current.getFieldsValue()
                                          : {}
                                      }
                                      onToolChange={this.handleSelect}
                                      onRemove={this.remove}
                                      onAdd={this.add}
                                    />
                                  </Form.Item>
                                </Col>
                              </TabPane>
                              <TabPane tab={translation(translationCache, constant.labor)} key="2">
                                <Col span={24}>
                                  <Form.Item>
                                    <LaborForm
                                      fieldKey={name}
                                      formObject={
                                        this.formHeader.current !== null
                                          ? this.formHeader.current.getFieldsValue()
                                          : {}
                                      }
                                      onAdd={this.add}
                                      onRemove={this.remove}
                                      onLaborChange={this.handleSelect}
                                    />
                                  </Form.Item>
                                </Col>
                              </TabPane>
                              <TabPane
                                tab={translation(translationCache, constant.material)}
                                key="3"
                              >
                                <Col span={24}>
                                  <Form.Item>
                                    <MaterialForm
                                      fieldKey={name}
                                      formObject={
                                        this.formHeader.current !== null
                                          ? this.formHeader.current.getFieldsValue()
                                          : {}
                                      }
                                      onAdd={this.add}
                                      onMaterialChange={this.handleSelect}
                                      onRemove={this.remove}
                                    />
                                  </Form.Item>
                                </Col>
                              </TabPane>
                              <TabPane tab={translation(translationCache, constant.items)} key="4">
                                <Col span={24}>
                                  <Form.Item>
                                    <ItemForm
                                      fieldKey={name}
                                      formObject={
                                        this.formHeader.current !== null
                                          ? this.formHeader.current.getFieldsValue()
                                          : {}
                                      }
                                      onAdd={this.add}
                                      onRemove={this.remove}
                                      onItemChange={this.handleSelect}
                                    />
                                  </Form.Item>
                                </Col>
                              </TabPane>
                            </StyledTabs>
                          </StyledSubForm>
                        </div>
                      </Panel>
                    ))}
                  </StyledCollapse>
                ) : null}
              </>
            )}
          </Form.List>
        </StyledForm>
        <StyledModal
          open={this.state.visible}
          width={1000}
          closable
          title={translation(translationCache, constant.selectEquipment)}
          onCancel={this.onDrawerCancel}
          onOk={this.onDrawerCancel}
        >
          <TableSelect
            data={this.state.tableData}
            translationCache={this.props.translationCache}
            key="DeviceCode"
            columns={[
              {
                title: translation(translationCache, 'Equipment'),
                dataIndex: 'DisplayCode',
                key: 'DisplayCode',
                ...this.getColumnSearchProps('DisplayCode')
              },
              {
                title: translation(translationCache, 'Equipment Description'),
                dataIndex: 'Name',
                key: 'Name',
                ...this.getColumnSearchProps('Name')
              }
            ]}
            handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
          />
          {/* <HierarchyModal
            value={this.state.deviceId}
            treeData={this.state.treeData}
            translationCache={this.props.translationCache}
            language={this.props.language}
            handleDropdownChange={this.hierarchyDropdownChange}
            deviceList={this.state.deviceList}
          /> */}
        </StyledModal>
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(JobPlanForm);
