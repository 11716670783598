import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';

import ReactEcharts from '../../Echarts/Echarts';
import * as echarts from 'echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { objectOfObject } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class DottedBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    this.requestCheck = false;
    let theme = this.props.selectedThemeing;
    let option = cloneDeep(this.state.option);
    let json = [];
    let { graphprops } = this.props;
    let xaxisLabels = [];
    let lineData = [];
    let barData = [];
    let tagName = [];
    let series = [];
    let xAxis = {};
    let legend = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = objectOfObject;
      this.requestCheck = true;
    } else {
      if (
        props.graphprops.graph === 'dottedBar' &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
          (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
          (graphprops &&
            (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.dayMonthYearExists === 'true' ||
              graphprops.selection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.DeviceCode,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection,
            this.props.dashboardPayload,
            this.props.AssetCode,
            this.props.selectionDateLive,
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        } else {
          json = await getChartDataWithoutSelection(
            graphprops,
            this.abortController,
            this.props.dropdownSelection,
            this.props.selectionDateLive,
            '',
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        }
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let queryTagName = '';
      let query1TagName = '';
      if (json && json.query && json.query1) {
        queryTagName = json.query && json.query[0] && json.query[0].Name ? json.query[0].Name : '';
        query1TagName =
          json.query1 && json.query1[0] && json.query1[0].Name ? json.query1[0].Name : '';
        tagName.push(queryTagName);
        tagName.push(query1TagName);
        lineData = json.query.map((item) => {
          let date =
            graphprops && graphprops.type === 'live'
              ? moment
                  .utc(item.DateTime)
                  .tz(this.props.currentTimeZone)
                  .format('DD/MM/YYYY HH:mm:ss')
              : moment(item.DateTime).format('DD/MM/YYYY HH:mm:ss');

          xaxisLabels.push(date);
          return item.Value;
        });
        barData = json.query1.map((item) => {
          return item.Value;
        });
      }
      xAxis = {
        data: xaxisLabels,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      };
      tagName.map((item) => {
        legend.push({
          name: item,
          icon: 'roundRect',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontSize: 18,
            fontWeight: 'bold'
          }
        });
        return {};
      });
      series = [
        {
          //line
          name: queryTagName,
          type: 'line',
          smooth: true,
          showAllSymbol: true,
          symbol: 'emptyCircle',
          itemStyle: {
            normal: {
              color:
                this.props.graphprops.colors && this.props.graphprops.colors[0]
                  ? this.props.graphprops.colors[0]
                  : 'red'
            }
          },
          symbolSize: 15,
          data: lineData
        },
        {
          //bar
          name: query1TagName,
          type: 'bar',
          barWidth: 10,
          itemStyle: {
            barBorderRadius: 5,
            color:
              this.props.graphprops &&
              this.props.graphprops.colors &&
              this.props.graphprops.colors[1]
                ? this.props.graphprops.colors[1]
                : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#14c8d4' },
                    { offset: 1, color: '#43eec6' }
                  ])
          },
          data: barData
        },
        {
          //line
          name: queryTagName,
          type: 'bar',
          barGap: '-100%',
          barWidth: 10,
          itemStyle: {
            color:
              this.props.graphprops &&
              this.props.graphprops.colors &&
              this.props.graphprops.colors[2]
                ? this.props.graphprops.colors[2]
                : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(20,200,212,0.5)' },
                    { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                    { offset: 1, color: 'rgba(20,200,212,0)' }
                  ])
          },
          z: -12,
          data: lineData
        },
        {
          name: query1TagName,
          type: 'pictorialBar',
          symbol: 'rect',
          itemStyle: {
            color:
              this.props.graphprops &&
              this.props.graphprops.colors &&
              this.props.graphprops.colors[2]
                ? this.props.graphprops.colors[2]
                : '#0f375f'
          },
          symbolRepeat: true,
          symbolSize: [12, 4],
          symbolMargin: 1,
          z: -10,
          data: lineData
        }
      ];
      let seriesData = await trendLabelTranslation(series, this.props.translationCache);

      legend = await trendLabelTranslation(legend, this.props.translationCache);

      option = this.getOption();
      option.series = seriesData;
      option.xAxis = xAxis;
      option.legend = {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [...legend]
      };
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    // let colorCode =
    //   this.props.graphprops && this.props.graphprops.colors
    //     ? this.props.graphprops.colors
    //     : ['red', 'green'];
    let option = {
      tooltip: {
        trigger: 'axis',
        formatter: '{b} <br/>{a}: {c} <br/> {a1} : {c1}',
        axisPointer: {
          type: 'shadow',
          animation: true
        },

        textStyle: {
          // color: theme === "lightTheme" ? "black" : "white",
          fontSize: 16
        }
      },
      // tooltip: {
      //   trigger: 'axis',
      //   // formatter: "{b} <br/>{a}: {c} <br/> {a1} : {c1}",
      //   formatter: function (params) {
      //     var colorSpan = (color) =>
      //       '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
      //       color +
      //       '"></span>';
      //     let rez = '<p>' + params[0].axisValue + '</p>';
      //     params.forEach((item, index) => {
      //       if (index <= 1) {
      //         var xx =
      //           '<p>' +
      //           colorSpan(colorCode[index]) +
      //           '' +
      //           item.seriesName +
      //           ': ' +
      //           item.data +
      //           '</p>';
      //         rez += xx;
      //       }
      //     });
      //     let difference = params[0].data - params[1].data;
      //     rez +=
      //       '<p>' +
      //       colorSpan(colorCode[colorCode.length - 1]) +
      //       '' +
      //       'Difference' +
      //       ': ' +
      //       Number(difference).toFixed(2) +
      //       '</p>';
      //     return rez;
      //   },
      //   axisPointer: {
      //     type: 'shadow',
      //     animation: true
      //   },

      //   textStyle: {
      //     // color: theme === "lightTheme" ? "black" : "white",
      //     fontSize: 10
      //   }
      // },
      legend: {
        data: ['line', 'bar'],

        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },

      yAxis: {
        splitLine: { show: false },
        axisLabel: {
          // fontSize: 15,
        },
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          backgroundColor: '#9a9191',

          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ],

      xAxis: {
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      },
      // legend: {
      //   textStyle: { color: theme === "lightTheme" ? "black" : "white" },
      // },
      series: []
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(DottedBar);
