import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import { createStructuredSelector } from 'reselect';
import { Modal, Table, message, Button } from 'antd';
import { AreaChartOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import './singlehmi.css';
import history from '../../commons/history';
import { getHmiAllParams, getReportNametoDownload } from './hmicalls';
import { getTranslationCache } from '../../selectors/language';
import { constant } from './Constants';
import localStorage from '../../utils/localStorage';
import ReactEcharts from './Echarts/Echarts';
import { getCurrentTimezone } from '../../selectors/layout';

const columns = [
  {
    title: 'Parameter Name',
    dataIndex: 'Parameter',
    key: 'Parameter'
  },
  {
    title: 'Value',
    dataIndex: 'Value',
    key: 'Value'
  },
  {
    title: 'UOM',
    dataIndex: 'UOM',
    key: 'UOM'
  }
];

class Text extends React.Component {
  timeTicket = null;
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      visible: false,
      graphData: [],
      dataSource: [],
      dataSourceStatus: [],

      //selectedRowKeys: [],
      selectedParameters: [],
      translationCache: props.translationCache || [],
      visibleGraph: false,
      option: {},
      data: [],
      dynamicColumns: [],
      dynamicDatasource: [],
      dynamicVisible: false
    };
  }
  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    clearInterval(this.timeTicket);
    this.setState({ visible: false });
  };
  handleGraphOk = () => {
    this.setState({ visibleGraph: false });
  };
  handleGraphCancel = () => {
    clearInterval(this.timeTicket);
    this.setState({ visibleGraph: false });
  };

  handleVisibleOk = () => {
    this.setState({ dynamicVisible: false });
  };
  handleVisibleCancel = () => {
    clearInterval(this.timeTicket);
    this.setState({ dynamicVisible: false });
  };

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  componentDidMount() {
    // this.getParams()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  getParams = async () => {
    let { device, hmiName, hmiType, checkStatus } = this.props;
    // .displayName
    let dataSource = await getHmiAllParams(device, hmiName, hmiType, checkStatus);
    this.setState({ dataSource });
    this._getDataSource('HMI Modal', dataSource);
    // let operationalDataSource =
    //   dataSource &&
    //   Array.isArray(dataSource) &&
    //   dataSource.filter((data) => {
    //     return data.Type === 'operational';
    //   });
    // let statusDataSource =
    //   dataSource &&
    //   Array.isArray(dataSource) &&
    //   dataSource.filter((data) => {
    //     return data.Type === 'OnChange';
    //   });
    // this.setState({
    //   dataSource: operationalDataSource,
    //   dataSourceStatus: statusDataSource
    // });
    // this._getDataSource(displayName, operationalDataSource);
    // this._getDataSource(displayName, statusDataSource);
  };

  _getDataSource = async (displayName, dataSource) => {
    let downloadExcelPayload = {
      Name: displayName,
      Consumption: 'HMI',
      ReportType: 'singleHierarchy',
      PortName: 'MEW_BMecomo',
      SelectedDate:
        moment.utc().local().format('YYYY-MM-DD HH:mm') +
        '~' +
        moment.utc().local().format('YYYY-MM-DD HH:mm'),
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    let reportName = await getReportNametoDownload(columns, dataSource, downloadExcelPayload);

    this.setState({
      reportName: reportName && reportName.file ? reportName.file : ''
    });
  };

  onTrendClick = (parameters, deviceID) => {
    let data = queryString.stringify(
      { parameterlist: parameters, type: 'sld', device: deviceID },
      { arrayFormat: 'index' }
    );
    history.push(`/rubus/viewtrends?${data}`);
  };
  _getGraphData = (apiObject) => {
    let accessToken = localStorage.get('accessToken');
    const SiteId = localStorage.get('currentSite');
    let options = {};
    if (apiObject && apiObject.method === 'GET' && apiObject.url) {
      options = {
        method: apiObject.method,
        url: `${apiObject.url}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (apiObject && apiObject.method === 'POST' && apiObject.payload && apiObject.url) {
      options = {
        method: apiObject.method,
        url: `${apiObject.url}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        data: JSON.stringify({
          ...apiObject.payload,
          GraphType: 'line',
          SiteId
        })
      };
    }
    let data = axios(options)
      .then(({ data }) => {
        if (data && Array.isArray(data)) {
          return data;
        } else {
          return [];
        }
      })
      .catch(() => {
        return [];
      });
    return data;
  };
  getOption = () => {
    let option = {
      backgroundColor: '#fff',
      title: {},
      legend: {
        icon: 'roundRect',
        textStyle: {
          color: '#000',
          fontSize: 15
        }
      },
      // color: panelData.colors,
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLine: {
          lineStyle: {
            color: '#000'
          }
        },
        axisTick: {
          lineStyle: {
            color: '#000'
          }
        },
        axisLabel: {
          color: '#000'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: 'black'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'black'
          }
        },
        axisLabel: {
          color: '#000'
        }
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 25,
        feature: {
          // myTool1: {
          // show: true,
          // title: "custom extension method 1",
          // icon:
          // "path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z",
          // onclick: function () {
          // graph.downLoadExcel();
          // },
          // },
          saveAsImage: {
            title: 'download',
            backgroundColor: 'white',
            name: this.props.graphprops ? this.props.graphprops.title : 'name',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      dataZoom: [
        // {
        // type: "slider",
        // show: true,
        // start: 0,
        // end: 100,
        // backgroundColor: "#9a9191",
        // textStyle: {
        // color: "white",
        // },
        // },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ],
      series: {
        name: 'value',
        type: 'line',
        data: []
      }
    };
    return option;
  };
  getGraphDatasorce = async (annotation) => {
    let option = cloneDeep(this.getOption());
    let xaxis = [],
      series = [];
    let { graphData } = this.state;
    let trendData = await this._getGraphData(annotation.graphAPI);
    if (trendData.length > 0) {
      graphData.push(trendData[0]);
      this.setState({
        graphData
      });
    } else {
      message.warn('NO DATA');
    }
    graphData &&
      Array.isArray(graphData) &&
      graphData.map((data) => {
        let dateUTC = '';
        if (data.DateTime) {
          dateUTC = moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('YYYY-MM-DD HH:mm');
        }
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(dateUTC);
        }
        if (series.map((data) => data.name).includes(data.Name)) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([dateUTC, data.Value]);
        } else {
          let obj = {
            type: 'line',
            name: data.Name,
            data: [data.Value],
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            }
          };
          series.push(obj);
        }
        return {};
      });
    xaxis.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    option.xAxis.data = xaxis;
    option.series = series;
    this.setState({
      option,
      graphData
    });
  };

  getDynamicDatasource = async (data, text) => {
    if (
      data &&
      data.modalURL &&
      data.modalURL.URL &&
      data.modalURL.Method &&
      data.modalURL.Payload
    ) {
      const accessToken = localStorage.get('accessToken');
      const siteId = localStorage.get('currentSite');
      const deviceTypeObject = {
        method: `${data.modalURL.Method}`,
        url: `${data.modalURL.URL}?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        ...(data.modalURL.Method === 'POST'
          ? {
              data: {
                ...data.modalURL.Payload,
                ...(data && data['dynamicKey'] ? { [data['dynamicKey']]: text } : {})
              }
            }
          : {})
      };
      axios(deviceTypeObject)
        .then((response) => {
          let responseData = response.data;
          let dynamicColumns = [];
          dynamicColumns =
            responseData &&
            responseData[0] &&
            Object.keys(responseData[0]) &&
            Object.keys(responseData[0]).map((dataObject) => {
              return {
                title: dataObject,
                dataIndex: dataObject,
                key: dataObject
              };
            });

          let dynamicDatasource = responseData || [];
          this.setState({ dynamicColumns, dynamicDatasource });
        })
        .catch(function () {});
    }
  };
  typeChecking = (payload) => {
    let { annotation } = this.props;
    if (typeof payload === 'string') {
      //String
      return payload;
    } else if (
      typeof payload === 'number' &&
      !Number.isNaN(payload) &&
      !Number.isInteger(payload)
    ) {
      //float
      return payload.toFixed(
        annotation && (annotation.decimalLength || annotation.decimalLength === 0)
          ? annotation.decimalLength
          : 2
      );
    } else {
      return payload;
    }
  };

  changeAlignment = (props) => {
    let { x, payload, alignment, annotation } = props;
    if (alignment === 'forward' && payload) {
      if (typeof payload === 'number' && !Number.isNaN(payload) && !Number.isInteger(payload)) {
        // Decimal Conversion
        return (
          Number(x) -
          Number(
            payload
              .toFixed(
                annotation && (annotation.decimalLength || annotation.decimalLength === 0)
                  ? annotation.decimalLength
                  : 2
              )
              .toString().length - 1
          )
        );
      } else if (typeof payload === 'number') {
        return Number(x) - Number(payload.toString().length - 1);
      } else if (typeof payload === 'string') {
        return Number(x) - Number(payload.length - 1);
      } else {
        return x;
      }
    } else if (alignment === 'backward') {
      return x;
    } else {
      return x;
    }
  };

  render() {
    const {
      selectedParameters,
      reportName,
      dataSource,
      translationCache,
      dynamicColumns,
      dynamicDatasource
    } = this.state;
    const columns = [
      {
        title:
          translationCache && translationCache[`${constant.Select_Parameter}`]
            ? translationCache[`${constant.Select_Parameter}`]
            : `${constant.Select_Parameter}`,
        dataIndex: 'Parameter',
        key: 'Parameter'
      },
      {
        title:
          translationCache && translationCache[`${constant.Value}`]
            ? translationCache[`${constant.Value}`]
            : `${constant.Value}`,
        dataIndex: 'Value',
        key: 'Value'
      },
      {
        title:
          translationCache && translationCache[`${constant.UOM}`]
            ? translationCache[`${constant.UOM}`]
            : `${constant.UOM}`,
        dataIndex: 'UOM',
        key: 'UOM'
      }
    ];

    let { y, fontSize, fontFamily, fill, payload, displayName, device, annotation, className } =
      this.props;
    return (
      <React.Fragment>
        <text
          x={this.changeAlignment(this.props)}
          y={y}
          className={className}
          style={{ cursor: 'pointer' }}
          fontSize={fontSize}
          fontWeight={'800'}
          fontFamily={fontFamily}
          fill={fill}
          onClick={async () => {
            if (this.timeInterval) {
              clearInterval(this.timeTicket);
            }
            if (annotation && annotation.modalType && annotation.modalType === 'graph') {
              this.getGraphDatasorce(annotation);
              this.setState({ visibleGraph: true });
            } else if (annotation && annotation.modalType && annotation.modalType === 'dynamic') {
              this.getDynamicDatasource(annotation, payload);
              this.setState({ dynamicVisible: true });
            } else if (
              annotation &&
              annotation.modalType &&
              annotation.modalType === 'parameters'
            ) {
              this.getParams();
              this._getDataSource(displayName, dataSource);
              this.setState({ visible: true });
            }

            this.timeTicket = setInterval(() => {
              if (annotation && annotation.modalType && annotation.modalType === 'graph') {
                this.getGraphDatasorce(annotation);
              } else {
                this.getParams();
              }
            }, 60000);
          }}
        >
          {this.typeChecking(payload)}
        </text>

        {displayName !== '' || displayName ? (
          <Modal
            visible={this.state.visible}
            closable
            title={
              displayName !== undefined ? (
                <div>
                  {displayName} ({device})
                  <span>
                    {/* {dataSource.length >= 1 ? (
                      <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() =>
                          history.push(
                            `https://mew.bmecomosolution.com/api/reports/${reportName}`
                          )
                        }
                      />
                    ) : null} */}
                    {selectedParameters.length >= 1 && selectedParameters.length <= 5 ? (
                      <Button
                        type="primary"
                        icon={<AreaChartOutlined />}
                        onClick={() => this.onTrendClick(selectedParameters, device)}
                      />
                    ) : null}
                  </span>
                </div>
              ) : (
                <div>
                  Parameter List
                  <span style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    {selectedParameters.length >= 1 && selectedParameters.length <= 5 ? (
                      <Button
                        type="primary"
                        icon={<AreaChartOutlined />}
                        onClick={() => this.onTrendClick(selectedParameters, device)}
                      />
                    ) : null}
                  </span>
                </div>
              )
            }
            width={750}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {dataSource && Array.isArray(dataSource) && dataSource.length > 0 ? (
                <a href={`https://mew.bmecomosolution.com/api/reports/${reportName}`}>
                  {' '}
                  <DownloadOutlined style={{ fontSize: '25px' }} />
                </a>
              ) : null}
              {dataSource &&
                Array.isArray(dataSource) &&
                dataSource.map((item, index) => {
                  if (index === 0) {
                    let updatedTime = moment
                      .utc(item.UpdatedTime, 'YYYY-MM-DD HH:mm:ss')
                      .tz(this.props.currentTimeZone)
                      .format('YYYY-MM-DD HH:mm:ss');
                    return <div key={index}>Last Updated TimeStamp : {updatedTime}</div>;
                  } else return null;
                })}
            </div>
            <Table className="sldModalTable" columns={columns} dataSource={dataSource} />
          </Modal>
        ) : null}

        <Modal
          visible={this.state.dynamicVisible}
          closable
          title={
            displayName !== '' || displayName || displayName !== undefined ? (
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{displayName}</div>
            ) : (
              'Show Data'
            )
          }
          width={750}
          onOk={this.handleVisibleOk}
          onCancel={this.handleVisibleCancel}
          footer={null}
        >
          {dynamicColumns &&
          Array.isArray(dynamicColumns) &&
          dynamicDatasource &&
          Array.isArray(dynamicDatasource) ? (
            <Table
              className="sldModalTable"
              columns={dynamicColumns}
              dataSource={dynamicDatasource}
            />
          ) : (
            'No Data'
          )}
        </Modal>

        <Modal
          visible={this.state.visibleGraph}
          title={'Live Graph'}
          width={750}
          onOk={this.handleGraphOk}
          onCancel={this.handleGraphCancel}
          footer={null}
        >
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            // style={{ height: "100%", width: "100%" }}
            className="react_for_echarts"
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Text);
