import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Router, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme from '../commons/themes';
import history from '../commons/history';

import { LoginPage } from './../pages/Settings/LoginPage';
import { HomePage } from '../pages';
import { ChangeFirstPassword } from '../pages/Settings/ChangeFirstPassword';
import { makeSelectTheme } from '../selectors/theme';

const mapStateToProps = createStructuredSelector({
  selectedTheme: makeSelectTheme()
});

class Routerr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTheme: this.props.selectedTheme,
      themeObject: theme.blueTheme
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedTheme) {
      this.setState({ selectedTheme: nextProps.selectedTheme });
      this.themeSelection(nextProps.selectedTheme);
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.themeObject}>
        <Router history={history}>
          <div>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route exact path="/login" component={LoginPage} />
            <Route path="/changeFirstPassword" component={ChangeFirstPassword} />
            <Route path="/rubus" component={HomePage} />
          </div>
        </Router>
      </ThemeProvider>
    );
  }

  themeSelection(selectedTheme) {
    switch (selectedTheme) {
      case '#292961':
        this.setState({ themeObject: theme.indigoTheme });
        break;
      case '#4b4ba3':
        this.setState({ themeObject: theme.lightIndigoTheme });
        break;
      case '#1a3652':
        this.setState({ themeObject: theme.blueTheme });
        break;
      case '#2261a1':
        this.setState({ themeObject: theme.lightBlueTheme });
        break;
      case '#0d4524':
        this.setState({ themeObject: theme.greenTheme });
        break;
      case '#156b39':
        this.setState({ themeObject: theme.lightGreenTheme });
        break;
      case '#691a16':
        this.setState({ themeObject: theme.redTheme });
        break;
      case '#a62e21':
        this.setState({ themeObject: theme.lightRedTheme });
        break;
      case '#2e2e2e':
        this.setState({ themeObject: theme.darkTheme });
        break;
      case 'Light':
        this.setState({ themeObject: theme.lightTheme });
        break;
      default:
        this.setState({ themeObject: theme.lightTheme });
    }
  }
}
export default connect(mapStateToProps)(Routerr);
