import React, { Component } from 'react';

import './style.css';
import SmartMotorControl from './SmartMotorControl';
import SmartLightControl from './SmartLightingControl';
import SmartControlTags from './SmartControlTags';
import { Fragment } from 'react';

class SmartControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: ''
    };
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    //  this.historyLog();
    try {
      if (this.props.match.params.formName) {
        this.setState({ formType: this.props.match.params.formName });
      }
    } catch (error) {
      console.log(error);
    }
  };
  componentDidUpdate(prevprops) {
    try {
      if (
        this.props.match.params.formName &&
        this.props.match.params.formName !== prevprops.match.params.formName
      ) {
        this.setState({ formType: this.props.match.params.formName });
      }
    } catch (error) {
      console.log(error);
    }
  }
  // componentDidMount = () => {
  //  this.getFormData();
  // };

  render() {
    const { formType } = this.state;
    return (
      <Fragment>
        {formType === 'VibrationDashboard' ? <SmartMotorControl /> : <div></div>}
        {formType === 'SmartLightControl' ? <SmartLightControl /> : <div></div>}
        {formType === 'SmartControlTag' ? <SmartControlTags /> : <div></div>}
      </Fragment>
      // <div className="mode1" style={{ padding: "50px" }}>

      // </div>
    );
  }
}

export default SmartControl;
