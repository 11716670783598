/**
 * @author Anvesh
 * @description This Component is mainly for APiCalls export for Equipment MAnagement
 */

import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import moment from 'moment';

export async function getHierarchyLevel(deviceID, Code) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/hierarchymodule/getHierarchyLevelsByCode?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Code: Code,
      DeviceTypeId: deviceID
    }
  };
  return axios(getHierarchyLevel);
}
export async function getPartsSupilerList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSupplierList = {
    method: 'POST',
    url: `/api/supplier/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId
    }
  };
  return axios(getSupplierList);
}
export async function getDeviceType() {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getDeviceType = {
    method: 'GET',
    url: `/api/deviceType/get`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceType);
}
export async function createSpec(Type, deviceID, Specs, selectedSuplier) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/specData/upsert`,
    headers: {
      Authorization: Authorization
    },

    data: {
      SiteId: siteId,
      Type: Type,
      Device: deviceID,
      SupplierId: selectedSuplier,

      Specifications: Specs.Specifications
    }
  };
  return axios(getHierarchyLevel);
}
export async function getSpecRecordforParts(type, deviceID, partId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/specData/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Type: type,
      // "DeviceId": deviceID,
      PartId: partId
    }
  };
  return axios(getHierarchyLevel);
}
export async function createPartsSpecs(Type, Specs, partId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/specData/upsert`,
    headers: {
      Authorization: Authorization
    },

    data: {
      SiteId: siteId,
      Type: Type,
      PartId: partId,
      // "SupplierId":selectedSuplier,
      Specifications: Specs.Specifications
    }
  };
  return axios(getHierarchyLevel);
}
export async function createPart(values, deviceID, MainId, Type) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/partsData/upsert`,
    headers: {
      Authorization: Authorization
    },

    data: {
      SiteId: siteId,
      Type: Type,
      Device: deviceID,
      // "DeviceTypeId":MainId,
      Parts: values.Parts
    }
  };
  return axios(getHierarchyLevel);
}
export async function getSpecRecord(deviceID) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/specData/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Device: deviceID,
      Type: 'Equipment'
    }
  };
  return axios(getHierarchyLevel);
}
export async function getPartRecord(selectedKey) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/partsData/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Device: selectedKey
      // "DeviceTypeId":deviceType,
    }
  };
  return axios(getHierarchyLevel);
}

export async function getPartsList(deviceID) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/parts/partsList`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      DeviceId: deviceID
    }
  };
  return axios(getHierarchyLevel);
}
export async function getSubDeviceList(deviceID, EquipmentType, EquipmentID) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSubDeviceList = {
    method: 'POST',
    url: `/api/device/getDeviceByType`,
    // url:`/api/device/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      EquipmentType: EquipmentType,
      DeviceType: deviceID,
      ParentEquipment: EquipmentID
    }
  };
  return axios(getSubDeviceList);
}
export async function getOverview(deviceID) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getOverview = {
    method: 'POST',
    url: `/api/specData/listAll`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Type: 'Equipment',
      Device: deviceID
    }
  };
  return axios(getOverview);
}
export async function getPartLocation(partId, deviceType, deviceId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getPartLocation = {
    method: 'POST',
    url: `/api/partsData/locations`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      DeviceTypeId: deviceType,
      DeviceId: deviceId,
      PartId: partId
    }
  };
  return axios(getPartLocation);
}
export async function getEventsList(locatedId, partId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getEventsList = {
    method: 'POST',
    url: `/api/events/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      PartId: partId,
      PartLocatedId: locatedId
    }
  };
  return axios(getEventsList);
}
export async function postEventData(values) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const postEventData = {
    method: 'POST',
    url: `/api/events/create`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      ...values,
      StartTime: moment(values.StartTime).format('YYYY-MM-DD HH:mm:ss')
    }
  };
  return axios(postEventData);
}
export async function eventCheck(value, Status) {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const eventCheck = {
    method: 'POST',
    url: `/api/eventStatusMapping/validation`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: '1',
      EventId: value,
      Status: Status
    }
  };
  return axios(eventCheck);
}
