import React from 'react';
import axios from 'axios';
import { Button, Input, Drawer, Popconfirm, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';
import DeviceParameterContainer from './DeviceParameter/DeviceParameterContainer';
import { StyledApplication, AddTableButton } from './style';
import { _getDeviceParameterBasedOnType, _getDeviceTypesList } from '../AssetDeviceList/APICalls';
import { StyledTable } from '../../../commons/styles/layout';

const FormItem = Form.Item;
const { TextArea } = Input;

class DeviceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      deviceTypeList: props.deviceTypeList || [],
      deviceTypeObject: {},
      deviceParametersBasedOnType: [],
      UOMList: []
    };
  }
  deviceTypeRef = React.createRef();

  componentDidMount() {
    this.getDeviceTypeLIst();
    this._getUOMist();
  }

  getDeviceTypeLIst = async () => {
    let deviceTypeList = await _getDeviceTypesList();
    this.setState({
      deviceTypeList
    });
  };

  _getUOMist = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const uomObject = {
      method: 'POST',
      url: `/api/masterconfiguration/master?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterName: 'AssetDevice_UOM',
        ActionType: 'read'
      }
    };
    axios(uomObject)
      .then((response) => {
        this.setState({ UOMList: response.data });
      })
      .catch(function () {});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { deviceParametersBasedOnType } = this.state;
    this.deviceTypeRef.current
      .validateFields()
      .then(async (values) => {
        delete values.names;
        delete values.code;
        delete values.uom;
        delete values.dataType;
        deviceParametersBasedOnType.forEach((parameter, index) => {
          if (!parameter.Id) {
            deviceParametersBasedOnType[index].DeviceType = values.Code;
          }
        });
        this.setState({ visible: false });
        this.props.actions.saveDeviceParamters(deviceParametersBasedOnType);
        this.getDeviceTypeLIst();
      })
      .catch(() => {});
  };

  showDrawer = () => {
    if (this.deviceTypeRef.current) {
      this.deviceTypeRef.current.resetFields();
    }
    this.setState({
      visible: true,
      deviceTypeObject: {},
      deviceParametersBasedOnType: []
    });
  };

  onClose = () => {
    if (this.deviceTypeRef.current) {
      this.deviceTypeRef.current.resetFields();
    }
    this.setState({
      visible: false,
      deviceTypeObject: {}
    });
  };

  resetForm = () => {
    this.setState({ deviceTypeObject: {} });
    if (this.deviceTypeRef.current) {
      this.deviceTypeRef.current.resetFields();
    }
  };

  showDrawerOldDevice = async (e, row) => {
    e.preventDefault();
    let data = await _getDeviceParameterBasedOnType(row.Code);
    this.setState({
      deviceTypeObject: row,
      deviceParametersBasedOnType: data,
      visible: true
    });
  };

  updateArray = (array, index, updateFn) => {
    return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
  };

  saveDeviceType = (e) => {
    e.preventDefault();
    this.deviceTypeRef.current
      .validateFields()
      .then(async (values) => {
        delete values.names;
        delete values.uom;
        delete values.dataType;
        this.props.actions.saveDeviceType(values);
        setTimeout(() => {
          this.getDeviceTypeLIst();
          this.setState({ visible: false });
          if (this.deviceTypeRef.current) {
            this.deviceTypeRef.current.resetFields();
          }
        }, 500);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateDeviceType = (e) => {
    e.preventDefault();
    this.deviceTypeRef.current
      .validateFields()
      .then(async (values) => {
        delete values.names;
        delete values.uom;
        delete values.dataType;
        this.props.actions.updateDeviceType(values);
        setTimeout(() => {
          this.getDeviceTypeLIst();
          this.setState({
            visible: false
          });
          if (this.deviceTypeRef.current) {
            this.deviceTypeRef.current.resetFields();
          }
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDeviceParameter = (deviceParametersBasedOnType) => {
    this.setState({ deviceParametersBasedOnType });
  };

  render() {
    const { deviceTypeList, deviceTypeObject, UOMList, deviceParametersBasedOnType } = this.state;
    const columns = [
      {
        title: 'Equipment Type Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Equipment Type Code',
        dataIndex: 'Code',
        key: 'Code'
      },
      {
        title: 'Equipment Type Description',
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, row) => {
          return {
            props: {
              // style: {
              //   background: "#fff"
              // }
            },
            children: (
              <Popconfirm
                title="Are you sure delete deviceType?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  this.props.actions.deleteDeviceType(row.Id);
                  this.getDeviceTypeLIst();
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Popconfirm>
            )
          };
        }
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 115 }}>
        <AddTableButton type="primary" onClick={this.showDrawer}>
          Create Equipment Type
        </AddTableButton>
        <Drawer
          title={!deviceTypeObject.Id ? 'Create Equipment Type' : 'Update Equipment Type'}
          placement="right"
          closable
          width={1200}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          ></div>
          <Form
            layout="vertical"
            ref={this.deviceTypeRef}
            name="user_form"
            initialValues={deviceTypeObject || {}}
          >
            <FormItem
              label="Equipment Type Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Equipment type name!'
                }
              ]}
            >
              <Input placeholder="Enter Equipment name" style={{ width: '300px' }} />
            </FormItem>
            <FormItem
              label="Equipment Type Code"
              name="Code"
              rules={[
                {
                  required: true,
                  message: 'Please input your Equipment type Code!'
                }
              ]}
            >
              <Input placeholder="enter Equipment Code" style={{ width: '300px' }} />
            </FormItem>
            <Form.Item label="Id" name="Id" display="none" hidden={true}>
              <Input style={{ display: 'none' }} />
            </Form.Item>

            <FormItem
              label="Equipment Type Description"
              name="Description"
              rules={[
                {
                  required: true,
                  message: 'Please input your Equipment type Description!'
                }
              ]}
            >
              <TextArea
                rows={2}
                style={{ width: '400px' }}
                placeholder="enter Equipment description"
              />
            </FormItem>
            {
              <div>
                {!deviceTypeObject.Id ? (
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: '5px', marginBottom: '5px' }}
                    onClick={this.saveDeviceType}
                  >
                    Save Equipment Type
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: '5px', marginBottom: '5px' }}
                    onClick={this.updateDeviceType}
                  >
                    Update Equipment Type
                  </Button>
                )}
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: '5px', marginBottom: '5px' }}
                  onClick={this.resetForm}
                >
                  Clear
                </Button>
              </div>
            }

            {deviceTypeObject.Code ? (
              <DeviceParameterContainer
                UOMList={UOMList}
                deviceType={deviceTypeObject.Code}
                deviceParametersBasedOnType={deviceParametersBasedOnType}
                deviceParameter={this.updateDeviceParameter}
                closeDrawer={this.onClose}
              />
            ) : (
              <div></div>
            )}
          </Form>
        </Drawer>
        <StyledTable
          size="medium"
          columns={columns}
          dataSource={deviceTypeList && Array.isArray(deviceTypeList) ? deviceTypeList : []}
          // pagination={
          //   deviceTypeList && Array.isArray(deviceTypeList) && deviceTypeList.length > 5
          //     ? {
          //         pageSize: '10'
          //       }
          //     : false
          // }
          locale={{ emptyText: 'No Data' }}
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation();
              this.showDrawerOldDevice(e, record);
            }
          })}
        />
      </StyledApplication>
    );
  }
}

export default DeviceType;
