import React from 'react';
import axios from 'axios';
import { Button, Radio, message } from 'antd';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from '../../commons/history';
import GraphView from './GraphView';
import localStorage from '../../utils/localStorage';
// import { translation } from '../Adapter/TranslationAdapter.js';
import { NoRecordComponent } from '../Dashboard/ChartComponents/Utils';
import ViewDashboard from '../Dashboard/DashboardComponents/Dashboard';
import { BackwardOutlined } from '@ant-design/icons';
import { getCurrentLanguage, getTranslationCache } from '../../selectors/language';
import constant from './constant.js';

class GraphViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trendData: {
        Device: 'CV-121',
        TimeStamp: '2022-01-06 14:07:59',
        FileName: '20220106173759.cna',
        FFTOutPut: {},
        xRanges: {
          gridIndex: 0,
          min: '0.000001',
          max: '0.058540'
        },
        yRanges: {
          gridIndex: 1,
          min: '0.000002',
          max: '0.113124'
        },
        zRanges: {
          gridIndex: 2,
          min: '0.000001',
          max: '0.059487'
        }
      },
      type: 'graph',
      deviceList: [],
      language: props.language || 'English',
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    // /rubus/FFTLink?filename=20220106173759.cna&device=CV-121
    let params = queryString.parse(this.props.history.location.search);
    console.log(params, 'params');
    this.getDeviceList();
    this.graphView(params);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.language !== this.props.language
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        language: this.props.language
      });
    }
  }

  getDeviceList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((res) => {
        let deviceList = res.data;
        this.setState({ deviceList });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  graphView = (data) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/fft/show?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Device: data.device,
        FileName: [data.filename]
      }
    };
    axios(headers)
      .then((res) => {
        this.setState({
          // trendData:
          // {
          //   "Device": "FNM-501",
          //   "DeviceAlias": "1_FNM-501",
          //   "TimeStamp": "2024-01-18 23:58:17",
          //   "FileName": "20240119085817.cna",
          //   "FFTOutPut": {
          //     "X": [
          //       [
          //         "300.0000",
          //         "2.9000"
          //       ],
          //       [
          //         "300.0000",
          //         "3.9000"
          //       ],
          //       [
          //         "56.0000",
          //         "3.9000"
          //       ],
          //       [
          //         "10.167",
          //         "86.4560"
          //       ],
          //       [
          //         "14.2000",
          //         "69.9000"
          //       ],
          //       [
          //         "15.3000",
          //         "2.56770"
          //       ],
          //       [
          //         "18.4000",
          //         "34.3355"
          //       ]
          //     ],
          //     "Y": [
          //       [
          //         "0.0000",
          //         "83.8004"
          //       ],
          //       [
          //         "56.00000",
          //         "27.00000"
          //       ],
          //       [
          //         "10.167",
          //         "86.4560"
          //       ],
          //       [
          //         "14.2000",
          //         "69.9000"
          //       ],
          //       [
          //         "15.3000",
          //         "2.56770"
          //       ],
          //       [
          //         "18.4000",
          //         "34.3557"
          //       ]
          //     ]
          //   }
          // },
          trendData: res.data && res.data[0] ? res.data[0] : {},
          device: data.device
        });
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  };

  handleChange = (e) => {
    const type = e.target.value;
    this.setState({ type });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { trendData, device, deviceList, type, translationCache } = this.state;

    let filteredDevice = deviceList.filter((deviceObject) => {
      return deviceObject.AliasCode.toString() === device;
    });
    return (
      <React.Fragment>
        <div
          style={{
            fontSize: 'bold',
            padding: '20px',
            background: 'white',
            minHeight: window.innerHeight - 120
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              color: 'black',
              fontSize: '17px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {filteredDevice &&
            Array.isArray(filteredDevice) &&
            filteredDevice[0] &&
            filteredDevice[0].Name
              ? `${filteredDevice[0].DeviceCode}${
                  filteredDevice[0].DeviceDescription
                    ? this.translation(filteredDevice[0].DeviceDescription)
                    : ''
                }  `
              : device}
          </div>
          <Button
            type=""
            style={{ border: '2px solid #40a9ff', color: '#40a9ff' }}
            onClick={() => {
              history.push({
                pathname: `/rubus/FFT`,
                state: device ? device : ''
              });
            }}
          >
            <BackwardOutlined />
            {translationCache && translationCache['FFT'] && translationCache['FFT']
              ? translationCache['FFT']
              : 'FFT'}
          </Button>
          <Radio.Group onChange={this.handleChange} value={type} style={{ float: 'right' }}>
            <Radio.Button value="graph">{this.translation(constant.FFT_Graph)} </Radio.Button>
            <Radio.Button value="summary">{this.translation(constant.Summary)}</Radio.Button>
          </Radio.Group>

          {trendData && type === 'graph' ? (
            <div>
              {trendData && Object.keys(trendData).length !== 0 ? (
                <GraphView
                  panelData={{
                    graph: 'trends',
                    trendData
                  }}
                />
              ) : (
                <NoRecordComponent />
              )}
            </div>
          ) : (
            <div>
              <ViewDashboard match={{ params: { name: 'FFT', DeviceAlias: device } }} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(GraphViewComponent);
