import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../../../../utils/localStorage';

export const _getAssetsBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/asset/get?SiteId=${siteId}&AssetType=${data.AssetType}&ParentAsset=${data.parentAssetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getSitesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/site/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const _saveAssetData = (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const Object = {
    method: 'POST',
    url: `/api/asset/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };

  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/asset/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
};

export const _getHierarchyJSONAPI = () => {
  // const siteId = localStorage.get('currentSite')
  // const accessToken = localStorage.get('accessToken')
  // const Object = {
  //   method: 'GET',
  //   url: `/api/hierarchy/list?SiteId=${siteId}`,
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`
  //   }
  // }
  // return axios(Object)
  //   .then((response) => {
  //     return response.data
  //   }).catch(function () {
  //     return []
  //   });
  return {
    asset: {
      location: {
        name: 'Location',
        parent: null
      },
      subLocation: {
        name: 'Sub-Location',
        parent: ['location', 'subLocation']
      },
      asset: {
        name: 'Asset',
        parent: ['location', 'subLocation', 'asset']
      }
    },
    device: {
      Primary: {
        name: 'Primary',
        asset: true,
        parent: ['location', 'subLocation', 'asset']
      },
      Secondary: {
        name: 'Secondary',
        parent: ['Primary', 'Secondary']
      },
      'Sub-Equipment': {
        name: 'Sub-Equipment',
        parent: ['Primary', 'Secondary', 'Sub-Equipment']
      }
    }
  };
};

export const _updateAssetData = (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `/api/asset/update?SiteId=${siteId}&Id=${payload.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/asset/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch(() => {});
  // return axios(Object)
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     if (err.response.status === 400) {
  //       message.info(err.response.data.message);
  //     }
  //   });
};
export const _getDeviceTypesList = () => {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/deviceType/get`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceParameterBasedOnType = (deviceTypeId) => {
  const accessToken = localStorage.get('accessToken');
  const deviceTypeObject = {
    method: 'GET',
    url: `/api/parameter/get?DeviceType=${deviceTypeId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(deviceTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceParameterType = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const ParameterTypeObject = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'AssetDevice_ParameterType',
      ActionType: 'read',
      Type: 'WithoutSiteId'
    }
  };
  return axios(ParameterTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
};

export const _conditionList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const ParameterTypeObject = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'AssetDevice_Conditions',
      ActionType: 'read',
      Type: 'WithoutSiteId'
    }
  };
  return axios(ParameterTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
};

export const _getDeviceBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/device/list?SiteId=${siteId}
    ${data.parentAssetId ? `&Asset=${data.parentAssetId}` : ``}
    ${data.parentDeviceId ? `&ParentEquipment=${data.parentDeviceId}` : ``}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const _saveDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/device/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};
export const _updateDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `/api/device/update?SiteId=${siteId}&Id=${data.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _getParameterGroupList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'POST',
      url: `/api/masterconfiguration/master?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterName: 'AssetCode_ParameterGroup',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(uomObject)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _tagReferenceList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'POST',
      url: `/api/masterconfiguration/master?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterName: 'AssetDevice_TagReference',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(uomObject)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _saveDeviceParamters = (payload) => {
  const accessToken = localStorage.get('accessToken');
  const uomObject = {
    method: 'POST',
    url: `/api/parameter/create`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(uomObject)
    .then(() => {
      message.success('Condition & Tag references has been updated');
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _deleteDeviceParameters = (deviceParameterId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `/api/deviceParameter/deleteAlias?SiteId=${siteId}&Id=${deviceParameterId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _deleteDevice = (deviceId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `/api/device/delete?SiteId=${siteId}&Id=${deviceId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _deleteAssetObject = (assetId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `/api/asset/delete?SiteId=${siteId}&Id=${assetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 500) {
        message.info(error.response.data.message);
      }
    });
};
export async function getMasterById(id) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/asset/get?Id=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
