import React, { Component } from 'react';
import { Form, message, Select, Divider, Space, Button } from 'antd';
import {
  getSitesList,
  submitMaster,
  getMasterById,
  getSkillTypeList,
  insertSkillType
} from '../../../Utils/FormCalls';
import _ from 'lodash';
import moment from 'moment';
import { PaperClipOutlined } from '@ant-design/icons';
import history from '../../../../../commons/history';
import FileBase64 from '../../../Utils/FileUpload';
import { decode as base64_decode } from 'base-64';
import { PlusOutlined } from '@ant-design/icons';
import {
  StyledBox,
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal
} from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import { connect } from 'react-redux';
import { constant } from '../../../Utils/constants';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import translation from '../../../Utils/translation';
import localStorage from '../../../../../utils/localStorage';
import { check } from '../../../Utils/FormCalls';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
import { getUserProfile } from '../../../../../selectors/layout';
// import InputNumberComponent from '../../../Widgets/InputNumberComponent';
const { Option } = Select;
const urlList = {
  insert: '/api/masterLabor/insert',
  getbyId: '/api/masterLabor/getById'
};
const type = 'Labor';
const fileData = new FormData();

class ManPowerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      statusList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      translationCache: props.translationCache || [],
      SkilTypeList: [],
      viewSkillType: false
    };
  }
  hRef = React.createRef();
  SkillRef = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    try {
      let sitesList = await getSitesList();
      let formObject = this.hRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.hRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let SkilTypeList = await getSkillTypeList();
      this.setState({ SkilTypeList: SkilTypeList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.hRef.current) this.hRef.current.setFieldsValue(this.props.formObject);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  _getDataById = async () => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/ManPowerDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
      formObject = data && data !== undefined ? data : {};
      _.cloneDeepWith(data, (value) => {
        return value && value !== null && value.StartTime && value.EndTime
          ? {
              ...value,
              ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
            }
          : _.noop();
      });
    }
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(formObject);
    }
    this.setState({
      formObject
    });
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.hRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.hRef.current) this.hRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };

  onFormsubmit = async (values) => {
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let { formObject, getById, fileData } = this.state;
    let payload = {
      ...formObject,
      ...values,
      ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
      ...(formObject && formObject.Id ? { UpdatedBy: userId } : {}),
      Id: getById || formObject.Id
    };
    let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
    if (statusResponse && statusResponse.message) {
      this._getDataById(statusResponse.Id);
      // history.goBack();
      message.success(statusResponse.message);
      // this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };

  onChange = () => {
    let formObject = this.hRef.current.getFieldsValue();

    formObject = {
      ...formObject
    };
    this.setState({
      formObject
    });
    this.hRef.current.setFieldsValue(formObject);
  };

  onModalSkillTypeFormsubmit = (e) => {
    e.preventDefault();
    this.SkillRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        let SkillTypeResponse = await insertSkillType(payload);
        if (SkillTypeResponse) {
          message.success(SkillTypeResponse.message);
          let SkilTypeList = await getSkillTypeList();
          let formObject = this.SkillRef.current.getFieldsValue();
          this.SkillRef.current.setFieldsValue(formObject);
          this.setState({ viewSkillType: false, SkilTypeList, formObject });
          if (this.SkillRef.current) {
            this.SkillRef.current.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  oncloseSkill = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      if (this.SkillRef.current) {
        this.SkillRef.current.resetFields();
      }
      this.setState({ viewSkillType: false });
    }
  };
  onReset = () => {
    if (this.hRef.current) this.hRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Input mandatory fields');
  // };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  render() {
    let { sitesList, formObject, translationCache, SkilTypeList, viewSkillType } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      }
      // pattern: {
      //   mismatch: '${label} does not match pattern'
      // }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="Item"
            theme={themeSettings}
            ref={this.hRef}
            initialValues={formObject}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item name="Id"></Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '30px'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.personLastName)}
                  name="LastName"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Person Last Name')}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '30px'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.personFirstName)}
                  name="FirstName"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Person First Name')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.personTitle)}
                  name="Title"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={10}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, constant.inputPersonTitle)}
                    value={'WO-11'}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.site)}
                  name="SiteId"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Site')}
                    disabled={formObject && formObject.Id ? true : false}
                  >
                    {sitesList &&
                      Array.isArray(sitesList) &&
                      sitesList.map((site, index) => {
                        return (
                          <Option key={index} value={site.Id}>
                            {' '}
                            {site.Name}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  display="none"
                  name="Id"
                  hidden={true}
                >
                  <InputComponent style={{ display: 'none', width: '70%' }}></InputComponent>
                </Form.Item>
                {/* <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.id)}
                    display="none"
                    name="Id"
                    hidden={true}
                  // initialValue={editObj.Id || ''}
                  >
                    <InputComponent style={{ display: 'none', color: 'black' }} />
                  </Form.Item> */}
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.email)}
                  name="Email"
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Email')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.language)}
                  name="Language"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Language')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.countryCode)}
                  name="CountryCode"
                  rules={[
                    {
                      // pattern: new RegExp(/^[0-9]*$/)
                      // pattern: new RegExp(/^[0-9 ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={10}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Country Code')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.phoneNumber)}
                  name="PhoneNumber"
                  rules={[
                    {
                      required: true
                      // pattern: new RegExp(/^[0-9]*$/)
                      // pattern: new RegExp(/^[0-9 ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={15}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Phone Number')}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.address)}
                  name="Address"
                >
                  <InputComponent
                    //maxLength={150}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Address')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.city)}
                  name="City"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input City')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.state)}
                  name="State"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input State/Province')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.country)}
                  name="Country"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z ]*$/)
                      // pattern: new RegExp(/^[一-龯ぁ-んァ-ンA-Za-zァ-ヶ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Country')}
                  />
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.zipCode)}
                  name="ZipCode"
                  rules={[
                    {
                      // pattern: new RegExp(/^[0-9]*$/)
                      // pattern: new RegExp(/^[0-9 ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={10}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Zip Code')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  name="Attachments"
                  // label={translation(translationCache, constant.attachments)}
                  label={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        {translation(translationCache, constant.attachments)}
                        <PaperClipOutlined className="plusCircleOutlined" />
                      </span>
                    </div>
                  }
                >
                  <FileBase64
                    style={{ width: '70%' }}
                    downloadClassName={'formdownloadInput'}
                    Attachments={formObject.Attachments}
                    fileUpload={this.fileupload}
                  ></FileBase64>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.Skill)}
                  name="SkillTypeId"
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Skill')}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0'
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px'
                          }}
                        >
                          <Button
                            type="dashed"
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({ viewSkillType: true })}
                          >
                            {translation(translationCache, 'Add SkillType')}
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {SkilTypeList &&
                      Array.isArray(SkilTypeList) &&
                      SkilTypeList.map((skill, index) => {
                        return (
                          <Option key={index} value={skill.Id}>
                            {' '}
                            {skill.Type}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.Quantity)}
                  name="AvailableQuantity"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) => check(value, 'Item', { validateDecimal: true })
                    }
                  ]}
                >
                  <InputNumberComponent
                    //maxLength={20}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Quantity')}
                    // onChange={(e) => this.handleChange(e, 'Quantity')}
                  />
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
              </StyledBox>
              <StyledModal
                title={translation(translationCache, constant.addSkill)}
                open={viewSkillType}
                okText={'Submit'}
                onOk={(e) => this.onModalSkillTypeFormsubmit(e)}
                onCancel={this.oncloseSkill}
              >
                <Form
                  layout="vertical"
                  ref={this.SkillRef}
                  name="user_form"
                  onFinish={(e) => this.onModalSkillTypeFormsubmit(e)}
                >
                  <Form.Item
                    label={translation(translationCache, 'Type')}
                    name={'Type'}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <InputComponent
                      placeholder={translation(translationCache, 'Input Type')}
                      onChange={(e) => this.onChange(e)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, 'Description')}
                    name={'Description'}
                    placeholder={translation(translationCache, 'Input Description')}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <InputComponent
                      placeholder={translation(translationCache, 'Input Description')}
                    />
                  </Form.Item>
                </Form>
              </StyledModal>
            </div>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ManPowerDetails);
