/**
 * @author Anvesh
 * @description This Component is mainly for APiCalls export for Supplier,Parts,Status Masters
 */

import axios from 'axios';
import localStorage from '../../../utils/localStorage';

/**Api Calls Related to Supplier Masters Start */
export async function getSupplierList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSupplierList = {
    method: 'POST',
    url: `/api/supplier/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId
    }
  };
  return axios(getSupplierList);
}
export async function createSupplier(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const createSupplier = {
    method: 'POST',
    url: `/api/supplier/create`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(createSupplier);
}
export async function updateSupplier(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const updateSupplier = {
    method: 'PATCH',
    url: `/api/supplier/update`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(updateSupplier);
}
export async function deleteSupplier(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteSupplier = {
    method: 'POST',
    url: `/api/supplier/delete`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(deleteSupplier);
}

/**Api Calls Related to Supplier Masters End */

export async function createSpec(Specs) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/specData/upsert1`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,

      Specifications: Specs.Specifications
    }
  };
  return axios(getHierarchyLevel);
}
export async function getFileName() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getFileName = {
    method: 'GET',
    url: `/api/parts/generateExcel`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId
    }
  };
  return axios(getFileName);
}
/**Api Calls Related to Parts Masters Start */
export async function getPartMasterRecord(deviceID, deviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/parts/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Type: 'Part',
      DeviceId: deviceID,
      DeviceTypeId: deviceType
    }
  };
  return axios(getHierarchyLevel);
}
export async function createPartMaster(Type, deviceID, Specs, deviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/parts/upsert`,
    headers: {
      Authorization: Authorization
    },

    data: {
      SiteId: siteId,
      Type: Type,
      DeviceId: deviceID,
      DeviceTypeId: deviceType,
      Parts: Specs.Parts
    }
  };
  return axios(getHierarchyLevel);
}
export async function getParameterTypes() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getHierarchyLevel = {
    method: 'POST',
    url: `/api/partParameterTypes/get`,
    headers: {
      Authorization: Authorization
    },

    data: {
      SiteId: siteId
    }
  };
  return axios(getHierarchyLevel);
}
/**Api Calls Related to Parts Masters End */

export async function getDeviceType() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;

  const getDeviceType = {
    method: 'GET',
    url: `/api/deviceType/get`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceType);
}

/**Api Calls Related to Status Masters Start */
export async function getStatusList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getStatusList = {
    method: 'POST',
    url: `/api/deviceTypeStatus/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId
    }
  };
  return axios(getStatusList);
}
export async function createStatus(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const createStatus = {
    method: 'POST',
    url: `/api/deviceTypeStatus/create`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(createStatus);
}
export async function updateStatus(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const updateStatus = {
    method: 'PATCH',
    url: `/api/deviceTypeStatus/update`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(updateStatus);
}
export async function deleteStatus(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteStatus = {
    method: 'POST',
    url: `/api/deviceTypeStatus/delete`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(deleteStatus);
}
/**Api Calls Related to Status Masters End */

/**Api Calls Related to Specifications Masters Start */
export async function getSpecsList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSpecsList = {
    method: 'POST',
    url: `/api/deviceSpecifications/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Grouping: 'true'
    }
  };
  return axios(getSpecsList);
}
export async function getDeviceSpecsList(deviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSpecsList = {
    method: 'POST',
    url: `/api/deviceSpecifications/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      DeviceTypeId: deviceType,
      Grouping: 'true'
    }
  };
  return axios(getSpecsList);
}
export async function createSpecs(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const createSpecifications = {
    method: 'POST',
    url: `/api/deviceSpecifications/create`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(createSpecifications);
}
export async function updateSpecs(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const updateSpecs = {
    method: 'PATCH',
    url: `/api/deviceSpecifications/update`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(updateSpecs);
}
export async function deleteSpecs(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteSpecs = {
    method: 'POST',
    url: `/api/deviceSpecifications/delete`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(deleteSpecs);
}
/**Api Calls Related to Specifications Masters End */
/**Apis For upload */
export async function getUploadList(deviceID) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const uploadList = {
    method: 'POST',
    url: `/api/equipmentDocs/get`,
    headers: {
      Authorization: Authorization
    },
    data: {
      DeviceId: deviceID,
      SiteId: siteId
    }
  };
  return axios(uploadList);
}
export async function deleteDoc(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteDoc = {
    method: 'POST',
    url: `/api/equipmentDocs/delete`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Id: payload.Id,
      SiteId: siteId
    }
  };
  return axios(deleteDoc);
}
