import React from 'react';
import axios from 'axios';
import {
  Table,
  Button,
  Drawer,
  Form,
  Input,
  Upload,
  message,
  Switch,
  Row,
  Col,
  Select
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { DrawerFooter } from './styles.js';
import './style.css';
import { StyledTable, lightTheme, StyledDashboard } from './styles.js';
class EdgeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      configObject: {},
      visible: false,
      historianShow: {},
      historianResponse: {},
      checked: ''
    };
  }
  userRef = React.createRef();
  componentDidMount() {
    this.getEdgeConfigList();
    this._getHistorian();
    this._getHistorianResponse();
    this.setState({ configObject: {} });
  }

  getEdgeConfigList = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/edgeConfig/get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ dataSource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  _getHistorian = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/edgeConfig/historian`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({
          historianShow: response.data
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  _getHistorianResponse = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/edgeConfig/getHistorian`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ historianResponse: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  showDrawer = () => {
    if (this.userRef.current) {
      this.userRef.current.resetFields();
    }
    this.setState({
      visible: true,
      configObject: {}
    });
  };
  onRecord = (record, e) => {
    e.preventDefault();
    this.setState({ configObject: record, visible: true });
  };
  onClose = () => {
    this.setState({ visible: false, configObject: {} });
  };
  createConfiguration = () => {
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        this.saveEdgeConfiguration(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  saveEdgeConfiguration = (values) => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/edgeConfig/create`,
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`
      },
      data: values
    };
    axios(headers)
      .then(() => {
        this.setState({ visible: false, configObject: {} });
        this.getEdgeConfigList();
      })
      .catch(() => {
        message.error('Please check the entered fields');
      });
  };
  updateConfiguration = () => {
    const { dataSource } = this.state;
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        const filterdObject = dataSource.filter((item) => {
          return item.Device === values.device;
        });
        let id = filterdObject[0].Id;
        this.updatedEdgeConfiguration(id, values);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  updatedEdgeConfiguration = (id, values) => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'PATCH',
      url: `/api/edgeConfig/update/${id}`, // ?id=${id}
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`
      },
      data: values
    };
    axios(headers)
      .then(() => {
        this.getEdgeConfigList();
        this.setState({ visible: false, configObject: {} });
      })
      .catch(() => {
        message.error('Please check the entered fields');
      });
  };

  deleteConfiguration = () => {
    const { dataSource } = this.state;
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        const filterdObject = dataSource.filter((item) => {
          return item.Device === values.device;
        });
        let id = filterdObject[0].Id;
        this.deleteEdgeConfiguration(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  deleteEdgeConfiguration = (id) => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'DELETE',
      url: `/api/edgeConfig/delete/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ configObject: {}, visible: false });
        this.getEdgeConfigList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV =
      file.type === 'text/csv' ||
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    if (!isCSV) {
      message.error('You can only upload CSV file!');
    }
    if (!isLt2M) {
      message.error('CSV file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };
  handleChange = (info) => {
    const { status } = info.file;

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      this.getEdgeConfigList();
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  historianOnChange = (checked) => {
    this.setState({ checked });
    let payload = {
      historian: 'historian',
      status: checked
    };
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/edgeConfig/checkHistorian`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(() => {
        this._getHistorianResponse();

        message.success('Submitted Successfully');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderFormSwitch(renderFormItems) {
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 300 }}
            placeholder={`Select ${renderFormItems.Key}`}
            onChange={(e) => this.userRef.current.setFieldsValue({ [renderFormItems.Key]: e })}
          >
            {renderFormItems.Options &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Select.Option
                      key={index}
                      value={option[renderFormItems.OptionsSettings.value]}
                    >
                      {option[renderFormItems.OptionsSettings.key]}
                    </Select.Option>
                  );
                }
                return (
                  <Select.Option key={index} value={option}>
                    {option}
                  </Select.Option>
                );
              })}
          </Select>
        );

      default:
        return <Input style={{ width: 300 }} placeholder={`Enter ${renderFormItems.Key}`} />;
    }
  }

  render() {
    let { dataSource, configObject, visible, historianShow, historianResponse } = this.state;

    const accessToken = localStorage.get('accessToken');
    var Authorization = `Bearer ${accessToken}`;

    const columns = [
      {
        title: 'DEVICE',
        key: 'Device',
        dataIndex: 'Device',
        className: 'table-column'
      },
      {
        title: 'PARAMETER CODE',
        key: 'ParameterCode',
        dataIndex: 'ParameterCode',
        className: 'table-column'
      },
      {
        title: 'DATA TYPE',
        key: 'DataType',
        dataIndex: 'DataType',
        className: 'table-column'
      },
      {
        title: 'PLC DATA TYPE',
        key: 'PLCDataType',
        dataIndex: 'PLCDataType',
        className: 'table-column'
      },
      {
        title: 'DB ADDRESS',
        key: 'DBAddress',
        dataIndex: 'DBAddress',
        className: 'table-column'
      }
    ];
    let formStructure = [
      {
        DisplayName: 'Device',
        Key: 'device',
        Widget: 'Input'
      },
      {
        DisplayName: 'ParameterCode',
        Key: 'parameterCode',
        Widget: 'Input'
      },
      {
        DisplayName: 'DataType',
        Key: 'dataType',
        Widget: 'Input'
      },
      {
        DisplayName: 'PLCDataType',
        Key: 'plcDataType',
        Widget: 'Input',
        disableOnUpdate: true
      },
      {
        DisplayName: 'DBAddress',
        Key: 'dbAddress',
        Widget: 'Input',
        disableOnUpdate: true
      }
    ];
    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 173 }}>
        <div className="edgeDiv" style={{ width: '100%', marginTop: '3%' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <Button type={''} className="buttonCss" onClick={this.showDrawer}>
                Add
              </Button>
            </Col>
            <Col span={4} offset={5}>
              {historianShow['historian'] ? (
                <Form>
                  <label style={{ fontSize: '15px', fontWeight: 'bold' }}>Store Historian : </label>
                  <Switch
                    checkedChildren={'true'}
                    unCheckedChildren={'false'}
                    onChange={this.historianOnChange}
                    defaultChecked={historianResponse.Status}
                  ></Switch>
                </Form>
              ) : null}
            </Col>
            <Col span={2}>
              <Upload
                name="file"
                accept="text/csv"
                action={`/api/edgeConfig/bulkUpload`}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                <Button type="" className="buttonCss">
                  <UploadOutlined clasName="uage" /> Click to Upload
                </Button>
              </Upload>
            </Col>
          </Row>

          <Drawer
            title={
              configObject.Device === undefined
                ? 'Create EdgeConfiguration'
                : 'Update EdgeConfiguration'
            }
            visible={visible}
            width={500}
            placement={'right'}
            onClose={this.onClose}
          >
            <Form layout="vertical">
              {formStructure &&
                Array.isArray(formStructure) &&
                formStructure.map((formItem, index) => {
                  return (
                    <Form.Item
                      key={index}
                      name={formItem.Key}
                      label={this.translation(formItem.DisplayName)}
                    >
                      {this.renderFormSwitch(formItem)}
                    </Form.Item>
                  );
                })}
            </Form>
            <DrawerFooter>
              {configObject.Device === undefined ? null : (
                <Button onClick={this.deleteConfiguration} style={{ marginRight: 8 }}>
                  Delete
                </Button>
              )}

              {configObject.Device === undefined ? (
                <Button onClick={this.createConfiguration} type="primary">
                  Create Configuration
                </Button>
              ) : (
                <Button onClick={this.updateConfiguration} type="primary">
                  Update Configuration
                </Button>
              )}
            </DrawerFooter>
          </Drawer>
          <StyledTable theme={lightTheme}>
            <Table
              className="table"
              columns={columns}
              // size="large"
              pagination={
                dataSource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : 1
              }
              locale={{ emptyText: 'No Data' }}
              dataSource={dataSource}
              onRow={(record) => ({
                onClick: (e) => {
                  e.stopPropagation();
                  this.onRecord(record, e);
                }
              })}
            ></Table>
          </StyledTable>
        </div>
      </StyledDashboard>
    );
  }
}
export default EdgeConfiguration;
