import styled from 'styled-components';
import { Button } from 'antd';

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;

export const StyledDashboard = styled.div`
  background: white;
  // margin: 20px;
  padding: 30px;
`;
export const StyledButton = styled(Button)`
  display: flex;
  justify-content: right;
`;
export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;
