import React, { Fragment } from 'react';

import { getbookMarkByname } from './ChartCalls';
import Trends from './Trends';

export default class ViewTrends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trendData: {}
    };
  }

  async UNSAFE_componentWillMount() {
    let module = this.props.match.params.module;
    let bookmarkId = this.props.match.params.id;
    let data = await getbookMarkByname(module, bookmarkId);
    if (data && data[0]) {
      let tunedData = data[0].Payload;
      this.setState({ tunedData });
    }
  }

  render() {
    return (
      <Fragment>
        <Trends tunedData={this.state.tunedData} />
      </Fragment>
    );
  }
}
