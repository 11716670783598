import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import rubusSLogo from '../../../../images/rubus_new/favicon.jpeg';

class AssetDeviceBreadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbList: props.breadcrumbList || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.breadcrumbList !== this.props.breadcrumbList) {
      this.setState({
        breadcrumbList: this.props.breadcumbList
      });
    }
  }

  changeOnBreadCrumb = (asset) => {
    let { breadcrumbList } = this.props;
    let checkCondition = false;
    let newBreadcrumbList =
      breadcrumbList &&
      Array.isArray(breadcrumbList) &&
      breadcrumbList.filter((breadcrumb) => {
        if (JSON.stringify(breadcrumb) === JSON.stringify(asset)) {
          checkCondition = true;
          return true;
        }
        return !checkCondition;
      });
    if (asset && typeof asset === 'string') {
      newBreadcrumbList = [];
    }
    this.props.actions.breadcrumbList(newBreadcrumbList);
    this.props.actions.currentAsset(asset);
    this.props.actions.currentDevice({});
  };

  render() {
    const { location } = this.props.history;
    const { breadcrumbList } = this.props;
    let menuName = '';
    const pathList = location.pathname.replace('rubus', '');
    const pathSnippets = pathList.split('/').filter((i) => i);
    const pathSnippets1 = location.pathname.split('/assetConfiguration/').filter((i) => i);
    const subBreadC =
      breadcrumbList &&
      Array.isArray(breadcrumbList) &&
      breadcrumbList.map((asset) => {
        return (
          <Breadcrumb.Item key={{ asset }}>
            <Link
              to={{ pathname: `${asset.AssetCode}` }}
              onClick={(e) => {
                this.changeOnBreadCrumb(asset, e);
              }}
            >
              {asset.Name}
            </Link>
          </Breadcrumb.Item>
        );
      });

    let subBreadCStatus = false;
    const pathSnippetsFormatted = pathSnippets
      .map((snippet) => {
        const pathURL = location.pathname.split(snippet).filter((i) => i);
        menuName = snippet;
        snippet = _.startCase(snippet);
        snippet.replace(/-/g, ' ');

        if (
          menuName === pathSnippets1[1] &&
          breadcrumbList &&
          breadcrumbList.length !== 0 &&
          menuName !== 'asset'
        ) {
          subBreadCStatus = true;
          return <Breadcrumb.Item key={{ snippet }}></Breadcrumb.Item>;
        } else {
          subBreadCStatus = false;
          return (
            <Breadcrumb.Item key={{ snippet }}>
              <Link
                to={{ pathname: `${pathURL[0]}${menuName}` }}
                onClick={(e) => {
                  this.changeOnBreadCrumb(menuName, e);
                }}
              >
                {snippet}
              </Link>
            </Breadcrumb.Item>
          );
        }
      })
      .concat(subBreadCStatus ? subBreadC : null);

    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        <Link to="/rubus/assetConfiguration">
          {' '}
          <img
            src={rubusSLogo}
            alt="logo"
            height="12"
            style={{ marginTop: '-3px', height: '12px' }}
          />
          <span style={{ marginLeft: '3px' }}>Rubus</span>
        </Link>
      </Breadcrumb.Item>
    ].concat(pathSnippetsFormatted);

    return (
      <div>
        <div
          style={{
            background: 'white',
            display: this.props.breadCrumb === true ? 'none' : 'block'
          }}
        >
          <Breadcrumb style={{ padding: '5px', marginLeft: '48px' }}>{breadcrumbItems}</Breadcrumb>
          <div style={{ marginLeft: 50 }}>
            <h1>{_.startCase(menuName)}</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetDeviceBreadcrumbs;
