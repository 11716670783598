import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { Form, message, Select, Input, Button } from 'antd';
import history from '../../../../../commons/history';
import TableView from '../../../Widgets/TableView';
import FileBase64 from '../../../Utils/FileUpload';
import { check } from '../../../Utils/FormCalls';
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import TableSelect from '../../../Widgets/TableSelect';
import {
  StyledComponent,
  StyledForm,
  StyledButton,
  StyledSubHeading,
  StyledModal,
  StyledBox
} from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import Highlighter from 'react-highlight-words';
import {
  getSitesList,
  getLocationByAsset,
  submitMaster,
  getDeviceList,
  getManufacturerList,
  getHierarchyList,
  getMasterById
} from '../../../Utils/FormCalls';
import { decode as base64_decode } from 'base-64';
import { PaperClipOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { constant } from '../../../Utils/constants';
import localStorage from '../../../../../utils/localStorage';
import { getTranslationCache } from '../../../../../selectors/language';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
import { getUserProfile } from '../../../../../selectors/layout';

const { Option } = Select;
const type = 'Item';
const urlList = {
  insert: '/api/masterItems/insert',
  getbyId: '/api/masterItems/getById',
  delete: 'api/masterItems/getById?Id=3'
};
const fileData = new FormData();

class ItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      datasource:
        props.formObject && props.formObject.Specifications ? props.formObject.Specifications : [],
      tableData: [],
      locationList: [],
      ItemTypeList: [],
      hierarchyList: [],
      ManufacturerList: [],
      visible: false,
      deviceList: [],
      status: 'false',
      translationCache: props.translationCache || [],
      columns: [
        {
          title: translation(props.translationCache, constant.name),
          key: 'Name',
          dataIndex: 'Name',
          widget: 'input',
          required: true
          // maxLength: 50
        },
        {
          title: translation(props.translationCache, constant.value),
          key: 'Value',
          dataIndex: 'Value',
          widget: 'inputNumber',
          required: true
          // maxLength: 30
        },
        {
          title: translation(props.translationCache, constant.unitOfMeasure),
          key: 'UOM',
          dataIndex: 'UOM',
          widget: 'select',
          required: true,
          table: {
            url: '/api/masterconfiguration/getDropdown',
            method: 'POST',
            payload: {
              TableName: 'appconfig."Site_Master_UnitOfMeasures"',
              ColumnName: 'Name'
            }
          }
        }
      ]
    };
  }

  ItemRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.ItemRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.ItemRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let ManufacturerList = await getManufacturerList();
      this.setState({ ManufacturerList: ManufacturerList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.ItemRef.current) this.ItemRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        datasource:
          this.props.formObject && this.props.formObject.Specifications
            ? this.props.formObject.Specifications
            : []
      });
    }
  };
  _getDataById = async (ItemId) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/ItemDetails/');
    let formId = ItemId ? ItemId : base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getMasterById(urlList.getbyId, formId, 'GET');
      formObject = data && data !== undefined ? data : {};
      _.cloneDeepWith(data, (value) => {
        return value && value !== null && value.StartTime && value.EndTime
          ? {
              ...value,
              ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
            }
          : _.noop();
      });
    }
    if (this.ItemRef.current) {
      this.ItemRef.current.setFieldsValue(formObject);
    }
    this.setState({
      formObject,
      datasource: formObject && formObject.Specifications ? formObject.Specifications : []
    });
  };
  handleChange = async (value, key) => {
    let { deviceList } = this.state;
    let formObject = this.ItemRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.data.Name
      };
      // this.ItemRef.current.setFieldsValue({"LocationId":location["Name"]})
    }
    if (this.ItemRef.current) this.ItemRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };

  fileupload = async (values, key, fileDataObject) => {
    let { datasource } = this.state;
    let formObject = this.ItemRef.current.getFieldsValue();
    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      Specifications: datasource,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.ItemRef.current) this.ItemRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };

  onFormsubmit = async (values) => {
    let { formObject, fileData } = this.state;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let Specifications = [];
    let SpecificationsForm =
      formObject && formObject.Specifications ? formObject.Specifications : [];
    if (SpecificationsForm && Array.isArray(SpecificationsForm)) {
      let editableList = [];
      editableList = SpecificationsForm.filter((specifi) => {
        return specifi.editable === true;
      });
      if (editableList && Array.isArray(editableList) && editableList.length === 0) {
        Specifications = SpecificationsForm.map((spec) => {
          delete spec.editable;
          delete spec.key;
          return spec;
        });
        let payload = {
          ...formObject,
          ...values,
          ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
          ...(formObject && formObject.Id ? { UpdatedBy: userId } : {}),
          Specifications
        };
        let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
        if (statusResponse && statusResponse.message) {
          this._getDataById(statusResponse.Id);
          message.success(statusResponse.message);
          // this.onReset();
        } else if (statusResponse && statusResponse.Error) {
          message.error(statusResponse.Error);
        }
      } else {
        message.warn('Please save specifications before submitting');
      }
    }
  };

  onReset = () => {
    if (this.ItemRef.current) this.ItemRef.current.resetFields();
  };
  parentNode = (parent) => {
    let formObject = this.ItemRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Location: parent.Code
    };
    this.ItemRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };

  saveTableViewData = (datasource) => {
    let formObject = this.ItemRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Specifications: datasource
    };
    this.setState({ formObject, datasource });
  };
  onDrawerCancel = (e) => {
    let formObject = this.ItemRef.current.getFieldsValue();
    if (formObject.Equipment === undefined && e.target.tagName === 'DIV') {
      e.preventDefault();
      return message.error(`Please Click On Cancel`);
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  handleFieldChange(index, fieldName, key) {
    let e = '';
    e = index;

    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target[fieldName] = e;
      this.setState({ data: newData });
    }
  }

  onManufacturerClick = (data) => {
    let { ManufacturerList, datasource } = this.state;
    let formObject = this.ItemRef.current.getFieldsValue();
    let testdata = ManufacturerList.filter((test) => test.Id === data);

    formObject = {
      ...formObject,
      Specifications: datasource,
      ManufacturerId: testdata[0].Id,
      ManufacturerDescription: testdata[0].Description
    };
    this.ItemRef.current.setFieldsValue(formObject);
    this.setState({
      formObject
    });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.ItemRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.ItemRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      EquipmentDescription: value.Name,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode
    };
    this.ItemRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let { formObject, ManufacturerList, columns, datasource, translationCache, sitesList } =
      this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <StyledForm
          name="Item"
          theme={themeSettings}
          ref={this.ItemRef}
          initialValues={formObject}
          onFinish={this.onFormsubmit}
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
          validateMessages={validateMessages}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <StyledButton theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.submit)}
              </StyledButton>
            </Form.Item>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Id"></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.item)}
                name="Item"
                style={{
                  marginTop: '30px',
                  width: '100%',
                  flex: '50%'
                }}
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.inputItem)}
                  disabled={formObject && formObject.Id ? true : false}
                  onChange={(e) => this.handleChange(e.target.value, 'Item')}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%',
                  marginTop: '30px'
                }}
                label={translation(translationCache, constant.itemDescription)}
                name="Description"
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={150}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputDescription)}
                  onChange={(e) => this.handleChange(e.target.value, 'Description')}
                />
              </Form.Item>

              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.equipment)}
                name="DisplayCode"
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  disabled={true}
                  suffix={
                    <DownOutlined
                      style={{
                        color: '#1a3b5c',
                        cursor: formObject && formObject.Id ? 'not-allowed' : null,
                        opacity: formObject && formObject.Id ? '0.8' : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (formObject && !formObject.Id) {
                          this.getDeviceData('visible');
                        }
                      }}
                    />
                  }
                  // maxLength={150}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.SelectEquipment)}
                  // onClick={() => {
                  //   this.getDeviceData('visible');
                  // }}
                />
              </Form.Item>
              <StyledModal
                open={this.state.visible}
                width={800}
                closable
                title={translation(translationCache, constant.selectEquipment)}
                onOk={this.onModalOk}
                onCancel={this.onDrawerCancel}
              >
                <TableSelect
                  data={this.state.tableData}
                  translationCache={this.props.translationCache}
                  key="Equipment"
                  columns={[
                    {
                      title: translation(translationCache, 'Equipment'),
                      dataIndex: 'DisplayCode',
                      key: 'DisplayCode',
                      ...this.getColumnSearchProps('DisplayCode')
                    },
                    {
                      title: translation(translationCache, 'Equipment Description'),
                      dataIndex: 'Name',
                      key: 'Name',
                      ...this.getColumnSearchProps('Name')
                    }
                  ]}
                  handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                />
                {/* <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                  deviceList={this.state.deviceList}
                /> */}
              </StyledModal>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.equipmentDescription)}
                name="EquipmentDescription"
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={150}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.AutopopulatebasedonEquipment)}
                  disabled={true}
                />
              </Form.Item>
            </StyledBox>
            <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                label={translation(translationCache, constant.issueUnit)}
                name="IssueUnit"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputIssueUnit)}
                  value={'WO-11'}
                  onChange={(e) => this.handleChange(e.target.value, 'IssueUnit')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                label={translation(translationCache, constant.orderUnit)}
                name="OrderUnit"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputOrderUnit)}
                  onChange={(e) => this.handleChange(e.target.value, 'OrderUnit')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.itemType)}
                name="ItemType"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                {/* <Select style={{ width: "90%" }} placeholder="Select ItemType" onChange={(e) => this.handleChange(e, "ItemType")}>
                      {ItemTypeList.map((ItemType) => { return (<Option value={ItemType.Id}> {ItemType.Name} </Option>); })}
                    </Select> */}
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputItemType)}
                  value={'WO-11'}
                  onChange={(e) => this.handleChange(e.target.value, 'ItemType')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.storeLocation)}
                name="StoreLocation"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputStoreLocation)}
                  onChange={(e) => this.handleChange(e.target.value, 'StoreLocation')}
                />
              </Form.Item>

              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.site)}
                name="SiteId"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.SelectSite)}
                  disabled={formObject && formObject.Id ? true : false}
                  onChange={(e) => this.handleChange(e, 'SiteId')}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                name={'Attachments'}
                label={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      {translation(translationCache, constant.attachments)}
                      <PaperClipOutlined className="plusCircleOutlined" />
                    </span>
                  </div>
                }
              >
                <FileBase64
                  style={{ width: '70%' }}
                  Attachments={formObject.Attachments}
                  fileUpload={this.fileupload}
                ></FileBase64>
              </Form.Item>
            </StyledBox>
            <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                label={translation(translationCache, constant.material)}
                name="Material"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputMaterial)}
                  onChange={(e) => this.handleChange(e.target.value, 'Material')}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                label={translation(translationCache, constant.partsNameSystem)}
                name="PartsNameSystem"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.inputPartsNameSystem)}
                  onChange={(e) => this.handleChange(e.target.value, 'PartsNameSystem')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.manufacturer)}
                name="ManufacturerId"
              >
                <Select
                  style={{ width: '70%' }}
                  value={formObject.Manufacturer}
                  placeholder={translation(translationCache, constant.SelectManufacturer)}
                  onChange={(e) => this.onManufacturerClick(e)}
                >
                  {ManufacturerList &&
                    Array.isArray(ManufacturerList) &&
                    ManufacturerList.map((code, index) => {
                      return (
                        <Option key={index} value={code.Id}>
                          {' '}
                          {code.Manufacturer}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.manufacturerDescription)}
                name="ManufacturerDescription"
                rules={[
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                    // pattern: new RegExp(/^[^%!^ ]*$/)
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(
                    translationCache,
                    constant.AutopopulatebasedonManufacturer
                  )}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.Quantity)}
                name="AvailableQuantity"
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => check(value, 'Item', { validateDecimal: true })
                  }
                ]}
              >
                <InputNumberComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, constant.InputQuantity)}
                  onChange={(e) => this.handleChange(e, 'AvailableQuantity')}
                />
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>

              <Form.Item name="Equipment"></Form.Item>
            </StyledBox>
          </div>

          <StyledSubHeading>
            {translation(translationCache, constant.specifications)}
          </StyledSubHeading>

          <Form.Item>
            <TableView
              form={{ columns: columns, datasource }}
              recordType={'FormSubSectionTable'}
              saveTableViewData={(e) => this.saveTableViewData(e)}
              showAddButton={true}
            />
          </Form.Item>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ItemDetails);
