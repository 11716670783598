import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { defaultRefreshTime } from '../../constant.json';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { ArrowDownOutlined, ArrowUpOutlined, BellFilled } from '@ant-design/icons';
import '../../CSS/style.css';

class StatusCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      uom: this.props.graphprops.uom ? this.props.graphprops.uom : '',
      statusCounter: 0,
      status: 'down',
      translationCache: props.translationCache || [],
      image: '',
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <div
            className="statusCounter"
            style={{
              textAlign: 'center',
              backgroundColor:
                this.props.graphprops &&
                this.props.graphprops[graphprops.graph] &&
                this.props.graphprops[graphprops.graph].backgroundColor
                  ? this.props.graphprops[graphprops.graph].backgroundColor
                  : ''
            }}
          >
            <div
              style={{
                color: this.props.graphprops[graphprops.graph].titleColor
                  ? this.props.graphprops[graphprops.graph].titleColor
                  : 'steelblue',
                fontSize: this.props.graphprops[graphprops.graph].fontSize
                  ? this.props.graphprops[graphprops.graph].fontSize + 'em'
                  : '5em'
              }}
            >
              {this.props.graphprops[graphprops.graph].statusCounterType === 'notification' ? (
                <div>
                  <BellFilled
                    style={{
                      marginRight: '4px',
                      fontWeight: '800',
                      fontSize: '0.8em',
                      color:
                        this.props.graphprops &&
                        this.props.graphprops[graphprops.graph] &&
                        this.props.graphprops[graphprops.graph].iconColor &&
                        this.props.graphprops[graphprops.graph].iconColor[0]
                          ? this.props.graphprops[graphprops.graph].iconColor[0]
                          : 'green'
                    }}
                  />
                  <span
                    style={{
                      color:
                        this.props.graphprops &&
                        this.props.graphprops[graphprops.graph] &&
                        this.props.graphprops[graphprops.graph].iconColor &&
                        this.props.graphprops[graphprops.graph].iconColor[0]
                          ? this.props.graphprops[graphprops.graph].iconColor[0]
                          : 'green'
                    }}
                  >
                    {this.state.counter}
                  </span>
                  <BellFilled
                    style={{
                      marginRight: '4px',
                      fontWeight: '800',
                      fontSize: '0.8em',
                      color:
                        this.props.graphprops &&
                        this.props.graphprops[graphprops.graph] &&
                        this.props.graphprops[graphprops.graph].iconColor &&
                        this.props.graphprops[graphprops.graph].iconColor[1]
                          ? this.props.graphprops[graphprops.graph].iconColor[1]
                          : 'red'
                    }}
                  />
                  <span
                    style={{
                      color:
                        this.props.graphprops &&
                        this.props.graphprops[graphprops.graph] &&
                        this.props.graphprops[graphprops.graph].iconColor &&
                        this.props.graphprops[graphprops.graph].iconColor[1]
                          ? this.props.graphprops[graphprops.graph].iconColor[1]
                          : 'red'
                    }}
                  >
                    {this.state.statusCounter}
                    {this.state.uom}
                  </span>
                </div>
              ) : this.props.graphprops[graphprops.graph].statusCounterType === 'Image & string' ? (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    {this.state.counter}
                    {this.state.uom}
                  </div>

                  <span
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={this.state.image ? this.state.image : ''}
                      type="image"
                      alt=""
                      style={{ height: '100px', width: '110px', marginTop: '7px' }}
                    />
                  </span>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    {this.state.counter}
                    {this.state.uom}
                  </div>
                </div>
              )}
            </div>

            {this.props.graphprops.graph === 'statusCounter' && this.state.status === 'High' ? (
              <div
                style={{
                  color:
                    this.props.graphprops[graphprops.graph] &&
                    this.props.graphprops[graphprops.graph].iconColor &&
                    this.props.graphprops[graphprops.graph].iconColor[0]
                      ? this.props.graphprops[graphprops.graph].iconColor[0]
                      : '#389e0d',
                  marginRight: '2%',
                  fontSize: '1.5em',
                  textAlign: 'center',
                  fontWeight: '600'
                }}
              >
                <ArrowUpOutlined
                  style={{
                    marginRight: '4px',
                    fontWeight: '800',
                    fontSize: '0.8em'
                  }}
                />
                {this.state.statusCounter} {this.state.uom}
              </div>
            ) : this.props.graphprops.graph === 'statusCounter' && this.state.status === 'Low' ? (
              <div
                style={{
                  color:
                    this.props.graphprops &&
                    this.props.graphprops[graphprops.graph] &&
                    this.props.graphprops[graphprops.graph].iconColor &&
                    this.props.graphprops[graphprops.graph].iconColor[0]
                      ? this.props.graphprops[graphprops.graph].iconColor[0]
                      : 'white',
                  marginRight: '2%',
                  fontSize: '1.5em',
                  textAlign: 'center',
                  fontWeight: '600'
                }}
              >
                <ArrowDownOutlined
                  style={{
                    marginRight: '4px',
                    fontWeight: '800',
                    fontSize: '0.8em'
                  }}
                />
                {this.state.statusCounter}
                {this.state.uom}
              </div>
            ) : this.props.graphprops.graph === 'statusCounter' && this.state.status === 'Equal' ? (
              <div
                style={{
                  color:
                    this.props.graphprops &&
                    this.props.graphprops[graphprops.graph] &&
                    this.props.graphprops[graphprops.graph].iconColor &&
                    this.props.graphprops[graphprops.graph].iconColor[0]
                      ? this.props.graphprops[graphprops.graph].iconColor[0]
                      : 'white',
                  marginRight: '2%',
                  fontSize: '1.5em',
                  textAlign: 'center',
                  fontWeight: '600'
                }}
              >
                {this.state.statusCounter}
                {this.state.uom}
              </div>
            ) : null}
          </div>
        )}
      </Fragment>
    );
  }

  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let data = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else {
        data = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }
    // data = [
    //   {
    //     interval_alias: "2020-05-12T21:49:05.161Z",
    //     tagname: "TRR_TIC301_PV",
    //     tagvalue: 395.4,
    //     status: "Low",
    //     StatusValue: "54.0",
    //     tag_description: "Main stream temperature control",
    //   },
    // ];
    if (
      (data && data.message) ||
      (Array.isArray(data) && data.length === 0 && graphprops.enableMock !== true)
    ) {
      if (data.message) {
        errorInAPI = true;
      } else if (data.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph].statusCounterType === 'number'
      ) {
        this.setState({ counter: Number(data[0].Value).toFixed(2) });
        this.setState({
          statusCounter: data[0].StatusValue,
          status: data[0].Status
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph].statusCounterType === 'string'
      ) {
        this.setState({ counter: data[0].Value });
        this.setState({
          statusCounter: data[0].StatusValue,
          status: data[0].Status
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph].statusCounterType === 'both'
      ) {
        this.setState({
          uom: this.props.graphprops[graphprops.graph].uom,
          statusCounter: data[0].StatusValue,
          status: data[0].Status,
          counter: Number(data[0].Value).toFixed(2)
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph].statusCounterType === 'notification'
      ) {
        this.setState({
          statusCounter: data[0].StatusValue,
          counter: Number(data[0].Value).toFixed(2)
        });
      } else if (
        data &&
        Array.isArray(data) &&
        data.length > 0 &&
        this.props.graphprops[graphprops.graph].statusCounterType === 'Image & string'
      ) {
        this.setState({
          status: data[0].Status,
          statusCounter: data[0].StatusValue,
          counter: data[0].Value,
          image: data[0].Description
        });
      }

      if (data && Array.isArray(data) && data[0] && data[0].Value === null) {
        this.setState({ errorInAPI, noDataInDB: !noDataInDB });
      } else {
        this.setState({ errorInAPI, noDataInDB });
      }
    }
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(StatusCounter);
