import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';

import { Tag } from 'antd';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import ReactEcharts from '../../Echarts/Echarts';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { guageLineMockJson } from '../../../Mock/MockJson';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class GuageLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //option: this.getData(props),
      option: this.getOption(props),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.guageName = '';
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            //onEvents={onEvents}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getData = async () => {
    let theme = this.props.selectedThemeing;
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = guageLineMockJson;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      var guage = json && json.query;
      var line = json && json.query1;
      if (line && Array.isArray(line)) {
        let dates = [];
        let values = [];
        let name = '';
        let tagnames = [];
        let series = [];
        let legendsdata = [];
        // let finalArray = [];
        line.map((val) => {
          if (val.Name !== name) {
            if (tagnames.indexOf(val.Name) === -1) {
              tagnames.push(val.Name);
              name = val.Name;
              legendsdata.push({
                name: val.Description,

                icon: 'roundRect',

                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });

        let yAxis = {
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          },
          gridIndex: 0
        };

        tagnames.map((tagval, tagindex) => {
          values = [];
          line.map((jsonval) => {
            if (tagnames[0] === jsonval.Name && tagindex === 0) {
              dates.push(
                moment
                  .utc(jsonval.DateTime)
                  .tz(this.props.currentTimeZone)
                  .format('DD/MM/YYYY HH:mm:ss')
              );
            }
            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });

          series.push({
            name: legendsdata[tagindex]['name'],
            data: values,
            type: 'line',
            axisLabel: { fontSize: 15 },
            //   areaStyle: {},
            smooth: true
          });
          return {};
        });

        tagnames.map((tagval) => {
          values = [];
          Object.keys(guage).map((jsonval) => {
            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });
          return {};
        });

        guage = Object.keys(guage).map((jsonData) => {
          return {
            name:
              this.props.graphprops && this.props.graphprops.uom ? this.props.graphprops.uom : '',
            title: {
              offsetCenter: [0, '20%'],
              fontSize: 20,
              fontStyle: 'italic',
              color:
                this.props.graphprops && this.props.graphprops.titleColor
                  ? this.props.graphprops.titleColor
                  : ''
            },
            // name: jsonData,
            value: guage[jsonData].Value
          };
        });
        series.push({
          // name: legendsdata[tagindex]["name"],
          splitNumber: 8,
          min:
            graphprops && graphprops.range !== undefined && graphprops.range !== null
              ? graphprops.range[0]
              : 0,
          max:
            graphprops && graphprops.range !== undefined && graphprops.range !== null
              ? graphprops.range[1]
              : 100,

          type: 'gauge',
          radius: '40%',
          center: ['50%', '25%'],
          axisLine: {
            lineStyle: {
              width: 30,
              color: [
                [0.3, '#67e0e3'],
                [0.7, '#37a2da'],
                [1, '#fd666d']
              ]
            }
          },
          pointer: {
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            distance: -30,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          splitLine: {
            distance: -30,
            length: 30,
            lineStyle: {
              color: '#fff',
              width: 4
            }
          },
          axisLabel: {
            color: 'auto',
            distance: 40,
            fontSize: 10
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}',
            color: 'auto',
            fontSize: 20
          },
          data: guage
        });

        const option = cloneDeep(this.getOption());
        series = await trendLabelTranslation(series, this.props.translationCache);
        option.xAxis.data = [...dates];
        option.yAxis = { ...yAxis };
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option: option,
          refreshDateTime: moment()
        });
      }
      this.setState({
        errorInAPI,
        noDataInDB
      });
    }
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} <br/>  {c}'
      },
      label: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 14
      },
      textStyle: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 18
      },
      legend: {
        left: 'center',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      // tooltip: {
      //   trigger: "axis",
      //   //showContent: false
      // },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: 'black',
            iconStyle: {
              // color: "none",
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },

      grid: [
        {
          top: '45%',
          width: '100%',
          //bottom: '45%',
          left: 10,
          height: '50%',
          containLabel: true
        },
        {
          top: 0,
          width: '100%',
          bottom: 0,
          left: 0,
          height: '50%',
          containLabel: false
        }
      ],
      xAxis: {
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        },
        type: 'category'
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        },
        gridIndex: 0
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(GuageLine);
