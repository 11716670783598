import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { flattenDeep } from 'lodash';
import {
  Form,
  message,
  Select,
  Button,
  Divider,
  Space,
  Row,
  Col,
  Input,
  DatePicker,
  Tooltip,
  Tree
} from 'antd';
import {
  getSitesList,
  getLocationByAsset,
  submitMaster,
  getDeviceList,
  questionsListMaster,
  getinspectionAreaList,
  getinspectionItemList,
  getAnswersList,
  getWorkPlanningList,
  saveQuestionData,
  saveAreaData,
  saveItemData,
  getEquipmentRecord,
  getMasterById,
  getEquipmentHierarchy,
  getInspectionData,
  getUsersList,
  getStatusListPM
} from '../.././../Utils/FormCalls';
import Highlighter from 'react-highlight-words';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';

import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import TableSelect from '../../../Widgets/TableSelect';
import {
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal,
  StyledTree
} from '../../../CSS/style';
import history from '../../../../../commons/history';
import { constant } from '../../../Utils/constants';
import { decode as base64_decode } from 'base-64';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { PlusOutlined } from '@ant-design/icons';
import InputComponent from '../../../Widgets/InputComponent';
import localStorage from '../../../../../utils/localStorage';
import { getUserProfile } from '../../../../../selectors/layout';
const { Option } = Select;
const type = 'Item';
const urlList = {
  insert: '/api/inspectionconfig/insert',
  delete: 'api/masterItems/getById?Id=3',
  getbyId: '/api/inspectionconfig/getById',
  qInsert: '/api/inspectionQuestions/insert'
};
const fileData = new FormData();
class QuestionMasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      answersList: [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 2,
          name: 'No'
        }
      ],
      translationCache: props.translationCache || [],
      visible: false,
      deviceList: [],
      questionList: [],
      inspectionAreaList: [],
      inspectionItemList: [],
      status: 'false',
      AnswerList: [],
      WorkPlanList: [],
      open: 'false',
      viewQuestionModal: false,
      ViewModal: false,
      viewAreaModal: false,
      viewItemModal: false,
      EquipmentRecord: [],
      getById: '',
      answerKey: [],
      hierarchyList: [],
      formObjectId: '',
      formObjectQuestions: {},
      newRecord: false
    };
  }
  InspectionMastersRef = React.createRef();
  QuestionRef = React.createRef();
  deviceRef = React.createRef();
  areaRef = React.createRef();
  ItemRef = React.createRef();
  optionRef = React.createRef();
  componentDidMount = async () => {
    try {
      // let hierarchyList = await this.getEquipmentHierarchy();
      let sitesList = await getSitesList();
      let WorkPlanList = await getWorkPlanningList();
      let questionList = await questionsListMaster();
      let inspectionAreaList = await getinspectionAreaList();
      let inspectionItemList = await getinspectionItemList();
      let AnswerList = await getAnswersList();
      let statusList = await getStatusListPM();
      let deviceList = await getDeviceList();
      let usersList = await getUsersList();
      let formObject = this.InspectionMastersRef.current.getFieldsValue();
      // let inspectionName = await getInspectionName();
      formObject = {
        ...formObject,
        SiteId: sitesList && sitesList[0] && sitesList[0].Id ? sitesList[0].Id : ''
        // InspectionId:inspectionName && inspectionName.InspectionId ? inspectionName.InspectionId : ''
      };
      this.InspectionMastersRef.current.setFieldsValue(formObject);
      this._getDataById();
      this.setState({
        questionList,
        inspectionItemList,
        statusList,
        inspectionAreaList,
        WorkPlanList,
        AnswerList,
        deviceList,
        sitesList,
        usersList,
        // hierarchyList,
        formObject
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.InspectionMastersRef.current) {
        this.InspectionMastersRef.current.setFieldsValue(this.props.formObject);
      }
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  _getDataById = async (Id) => {
    let formObject = {};

    let hierarchyList = {};
    let path = history.location.pathname.split('/rubus/AMM/InspectionMasterDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
      hierarchyList = await this.getEquipmentHierarchy(formId);
      formObject = data ? data : {};
    } else if (Id) {
      let data = await getMasterById(urlList.getbyId, Id, 'GET');
      hierarchyList = await this.getEquipmentHierarchy(Id);
      formObject = data ? data : {};
    }
    _.cloneDeepWith(formObject, (value) => {
      return value && value !== undefined && value !== null
        ? {
            ...(value['CreatedDate']
              ? (value['CreatedDate'] = moment.utc(
                  value.CreatedDate,
                  'YYYY-MM-DD HH:mm:ss'
                )).local()
              : null)
          }
        : _.noop();
    });

    this.setState({
      formObject,
      hierarchyList,
      formObjectId: formObject && formObject.Id ? formObject.Id : ''
    });
    if (formObject) {
      this.InspectionMastersRef.current.setFieldsValue(formObject);
    }
  };

  handleChange = async (value, key) => {
    let { formObject, deviceList } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.data.Name
      };
    }
    if (this.InspectionMastersRef.current)
      this.InspectionMastersRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  onanswerchange = (answerKey) => {
    this.setState({
      answerKey
    });
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.InspectionMastersRef.current.getFieldsValue();
    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.InspectionMastersRef.current)
      this.InspectionMastersRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onInspecFormsubmit = async (values) => {
    let { formObjectQuestions, fileData, formObjectId } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      ...formObjectQuestions,
      ...values,
      ...(formObjectId ? { InspectionId: formObjectId } : ''),
      SiteId: siteId
    };
    let statusResponse = await submitMaster(urlList.qInsert, payload, fileData, type);
    if (statusResponse && statusResponse.message) {
      message.success(statusResponse.message);
      this.getEquipmentHierarchy(payload.InspectionId);
      // this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };

  onFormsubmit = async (values) => {
    let { formObject, fileData, formObjectId } = this.state;
    const siteId = localStorage.get('currentSite');
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let payload = {
      ...formObject,
      ...values,
      ...(formObject && formObjectId ? { Id: formObjectId } : ''),
      ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
      ...(formObjectId ? { UpdatedBy: userId } : {}),
      SiteId: siteId
    };
    let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
    if (statusResponse && statusResponse.message) {
      message.success(statusResponse.message);
      this._getDataById(statusResponse.Id);
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    if (this.QuestionRef.current) this.QuestionRef.current.resetFields();
    this.setState({ formObjectQuestions: {} });
  };
  onDrawerCancel = () => {
    this.setState({
      visible: false
    });
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  onChangeQuestion = () => {
    let formObjectQuestions = this.QuestionRef.current.getFieldsValue();
    formObjectQuestions = {
      ...formObjectQuestions
    };
    this.setState({
      formObjectQuestions
    });
    this.QuestionRef.current.setFieldsValue(formObjectQuestions);
  };
  openCollapse = () => {
    this.setState({ open: true });
  };
  onAreaClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewAreaModal: false });
      this.areaRef.current.resetFields();
    }
  };
  onFormAreaSubmit = (e) => {
    e.preventDefault();
    this.areaRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          data: { ...values },
          MasterName: 'Master_Inspection_Areas',
          ActionType: 'create'
        };
        let CreateArea = await saveAreaData(payload);
        if (CreateArea) {
          message.success(CreateArea.message);
          let inspectionAreaList = await getinspectionAreaList();
          this.setState({ inspectionAreaList, viewAreaModal: false });

          if (this.areaRef.current) this.areaRef.current.resetFields();
          let formObjectQuestions = this.QuestionRef.current.getFieldsValue();
          let areaId = inspectionAreaList.filter((item) => item.Area === values.Area);
          if (areaId && areaId[0]) {
            formObjectQuestions = {
              ...formObjectQuestions,
              AreaId: areaId[0].Id
            };

            this.QuestionRef.current.setFieldsValue(formObjectQuestions);
            this.setState({ formObjectQuestions, viewAreaModal: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onItemClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewItemModal: false });
      if (this.ItemRef.current) this.ItemRef.current.resetFields();
    }
  };
  onFormItemSubmit = (e) => {
    e.preventDefault();
    this.ItemRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          MasterName: 'Master_Inspection_Items',
          ActionType: 'create',
          data: {
            ...values
          }
        };
        let CreateItemData = await saveItemData(payload);
        if (CreateItemData) {
          message.success(CreateItemData.message);
          let inspectionItemList = await getinspectionItemList();
          this.setState({ inspectionItemList, viewItemModal: false });
          if (this.ItemRef.current) this.ItemRef.current.resetFields();
          let formObjectQuestions = this.QuestionRef.current.getFieldsValue();
          let itemId = inspectionItemList.filter((item) => item.Item === values.Item);
          if (itemId && itemId[0]) {
            formObjectQuestions = {
              ...formObjectQuestions,
              ItemId: itemId[0].Id
            };

            this.QuestionRef.current.setFieldsValue(formObjectQuestions);
            this.setState({ formObjectQuestions, viewItemModal: false });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  adQuestionForm = () => {
    this.setState({ viewQuestionModal: true });
    if (this.deviceRef.current) this.deviceRef.current.resetFields();
  };
  addAnswerModal = () => {
    this.setState({ ViewModal: true });
    if (this.optionRef.current) this.optionRef.current.resetFields();
  };
  onQuestionsClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ viewQuestionModal: false });
    }
  };
  onModalclose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({ ViewModal: false });
    }
  };

  onModalFormsubmit = () => {
    this.deviceRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        // if (values.Answers.length === 2 && values.Answers.length !== 1) {
        let createDeviceResponse = await saveQuestionData(payload);
        if (createDeviceResponse) {
          message.success(createDeviceResponse.message);
          let questionList = await questionsListMaster();
          let formObjectQuestions = this.QuestionRef.current.getFieldsValue();
          let questionId = questionList.filter((item) => item.Question === values.Question);
          formObjectQuestions = {
            ...formObjectQuestions,
            QuestionIds: [
              ...(formObjectQuestions && formObjectQuestions['QuestionIds']
                ? formObjectQuestions['QuestionIds']
                : []),
              questionId[0].Id
            ]
          };
          this.QuestionRef.current.setFieldsValue(formObjectQuestions);
          this.setState({
            formObjectQuestions,
            viewQuestionModal: false,
            questionList,
            ViewModal: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onHandleChange = async (e, type) => {
    let form = this.QuestionRef.current.getFieldsValue();

    let formObjectQuestions = {
      ...form
    };
    let payload = {
      // Equipment: formObjectQuestions.Equipment,
      AreaId: formObjectQuestions.AreaId,
      ItemId: formObjectQuestions.ItemId
    };
    if (type === 'ItemId') {
      let EquipmentRecord = await getEquipmentRecord(payload);
      let getById = EquipmentRecord && EquipmentRecord[0] && EquipmentRecord[0].Id;
      this.setState({ EquipmentRecord, getById: getById });
      formObjectQuestions = {
        ...formObjectQuestions,
        QuestionIds:
          EquipmentRecord &&
          EquipmentRecord &&
          EquipmentRecord[0] &&
          EquipmentRecord[0] !== undefined
            ? EquipmentRecord[0].QuestionIds
            : []
      };
      this.setState({
        formObjectQuestions
      });
    }

    this.QuestionRef.current.setFieldsValue(formObjectQuestions);
    this.setState({ formObjectQuestions });
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObjectQuestions: this.InspectionMastersRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObjectQuestions: this.QuestionRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObjectQuestions = this.QuestionRef.current.getFieldsValue();
    formObjectQuestions = {
      ...formObjectQuestions,
      Equipment: value.AliasCode,
      DeviceCode: value.DeviceCode
    };
    this.setState({
      formObjectQuestions,
      [modal]: false
    });
    this.QuestionRef.current.setFieldsValue(formObjectQuestions);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getEquipmentHierarchy = async (Id) => {
    let treeData = await getEquipmentHierarchy(Id);
    if (treeData) {
      this.expandAll(treeData);
    }
    this.setState({ treeData, formObjectQuestions: {}, formObjectId: Id });
  };
  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              title={item && !item.ParentId ? `${item.Code} (${item.Name})` : item.Name}
              key={item.key}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return <Tree.TreeNode key={item.key} title={<div>{item.Name}</div>} dataRef={item} />;
      })
    );
  };
  onExpand = (expandedKeys) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys
    });
  };
  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };
  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys =
      data &&
      Array.isArray(data) &&
      data.map((node) => {
        let childKeys = [];
        if (node.children) {
          childKeys = this.getAllKeys(node.children);
        }
        return [childKeys, node.key];
      });
    return flattenDeep(nestedKeys);
  };
  onSelect = async (selectedKeys, info) => {
    let { formObjectId } = this.state;
    let status =
      info &&
      info['selectedNodes'] &&
      info['selectedNodes'][0] &&
      info['selectedNodes'][0]['dataRef'] &&
      info['selectedNodes'][0]['dataRef']['Type'] &&
      info['selectedNodes'][0]['dataRef']['Type'] === 'Items';
    if (status) {
      let formObjectQuestions = {};
      let dataRef = info['selectedNodes'][0]['dataRef'];
      let questionPayload = {
        Equipment: dataRef && dataRef['MainParentId'] ? dataRef['MainParentId'] : '',
        AreaId: dataRef && dataRef['ParentId'] ? dataRef['ParentId'] : '',
        ItemId: dataRef && dataRef['Code'] ? dataRef['Code'] : '',
        InspectionId: formObjectId
      };

      let data = await getInspectionData(questionPayload);
      if (data && data[0]) {
        formObjectQuestions = data[0];
        this.setState({
          formObjectQuestions
        });
        if (formObjectQuestions) {
          this.QuestionRef.current.setFieldsValue(formObjectQuestions);
        }
      }
    } else if (status === false) {
      message.error('Please Select Item');
    }
  };
  setNewRecord = async () => {
    this.onReset();
    this.setState({
      formObject: {},
      newRecord: true
    });
  };
  render() {
    let {
      formObjectQuestions,
      viewQuestionModal,
      WorkPlanList,
      questionList,
      inspectionAreaList,
      inspectionItemList,
      viewAreaModal,
      viewItemModal,
      AnswerList,
      formObjectId,
      treeData,
      expandedKeys,
      statusList,
      formObject,
      usersList
      // newRecord
    } = this.state;
    console.log('renderrt', formObjectId && formObjectId !== '' ? false : true);
    let { translationCache } = this.props;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const newLocal = true;
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="InspectionMasters"
            theme={themeSettings}
            ref={this.InspectionMastersRef}
            className="collapse"
            onFinish={(data) => this.onFormsubmit(data, formObjectId)}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.InspectionId)}
                name="InspectionId"
                rules={[{ required: true }]}
              >
                <InputComponent
                  disabled={formObject && formObject.Id ? true : false}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Inspection Id')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.InspectionDesc)}
                name="Description"
                rules={[{ required: true }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Inspection Description')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.createdBy)}
                name="CreatedBy"
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(this.props.translationCache, constant.CreatedBy)}
                  disabled={true}
                >
                  {usersList &&
                    Array.isArray(usersList) &&
                    usersList.map((user, index) => {
                      return (
                        <Option key={index} value={user.Id}>
                          {user.FirstName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.createdDate)}
                style={{ width: '100%', flex: '50%' }}
                name="CreatedDate"
              >
                <DatePicker
                  placeholder={translation(translationCache, constant.createdDate)}
                  format="YYYY-MM-DD HH:mm:ss"
                  inputReadOnly={true}
                  style={{ width: '70%' }}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.status)}
                name="Status"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Status')}
                  style={{ width: '70%' }}
                >
                  {statusList &&
                    Array.isArray(statusList) &&
                    statusList.map((status, index) => {
                      return (
                        <Option key={index} value={status.Value}>
                          {' '}
                          {translation(translationCache, status.Key)}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div>
          </StyledForm>
          <div>
            <Tooltip
              color={formObjectId && formObjectId !== '' ? '#1a3652' : 'black'}
              title={translation(
                translationCache,
                formObjectId && formObjectId !== '' ? '' : constant.enabledAfterSubmit
              )}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div
                  style={{
                    borderRight: '1px dashed white',
                    width: '50%',
                    flex: '10%',
                    overflow: 'auto',
                    height: window.innerHeight - 250
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button
                      type="primary"
                      style={{ marginRight: '5px' }}
                      onClick={() => {
                        this.setNewRecord();
                      }}
                    >
                      <PlusOutlined />
                      {translation(translationCache, 'Add')}
                    </Button>
                  </div>

                  <StyledTree
                    onExpand={this.onExpand}
                    expandedKeys={expandedKeys}
                    style={{ width: '100%', flex: '50%', padding: '20px' }}
                    onSelect={this.onSelect}
                    showLine
                    defaultExpandAll={true}
                    switcherIcon={<DownOutlined />}
                  >
                    {this.renderTreeNodes(treeData)}
                  </StyledTree>
                </div>
                <div
                  style={{
                    width: '50%',
                    flex: '50%',
                    padding: '10px',
                    borderLeft: '3px dashed steelblue',
                    minHeight: window.innerHeight - 143,
                    opacity: formObjectId && formObjectId !== '' ? 1 : 0.75,
                    pointerEvents: formObjectId && formObjectId !== '' ? 'initial' : 'none'
                  }}
                >
                  <StyledForm
                    name="InspectionMastersTree"
                    theme={themeSettings}
                    ref={this.QuestionRef}
                    className="collapse"
                    onFinish={(data) => this.onInspecFormsubmit(data, formObjectId)}
                    layout="horizontal"
                    labelCol={{ flex: '250px' }}
                    labelAlign="right"
                    labelWrap
                    validateMessages={validateMessages}
                  >
                    <div
                      // // title="This is my tooltip"
                      // disabled
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Form.Item name="Equipment"></Form.Item>
                      <Form.Item
                        style={{
                          width: '100%',
                          flex: '100%'
                        }}
                        label={translation(translationCache, constant.equipment)}
                        name="DeviceCode"
                        rules={[{ required: true }]}
                      >
                        <InputComponent
                          disabled={true}
                          suffix={
                            <DownOutlined
                              style={{
                                color: '#1a3b5c'
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.getDeviceData('visible');
                              }}
                            />
                          }
                          style={{ width: '70%' }}
                          placeholder={translation(
                            translationCache,
                            `Select ${constant.equipment}`
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '100%', flex: '100%' }}
                        label={translation(translationCache, constant.inspectionArea)}
                        name="AreaId"
                        rules={[{ required: true }]}
                      >
                        <Select
                          style={{ width: '70%' }}
                          placeholder={translation(translationCache, 'Select Inspection Area')}
                          onSelect={(e) => this.onHandleChange(e, 'AreaId')}
                          showArrow={true}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: '8px 0'
                                }}
                              />
                              <Space
                                style={{
                                  padding: '0 8px 4px'
                                }}
                              >
                                <Button
                                  type="dashed"
                                  icon={<PlusOutlined />}
                                  onClick={() => this.setState({ viewAreaModal: true })}
                                >
                                  {translation(translationCache, constant.addArea)}
                                </Button>
                              </Space>
                            </>
                          )}
                        >
                          {inspectionAreaList &&
                            Array.isArray(inspectionAreaList) &&
                            inspectionAreaList.map((area, index) => {
                              return (
                                <Option key={index} value={area.Id}>
                                  {translation(translationCache, area.Area)}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        style={{ width: '100%', flex: '100%' }}
                        className="label"
                        label={translation(translationCache, constant.inspectionItems)}
                        name="ItemId"
                        rules={[{ required: true }]}
                      >
                        <Select
                          style={{ width: '70%' }}
                          placeholder={translation(translationCache, 'Select Inspection Items')}
                          onSelect={(e) => this.onHandleChange(e, 'ItemId')}
                          showArrow={true}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: '8px 0'
                                }}
                              />
                              <Space
                                style={{
                                  padding: '0 8px 4px'
                                }}
                              >
                                <Button
                                  type="dashed"
                                  icon={<PlusOutlined />}
                                  onClick={() => this.setState({ viewItemModal: true })}
                                >
                                  {translation(translationCache, constant.addItems)}
                                </Button>
                              </Space>
                            </>
                          )}
                        >
                          {inspectionItemList &&
                            Array.isArray(inspectionItemList) &&
                            inspectionItemList.map((device, index) => {
                              return (
                                <Option key={index} value={device.Id}>
                                  {translation(translationCache, device.Item)}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        style={{ width: '100%' }}
                        className="label"
                        label={translation(translationCache, constant.question)}
                        name="QuestionIds"
                        rules={[{ required: true }]}
                      >
                        <Select
                          style={{ width: '70%' }}
                          mode="multiple"
                          placeholder={translation(translationCache, 'Select Question')}
                          showArrow={true}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: '8px 0'
                                }}
                              />
                              <Space
                                style={{
                                  padding: '0 8px 4px'
                                }}
                              >
                                <Button
                                  type="dashed"
                                  icon={<PlusOutlined />}
                                  onClick={() => this.adQuestionForm()}
                                >
                                  {translation(translationCache, 'Add Question')}
                                </Button>
                              </Space>
                            </>
                          )}
                        >
                          {questionList &&
                            Array.isArray(questionList) &&
                            questionList.map((question, index) => {
                              return (
                                <Option key={index} value={question.Id}>
                                  {' '}
                                  {translation(translationCache, question.Question)}{' '}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item name="Id"></Form.Item>

                      <StyledModal
                        title={translation(translationCache, 'Add Item')}
                        open={viewItemModal}
                        okText={'Save'}
                        onOk={(e) => this.onFormItemSubmit(e)}
                        onCancel={this.onItemClose}
                      >
                        <Form
                          layout="vertical"
                          ref={this.ItemRef}
                          name="user_form"
                          onFinish={() => this.onFormItemSubmit()}
                        >
                          <Form.Item
                            label={translation(translationCache, 'Item')}
                            name={'Item'}
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <InputComponent
                              placeholder={translation(translationCache, 'Please enter Item')}
                            />
                          </Form.Item>
                        </Form>
                      </StyledModal>
                      <StyledModal
                        open={this.state.visible}
                        width={800}
                        closable
                        title={translation(translationCache, constant.selectEquipment)}
                        onOk={this.onModalOk}
                        onCancel={this.onDrawerCancel}
                      >
                        <TableSelect
                          data={this.state.tableData}
                          translationCache={this.props.translationCache}
                          key="Equipment"
                          columns={[
                            {
                              title: translation(translationCache, 'Equipment'),
                              dataIndex: 'DeviceCode',
                              key: 'DeviceCode',
                              ...this.getColumnSearchProps('DeviceCode')
                            },
                            {
                              title: translation(translationCache, 'Equipment Description'),
                              dataIndex: 'Name',
                              key: 'Name',
                              ...this.getColumnSearchProps('Name')
                            }
                          ]}
                          handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                        />
                      </StyledModal>
                      <StyledModal
                        title={translation(translationCache, constant.addArea)}
                        open={viewAreaModal}
                        okText={'Save'}
                        onOk={(e) => this.onFormAreaSubmit(e)}
                        onCancel={this.onAreaClose}
                      >
                        <Form
                          layout="vertical"
                          ref={this.areaRef}
                          name="user_form"
                          onFinish={() => this.onFormAreaSubmit()}
                        >
                          <Form.Item
                            label={translation(translationCache, constant.area)}
                            name={'Area'}
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <InputComponent
                              placeholder={translation(translationCache, 'Enter Area')}
                            />
                          </Form.Item>
                        </Form>
                      </StyledModal>
                      <StyledModal
                        title={translation(translationCache, 'Add Question')}
                        open={viewQuestionModal}
                        okText={'Save'}
                        width={700}
                        onOk={(e) => this.onModalFormsubmit(e)}
                        onCancel={this.onQuestionsClose}
                      >
                        <StyledForm
                          theme={themeSettings}
                          ref={this.deviceRef}
                          name="user_form"
                          layout="vertical"
                          labelAlign="right"
                          labelWrap
                        >
                          <Form.Item
                            label={translation(translationCache, 'Add Question')}
                            name={'Question'}
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <InputComponent
                              onChange={(e) => this.onanswerchange(e)}
                              placeholder={translation(translationCache, 'Add Question')}
                            />
                          </Form.Item>
                          <Form.List name="Answers">
                            {(Answers, { add }) => (
                              <>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    disabled={AnswerList && AnswerList.length === 0 ? true : false}
                                    onClick={() => {
                                      add();
                                    }}
                                    icon={<PlusOutlined />}
                                  >
                                    {' '}
                                    {translation(translationCache, 'Add Answers')}{' '}
                                  </Button>
                                </Form.Item>
                                {Answers.map(({ key, name, ...restField }) => (
                                  <Row key={key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={12}>
                                      <Form.Item
                                        {...restField}
                                        label={translation(translationCache, 'Answer')}
                                        name={[name, 'Option']}
                                        rules={[{ required: true }]}
                                      >
                                        <Select
                                          value={
                                            formObjectQuestions && formObjectQuestions.Answers
                                              ? formObjectQuestions.Answers
                                              : []
                                          }
                                          placeholder={translation(
                                            translationCache,
                                            'Select Answer'
                                          )}
                                          onChange={(e) => this.onanswerchange(e)}
                                          showArrow={true}
                                          style={{ opacity: newLocal ? 1 : 1 }}
                                        >
                                          {AnswerList &&
                                            Array.isArray(AnswerList) &&
                                            AnswerList.map((answers, index) => {
                                              return (
                                                <Option
                                                  key={index}
                                                  value={answers.Id}
                                                  disabled={
                                                    this.deviceRef.current &&
                                                    this.deviceRef.current.getFieldsValue() &&
                                                    this.deviceRef.current.getFieldsValue()[
                                                      'Answers'
                                                    ] &&
                                                    this.deviceRef.current.getFieldsValue()[
                                                      'Answers'
                                                    ][index] &&
                                                    this.deviceRef.current.getFieldsValue()[
                                                      'Answers'
                                                    ][index]['Option'] === answers.Id
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  {translation(
                                                    this.props.translationCache,
                                                    answers.Answer
                                                  )}
                                                </Option>
                                              );
                                            })}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                      <Form.Item
                                        {...restField}
                                        label={translation(translationCache, 'Select WorkPlan')}
                                        name={[name, 'Actions']}
                                        rules={[{ required: false }]}
                                      >
                                        <Select
                                          placeholder={translation(
                                            translationCache,
                                            'Select WorkPlan'
                                          )}
                                          mode="multiple"
                                          showArrow={true}
                                        >
                                          {WorkPlanList &&
                                            Array.isArray(WorkPlanList) &&
                                            WorkPlanList.map((answers, index) => {
                                              return (
                                                <Option key={index} value={answers.Code}>
                                                  {translation(translationCache, answers.Name)}
                                                </Option>
                                              );
                                            })}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </StyledForm>
                      </StyledModal>
                    </div>
                    <Form.Item
                      disabled={formObjectId && formObjectId !== '' ? false : true}
                      //  label={() => <div style={{ color: '#1a3652 !important' }}>Action</div>}
                      style={{
                        color: '#1a3652 !important',
                        marginLeft: '250px'
                        // cursor: formObjectId && formObjectId !== '' ? null : 'not-allowed',
                        // opacity: formObjectId && formObjectId !== '' ? null : '0.8',
                      }}
                    >
                      {formObjectQuestions && formObjectQuestions.Id ? (
                        <StyledButton theme={themeSettings} htmlType="submit">
                          {translation(translationCache, 'Update')}
                        </StyledButton>
                      ) : (
                        <StyledButton theme={themeSettings} htmlType="submit">
                          {translation(translationCache, 'Save')}
                        </StyledButton>
                      )}
                    </Form.Item>
                  </StyledForm>
                </div>
              </div>
            </Tooltip>
          </div>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(QuestionMasterForm);
