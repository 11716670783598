import axios from 'axios';
import localStorage from '../../../../utils/localStorage';

export async function _getMenuList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let orgHeaders = {
    method: 'GET',
    url: `/api/menu/get?SiteId=${siteId}&Type=Structured`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(orgHeaders)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
