import React, { Fragment } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ImageViewer from 'react-simple-image-viewer';

import { getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
// import { arrayOfObjects } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';
import Truck from '../../../../../images/rubus_new/Truck.jpg';
import '../../CSS/style.css';

class ImageGalleryWithPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      images: [],
      imagesList: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);
    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  imageData = (imagesList) => {
    let images = [];
    imagesList &&
      Array.isArray(imagesList) &&
      imagesList.length > 0 &&
      imagesList.map((src) => {
        images.push(src.Value);
        return {};
      });
    this.setState({
      images: images
    });
  };

  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      isViewerOpen: true
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      isViewerOpen: false

      // percent:50
    });
  };
  getData = async () => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = [
        {
          imageUrl: Truck
        },
        {
          imageUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeZeDQSmW4Nt9OH-zV4XSxOoS1GPPzD3wkAw&usqp=CAU'
        },
        {
          imageUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeZeDQSmW4Nt9OH-zV4XSxOoS1GPPzD3wkAw&usqp=CAU'
        },
        {
          imageUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF1xnk8-XJgK2xFFRRNJhXfjXt5qDK1ab9FM8yv-Sx4CRszfP1ra-5OA7UW_UHWF2mQlU&usqp=CAU'
        }
      ];
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else if (graphprops) {
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (json) {
        this.imageData(json);
        let currentImage = json[0].imageUrl;
        this.setState({
          imagesList: json,
          currentImage,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    let {
      isViewerOpen,
      currentImage,
      images,
      imagesList,
      refreshDateTime,
      errorInAPI,
      noDataInDB
    } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <div style={{ width: '100%' }}>
            <div style={{ width: '40%', float: 'left', margin: '5px 5px' }}>
              {imagesList &&
                Array.isArray(imagesList) &&
                imagesList.length > 0 &&
                imagesList.map((src, index) => (
                  <img
                    src={src.imageUrl}
                    onClick={() => {
                      this.openImageViewer(src.imageUrl, src.videoUrl);
                    }}
                    width="50%"
                    height="150"
                    key={index}
                    style={{ margin: '5px 0px', padding: '2px' }}
                    alt=""
                  />
                ))}
              {isViewerOpen && (
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  onClose={this.closeImageViewer}
                />
              )}
            </div>

            <div style={{ float: 'left', width: '58%', marginTop: '10px' }}>
              <img src={currentImage} width="90%" height="300px" alt="" />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(ImageGalleryWithPreview);
