import React, { Component } from 'react';
import moment from 'moment';
import { Table, Select, Button } from 'antd';

import TableViewInTable from './TableViewInTable';

import '../CSS/eLog.css';
import styles from '../CSS/style.less';
import { lightTheme } from '../CSS/style.js';

const { Option } = Select;

class TableInsideTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns:
        this.props.form && this.props.form.columns
          ? this.generateColumns(this.props.form.columns)
          : [],
      data:
        props.form && props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : [],
      mainHeaderValue: props.mainHeaderValue ? props.mainHeaderValue : {},
      subHeaderValue: props.subHeaderValue ? props.subHeaderValue : {},
      tableInTableStatus: false,
      open: false,
      yearSelection: false
    };
  }

  componentDidMount() {
    this.setState({
      form: this.props.form,
      columns:
        this.props.form && this.props.form.columns
          ? this.generateColumns(this.props.form.columns)
          : [],
      data:
        this.props.form && this.props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      this.setState({
        form: this.props.form,
        columns:
          this.props.form && this.props.form.columns
            ? this.generateColumns(this.props.form.columns)
            : [],
        data:
          this.props.form && this.props.form.datasource
            ? this.props.form.datasource.map((dataSource, index) => {
                return {
                  ...dataSource,
                  key: index + 1
                };
              })
            : []
      });
    }
  }

  generateColumns = (columns) => {
    let { translationCache } = this.props;
    let finalColumns = [];
    if (columns && columns.length > 0) {
      finalColumns = columns.map((column) => {
        return {
          ...column,
          title:
            translationCache && translationCache[column.title] && translationCache[column.title]
              ? translationCache[column.title]
              : column.title
        };
      });
    }
    return finalColumns;
  };

  addRow = () => {
    let { data } = this.state;
    let { form } = this.props;
    let dataLength;
    dataLength = data.length + 1;
    let editObj = {
      key: dataLength,
      table: form.defaultTableInTable ? form.defaultTableInTable : {}
    };
    data.push(editObj);
    this.setState({
      tableInTableStatus: true,
      editObj,
      data,
      yearSelection: false
    });

    form = {
      ...form,
      datasource: [...(form['datasource'] ? form['datasource'] : []), editObj]
    };
    this.props.saveTableViewData(form);
  };

  tableRowClick = (editObj) => {
    this.setState({
      tableInTableStatus: true,
      editObj,
      yearSelection: true
    });
  };
  closeTable = () => {
    this.setState({
      tableInTableStatus: false,
      editObj: {}
    });
  };

  _getJudgementData = (maxValue, datasource) => {
    switch (true) {
      case maxValue <= `${datasource['Excellent']}`: {
        return 'Excellent';
      }
      case maxValue <= `${datasource['Good']}`: {
        return 'Good';
      }
      case maxValue <= `${datasource['Possible']}`: {
        return 'Possible';
      }
      case maxValue >= `${datasource['Impossible']}`: {
        return 'Impossible';
      }
      default:
        return '';
    }
  };

  saveTableViewData = async (tableIn, value, columnData) => {
    let { form, formObject } = this.props;
    let { editObj } = this.state;
    let table = {};
    let dataIndexKey = editObj && editObj.key ? editObj.key - 1 : 0;
    if (
      columnData &&
      columnData.autopopulate &&
      columnData.autopopulate === 'MAX' &&
      value &&
      Array.isArray(value)
    ) {
      let maxList = [];
      maxList = value.map((maxObj) => {
        return maxObj.Max;
      });
      let currentMaxValueInTable = Math.max(...maxList.filter((e) => e !== undefined));

      let datasourceObject =
        formObject && formObject.form && formObject.form.datasource
          ? formObject.form.datasource
          : {};
      let getJudgementSymbol = await this._getJudgementData(
        currentMaxValueInTable,
        datasourceObject
      );
      table = {
        ...(form['datasource'] &&
        form['datasource'][dataIndexKey] &&
        form['datasource'][dataIndexKey]['table']
          ? form['datasource'][dataIndexKey]['table']
          : {}),
        Judgement: {
          ...(form['datasource'] &&
          form['datasource'][dataIndexKey] &&
          form['datasource'][dataIndexKey]['table'] &&
          form['datasource'][dataIndexKey]['table']['Judgement']
            ? form['datasource'][dataIndexKey]['table']['Judgement']
            : {}),
          datasource: [
            {
              ...(form['datasource'] &&
              form['datasource'][dataIndexKey] &&
              form['datasource'][dataIndexKey]['table'] &&
              form['datasource'][dataIndexKey]['table']['Judgement'] &&
              form['datasource'][dataIndexKey]['table']['Judgement'][0]
                ? form['datasource'][dataIndexKey]['table']['Judgement'][0]
                : {}),
              Judgement: getJudgementSymbol
            }
          ]
        },
        [tableIn]: {
          ...(form['datasource'] &&
          form['datasource'][dataIndexKey] &&
          form['datasource'][dataIndexKey]['table'] &&
          form['datasource'][dataIndexKey]['table'][tableIn]
            ? form['datasource'][dataIndexKey]['table'][tableIn]
            : {}),
          datasource: value
        }
      };
    } else {
      table = {
        ...(form['datasource'] &&
        form['datasource'][dataIndexKey] &&
        form['datasource'][dataIndexKey]['table']
          ? form['datasource'][dataIndexKey]['table']
          : {}),
        [tableIn]: {
          ...(form['datasource'] &&
          form['datasource'][dataIndexKey] &&
          form['datasource'][dataIndexKey]['table'] &&
          form['datasource'][dataIndexKey]['table'][tableIn]
            ? form['datasource'][dataIndexKey]['table'][tableIn]
            : {}),
          datasource: value
        }
      };
    }

    if (form && form['datasource'] && form['datasource'][dataIndexKey]) {
      form['datasource'][dataIndexKey] = {
        ...form['datasource'][dataIndexKey],
        table
      };
    }
    this.setState({
      editObj: {
        ...editObj,
        table
      }
    });
    this.props.saveTableViewData(form);
  };

  yearSelect = (value) => {
    console.log('yearSelect', value);
    let { form, editObj } = this.state;
    let datasourceList = form['datasource'];
    datasourceList[editObj.key - 1] = {
      ...datasourceList[editObj.key - 1],
      Year: value
    };
    form = {
      ...form,
      datasource: datasourceList
    };
    this.setState({
      editObj: {
        ...editObj,
        Year: value
      }
    });
    this.props.saveTableViewData(form);
  };

  render() {
    let { loading, form, translationCache } = this.props;
    let { columns, data, tableInTableStatus, editObj, yearSelection } = this.state;
    console.log('yearSelection', yearSelection);
    if (moment().year() > 2019) {
      var yearList = [];
      for (var i = 2017; i <= moment().year(); i++) {
        yearList.push(i);
      }
      yearList.sort((a, b) => b - a);
    }
    let tableInTableProcess = editObj && editObj.table ? editObj.table : {};

    let yearEntriedAlready =
      data &&
      Array.isArray(data) &&
      data.map((YearData) => {
        return YearData.Year;
      });
    return (
      <div className="tableView" style={{ marginTop: '80px' }}>
        <div>
          <Table
            theme={lightTheme}
            className="TableView"
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            style={{ marginTop: '10px', border: '1px solid  red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
            onRow={(record) => ({
              onClick: (e) => {
                e.stopPropagation();
                this.tableRowClick(record);
              }
            })}
          />
          {form && form.showAddButton ? (
            <Button type="primary" style={{ marginTop: '10px' }} onClick={this.addRow}>
              {translationCache && translationCache['Add Row'] && translationCache['Add Row']
                ? translationCache['Add Row']
                : 'Add Row'}
            </Button>
          ) : null}
        </div>

        {tableInTableStatus && tableInTableStatus === true ? (
          <div style={{ padding: '15px', border: '3px solid white', marginTop: '20px' }}>
            {tableInTableStatus && tableInTableStatus === true ? (
              <div>
                <Button style={{ float: 'right' }} onClick={this.closeTable}>
                  {' '}
                  {translationCache && translationCache['Close']
                    ? translationCache['Close']
                    : 'Close'}
                </Button>

                <div style={{ marginTop: '10px' }}>
                  <label style={{ color: 'steelblue', fontWeight: 'bold', fontSize: '19px' }}>
                    {translationCache &&
                    translationCache['Select Year'] &&
                    translationCache['Select Year']
                      ? translationCache['Select Year']
                      : 'Select Year'}{' '}
                    :{' '}
                  </label>
                  <Select
                    value={editObj && editObj.Year ? editObj.Year : ''}
                    onChange={(e) => this.yearSelect(e)}
                    placeholder={'Select Year'}
                    style={{ width: '100px' }}
                    disabled={yearSelection && yearSelection !== '' ? yearSelection : ''}
                  >
                    {yearList.map((value, index) => {
                      return (
                        <Option
                          key={index}
                          disabled={yearEntriedAlready.includes(value) ? true : false}
                          value={value}
                        >
                          {value}
                        </Option>
                      );
                    })}
                  </Select>
                </div>

                {tableInTableProcess &&
                  Object.keys(tableInTableProcess) &&
                  Object.keys(tableInTableProcess).map((tableIn, index) => {
                    return (
                      <TableViewInTable
                        key={index}
                        form={tableInTableProcess[tableIn]}
                        // formObject={this.props.formObject}
                        // recordType={'FormSubSectionTable'}
                        saveTableViewData={(e, columnsData, value) => {
                          this.saveTableViewData(tableIn, e, columnsData, value);
                        }}
                        translationCache={this.props.translationCache}
                      />
                    );
                  })}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default TableInsideTables;
