import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import { message } from 'antd';
export async function SubmitForm(payload, url) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getUserList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/user/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getUsecaseList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/analytics/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getUserListData() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/user/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/analytics/getById?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e;
    });
}

export const UpdateUsecase = async (payload) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const header = {
    method: 'POST',
    url: `/api/analytics/update?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};

export async function deleteUsecase(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/analytics/delete?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { id }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getRelatedTags() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'appConfig."Site_Master_ParametersList"',
      ColumnName: 'Code'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function deleteAttachment(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/analytics/deleteAttachment`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { Id: id }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getStatusList() {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'analytics."Site_Master_TaskStatus"',
      ColumnName: 'Name'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export const getSitesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/site/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};
