import React from 'react';
import { Divider, Radio, Select, message } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTranslationCache } from '../.././../selectors/language';
import { setTimeZone } from '../../../modules/layout/layout.duck';
import { constant } from '../Constants';

import styles from '../../../commons/styles';
import { themes } from './mock';
import { StyledTheme } from './style';
import { getCurrentTimezone } from '../../../selectors/layout';

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight, StyledHeading } = styles.layout;

const RadioGroup = Radio.Group;

class Preferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: this.props.selectedTheme,
      radioThemeValue: this.props.selectedThemeing,
      selectedLangauge: this.props.selectedLangauge,
      translationCache: props.translationCache || [],
      currentTimeZone: this.props.currentTimeZone
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.currentTimeZone !== this.props.currentTimeZone
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        currentTimeZone: this.props.currentTimeZone
      });
    }
  }

  onNaviagionModeChange = (mode) => {
    this.props.actions.setNavigationMode(mode);
  };

  render() {
    const { translationCache } = this.state;
    console.log('currentTimeZone');
    return (
      <div>
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>
              {translationCache && translationCache[`${constant.Navigation_theme}`]
                ? translationCache[`${constant.Navigation_theme}`]
                : `${constant.Navigation_theme}`}
            </StyledHeading>
            <p>
              {translationCache &&
              translationCache[`${constant.Navigation_Desc}`] &&
              translationCache[`${constant.Navigation_Desc}`]
                ? translationCache[`${constant.Navigation_Desc}`]
                : `${constant.Navigation_Desc}`}
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <RadioGroup onChange={this.onRadioChange} value={this.state.radioValue}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {themes.map((key) => {
                  return (
                    <>
                      <div style={{ marginLeft: '30px' }}>
                        <StyledTheme
                          style={{ backgroundColor: `${key.backgroundColor}` }}
                        ></StyledTheme>
                        <Radio
                          style={{ margin: 20, alignContent: 'left' }}
                          margin
                          value={key.color}
                        >
                          {key.color}
                        </Radio>
                      </div>
                    </>
                  );
                })}
              </div>
            </RadioGroup>
          </StyledWrapperRight>
        </StyledWrapper>
        <Divider />
        <StyledWrapper>
          <StyledWrapperLeft>
            this.props.currentTimeZone
            <StyledHeading>View Data in Different Time Zones </StyledHeading>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <Select
              showSearch
              placeholder="Select Zones"
              optionFilterProp="children"
              value={this.props.currentTimeZone}
              onChange={(value) => {
                this.props.actions.setTimeZone(value);
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Asia/Kolkata',
                  label: 'Asia/Kolkata'
                },
                {
                  value: 'Asia/Tokyo',
                  label: 'Asia/Tokyo'
                }
              ]}
            />
          </StyledWrapperRight>
        </StyledWrapper>

        {/* <Divider />  */}

        {/* <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>{translationCache &&
                translationCache[`${constant.background_theme}`] &&
                translationCache[`${constant.background_theme}`] ?
                translationCache[`${constant.background_theme}`] :
                `${constant.background_theme}`}</StyledHeading>
            <p>
            {translationCache &&
                translationCache[`${constant.Theme_Desc}`] &&
                translationCache[`${constant.Theme_Desc}`] ?
                translationCache[`${constant.Theme_Desc}`] :
                `${constant.Theme_Desc}`}
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <RadioGroup
              onChange={this.onRadioThemeChange}
              value={this.state.radioThemeValue}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {theme.map((key) => {
                  return (
                   <>
                    <div style={{ marginLeft: "30px" }}>
                      <StyledTheme
                        style={{ backgroundColor: `${key.backgroundColor}` }}
                      ></StyledTheme>
                      <Radio
                        style={{ margin: 20, alignContent: "left" }}
                        margin
                        value={key.color}
                      >
                        {key.color}
                      </Radio>
                    </div>
                   </>
                  );
                })}
              </div>
            </RadioGroup>
          </StyledWrapperRight>
        </StyledWrapper>
         <Divider />
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>Navigation Mode</StyledHeading>
            <p>This settings allow you to customize the menu position</p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <div style={{ display: "flex", flexDirection: "row",justifyContent:'flex-start' }}>
                <div style={{textAlign:"center"}}>
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/XwFOFbLkSM/LCkqqYNmvBEbokSDscrm.svg"
                  style={{ cursor: "pointer", marginRight: "20px" }}
                  alt=""
                  onClick={() => this.onNaviagionModeChange("side")}
                />
             <h1> <span style={{fontSize:"15px"}}>Side Navigation</span></h1>
                </div>
                <div style={{marginLeft:"20px",textAlign:"center"}}>
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/URETY8%24STp/KDNDBbriJhLwuqMoxcAr.svg"
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => this.onNaviagionModeChange("top")}
                />
                <h1> <span style={{fontSize:"15px"}}>Top Navigation</span></h1>
                </div>
            </div>
          </StyledWrapperRight>
        </StyledWrapper>
        <Divider /> */}
        {/* <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>Behavior</StyledHeading>
            <p>
              This setting allows you to customize the behavior of the system
              layout and default views.
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <Form onSubmit={this.handleSubmit}>
              <b>Layout width</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="Fixed">
                  {data.map((option) => (
                    <Option key={option.value}>{option.label}</Option>
                  ))}
                </StyledSelect>
                <p>
                  Choose between fixed (max. 1200px) and fluid (100%)
                  application layout.
                </p>
              </FormItem>
              <b>Default dashboard</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="default">
                  {dashboard.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </StyledSelect>
              </FormItem>
              <b>Project overview content</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="default">
                  {dashboard.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </StyledSelect>
              </FormItem>
              <FormItem>
                <PrimaryButton
                  style={{ width: "30%" }}
                  size="default"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Save Changes
                </PrimaryButton>
              </FormItem>
            </Form>
          </StyledWrapperRight>
        </StyledWrapper> */}
      </div>
    );
  }
  handleChange(value) {
    this.props.actions.changeLanguage(value);
  }
  onRadioChange = (e) => {
    this.props.actions.changeTheme(e.target.value);

    message.loading('Loading theme..', 1.0);
    // .then(() => message.success('Loading theme', 1.5))
    this.setState({
      radioValue: e.target.value
    });
  };

  onRadioThemeChange = (e) => {
    this.props.actions.changeThemeing(e.target.value);
    this.setState({
      radioThemeValue: e.target.value
    });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setTimeZone
      },
      dispatch
    )
  };
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
