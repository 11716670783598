import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { pieSingleMockJson } from '../../../Mock/MockJson';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';

class PieSingleLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      titleText: '',
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.pieName = '';
  }

  data = [];
  timeTicket = null;
  abortController = new window.AbortController();
  requestCheck = true;
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  onChartClick = (cellData) => {
    // if (cellData.componentIndex === 2) {
    //   this.pieName = cellData.name;
    //   this.getData(this.props);
    // }
    if (cellData.componentSubType === 'pie') {
      this.pieName = cellData.name;
      this.getData(this.props);
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            onEvents={onEvents}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getData = async (props, theme) => {
    let json = [];
    this.requestCheck = false;
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = pieSingleMockJson;

      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else if (graphprops) {
        json = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          '',
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let lineObject = [];
      // var line = json && json.query ? json.query : [];
      let pie = json;
      if (this.pieName) {
        // lineObject = line[this.pieName];
        let lineData =
          json &&
          json.filter((jsonData) => {
            return jsonData.Description === this.pieName;
          });
        lineObject = lineData && Array.isArray(lineObject) && lineData[0] ? lineData[0].Action : [];

        this.setState({ titleText: this.pieName });
      } else if (pie) {
        lineObject = pie && pie[0] && pie[0].Action ? pie[0].Action : [];
      }

      let yAxis = [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            interval: 0,
            rotate: 30
          }
        }
      ];
      let xAxis = [
        {
          type: 'category',
          data:
            lineObject &&
            Array.isArray(lineObject) &&
            lineObject.length > 0 &&
            lineObject.map(function (item) {
              return item.DateTime;
            }),
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      ];
      let toolBox = {
        showTitle: false,
        orient: 'vertical',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      };
      let tooltip = {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
        textStyle: {
          fontSize: 15
        }
      };

      let dataZoom = [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          backgroundColor: '#9a9191',

          textStyle: {
            color: 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ];
      let series = [
        {
          type: 'line',
          stack: 'chart',
          z: 3,
          itemStyle: {
            normal: {
              color: this.props.graphprops.lineColor ? this.props.graphprops.lineColor : 'steelblue'
            }
          },

          data:
            lineObject &&
            Array.isArray(lineObject) &&
            lineObject.length > 0 &&
            lineObject.map(function (item) {
              return item.Value;
            })
        },
        {
          type: 'line',
          silent: true,
          itemStyle: {
            normal: {
              color: this.props.graphprops.lineColor ? this.props.graphprops.lineColor : 'red'
            }
          },
          data:
            lineObject &&
            Array.isArray(lineObject) &&
            lineObject.length > 0 &&
            lineObject.map(function (item) {
              return item.Value;
            })
        },

        {
          type: 'pie',
          radius: '30%',
          center: ['50%', '25%'],
          data:
            pie &&
            Array.isArray(pie) &&
            pie.map(function (pieData) {
              return {
                name: pieData.Description,
                value: pieData.Value
              };
            })
        }
      ];

      series = await trendLabelTranslation(series, this.props.translationCache);
      const option = cloneDeep(this.getOption());
      if (
        this.props.graphprops.colors !== null &&
        this.props.graphprops.colors !== undefined &&
        this.props.graphprops.colors.length !== 0
      ) {
        option.color = [...this.props.graphprops.colors];
      }
      option.series = series;
      option.yAxis = yAxis;
      option.xAxis = xAxis;
      option.dataZoom = dataZoom;
      option.toolbox = toolBox;
      option.tooltip = tooltip;
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      label: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 14
      },
      textStyle: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 18
      },
      legend: {
        left: 'center',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              // color: "none",
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },

      grid: { top: '55%' },
      xAxis: [
        {
          type: 'value',

          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(PieSingleLine);
