import React, { Component } from 'react';
import { Button, Popconfirm, Input, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { getMasterList, deleteMaster, getUsersList } from '../../../Utils/FormCalls';
import { encode as base64_encode } from 'base-64';
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import history from '../../../../../commons/history';
import { connect } from 'react-redux';
import { constant } from '../../../Utils/constants';
import { createStructuredSelector } from 'reselect';

import translation from '../../../Utils/translation';
import { getTranslationCache } from '../../../../../selectors/language';
import themeSettings from '../../../Utils/themeSettings.json';
import ListView from '../../../Utils/ListView';
import { StyledComponent } from '../../../CSS/style';
const urlList = {
  list: '/api/masterItems/list',
  delete: '/api/masterItems/delete'
};

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      SpecificationData: [],
      activeKey: '1',
      form: {},
      translationCache: props.translationCache || [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['Item', 'Description'],

        firstTitle: 'Item',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/ItemDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.match.params.Masters !== prevprops.match.params.Masters) {
      let mastername = this.props.match.params.Masters;
      this._getAdhocDataSource();
      this.setState({ mastername });
    }
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  _getAdhocDataSource = async () => {
    let dataSource = await getMasterList(urlList.list);
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };

  downTimeData = (form) => {
    this.setState({
      form
    });
  };
  _deleteById = async (id) => {
    // let payload = {
    //   url: `/api/masterTask/delete?Id=${id}`,
    //   method: 'POST'
    // };
    await deleteMaster(urlList.delete, id);
    //await deleteById(payload);
    let dataSource = await getMasterList(urlList.list);
    this.setState({
      dataSource
    });
  };
  // deleteItemMaster = async (record) => {
  //   await deleteMaster(urlList.delete, record.Id);
  //   let dataSource = await getMasterList(urlList.list);
  //   this.setState({
  //     dataSource,
  //     activeKey: '1'
  //     // form: {}
  //   });
  // };
  // changeStatus = async (id) => {
  //     await changeWorkOrderStatus(id)
  //     let dataSource = await getEmergencyWorkOrdersList()
  //     this.setState({
  //         dataSource,
  //         activeKey: "1",
  //         // form: {}
  //     });

  // }

  editAssetMaster = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.item),
        dataIndex: 'Item',
        key: 'Item',
        filter: true,
        ...this.getColumnSearchProps('Item')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(translationCache, constant.storeLocation),
        dataIndex: 'StoreLocation',
        key: 'StoreLocation',
        filter: true
      },
      // {
      //     title: "Status",
      //     dataIndex: "Status",
      //     key: "Status",
      //     render: (text, record) => (
      //         <>
      //             <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : "Active"}`}>
      //                 <span onClick={() => this.changeStatus(record.Id)} >
      //                     <Tag color={text && text === 'Active' ? '#87d068' :
      //                         text === 'Inactive' ? "#ff4d4f" : "gold"} key={text}>
      //                         {text}
      //                     </Tag>
      //                 </span>
      //             </Tooltip>

      //         </>
      //     )
      // },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this.editAssetMaster(record)} size="middle">
              {' '}
              <Tooltip title={translation(translationCache, 'Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(translationCache, constant.deleteItemMaster)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={translation(translationCache, 'Delete')}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, constant.ITEM_MASTER),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['Item', 'Description'],

      firstTitle: 'Item',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/ItemDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ItemList);
