export const defaultGraphTypeProps = {
  area: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Line Type',
      Widget: 'Input',
      Key: 'linetype'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    }
  ],
  report: [
    {
      DisplayName: 'Report Name',
      Widget: 'Input',
      Key: 'ReportName'
    }
  ],
  guage: [
    {
      DisplayName: 'Title Color',
      Widget: 'ColorList',
      Key: 'titleColor'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Unit of measure',
      Widget: 'Input',
      Key: 'uom'
    },
    {
      DisplayName: 'Type of guage',
      Widget: 'Select',
      Key: 'guageType',
      Options: ['normalGuage', 'speedGuage']
    },
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    },
    {
      DisplayName: 'SplitNumber',
      Widget: 'Input',
      Key: 'SplitNumber'
    }
  ],
  GridGuage: [
    {
      DisplayName: 'Unit of measure',
      Widget: 'Input',
      Key: 'uom'
    },
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    },
    {
      DisplayName: 'SplitNumber',
      Widget: 'Input',
      Key: 'SplitNumber'
    }
  ],
  bar: [
    {
      DisplayName: 'Tags',
      Widget: 'TagList',
      Key: 'tags'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Show MinMax ',
      Widget: 'Select',
      Key: 'minMax',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'xAxisLabel',
      Widget: 'Select',
      Key: 'xAxisLabel',
      Options: ['string', 'timeStamp']
    },
    {
      DisplayName: 'xAxisDateFormat',
      Widget: 'Input',
      Key: 'xAxisDateFormat'
    },
    {
      DisplayName: 'Interval',
      Widget: 'Number',
      Key: 'Interval'
    },
    {
      DisplayName: 'Linecolor',
      Widget: 'ColorList',
      Key: 'Linecolor'
    },
    {
      DisplayName: 'Show Value on Bar',
      Widget: 'Select',
      Key: 'showLabel',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'Show YAxis Description',
      Widget: 'Input',
      Key: 'showDescInYAxis'
    },
    {
      DisplayName: 'Show Stack',
      Widget: 'Select',
      Key: 'showStack',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'View Type',
      Widget: 'Select',
      Key: 'viewType',
      Options: ['horizontal', 'vertical']
    }
  ],

  counter: [
    {
      DisplayName: 'Counter Type',
      Widget: 'Select',
      Key: 'counterType',
      Options: ['number', 'string', 'both', 'boolean', 'date-picker']
    },
    {
      DisplayName: 'Font Color',
      Widget: 'Input',
      Key: 'titleColor'
    },
    {
      DisplayName: 'Font Size',
      Widget: 'Input',
      Key: 'fontSize'
    },
    {
      DisplayName: 'Display Type',
      Widget: 'Select',
      Key: 'displayType',
      Options: ['Image & Value', 'Value']
    },
    {
      DisplayName: 'Image URL',
      Widget: 'Input',
      Key: 'imageURL'
    }
  ],
  statusCounter: [
    {
      DisplayName: 'FontSize',
      Widget: 'Input',
      Key: 'fontSize'
    },
    {
      DisplayName: 'IconColor',
      Widget: 'ColorList',
      Key: 'iconColor'
    },
    {
      DisplayName: 'TitleColor',
      Widget: 'Input',
      Key: 'titleColor'
    },
    {
      DisplayName: 'BackgroundColor',
      Widget: 'Input',
      Key: 'backgroundColor'
    },
    {
      DisplayName: 'Unit of measure',
      Widget: 'Input',
      Key: 'uom'
    },
    {
      DisplayName: 'Status Counter Type',
      Widget: 'Select',
      Key: 'statusCounterType',
      Options: ['number', 'string', 'both', 'notification', 'Image & string']
    },
    {
      DisplayName: 'Status Counter Condition',
      Widget: 'Select',
      Key: 'statusCounterCondition',
      Options: ['true', 'false']
    }
  ],
  progressBar: [
    {
      DisplayName: 'Bar Type',
      Widget: 'Select',
      Key: 'barType',
      Options: ['circle', 'line', 'dashboard']
    }
  ],
  singleCandleStick: [
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    },
    {
      DisplayName: 'Title color',
      Widget: 'ColorList',
      Key: 'titleColor'
    }
  ],
  singleBar: [
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    },
    {
      DisplayName: 'Title color',
      Widget: 'ColorList',
      Key: 'titleColor'
    }
  ],
  sliderStatus: [
    {
      DisplayName: 'Tags',
      Widget: 'TagList',
      Key: 'tags'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Linecolor',
      Widget: 'ColorList',
      Key: 'linecolor'
    }
  ],
  GuageLine: [
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'TitleColor',
      Widget: 'Input',
      Key: 'titleColor'
    },
    {
      DisplayName: 'Linecolor',
      Widget: 'Input',
      Key: 'linecolor'
    },
    {
      DisplayName: 'Unit of measure',
      Widget: 'Input',
      Key: 'uom'
    }
  ],
  cardsList: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'View Type',
      Widget: 'Select',
      Key: 'viewType',
      Options: ['listOfItems', 'listOfCards']
    },
    {
      DisplayName: 'NumberOfColumns',
      Widget: 'Input',
      Key: 'numberOfColumns'
    },
    {
      DisplayName: 'Display Type',
      Widget: 'Select',
      Key: 'displayType',
      Options: ['Value', 'Image']
    },
    {
      DisplayName: 'Show Last 30mins',
      Widget: 'Select',
      Key: 'show30minsData',
      Options: ['true', 'false']
    }
  ],

  notificationTable: [
    {
      DisplayName: 'Activate Lights',
      Widget: 'Input',
      Key: 'activateLights'
    },
    {
      DisplayName: 'DeActivate Lights',
      Widget: 'Input',
      Key: 'deActivateLights'
    }
  ],
  scatter: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    }
  ],
  pie: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Display Type',
      Widget: 'Select',
      Key: 'ResponseType',
      Options: ['Show Value', 'Show Percentage']
    }
  ],
  pieRose: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Display Type',
      Widget: 'Select',
      Key: 'ResponseType',
      Options: ['Show Value', 'Show Percentage']
    }
  ],
  dottedBar: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    }
  ],
  trend: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Line Type',
      Widget: 'Input',
      Key: 'linetype'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Line Color',
      Widget: 'ColorList',
      Key: 'Linecolor'
    },
    {
      DisplayName: 'VisualMap',
      Widget: 'Input',
      Key: 'VisualMap'
    }
  ],
  donut: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Show Total',
      Widget: 'Select',
      Options: ['Yes', 'No'],
      Key: 'ShowTotal'
    },
    {
      DisplayName: 'Unit of measure',
      Widget: 'Input',
      Key: 'uom'
    }
  ],
  pieSingleLine: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Line Color',
      Widget: 'ColorList',
      Key: 'Linecolor'
    }
  ],
  stackedBar: [
    {
      DisplayName: 'Tags',
      Widget: 'TagList',
      Key: 'tags'
    },
    {
      DisplayName: 'Table',
      Widget: 'Select',
      Key: 'table',
      Options: ['yes', 'no']
    },
    {
      DisplayName: 'Table Columns',
      Widget: 'TextArea',
      Key: 'columns'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Show MinMax ',
      Widget: 'Select',
      Key: 'minMax',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'xAxisLabel',
      Widget: 'Select',
      Key: 'xAxisLabel',
      Options: ['string', 'timeStamp']
    },
    {
      DisplayName: 'Linecolor',
      Widget: 'ColorList',
      Key: 'Linecolor'
    },
    {
      DisplayName: 'Show Lables on Bar',
      Widget: 'Select',
      Key: 'showLabel',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'Show Stack',
      Widget: 'Select',
      Key: 'showStack',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'View Type',
      Widget: 'Select',
      Key: 'viewType',
      Options: ['horizontal', 'vertical']
    }
  ],
  heatmapCalendar: [
    {
      DisplayName: 'Visual Map',
      Widget: 'TextArea',
      Key: 'VisualMap'
    }
  ],
  HeatmapCartesion: [
    {
      DisplayName: 'Heatmap Carteasian Type',
      Widget: 'Select',
      Key: 'cartesionDisplayType',
      Options: ['percentage', 'value', 'none']
    },
    {
      DisplayName: 'Visual Map',
      Widget: 'Input',
      Key: 'VisualMap'
    },
    {
      DisplayName: 'Range',
      Widget: 'Input',
      Key: 'range'
    }
  ],
  stackedLine: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'Colors'
    },
    {
      DisplayName: 'Show Min Max',
      Widget: 'Select',
      Key: 'minMax',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Table',
      Widget: 'Select',
      Key: 'table',
      Options: ['yes', 'no']
    },
    {
      DisplayName: 'Tags',
      Widget: 'TagList',
      Key: 'tags'
    }
  ],
  barTable: [
    {
      DisplayName: 'Table Columns',
      Widget: 'TextArea',
      Key: 'columns'
    },
    {
      DisplayName: 'Tags',
      Widget: 'TagList',
      Key: 'tags'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    }
  ],
  stackbar: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    }
  ],
  line: [
    {
      DisplayName: 'Display Type',
      Widget: 'Select',
      Key: 'displayType',
      Options: ['DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm', 'None']
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Linecolor',
      Widget: 'ColorList',
      Key: 'Linecolor'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Show MinMax ',
      Widget: 'Select',
      Key: 'minMax',
      Options: ['true', 'false']
    }
  ],
  image: [
    {
      DisplayName: 'HMI Name',
      Widget: 'Input',
      Key: 'path'
    },
    {
      DisplayName: 'HMI Type',
      Widget: 'Select',
      Key: 'Type',
      Options: ['static', 'dynamic', 'distinct']
    },
    {
      DisplayName: 'Device Code/Name',
      Widget: 'Input',
      Key: 'Device Code/Name'
    }
  ],
  liveStreaming: [
    {
      DisplayName: 'Path',
      Widget: 'Input',
      Key: 'path'
    }
  ],
  table: [
    {
      DisplayName: 'Table Size',
      Widget: 'Input',
      Key: 'tableSize'
    },
    {
      DisplayName: 'Columns',
      Widget: 'TextArea',
      Key: 'columns'
    }
  ],
  lineTable: [
    {
      DisplayName: 'Columns',
      Widget: 'TextArea',
      Key: 'columns'
    }
  ],
  DonutCounter: [
    {
      DisplayName: 'Columns',
      Widget: 'TextArea',
      Key: 'columns'
    },
    {
      DisplayName: 'Links',
      Widget: 'TextArea',
      Key: 'links'
    }
  ],
  LineBarArea: [
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Graph Type',
      Widget: 'TextArea',
      Key: 'GraphType'
    }
  ],
  windBarb: [
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Visual Map',
      Widget: 'TextArea',
      Key: 'VisualMap'
    }
  ],
  counterLine: [
    {
      DisplayName: 'Display Type',
      Widget: 'Input',
      Key: 'displayType'
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'Colors',
      Widget: 'ColorList',
      Key: 'colors'
    },
    {
      DisplayName: 'Counter Color',
      Widget: 'Input',
      Key: 'counterColor'
    },
    {
      DisplayName: 'Is Modal',
      Widget: 'Select',
      Key: 'isModal',
      Options: ['true', 'false']
    },
    {
      DisplayName: 'Font Size',
      Widget: 'Input',
      Key: 'fontSize'
    }
  ],
  beijingLine: [
    {
      DisplayName: 'Graph Type',
      Widget: 'Select',
      Key: 'beijingType',
      Options: ['line', 'bar']
    },
    {
      DisplayName: 'YAxis',
      Widget: 'TextArea',
      Key: 'YAxis'
    },
    {
      DisplayName: 'VisualMap',
      Widget: 'TextArea',
      Key: 'VisualMap'
    }
  ],
  Report: [
    {
      DisplayName: 'Report Name',
      Widget: 'Input',
      Key: 'ReportName'
    }
  ],
  Predictions: [
    {
      DisplayName: 'PredictionType',
      Widget: 'Select',
      Key: 'PredictionType',
      Options: ['arrayOfArray', 'arrayOfObject']
    }
  ]
};

export const graphTypeList = [
  {
    //Completed
    value: 'area',
    key: 'Area'
  },
  {
    //Completed
    value: 'bar',
    key: 'Bar'
  },
  {
    //Completed
    value: 'beijingLine',
    key: 'BeijingLine'
  },
  {
    //Completed
    value: 'candleStick',
    key: 'CandleStick'
  },
  {
    //Completed
    value: 'cardsList',
    key: 'CardsList'
  },
  {
    //Completed
    value: 'counter',
    key: 'Counter'
  },
  {
    //Completed
    value: 'counterLine',
    key: 'Counter Line'
  },
  {
    //Completed
    value: 'donut',
    key: 'Donut'
  },
  {
    //Completed
    value: 'funnel',
    key: 'Funnel'
  },
  {
    //Completed
    value: 'guage',
    key: 'Guage'
  },
  {
    //Completed
    value: 'GuageRing',
    key: 'GuageRing'
  },
  {
    //value is not displayed in heatmap
    value: 'heatmapCalendar',
    key: 'HeatmapCalendar'
  },
  {
    //Completed
    value: 'line',
    key: 'Line'
  },
  {
    // view not coming
    value: 'liquidGuage',
    key: 'LiquidGuage'
  },
  {
    //Completed
    value: 'pie',
    key: 'Pie'
  },
  {
    //Completed
    value: 'pieRose',
    key: 'PieRose'
  },
  {
    //Completed
    value: 'progressBar',
    key: 'ProgressBar'
  },
  {
    //Completed
    value: 'scatter',
    key: 'Scatter'
  },
  {
    //Completed
    value: 'singleBar',
    key: 'SingleBar'
  },
  {
    //Completed
    value: 'singleCandleStick',
    key: 'SingleCandleStick'
  },
  {
    // Issue combination of bar&Table yes,no
    value: 'stackedBar',
    key: 'StackedBar'
  },
  {
    //Completed
    value: 'statusCounter',
    key: 'StatusCounter'
  },

  {
    //Completed
    value: 'table',
    key: 'Table'
  },
  {
    //Completed
    value: 'windBarb',
    key: 'WindBarb'
  },

  /*ArrayOfObjectsWithAction */
  {
    // Completed
    value: 'dottedBar',
    key: 'DottedBar'
  },

  {
    // Completed
    value: 'lineTable',
    key: 'LineTable'
  },
  {
    // Completed
    value: 'pieSingleLine',
    key: 'PieSingleLine'
  },

  /*ObjectOfObject*/
  {
    //Completed
    value: 'barTable',
    key: 'BarTable'
  },
  {
    //Completed
    value: 'guageLine',
    key: 'GuageLine'
  },
  {
    //Completed
    value: 'pieLine',
    key: 'PieLine'
  },
  {
    //Completed
    value: 'pieNest',
    key: 'PieNestComponent'
  },

  {
    //unused
    value: 'boxPlot',
    key: 'BoxPlot'
  },
  {
    //unused
    value: 'bubbleChart',
    key: 'BubbleChart'
  },
  {
    //unused
    value: 'candleStickBrush',
    key: 'CandleStickBrush'
  },
  {
    //unused
    value: 'feedbackTable',
    key: 'FeedbackTable'
  },
  {
    //unused
    value: 'regression',
    key: 'Regression'
  },

  {
    value: 'image',
    key: 'Single Line Diagram(HMI)'
  },
  {
    //Completed
    value: 'pieBar',
    key: 'PieBar'
  },
  {
    //unused
    value: 'treeGraph',
    key: 'TreeGraph'
  },
  {
    //Completed
    value: 'HeatmapCartesion',
    key: 'HeatmapCartesion'
  },

  {
    //Completed
    value: 'cardsList',
    key: 'CardsList'
  },
  {
    //Completed
    value: 'stackedLine',
    key: 'StackedLine'
  },
  {
    value: 'ImageGalleryWithPreview',
    key: 'ImageGalleryWithPreview'
  },
  {
    value: 'liveStreaming',
    key: 'LiveStreaming'
  },
  {
    value: 'notificationTable',
    key: 'NotificationTable'
  },
  {
    value: 'selectImageDetails',
    key: 'SelectImageDetails'
  },
  {
    value: 'sliderStatus',
    key: 'SliderStatus'
  },
  {
    value: 'cards',
    key: 'BarwithLine'
  },
  {
    value: 'notificationScatter',
    key: 'NotificationScatter'
  },
  {
    value: 'LineBarArea',
    key: 'Line Bar Area'
  },
  {
    value: 'GeoMap',
    key: 'Geo Map'
  },
  {
    value: 'SunBurst',
    key: 'Sun Burst'
  },
  {
    value: 'DonutCounter',
    key: 'Donut Counter'
  },
  {
    value: 'CountersPie',
    key: 'Counters Pie'
  },
  {
    value: 'Video',
    key: 'Video'
  },
  {
    value: 'LineTableCounter',
    key: 'LineTableCounter'
  },
  {
    value: 'compassGuage',
    key: 'Compass Guage'
  },
  {
    value: 'GridGuage',
    key: 'GridGuage'
  },
  {
    value: 'Report',
    key: 'Report'
  },
  {
    value: 'Image',
    key: 'Image'
  },
  {
    value: 'Predictions',
    key: 'Predictions'
  }
];
