import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Button, Popconfirm, Tooltip, Input, DatePicker, Modal, Form, message } from 'antd';

import localStorage from '../../utils/localStorage';
import history from '../../commons/history';

import GraphView from './GraphView';
import { translation } from '../Adapter/TranslationAdapter.js';
import { ModalDiv, darkTheme, lightTheme } from '../Dashboard/DashboardComponents/CSS/styles';
import { getCurrentLanguage, getTranslationCache } from '../../selectors/language';

import constant from './constant';
import {
  BarChartOutlined,
  DoubleLeftOutlined,
  SearchOutlined,
  UpSquareOutlined
} from '@ant-design/icons';
import HierarchyModal from './HierarchyModal';
import { StyledComponent, StyledForm, StyledCheckbox } from './CSS/Style';
import themeSettings from './Utils/themeSettings.json';

const { RangePicker } = DatePicker;
class FormGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      optionList: [],
      selectedThemeing: 'black',
      modalVisible: false,
      dateTimeRange: [],
      isGenerate: false,
      Device: '',
      deviceList: [],
      translationCache: props.translationCache || [],
      language: props.language,
      graphData: {},
      selectedGraph: {},
      curentPage: 1,
      deviceId: [],
      isModalVisible: false,
      formObject: {}
    };

    this.hRef = React.createRef();
  }
  componentDidMount = async () => {
    if (this.props.location && this.props.location.state) {
      this.setState({ deviceId: this.props.location.state });
    }
    await this.getDeviceList();
    await this._getOptionList();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.language !== this.props.language
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        language: this.props.language
      });
    }
  }

  _getOptionList = () => {
    let options = [];

    options = JSON.parse(localStorage.get('modules')) || [];
    this.setState({
      options: options && options.hierarchy ? options.hierarchy : []
    });
  };

  getDeviceList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((res) => {
        let deviceList = res.data;
        this.setState({ deviceList });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  generateRecord = (record) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/fft/generate?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Device: record.Device,
        FileName: [record.FileName]
      }
    };
    axios(headers)
      .then((res) => {
        if (res && res.data && res.data.message) {
          message.success(res.data.message);
        }
        this.submitFile(record);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  graphView = (record) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/fft/show?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Device: record && record.DeviceAlias ? record.DeviceAlias : '',
        FileName: [record.FileName]
      }
    };
    axios(headers)
      .then((res) => {
        this.setState({
          modalVisible: true,
          graphData: res.data[0] && res.data[0],
          selectedGraph: record
        });
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === 'TimeStamp' ? (
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD-MM-YYYY HH:mm"
            placeholder={translation(
              this.props.translationCache[`${constant.Search}`] +
                this.props.translationCache[`${dataIndex}`]
            )}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        ) : (
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={translation(
              this.props.translationCache[`${constant.Search}`] +
                this.props.translationCache[`${dataIndex}`]
            )}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        )}

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          <SearchOutlined /> {this.props.translationCache[`${constant.Search}`]}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.translationCache[`${constant.Reset}`]}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record && record[dataIndex] && dataIndex === 'Device'
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : record && record[dataIndex] && dataIndex === 'TimeStamp'
        ? moment(record['dataIndex']).format('DD-MM-YYYY HH:mm')
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm, column) => {
    confirm();
    if (column === 'TimeStamp') {
      this.setState({
        searchText: moment(selectedKeys[0]).format('DD-MM-YYYY HH:mm')
      });
    } else {
      this.setState({ searchText: selectedKeys[0] });
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  submitFile = async (record) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/fft/getFiles?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Device: record.Device ? record.Device : '',
        StartDate: moment.utc(this.state.dateTimeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment.utc(this.state.dateTimeRange[1]).format('YYYY-MM-DD HH:mm:ss'),
        isGenerate: this.state.isGenerate
      }
    };
    axios(headers)
      .then((res) => {
        message.success(this.props.translationCache[`${constant.Data_Found}`]);
        this.setState({ dataSource: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onFinish = async (values) => {
    let { deviceId } = this.state;
    if (deviceId) {
      let siteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      const headers = {
        method: 'POST',
        url: `/api/fft/getFiles?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          // Device: '4_PUM-343-A',
          // EndDate: '2022-02-25 06:57:19',
          // isGenerate: true,
          // StartDate: '2022-02-18 05:57:19'
          Device: deviceId,
          StartDate: moment(values.dateTimeRange[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
          EndDate: moment(values.dateTimeRange[1]).utc().format('YYYY-MM-DD HH:mm:ss'),
          isGenerate: values.isGenerate
        }
      };
      axios(headers)
        .then((res) => {
          if (res && res.data && Array.isArray(res.data) && res.data.length <= 0) {
            message.error(this.setTranslation('No Data Found'));
          } else if (res && res.data && res.data.message) {
            message.error(this.setTranslation(res.data.message));
          } else {
            message.success(this.setTranslation('Data Found'));
          }
          this.setState({
            dataSource: res.data,
            dateTimeRange: values.dateTimeRange,
            isGenerate: values.isGenerate,
            Device: values.deviceId,
            curentPage: 1
          });
        })
        .catch(() => {
          console.log('err');
        });
    } else {
      message.error('Please select sensor !!');
    }
  };
  onFinishFailed = () => {
    message.error('Please enter mandatory fields');
  };
  close = () => {
    this.setState({ modalVisible: false });
  };

  hierarchyDropdownChange = (deviceId) => {
    this.setState({ deviceId: deviceId[deviceId.length - 1], isModalVisible: false });
  };
  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  render() {
    const { translationCache } = this.state;
    const { dataSource, graphData, deviceList, selectedGraph, curentPage, deviceId, formObject } =
      this.state;
    let theme = this.state.selectedThemeing;
    let filteredDevice =
      deviceId && deviceList
        ? deviceList.filter((device) => device.AliasCode.toString() === deviceId)
        : '';

    let selectedDeviceName = [];
    if (selectedGraph && selectedGraph.Device && deviceList) {
      selectedDeviceName = deviceList.filter((e) => e.AliasCode === selectedGraph.Device);
    }

    const columns = [
      {
        title:
          translationCache && translationCache[`${constant.device}`]
            ? translationCache[`${constant.device}`]
            : `${constant.device}`,
        dataIndex: 'Device',
        key: 'Device',
        ...this.getColumnSearchProps('Device'),
        render: (text) => {
          let filteredDevice = deviceList.filter((device) => device.AliasCode === text);
          return (
            <div>
              {filteredDevice &&
              Array.isArray(filteredDevice) &&
              filteredDevice[0] &&
              filteredDevice[0].Name
                ? `${filteredDevice[0].DeviceCode}`
                : text}
            </div>
          );
        }
      },
      {
        title:
          translationCache && translationCache[`${constant.timestamp}`]
            ? translationCache[`${constant.timestamp}`]
            : `${constant.timestamp}`,
        dataIndex: 'TimeStamp',
        key: 'TimeStamp',
        sorter: (a, b) => moment(a.TimeStamp) - moment(b.TimeStamp),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('TimeStamp'),
        render: (text) => (
          <div>{moment.utc(text, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')}</div>
        )
      },
      {
        title:
          translationCache && translationCache[`${constant.filename}`]
            ? translationCache[`${constant.filename}`]
            : `${constant.filename}`,
        dataIndex: 'FileName',
        key: 'FileName'
      },
      {
        title:
          translationCache && translationCache[`${constant.fftgenerateddate}`]
            ? translationCache[`${constant.fftgenerateddate}`]
            : `${constant.fftgenerateddate}`,
        dataIndex: 'FFTGeneratedDate',
        key: 'FFTGeneratedDate',
        render: (text) => (
          <div>
            {text
              ? moment.utc(text, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')
              : ''}
          </div>
        )
      },
      {
        title:
          translationCache && translationCache[`${constant.status}`]
            ? translationCache[`${constant.status}`]
            : `${constant.status}`,
        dataIndex: '',
        key: '',
        render: (text, record) => (
          <div>
            {record.Status === 'GENERATE' ? (
              <Popconfirm
                title={
                  translationCache && translationCache[`${constant.Do_you_want_to_Generate}`]
                    ? translationCache[`${constant.Do_you_want_to_Generate}`]
                    : `${constant.Do_you_want_to_Generate}`
                }
                onConfirm={() => {
                  this.generateRecord(record);
                }}
                onCancel={this.cancel}
                okText={translationCache[`${constant.yes}`]}
                cancelText={translationCache[`${constant.no}`]}
              >
                <Button
                  type="primary"
                  disabled={record && record.Status && record.Status === 'Done' ? true : false}
                >
                  {translationCache && translationCache[`${constant.Generate}`]
                    ? translationCache[`${constant.Generate}`]
                    : `${constant.Generate}`}
                </Button>
              </Popconfirm>
            ) : (
              <Tooltip placement="top" title="Graph View">
                <Button
                  type="button"
                  style={{ marginLeft: '10px' }}
                  onClick={() => this.graphView(record)}
                >
                  <BarChartOutlined width="50em" style={{ color: '#40a9ff' }} />
                </Button>
              </Tooltip>
            )}
          </div>
        )
      }
    ];
    let handlePagination = (curentPage) => {
      this.setState({
        curentPage
      });
    };
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ minHeight: window.innerHeight - 69, padding: '0px 15px' }}
      >
        <>
          <Button
            style={{ marginTop: '10px' }}
            type={'primary'}
            onClick={() => {
              history.goBack();
            }}
          >
            <DoubleLeftOutlined />
            {translationCache && translationCache['Go Back']
              ? translationCache['Go Back']
              : 'Go Back'}
          </Button>
          <StyledForm
            layout={'inline'}
            ref={this.hRef}
            initialValues={{
              layout: formObject || {}
            }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            style={{ margin: '36px 0px' }}
            theme={themeSettings}
            validateMessages={validateMessages}
          >
            <Form.Item
              label={
                translationCache && translationCache[`${constant.device}`]
                  ? translationCache[`${constant.device}`]
                  : `${constant.device}`
              }
              name="deviceId"
              initialValue={deviceId || ''}
              rules={[{ required: false }]}
            >
              <span style={{ marginTop: '10px' }}>
                <Input
                  placeholder={
                    translationCache &&
                    translationCache['Select sensor'] &&
                    translationCache['Select sensor']
                      ? translationCache['Select sensor']
                      : 'Select sensor'
                  }
                  value={
                    filteredDevice &&
                    Array.isArray(filteredDevice) &&
                    filteredDevice[0] &&
                    filteredDevice[0].Name
                      ? `${this.setTranslation(filteredDevice[0].Name)}${
                          this.setTranslation(filteredDevice[0].DeviceDescription)
                            ? `-${this.setTranslation(filteredDevice[0].DeviceDescription)}`
                            : ''
                        }  `
                      : ''
                  }
                  style={{
                    width: '70%'
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ isModalVisible: true });
                  }}
                >
                  <UpSquareOutlined />
                </Button>
                <Modal
                  title={
                    translationCache && translationCache[`${constant.SELECT_HIERARCHY}`]
                      ? translationCache[`${constant.SELECT_HIERARCHY}`]
                      : `${constant.SELECT_HIERARCHY}`
                  }
                  className="hierarchyModal"
                  visible={this.state.isModalVisible}
                  bodyStyle={{ overflowY: 'scroll', height: '900px' }}
                  footer={null}
                  onCancel={() => {
                    this.setState({
                      isModalVisible: false
                    });
                  }}
                >
                  <HierarchyModal handleDropdownChange={this.hierarchyDropdownChange} />
                </Modal>
              </span>
            </Form.Item>
            <Form.Item
              label={
                translationCache && translationCache[`${constant.timestamprange}`]
                  ? translationCache[`${constant.timestamprange}`]
                  : `${constant.timestamprange}`
              }
              name="dateTimeRange"
              initialValue={[moment().subtract(1, 'hour'), moment()] || []}
              rules={[{ required: true }]}
            >
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder={['Start DateTime', 'End DateTime']}
                style={{
                  width: '70%'
                }}
              />
            </Form.Item>
            <Form.Item label={''} name="isGenerate" valuePropName="checked" initialValue={true}>
              <StyledCheckbox
                style={{
                  width: '70%'
                }}
              >
                <span style={{ color: 'white' }}>
                  {translationCache && translationCache[`${constant.showonlygenerated}`]
                    ? translationCache[`${constant.showonlygenerated}`]
                    : `${constant.showonlygenerated}`}
                </span>
              </StyledCheckbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {translationCache && translationCache[`${constant.submit}`]
                  ? translationCache[`${constant.submit}`]
                  : `${constant.submit}`}
              </Button>
            </Form.Item>
          </StyledForm>

          <Table
            columns={columns}
            dataSource={dataSource && dataSource.length > 0 ? dataSource : []}
            pagination={{
              current: curentPage,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30'],
              onChange: handlePagination
            }}
          />
          <ModalDiv key={0} theme={theme === 'lightTheme' ? lightTheme : darkTheme}>
            <Modal
              // title={`${selectedGraph.Device} (${selectedGraph.TimeStamp})`}
              title={`${
                selectedDeviceName && selectedDeviceName[0] && selectedDeviceName[0].DeviceCode
                  ? selectedDeviceName[0].DeviceCode
                  : selectedGraph.Device
              }                 
                (${moment
                  .utc(selectedGraph.TimeStamp, 'YYYY-MM-DD HH:mm:ss')
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')})`}
              centered
              width="1600px"
              height="600px"
              style={{ background: 'white' }}
              visible={this.state.modalVisible}
              onCancel={() => this.close()}
              footer={null}
            >
              <GraphView
                panelData={{
                  graph: 'trends',
                  trendData: graphData
                }}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </Modal>
          </ModalDiv>
        </>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(FormGenerator);
