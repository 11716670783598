import React from 'react';
import moment from 'moment';
import { DatePicker, message, Select, Table } from 'antd';

import {
  getChartWarningSelection
  // _getReportNametoDownload
} from '../../../ChartCalls';
import { DatePickerSlider } from '../../../CSS/style';
import SliderWarning from './SliderWarning';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../../selectors/language';
import { getCurrentTimezone } from '../../../../../../selectors/layout';
const { RangePicker } = DatePicker;
const { Option } = Select;

class WarningLine extends React.Component {
  constructor(props) {
    super(props);
    let { warningList } = this.props.graphprops || {};
    warningList = warningList && JSON.parse(warningList) ? JSON.parse(warningList) : [];

    let warningDevice =
      warningList && Array.isArray(warningList) && warningList.length > 0 && warningList[0].device
        ? warningList[0].device
        : '';
    let warningParameter =
      warningList && Array.isArray(warningList) && warningList[0] && warningList[0].parameter
        ? warningList[0].parameter
        : '';
    this.state = {
      tableData: [],
      previousTableData: [],
      graphprops:
        {
          ...props.graphprops,
          dateRangeExists: 'true'
        } || {},
      initialRender: true,
      deviceId: props.deviceId || undefined,
      warningDevice,
      warningList,
      warningParameter,
      selectionDateRangePrevious: {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD 00:00'),
        endDate: moment().subtract(1, 'hour').format('YYYY-MM-DD 23:59'),
        timeBucket: '1m',
        warningDevice,
        warningParameter,
        warningGraphType: 'line'
      },
      selectionDateRange: {
        startDate: moment().subtract(3, 'days').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1m',
        warningDevice,
        warningParameter,
        warningGraphType: 'line'
      },
      selectedDateRange: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  componentDidUpdate = (preprops) => {
    if (preprops.graphprops !== this.props.graphprops) {
      this.setData();
    }
  };

  setData = () => {
    let { warningList } = this.props.graphprops || {};
    warningList = warningList && JSON.parse(warningList) ? JSON.parse(warningList) : [];
    let warningDevice =
      warningList && Array.isArray(warningList) && warningList.length > 0 && warningList[0].device
        ? warningList[0].device
        : '';
    let warningParameter =
      warningList &&
      Array.isArray(warningList) &&
      warningList.length > 0 &&
      warningList[0].parameter
        ? warningList[0].parameter
        : '';

    let selectionDateRange = {
      startDate: moment().subtract(3, 'hour').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      timeBucket: '1m',
      warningDevice,
      warningParameter,
      warningGraphType: 'line'
    };
    let selectionDateRangePrevious = {
      startDate: moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'),
      timeBucket: '1m',
      warningDevice,
      warningParameter,
      warningGraphType: 'line'
    };
    this._getTableData(
      { ...selectionDateRange, warningGraphType: 'table' },
      { ...selectionDateRangePrevious, warningGraphType: 'table' }
    );
    this.setState({
      selectionDateRange,
      selectionDateRangePrevious,
      graphprops:
        {
          ...this.props.graphprops,
          dateRangeExists: 'true'
        } || {},
      warningParameter,
      warningDevice
    });
  };

  _getTableData = async (selectionDateRange, selectionDateRangePrevious) => {
    let { graphprops } = this.props;
    let { warningList } = graphprops || {};
    warningList = warningList && JSON.parse(warningList) ? JSON.parse(warningList) : [];
    let warningDevice =
      warningList && Array.isArray(warningList) && warningList.length > 0 && warningList[0].device
        ? warningList[0].device
        : '';
    let warningParameter =
      warningList &&
      Array.isArray(warningList) &&
      warningList.length > 0 &&
      warningList[0].parameter
        ? warningList[0].parameter
        : '';
    graphprops = {
      ...graphprops,
      dateRangeExists: 'true',
      warningList,
      warningDevice,
      warningParameter
    };
    if (selectionDateRange) {
      let tableData = await getChartWarningSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        selectionDateRange,
        this.props.dayMonthYear,
        this.abortController,
        this.props.dropdownSelection,
        this.props.dashboardPayload,
        this.props.AssetCode,
        this.props.selectionDateLive,
        this.props.currentTimeZone
      );
      this.setState({
        tableData
      });
    }
    if (selectionDateRangePrevious) {
      let previousTableData = await getChartWarningSelection(
        graphprops,
        this.props.deviceId,
        this.props.selectionDate,
        this.props.selectionMonth,
        this.props.selectionWeek,
        this.props.shift,
        selectionDateRangePrevious,
        this.props.dayMonthYear,
        this.abortController,
        this.props.dropdownSelection
      );
      this.setState({
        previousTableData
      });
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  onDateRangePickerChange = async (currentDate) => {
    let { selectionDateRange } = this.state;
    if (currentDate && currentDate[0] && currentDate[1]) {
      selectionDateRange = {
        ...selectionDateRange,
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1m'
      };
    }
    await this._getTableData({ ...selectionDateRange, warningGraphType: 'table' });
    this.setState({
      selectionDateRange,
      selectedDateRange: false,
      initialRender: false
    });
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  handleOpenChange = (open) => {
    if (open) {
      this.setState({ mode: 'time' });
    }
  };

  handlePanelChange = (value, mode) => {
    this.setState({ mode });
  };

  deviceSelect = (parameter) => {
    let { selectionDateRange, selectionDateRangePrevious } = this.state;

    let { warningList } = this.props.graphprops || [];
    warningList = warningList && JSON.parse(warningList) ? JSON.parse(warningList) : [];
    let warningObject =
      warningList &&
      Array.isArray(warningList) &&
      warningList
        .filter((warn) => warn.parameter === parameter)
        .map((warn) => {
          return warn;
        });
    let warningDevice = warningObject && warningObject[0] ? warningObject[0] : {};
    selectionDateRange = {
      ...selectionDateRange,
      warningDevice: warningDevice.device,
      warningParameter: warningDevice.parameter
    };
    selectionDateRangePrevious = {
      ...selectionDateRangePrevious,
      warningDevice: warningDevice.device,
      warningParameter: warningDevice.parameter
    };
    this._getTableData(
      { ...selectionDateRange, warningGraphType: 'table' },
      { ...selectionDateRangePrevious, warningGraphType: 'table' }
    );
    this.setState({
      warningDevice: warningDevice.device,
      warningParameter: warningDevice.parameter,
      selectionDateRange,
      selectionDateRangePrevious,
      selectedDateRange: false,
      initialRender: true
    });
  };

  SendSelectedDateRange = async (dateRange) => {
    let { selectionDateRangePrevious } = this.state;
    let startDate = '';
    let endDate = '';
    if (this.state.initialRender) {
      startDate = moment(dateRange.startDate, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(dateRange.endDate, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    } else {
      startDate = moment(dateRange.startDate, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(dateRange.endDate, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    }
    if (startDate && endDate && startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      selectionDateRangePrevious = {
        ...selectionDateRangePrevious,
        startDate,
        endDate,
        timeBucket: '1m'
      };
      await this._getTableData(undefined, {
        ...selectionDateRangePrevious,
        warningGraphType: 'table'
      });
      this.setState({
        // selectionDateRange,
        // initialRender: false,
        selectedDateRange: true,
        selectionDateRangePrevious
      });
    } else {
      message.error('Error in selection');
    }
  };

  SendSelectedDateRangePrevious = () => {
    // console.log('dateRangePrevious', dateRangePrevious);
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let {
      selectionDateRange,
      selectionDateRangePrevious,
      warningParameter,
      tableData,
      previousTableData,
      selectedDateRange,
      graphprops
    } = this.state;
    let { warningList } = this.props.graphprops || [];
    console.log('warningList in props', warningList);
    warningList = warningList && JSON.parse(warningList) ? JSON.parse(warningList) : [];
    console.log('warningList', warningList, warningParameter);
    const columns = [
      {
        title: this.translation('Warning limits'),
        dataIndex: 'Warning Limits',
        key: 'Warning Limits'
      },
      {
        title: this.translation('Number of Count'),
        dataIndex: 'Number of Count',
        key: 'Number of Count'
      },
      {
        title: this.translation('Total Time in Mins'),
        dataIndex: 'Total Time in mins',
        key: 'Total Time in mins'
      }
    ];

    return (
      <div style={{ height: '70%', marginTop: '40px', display: 'inline-block', width: '100%' }}>
        <DatePickerSlider>
          <Select style={{ width: 300 }} value={warningParameter} onChange={this.deviceSelect}>
            {warningList &&
              Array.isArray(warningList) &&
              warningList.map((warningList, index) => {
                return (
                  <Option key={index} value={warningList.parameter}>
                    {this.translation(warningList.parameterName)}
                  </Option>
                );
              })}
          </Select>
          <RangePicker
            allowClear={false}
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            placeholder={[this.translation('Start Date'), this.translation('End Date')]}
            onChange={this.onDateRangePickerChange}
            disabledDate={this.disabledDate}
            value={[moment(selectionDateRange.startDate), moment(selectionDateRange.endDate)]}
            // ranges={{
            //   Today: [moment().startOf('day'), moment()],
            //   'This Month': [moment().startOf('month'), moment()],
            //   'Last Month': [
            //     moment().subtract(1, 'month').startOf('month'),
            //     moment().subtract(1, 'month').endOf('month')
            //   ]
            // }}
            style={{
              width: '400px',
              cursor: 'pointer'
            }}
          />
        </DatePickerSlider>

        {graphprops && graphprops.title ? (
          <div style={{ width: selectedDateRange ? '45%' : '100%', float: 'left' }}>
            <SliderWarning
              graphprops={this.state.graphprops}
              dropdownSelection={this.props.dropdownSelection}
              deviceId={this.state.deviceId}
              selectionDateRange={this.state.selectionDateRange}
              sendSelectedDateRange={this.SendSelectedDateRange}
              initialRender={this.state.initialRender}
            />
            <Table
              className="TableWidget"
              style={{ padding: '55px' }}
              dataSource={tableData && Array.isArray(tableData) ? tableData : []}
              columns={columns}
            />
          </div>
        ) : null}

        {selectedDateRange && graphprops && graphprops.title ? (
          <div>
            <div style={{ width: '45%', float: 'right' }}>
              <SliderWarning
                graphprops={this.state.graphprops}
                dropdownSelection={this.props.dropdownSelection}
                deviceId={this.state.deviceId}
                selectionDateRange={selectionDateRangePrevious}
                sendSelectedDateRange={this.SendSelectedDateRangePrevious}
                initialRender={this.state.initialRender}
              />
              <Table
                className="TableWidget"
                style={{ padding: '55px' }}
                dataSource={
                  previousTableData && Array.isArray(previousTableData) ? previousTableData : []
                }
                columns={columns}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(WarningLine);
