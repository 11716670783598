import React, { useState } from 'react';
import 'antd/dist/antd.css';
// import './index.css';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, Drawer, Form, Input, List, Space } from 'antd';
import moment from 'moment';

const { TextArea } = Input;
const CommentList = ({ comments }) => {
  return (
    <List
      key={comments}
      dataSource={comments.map((items) => {
        return {
          // author: items.CreatedBy === 1 || '1' ? 'Admin' : 'Admins',
          author: 1,
          avatar: <Avatar icon={<UserOutlined />} />,
          content: <p className="commentcss">{items.Comment}</p>,
          datetime: moment(items.CreatedDate).fromNow()
        };
      })}
      header={`${comments.length} ${comments.length > 1 ? 'Comments' : 'Comment'}`}
      itemLayout="horizontal"
      renderItem={(props) => <Comment {...props} />}
    />
  );
};
const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);
const CommentsForm = (props) => {
  const [comments, setComments] = useState(props && props.Comments ? props.Comments : []);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const handleSubmit = () => {
    if (!value) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setValue('');
      setComments([
        ...comments,
        {
          CreatedBy: props.user,
          Comment: value,
          CreatedDate: moment().format('MM/DD/YYYY hh:mm:ss A')
        }
      ]);
    }, 1000);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  // useEffect((prevprops, props) => {
  //   // action on update of movies
  //   if (prevprops !== props) {
  //     setComments(
  //       props && props.Comments && props.Comments !== null ? props && props.Comments : comments
  //     );
  //   }
  // });

  // if(props.mainkey && props.mainindex) {
  //   data=props.formObject &&
  //   props.formObject[props.mainkey] &&
  //  props. formObject[props.mainkey][props.mainindex] &&
  //  props.formObject[props.mainkey][props.mainindex] &&
  //  props.formObject[props.mainkey][props.mainindex][props.keyName] &&
  //  props.formObject[props.mainkey][props.mainindex][props.keyName][props.index] &&
  //  props.formObject[props.mainkey][props.mainindex][props.keyName][props.index]["Comments"]?
  //  props.formObject[props.mainkey][props.mainindex][props.keyName][props.index]["Comments"]:[]
  // }
  //      else if( props.keyName && props.index){
  //       data=props. formObject &&
  //       props. formObject[ props.keyName]  &&
  //        props.formObject[ props.keyName][ props.index] &&
  //        props.formObject[ props.keyName][ props.index]["Comments"] ?props.formObject[ props.keyName][ props.index]["Comments"]:[]
  //      }else{
  //       data= props.formObject &&props.formObject["Comments"] ? props.formObject["Comments"]:[]
  //      }

  return (
    <>
      <Drawer
        closable
        width={1000}
        open={props.visible}
        title={props.Title}
        onClose={() => props.modalClose()}
        okText={'Submit'}
        extra={
          <Space>
            <Button onClick={() => props.modalClose()}>Cancel</Button>
            <Button
              disabled={submitting === true ? true : false}
              onClick={() =>
                props.submitComments(
                  comments,
                  props.keyName,
                  props.index,
                  props.mainkey,
                  props.mainindex
                )
              }
              type="primary"
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Comment
          avatar={<Avatar icon={<UserOutlined />} />}
          content={
            <Editor
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
            />
          }
        />
        {comments.length > 0 && <CommentList comments={comments} />}
      </Drawer>
    </>
  );
};
export default CommentsForm;
