/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of WorkOrders
 */
import React, { Component } from 'react';
import { Button, Input, Popconfirm, Tooltip } from 'antd';
import {
  getEmergencyWorkOrdersList,
  deleteWorkOrder,
  changeWorkOrderStatus,
  getUsersList
} from '../../../Utils/FormCalls';
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { encode as base64_encode } from 'base-64';
import history from '../../../../../commons/history';
import ListView from '../../../Utils/ListView';
import Highlighter from 'react-highlight-words';
import { StyledComponent } from '../../../CSS/style';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import moment from 'moment-timezone';

class EmergencyWorkOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      dataSource: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkOrder'],

        firstTitle: 'WorkOrder',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/EWODetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };
  _getAdhocDataSource = async () => {
    let dataSource = await getEmergencyWorkOrdersList();
    dataSource = this.convert(dataSource);
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };
  convert = (data) => {
    return (
      data &&
      data.map((item) => {
        return {
          ...item,
          RecordDate:
            item && item.RecordDate
              ? moment(item.RecordDate).local().format('YYYY-MM-DD HH:mm:ss')
              : null
        };
      })
    );
  };
  editWorkOrder = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          {translation(this.props.translationCache, `Search`)}
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log('handleSearch', selectedKeys, typeof selectedKeys[0], dataIndex);
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  _deleteById = async (id) => {
    await deleteWorkOrder(id);
    let dataSource = await getEmergencyWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1'
      // form: {}
    });
  };

  changeStatus = async (id) => {
    await changeWorkOrderStatus(id);
    let dataSource = await getEmergencyWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1'
      // form: {}
    });
  };

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.workOrder),
        dataIndex: 'WorkOrder',
        key: 'WorkOrder',
        filter: true,
        ...this.getColumnSearchProps('WorkOrder')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },

      {
        title: translation(translationCache, constant.recordedDate),
        dataIndex: 'RecordDate',
        key: 'RecordDate',
        filter: true,
        ...this.getColumnSearchProps('RecordDate')
        // render: (text) => {
        //   if (text) {
        //     return moment(text).local().format('YYYY-MM-DD HH:mm:ss');
        //   }
        // },
      },
      {
        title: translation(translationCache, constant.actualStart),
        dataIndex: 'ActualStart',
        key: 'ActualStart',
        filter: true,
        render: (text) => {
          if (text) {
            return moment(text).local().format('YYYY-MM-DD HH:mm:ss');
          }
        }
      },
      {
        title: translation(translationCache, constant.actualEnd),
        dataIndex: 'ActualEnd',
        key: 'ActualEnd',
        filter: true,
        render: (text) => {
          if (text) {
            return moment(text).local().format('YYYY-MM-DD HH:mm:ss');
          }
        }
      },

      // {
      //   title: translation(translationCache, constant.status),
      //   dataIndex: 'Status',
      //   key: 'Status',
      //   render: (text, record) => (
      //     <>
      //       <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
      //         <span onClick={() => this.changeStatus(record.Id)}>
      //           <Tag
      //             color={
      //               text && text === 'Active'
      //                 ? '#87d068'
      //                 : text === 'Inactive'
      //                 ? '#ff4d4f'
      //                 : 'gold'
      //             }
      //             key={text}
      //           >
      //             {translation(translationCache, constant.text)}
      //           </Tag>
      //         </span>
      //       </Tooltip>
      //     </>
      //   )
      // },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName'
        // filter: true
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              disabled={record.Status === 'Inactive' ? true : false}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.editWorkOrder(record)}
              size="middle"
            >
              <Tooltip title={'Edit'}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(
                translationCache,
                constant.areYouSureThatYouWantToDeleteTheEmergencyWorkOrder
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={translation(translationCache, 'Delete')}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'Trouble Reporting'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['WorkOrder'],

      firstTitle: 'WorkOrder',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/EWODetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EmergencyWorkOrders);
