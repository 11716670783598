import React, { Fragment } from 'react';
import { Space, Form, Select, Input, Button, Row, Col, message, Popconfirm, Divider } from 'antd';
import { Card } from 'antd';
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { getSupplierList } from '../EquipmentMastersComponents/ApiCall';
import { createSpec, getSpecRecord } from './ApiCalls';
import { StyledApplication1 } from '../style';
import './style.css';

const { Option } = Select;
class EquipmentHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: undefined,
      deviceList: [],
      deviceCode: undefined,
      hierarchy: [],
      hierarchyData: [],
      Specifications: [],
      specsMaster: [],
      DeviceId: this.props.DeviceId,
      recordData: [],
      supplierList: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      selectedId: undefined,
      deviceId: undefined,
      EquipementName: this.props.EquipementName,
      ModelVisible: false,

      visible: this.props.visible
    };
  }

  formRef = React.createRef();
  componentDidMount = async () => {
    try {
      let supplierList = await getSupplierList();
      this.setState({
        supplierList: supplierList.data
      });
      try {
        if (this.props.DeviceId !== undefined || this.props.DeviceId !== null) {
          let record = await getSpecRecord(this.props.DeviceId);
          let recordDat = record.data && record.data;

          this.setState({ recordData: recordDat, visible: true });
          this.onReset();
        }
      } catch (err) {
        message.warn(`${err.message}`);
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  // _getHierarchy = () => {
  //   const siteId = localStorage.get('currentSite')
  //   let accessToken = localStorage.get('accessToken')
  //   const Headers = {
  //     method: 'GET',
  //     url: `/api/hierarchymodule/getHierarchy?SiteId=${siteId}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     },

  //   }
  //   axios(Headers)
  //     .then((response) => {
  //       this.setState({ hierarchyData: response.data.hierarchy })
  //     }).catch(error => {
  //       );
  //     })
  // }

  onReset = () => {
    this.formRef.current.resetFields();
  };

  onFinish = async (values) => {
    const { recordData } = this.state;
    if (values.Specifications.length > 0) {
      try {
        await createSpec('Equipment', this.state.DeviceId, values, recordData.SupplierId);
        this.setState({ deviceCode: undefined, deviceType: undefined, visible: false });
        message.success('Record submitted succesfully');
        this.props._getHierarchy();
      } catch (err) {
        message.warn(`${err.message}`);
      }
    } else {
      message.warn('Please Add Atleast one Specification');
    }
  };

  render() {
    let { DeviceId, recordData, supplierList, EquipementName } = this.state;
    return (
      <StyledApplication1>
        <Fragment>
          <Row>
            <Col span={19}>
              {DeviceId !== undefined ? (
                <Form
                  ref={this.formRef}
                  name="dynamic_form_nest_item"
                  layout="vertical"
                  onFinish={this.onFinish}
                  initialValues={
                    recordData.length !== 0 && recordData.length !== undefined
                      ? recordData.Specifications
                      : recordData
                  }
                  style={{
                    width: '75vw',
                    backgroundColor: '#214972',
                    height: '114vh',
                    flex: '1',
                    overflowY: 'scroll'
                  }}
                >
                  <Form.Item
                    label={
                      <label style={{ color: '#fff', fontWeight: 'bold' }}>
                        Selected Equipment :
                      </label>
                    }
                    style={{ marginLeft: '70px' }}
                  >
                    <Input
                      value={EquipementName ? EquipementName : ''}
                      style={{ width: '300px', fontStyle: 'bold' }}
                      disabled
                    />
                  </Form.Item>
                  {this.state.visible === true ? (
                    <div>
                      <Divider style={{ color: '#fff', fontWeight: 'bold' }}>
                        Supplier Details
                      </Divider>
                      <Form.Item
                        label={
                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                            Select Supplier
                          </label>
                        }
                        // initialValue={SpecRecordData.SupplierId}
                        name="SupplierId"
                        style={{ marginLeft: '67px' }}
                        rules={[{ required: true, message: 'Please Select Supplier' }]}
                      >
                        <Select
                          style={{ width: '300px' }}
                          placeholder="Select Supplier"
                          onChange={this.handleChange}
                        >
                          {supplierList &&
                            Array.isArray(supplierList) &&
                            supplierList.map((param, index) => {
                              return (
                                <Option key={index} value={param.Id}>
                                  {' '}
                                  {param.Name}{' '}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Card style={{ backgroundColor: '#214972' }}>
                        <Form.List name="Specifications">
                          {(fields, { add, remove }) => (
                            <>
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  className="submitBtn"
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                >
                                  Add Specifications
                                </Button>
                              </Form.Item>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ marginLeft: '70px' }}>
                                  <Form.Item
                                    label={
                                      <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                        Enter Specification :
                                      </label>
                                    }
                                    {...restField}
                                    name={[name, 'SpecName']}
                                    rules={[
                                      { required: true, message: 'Please Select Specifications' }
                                    ]}
                                  >
                                    <Input placeholder="Specification" />
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                        Value:
                                      </label>
                                    }
                                    {...restField}
                                    name={[name, 'Value']}
                                    rules={[{ required: true, message: 'Please Provide Value' }]}
                                  >
                                    <Input placeholder="Value" />
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                        UOM:
                                      </label>
                                    }
                                    {...restField}
                                    name={[name, 'UOM']}
                                  >
                                    <Input placeholder="UOM" />
                                  </Form.Item>
                                  <Popconfirm
                                    title="Are you sure that you want to delete the Specification ?"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => remove(name)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      className="deleteIcon"
                                      style={{ fontSize: '20px' }}
                                    />
                                  </Popconfirm>
                                </Space>
                              ))}
                            </>
                          )}
                        </Form.List>
                        <Form.Item>
                          <Button
                            type="primary"
                            className="submitBtn"
                            style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '70px' }}
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Card>
                    </div>
                  ) : null}
                </Form>
              ) : null}
            </Col>
          </Row>
        </Fragment>
      </StyledApplication1>
    );
  }
}

export default EquipmentHierarchy;
