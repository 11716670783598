import axios from 'axios';
import moment from 'moment';

import localStorage from '../../utils/localStorage';

export async function reportTableCall(ReportName, ReportType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(
    `/api/report/get?SiteId=${siteId}&ReportName=${ReportName}&ReportType=${ReportType}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {});

  return data === undefined ? [] : data;
}

export async function getReportNametoDownloadMultiVaraint(
  columns,
  datasource,
  multiVaraintColumns,
  downloadExcelPayload
) {
  let finalObject = [];
  let parentHeaderkeys =
    columns &&
    Array.isArray(columns) &&
    columns.map((parentColumns) => {
      return parentColumns.key;
    });
  let parentHeaderTitle =
    columns &&
    Array.isArray(columns) &&
    columns.map((parentColumns) => {
      return parentColumns.title;
    });
  finalObject.push({
    SNo: 1,
    values: parentHeaderTitle && Array.isArray(parentHeaderTitle) ? parentHeaderTitle : [],
    type: 'parentHeader',
    colorCode: '#FFDAE3F3'
  });
  datasource &&
    Array.isArray(datasource) &&
    datasource.map((datasource) => {
      let finalObjectLength = finalObject.length + 1;
      let parentDatasource =
        parentHeaderkeys &&
        Array.isArray(parentHeaderkeys) &&
        parentHeaderkeys.map((parentHeader) => {
          return datasource[parentHeader] || '';
        });
      finalObject.push({
        SNo: finalObjectLength,
        values: parentDatasource && Array.isArray(parentDatasource) ? parentDatasource : [],
        type: '',
        colorCode: '',
        textColor: datasource && datasource['color'] ? datasource['color'] : ''
      });
      finalObject.push({
        SNo: finalObject.length + 1,
        values: [],
        type: '',
        colorCode: ''
      });
      finalObjectLength = finalObject.length + 1;

      let childHeaderTitle =
        multiVaraintColumns &&
        multiVaraintColumns[0] &&
        multiVaraintColumns[0].columns &&
        Array.isArray(multiVaraintColumns[0].columns) &&
        multiVaraintColumns[0].columns.map((childColumns) => {
          return childColumns.title;
        });
      if (childHeaderTitle && Array.isArray(childHeaderTitle)) {
        childHeaderTitle = [null, ...childHeaderTitle];
      } else {
        childHeaderTitle = [null, childHeaderTitle];
      }

      let childHeaderkeys =
        multiVaraintColumns &&
        multiVaraintColumns[0] &&
        multiVaraintColumns[0].columns &&
        Array.isArray(multiVaraintColumns[0].columns) &&
        multiVaraintColumns[0].columns.map((childColumns) => {
          return childColumns.key;
        });
      if (childHeaderkeys && Array.isArray(childHeaderkeys)) {
        childHeaderkeys = [null, ...childHeaderkeys];
      } else {
        childHeaderTitle = [null, childHeaderTitle];
      }

      finalObject.push({
        SNo: finalObjectLength,
        values: childHeaderTitle && Array.isArray(childHeaderTitle) ? childHeaderTitle : [],
        type: 'childHeader',
        colorCode: '#FFDAE3F3'
      });

      datasource.multiVariantChildren &&
        Array.isArray(datasource.multiVariantChildren) &&
        datasource.multiVariantChildren.map((childHeader) => {
          finalObjectLength = finalObject.length + 1;
          let childDatasource =
            childHeaderkeys &&
            Array.isArray(childHeaderkeys) &&
            childHeaderkeys.map((childHeaderkeys) => {
              return childHeader[childHeaderkeys] ? childHeader[childHeaderkeys] : '';
            });
          finalObject.push({
            SNo: finalObjectLength,
            values: childDatasource && Array.isArray(childDatasource) ? childDatasource : [],
            type: '',
            colorCode: ''
          });
          return {};
        });
      finalObject.push({
        SNo: finalObject.length + 1,
        values: [],
        type: '',
        colorCode: ''
      });
      return {};
    });

  let payload = {
    // ...headerPayload,
    ...downloadExcelPayload,
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm:SS'),
    currentTime: moment().format('YYYY-MM-DD'),
    Data: finalObject
  };

  let accessToken = localStorage.get('accessToken');
  let options = {};
  let siteId = localStorage.get('currentSite');
  options = {
    method: 'POST',
    url: `/api/file/generateExcel?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

export async function getDataSourceFromBackend(apiObject) {
  let accessToken = localStorage.get('accessToken');
  const SiteId = localStorage.get('currentSite');
  let options = {};
  if (apiObject && apiObject.method === 'GET' && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}?SiteId=${SiteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
  } else if (apiObject && apiObject.method === 'POST' && apiObject.payload && apiObject.uri) {
    options = {
      method: apiObject.method,
      url: `${apiObject.uri}?SiteId=${SiteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify({
        ...apiObject.payload,
        SiteId
      })
    };
  }

  let data = axios(options)
    .then(({ data }) => {
      if (data && Array.isArray(data) && typeof data !== 'string') {
        return data;
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });

  return data;
}

function getChildren(columnChildren, datasourceObject) {
  let newChildrenObject = {};
  if (columnChildren && columnChildren.children) {
    columnChildren.children.map((subChildren) => {
      newChildrenObject[subChildren.title] = getChildren(subChildren, datasourceObject);
      return {};
    });
    return newChildrenObject;
  } else {
    if (
      columnChildren &&
      columnChildren.key &&
      datasourceObject[columnChildren.key] === undefined
    ) {
      // newChildrenObject[columnChildren.title] = ""
      return '';
    } else {
      // newChildrenObject[columnChildren.title] = datasourceObject[columnChildren.key]
      return datasourceObject[columnChildren.key];
    }
  }
  // return newChildrenObject
}

export async function getReportNametoDownload(
  columns,
  datasource,
  downloadExcelPayload,
  childColumns,
  childDatasource,
  weekSummaryDatasource,
  selectionPayload
) {
  let payload = {};
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = [];

    if (datasource && datasource.length > 0) {
      datasource &&
        Array.isArray(datasource) &&
        datasource.map((datasourceObject) => {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    datasourceObject
                  );
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (datasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else if (
                  datasourceObject &&
                  datasourceObject[column.key] &&
                  datasourceObject[column.key] !== '-' &&
                  column &&
                  column.isUTC &&
                  (column.isUTC === true || column.isUTC === 'true')
                ) {
                  newObj[column.title] = moment
                    .utc(datasourceObject[column.key])
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss');
                } else {
                  newObj[column.title] = datasourceObject[column.key];
                }
              }
              return {};
            });
          reportPayload.push(newObj);
          return {};
        });
    } else {
      let newObj = {};
      columns &&
        Array.isArray(columns) &&
        columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {};
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
              return {};
            });
            newObj[column.title] = {
              ...newChildrenObject
            };
          } else {
            newObj[column.title] = '';
          }
          return {};
        });
      reportPayload.push(newObj);
    }
    let reportChildPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true)
    ) {
      if (childDatasource && childDatasource.length > 0) {
        childDatasource.map((childDatasourceObject) => {
          let newObj = {};
          childColumns &&
            childColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }
              return {};
            });
          reportChildPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }
            return {};
          });
        reportChildPayload.push(newObj);
      }
    }
    let reportWeekPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true) &&
      (downloadExcelPayload.weekSummaryType === 'true' ||
        downloadExcelPayload.weekSummaryType === true)
    ) {
      if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {};
          childColumns &&
            childColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }

              return {};
            });
          reportWeekPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }

            return {};
          });
        reportWeekPayload.push(newObj);
      }
    }
    payload = {
      ...selectionPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload
    };
  } else {
    let reportWeekPayload = [];
    let reportChildPayload = [];
    let reportPayload = {};
    let tableData = [];
    datasource &&
      Object.keys(datasource).map((dataKey) => {
        let adhocDatasource = datasource[dataKey].table;
        let adhocColumns = columns[dataKey];
        let indexValue = ++dataKey;
        reportPayload['table' + indexValue] = [];
        if (adhocDatasource && adhocDatasource.length > 0) {
          adhocDatasource &&
            Array.isArray(adhocDatasource) &&
            adhocDatasource.map((datasourceObject) => {
              let newObj = {};
              adhocColumns &&
                Array.isArray(adhocColumns) &&
                adhocColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        datasourceObject
                      );
                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (datasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = datasourceObject[column.key];
                    }
                  }
                  return {};
                });

              reportPayload['table' + indexValue].push(newObj);
              return {};
            });
        } else {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                newObj[column.title] = '';
              }

              return {};
            });
          tableData.push(newObj);
        }
        let reportChildPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true)
        ) {
          if (childDatasource && childDatasource.length > 0) {
            childDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportChildPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportChildPayload.push(newObj);
          }
        }
        let reportWeekPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.SummaryType === 'true' ||
            downloadExcelPayload.SummaryType === true) &&
          (downloadExcelPayload.weekSummaryType === 'true' ||
            downloadExcelPayload.weekSummaryType === true)
        ) {
          if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
            weekSummaryDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      newObj[column.title] = '';
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportWeekPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportWeekPayload.push(newObj);
          }
        }

        return {};
      });

    //  })
    payload = {
      ...selectionPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload
    };
  }
  let siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  let options = {};
  options = {
    method: 'POST',
    url: `/api/file/generateExcel?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

export async function getPdfNametoDownload(
  columns,
  datasource,
  downloadExcelPayload,
  childColumns,
  childDatasource,
  weekSummaryDatasource,
  headerPayload
) {
  let payload = {};
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = [];

    if (datasource && datasource.length > 0) {
      datasource &&
        Array.isArray(datasource) &&
        datasource.map((datasourceObject) => {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    datasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (datasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = datasourceObject[column.key];
                }
              }

              return {};
            });

          reportPayload.push(newObj);
          return {};
        });
    } else {
      let newObj = {};
      columns &&
        Array.isArray(columns) &&
        columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {};
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
              return {};
            });
            newObj[column.title] = {
              ...newChildrenObject
            };
          } else {
            newObj[column.title] = '';
          }

          return {};
        });
      reportPayload.push(newObj);
    }
    let reportChildPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true)
    ) {
      if (childDatasource && childDatasource.length > 0) {
        childDatasource.map((childDatasourceObject) => {
          let newObj = {};
          childColumns &&
            childColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }

              return {};
            });
          reportChildPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }

            return {};
          });
        reportChildPayload.push(newObj);
      }
    }
    let reportWeekPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true) &&
      (downloadExcelPayload.weekSummaryType === 'true' ||
        downloadExcelPayload.weekSummaryType === true)
    ) {
      if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {};
          childColumns &&
            childColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }

              return {};
            });
          reportWeekPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }

            return {};
          });
        reportWeekPayload.push(newObj);
      }
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload
    };
  } else {
    let reportWeekPayload = [];
    let reportChildPayload = [];
    let reportPayload = {};
    let tableData = [];
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource &&
      Object.keys(datasource).map((dataKey) => {
        let adhocDatasource = datasource[dataKey].table;
        let adhocColumns = columns[dataKey];
        let indexValue = ++dataKey;
        reportPayload['table' + indexValue] = [];
        if (adhocDatasource && adhocDatasource.length > 0) {
          adhocDatasource &&
            Array.isArray(adhocDatasource) &&
            adhocDatasource.map((datasourceObject) => {
              let newObj = {};
              adhocColumns &&
                Array.isArray(adhocColumns) &&
                adhocColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        datasourceObject
                      );
                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (datasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = datasourceObject[column.key];
                    }
                  }

                  return {};
                });

              reportPayload['table' + indexValue].push(newObj);
              return {};
            });
        } else {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                newObj[column.title] = '';
              }

              return {};
            });
          tableData.push(newObj);
        }
        let reportChildPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.SummaryType === 'true' || downloadExcelPayload.SummaryType === true)
        ) {
          if (childDatasource && childDatasource.length > 0) {
            childDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportChildPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportChildPayload.push(newObj);
          }
        }
        let reportWeekPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.SummaryType === 'true' ||
            downloadExcelPayload.SummaryType === true) &&
          (downloadExcelPayload.weekSummaryType === 'true' ||
            downloadExcelPayload.weekSummaryType === true)
        ) {
          if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
            weekSummaryDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      newObj[column.title] = '';
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportWeekPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportWeekPayload.push(newObj);
          }
        }

        return {};
      });

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload
    };
  }

  let accessToken = localStorage.get('accessToken');
  let options = {};
  options = {
    method: 'POST',
    url: `/api/report/generatePdf`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

export async function getISOReportNametoDownload(
  columns,
  datasource,
  downloadExcelPayload,
  childColumns,
  childDatasource,
  weekSummaryColumns,
  weekSummaryDatasource,
  monthlySummaryColumns,
  monthSummaryDatasource,
  headerPayload
) {
  let payload = {};

  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = [];

    if (datasource && datasource.length > 0) {
      datasource &&
        Array.isArray(datasource) &&
        datasource.map((datasourceObject) => {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    datasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (datasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = datasourceObject[column.key];
                }
              }

              return {};
            });

          reportPayload.push(newObj);
          return {};
        });
    } else {
      let newObj = {};
      columns &&
        Array.isArray(columns) &&
        columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {};
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
              return {};
            });
            newObj[column.title] = {
              ...newChildrenObject
            };
          } else {
            newObj[column.title] = '';
          }

          return {};
        });
      reportPayload.push(newObj);
    }

    let reportChildPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
    ) {
      if (childDatasource && Array.isArray(childDatasource) && childDatasource.length > 0) {
        Array.isArray(childDatasource) &&
          childDatasource.map((childDatasourceObject) => {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(
                      columnChildren,
                      childDatasourceObject
                    );

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  if (childDatasourceObject[column.key] === undefined) {
                    // newObj[column.title] = ""
                  } else {
                    newObj[column.title] = childDatasourceObject[column.key];
                  }
                }

                return {};
              });
            reportChildPayload.push(newObj);
            return {};
          });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }
            return {};
          });
        reportChildPayload.push(newObj);
      }
    }
    let reportWeekPayload = [];
    let reportMonthPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
    ) {
      if (
        weekSummaryDatasource &&
        Array.isArray(weekSummaryDatasource) &&
        weekSummaryDatasource.length > 0
      ) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {};

          weekSummaryColumns &&
            weekSummaryColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );

                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }

              return {};
            });

          reportWeekPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        weekSummaryColumns &&
          weekSummaryColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }

            return {};
          });
        reportWeekPayload.push(newObj);
      }

      if (monthSummaryDatasource && monthSummaryDatasource.length > 0) {
        monthSummaryDatasource &&
          Array.isArray(monthSummaryDatasource) &&
          monthSummaryDatasource.map((childDatasourceObject) => {
            let newObj = {};

            monthlySummaryColumns &&
              monthlySummaryColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(
                      columnChildren,
                      childDatasourceObject
                    );

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  if (childDatasourceObject[column.key] === undefined) {
                    newObj[column.title] = '';
                  } else {
                    newObj[column.title] = childDatasourceObject[column.key];
                  }
                }

                return {};
              });
            reportMonthPayload.push(newObj);
            return {};
          });
      } else {
        let newObj = {};
        monthlySummaryColumns &&
          monthlySummaryColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }

            return {};
          });
        reportMonthPayload.push(newObj);
      }
    }
    let childTables = {};

    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === true &&
        reportChildPayload &&
        reportChildPayload.length > 1)
    ) {
      childTables['child' + 1] = reportChildPayload;
    }
    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === true &&
        reportWeekPayload &&
        reportWeekPayload.length > 1)
    ) {
      childTables['child' + 2] = reportWeekPayload;
    }
    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === 'true' &&
        reportMonthPayload &&
        reportMonthPayload.length > 1)
    ) {
      childTables['child' + 3] = reportMonthPayload;
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      ChildTablesData: childTables
      //  "Summary": reportChildPayload,
      //  "WeeklySummary": reportWeekPayload,
      //  "MonthlySummary":reportMonthPayload
    };
  } else {
    let reportWeekPayload = [];
    let reportChildPayload = [];
    let reportPayload = {};
    let tableData = [];
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource &&
      Object.keys(datasource).map((dataKey) => {
        let adhocDatasource = datasource[dataKey].table;
        // // let header=datasource[dataKey].header
        let adhocColumns = columns[dataKey];
        let indexValue = parseInt(dataKey) + 1;
        reportPayload['table' + indexValue] = {
          datasource: []

          // ["headers"]: datasource[dataKey] &&datasource[dataKey].header
        };

        if (adhocDatasource && adhocDatasource.length > 0) {
          adhocDatasource &&
            Array.isArray(adhocDatasource) &&
            adhocDatasource.map((datasourceObject) => {
              let newObj = {};
              adhocColumns &&
                Array.isArray(adhocColumns) &&
                adhocColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        datasourceObject
                      );
                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (datasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = datasourceObject[column.key];
                    }
                  }

                  return {};
                });
              datasource[dataKey] &&
                datasource[dataKey].header &&
                Object.keys(datasource[dataKey].header).map(() => {
                  delete datasource[dataKey].header['KilnNo'];
                  return {};
                });
              reportPayload['table' + indexValue]['datasource'].push(newObj);
              reportPayload['table' + indexValue]['headers'] =
                datasource[dataKey] && datasource[dataKey].header;
              // delete reportPayload["table"+indexValue] ["headers"]["KilnNo"]

              reportPayload['table' + indexValue]['remarks'] =
                datasource[dataKey] && datasource[dataKey].footer;
              return {};
            });
        } else {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                newObj[column.title] = '';
              }

              return {};
            });
          tableData.push(newObj);
        }
        let reportChildPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
        ) {
          if (childDatasource && childDatasource.length > 0) {
            childDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportChildPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportChildPayload.push(newObj);
          }
        }
        let reportWeekPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
        ) {
          if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
            weekSummaryDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      newObj[column.title] = '';
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportWeekPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportWeekPayload.push(newObj);
          }
        }
        return {};
      });

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload
    };
  }
  let siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  let options = {};
  options = {
    method: 'POST',
    url: `/api/file/generateExcel?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

export async function getISOPdfNametoDownload(
  columns,
  datasource,
  downloadExcelPayload,
  childColumns,
  childDatasource,
  weekSummaryDatasource,
  weekSummaryColumns,
  monthSummaryDatasource,
  monthlySummaryColumns,
  headerPayload
) {
  let payload = {};
  if (columns && Array.isArray(columns) && datasource && Array.isArray(datasource)) {
    let reportPayload = [];

    if (datasource && datasource.length > 0) {
      datasource &&
        Array.isArray(datasource) &&
        datasource.map((datasourceObject) => {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    datasourceObject
                  );
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (datasourceObject[column.key] === undefined) {
                  // newObj[column.title] = ""
                } else {
                  newObj[column.title] = datasourceObject[column.key];
                }
              }
              return {};
            });
          reportPayload.push(newObj);
          return {};
        });
    } else {
      let newObj = {};
      columns &&
        Array.isArray(columns) &&
        columns.map((column) => {
          if (column && column.children) {
            let newChildrenObject = {};
            column.children.map((columnChildren) => {
              newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
              return {};
            });
            newObj[column.title] = {
              ...newChildrenObject
            };
          } else {
            newObj[column.title] = '';
          }

          return {};
        });
      reportPayload.push(newObj);
    }

    let reportChildPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
    ) {
      if (childDatasource && Array.isArray(childDatasource) && childDatasource.length > 0) {
        Array.isArray(childDatasource) &&
          childDatasource.map((childDatasourceObject) => {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(
                      columnChildren,
                      childDatasourceObject
                    );
                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  if (childDatasourceObject[column.key] === undefined) {
                    // newObj[column.title] = ""
                  } else {
                    newObj[column.title] = childDatasourceObject[column.key];
                  }
                }
                return {};
              });
            reportChildPayload.push(newObj);
            return {};
          });
      } else {
        let newObj = {};
        childColumns &&
          childColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }
            return {};
          });
        reportChildPayload.push(newObj);
      }
    }
    let reportWeekPayload = [];
    let reportMonthPayload = [];
    if (
      childColumns &&
      (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
    ) {
      if (
        weekSummaryDatasource &&
        Array.isArray(weekSummaryDatasource) &&
        weekSummaryDatasource.length > 0
      ) {
        weekSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {};

          weekSummaryColumns &&
            weekSummaryColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }
              return {};
            });
          reportWeekPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        weekSummaryColumns &&
          weekSummaryColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }
            return {};
          });
        reportWeekPayload.push(newObj);
      }

      if (
        monthSummaryDatasource &&
        Array.isArray(monthSummaryDatasource) &&
        monthSummaryDatasource.length > 0
      ) {
        monthSummaryDatasource.map((childDatasourceObject) => {
          let newObj = {};

          monthlySummaryColumns &&
            monthlySummaryColumns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(
                    columnChildren,
                    childDatasourceObject
                  );
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                if (childDatasourceObject[column.key] === undefined) {
                  newObj[column.title] = '';
                } else {
                  newObj[column.title] = childDatasourceObject[column.key];
                }
              }

              return {};
            });
          reportMonthPayload.push(newObj);
          return {};
        });
      } else {
        let newObj = {};
        monthlySummaryColumns &&
          monthlySummaryColumns.map((column) => {
            if (column && column.children) {
              let newChildrenObject = {};
              column.children.map((columnChildren) => {
                newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                return {};
              });
              newObj[column.title] = {
                ...newChildrenObject
              };
            } else {
              newObj[column.title] = '';
            }
            return {};
          });
        reportMonthPayload.push(newObj);
      }
    }
    let childTables = {};
    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === true &&
        reportChildPayload &&
        reportChildPayload.length > 1)
    ) {
      childTables['child' + 1] = reportChildPayload;
    }
    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === true &&
        reportWeekPayload &&
        reportWeekPayload.length > 1)
    ) {
      childTables['child' + 2] = reportWeekPayload;
    }
    if (
      (downloadExcelPayload && downloadExcelPayload.childTable === 'true') ||
      (downloadExcelPayload &&
        downloadExcelPayload.childTable === true &&
        reportMonthPayload &&
        reportMonthPayload.length > 1)
    ) {
      childTables['child' + 3] = reportMonthPayload;
    }
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,

      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Data: reportPayload,
      ChildTablesData: childTables
      //  "Summary": reportChildPayload,
      //  "WeeklySummary": reportWeekPayload,
      //  "MonthlySummary":reportMonthPayload
    };
  } else {
    let reportWeekPayload = [];
    let reportMonthPayload = [];
    let reportChildPayload = [];
    let reportPayload = {};
    let tableData = [];
    // columns && Object.keys(columns).map((colKey)=>{
    // let adhocColumns =columns[colKey]
    datasource &&
      Object.keys(datasource).map((dataKey) => {
        let adhocDatasource = datasource[dataKey].table;
        let adhocColumns = columns[dataKey];
        let indexValue = parseInt(dataKey) + 1;
        reportPayload['table' + indexValue] = {
          datasource: []
        };

        if (adhocDatasource && adhocDatasource.length > 0) {
          adhocDatasource &&
            Array.isArray(adhocDatasource) &&
            adhocDatasource.map((datasourceObject) => {
              let newObj = {};
              adhocColumns &&
                Array.isArray(adhocColumns) &&
                adhocColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        datasourceObject
                      );
                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (datasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = datasourceObject[column.key];
                    }
                  }
                  return {};
                });
              datasource[dataKey] &&
                datasource[dataKey].header &&
                Object.keys(datasource[dataKey].header).map(() => {
                  delete datasource[dataKey].header['KilnNo'];
                  return {};
                });
              reportPayload['table' + indexValue]['datasource'].push(newObj);
              reportPayload['table' + indexValue]['headers'] =
                datasource[dataKey] && datasource[dataKey].header;
              // delete reportPayload["table"+indexValue] ["headers"]["KilnNo"]
              reportPayload['table' + indexValue]['remarks'] =
                datasource[dataKey] && datasource[dataKey].footer;
              return {};
            });
        } else {
          let newObj = {};
          columns &&
            Array.isArray(columns) &&
            columns.map((column) => {
              if (column && column.children) {
                let newChildrenObject = {};
                column.children.map((columnChildren) => {
                  newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');
                  return {};
                });
                newObj[column.title] = {
                  ...newChildrenObject
                };
              } else {
                newObj[column.title] = '';
              }

              return {};
            });
          tableData.push(newObj);
        }
        let reportChildPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
        ) {
          if (childDatasource && childDatasource.length > 0) {
            childDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      // newObj[column.title] = ""
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportChildPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportChildPayload.push(newObj);
          }
        }
        let reportWeekPayload = [];
        if (
          childColumns &&
          (downloadExcelPayload.childTable === 'true' || downloadExcelPayload.childTable === true)
        ) {
          if (weekSummaryDatasource && weekSummaryDatasource.length > 0) {
            weekSummaryDatasource.map((childDatasourceObject) => {
              let newObj = {};
              childColumns &&
                childColumns.map((column) => {
                  if (column && column.children) {
                    let newChildrenObject = {};
                    column.children.map((columnChildren) => {
                      newChildrenObject[columnChildren.title] = getChildren(
                        columnChildren,
                        childDatasourceObject
                      );

                      return {};
                    });
                    newObj[column.title] = {
                      ...newChildrenObject
                    };
                  } else {
                    if (childDatasourceObject[column.key] === undefined) {
                      newObj[column.title] = '';
                    } else {
                      newObj[column.title] = childDatasourceObject[column.key];
                    }
                  }

                  return {};
                });
              reportWeekPayload.push(newObj);
              return {};
            });
          } else {
            let newObj = {};
            childColumns &&
              childColumns.map((column) => {
                if (column && column.children) {
                  let newChildrenObject = {};
                  column.children.map((columnChildren) => {
                    newChildrenObject[columnChildren.title] = getChildren(columnChildren, '');

                    return {};
                  });
                  newObj[column.title] = {
                    ...newChildrenObject
                  };
                } else {
                  newObj[column.title] = '';
                }

                return {};
              });
            reportWeekPayload.push(newObj);
          }
        }

        return {};
      });

    //  })
    payload = {
      ...headerPayload,
      ...downloadExcelPayload,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Summary: reportChildPayload,
      WeeklySummary: reportWeekPayload,
      MonthlySummary: reportMonthPayload
    };
  }
  let accessToken = localStorage.get('accessToken');
  const SiteId = localStorage.get('currentSite');
  let options = {};
  options = {
    method: 'POST',
    url: `/api/report/generatePdf`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...payload, SiteId }
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}

export async function _saveReportAPI(payload) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const deviceTypeObject = {
    method: 'POST',
    url: `/api/report/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...payload, SiteId: siteId }
  };
  axios(deviceTypeObject)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
}
